import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import CompanySetting from '../../../model/companySetting'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import PensionCompany from '../../../model/pensionCompany'
import { ContractReducer } from '../../../reducers/contracts'
import PensionSchemes from '../../../types/pension-scheme'
import { formatPensionScheme } from '../../../utils/format-utils'
import { formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentPensionEdit from './EmploymentPensionEdit'

type Props = {
  mutableContract: Contract
  settingsEnabled: CompanySetting[]
  employee: Employee
  contracts: ContractReducer
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>
  isMutableContract: boolean

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentPensionCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const { contracts, isMutableContract } = props
  const previousContracts = usePrevious(contracts)

  const setEditVisibility = useCallback(
    (visible: boolean) => {
      if (!isMutableContract && visible) {
        return
      }
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setShowEdit(visible)
    },
    [isMutableContract]
  )

  useEffect(() => {
    // Check for save callback
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      // Check for no error occurred
      if (!contracts.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  }, [previousContracts, contracts, setEditVisibility])

  type PensionRow = {
    key: string
    company?: string
    number: string
    scheme: string
    rate: string
    suffix: string
    net: boolean
  }

  const pensionColumns = [
    {
      title: t('pension.card.table.header.company'),
      dataIndex: '',
      key: 'x1',
      render: (row: PensionRow) =>
        row.company ? (
          row.company
        ) : (
          <span>
            <em>{t('pension.card.table.transfer')}</em>
          </span>
        ),
    },
    { title: t('pension.card.table.header.number'), dataIndex: 'number', key: 'number' },
    { title: t('pension.card.table.header.scheme'), dataIndex: 'scheme', key: 'scheme' },
    {
      title: t('pension.card.table.header.rate'),
      dataIndex: '',
      key: 'x2',
      render: (row: PensionRow) => (
        <div>
          {row.rate} <em>{row.suffix}</em>
          {row.net && (
            <>
              <br />
              {t('pension.card.table.net')}
            </>
          )}
        </div>
      ),
    },
  ]

  const getPensionRows = (): PensionRow[] => {
    return (
      props.mutableContract.remuneration?.pension
        .filter((row) => row.scheme !== 'ATP')
        .map((row) => {
          let company = undefined
          let number = ''
          if (row.pensionCompanyID) {
            const pensionCompany = props.pensionCompanies.find(
              (pensionCompany) => pensionCompany.id === row.pensionCompanyID
            )
            if (pensionCompany) {
              company = pensionCompany.name
            }
            number = row.pensionCustomerID || ''
          } else {
            number = row.sortCode + ' ' + row.account
          }
          return {
            key: row.id,
            company: company,
            number: number,
            scheme: formatPensionScheme(row.scheme),
            net: row.scheme === PensionSchemes.EMPLOYEE_PAID_NET || row.scheme === PensionSchemes.EMPLOYER_PAID_NET,
            rate: row.fixedAmount ? formatNumber(row.fixedAmount, 2) : formatNumber(row.percentage, 2),
            suffix: row.fixedAmount ? t('pension.card.table.suffix.fixed') : t('pension.card.table.suffix.percentage'),
          }
        }) || []
    )
  }

  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button.Icon type="paperWithPencil" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>{t('pension.card.title')}</Title>

      <Table columns={pensionColumns} dataSource={getPensionRows()} pagination={false} />

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={788}
        footer={null}
      >
        <EmploymentPensionEdit
          visible={showEdit}
          settingsEnabled={props.settingsEnabled}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          pensionCompanies={props.pensionCompanies}
          leaveTypes={props.leaveTypes}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
