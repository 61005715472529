import React, { ReactElement, useEffect } from 'react'

import { BootstrapResult, getBootstrap } from '../../actions/bootstrap'
import { getEmployees } from '../../actions/employees'
import jsBrowserHistory from '../../components/widgets/jsBrowserHistory'
import paths from '../../constants/paths'
import { CompanyReducer } from '../../reducers/companies'
import { EmployeeReducer } from '../../reducers/employees'
import { connectToReducer } from '../../utils/reducer-utils'
import { RouteProps } from '../../utils/route-utils'

export function needCompany(companies: CompanyReducer, pathname: string, permitRedirect: boolean): boolean {
  const addCompanyRoute = '/' + paths.COMPANIES + '/' + paths.ADD
  const companiesRoute = '/' + paths.COMPANIES
  const singleCompanyState = companies.companies.size <= 1
  if (
    companies.loaded &&
    !companies.company &&
    pathname !== addCompanyRoute &&
    (singleCompanyState || (pathname !== companiesRoute && pathname.indexOf('/dashboard') !== 0)) &&
    pathname.indexOf('/account') !== 0 &&
    pathname.indexOf('/in/webConnect') !== 0 &&
    pathname.indexOf('/signing') !== 0 &&
    pathname.indexOf('/sso') !== 0 &&
    pathname.indexOf('/user-invite') !== 0 &&
    pathname !== '/a' &&
    pathname.indexOf('/' + paths.COMPANY_GROUPS) !== 0 &&
    pathname.indexOf('/jump') !== 0 &&
    pathname.indexOf('/' + paths.PDF_PREVIEW) !== 0
  ) {
    if (permitRedirect) {
      if (!singleCompanyState) {
        jsBrowserHistory.push(companiesRoute)
      } else {
        jsBrowserHistory.push(addCompanyRoute)
      }
    }
    return true
  }
  return false
}

type Reducers = {
  companies: CompanyReducer
  employees: EmployeeReducer
}

type Actions = {
  getEmployees: () => void
  getBootstrap: (
    justLoggedIn: boolean,
    includeActiveCompany: boolean,
    forceSettingActiveCompany: boolean
  ) => Promise<BootstrapResult | void>
}

function Jumper(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { companies, employees, getEmployees } = props

  useEffect(() => {
    if (!companies.company) {
      return
    }
    if ((!employees.loaded && !employees.loaded) || employees.companyID !== companies.company.id) {
      getEmployees()
    }
  }, [companies, employees, getEmployees])

  const { location, getBootstrap } = props

  useEffect(() => {
    if (!companies.company) {
      jsBrowserHistory.push(paths.COMPANIES + '/' + paths.ADD)
      return
    }
    const endOfLink = location.search + location.hash
    const pathname = location.pathname.replace(/^\/jump/, '').replace(/\/$/, '')
    const pathSplit = pathname.split('/')
    if (pathSplit.length < 2) {
      jsBrowserHistory.push('/' + endOfLink)
      return
    }
    if (needCompany(companies, pathname, false)) {
      getBootstrap(false, true, true)
      return
    }
    switch (pathSplit[1]) {
      case paths.COMPANIES:
        jsBrowserHistory.push(
          pathname.replace('/' + paths.COMPANIES + '/', '/' + paths.COMPANIES + '/' + companies.company.id + '/') +
            endOfLink
        )
        break
      case paths.EMPLOYEES: {
        if (pathSplit.length === 2) {
          jsBrowserHistory.push(pathname + endOfLink)
          break
        }
        if (employees.loading || !employees.loaded) {
          break
        }
        const employeeID = employees.employees.filter((e) => e.affiliationType !== 'Freelancer').first()?.id
        if (!employeeID) {
          jsBrowserHistory.push('/' + paths.EMPLOYEES)
          return
        }
        jsBrowserHistory.push(
          pathname.replace('/' + paths.EMPLOYEES + '/', '/' + paths.EMPLOYEES + '/' + employeeID + '/') + endOfLink
        )
        break
      }
      case paths.FREELANCERS: {
        if (pathSplit.length === 2) {
          jsBrowserHistory.push(pathname + endOfLink)
          break
        }
        if (employees.loading || !employees.loaded) {
          break
        }
        const employeeID = employees.employees.filter((e) => e.affiliationType === 'Freelancer').first()?.id
        if (!employeeID) {
          jsBrowserHistory.push('/' + paths.FREELANCERS)
          return
        }
        jsBrowserHistory.push(
          pathname.replace('/' + paths.FREELANCERS + '/', '/' + paths.FREELANCERS + '/' + employeeID + '/' + endOfLink)
        )
        break
      }
      default:
        jsBrowserHistory.push(pathname)
        break
    }
  }, [companies, location, employees, getBootstrap])

  return <></>
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    companies: state.companies,
    employees: state.employees,
  }),
  {
    getEmployees: getEmployees,
    getBootstrap: getBootstrap,
  }
)(Jumper)
