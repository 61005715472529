import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { ApiKeyCreationFields } from '../../model/apiKey'
import { ApiKeyReducer } from '../../reducers/apiKeys'
import { formatDate } from '../../utils/date-utils'
import { formatApiKeyScope } from '../../utils/format-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ApiKeyEdit from './ApiKeyEdit'

type Props = {
  apiKeys: ApiKeyReducer

  addAlert: addAlertSignature
  getApiKeys: () => void
  addApiKey: (apiKey: ApiKeyCreationFields) => void
  deleteApiKey: (id: string) => void
}

export default function ApiKeysTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { apiKeys, getApiKeys } = props
  useEffect(() => {
    if (!apiKeys.loading && !apiKeys.loaded) {
      getApiKeys()
    }
  })

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const previousApiKeys = usePrevious(apiKeys)

  useEffect(() => {
    // Check for save callback
    if (previousApiKeys && previousApiKeys.saving && !apiKeys.saving) {
      // Check for no error occurred
      if (!apiKeys.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteApiKey(id)
      }
    }
  }

  type ApiKeyRow = {
    key: string
    id: string
    description: string
    apiKey: string
    scopes: string
    createdAt: string
  }

  const columns = [
    { title: t('api_keys.table.header.description'), dataIndex: 'description', key: 'description' },
    {
      title: t('api_keys.table.header.key'),
      dataIndex: '',
      key: 'xApiKey',
      render: (apiKey: ApiKeyRow) => {
        return (
          <Input
            value={apiKey.apiKey}
            readOnly={true}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.currentTarget.select()}
          />
        )
      },
    },
    { title: t('api_keys.table.header.scopes'), dataIndex: 'scopes', key: 'scopes' },
    { title: t('api_keys.table.header.created_at'), dataIndex: 'createdAt', key: 'createdAt' },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      className: 'company-table-actions',
      render: (apiKey: ApiKeyRow) => {
        if (deleting.indexOf(apiKey.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('api_keys.table.actions.delete')}>
              <span onClick={remove(apiKey.id)} style={{ cursor: 'pointer' }}>
                <Icon type="xSign" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getApiKeyRows = (): ApiKeyRow[] => {
    return props.apiKeys.apiKeys
      .map((apiKey) => {
        return {
          key: apiKey.id,
          id: apiKey.id,
          apiKey: apiKey.apiKey,
          description: apiKey.description,
          scopes: apiKey.scopes.map((scope) => formatApiKeyScope(scope)).join(', '),
          createdAt: formatDate(apiKey.createdAt),
        }
      })
      .toArray()
  }

  if (!props.apiKeys.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <TitleMenu>
        <FeatureLock featureType={'API Keys'} description={t('api_keys.header.add_api_key_lock')}>
          <Button type="primary" onClick={() => setEditVisibility(true)} prefixIcon="plusCircle">
            {t('api_keys.header.add_api_key')}
          </Button>
        </FeatureLock>
      </TitleMenu>
      <Title>{t('api_keys.title')}</Title>

      <p>
        {tx('api_keys.description', {
          link: (
            <a href="https://api.salary.dk/docs" target="_blank" rel="noopener noreferrer">
              {t('api_keys.description_link')}
            </a>
          ),
        })}
      </p>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getApiKeyRows()} pagination={false} />

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <ApiKeyEdit
          visible={editing !== false}
          apiKeyID={typeof editing === 'string' ? editing : undefined}
          apiKeys={props.apiKeys}
          addApiKey={props.addApiKey}
        />
      </Modal>
    </Card>
  )
}
