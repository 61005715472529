import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import ContractBookIntegration, {
  ContractBookIntegrationCreationFields,
  ContractBookIntegrationMutableFields,
} from '../../model/contractBookIntegration'
import { AlertReducer } from '../../reducers/alerts'
import { ContractBookContractReducer } from '../../reducers/contractBookContracts'
import { ContractBookIntegrationReducer } from '../../reducers/contractBookIntegrations'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Table from '../elements/table'
import Title from '../elements/Title'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ContractBookForm, { ContractBookResult } from './ContractBookForm'

type Props = {
  alerts: AlertReducer
  company: Company
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookContracts: ContractBookContractReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractBookIntegrations: () => void
  updateContractBookIntegration: (
    contractBookID: string,
    integration: ContractBookIntegrationMutableFields
  ) => Promise<ContractBookIntegration | void>
  createContractBookIntegration: (
    integration: ContractBookIntegrationCreationFields
  ) => Promise<ContractBookIntegration | void>
  deleteContractBookIntegration: (id: string) => Promise<boolean | void>
  getContractBookContracts: () => void
  setContractBookContractActive: (id: string, active: boolean) => void
}

export default function ContractBook(props: Props): ReactElement | null {
  const [savingCount, setSavingCount] = useState(0)
  const [error, setError] = useState<Error | null>(null)

  const { contractBookIntegrations, getContractBookIntegrations, contractBookContracts, getContractBookContracts } =
    props

  useEffect(() => {
    if (!contractBookIntegrations.loading && !contractBookIntegrations.loaded) {
      getContractBookIntegrations()
    }

    if (!contractBookContracts.loading && !contractBookContracts.loaded) {
      getContractBookContracts()
    }
  })

  const { addAlert, company } = props

  const previousSavingCount = usePrevious(savingCount)

  useEffect(() => {
    // Check for save callback
    if (previousSavingCount && previousSavingCount > 0 && savingCount === 0) {
      // Check for no error occurred
      if (!contractBookIntegrations.error) {
        addAlert('success', t('contract_book.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(contractBookIntegrations.error, error, setError)
  }, [contractBookIntegrations, error])

  type ContractRow = {
    key: string
    id: string
    contractName: string
    signeeName?: string
    updatedAt: string
  }

  const columns = [
    { title: t('contract_book.table.header.contract_name'), dataIndex: 'contractName', key: 'contractName' },
    { title: t('contract_book.table.header.signee_name'), dataIndex: 'signeeName', key: 'signeeName' },
    { title: t('contract_book.table.header.updated_at'), dataIndex: 'updatedAt', key: 'updatedAt' },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      width: 1,
      render: (contract: ContractRow) => (
        <>
          <Link to={'/' + paths.EMPLOYEES + '/' + paths.ADD + '?contractBookContractID=' + contract.id}>
            <Icon type="plusCircle" />
          </Link>
          <DumbLink
            onClick={() => props.setContractBookContractActive(contract.id, false)}
            title={t('contract_book.table.actions.delete')}
          >
            <Icon type="xSign" />
          </DumbLink>
        </>
      ),
    },
  ]

  const getContractBookContractRows = (): ContractRow[] => {
    return props.contractBookContracts.contracts
      .filter((contract) => contract.active)
      .map((contract) => {
        return {
          key: contract.contractID,
          id: contract.contractID,
          contractName: contract.contractName,
          signeeName: contract.signeeName,
          updatedAt: formatDate(contract.updatedAt),
        }
      })
      .toArray()
  }

  const handleSubmit = (values: ContractBookResult) => {
    values.contractBookIntegrations.forEach((contractBookIntegration) => {
      if (!contractBookIntegration.contractBookAPIKey || contractBookIntegration.contractBookAPIKey === '') {
        return
      }
      setSavingCount((prev) => prev + 1)
      if (contractBookIntegration.delete) {
        if (contractBookIntegration.id && contractBookIntegration.id !== '') {
          props.deleteContractBookIntegration(contractBookIntegration.id).then(() => setSavingCount((prev) => prev - 1))
        }

        return
      }
      if (contractBookIntegration.id && contractBookIntegration.id !== '') {
        props
          .updateContractBookIntegration(contractBookIntegration.id, {
            contractBookAPIKey: contractBookIntegration.contractBookAPIKey,
          })
          .then(() => setSavingCount((prev) => prev - 1))
      } else {
        props
          .createContractBookIntegration({
            contractBookCallbackToken: contractBookIntegration.contractBookCallbackToken,
            contractBookAPIKey: contractBookIntegration.contractBookAPIKey,
          })
          .then(() => setSavingCount((prev) => prev - 1))
      }
    })
  }

  if (
    !props.contractBookIntegrations.loaded ||
    (props.contractBookIntegrations.contractBookIntegrations &&
      props.contractBookIntegrations.contractBookIntegrations.size > 0 &&
      props.contractBookContracts.loading)
  ) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const saving = savingCount > 0 || props.contractBookIntegrations.saving

  return (
    <div>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <Card>
        <Title>ContractBook</Title>

        <div className="companies-single-form">
          <Row>&nbsp;</Row>
          {!saving && (
            <ContractBookForm
              contractBookIntegrations={props.contractBookIntegrations.contractBookIntegrations}
              company={props.company}
              onSubmit={handleSubmit}
            />
          )}
          {saving && <LoadingOverlay />}
        </div>
      </Card>
      <Card>
        <Title>{t('contract_book.unsigned_contracts')}</Title>

        {props.contractBookContracts.error && (
          <Alert message={formatError(props.contractBookContracts.error)} type="error" showIcon />
        )}

        <Table columns={columns} dataSource={getContractBookContractRows()} pagination={false} />
      </Card>
    </div>
  )
}
