import { subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { ContractCreationFields } from '../../model/contract'
import Employee from '../../model/employee'
import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import { formatAPIDate, getDate, isSameOrAfter, isTimeBefore } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

type Props = {
  employee: Employee
  employment: Employment
  salaryCycles: List<SalaryCycle>
  company: Company

  addAlert: addAlertSignature
  addContract: (contract: ContractCreationFields) => void
}

export default function NoContractCard(props: Props): ReactElement | null {
  const getSalaryCycleID = () => {
    let defaultHourlyMonthlyCycleID = props.company.defaultHourlyMonthlyCycleID
    if (!defaultHourlyMonthlyCycleID) {
      const salaryCycle = props.salaryCycles.find(
        (salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.prepaid && !salaryCycle.offset
      )
      if (salaryCycle) {
        defaultHourlyMonthlyCycleID = salaryCycle.id
      }
    }
    return defaultHourlyMonthlyCycleID
  }

  const _createFreelanceContract = () => {
    // Get first usable salary period
    const salaryCycleID = getSalaryCycleID()
    if (!salaryCycleID) {
      props.addAlert('error', t('freelancer.no_contract_card.error.no_cycle'), { closable: true })
      return // not possible
    }
    const period = props.salaryCycles
      .find((cycle) => cycle.id === salaryCycleID)
      ?.salaryPeriods.find((salaryPeriod) =>
        isSameOrAfter(getDate(salaryPeriod.latestStartTime), subMonths(getDate(), 1))
      )
    const productionUnits = props.company.productionUnits
    if (productionUnits.length < 1) {
      props.addAlert('error', t('freelancer.no_contract_card.error.no_production_unit'), { closable: true })
      return // not possible
    }

    let validFrom = getDate()
    if (period) {
      validFrom = getDate(period.start)
    }
    // but if the period starts before the employment, switch it to the employment start date
    if (isTimeBefore(validFrom, getDate(props.employment.startDate))) {
      validFrom = getDate(props.employment.startDate)
    }

    // Create standard freelance contract
    props.addContract({
      employmentID: props.employment.id,
      employeeID: props.employee.id,
      employmentType: 'Freelance',
      validFrom: formatAPIDate(validFrom),
      productionUnitID: productionUnits[0].id,
      salaryCycleID,
      timeRegistrationMethodType: 'Coarse',
      carAllowanceRegistrationMethodType: 'Coarse',
      salaryRegistrationMethodType: 'Coarse',
      weeklyWorkDays: 0,
      workCycleHours: [0],
      workCycle: [[]],
      remuneration: {
        salary: [],
        benefits: [],
        leave: [],
        supplements: [],
        pension: [],
      },
    })
  }

  return (
    <Card className="no-contract-card">
      Con
      <Subtitle>{t('freelancer.no_contract_card.title')}</Subtitle>
      <p>{t('freelancer.no_contract_card.intro')}</p>
      <Button size="extra-extra-large" type="secondary" onClick={_createFreelanceContract}>
        {t('freelancer.no_contract_card.button')}
      </Button>
    </Card>
  )
}
