import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { EmploymentType } from '../../model/types'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import SearchEmployeeForm from '../search-employees/SearchEmployeeForm'

type Fields = {
  employeeIDs: string[]
  searchQuery?: string
  employmentType?: EmploymentType
  departmentID?: string
  onlyMutable: boolean
  createdBefore?: Date
  onlyDraft: boolean
}

export type ProjectEmployeeResult = {
  employeeIDs: string[]
}

type Props = {
  company: Company
  employees: List<Employee>
  employeeIDs: string[]
  departments: List<Department>
}

function ProjectEmployeeSelectForm(
  props: Props & FormComponentProps<Fields, ProjectEmployeeResult>
): ReactElement | null {
  return (
    <>
      <SearchEmployeeForm<Fields>
        company={props.company}
        employees={props.employees.toArray()}
        departments={props.departments}
        getFieldValue={props.getFieldValue}
        setFieldValue={props.setFieldValue}
        decorateField={props.decorateField}
        selectHeight={300}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('projects.employee_select.submit')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withValidations<Props, Fields, ProjectEmployeeResult>({
  mapPropsToFields: (props) => ({ employeeIDs: props.employeeIDs, onlyMutable: false, onlyDraft: false }),
  onSubmit: (values) => ({ employeeIDs: values.employeeIDs }),
})(ProjectEmployeeSelectForm)
