import React, { ReactElement } from 'react'

import { ExternalDaybook } from '../../api/company-accounting-integration-setup'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'

type Props = {
  editing: boolean
  allowNoDaybook: boolean
  daybooks: ExternalDaybook[]

  daybookID?: string
}

export type DaybookFields = {
  readonly step: 'Daybook'
  daybookID?: string
}

const NO_DAYBOOK = 'No Daybook'

function DaybookForm(props: Props & FormComponentProps<DaybookFields, DaybookFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {!props.editing && <Subtitle>{t('accounting_integration.daybooks.form.daybook_id')}</Subtitle>}
      {props.getFormError()}
      <Subcard className="connection-search">
        {decorateField('daybookID', {
          placeholder: t('accounting_integration.daybooks.form.daybook_id'),
          validate: (val) => (!val ? t('accounting_integration.daybooks.form.daybook_id.required') : ''),
        })(
          <Select dropdownMatchSelectWidth={false}>
            {props.allowNoDaybook && (
              <Select.Option key={NO_DAYBOOK} value={NO_DAYBOOK}>
                <i>{t('accounting_integration.daybooks.form.daybook_id.no_daybook')}</i>
              </Select.Option>
            )}
            {props.daybooks
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((daybook) => {
                return (
                  <Select.Option key={daybook.daybookID} value={daybook.daybookID}>
                    {daybook.name}
                  </Select.Option>
                )
              })}
          </Select>
        )}
        {props.allowNoDaybook && <p>{t('accounting_integration.daybooks.form.daybook_id.allow_no_daybook_note')}</p>}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="secondary"
            className="gtm-accounting-integration-continue-from-daybook"
          >
            {props.editing
              ? t('accounting_integration.daybooks.form.submit.editing')
              : t('accounting_integration.daybooks.form.submit.next_step')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, DaybookFields, DaybookFields>({
  mapPropsToFields: (props) => {
    const fields: DaybookFields = {
      step: 'Daybook',
      daybookID: props.daybookID,
    }
    if (!fields.daybookID) {
      if (props.editing) {
        // then it must be because we have selected no daybook earlier
        fields.daybookID = NO_DAYBOOK
      } else {
        if (props.daybooks && props.daybooks.length === 1) {
          fields.daybookID = props.daybooks[0].daybookID
        }
      }
    }
    return fields
  },
  onSubmit: (values) => {
    values.daybookID = values.daybookID === NO_DAYBOOK ? undefined : values.daybookID
    return values
  },
})(DaybookForm)
