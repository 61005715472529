import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import { DateFormat } from '../../model/types'
import { ProjectReducer } from '../../reducers/projects'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  projects: ProjectReducer
  projectID?: string
  employees: Employee[]

  showEmployeeSelect: () => void
}

type Fields = {
  name?: string
  description?: string
  externalID?: string
  hourlyRate?: string
  validFromEnabled: boolean
  validFrom: Date
  validToEnabled: boolean
  validTo: Date
}

export type ProjectResult = {
  name: string
  description?: string
  externalID?: string
  hourlyRate?: number
  validFrom?: DateFormat
  validTo?: DateFormat
}

function ProjectEditForm(props: Props & FormComponentProps<Fields, ProjectResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('projects.edit.form.name'),
            validate: (val) => {
              if (!val) {
                return t('projects.edit.form.name.required')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('description', {
            placeholder: t('projects.edit.form.description'),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('externalID', {
            placeholder: t('projects.edit.form.external_id'),
          })(<Input />)}
        </Col>
        <Col span={12}>
          {decorateField('hourlyRate', {
            placeholder: t('projects.edit.form.hourly_rate'),
            suffix: t('projects.edit.form.hourly_rate.suffix'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^([0-9,.]+)/)) {
                return t('projects.edit.form.hourly_rate.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p>{t('projects.edit.form.period_note')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="ant-switch-wrapper">
            {decorateField('validFromEnabled', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{t('projects.edit.form.valid_from_enabled')}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="ant-switch-wrapper">
            {decorateField('validToEnabled', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{t('projects.edit.form.valid_to_enabled')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {getFieldValue('validFromEnabled') &&
            decorateField('validFrom', {
              title: t('projects.edit.form.valid_from'),
            })(<DatePicker />)}
        </Col>
        <Col span={12}>
          {getFieldValue('validToEnabled') &&
            decorateField('validTo', {
              title: t('projects.edit.form.valid_to'),
            })(<DatePicker />)}
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          {props.employees
            .map((employee) => employee.name || employee.email || '-')
            .sort((a, b) => a.localeCompare(b))
            .join(', ') || <i>{t('projects.edit.form.employees.none')}</i>}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <DumbLink onClick={props.showEmployeeSelect}>{t('projects.edit.form.employees.select')}</DumbLink>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.projects.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ProjectResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      validFromEnabled: false,
      validToEnabled: false,
      validFrom: getDate(),
      validTo: getDate(),
    }

    const project = props.projects.projects.find((type) => type.id === props.projectID)
    if (project) {
      fields.name = project.name
      fields.description = project.description
      fields.externalID = project.externalID
      fields.hourlyRate = project.hourlyRate ? formatInputNumber(project.hourlyRate) : undefined
      fields.validFromEnabled = !!project.validFrom
      fields.validFrom = getDate(project.validFrom)
      fields.validToEnabled = !!project.validTo
      fields.validTo = getDate(project.validTo)
    }

    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'hourlyRate':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    name: values.name || '',
    hourlyRate: values.hourlyRate ? forceParseInputNumber(values.hourlyRate) : undefined,
    validFrom: values.validFromEnabled ? formatAPIDate(values.validFrom) : undefined,
    validTo: values.validToEnabled ? formatAPIDate(values.validTo) : undefined,
  }),
})(ProjectEditForm)
