import { addYears, isSameDay, startOfYear, subYears } from 'date-fns'
import React, { ReactElement } from 'react'

import Contract from '../../model/contract'
import Employee from '../../model/employee'
import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import { DateFormat } from '../../model/types'
import { formatDate, getDate, isSameOrAfter, isTimeAfter, isTimeBefore } from '../../utils/date-utils'
import { decorateFieldSignature, getFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Select from '../elements/select'

type ContractValidFromFields = {
  validFrom?: DateFormat
  validFromPicker?: Date
  employmentStartDate: Date
}

type Props<Fields extends ContractValidFromFields> = {
  contract: Contract
  employment: Employment
  employee: Employee
  useSalaryPeriods: boolean
  salaryCycle: SalaryCycle
  disabled?: boolean

  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
}

export default function ContractValidFrom<Fields extends ContractValidFromFields>(
  props: Props<Fields>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  const employment = props.employment
  const validate = (val: Date | string | undefined) => {
    if (!val) {
      return t('contract_valid_from_form.valid_from.required')
    }
    if (typeof val === 'string' && isNaN(Date.parse(val as string))) {
      return t('contract_valid_from_form.valid_from.invalid')
    }
    val = getDate(val)
    if (isSameDay(val, getDate(props.contract.validFrom))) {
      return null // if we are not changing it, then whatever it is, is all right
    }
    if (isTimeBefore(val, startOfYear(subYears(getDate(), 1)))) {
      return t('contract_valid_from_form.valid_from.not_before_start_of_last_year')
    }
    if (isTimeBefore(val, getFieldValue('employmentStartDate') || getDate(employment.startDate))) {
      return t('contract_valid_from_form.valid_from.not_before_employment_date')
    }
    if (isTimeBefore(val, getDate('2020-09-01'))) {
      return t('contract_valid_from_form.valid_from.not_before_date', { date: formatDate('2020-09-01') })
    }
    if (props.employee.immutableEndDate && isTimeBefore(val, getDate(props.employee.immutableEndDate))) {
      return t('contract_valid_from_form.valid_from.not_before_date', {
        date: formatDate(props.employee.immutableEndDate),
      })
    }
    if (employment.endDate && isTimeAfter(val, getDate(employment.endDate))) {
      return t('contract_valid_from_form.valid_from.not_after_termination_date', {
        date: formatDate(employment.endDate),
      })
    }
    if (props.contract.validTo && isSameOrAfter(val, getDate(props.contract.validTo))) {
      return t('contract_valid_from_form.valid_from.not_same_or_after_valid_to', {
        date: formatDate(props.contract.validTo),
      })
    }
    return null
  }

  const dates = props.useSalaryPeriods
    ? props.salaryCycle.salaryPeriods
        .filter(
          (period) =>
            isSameOrAfter(getDate(period.start), getDate(props.employee.immutableEndDate)) &&
            isTimeBefore(getDate(period.start), addYears(getDate(), 2))
        )
        .map((period) => period.start)
    : []
  const validFromValue = getFieldValue('validFrom')
  if (validFromValue && !dates.some((date) => date === validFromValue)) {
    dates.push(validFromValue)
  }

  return (
    <Col span={12}>
      {!props.useSalaryPeriods &&
        decorateField('validFromPicker', {
          placeholder: t('contract_valid_from_form.valid_from'),
          validate: validate,
        })(<DatePicker allowClear={false} style={{ width: '100%' }} disabled={props.disabled} />)}
      {props.useSalaryPeriods &&
        decorateField('validFrom', {
          title: t('contract_valid_from_form.valid_from'),
          validate: validate,
        })(
          <Select dropdownMatchSelectWidth={false} disabled={props.disabled}>
            {dates
              .sort((a, b) => a.localeCompare(b))
              .map((date) => {
                return (
                  <Select.Option value={date} key={date}>
                    {formatDate(date)}
                  </Select.Option>
                )
              })}
          </Select>
        )}
    </Col>
  )
}
