import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingAccount } from '../../model/accountingIntegration'
import Employee from '../../model/employee'
import { EmployeeReducer } from '../../reducers/employees'
import { combineSearchOption, FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'
import LoadingOverlay from '../widgets/LoadingOverlay'

const NO_ACCOUNT = 'NoAccount'

type Fields = {
  nondisburseableAccountingAccountID: string
}

export type EmployeeNondisburseableAccountResult = {
  nondisburseableAccountingAccountID?: string
}

type Props = {
  employee: Employee
  employees: EmployeeReducer
  accounts: List<AccountingAccount>
}

function NondisburseableAccountEditForm(
  props: Props & FormComponentProps<Fields, EmployeeNondisburseableAccountResult>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      {props.accounts.size > 0 && (
        <Row>
          <Col span={24}>
            {decorateField('nondisburseableAccountingAccountID', {
              placeholder: t('employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id'),
              validate: (val) => {
                if (!val || val === NO_ACCOUNT) {
                  return null
                }
                const account = props.accounts.find((account) => account.id === val)
                if (!account) {
                  return t(
                    'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.generic'
                  )
                }
                if (!account.active) {
                  return t(
                    'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.specific',
                    { account: account.accountNumber }
                  )
                }
                return null
              },
            })(
              <Select
                dropdownMatchSelectWidth={false}
                showSearch={true}
                filterOption={(inputValue: string, option: ReactElement) => {
                  return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }}
              >
                <Select.Option
                  key={NO_ACCOUNT}
                  value={NO_ACCOUNT}
                  title={t(
                    'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_account'
                  )}
                >
                  <em>
                    {t('employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_account')}
                  </em>
                </Select.Option>
                {props.accounts
                  .filter(
                    (account) => account.active || account.id === getFieldValue('nondisburseableAccountingAccountID')
                  )
                  .map((account) => {
                    let name = account.accountNumber
                    if (account.name) {
                      name += ' ' + account.name
                    }
                    return (
                      <Select.Option key={account.id} value={account.id} title={name}>
                        {name}
                        {!account.active && (
                          <i>
                            {' '}
                            {t(
                              'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.option_note'
                            )}
                          </i>
                        )}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.employees.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, EmployeeNondisburseableAccountResult>({
  mapPropsToFields: (props) => ({
    nondisburseableAccountingAccountID: props.employee.nondisburseableAccountingAccountID || NO_ACCOUNT,
  }),
  onSubmit: (values) => ({
    nondisburseableAccountingAccountID: values.nondisburseableAccountingAccountID
      ? values.nondisburseableAccountingAccountID
      : undefined,
  }),
})(NondisburseableAccountEditForm)
