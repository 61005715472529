import { List } from 'immutable'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyGroup from '../../model/companyGroup'
import { ModalOption, ModalType } from '../../model/modal'
import Warning from '../../model/warning'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { EmployeeReducer } from '../../reducers/employees'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { UserReducer } from '../../reducers/user'
import { WarningReducer } from '../../reducers/warnings'
import Language from '../../types/language'
import { removeUUIDFromURL, replaceUUIDInURL } from '../../utils/link-util'
import {
  CombinedMainMenuID,
  getAllParentsForCurrentPath,
  getMainMenu,
  getTopParentForCurrentPath,
  MenuContext,
  MenuItem,
  setMainMenuEmployee,
  setMainMenuFreelancer,
} from '../../utils/menu-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { getMissingPackageIDs, isPricingPackageGroup } from '../../utils/pricing-package-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import Icon from '../elements/icon'
import Input from '../elements/input/Input'
import Menu from '../elements/menu'
import Popover from '../elements/popover'
import UserImage from '../elements/UserImage'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import JumpLink from '../widgets/JumpLink'
import PendingApprovalCount from '../widgets/PendingApprovalCount'

import './MainMenu.css'

type Props = {
  location: RoutePropsLocation
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyGroups: List<CompanyGroup>
  employees: EmployeeReducer
  warnings: WarningReducer
  currentLanguage: Language
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer

  setActiveCompany: (id: string) => void
  logout: () => Promise<boolean | void>
  setCurrentLanguage: (language: Language) => void
  getPricingPackages: (packageIDs: string[]) => void
  addModal: (type: ModalType, options: ModalOption) => void
}

export default function MainMenu(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const { menu, setMenu } = useContext(MenuContext)
  const [menuFold, setMenuFold] = useState<CombinedMainMenuID[]>([])

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const { companyPricingPackages, pricingPackages, getPricingPackages } = props
  useEffect(() => {
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackageIDs(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray()
      )
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  }, [companyPricingPackages, pricingPackages, getPricingPackages])

  const { location } = props

  useEffectOnce(() => {
    const item = getTopParentForCurrentPath(location.pathname)
    if (item) {
      setMenuFold((prev) => {
        if (!menuFold.includes(item.id)) {
          return [...prev, item.id]
        }
        return prev
      })
    }
  })

  const { companies } = props

  const company = companies.company
  const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)

  const numberOfCompanies = companies.companies.size
  const hasMultipleCompanies = numberOfCompanies > 1 && company

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    props
      .logout()
      .then((res) => {
        if (res) {
          jsBrowserHistory.push('/' + paths.LOGIN)
        }
      })
      .catch(() => {
        // TODO: Handle
        return
      })
  }

  const getNotifications = (): Warning[] => {
    return props.warnings.warnings.filter((warning) => warning.companyLevel).toArray()
  }

  const getCompanies = (): Company[] => {
    const pattern = searchQuery ? new RegExp(escapeRegExp(searchQuery), 'i') : null
    return props.companies.companies
      .filter((company) => {
        if (pattern) {
          if (!pattern.test(company.name) && !pattern.test(company.nationalID)) {
            return false
          }
        }
        return true
      })
      .toArray()
  }

  const buildJumpPath = () => {
    const split = props.location.pathname.split('/')
    if (split.length < 2) {
      return '/' + paths.JUMP + props.location.search
    }
    let result = '/' + paths.JUMP + removeUUIDFromURL(props.location.pathname)
    if (result.split('/').length === 2) {
      // handle a few corner cases
      switch (split[1]) {
        case paths.COMPANIES:
          result = '/' + paths.JUMP + '/' + paths.COMPANIES + '/company'
          break
        case paths.EMPLOYEES:
          result = '/' + paths.JUMP + '/' + paths.EMPLOYEES + '/employment'
          break
        case paths.FREELANCERS:
          result = '/' + paths.JUMP + '/' + paths.FREELANCERS + '/overview'
          break
        case paths.PAY_ROLLS:
          result = '/' + paths.JUMP + '/' + paths.PAY_ROLLS + '/single'
          break
      }
    }
    return result + props.location.search
  }

  const renderUserMenu = (): ReactElement => {
    const company = props.companies.company
    const hasMultipleCompanies =
      props.companies.companies.size > 1 ||
      (!props.companies.company && props.companies.companies.size > 0) ||
      props.companyGroups.size > 0
    const hasSearch = props.companies.companies.size >= 10 || isUserSupport(props.user)
    const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)
    return (
      <Menu className={'user-menu' + (hasMultipleCompanies ? ' user-menu-companies' : '')} selectedKeys={[]}>
        {isUserSupport(props.user) && (
          <Menu.Item className="support-user-field">
            <JumpLink to={buildJumpPath()}>Hjælpehægte til denne side</JumpLink>
          </Menu.Item>
        )}
        {hasMultipleCompanies && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES}>
              <Icon type="suitcase" />
              {t('header.all_companies')}
            </Link>
          </Menu.Item>
        )}
        {hasSearch && (
          <Menu.Item>
            <div className="search-field">
              <Icon type="magnifyingGlass" /> <Input onChange={handleSearch} />
            </div>
          </Menu.Item>
        )}
        {hasMultipleCompanies &&
          getCompanies().map((company) => {
            return (
              <Menu.Item key={company.id}>
                <DumbLink
                  onClick={(e) => {
                    e.preventDefault()
                    props.setActiveCompany(company.id)
                    jsBrowserHistory.push('/')
                  }}
                >
                  <UserImage company={company.name} size="xsmall" />
                  {company.name} <span className="cvr">{company.nationalID}</span>
                </DumbLink>
              </Menu.Item>
            )
          })}
        {!onlyDepartments && !hasMultipleCompanies && !!company && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES + '/' + company.id}>
              <Icon type="suitcase" />
              {t('header.company')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const toggleMenuFoldItem = (id: CombinedMainMenuID) => {
    setMenuFold((prev) => {
      if (!prev.includes(id)) {
        return [...prev, id]
      } else {
        return prev.filter((item) => item !== id)
      }
    })
  }

  const expandMenuFoldItem = (id: CombinedMainMenuID) => {
    setMenuFold((prev) => {
      if (!prev.includes(id)) {
        return [...prev, id]
      }
      return prev
    })
  }

  const renderMenu = (items: MenuItem[], id: string, selectedKeys: string[]) => {
    return (
      <Menu selectedKeys={selectedKeys} id={id}>
        {items.map((item) => {
          const showToggle = !!item.children && !item.alwaysShowChildren
          if (item.packageLock) {
            // normally, I would have used PackageLock, but due to how Menu.Item works,
            // we cannot do that.
            if (
              !isPricingPackageGroup(
                pricingPackages.pricingPackages.toArray(),
                companyPricingPackages.companyPricingPackages.toArray(),
                company?.id,
                item.packageLock
              )
            ) {
              return null
            }
          }
          if (item.type === 'item-label' && item.modal) {
            const modal = item.modal
            return (
              <Menu.Item key={item.id}>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    props.addModal(modal.name, { width: modal.width, closable: modal.closable })
                  }}
                >
                  {item.icon}
                  {t(item.labelID)}
                </div>
              </Menu.Item>
            )
          }
          return (
            <Menu.Item key={item.id} className={showToggle ? 'with-menu-toggle' : ''}>
              {item.type === 'item-label' && (
                <Link to={item.link} id={item.id} onClick={showToggle ? () => expandMenuFoldItem(item.id) : undefined}>
                  {item.icon}
                  {t(item.labelID)}
                  {
                    item.id === 'registration-menu-approve-tab' && (
                      <PendingApprovalCount />
                    ) /* Yes, this is a bit of a hack, but it's the only menu item we have like this, so I'm doing it for now */
                  }
                </Link>
              )}
              {showToggle && (
                <span className="menu-toggle" onClick={() => toggleMenuFoldItem(item.id)}>
                  <Icon type="chevronDown" />
                </span>
              )}
              {item.type === 'item-employee-selector' &&
                item.employeeSelector(props.employees.employees.toArray(), menu, ({ employeeID, freelancerID }) => {
                  if (employeeID) {
                    if (props.location.pathname.match('/' + paths.EMPLOYEES + '/[^/]+/?')) {
                      jsBrowserHistory.push(replaceUUIDInURL(props.location.pathname, employeeID))
                    } else {
                      jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employeeID)
                    }
                    setMainMenuEmployee(setMenu, employeeID)
                  }
                  if (freelancerID) {
                    if (props.location.pathname.match('/' + paths.FREELANCERS + '/[^/]+/?')) {
                      jsBrowserHistory.push(replaceUUIDInURL(props.location.pathname, freelancerID))
                    } else {
                      jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + freelancerID)
                    }
                    setMainMenuFreelancer(setMenu, freelancerID)
                  }
                })}
              {showToggle && (
                <input
                  type="checkbox"
                  className="menu-toggle-value"
                  id={`menu-toggle-${item.id}`}
                  checked={menuFold.includes(item.id)}
                  readOnly
                />
              )}
              {item.children && renderMenu(item.children, `menu-${item.id}`, selectedKeys)}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const notifications = getNotifications().length

  return (
    <aside className="menu-aside-container">
      <div className="top-menu">
        <Popover
          placement="bottomRight"
          overlayClassName="user-menu-popover"
          content={renderUserMenu()}
          trigger="click"
        >
          <div className="user-menu-button">
            <UserImage company={company ? company.name : ''} name={props.user.name} size="medium" />
          </div>
        </Popover>
        {!onlyDepartments && (
          <Link to={'/' + paths.NOTIFICATIONS} className="notification-button">
            <Icon type="bell" />
            {notifications > 0 && <span className="notification-badge">{notifications}</span>}
          </Link>
        )}
      </div>
      <div className="main-menu">
        <Link to="/" className={'menu-logo' + (hasMultipleCompanies ? ' multiple' : '')}>
          {hasMultipleCompanies ? <span>{company.name}</span> : <SalaryLogo />}
        </Link>
        {renderMenu(
          getMainMenu(menu),
          'main-menu-top',
          getAllParentsForCurrentPath(props.location.pathname).map((i) => i.id)
        )}
      </div>
      <Menu className="bottom-menu">
        <Menu.Item>
          <Link to={'/' + paths.ACCOUNT}>
            <Icon type="user" />
            {t('header.account')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <DumbLink onClick={handleLogout}>
            <Icon type="key" />
            {t('header.log_out')}
          </DumbLink>
        </Menu.Item>
      </Menu>
    </aside>
  )
}
