import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import DocumentCategory, { DocumentCategoryMutableFields } from '../../model/documentCategory'
import { DocumentCategoryReducer } from '../../reducers/documentCategories'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import LoadingOverlay from '../widgets/LoadingOverlay'
import DocumentCategoryEdit from './DocumentCategoryEdit'

type Props = {
  documentCategories: DocumentCategoryReducer

  addAlert: addAlertSignature
  getDocumentCategories: () => void
  addDocumentCategory: (category: DocumentCategoryMutableFields) => void
  updateDocumentCategory: (category: DocumentCategory) => void
}

export default function DocumentCategoriesTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { documentCategories, getDocumentCategories } = props

  useEffect(() => {
    if (!documentCategories.loading && !documentCategories.loaded) {
      getDocumentCategories()
    }
  }, [documentCategories, getDocumentCategories])

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const previousDocumentCategories = usePrevious(documentCategories)

  useEffect(() => {
    if (previousDocumentCategories && previousDocumentCategories.saving && !documentCategories.saving) {
      if (!documentCategories.error) {
        setEditVisibility(false)
      }
    }
  })

  type DocumentCategoryRow = {
    key: string
    id: string
    name: string
    isActive: boolean
    active: string
  }

  const remove = (documentCategory: DocumentCategoryRow) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, documentCategory.id])
        props.updateDocumentCategory({ ...documentCategory, active: false })
      }
    }
  }
  const reactivate = (documentCategory: DocumentCategoryRow) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, documentCategory.id])
        props.updateDocumentCategory({ ...documentCategory, active: true })
      }
    }
  }

  const columns = [
    { title: t('document_category.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('document_category.table.header.active'), dataIndex: 'active', key: 'active' },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      className: 'company-table-actions',
      render: (documentCategory: DocumentCategoryRow) => {
        if (deleting.indexOf(documentCategory.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('document_category.table.actions.edit')}>
              <span onClick={() => setEditVisibility(documentCategory.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            {documentCategory.isActive && (
              <Tooltip title={t('document_category.table.actions.delete')}>
                <span onClick={remove(documentCategory)} style={{ cursor: 'pointer' }}>
                  <Icon type="xSign" />
                </span>
              </Tooltip>
            )}
            {!documentCategory.isActive && (
              <Tooltip title={t('document_category.table.actions.reactivate')}>
                <span onClick={reactivate(documentCategory)} style={{ cursor: 'pointer' }}>
                  <Icon type="roundedArrows" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getDocumentCategoryRows = (): DocumentCategoryRow[] => {
    return props.documentCategories.documentCategories
      .map((documentCategory): DocumentCategoryRow => {
        return {
          key: documentCategory.id,
          id: documentCategory.id,
          name: documentCategory.name,
          isActive: documentCategory.active,
          active: documentCategory.active
            ? t('document_category.table.active.true')
            : t('document_category.table.active.false'),
        }
      })
      .sort((a, b) => {
        if (a.active === b.active) {
          return a.name.localeCompare(b.name)
        }
        return a.active.localeCompare(b.active)
      })
      .toArray()
  }

  if (!props.documentCategories.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <TitleMenu>
        <Link to={'/' + paths.DOCUMENTS}>
          <Button>{t('document_category.header.documents')}</Button>
        </Link>
        <Button type="primary" onClick={() => setEditVisibility(true)} prefixIcon="plusCircle">
          {t('document_category.header.add_category')}
        </Button>
      </TitleMenu>
      <Title>{t('document_category.header.title')}</Title>

      <Table columns={columns} dataSource={getDocumentCategoryRows()} pagination={false} />

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <DocumentCategoryEdit
          visible={editing !== false}
          documentCategoryID={typeof editing === 'string' ? editing : undefined}
          documentCategories={props.documentCategories}
          addDocumentCategory={props.addDocumentCategory}
          updateDocumentCategory={props.updateDocumentCategory}
        />
      </Modal>
    </Card>
  )
}
