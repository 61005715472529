import React, { ReactElement } from 'react'

import {
  decorateFieldSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { parseInputNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import HelpModal from '../elements/HelpModal'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Switch from '../elements/switch'
import SwitchWrapper from '../form-elements/SwitchWrapper'

type BenefitFields = {
  hasCar: boolean
  hasEasyCar: boolean
  carTaxValue: string
  carValuation: string
  carAcquisitionDate: Date
  carFirstLicensePlateDate: Date
  carYearlyEnvironmentalTax: string
  carNettoAmount: string
  carMajorityShareholder: boolean
  hasInternet: boolean
  hasTelephone: boolean
  hasHealth: boolean
  healthTaxValue: string
  hasLunch: boolean
  hasLunchDaily: boolean
  hasEmployeeAssociation: boolean
  employeeAssociationAmount: string
  lunchNettoAmount: string
  hasBoardLodging: boolean
  boardLodgingTaxValue: string
  hasPermanentResidence: boolean
  permanentResidenceReportingAmount: string
  hasFreeTransport: boolean
  hasTaxReportings: boolean
  hasTaxReportingsNoThreshold: boolean
  hasPayReductions: boolean
  hasEmployeeStock: boolean
  hasFixedDisbursement: boolean
  hasFreeText: boolean
  employeeStockText?: string
  payReductions: {
    title: string
    isGross: 'true' | 'false'
    reduction: string
  }[]
  hasForenedeGruppeliv: boolean
  forenedeGruppelivCode: string
  forenedeGruppelivTaxValue: string
}

type Props<Fields extends BenefitFields> = {
  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
}

export default function BenefitForm<Fields extends BenefitFields>(props: Props<Fields>): ReactElement | null {
  const toggleBenefit = (
    key: keyof Pick<
      Fields,
      | 'hasCar'
      | 'hasEasyCar'
      | 'hasInternet'
      | 'hasTelephone'
      | 'hasHealth'
      | 'hasLunch'
      | 'hasLunchDaily'
      | 'hasEmployeeAssociation'
      | 'hasBoardLodging'
      | 'hasPermanentResidence'
      | 'hasFreeTransport'
      | 'hasTaxReportings'
      | 'hasTaxReportingsNoThreshold'
      | 'hasPayReductions'
      | 'hasEmployeeStock'
      | 'hasFixedDisbursement'
      | 'hasFreeText'
      | 'hasForenedeGruppeliv'
    >
  ) => {
    return (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()

      const { getFieldValue, setFieldValue, setAnyFieldValue } = props
      const val = getFieldValue(key)
      if (!val && getFieldValue('payReductions').length === 0) {
        setAnyFieldValue(`payReductions.0`, { isGross: 'true', withVacation: true, withPension: true })
      }
      setFieldValue(key, !val)
    }
  }

  const { decorateField, getFieldValue } = props

  return (
    <div>
      <Row>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasCar') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasCar')}
          >
            {t('benefit.form.has_car')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasInternet') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasInternet')}
          >
            {t('benefit.form.has_internet')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasTelephone') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasTelephone')}
          >
            {t('benefit.form.has_telephone')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasHealth') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasHealth')}
          >
            {t('benefit.form.has_health')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasLunch') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasLunch')}
          >
            {t('benefit.form.has_lunch')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasBoardLodging') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasBoardLodging')}
          >
            {t('benefit.form.has_board_lodging')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasPermanentResidence') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasPermanentResidence')}
          >
            {t('benefit.form.has_permanent_residence')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasPayReductions') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasPayReductions')}
          >
            {t('benefit.form.has_pay_reductions')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasFixedDisbursement') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasFixedDisbursement')}
          >
            {t('benefit.form.has_fixed_disbursement')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasEmployeeStock') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasEmployeeStock')}
          >
            {t('benefit.form.has_employee_stock')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasTaxReportings') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasTaxReportings')}
          >
            {t('benefit.form.has_tax_reportings')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={'contract-toggle-item' + (getFieldValue('hasFreeText') ? ' contract-toggle-item-active' : '')}
            onClick={toggleBenefit('hasFreeText')}
          >
            {t('benefit.form.has_free_text')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasForenedeGruppeliv') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasForenedeGruppeliv')}
          >
            {t('benefit.form.has_forenede_gruppeliv')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasEmployeeAssociation') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasEmployeeAssociation')}
          >
            {t('benefit.form.has_employee_association')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' + (getFieldValue('hasFreeTransport') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasFreeTransport')}
          >
            {t('benefit.form.has_free_transport')}
          </div>
        </Col>
        <Col span={8}>
          <div
            className={
              'contract-toggle-item' +
              (getFieldValue('hasTaxReportingsNoThreshold') ? ' contract-toggle-item-active' : '')
            }
            onClick={toggleBenefit('hasTaxReportingsNoThreshold')}
          >
            {t('benefit.form.has_tax_reporting_no_threshold')}
          </div>
        </Col>
      </Row>
      {getFieldValue('hasCar') && (
        <Row>
          <Col span={12}>
            <Headline>{t('benefit.form.car.title')}</Headline>
          </Col>
          <Col span={12}>
            <div className="ant-switch-wrapper" style={{ float: 'right' }}>
              {decorateField('hasEasyCar', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('benefit.form.car.has_easy_car')}</span>
            </div>
          </Col>
        </Row>
      )}
      {getFieldValue('hasCar') && (
        <Row>
          {getFieldValue('hasEasyCar') && (
            <>
              <Col span={12}>
                {decorateField('carValuation', {
                  placeholder: t('benefit.form.car.car_valuation'),
                  suffix: t('benefit.form.car.car_valuation.suffix'),
                  validate: (val, allValues) => {
                    if (!allValues.hasCar) {
                      return null
                    }
                    if (!val) {
                      return t('benefit.form.car.car_valuation.required')
                    }
                    if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                      return t('benefit.form.car.car_valuation.invalid')
                    }
                    return null
                  },
                })(<Input />)}
              </Col>
              <Col span={12}>
                {decorateField('carAcquisitionDate', {
                  placeholder: t('benefit.form.car.car_acquisition_date'),
                  validate: (val) => {
                    if (!val) {
                      return t('benefit.form.car.car_acquisition_date.required')
                    }
                    return null
                  },
                })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
              </Col>
              <Col span={12}>
                {decorateField('carFirstLicensePlateDate', {
                  placeholder: t('benefit.form.car.car_first_license_plate_date'),
                  validate: (val) => {
                    if (!val) {
                      return t('benefit.form.car.car_first_license_plate_date.required')
                    }
                    return null
                  },
                })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
              </Col>
              <Col span={12}>
                {decorateField('carYearlyEnvironmentalTax', {
                  placeholder: t('benefit.form.car.car_yearly_environmental_tax'),
                  suffix: t('benefit.form.car.car_yearly_environmental_tax.suffix'),
                  validate: (val, allValues) => {
                    if (!allValues.hasCar) {
                      return null
                    }
                    // eslint-disable-next-line eqeqeq
                    if (!val && val != '0') {
                      return t('benefit.form.car.car_yearly_environmental_tax.required')
                    }
                    if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                      return t('benefit.form.car.car_yearly_environmental_tax.invalid')
                    }
                    return null
                  },
                })(<Input />)}
              </Col>
              <Col span={12}>
                {decorateField('carNettoAmount', {
                  placeholder: t('benefit.form.car.car_netto_amount'),
                  suffix: t('benefit.form.car.car_netto_amount.suffix'),
                  validate: (val) => {
                    if (!val) {
                      return null
                    }
                    if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                      return t('benefit.form.car.car_netto_amount.invalid')
                    }
                    return null
                  },
                })(<Input />)}
              </Col>
            </>
          )}
          {!getFieldValue('hasEasyCar') && (
            <Col span={12}>
              {decorateField('carTaxValue', {
                placeholder: t('benefit.form.car.car_tax_value'),
                suffix: t('benefit.form.car.car_tax_value.suffix'),
                validate: (val, allValues) => {
                  if (!allValues.hasCar) {
                    return null
                  }
                  if (!val) {
                    return t('benefit.form.car.car_tax_value.required')
                  }
                  if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                    return t('benefit.form.car.car_tax_value.invalid')
                  }
                  return null
                },
              })(<Input />)}
              <span className="ant-form-extra">{t('benefit.form.car.car_tax_value.warning')}</span>
            </Col>
          )}
          <Col span={12}>
            <SwitchWrapper<Fields>
              id={'carMajorityShareholder'}
              decorateField={decorateField}
              style={{ marginTop: '25px' }}
            >
              {t('benefit.form.car.car_majority_shareholder')}
              <HelpModal>{t('benefit.form.car.car_majority_shareholder.help_text')}</HelpModal>
            </SwitchWrapper>
          </Col>
        </Row>
      )}
      {getFieldValue('hasForenedeGruppeliv') && (
        <Row>
          <Col span={12}>
            <Headline>{t('benefit.form.forenede_gruppeliv.header.code')}</Headline>
          </Col>
          <Col span={12}>
            <Headline>{t('benefit.form.forenede_gruppeliv.header.tax_value')}</Headline>
          </Col>
        </Row>
      )}
      {getFieldValue('hasForenedeGruppeliv') && (
        <Row>
          <Col span={12}>
            {decorateField('forenedeGruppelivCode', {
              placeholder: t('benefit.form.forenede_gruppeliv.code'),
              validate: (val, allValues) => {
                if (!allValues.hasForenedeGruppeliv) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.forenede_gruppeliv.code.required')
                }
                return null
              },
            })(<Input />)}
          </Col>
          <Col span={12}>
            {decorateField('forenedeGruppelivTaxValue', {
              placeholder: t('benefit.form.forenede_gruppeliv.tax_value'),
              suffix: t('benefit.form.forenede_gruppeliv.tax_value.suffix'),
              validate: (val, allValues) => {
                if (!allValues.hasForenedeGruppeliv) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.forenede_gruppeliv.tax_value.required')
                }
                if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) <= 0) {
                  return t('benefit.form.forenede_gruppeliv.tax_value.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {getFieldValue('hasEmployeeAssociation') && (
        <Row>
          <Col span={24}>
            {decorateField('employeeAssociationAmount', {
              placeholder: t('benefit.form.employee_association.amount'),
              suffix: t('benefit.form.employee_association.amount.suffix'),
              validate: (val, allValues) => {
                if (!allValues.hasEmployeeAssociation) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.employee_association.amount.required')
                }
                if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                  return t('benefit.form.employee_association.amount.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {(getFieldValue('hasHealth') || getFieldValue('hasLunch')) && (
        <Row>
          {getFieldValue('hasHealth') && (
            <Col span={12}>
              <Headline>{t('benefit.form.health.header.health')}</Headline>
            </Col>
          )}
          {getFieldValue('hasLunch') && (
            <Col span={12}>
              <Headline>{t('benefit.form.lunch.header.lunch')}</Headline>
            </Col>
          )}
        </Row>
      )}
      {(getFieldValue('hasHealth') || getFieldValue('hasLunch')) && (
        <Row>
          {getFieldValue('hasHealth') && (
            <Col span={12}>
              {decorateField('healthTaxValue', {
                placeholder: t('benefit.form.health.tax_value'),
                suffix: t('benefit.form.health.tax_value.suffix'),
                validate: (val, allValues) => {
                  if (!allValues.hasHealth) {
                    return null
                  }
                  if (!val) {
                    return t('benefit.form.health.tax_value.required')
                  }
                  if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) <= 0) {
                    return t('benefit.form.health.tax_value.invalid')
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          )}
          {getFieldValue('hasLunch') && (
            <Col span={12}>
              {decorateField('lunchNettoAmount', {
                placeholder: t('benefit.form.lunch.netto_amount'),
                suffix: getFieldValue('hasLunchDaily')
                  ? t('benefit.form.lunch.netto_amount.suffix.daily')
                  : t('benefit.form.lunch.netto_amount.suffix.standard'),
                validate: (val, allValues) => {
                  if (!allValues.hasLunch) {
                    return null
                  }
                  if (!val) {
                    return t('benefit.form.lunch.netto_amount.required')
                  }
                  if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
                    return t('benefit.form.lunch.netto_amount.invalid')
                  }
                  return null
                },
              })(<Input />)}
              <div style={{ marginTop: -10 }}>
                {decorateField('hasLunchDaily', {
                  skipLabel: true,
                })(
                  <Radio.Group>
                    <Radio value={false}>{t('benefit.form.lunch.has_lunch_daily.false')}</Radio>
                    <Radio value={true}>{t('benefit.form.lunch.has_lunch_daily.true')}</Radio>
                  </Radio.Group>
                )}
              </div>
            </Col>
          )}
        </Row>
      )}
      {getFieldValue('hasBoardLodging') && (
        <Row>
          <Col span={24}>
            <Headline>{t('benefit.form.board_lodging.title')}</Headline>
          </Col>
          <Col span={24}>
            <p>{t('benefit.form.board_lodging.intro.line_1')}</p>
            <p>{t('benefit.form.board_lodging.intro.line_2')}</p>
            <p>
              {tx('benefit.form.board_lodging.intro.line_3', {
                link: (
                  <a href="https://skat.dk/skat.aspx?oid=2234877" target="_blank" rel="noopener noreferrer">
                    {t('benefit.form.board_lodging.intro.line_3.link')}
                  </a>
                ),
              })}
            </p>
            <p>{t('benefit.form.board_lodging.intro.line_4')}</p>
          </Col>
        </Row>
      )}
      {getFieldValue('hasBoardLodging') && (
        <Row>
          <Col span={12}>
            {decorateField('boardLodgingTaxValue', {
              placeholder: t('benefit.form.board_lodging.lodging_tax_value'),
              suffix: t('benefit.form.board_lodging.lodging_tax_value.suffix'),
              validate: (val, allValues) => {
                if (!allValues.hasBoardLodging) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.board_lodging.lodging_tax_value.required')
                }
                if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) <= 0) {
                  return t('benefit.form.board_lodging.lodging_tax_value.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {getFieldValue('hasPermanentResidence') && (
        <Row>
          <Col span={24}>
            <Headline>{t('benefit.form.permanent_residence.title')}</Headline>
          </Col>
          <Col span={24}>
            <p>{t('benefit.form.permanent_residence.intro.line_1')}</p>
            <p>{t('benefit.form.permanent_residence.intro.line_2')}</p>
          </Col>
        </Row>
      )}
      {getFieldValue('hasPermanentResidence') && (
        <Row>
          <Col span={12}>
            {decorateField('permanentResidenceReportingAmount', {
              placeholder: t('benefit.form.permanent_residence.reporting_amount'),
              suffix: t('benefit.form.permanent_residence.reporting_amount.suffix'),
              validate: (val, allValues) => {
                if (!allValues.hasPermanentResidence) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.permanent_residence.reporting_amount.required')
                }
                if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) <= 0) {
                  return t('benefit.form.permanent_residence.reporting_amount.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {getFieldValue('hasEmployeeStock') && (
        <Row>
          <Col span={16}>
            {decorateField('employeeStockText', {
              placeholder: t('benefit.form.employee_stock.stock_text'),
              validate: (val, allValues) => {
                if (!allValues.hasEmployeeStock) {
                  return null
                }
                if (!val) {
                  return t('benefit.form.employee_stock.stock_text.required')
                }
                if (val.length > 58) {
                  return t('benefit.form.employee_stock.stock_text.max_58_characters')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
    </div>
  )
}
