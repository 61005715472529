import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { browserHistory, Router } from 'react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import thunk from 'redux-thunk'

import 'react-app-polyfill/ie11'

import { handleMessage } from './actions/socket'
import LocaleProvider from './components/antd/locale-provider'
import rootReducer from './reducers'
import { UserParams } from './reducers/user'
import routes from './routes'
import { getAccessToken } from './utils/cookie-utils'
import { getTranslationAntdStrings } from './utils/language-utils'
import { listen, subscribe, unsubscribe } from './utils/socket-utils'

Number.isInteger =
  Number.isInteger ||
  function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  }

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    release: process.env.REACT_APP_RELEASE,
    ignoreErrors: [
      '@webkit-masked-url',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'DIRECTIONS_ROUTE',
      'safari-web-extension',
    ],
    beforeSend(event, hint) {
      const error = hint?.originalException
      if (error && error.message) {
        switch (error.message) {
          case 'annulleret': // Request cancelled by user, nothing to do
          case 'AbortError: The operation was aborted.': // Request cancelled by user, nothing to do
          case 'can\'t redefine non-configurable property "userAgent"': // Error in third party script
          case 'cancelled': // Request cancelled by user, nothing to do
          case 'Failed to fetch': // Request failure sometimes happens, nothing to do
          case 'Failed to load Stripe.js': // Request failure sometimes happens, out of our control
          case 'Netværksforbindelsen gik tabt.': // Request failure sometimes happens, out of our control
          case 'NetworkError when attempting to fetch resource.': // Request failure sometimes happens, out of our control
          case 'Load failed': // Request failure sometimes happens, nothing to do
            return null
          default: {
            if (error.message.match(/^DIRECTIONS_ROUTE: NOT_FOUND:/)) {
              // Origin/destination not found on Google Maps, nothing to do
              return null
            }
            if (error.message.match(/^DIRECTIONS_ROUTE: ZERO_RESULTS:/)) {
              // Origin/destination not found on Google Maps, nothing to do
              return null
            }
            if (error.message.match(/safari-web-extension/)) {
              // A Safari extension failing, ignore it
              return null
            }
          }
        }
      }
      return event
    },
  })
}

const onUpdate = () => {
  // Ensure any popovers/tooltips are closed
  try {
    document.dispatchEvent(new MouseEvent('mousedown'))
  } catch (e) {
    // pass
  }
}

browserHistory.listen((location) => {
  // Scroll to top
  if (!location.state || !location.state.preventScrollToTop) {
    window.scrollTo(0, 0)
  }
})

let timeout = null
const enhancer = compose(
  applyMiddleware(thunk),
  batchedSubscribe((notify) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      notify()
    }, 50)
  })
)
const store = createStore(rootReducer, { user: UserParams({ loggedIn: !!getAccessToken() }) }, enhancer)

store.subscribe(() => {
  subscribe(store.getState())
  unsubscribe(store.getState())
})
listen(function (data) {
  handleMessage(store, data)
})

ReactDOM.render(
  <Provider store={store} key="reduxProvider">
    <LocaleProvider locale={getTranslationAntdStrings()}>
      <Router onUpdate={onUpdate} history={browserHistory} routes={routes} />
    </LocaleProvider>
  </Provider>,
  document.getElementById('root')
)
