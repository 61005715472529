import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { resendPaySlip } from '../../api/pay-slips'
import paths from '../../constants/paths'
import PDFPreviewTypes from '../../constants/pdf-preview-types'
import Department from '../../model/department'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import PaySlip from '../../model/paySlip'
import { formatError } from '../../utils/error-utils'
import { formatCurrency } from '../../utils/number-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { secureUrl } from '../../utils/request-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Subtitle from '../elements/Subtitle'
import Table from '../elements/table'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PayrollCardToggle from './PayrollCardToggle'

type Props = {
  payRoll: PayRoll
  employeesMap: Record<string, Employee>
  departments: List<Department>
  paySlips: List<PaySlip>
}

export default function PaySlipsCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)
  const [resentPaySlips, setResentPaySlips] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const resend = (paySlipID: string) => {
    if (resentPaySlips.some((id) => id === paySlipID)) {
      return
    }
    setResentPaySlips((prev) => {
      prev.push(paySlipID)
      return prev
    })
    setLoading(true)
    resendPaySlip(paySlipID)
      .catch(() => {
        setError(new Error(t('pay_roll.single.pay_slips.error.cannot_resend')))
      })
      .finally(() => setLoading(false))
  }

  const canResend = convertPayRollStatus(props.payRoll) === 'Success'

  type PaySlipRow = {
    key: string
    id: string
    grossPay: string
    netPay: string
    name: string
    employeeID?: string
    profileImageURL?: string
    isFreelancer: boolean
    department?: string
    shouldResend: boolean
    canResend: boolean
  }

  const columns = [
    {
      title: t('pay_roll.single.pay_slips.header.name'),
      dataIndex: '',
      key: 'xName',
      render: (paySlip: PaySlipRow) => (
        <span>
          <UserImage src={paySlip.profileImageURL} name={paySlip.name} />
          {paySlip.name}
        </span>
      ),
    },
    { title: t('pay_roll.single.pay_slips.header.department'), dataIndex: 'department', key: 'department' },
    { title: t('pay_roll.single.pay_slips.header.gross_pay'), dataIndex: 'grossPay', key: 'grossPay' },
    { title: t('pay_roll.single.pay_slips.header.net_pay'), dataIndex: 'netPay', key: 'netPay' },
    {
      title: '',
      dataIndex: '',
      key: 'x4',
      className: 'pay-roll-table-actions',
      render: (paySlip: PaySlipRow) => (
        <span>
          <Link
            to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + paySlip.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip placement="top" title={t('pay_roll.single.pay_slips.actions.download_payslip')}>
              <Icon type="arrowDownIntoTray" />
            </Tooltip>
          </Link>
          {paySlip.shouldResend && paySlip.canResend && (
            <DumbLink onClick={() => resend(paySlip.id)}>
              <Tooltip placement="top" title={t('pay_roll.single.pay_slips.actions.resend')}>
                <Icon type="roundedArrows" />
              </Tooltip>
            </DumbLink>
          )}
          {paySlip.shouldResend && !paySlip.canResend && (
            <span className="fake-a disabled">
              <Icon type="roundedArrows" />
            </span>
          )}
          <Link
            to={
              '/' +
              (paySlip.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              paySlip.employeeID +
              (paySlip.isFreelancer ? '/profile' : '/employment')
            }
          >
            <Tooltip placement="top" title={t('pay_roll.single.pay_slips.actions.edit')}>
              <Icon type="paperWithPencil" />
            </Tooltip>
          </Link>
        </span>
      ),
    },
  ]

  const getPaySlips = (): PaySlipRow[] => {
    return props.paySlips
      .map((paySlip) => {
        const combinedPaySlip: PaySlipRow = {
          key: paySlip.id,
          id: paySlip.id,
          grossPay: '-',
          netPay: formatCurrency(paySlip.payCheck, 2),
          name: '-', // so there is always something to sort by
          isFreelancer: false,
          shouldResend: false,
          canResend: false,
        }
        const employee = props.employeesMap[paySlip.employeeID]
        if (employee) {
          combinedPaySlip.employeeID = employee.id
          combinedPaySlip.name = employee.name || employee.email || '-'
          combinedPaySlip.profileImageURL = employee.profileImageURL
          combinedPaySlip.isFreelancer = employee.affiliationType === 'Freelancer'
          combinedPaySlip.shouldResend = canResend && !!employee.email
          combinedPaySlip.canResend = combinedPaySlip.shouldResend && !resentPaySlips.some((id) => id === paySlip.id)
          if (employee.departmentID) {
            combinedPaySlip.department = props.departments.find(
              (department) => department.id === employee.departmentID
            )?.name
          }
        }
        if (paySlip.calculations) {
          const salary = paySlip.calculations.find((calculation) => calculation.type === 'Salary')
          if (salary) {
            combinedPaySlip.grossPay = formatCurrency(salary.result, 2)
          }
        }
        return combinedPaySlip
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .toArray()
  }

  const paySlips = getPaySlips()
  return (
    <Card>
      {loading && <LoadingOverlay />}
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.pay_slips.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {tx('pay_roll.single.pay_slips.total', {
            total: <strong>{formatCurrency(props.payRoll.totalPaycheck, 2)}</strong>,
          })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <PayrollCardToggle expanded={expanded} onChange={(newState) => setExpanded(newState)} />
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        {paySlips.length > 0 && (
          <Row className="pay-roll-card-buttons">
            <Col span={24} style={{ textAlign: 'right' }}>
              <div>
                <Link
                  to={secureUrl('/v2/paySlipsZip?payRollID=' + props.payRoll.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button prefixIcon="arrowDownIntoTray">{t('pay_roll.single.pay_slips.download_all_zip')}</Button>
                </Link>
                <Link
                  to={secureUrl('/v2/paySlipsPDF?payRollID=' + props.payRoll.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button prefixIcon="arrowDownIntoTray">{t('pay_roll.single.pay_slips.download_all_pdf')}</Button>
                </Link>
              </div>
            </Col>
          </Row>
        )}

        <Table
          columns={columns}
          dataSource={paySlips}
          pagination={paySlips.length > 100 ? { defaultPageSize: 100 } : false}
          className={'pay-roll-table'}
        />
      </div>
    </Card>
  )
}
