import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import ContractDelta from '../../model/contractDelta'
import ContractTemplate, { ContractTemplateMutableFields } from '../../model/contractTemplate'
import ContractTemplateContract, { ContractTemplateContractMutableFields } from '../../model/contractTemplateContract'
import Department from '../../model/department'
import Employee from '../../model/employee'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import Remuneration from '../../model/remuneration'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { AlertReducer } from '../../reducers/alerts'
import { ContractTemplateContractReducer } from '../../reducers/contractTemplateContracts'
import { ContractTemplateReducer } from '../../reducers/contractTemplates'
import { EmployeeContractDeltaReducer } from '../../reducers/employeeContractDeltas'
import IncomeType from '../../types/income-type'
import Language from '../../types/language'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { getEmployeePayType, translatePayTypeToEmploymentType } from '../../utils/employee-utils'
import { formatError } from '../../utils/error-utils'
import { formatEmployeePayType } from '../../utils/format-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Input from '../elements/input'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import UserImage from '../elements/UserImage'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ContractTemplateContractSelectForm, { ContractSelectResult } from './ContractTemplateContractSelectForm'
import ContractTemplateForm, { ContractTemplateResult } from './ContractTemplateForm'

type Props = {
  alerts: AlertReducer
  company: Company
  contractTemplates: ContractTemplateReducer
  contractTemplateContracts: ContractTemplateContractReducer
  employees: List<Employee>
  employeeContractDeltas: EmployeeContractDeltaReducer
  employmentPositions: List<EmploymentPosition>
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  pensionCompanies: List<PensionCompany>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (contractID: string) => void
  getContractDeltas: (employeeID: string) => Promise<ContractDelta[] | void>
  createContractTemplate: (contractTemplate: ContractTemplateMutableFields) => Promise<ContractTemplate | void>
  createContractTemplateContract: (
    contractTemplateContract: ContractTemplateContractMutableFields
  ) => Promise<ContractTemplateContract | void>
}

export default function ContractTemplateAdd(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [current, setCurrent] = useState(0)
  const [employeeID, setEmployeeID] = useState<string>()
  const [contractIDs, setContractIDs] = useState<string[]>([])
  const [inputSearch, setInputSearch] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearch), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(e.currentTarget.value.trim())
  }

  const { contractTemplateContracts, addAlert } = props
  const previousContractTemplateContracts = usePrevious(contractTemplateContracts)

  useEffect(() => {
    if (
      previousContractTemplateContracts &&
      previousContractTemplateContracts.saving &&
      !contractTemplateContracts.saving
    ) {
      // Check for no error occurred
      if (!contractTemplateContracts.error) {
        addAlert('success', t('contract_template.alert.success'), { timeout: 5 })

        jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
      }
    }
  })

  const previousEmployeeID = usePrevious(employeeID)
  const { employees, getRemuneration, employeeContractDeltas, getContractDeltas } = props

  useEffect(() => {
    if ((!previousEmployeeID && employeeID) || previousEmployeeID !== employeeID) {
      const employee = employees.find((employee) => employee.id === employeeID)
      if (employee && employee.activeContract && !employee.activeContract.remuneration) {
        getRemuneration(employee.activeContract.id)
      }
      if (
        employee &&
        (employeeContractDeltas.employeeID !== employee.id ||
          !employeeContractDeltas.loaded ||
          !employeeContractDeltas.loading ||
          employeeContractDeltas.dirty)
      ) {
        getContractDeltas(employee.id).then((deltas) => {
          if (deltas && deltas.length === 1) {
            setCurrent((prev) => prev + 1)
            setContractIDs([deltas[0].contractID])
          }
        })
      }
    }
  })

  const { contractTemplates } = props

  useEffect(() => {
    regularComponentDidUpdate(contractTemplates.error || contractTemplateContracts.error, error, setError)
  }, [contractTemplates, contractTemplateContracts, error])

  const selectEmployee = (employeeID: string) => {
    setCurrent((prev) => prev + 1)
    setEmployeeID(employeeID)
    window.scrollTo(0, 0)
  }

  const handlePrevious = () => {
    switch (current) {
      case 1:
        setCurrent(0)
        setEmployeeID(undefined)
        setContractIDs([])
        break
      case 2:
        setCurrent(1)
        setContractIDs([])
        break
    }
  }

  const handleSelectContract = (values: ContractSelectResult) => {
    setContractIDs(
      props.employeeContractDeltas.contracts.reduce((list: string[], delta) => {
        // we expect these to be sorted chronologically;
        // so when we have found one, we know we need all the others
        if (list.length > 0) {
          return [...list, delta.contractID]
        }
        if (delta.contractID === values.contractID) {
          return [delta.contractID]
        }
        return list
      }, [])
    )
    setCurrent((prev) => prev + 1)
  }

  const getFilteredDeltaContracts = () => {
    return props.employeeContractDeltas.contracts.filter((delta) => contractIDs.indexOf(delta.contractID) > -1)
  }

  const handleSubmit = (values: ContractTemplateResult) => {
    const employee = props.employees.find((employee) => employee.id === employeeID)
    if (!employee) {
      return
    }
    const contracts = getFilteredDeltaContracts()
    const contract = contracts.first()?.contract
    if (!contract) {
      return
    }
    const remuneration = (contract && contract.remuneration) || {
      benefits: [],
      leave: [],
      pension: [],
      supplements: [],
      salary: [],
    }
    const employeeType = translatePayTypeToEmploymentType(getEmployeePayType(contract))
    const contractTemplate: ContractTemplateMutableFields = {
      companyID: props.company.id,
      title: values.title,
      description: values.description,
      version: '1.0',
      employeeType: employeeType,
      affiliationType: employee.affiliationType,
      paySlipTransportSMS: employee.paySlipTransportSMS,
      paySlipTransportMitDK: employee.paySlipTransportMitDK,
      paySlipTransportEBoks: employee.paySlipTransportEBoks,
      paySlipTransportEMail: employee.paySlipTransportEMail,
      transferDestinationType: employee.transferDestinationType,
      language: employee.language || Language.DANISH,
      preferredTaxCardType: employee.activeEmployment?.preferredTaxCardType || PreferredTaxCardType.MAIN_CARD,
      incomeType: employee.activeEmployment?.incomeType || IncomeType.DK_SALARY_INCOME,
      departmentID: employee.departmentID,
      public: false,
      active: true,
    }
    const remunerationTemplate: Remuneration = {
      benefits: remuneration.benefits,
      leave: remuneration.leave,
      pension: remuneration.pension.map((pension) => ({
        ...pension,
        // ensure these fields are nulled, as they must be unique per employee
        pensionCustomerID: undefined,
        destinationSortCode: undefined,
        destinationAccount: undefined,
      })),
      supplements: remuneration.supplements,
      salary: [],
    }
    values.salary.forEach((set, i) => {
      if (set) {
        const salary = remuneration.salary[i]
        if (salary) {
          remunerationTemplate.salary.push(salary)
        }
      }
    })

    type TemporaryContractTemplateContract = Omit<ContractTemplateContractMutableFields, 'contractTemplateID'>
    const templateContracts: TemporaryContractTemplateContract[] = [
      {
        carAllowanceRegistrationMethodType: contract.carAllowanceRegistrationMethodType,
        timeRegistrationMethodType: contract.timeRegistrationMethodType,
        salaryRegistrationMethodType: contract.salaryRegistrationMethodType,
        employmentPositionID: contract.employmentPositionID,
        position: contract.position,
        salaryCycleID: contract.salaryCycleID,
        workCycle: contract.workCycle ?? [],
        workCycleHours: contract.workCycleHours,
        workCycleAnchorDate: contract.workCycleAnchorDate,
        dayLaborer: contract.dayLaborer,
        remuneration: remunerationTemplate,
        order: 1,
      },
    ]

    values.futureContract.forEach((futureChange, i) => {
      if (i === 0) {
        return
      }
      if (futureChange.included) {
        const futureContract = contracts.get(i)?.contract
        if (!futureContract) {
          return
        }
        const futureRemuneration = futureContract.remuneration
        if (!futureRemuneration) {
          return
        }
        const futureRemunerationTemplate = {
          benefits: futureRemuneration.benefits,
          leave: futureRemuneration.leave,
          pension: futureRemuneration.pension.map((pension) => ({
            ...pension,
            // ensure these fields are nulled, as they must be unique per employee
            pensionCustomerID: undefined,
            destinationSortCode: undefined,
            destinationAccount: undefined,
          })),
          supplements: futureRemuneration.supplements,
          salary: futureRemuneration.salary.filter((futureSalary) =>
            remunerationTemplate.salary.some((baseSalary) => baseSalary.salaryTypeID === futureSalary.salaryTypeID)
          ),
        }
        templateContracts.push({
          carAllowanceRegistrationMethodType: futureContract.carAllowanceRegistrationMethodType,
          timeRegistrationMethodType: futureContract.timeRegistrationMethodType,
          salaryRegistrationMethodType: contract.salaryRegistrationMethodType,
          employmentPositionID: futureContract.employmentPositionID,
          position: futureContract.position,
          salaryCycleID: contract.salaryCycleID, // always the same as base contract
          workCycle: futureContract.workCycle ?? [],
          workCycleHours: futureContract.workCycleHours,
          workCycleAnchorDate: futureContract.workCycleAnchorDate,
          dayLaborer: futureContract.dayLaborer,
          remuneration: futureRemunerationTemplate,
          validFrom: futureChange.validFrom,
          order: i + 1,
        })
      }
    })

    props.createContractTemplate(contractTemplate).then((res) => {
      if (res) {
        let allSuccess = true
        templateContracts.forEach((templateContract) => {
          let success = false
          props.createContractTemplateContract({ ...templateContract, contractTemplateID: res.id }).then((res) => {
            if (res) {
              success = true
            }
          })
          if (!success) {
            allSuccess = false
          }
        })
        if (allSuccess) {
          jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
        }
      }
    })
  }

  const getEmployees = () => {
    return props.employees
      .filter((employee) => {
        if (
          !employee.activeEmployment ||
          !employee.earliestMutableContract ||
          employee.affiliationType === 'Freelancer'
        ) {
          return false
        }
        if (searchQuery) {
          const pattern = new RegExp(searchQuery, 'i')
          return !(!pattern.test(employee.name ?? '') && (!employee.nationalID || !pattern.test(employee.nationalID)))
        }
        return true
      })
      .map((employee) => {
        let position = employee.activeContract?.position
        if (employee.activeContract?.employmentPositionID) {
          const employmentPosition = props.employmentPositions.find(
            (position) => position.id === employee.activeContract?.employmentPositionID
          )
          if (employmentPosition) {
            position = employmentPosition.title
          }
        }
        return { ...employee, position }
      })
  }

  let employee
  if (employeeID) {
    employee = props.employees.find((employee) => employee.id === employeeID)
  }
  if (employee && (props.employeeContractDeltas.employeeID !== employee.id || !props.employeeContractDeltas.loaded)) {
    return <LoadingOverlay />
  }
  let contract
  if (employee) {
    contract = getFilteredDeltaContracts().first()?.contract
  }

  return (
    <div className="contract-template-add">
      <Row>
        <Col span={16}>
          {error && <Alert message={formatError(error)} type="error" showIcon />}
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          <div className="contract-template-add-overview">
            {current === 0 && (
              <Card className="contract-template-add-employees">
                <Blocktitle>{t('contract_template.add.step.0.title')}</Blocktitle>
                <p>{t('contract_template.add.step.0.intro')}</p>
                <Input.Search value={searchQuery} placeholder={t('employee_filter.search')} onChange={handleSearch} />
                <p>&nbsp;</p>
                {getEmployees().map((employee) => {
                  return (
                    <Row key={employee.id}>
                      <Col span={14}>
                        <Headline>
                          <UserImage src={employee.profileImageURL} name={employee.name} />
                          {employee.name}
                          <small>{employee.position ? employee.position : '-'}</small>
                        </Headline>
                      </Col>
                      <Col span={8}>
                        <div className="employees-type">
                          {formatEmployeePayType(getEmployeePayType(employee.earliestMutableContract))}
                        </div>
                      </Col>
                      <Col span={2}>
                        <Button style={{ float: 'right' }} type="secondary" onClick={() => selectEmployee(employee.id)}>
                          {t('contract_template.add.step.0.employee_select')}
                        </Button>
                      </Col>
                    </Row>
                  )
                })}
                <Link to={'/' + paths.EMPLOYEE_TEMPLATES}>
                  <Button>{t('contract_template.add.step.0.buttons.back')}</Button>
                </Link>
              </Card>
            )}
            {current === 1 && (
              <Card>
                <Blocktitle>{t('contract_template.add.step.1.title')}</Blocktitle>
                <p>{t('contract_template.add.step.1.intro')}</p>
                <ContractTemplateContractSelectForm
                  contractDeltas={props.employeeContractDeltas.contracts}
                  handlePrevious={handlePrevious}
                  onSubmit={handleSelectContract}
                />
              </Card>
            )}
            {current === 2 && (
              <Card>
                <Blocktitle>{t('contract_template.add.step.2.title')}</Blocktitle>
                <p>
                  {tx('contract_template.add.step.2.intro.line_1', {
                    link: (
                      <Link to={'/' + paths.EMPLOYEES + '/' + employeeID + '/employment'}>
                        {t('contract_template.add.step.2.intro.line_1.link')}
                      </Link>
                    ),
                  })}
                </p>
                <p>{t('contract_template.add.step.2.intro.line_2')}</p>
                {employee && (
                  <ContractTemplateForm
                    creating={true}
                    contractTemplate={{
                      employeeType: translatePayTypeToEmploymentType(getEmployeePayType(contract)),
                      affiliationType: employee.affiliationType,
                      paySlipTransportSMS: employee.paySlipTransportSMS,
                      paySlipTransportMitDK: employee.paySlipTransportMitDK,
                      paySlipTransportEBoks: employee.paySlipTransportEBoks,
                      paySlipTransportEMail: employee.paySlipTransportEMail,
                      transferDestinationType: employee.transferDestinationType,
                      language: employee.language || Language.DANISH,
                      preferredTaxCardType:
                        employee.activeEmployment?.preferredTaxCardType || PreferredTaxCardType.MAIN_CARD,
                      incomeType: employee.activeEmployment?.incomeType || IncomeType.DK_SALARY_INCOME,
                      departmentID: employee.departmentID,
                    }}
                    contractTemplateContracts={getFilteredDeltaContracts()}
                    employmentPositions={props.employmentPositions}
                    departments={props.departments}
                    salaryCycles={props.salaryCycles}
                    salaryTypes={props.salaryTypes}
                    pensionCompanies={props.pensionCompanies}
                    handlePrevious={handlePrevious}
                    onSubmit={handleSubmit}
                  />
                )}
              </Card>
            )}
          </div>
        </Col>
        <Col span={7}>
          <Card>
            <Subtitle>{t('contract_template.add.steps.title')}</Subtitle>
            <p>{t('contract_template.add.steps.intro')}</p>

            <Steps direction="vertical" current={current}>
              <Steps.Step title={t('contract_template.add.steps.0')} />
              <Steps.Step title={t('contract_template.add.steps.1')} />
              <Steps.Step title={t('contract_template.add.steps.2')} />
            </Steps>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
