import React, { ReactElement } from 'react'

import { CompanyDeviationConfigurationMutableFields } from '../../model/companyDeviationConfiguration'
import { CompanyDeviationConfigurationReducer } from '../../reducers/companyDeviationConfigurations'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatDeviationType } from '../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer
}

type Fields = {
  hoursID?: string
  hoursEnabled: boolean
  hoursThreshold: string
  hoursPeriods: string
  grossPayID?: string
  grossPayEnabled: boolean
  grossPayThreshold: string
  grossPayPeriods: string
  payCheckID?: string
  payCheckEnabled: boolean
  payCheckThreshold: string
  payCheckPeriods: string
  taxCardID?: string
  taxCardEnabled: boolean
  grossPayReductionID?: string
  grossPayReductionEnabled: boolean
}

export type DeviationResult = {
  companyDeviationConfigurations: CompanyDeviationConfigurationMutableFields[]
}

function DeviationsEditForm(props: Props & FormComponentProps<Fields, DeviationResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      <Row>
        <Col span={6}>{t('deviation.edit.form.header.type')}</Col>
        <Col span={2}>{t('deviation.edit.form.header.enable')}</Col>
        <Col span={16}>{t('deviation.edit.form.header.settings')}</Col>
      </Row>
      <Row>
        <Col span={6}>
          <strong>{formatDeviationType('Hours')}</strong>
          <p>{t('deviation.edit.form.hours.intro')}</p>
        </Col>
        <Col span={2}>
          {decorateField('hoursEnabled', {
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
        {getFieldValue('hoursEnabled') && (
          <Col span={16}>
            <Subcard>
              <Row>
                <Col span={10}>
                  {decorateField('hoursThreshold', {
                    placeholder: t('deviation.edit.form.hours.threshold'),
                    suffix: '%',
                  })(<Input />)}
                </Col>
                <Col span={10}>
                  {decorateField('hoursPeriods', {
                    placeholder: t('deviation.edit.form.hours.periods'),
                    suffix: t('deviation.edit.form.hours.periods.suffix'),
                  })(<Input />)}
                </Col>
              </Row>
              <Row>
                <p>{t('deviation.edit.form.hours.description')}</p>
              </Row>
            </Subcard>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={6}>
          <strong>{formatDeviationType('Gross Pay')}</strong>
          <p>{t('deviation.edit.form.gross_pay.intro')}</p>
        </Col>
        <Col span={2}>
          {decorateField('grossPayEnabled', {
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
        {getFieldValue('grossPayEnabled') && (
          <Col span={16}>
            <Subcard>
              <Row>
                <Col span={10}>
                  {decorateField('grossPayThreshold', {
                    placeholder: t('deviation.edit.form.gross_pay.threshold'),
                    suffix: '%',
                  })(<Input />)}
                </Col>
                <Col span={10}>
                  {decorateField('grossPayPeriods', {
                    placeholder: t('deviation.edit.form.gross_pay.periods'),
                    suffix: t('deviation.edit.form.gross_pay.periods.suffix'),
                  })(<Input />)}
                </Col>
              </Row>
              <Row>
                <p>{t('deviation.edit.form.gross_pay.description')}</p>
              </Row>
            </Subcard>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={6}>
          <strong>{formatDeviationType('Pay Check')}</strong>
          <p>{t('deviation.edit.form.pay_check.intro')}</p>
        </Col>
        <Col span={2}>
          {decorateField('payCheckEnabled', {
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
        {getFieldValue('payCheckEnabled') && (
          <Col span={16}>
            <Subcard>
              <Row>
                <Col span={10}>
                  {decorateField('payCheckThreshold', {
                    placeholder: t('deviation.edit.form.pay_check.threshold'),
                    suffix: '%',
                  })(<Input />)}
                </Col>
                <Col span={10}>
                  {decorateField('payCheckPeriods', {
                    placeholder: t('deviation.edit.form.pay_check.periods'),
                    suffix: t('deviation.edit.form.pay_check.periods.suffix'),
                  })(<Input />)}
                </Col>
              </Row>
              <Row>
                <p>{t('deviation.edit.form.pay_check.description')}</p>
              </Row>
            </Subcard>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={6}>
          <strong>{formatDeviationType('Gross Pay Reduction')}</strong>
          <p>{t('deviation.edit.form.gross_pay_reduction.intro')}</p>
        </Col>
        <Col span={2}>
          {decorateField('grossPayReductionEnabled', {
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <strong>{formatDeviationType('Tax Card')}</strong>
          <p>{t('deviation.edit.form.tax_card.intro')}</p>
        </Col>
        <Col span={2}>
          {decorateField('taxCardEnabled', {
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" type="secondary">
            {t('deviation.edit.form.submit')}
          </Button>
        </Col>
      </Row>
      {props.companyDeviationConfigurations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, DeviationResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      hoursEnabled: false,
      hoursThreshold: '10',
      hoursPeriods: '3',
      grossPayEnabled: false,
      grossPayThreshold: '10',
      grossPayPeriods: '3',
      payCheckEnabled: false,
      payCheckThreshold: '10',
      payCheckPeriods: '3',
      taxCardEnabled: false,
      grossPayReductionEnabled: false,
    }
    const hoursConfig = props.companyDeviationConfigurations.companyDeviationConfigurations.find(
      (config) => config.type === 'Hours'
    )
    if (hoursConfig) {
      fields.hoursEnabled = true
      fields.hoursID = hoursConfig.id
      fields.hoursThreshold = formatInputNumber(hoursConfig.threshold)
      fields.hoursPeriods = formatInputNumber(hoursConfig.periods)
    }

    const taxCardConfig = props.companyDeviationConfigurations.companyDeviationConfigurations.find(
      (config) => config.type === 'Tax Card'
    )
    if (taxCardConfig) {
      fields.taxCardEnabled = true
      fields.taxCardID = taxCardConfig.id
    }

    const grossPayConfig = props.companyDeviationConfigurations.companyDeviationConfigurations.find(
      (config) => config.type === 'Gross Pay'
    )
    if (grossPayConfig) {
      fields.grossPayEnabled = true
      fields.grossPayID = grossPayConfig.id
      fields.grossPayThreshold = formatInputNumber(grossPayConfig.threshold)
      fields.grossPayPeriods = formatInputNumber(grossPayConfig.periods)
    }

    const payCheckConfig = props.companyDeviationConfigurations.companyDeviationConfigurations.find(
      (config) => config.type === 'Pay Check'
    )
    if (payCheckConfig) {
      fields.payCheckEnabled = true
      fields.payCheckID = payCheckConfig.id
      fields.payCheckThreshold = formatInputNumber(payCheckConfig.threshold)
      fields.payCheckPeriods = formatInputNumber(payCheckConfig.periods)
    }

    const grossPayReductionConfig = props.companyDeviationConfigurations.companyDeviationConfigurations.find(
      (config) => config.type === 'Gross Pay Reduction'
    )
    if (grossPayReductionConfig) {
      fields.grossPayReductionEnabled = true
      fields.grossPayReductionID = grossPayReductionConfig.id
    }

    return fields
  },
  onSubmit: (values) => {
    const companyDeviationConfigurations: CompanyDeviationConfigurationMutableFields[] = []
    if (values.hoursEnabled) {
      companyDeviationConfigurations.push({
        id: values.hoursID,
        type: 'Hours',
        threshold: forceParseInputNumber(values.hoursThreshold),
        periods: forceParseInputNumber(values.hoursPeriods),
      })
    }
    if (values.taxCardEnabled) {
      companyDeviationConfigurations.push({
        id: values.taxCardID,
        type: 'Tax Card',
        threshold: 0,
        periods: 0,
      })
    }
    if (values.grossPayEnabled) {
      companyDeviationConfigurations.push({
        id: values.grossPayID,
        type: 'Gross Pay',
        threshold: forceParseInputNumber(values.grossPayThreshold),
        periods: forceParseInputNumber(values.grossPayPeriods),
      })
    }
    if (values.payCheckEnabled) {
      companyDeviationConfigurations.push({
        id: values.payCheckID,
        type: 'Pay Check',
        threshold: forceParseInputNumber(values.payCheckThreshold),
        periods: forceParseInputNumber(values.payCheckPeriods),
      })
    }
    if (values.grossPayReductionEnabled) {
      companyDeviationConfigurations.push({
        id: values.grossPayReductionID,
        type: 'Gross Pay Reduction',
        threshold: 0,
        periods: 0,
      })
    }

    return {
      companyDeviationConfigurations,
    }
  },
})(DeviationsEditForm)
