import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import { DepartmentPermission, DepartmentUserMutableFields } from '../../model/departmentUser'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companyUsers: CompanyUserReducer
  departments: List<Department>
  companyUser: CompanyUser
}

type Fields = {
  departments: {
    departmentID: string
    hasAccess: boolean
    seeEmployment: boolean
    canWrite: boolean
  }[]
}

export type CompanyUserDepartmentResult = {
  departments: DepartmentUserMutableFields[]
}

function EditCompanyUserDepartmentsForm(
  props: Props & FormComponentProps<Fields, CompanyUserDepartmentResult>
): ReactElement | null {
  const { decorateAnyField, getAnyFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          <label>{t('company_users.edit_departments.form.departments')}</label>
        </Col>
        {props.departments
          .filter((department) => department.active)
          .map((department, i) => {
            return (
              <Col key={department.id} span={12} className="ant-department-col">
                <div>
                  <Row className="ant-department-col-department">
                    <Col span={24}>
                      {decorateAnyField(`departments.${i}.hasAccess`, {
                        skipWrapper: true,
                        skipLabel: true,
                        valueOnChecked: true,
                        noBlur: true,
                      })(
                        <Checkbox>
                          <strong>{department.name}</strong>
                          <br />
                          <span>{t('company_users.edit_departments.form.has_access')}</span>
                        </Checkbox>
                      )}
                      <Row className="ant-department-col-permissions">
                        <Col span={24}>Yderligere rettigheder:</Col>
                        <Col span={24}>
                          {decorateAnyField(`departments.${i}.seeEmployment`, {
                            skipWrapper: true,
                            skipLabel: true,
                            valueOnChecked: true,
                            noBlur: true,
                          })(
                            <Checkbox disabled={!getAnyFieldValue(`departments.${i}.hasAccess`)}>
                              {t('company_users.edit_departments.form.see_employment')}
                            </Checkbox>
                          )}
                        </Col>
                        <Col span={24}>
                          {decorateAnyField(`departments.${i}.canWrite`, {
                            skipWrapper: true,
                            skipLabel: true,
                            valueOnChecked: true,
                            noBlur: true,
                          })(
                            <Checkbox disabled={!getAnyFieldValue(`departments.${i}.hasAccess`)}>
                              {getAnyFieldValue(`departments.${i}.seeEmployment`)
                                ? t('company_users.edit_departments.form.can_write.with_see_employment')
                                : t('company_users.edit_departments.form.can_write.without_see_employment')}
                            </Checkbox>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            )
          })}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" danger size="extra-extra-large" block>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companyUsers.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, CompanyUserDepartmentResult>({
  mapPropsToFields: (props) => {
    return {
      departments: props.departments
        .filter((department) => department.active)
        .toArray()
        .map((department) => {
          const userDepartment = props.companyUser.departments.find((v) => v.departmentID === department.id)
          return {
            departmentID: department.id,
            hasAccess: !!userDepartment,
            seeEmployment:
              !!userDepartment &&
              !!userDepartment.permissions &&
              userDepartment.permissions.some((p) => p === 'SeeSalaryRates'),
            canWrite:
              !!userDepartment &&
              !!userDepartment.permissions &&
              userDepartment.permissions.some((p) => p === 'EditObjects'),
          }
        }),
    }
  },
  onChange: (key, val) => {
    const values = {}
    if (val && key.match(/.+\.seeEmployment$/)) {
      const i = key.replace(/departments.([0-9]).seeEmployment/, '$1')
      setByPath(values, `departments.${i}.canWrite`, false)
    }
    if (!val && key.match(/.+\.hasAccess$/)) {
      const i = key.replace(/departments.([0-9]).hasAccess/, '$1')
      setByPath(values, `departments.${i}.seeEmployment`, false)
      setByPath(values, `departments.${i}.canWrite`, false)
    }
    setByPath(values, key, val)
    return values
  },
  onSubmit: (values) => ({
    departments: values.departments.reduce((list: DepartmentUserMutableFields[], department) => {
      if (department.hasAccess) {
        const permissions: DepartmentPermission[] = ['ApproveObjects']
        if (department.seeEmployment) {
          permissions.push('SeeSalaryRates')
        }
        if (department.canWrite) {
          permissions.push('EditObjects')
        }
        if (department.seeEmployment && department.canWrite) {
          permissions.push('HireFire')
        }
        list.push({ departmentID: department.departmentID, permissions })
      }
      return list
    }, []),
  }),
})(EditCompanyUserDepartmentsForm)
