import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Contract from '../../model/contract'
import { AffiliationType } from '../../model/employee'
import { SalaryDefinition } from '../../model/remuneration'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { EmployeePayType } from '../../utils/employee-utils'
import {
  decorateFieldSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { formatBiweeklySalaryCycle, formatEmployeePayType, formatSalaryCycle } from '../../utils/format-utils'
import { getAvailableCycles } from '../../utils/salary-cycle-utils'
import { getSalaryType } from '../../utils/salary-type-utils'
import { t } from '../../utils/translation-utils'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Lock from '../widgets/Lock'

type PaymentFields = {
  payType: EmployeePayType
  salaryCycleID?: string
  remuneration?: {
    salary: { salaryTypeID: string; title?: string }[]
  }
}

type Props<Fields extends PaymentFields> = {
  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setFieldValue: setFieldValueSignature<Fields>
  company: Company
  employee: {
    affiliationType: AffiliationType
    activeContract?: Contract
  }
  salaryCycles?: List<SalaryCycle>
  salaryTypes?: List<SalaryType>
}

export default function ContractPayment<Fields extends PaymentFields>(props: Props<Fields>): ReactElement | null {
  const getSalaryCycles = (payType: EmployeePayType) => {
    return getAvailableCycles(
      props.company,
      props.salaryCycles?.toArray() || [],
      payType !== 'Salaried',
      props.employee
    )
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { getFieldValue, setFieldValue } = props

    const payType = e.target.value as EmployeePayType
    const allCycles = getSalaryCycles(payType)
    const matchedCycles = allCycles.filter((salaryCycle) => salaryCycle.id === getFieldValue('salaryCycleID'))
    if (matchedCycles.length > 0 && matchedCycles[0].frequency === 'Monthly') {
      if (payType !== 'Salaried') {
        const offsetCycles = allCycles.filter((salaryCycle) => salaryCycle.offset)
        if (offsetCycles.length > 0) {
          setFieldValue('salaryCycleID', offsetCycles[0].id)
        }
      } else {
        const normalCycles = allCycles.filter((salaryCycle) => !salaryCycle.offset)
        if (normalCycles.length > 0) {
          setFieldValue('salaryCycleID', normalCycles[0].id)
        }
      }
    } else if (matchedCycles.length === 0) {
      const availableCycles = allCycles.filter((salaryCycle) => salaryCycle.frequency === 'Monthly')
      if (availableCycles.length > 0) {
        setFieldValue('salaryCycleID', availableCycles[0].id)
      } else {
        setFieldValue('salaryCycleID', allCycles[0].id)
      }
    }
  }

  const { decorateField, getFieldValue } = props
  const payTypes: EmployeePayType[] = ['Salaried', 'Hourly Paid', 'Commissioned']
  const isFreelancer = props.employee.affiliationType === 'Freelancer'

  return (
    <Row>
      <Col span={isFreelancer ? 24 : 12}>
        {decorateField('salaryCycleID', {
          title: isFreelancer
            ? t('employment_pay.edit.contract_payment.salary_cycle_id.freelancer')
            : t('employment_pay.edit.contract_payment.salary_cycle_id.standard'),
          placeholder: t('employment_pay.edit.contract_payment.salary_cycle_id.placeholder'),
          validate: (val) => (!val ? t('employment_pay.edit.contract_payment.salary_cycle_id.required') : null),
        })(
          <Select dropdownMatchSelectWidth={false}>
            {getSalaryCycles(getFieldValue('payType')).map((salaryCycle) => {
              return (
                <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                  {formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}
                  {salaryCycle.frequency === 'BiWeekly' && ` (${formatBiweeklySalaryCycle(salaryCycle, false)})`}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Col>
      {!isFreelancer && (
        <Col span={12}>
          {decorateField('payType', {
            title: '',
          })(
            <Radio.Group onChange={handleChange}>
              {payTypes.map((payType) => (
                <Lock
                  key={payType}
                  isLocked={
                    payType === 'Commissioned' &&
                    !!props.employee.activeContract &&
                    ((props.getAnyFieldValue('remuneration.salary') as SalaryDefinition[]) || []).some((salary) => {
                      const type = getSalaryType(props.salaryTypes?.toArray(), salary.salaryTypeID)
                      if (!type) {
                        return false
                      }
                      return type.class !== 'SupplementVaried'
                    })
                  }
                  description={t('employment_pay.edit.contract_payment.pay_type.description')}
                >
                  <Radio value={payType}>{formatEmployeePayType(payType)}</Radio>
                </Lock>
              ))}
            </Radio.Group>
          )}
        </Col>
      )}
    </Row>
  )
}
