import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatUserPermission } from '../../utils/format-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company?: Company
  companyUser?: CompanyUser
  companyUsers: CompanyUserReducer
}

export type EditCompanyUserResult = {
  permissionAdmin: boolean
  permissionApprovePayRoll: boolean
  permissionReviewPayRoll: boolean
}

function EditCompanyUserForm(
  props: Props & FormComponentProps<EditCompanyUserResult, EditCompanyUserResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          <div className="ant-switch-wrapper">
            {decorateField('permissionAdmin', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{formatUserPermission('Admin')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ant-switch-wrapper">
            {decorateField('permissionApprovePayRoll', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{formatUserPermission('ApprovePayRoll')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ant-switch-wrapper">
            {decorateField('permissionReviewPayRoll', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{formatUserPermission('ReviewPayRoll')}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" danger size="extra-extra-large" block>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companyUsers.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, EditCompanyUserResult, EditCompanyUserResult>({
  mapPropsToFields: (props) => {
    const permissions = (props.companyUser && props.companyUser.permissions) || []
    return {
      permissionAdmin: permissions.filter((v) => v.permission === 'Admin').length > 0,
      permissionApprovePayRoll: permissions.filter((v) => v.permission === 'ApprovePayRoll').length > 0,
      permissionReviewPayRoll: permissions.filter((v) => v.permission === 'ReviewPayRoll').length > 0,
    }
  },
  onChange: (key, val) => {
    const values = {}
    switch (key) {
      case 'permissionAdmin':
        setByPath(values, key, val)
        setByPath(values, 'permissionApprovePayRoll', false)
        break
      case 'permissionApprovePayRoll':
        setByPath(values, key, val)
        setByPath(values, 'permissionAdmin', false)
        break
      case 'permissionReviewPayRoll':
        setByPath(values, key, val)
        setByPath(values, 'permissionAdmin', false)
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => values,
})(EditCompanyUserForm)
