import { endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns'
import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import CompanyGroup from '../../../model/companyGroup'
import { ReportType } from '../../../model/report'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { setByPath } from '../../../utils/object-utils'
import { formatReportName, getGroupReports } from '../../../utils/report-utils'
import { t } from '../../../utils/translation-utils'
import Card from '../../elements/card'
import Checkbox from '../../elements/checkbox'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Select from '../../elements/select'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import ReportButton, { GroupReportFields } from '../../widgets/ReportButton'

type Props = {
  companyGroup: CompanyGroup

  addAlert: addAlertSignature
}

type Fields = {
  report?: ReportType
  from: Date
  to: Date
  wholeGroup: boolean
  companies: Record<string, boolean>
}

function ReportsTab(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const [buttonState, setButtonState] = useState(1)
  const [disabled, setDisabled] = useState(false)

  const isValid = (): boolean => {
    const { getFieldValue } = props

    const report = getFieldValue('report')
    if (!report) {
      return false
    }

    return true
  }

  const getFields = (): GroupReportFields | undefined => {
    const { getFieldValue, getAnyFieldValue } = props

    const report = getFieldValue('report')
    if (!report) {
      return undefined
    }
    const from = formatAPIDate(getFieldValue('from'))
    const to = formatAPIDate(getFieldValue('to'))
    const wholeGroup = getFieldValue('wholeGroup')
    const companies: string[] = []
    if (!wholeGroup) {
      props.companyGroup.companies.forEach((company) => {
        if (getAnyFieldValue('companies.' + company.id)) {
          companies.push(company.id)
        }
      })
    }

    return {
      type: 'Group',
      companyGroupID: props.companyGroup.id,
      report,
      from,
      to,
      companies,
    }
  }

  const { decorateField, decorateAnyField, getFieldValue } = props

  return (
    <Card>
      <Title>{t('companies.group.reports.title')}</Title>

      <Row>
        <Col span={24}>
          {decorateField('report', {
            skipWrapper: true,
            skipLabel: true,
            placeholder: t('companies.group.reports.form.report'),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              style={{ width: '38%' }}
              onChange={() => setButtonState((prev) => prev + 1)}
              disabled={disabled}
            >
              {getGroupReports().map((report) => {
                return (
                  <Select.Option key={report} value={report}>
                    {formatReportName(report)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('from', {
            placeholder: t('companies.group.reports.form.from'),
            validate: (val) => {
              if (!val) {
                return t('companies.group.reports.form.from.required')
              }
              return null
            },
          })(
            <DatePicker
              allowClear={false}
              style={{ width: '100%' }}
              onChange={() => setButtonState((prev) => prev + 1)}
              disabled={disabled}
            />
          )}
        </Col>
        <Col span={12}>
          {decorateField('to', {
            placeholder: t('companies.group.reports.form.to'),
            validate: (val) => {
              if (!val) {
                return t('companies.group.reports.form.to.required')
              }
              return null
            },
          })(
            <DatePicker
              allowClear={false}
              style={{ width: '100%' }}
              onChange={() => setButtonState((prev) => prev + 1)}
              disabled={disabled}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('wholeGroup', {
            title: t('companies.group.reports.form.while_group'),
            noBlur: true,
            valueOnChecked: true,
          })(<Switch onChange={() => setButtonState((prev) => prev + 1)} disabled={disabled} />)}
        </Col>
        {!getFieldValue('wholeGroup') && (
          <Col span={12}>
            <p>{t('companies.group.reports.form.not_while_group')}</p>
            {props.companyGroup.companies.map((company) => {
              const title = company.name + ' (' + company.nationalID + ')'
              return (
                <Col span={12} key={company.id}>
                  {decorateAnyField('companies.' + company.id, {
                    skipWrapper: true,
                    skipLabel: true,
                    title,
                  })(<Checkbox onChange={() => setButtonState((prev) => prev + 1)} disabled={disabled} />)}
                  <span className="ant-switch-text">{title}</span>
                </Col>
              )
            })}
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <ReportButton
            onStateChange={(ready) => setDisabled(!ready)}
            state={buttonState}
            type={'Group Excel'}
            addAlert={props.addAlert}
            getFields={getFields}
            isValid={isValid}
            buttonType="primary"
          />
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: () => ({
    wholeGroup: true,
    from: startOfMonth(subMonths(getDate(), 1)),
    to: startOfDay(endOfMonth(subMonths(getDate(), 1))),
    companies: {},
  }),
  onChange: (key, val) => {
    const values = {}
    switch (key) {
      case 'from':
        setByPath(values, key, val)
        if (val && val instanceof Date) {
          setByPath(values, 'to', startOfDay(endOfMonth(val)))
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => values,
})(ReportsTab)
