import { List } from 'immutable'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { TimeRegistrationBulk } from '../../../api/time-registrations'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import { TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { DateFormat } from '../../../model/types'
import { EmployeeReducer } from '../../../reducers/employees'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import RemunerationType from '../../../types/remuneration-type'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { RequestError } from '../../../utils/request-utils'
import { t, tx } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import LeaveEditForm, { Result } from './LeaveEditForm'

type Props = {
  visible: boolean
  canApproveObjects: boolean
  company: Company
  employee?: Employee
  employees?: EmployeeReducer
  timeRegistrationID?: string
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: List<LeaveType>
  period?: DateFormat[]
  remunerationType?: RemunerationType
  getRemuneration?: (id: string) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  deleteTimeRegistration?: (id: string) => void
  approveTimeRegistrations?: (ids: string[]) => void
}

export default function LeaveEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null)

  const { getRemuneration, employee, employees } = props

  useEffect(() => {
    if (
      getRemuneration &&
      employees &&
      employee &&
      employee.activeContract &&
      !employee.activeContract.remuneration &&
      !employees.loading
    ) {
      getRemuneration(employee.activeContract.id)
    }
  }, [getRemuneration, employee, employees])

  const { visible, timeRegistrations, company } = props

  useEffect(() => {
    if (
      error instanceof RequestError &&
      ((error as RequestError).details?.length ?? 0) > 0 &&
      (error as RequestError).details![0] === 'Leave registration cannot be on vacation day' &&
      !company.settingsEnabled.includes('UseAdjustedWorkWeekLeaveRate')
    ) {
      setErrorMessage(
        tx('error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate', {
          link: (
            <Link
              to={'/' + paths.COMPANIES + '/' + company.id + '/advance-settings#setting-UseAdjustedWorkWeekLeaveRate'}
            >
              {t(
                'error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate.link'
              )}
            </Link>
          ),
        })
      )
    } else {
      // make sure the message is otherwise removed, so it won't block for a real error
      setErrorMessage(null)
    }
    visibleComponentDidUpdate(visible, timeRegistrations.error, error, setError)
  }, [visible, error, timeRegistrations, company])

  const handleSubmit = (values: Result) => {
    if (props.timeRegistrationID) {
      const timeRegistration: TimeRegistrationMutableFields = {
        id: props.timeRegistrationID,
        employeeID: props.employee?.id || '',
        class: values.class,
        leaveTypeID: values.leaveTypeID,
        leaveSubTypeID: values.leaveSubTypeID,
        approved: props.canApproveObjects,
        note: values.note || '',
        date: values.date,
        days: values.days,
      }
      props.updateTimeRegistration(timeRegistration)
    } else {
      if (values.startDate === values.endDate) {
        const timeRegistration: TimeRegistrationMutableFields = {
          employeeID: props.employee?.id || '',
          class: values.class,
          leaveTypeID: values.leaveTypeID,
          leaveSubTypeID: values.leaveSubTypeID,
          approved: props.canApproveObjects,
          note: values.note || '',
          date: values.startDate,
          days: values.days,
        }
        props.createTimeRegistration(timeRegistration)
      } else {
        const timeRegistration: TimeRegistrationBulk = {
          employeeID: props.employee?.id || '',
          leaveTypeID: values.leaveTypeID,
          leaveSubTypeID: values.leaveSubTypeID,
          approved: props.canApproveObjects,
          note: values.note || '',
          startDate: values.startDate,
          endDate: values.endDate,
        }
        props.createTimeRegistrationBulk(timeRegistration)
      }
    }
  }

  const needsApproval = () => {
    let needsApproval = false
    if (props.timeRegistrationID) {
      const timeReg = props.timeRegistrations.timeRegistrations.find((reg) => reg.id === props.timeRegistrationID)
      if (timeReg) {
        needsApproval = !timeReg.approved
      }
    }
    return needsApproval
  }

  const approveLeave = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!props.approveTimeRegistrations || !props.timeRegistrationID || !needsApproval()) {
      return
    }
    props.approveTimeRegistrations([props.timeRegistrationID])
  }

  const deleteLeave = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!props.timeRegistrationID || !props.deleteTimeRegistration) {
      return
    }
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteTimeRegistration(props.timeRegistrationID)
    }
  }

  if (!props.employee || !props.employee.activeContract) {
    return null
  }
  if (!props.employee.activeContract.remuneration) {
    return <LoadingOverlay />
  }

  return (
    <Card>
      <Subtitle>
        {props.timeRegistrationID
          ? t('leave.tab.edit.title.edit', { name: employee!.name })
          : t('leave.tab.edit.title.create', { name: employee!.name })}
      </Subtitle>
      <p>&nbsp;</p>
      {(errorMessage || error) && <Alert message={errorMessage || formatError(error!)} type="error" showIcon />}
      <LeaveEditForm
        employee={props.employee}
        timeRegistrationID={props.timeRegistrationID}
        period={props.period}
        timeRegistrations={props.timeRegistrations}
        leaveTypes={props.leaveTypes}
        remunerationType={props.remunerationType}
        onSubmit={handleSubmit}
      />
      {props.approveTimeRegistrations && needsApproval() && (
        <Button
          size="extra-extra-large"
          type="secondary"
          style={{ width: '100%', marginTop: '10px' }}
          onClick={approveLeave}
        >
          {t('leave.tab.edit.button.approve')}
        </Button>
      )}
      {props.deleteTimeRegistration && props.timeRegistrationID && (
        <Button danger size="extra-extra-large" style={{ width: '100%', marginTop: '10px' }} onClick={deleteLeave}>
          {t('leave.tab.edit.button.delete')}
        </Button>
      )}
    </Card>
  )
}
