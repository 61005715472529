import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { getCompanyAccountPlans } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getBanks } from '../actions/banks'
import { getCompanyPricingPackages } from '../actions/company-pricing-packages'
import { addContract, getContracts, updateContract } from '../actions/contracts'
import { getCostCenters } from '../actions/cost-centers'
import { addDepartment, getDepartments } from '../actions/departments'
import {
  deleteEmployeeEmergencyContact,
  getEmployeeEmergencyContact,
  updateEmployeeEmergencyContact,
} from '../actions/employee-emergency-contacts'
import {
  convertEmployee,
  deleteEmployee,
  deleteEmployeeUser,
  sendInvite,
  updateEmployee,
  updateProfileImage,
} from '../actions/employees'
import { getEmployments, rehireEmployee, terminateEmployment, updateEmployment } from '../actions/employments'
import { getExpenseCategories } from '../actions/expense-categories'
import {
  addOneTimePay,
  approveOneTimePays,
  deleteOneTimePay,
  getOneTimePays,
  unapproveOneTimePays,
  updateOneTimePay,
} from '../actions/one-time-pays'
import { getPaySlips } from '../actions/pay-slips'
import { getPricingPackages } from '../actions/pricing-packages'
import { getProjects } from '../actions/projects'
import {
  addReimbursementVoucher,
  approveReimbursementVouchers,
  draftReimbursementVouchers,
  getReimbursementVouchers,
  unapproveReimbursementVouchers,
  updateReimbursementVoucher,
  updateReimbursementVoucherFields,
} from '../actions/reimbursement-vouchers'
import { getSalaryCycles } from '../actions/salary-cycles'
import {
  approveTimeRegistrations,
  createTimeRegistration,
  createTimeRegistrationBulk,
  deleteTimeRegistration,
  deleteTimeRegistrationBulk,
  unapproveTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import { deleteWarning } from '../actions/warnings'
import { EmployeeConversionType } from '../api/employees'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../api/reimbursement-vouchers'
import { TimeRegistrationBulk } from '../api/time-registrations'
import FreelancersSingleComponent from '../components/freelancers-single/FreelancersSingle'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import { ContractCreationFields, ContractMutableFields } from '../model/contract'
import Department, { DepartmentCreationFields } from '../model/department'
import Employee from '../model/employee'
import { EmployeeEmergencyContactMutableFields } from '../model/employeeEmergencyContact'
import Employment from '../model/employment'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import ProfileImage from '../model/profileImage'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { BankReducer } from '../reducers/banks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountPlanReducer } from '../reducers/companyAccountPlans'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyPricingPackageReducer } from '../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeEmergencyContactReducer } from '../reducers/employeeEmergencyContacts'
import { EmployeeInviteReducer } from '../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../reducers/employeeProfileImages'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentReducer } from '../reducers/employments'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { PaySlipReducer } from '../reducers/paySlips'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { ProjectReducer } from '../reducers/projects'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { UserReducer } from '../reducers/user'
import { WarningReducer } from '../reducers/warnings'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer, loadReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  user: UserReducer
  employees: EmployeeReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyFeatures: CompanyFeatureReducer
  paySlips: PaySlipReducer
  salaryCycles: SalaryCycleReducer
  banks: BankReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employments: EmploymentReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  oneTimePays: OneTimePayReducer
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  expenseCategories: ExpenseCategoryReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  companyPricingPackages: CompanyPricingPackageReducer
  pricingPackages: PricingPackageReducer
  projects: ProjectReducer
  timeRegistrations: TimeRegistrationReducer
  warnings: WarningReducer
  companyAccountPlans: CompanyAccountPlanReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  deleteEmployee: (employeeID: string) => Promise<boolean | void>
  getPaySlips: (payRollID?: string, employeeID?: string) => void
  getSalaryCycles: () => void
  getBanks: () => void
  getEmployeeEmergencyContact: (employeeID: string) => void
  updateEmployeeEmergencyContact: (
    employeeID: string,
    employeeEmergencyContact: EmployeeEmergencyContactMutableFields
  ) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  getEmployments: (employeeID: string) => void
  updateEmployment: (employeeID: string, employment: Employment) => void
  addContract: (contract: ContractCreationFields) => void
  getContracts: () => void
  updateContract: (contract: ContractMutableFields) => void
  getDepartments: () => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  getCostCenters: () => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  approveOneTimePays: (oneTimePayIDs: string[]) => void
  unapproveOneTimePays: (oneTimePayIDs: string[]) => void
  deleteOneTimePay: (id: string) => void
  getOneTimePays: (companyID?: string, employeeID?: string) => void
  sendInvite: (employeeID: string) => void
  deleteEmployeeUser: (employeeID: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  getReimbursementVouchers: () => void
  getExpenseCategories: () => void
  addReimbursementVoucher: (reimbursementVoucher: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucher: (reimbursementVoucher: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (
    reimbursementVoucher: ReimbursementVoucherFieldsUpdate
  ) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (reimbursementVoucherIDs: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (reimbursementVoucherIDs: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (reimbursementVoucherIDs: string[]) => Promise<ReimbursementVoucher[] | void>
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  rehireEmployee: (employeeID: string, startDate: DateFormat) => Promise<boolean | void>
  getCompanyPricingPackages: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  getProjects: () => void
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
  convertEmployee: (employeeID: string, toType: EmployeeConversionType) => Promise<Employee | void>
  deleteWarning: (id: string) => void
  getCompanyAccountPlans: () => void
}

function FreelancersSingle(props: Reducers & Actions & RouteProps): ReactElement | null {
  const resolveSplat = () => {
    const parts = splatString(props.params.splat).split('/')
    const employeeID = parts[0]
    let section = ''
    let subsection = undefined
    if (parts.length > 1) {
      section = parts[1]
      if (parts.length > 2) {
        subsection = parts[2]
      }
    }
    section = section || 'overview'
    return { employeeID, section, subsection }
  }

  const hasEmployeeEmergencyContactsFeature = () => {
    return (
      props.companyFeatures.loaded &&
      props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Employee Emergency Contacts')
    )
  }

  const { employeeID, section, subsection } = resolveSplat()
  const previousEmployeeID = usePrevious(employeeID)

  const {
    params,
    employees,
    companies,
    paySlips,
    getPaySlips,
    salaryCycles,
    getSalaryCycles,
    employments,
    getEmployments,
    employeeEmergencyContacts,
    getEmployeeEmergencyContact,
    oneTimePays,
    getOneTimePays,
    costCenters,
    getCostCenters,
    expenseCategories,
    getExpenseCategories,
    reimbursementVouchers,
    getReimbursementVouchers,
    companyPricingPackages,
    getCompanyPricingPackages,
    pricingPackages,
    getPricingPackages,
    projects,
    getProjects,
  } = props
  useEffect(() => {
    const company = companies.company
    if (!company) {
      return
    }
    const employee = employees.employees.find((employee) => employee.id === employeeID)
    if (!employee) {
      return
    }
    if (previousEmployeeID !== employeeID) {
      const prevEmployee = employees.employees.find((employee) => employee.id === previousEmployeeID)
      if (prevEmployee && prevEmployee.name === employee.name) {
        document.location = '/' + paths.FREELANCERS + '/' + params.splat
      }
    }
    if (paySlips.employeeID !== employeeID || (!paySlips.loading && !paySlips.loaded)) {
      getPaySlips(undefined, employeeID)
    }
    loadReducer(salaryCycles, getSalaryCycles)
    if (employments.employeeID !== employeeID || (!employments.loading && !employments.loaded)) {
      getEmployments(employeeID)
    }
    if (
      hasEmployeeEmergencyContactsFeature() &&
      ((employeeEmergencyContacts.loaded && employeeEmergencyContacts.employeeID !== employeeID) ||
        (!employeeEmergencyContacts.loaded && !employeeEmergencyContacts.loading))
    ) {
      getEmployeeEmergencyContact(employeeID)
    }
    if (oneTimePays.employeeID !== employeeID || (!oneTimePays.loading && !oneTimePays.loaded)) {
      getOneTimePays(undefined, employeeID)
    }
    loadReducer(costCenters, getCostCenters)
    loadReducer(expenseCategories, getExpenseCategories)
    loadReducer(reimbursementVouchers, getReimbursementVouchers)
    loadReducer(companyPricingPackages, getCompanyPricingPackages)
    loadReducer(pricingPackages, () => getPricingPackages([]))
    loadReducer(projects, getProjects)
  })

  const employee = props.employees.employees.find((employee) => employee.id === employeeID)
  const company = props.companies.company
  if (!employee || !company) {
    jsBrowserHistory.push('/' + paths.FREELANCERS)
    return null
  }

  const { location } = props
  if (location.pathname.match(/\/freelancers\/[^/]+\/?$/)) {
    jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/overview')
    return null
  }

  const loading =
    !props.paySlips.loaded ||
    !props.salaryCycles.loaded ||
    (hasEmployeeEmergencyContactsFeature() && !props.employeeEmergencyContacts.loaded) ||
    !props.employments.loaded ||
    !props.oneTimePays.loaded ||
    !props.projects.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.paySlips.loaded, text: 'udbetalinger' },
            { loading: !props.salaryCycles.loaded, text: 'løncykler' },
            {
              loading: hasEmployeeEmergencyContactsFeature() && !props.employeeEmergencyContacts.loaded,
              text: 'nødkontakt',
            },
            { loading: !props.employments.loaded, text: 'ansættelser' },
            { loading: !props.oneTimePays.loaded, text: 'engangsbetalinger' },
            { loading: !props.projects.loaded, text: 'projekter' },
          ])}
        />
      </div>
    )
  }

  return (
    <FreelancersSingleComponent
      section={section}
      subsection={subsection}
      alerts={props.alerts}
      employee={employee}
      user={props.user}
      employees={props.employees}
      company={company}
      companyFeatures={props.companyFeatures.companyFeatures}
      companyUsers={props.companyUsers}
      paySlips={props.paySlips.paySlips}
      salaryCycles={props.salaryCycles.salaryCycles}
      banks={props.banks}
      employeeEmergencyContacts={props.employeeEmergencyContacts}
      employments={props.employments}
      contracts={props.contracts}
      departments={props.departments}
      costCenters={props.costCenters.costCenters}
      oneTimePays={props.oneTimePays}
      employeeInvites={props.employeeInvites}
      employeeProfileImages={props.employeeProfileImages}
      expenseCategories={props.expenseCategories.expenseCategories}
      reimbursementVouchers={props.reimbursementVouchers}
      companyPricingPackages={props.companyPricingPackages.companyPricingPackages}
      pricingPackages={props.pricingPackages.pricingPackages}
      projects={props.projects.projects}
      timeRegistrations={props.timeRegistrations}
      warnings={props.warnings.warnings}
      companyAccountPlans={props.companyAccountPlans}
      hasEmployeeEmergencyContactsFeature={hasEmployeeEmergencyContactsFeature()}
      location={props.location}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateEmployee={props.updateEmployee}
      deleteEmployee={props.deleteEmployee}
      getBanks={props.getBanks}
      updateEmployeeEmergencyContact={props.updateEmployeeEmergencyContact}
      deleteEmployeeEmergencyContact={props.deleteEmployeeEmergencyContact}
      updateEmployment={props.updateEmployment}
      addContract={props.addContract}
      updateContract={props.updateContract}
      getDepartments={props.getDepartments}
      addDepartment={props.addDepartment}
      addOneTimePay={props.addOneTimePay}
      updateOneTimePay={props.updateOneTimePay}
      approveOneTimePays={props.approveOneTimePays}
      unapproveOneTimePays={props.unapproveOneTimePays}
      deleteOneTimePay={props.deleteOneTimePay}
      sendInvite={props.sendInvite}
      deleteEmployeeUser={props.deleteEmployeeUser}
      updateProfileImage={props.updateProfileImage}
      addReimbursementVoucher={props.addReimbursementVoucher}
      updateReimbursementVoucher={props.updateReimbursementVoucher}
      updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
      approveReimbursementVouchers={props.approveReimbursementVouchers}
      unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
      draftReimbursementVouchers={props.draftReimbursementVouchers}
      getEmployments={props.getEmployments}
      terminateEmployment={props.terminateEmployment}
      rehireEmployee={props.rehireEmployee}
      createTimeRegistration={props.createTimeRegistration}
      createTimeRegistrationBulk={props.createTimeRegistrationBulk}
      approveTimeRegistrations={props.approveTimeRegistrations}
      unapproveTimeRegistrations={props.unapproveTimeRegistrations}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
      convertEmployee={props.convertEmployee}
      deleteWarning={props.deleteWarning}
      getCompanyAccountPlans={props.getCompanyAccountPlans}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    employees: state.employees,
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    companyUsers: state.companyUsers,
    paySlips: state.paySlips,
    salaryCycles: state.salaryCycles,
    banks: state.banks,
    employeeEmergencyContacts: state.employeeEmergencyContacts,
    employments: state.employments,
    contracts: state.contracts,
    departments: state.departments,
    costCenters: state.costCenters,
    oneTimePays: state.oneTimePays,
    employeeInvites: state.employeeInvites,
    employeeProfileImages: state.employeeProfileImages,
    expenseCategories: state.expenseCategories,
    reimbursementVouchers: state.reimbursementVouchers,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
    projects: state.projects,
    timeRegistrations: state.timeRegistrations,
    warnings: state.warnings,
    companyAccountPlans: state.companyAccountPlans,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateEmployee: updateEmployee,
    deleteEmployee: deleteEmployee,
    getPaySlips: getPaySlips,
    getSalaryCycles: getSalaryCycles,
    getBanks: getBanks,
    getEmployeeEmergencyContact: getEmployeeEmergencyContact,
    updateEmployeeEmergencyContact: updateEmployeeEmergencyContact,
    deleteEmployeeEmergencyContact: deleteEmployeeEmergencyContact,
    getEmployments: getEmployments,
    updateEmployment: updateEmployment,
    addContract: addContract,
    getContracts: getContracts,
    updateContract: updateContract,
    getDepartments: getDepartments,
    addDepartment: addDepartment,
    getCostCenters: getCostCenters,
    getOneTimePays: getOneTimePays,
    addOneTimePay: addOneTimePay,
    updateOneTimePay: updateOneTimePay,
    approveOneTimePays: approveOneTimePays,
    unapproveOneTimePays: unapproveOneTimePays,
    deleteOneTimePay: deleteOneTimePay,
    sendInvite: sendInvite,
    deleteEmployeeUser: deleteEmployeeUser,
    updateProfileImage: updateProfileImage,
    getExpenseCategories: getExpenseCategories,
    getReimbursementVouchers: getReimbursementVouchers,
    addReimbursementVoucher: addReimbursementVoucher,
    updateReimbursementVoucher: updateReimbursementVoucher,
    updateReimbursementVoucherFields: updateReimbursementVoucherFields,
    approveReimbursementVouchers: approveReimbursementVouchers,
    unapproveReimbursementVouchers: unapproveReimbursementVouchers,
    draftReimbursementVouchers: draftReimbursementVouchers,
    terminateEmployment: terminateEmployment,
    rehireEmployee: rehireEmployee,
    getPricingPackages: getPricingPackages,
    getCompanyPricingPackages: getCompanyPricingPackages,
    getProjects: getProjects,
    createTimeRegistration: createTimeRegistration,
    createTimeRegistrationBulk: createTimeRegistrationBulk,
    approveTimeRegistrations: approveTimeRegistrations,
    unapproveTimeRegistrations: unapproveTimeRegistrations,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    deleteTimeRegistrationBulk: deleteTimeRegistrationBulk,
    convertEmployee: convertEmployee,
    deleteWarning: deleteWarning,
    getCompanyAccountPlans: getCompanyAccountPlans,
  }
)(FreelancersSingle)
