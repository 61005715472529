import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Asset, { AssetMutableFields } from '../../../model/asset'
import AssetCategory from '../../../model/assetCategory'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import { AssetCategoryReducer } from '../../../reducers/assetCategories'
import { AssetReducer } from '../../../reducers/assets'
import { formatDate, isSameOrAfter, isTimeAfter, isTimeBefore } from '../../../utils/date-utils'
import { getDate } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import AssetDisplay from './AssetDisplay'
import AssetEdit from './AssetEdit'

type Props = {
  assetCategories: AssetCategoryReducer
  assets: AssetReducer

  employee: Employee
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean

  getAssetCategories: () => Promise<AssetCategory[] | void>
  getAssets: () => Promise<Asset[] | void>
  createAsset: (companyID: string, asset: AssetMutableFields) => void
  deleteAsset: (assetID: string) => void
  receiveAsset: (companyID: string, asset: Asset) => void
  hideAsset: (companyID: string, asset: Asset) => void
  updateAsset: (companyID: string, asset: AssetMutableFields) => void
}

export default function AssetsTab(props: Props): ReactElement | null {
  const [editing, setEditing] = useState<string | boolean>(false)
  const [returning, setReturning] = useState<boolean>(false)
  const [displaying, setDisplaying] = useState<Asset>()
  const [displayingCategory, setDisplayingCategory] = useState<string>()
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [mutable, setMutable] = useState(false)

  const setEditVisibility = useCallback(
    (id: boolean | string, mutable = true) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setEditing(id)
      setReturning(false)
      setMutable(mutable)
    },
    [setModalKey, setEditing, setMutable]
  )

  const setReturnVisibility = useCallback(
    (id: boolean | string, mutable = true) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setEditing(id)
      setReturning(true)
      setMutable(mutable)
    },
    [setModalKey, setEditing, setMutable]
  )

  const setDisplayVisibility = useCallback(
    (asset: Asset | undefined, category: string | undefined) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setDisplaying(asset)
      setDisplayingCategory(category)
    },
    [setModalKey, setDisplaying]
  )

  const assetSaving = props.assets.saving
  const wasAssetSaving = usePrevious(assetSaving)
  const assetError = props.assets.error

  useEffect(() => {
    if (wasAssetSaving && !assetSaving) {
      if (!assetError) {
        setEditVisibility(false)
      }
    }
  }, [wasAssetSaving, assetSaving, assetError, setEditVisibility])

  const companyID = props.company.id
  const { getAssetCategories, getAssets } = props
  const { assetCategories } = props
  const { assets } = props
  const { employee } = props

  useEffect(() => {
    if (assetCategories.companyID !== companyID || (!assetCategories.loading && !assetCategories.loaded)) {
      getAssetCategories()
    }
  }, [companyID, assetCategories, getAssetCategories])

  useEffect(() => {
    if (assets.companyID !== companyID || (!assets.loading && !assets.loaded)) {
      getAssets()
    }
  }, [employee, assets, getAssets, companyID])

  const remove = (asset: Asset) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('asset.tab.confirm.delete'))) {
        setDeleting((prev) => [...prev, asset.id])
        props.deleteAsset(asset.id)
      }
    }
  }

  const receiveAsset = (asset: Asset) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('asset.tab.confirm.receive'))) {
        setDeleting((prev) => [...prev, asset.id])
        props.receiveAsset(companyID, asset)
      }
    }
  }

  const hideAsset = (asset: Asset) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('asset.tab.confirm.hide'))) {
        setDeleting((prev) => [...prev, asset.id])
        props.hideAsset(companyID, asset)
      }
    }
  }

  type AssetRow = {
    key: string
    id: string
    title: string
    description: string
    note: string
    category: string
    receivedDate: string
    returnDate: string
    hasReturnedDate: boolean
    hidden: boolean
    original: Asset
  }

  const columns = [
    {
      title: t('asset.tab.outstanding.table.header.title'),
      dataIndex: '',
      key: 'x3',
      className: 'asset-title',
      render: (asset: AssetRow) => {
        if (deleting.indexOf(asset.id) !== -1) {
          return null
        }
        return (
          <span onClick={() => setDisplayVisibility(asset.original, asset.category)} style={{ cursor: 'pointer' }}>
            {asset.title}
          </span>
        )
      },
    },
    { title: t('asset.tab.outstanding.table.header.description'), dataIndex: 'description', key: 'description' },
    { title: t('asset.tab.outstanding.table.header.category'), dataIndex: 'category', key: 'category' },
    { title: t('asset.tab.outstanding.table.header.received'), dataIndex: 'receivedDate', key: 'receivedDate' },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'company-table-actions',
      render: (asset: AssetRow) => {
        if (deleting.indexOf(asset.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('asset.tab.outstanding.table.actions.edit')}>
              <span onClick={() => setEditVisibility(asset.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            {!asset.hasReturnedDate && (
              <Tooltip title={t('asset.tab.outstanding.table.actions.return')}>
                <span onClick={() => setReturnVisibility(asset.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="arrowDownIntoTray" />
                </span>
              </Tooltip>
            )}
            <Tooltip title={t('asset.tab.outstanding.table.actions.delete')}>
              <DumbLink onClick={remove(asset.original)} style={{ cursor: 'pointer' }}>
                <Icon type="xSign" />
              </DumbLink>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const returnedColumns = [
    {
      title: t('asset.tab.returned.table.header.title'),
      dataIndex: '',
      key: 'x3',
      className: 'asset-title',
      render: (asset: AssetRow) => {
        if (deleting.indexOf(asset.id) !== -1) {
          return null
        }
        return (
          <span onClick={() => setDisplayVisibility(asset.original, asset.category)} style={{ cursor: 'pointer' }}>
            {asset.title}
          </span>
        )
      },
    },
    { title: t('asset.tab.returned.table.header.description'), dataIndex: 'description', key: 'description' },
    { title: t('asset.tab.returned.table.header.category'), dataIndex: 'category', key: 'category' },
    { title: t('asset.tab.returned.table.header.returned'), dataIndex: 'returnDate', key: 'returnDate' },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'company-table-actions',
      render: (asset: AssetRow) => {
        if (deleting.indexOf(asset.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('asset.tab.returned.table.actions.receive')}>
              <DumbLink onClick={receiveAsset(asset.original)} style={{ cursor: 'pointer' }}>
                <Icon type="arrowDownIntoTray" />
              </DumbLink>
            </Tooltip>
            {!asset.hidden && (
              <Tooltip title={t('asset.tab.returned.table.actions.hide')}>
                <DumbLink onClick={hideAsset(asset.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="xSign" />
                </DumbLink>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getAssetRows = (): AssetRow[] => {
    const ac = props.assetCategories.assetCategories.toArray()
    const date = getDate()
    return props.assets.assets
      .toArray()
      .filter((value) => {
        if (value.employeeID != employee.id) {
          return false
        }
        if (!value.receivedDate) {
          // Not received yet
          return false
        }
        if (!value.returnDate) {
          // Not returned yet
          return true
        }
        // The very special case where the return date might be in the future
        const returnDate = getDate(value.returnDate)
        return isTimeBefore(date, returnDate)
      })
      .map((asset: Asset): AssetRow => {
        let title: string = asset.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }

        let date = t('asset.tab.outstanding.table.not_received')
        if (asset.receivedDate) {
          date = formatDate(asset.receivedDate)
        }

        let hasReturnedDate = false
        if (asset.returnDate) {
          const today = getDate()
          const returnDate = getDate(asset.returnDate)
          if (isTimeAfter(returnDate, today)) {
            hasReturnedDate = true
          }
        }

        const categories = ac.find((assetCategory: AssetCategory): boolean => {
          return assetCategory.id == asset.categoryID
        })
        let category = t('common.unknown')
        if (categories) {
          category = categories.title
        }
        return {
          key: asset.id,
          id: asset.id,
          title,
          description: asset.description || '',
          note: asset.note || '',
          category: category,
          receivedDate: date,
          returnDate: '',
          hasReturnedDate: hasReturnedDate,
          hidden: asset.hidden,
          original: asset,
        }
      })
  }

  const getReturnedAssetRows = (): AssetRow[] => {
    const ac = props.assetCategories.assetCategories.toArray()
    const date = getDate()
    return props.assets.assets
      .toArray()
      .filter((value) => {
        if (value.employeeID != employee.id) {
          return false
        }
        if (!value.returnDate) {
          // Not returned yet
          return false
        }
        if (value.hidden) {
          return false
        }
        // The very special case where the return date might be in the future
        const returnDate = getDate(value.returnDate)
        return isSameOrAfter(date, returnDate)
      })
      .map((asset: Asset): AssetRow => {
        let title: string = asset.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }

        const date = formatDate(asset.returnDate!)

        const categories = ac.find((assetCategory: AssetCategory): boolean => {
          return assetCategory.id == asset.categoryID
        })
        let category = t('common.unknown')
        if (categories) {
          category = categories.title
        }
        return {
          key: asset.id,
          id: asset.id,
          title,
          description: asset.description || '',
          note: asset.note || '',
          category: category,
          receivedDate: '',
          returnDate: date,
          hasReturnedDate: true,
          hidden: asset.hidden,
          original: asset,
        }
      })
  }

  return (
    <div>
      <Card className="employee-assets">
        <TitleMenu>
          {props.canEditObjects && (
            <Button onClick={() => setEditVisibility(true)} prefixIcon="user">
              {t('asset.tab.header.add_asset')}
            </Button>
          )}
        </TitleMenu>
        <Title>{t('asset.tab.title.outstanding')}</Title>

        <Table columns={columns} dataSource={getAssetRows()} pagination={false} />
      </Card>

      <Card className="employees-returned-assets">
        <Title>{t('asset.tab.title.returned')}</Title>

        <Table columns={returnedColumns} dataSource={getReturnedAssetRows()} pagination={false} />
      </Card>

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <AssetEdit
          returning={returning}
          companyID={companyID}
          visible={editing !== false}
          employeeID={props.employee.id}
          editing={mutable}
          full={true}
          assetCategories={props.assetCategories.assetCategories}
          assets={props.assets}
          assetID={typeof editing === 'boolean' ? undefined : editing}
          createAsset={props.createAsset}
          updateAsset={props.updateAsset}
        />
      </Modal>
      <Modal
        key={`display-${modalKey}`}
        visible={!!displaying}
        onOk={() => setDisplayVisibility(undefined, undefined)}
        onCancel={() => setDisplayVisibility(undefined, undefined)}
        width={582}
        footer={null}
      >
        <AssetDisplay asset={displaying} category={displayingCategory} />
      </Modal>
    </div>
  )
}
