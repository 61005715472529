import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import Employee from '../../model/employee'
import SalaryType from '../../model/salaryType'
import { AlertReducer } from '../../reducers/alerts'
import { EmployeeReducer } from '../../reducers/employees'
import { SwipeReducer } from '../../reducers/swipes'
import { formatDate } from '../../utils/date-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import Icon from '../elements/icon'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import SwipeEmployeeOverview from './SwipeEmployeeOverview'

import './SwipeOverview.css'

type Props = {
  subsection?: string
  alerts: AlertReducer
  company: Company
  employees: EmployeeReducer
  swipes: SwipeReducer
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  approveSwipes: (ids: string[]) => void
  deleteSwipe: (id: string) => void
  updateEmployee: (employee: Employee) => void
}

export default function SwipeOverview(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [approving, setApproving] = useState<string[]>([])

  const getSwipesForApproval = () => {
    return props.swipes.swipes
      .filter(
        (swipe) =>
          !swipe.approved && !approving.some((id) => id === swipe.id) && !deleting.some((id) => id === swipe.id)
      )
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const ids = getSwipesForApproval().map((swipe) => swipe.id)
    setApproving((prev) => [...prev, ...ids])
    props.approveSwipes(ids)
  }
  const approve = (swipeID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, swipeID])
      props.approveSwipes([swipeID])
    }
  }
  const remove = (swipeID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, swipeID])
        props.deleteSwipe(swipeID)
      }
    }
  }

  const showEmployees = () => {
    return props.subsection === 'employees'
  }
  const showHistory = () => {
    return props.subsection === 'history'
  }

  type SwipeRow = {
    id: string
    key: string
    statusText: string
    statusClass: string
    approved: boolean
    settled: boolean
    paid: boolean
    immutable: boolean
    deletable: boolean
    amount: string
    employeeName: string
    title: string
    createdAt: string
  }

  const columns = [
    {
      title: t('swipes.table.header.state'),
      dataIndex: '',
      key: 'xStatusText',
      width: '20%',
      render: (swipe: SwipeRow) => {
        return (
          <span>
            {swipe.statusText}
            <br />
            {!swipe.approved && <DumbLink onClick={approve(swipe.id)}>{t('swipes.table.approve_now')}</DumbLink>}
          </span>
        )
      },
    },
    {
      title: t('swipes.table.header.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
    },
    {
      title: t('swipes.table.header.employee'),
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    {
      title: t('swipes.table.header.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: '20%',
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (swipe: SwipeRow) => {
        if (swipe.approved && !swipe.deletable) {
          return null
        }
        return (
          <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
            {!swipe.approved && (
              <DumbLink onClick={approve(swipe.id)}>
                <Icon type="document" /> {t('swipes.table.actions.approve')}
              </DumbLink>
            )}
            {swipe.deletable && (
              <DumbLink onClick={remove(swipe.id)}>
                <Icon type="xSign" /> {t('swipes.table.actions.delete')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  const getSwipes = (): SwipeRow[] => {
    return props.swipes.swipes
      .filter((swipe) => !deleting.some((id) => id === swipe.id))
      .map((swipe) => {
        let statusText = t('swipes.state.awaiting_approval')
        let className = 'swipe-overview-table-row-warning'
        const approved = approving.some((id) => id === swipe.id) || swipe.approved
        if (approved) {
          if (swipe.paid) {
            statusText = t('swipes.state.paid')
            className = 'swipe-overview-table-row-success'
          } else {
            statusText = t('swipes.state.approved')
          }
        }
        let employeeName = t('common.unknown')
        props.employees.employees.forEach((employee) => {
          if (employee.id === swipe.employeeID) {
            employeeName = employee.name ?? employee.email ?? '-'
          }
        })
        return {
          id: swipe.id,
          key: swipe.id,
          statusText,
          statusClass: className,
          approved,
          settled: swipe.settled,
          paid: swipe.paid,
          immutable: swipe.immutable,
          deletable: swipe.deletable,
          amount: formatCurrency(swipe.amount, 2),
          employeeName,
          title: swipe.title,
          createdAt: formatDate(swipe.createdAt),
        }
      })
      .toArray()
  }
  const getPendingSwipes = () => {
    return getSwipes().filter((swipe) => !swipe.paid)
  }
  const getProcessedSwipes = () => {
    return getSwipes().filter((swipe) => swipe.paid)
  }

  const getRowClassName = (swipe: SwipeRow) => {
    const classNames = []
    classNames.push(swipe.statusClass)
    return classNames.join(' ')
  }

  if (!props.swipes.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (showEmployees()) {
    return (
      <SwipeEmployeeOverview
        alerts={props.alerts}
        company={props.company}
        employees={props.employees}
        swipes={props.swipes}
        salaryTypes={props.salaryTypes}
        removeAlert={props.removeAlert}
        updateEmployee={props.updateEmployee}
      />
    )
  }

  const sumOfUnapproved = getSwipesForApproval().reduce((sum, item) => sum + item.amount, 0)
  return (
    <div className="swipe-overview">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        {getSwipesForApproval().length > 0 && (
          <DumbLink onClick={approveAll}>
            <Button className="gtm-approve-all-swipe-overview">
              {t('swipes.header.approve_all', { amount: formatCurrency(sumOfUnapproved, 2) })}
            </Button>
          </DumbLink>
        )}
        {showHistory() ? (
          <Link to={'/' + paths.SWIPE_OVERVIEW}>
            <Button className="gtm-hide-swipe-overview-history">{t('swipes.header.history.back')}</Button>
          </Link>
        ) : (
          <Link to={'/' + paths.SWIPE_OVERVIEW + '/history'}>
            <Button className="gtm-show-swipe-overview-history">{t('swipes.header.history.show')}</Button>
          </Link>
        )}
        <Link to={'/' + paths.SWIPE_OVERVIEW + '/employees'}>
          <Button className={'gtm-employees-swipe-overview'}>{t('swipes.header.employees')}</Button>
        </Link>
      </TitleMenu>
      <Title>{showHistory() ? t('swipes.title.history') : t('swipes.title.active')}</Title>
      <Table
        columns={columns}
        dataSource={showHistory() ? getProcessedSwipes() : getPendingSwipes()}
        pagination={false}
        className="swipe-overview-table"
        rowClassName={getRowClassName}
      />
    </div>
  )
}
