import React, { useEffect, useState } from 'react'

import Employee from '../../../model/employee'
import { EmployeeReducer } from '../../../reducers/employees'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import { Col, Row } from '../../elements/grid'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  employees: EmployeeReducer
  setNewPassword: (v: boolean) => void
  newPassword: boolean

  resetToNationalID: (employeeID: string) => void
}

export type PayslipPDFPasswordFields = {
  hasPDFPassword?: boolean
  customPDFPassword?: string
  pdfPassword: string
}

function PayslipPDFPasswordForm(props: Props & FormComponentProps<PayslipPDFPasswordFields, PayslipPDFPasswordFields>) {
  const { decorateField, getFieldValue, setFieldValue } = props
  const [error, setError] = useState<Error | null>(null)

  const { employees } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error, error, setError)
  }, [employees, error, setError])

  const onToggle = (value: boolean) => {
    if (!value) {
      props.resetToNationalID(props.employee.id)
    }
  }

  const onSetPassword = () => {
    setFieldValue('pdfPassword', '')
    props.setNewPassword(true)
  }

  return (
    <div className="employees-single-form">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={24}>
          {decorateField('hasPDFPassword', {
            title: t('pay_slips_tab.pdf_password.password_header'),
            valueOnChecked: true,
            noBlur: true,
          })(<Switch onChange={onToggle} />)}
        </Col>
      </Row>
      {getFieldValue('hasPDFPassword') && props.newPassword && (
        <Row>
          <Col span={12}>
            {decorateField('pdfPassword', {
              placeholder: t('pay_slips_tab.pdf_password.password'),
              validate: (val) => {
                if (!val) {
                  return t('pay_slips_tab.pdf_password.missing')
                }

                return null
              },
            })(<Input />)}
          </Col>
          <Col span={12}>
            <Button
              htmlType="submit"
              size="extra-extra-large"
              className="ant-btn-secondary"
              style={{ marginTop: '25px' }}
            >
              {t('pay_slips_tab.pdf_password.save')}
            </Button>
          </Col>
        </Row>
      )}
      {getFieldValue('hasPDFPassword') && !props.newPassword && (
        <Row>
          <Col span={24}>
            <Button onClick={() => onSetPassword()}>{t('pay_slips_tab.pdf_password.set_password')}</Button>
          </Col>
        </Row>
      )}

      {props.employees.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, PayslipPDFPasswordFields, PayslipPDFPasswordFields>({
  mapPropsToFields: (props) => ({
    hasPDFPassword: props.employee.hasPDFPassword,
    customPDFPassword: '',
    pdfPassword: '',
    newPassword: false,
  }),
  onSubmit: (values) => values,
})(PayslipPDFPasswordForm)
