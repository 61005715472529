import { startOfYear } from 'date-fns'
import React, { ReactElement } from 'react'

import VacationCalendar, { VacationDay, VacationDayType, VacationNamedDay } from '../../model/vacationCalendar'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { formatDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatNameVacationDay } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import { VacationNamedDays } from '../../utils/vacation-utils'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Radio from '../elements/radio'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'

function formatDay(day: string) {
  const s = day.split('-')
  const unpad = (val: string) => {
    if (val[0] === '0') {
      return val[1]
    }
    return val
  }
  const d = t('vacation_calendar.date_format', { day: unpad(s[1]), month: unpad(s[0]) })
  let name
  switch (day) {
    case '01-01':
      name = t('vacation_calendar.named_days.new_years_day')
      break
    case '05-01':
      name = t('vacation_calendar.named_days.mayday')
      break
    case '06-05':
      name = t('vacation_calendar.named_days.constitution_day')
      break
    case '12-24':
      name = t('vacation_calendar.named_days.christmas_eve')
      break
    case '12-25':
      name = t('vacation_calendar.named_days.first_day_of_christmas')
      break
    case '12-26':
      name = t('vacation_calendar.named_days.second_day_of_christmas')
      break
    case '12-31':
      name = t('vacation_calendar.named_days.new_years_eve')
      break
    default:
      return d
  }
  // still here, use the name
  return t('vacation_calendar.named_day_format', { name: name, date: d })
}

const comparator = (a: FieldDay, b: FieldDay) => a.day.localeCompare(b.day)

type Props = {
  vacationCalendar: VacationCalendar
  vacationCalendars: VacationCalendarReducer
}

type FieldNamedDay = {
  namedDay: VacationNamedDay
  active: boolean
}

type FieldDay = {
  day: string
  active: boolean
}

type Fields = {
  newDay: Date
  namedDays: FieldNamedDay[]
  days: FieldDay[]
}

export type ResultFields = {
  days: VacationDay[]
}

function VacationCalendarForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const add = (e: React.MouseEvent) => {
    e.preventDefault()

    const { getFieldValue } = props

    const newDay = formatDate(getFieldValue('newDay'), 'MM-dd')

    if (getFieldValue('days').some((day) => day.day === newDay)) {
      return
    }

    const days = getFieldValue('days')
    days.push({ day: newDay, active: true })

    props.setFieldValue('days', days.sort(comparator))
  }

  const { decorateField, decorateAnyField, getFieldValue } = props
  return (
    <div className="vacation-calendar-form">
      {props.getFormError()}
      {getFieldValue('namedDays').map((day: FieldNamedDay, i: number) => {
        return (
          <Row key={`namedDays.${i}`}>
            <Col span={12}>
              <strong>{formatNameVacationDay(day.namedDay)}</strong>
            </Col>
            <Col span={12}>
              <div className="vacation-set-active">
                {decorateAnyField(`namedDays.${i}.active`, {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(
                  <Radio.Group>
                    <Radio value={true}>{t('vacation_calendar.form.active.checked')}</Radio>
                    <Radio value={false}>{t('vacation_calendar.form.active.unchecked')}</Radio>
                  </Radio.Group>
                )}
              </div>
            </Col>
          </Row>
        )
      })}
      {getFieldValue('days').map((day: FieldDay, i: number) => {
        return (
          <Row key={`days.${i}`}>
            <Col span={12}>
              <strong>{formatDay(day.day)}</strong>
            </Col>
            <Col span={12}>
              <div className="vacation-set-active">
                {decorateAnyField(`days.${i}.active`, {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(
                  <Radio.Group>
                    <Radio value={true}>{t('vacation_calendar.form.active.checked')}</Radio>
                    <Radio value={false}>{t('vacation_calendar.form.active.unchecked')}</Radio>
                  </Radio.Group>
                )}
              </div>
            </Col>
          </Row>
        )
      })}
      <Subcard className="calendar-add-day-form">
        <Subtitle>{t('vacation_calendar.form.add_day.title')}</Subtitle>
        <p>{t('vacation_calendar.form.add_day.intro')}</p>
        <Row>
          <Col span={12}>
            {decorateField('newDay', {
              placeholder: t('vacation_calendar.form.add_day.placeholder'),
              skipWrapper: true,
              skipLabel: true,
            })(<DatePicker format={t('date.day_month')} allowClear={false} />)}
          </Col>
          <Col span={12}>
            <Button className="calendar-add-day" onClick={add} prefixIcon="plusCircle">
              {t('vacation_calendar.form.add_day.action')}
            </Button>
          </Col>
        </Row>
        <p style={{ marginTop: '10px' }}>{t('vacation_calendar.form.add_day.note')}</p>
      </Subcard>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.vacationCalendars.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const namedDays: FieldNamedDay[] = []
    VacationNamedDays.forEach((namedDay) => {
      const vacationDay = props.vacationCalendar.days.find((day) => day.namedDay === namedDay)
      const type: VacationDayType = vacationDay ? vacationDay.type : 'Work Day'
      namedDays.push({ namedDay, active: type === 'Vacation Day' })
    })
    const days = props.vacationCalendar.days
      .filter((day) => !!day.day)
      .map((day) => ({ day: day.day || '', active: day.type === 'Vacation Day' }))
    days.sort(comparator)
    return {
      newDay: startOfYear(getDate()),
      namedDays,
      days,
    }
  },
  onSubmit: (values) => {
    const days: VacationDay[] = []
    values.namedDays.forEach((namedDay) => {
      if (!namedDay.active) {
        return
      }
      days.push({ namedDay: namedDay.namedDay, type: 'Vacation Day' })
    })
    values.days.forEach((day) => {
      if (!day.active) {
        return
      }
      days.push({ day: day.day, type: 'Vacation Day' })
    })
    return {
      days,
    }
  },
})(VacationCalendarForm)
