import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { apiClientConnect } from '../actions/companies'
import { getCompanyFeatures } from '../actions/company-features'
import Alert from '../components/elements/alert'
import Button from '../components/elements/button'
import Card from '../components/elements/card'
import Title from '../components/elements/Title'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { regularComponentDidUpdate } from '../utils/component-utils'
import { formatError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
}

type Actions = {
  apiClientConnect: (companyID: string, apiClientID: string, ref: string, needFeature?: string) => void
  getCompanyFeatures: (companyID?: string) => void
}

function WebTokenConnect(props: Reducers & Actions & RouteProps): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const nationalID = props.location.query.nationalID
  const company = props.companies.companies.find((company) => company.nationalID === nationalID)

  const { companyFeatures, getCompanyFeatures } = props

  useEffect(() => {
    if (!company) {
      return
    }
    if (companyFeatures.companyID !== company.id || (!companyFeatures.loaded && !companyFeatures.loading)) {
      getCompanyFeatures(company.id)
    }
  }, [company, companyFeatures, getCompanyFeatures])

  const { companies, location } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        if (location.query.redirect) {
          document.location = location.query.redirect
        }
      }
    }
  }, [companies, previousCompanies, location])

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const handleConfirm = (e: React.MouseEvent) => {
    e.preventDefault()

    const nationalID = location.query.nationalID
    const company = companies.companies.find((company) => company.nationalID === nationalID)

    if (company) {
      const hasFeature = companyFeatures.companyFeatures.some((feature) => feature.featureType === 'API Keys')
      props.apiClientConnect(
        company.id,
        props.location.query.apiClientID,
        props.location.query.ref,
        !hasFeature ? 'Billy' : undefined
      )
    }
  }

  if (props.companies.saving) {
    return (
      <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
        <LoadingOverlay />
      </div>
    )
  }

  if (!company) {
    return (
      <Card>
        <Title>Forbindelsesfejl</Title>
        <p>
          Den virksomhed (CVR: {nationalID}) du prøvede at forbinde til fra Billy, har denne Salary-konto ikke adgang
          til.
        </p>
        <p>
          Du skal logge ud af Salary, og <a href={props.location.query.redirect}>køre integrationen fra Billy</a> igen,
          og derefter logge ind med den bruger der har adgang til kontoen.
        </p>
      </Card>
    )
  }

  const hasFeature = companyFeatures.companyFeatures.some((feature) => feature.featureType === 'API Keys')

  return (
    <Card>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Title>
        Forbind virksomhed (CVR: {nationalID}, {company.name}) til Billy?
      </Title>
      <p>Er du sikker på du vil forbinde din virksomhed i Salary til din virksomhed i Billy?</p>
      <p>
        Når du har godkendt, vil du blive sendt tilbage til Billy. Såfremt du ikke ønsker at forbinde, bør du bruge
        tilbageknappen til at vende tilbage til Billy.
      </p>
      {!hasFeature && (
        <p style={{ fontWeight: 'bold' }}>
          For at forbinde med Billy, skal du bruge Basic-pakken. Men fordi du er Billy-kunde, kan du få Basic-pakken til
          10 kr./lønseddel. Ved at forbinde din virksomhed opgraderer du automatisk til Basic-pakken til halv pris.
        </p>
      )}
      <Button size="extra-extra-large" type="secondary" onClick={handleConfirm}>
        Forbind virksomhed{!hasFeature && ' og opgradér til Basic'}
      </Button>
    </Card>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    companies: state.companies,
    companyFeatures: state.companyFeatures,
  }),
  {
    apiClientConnect: apiClientConnect,
    getCompanyFeatures: getCompanyFeatures,
  }
)(WebTokenConnect)
