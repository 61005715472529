import { valueType } from 'rc-select/es/PropTypes'
import React, { ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'
import Select from '../select'

import './style/css'

type Props = {
  prefixCls?: string
  value?: valueType
  placeholder?: string
  dataSource?: string[]
  filterOption?: (inputValue: string, option: ReactElement) => boolean
  className?: string
  dropdownClassName?: string
  onSelect?: (value: valueType) => void
  onChange?: (value: valueType) => void
  onFocus?: (e: FocusEvent) => void
  onBlur?: (e: FocusEvent) => void
  inputID?: string
  transitionName?: 'slide-up'
  choiceTransitionName?: 'zoom'
  showSearch?: boolean
  optionLabelProp?: 'children'
}

export default function AutoComplete(props: Props): ReactElement | null {
  const {
    className = '',
    transitionName = 'slide-up',
    choiceTransitionName = 'zoom',
    showSearch = false,
    prefixCls = 'ant-select',
    optionLabelProp = 'children',
    dataSource,
  } = props

  const cls = classNames({
    [className]: !!className,
    [`${prefixCls}-show-search`]: true,
    [`${prefixCls}-auto-complete`]: true,
  })

  const options = dataSource
    ? dataSource.map((item) => {
        return <Select.Option key={item}>{item}</Select.Option>
      })
    : []

  return (
    <Select
      {...props}
      prefixCls={prefixCls}
      className={cls}
      mode="combobox"
      optionLabelProp={optionLabelProp}
      transitionName={transitionName}
      choiceTransitionName={choiceTransitionName}
      showSearch={showSearch}
    >
      {options}
    </Select>
  )
}
