import RcMenu from 'rc-menu'
import React, { PropsWithChildren, ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'

import './style/css'

type Props = {
  prefixCls?: string
  id?: string
  className?: string
  mode?: 'vertical' | 'horizontal'
  selectedKeys?: string[]
  onClick?: (e: React.MouseEvent) => void
  onOpenChange?: (keys: string[]) => void
}

export default function Menu(props: PropsWithChildren<Props>): ReactElement | null {
  const { prefixCls = 'ant-menu', className } = props

  const menuClassName = classNames(prefixCls, className)

  const menuProps = {
    className: menuClassName,
    mode: props.mode,
  }

  return <RcMenu prefixCls={prefixCls} {...props} {...menuProps} />
}
