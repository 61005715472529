import React, { CSSProperties, ReactElement, useLayoutEffect } from 'react'

import { iconToComponent } from './iconToComponent'

import '../Elements.css'

// NEED_NO_TRANSLATION

type Props = {
  type: keyof typeof iconToComponent
  style?: CSSProperties
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
}

export default function Icon(props: Props): ReactElement {
  const IconComponent = iconToComponent[props.type]
  const ref = React.createRef<SVGSVGElement>()

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const colour = window.getComputedStyle(ref.current).getPropertyValue('color')
    const fills = ref.current.getElementsByClassName('icon-fill')
    if (fills.length === 0) {
      return
    }
    if (window.getComputedStyle(fills[0]).getPropertyValue('fill') === colour) {
      // something already modifying the colour, let's bail
      return
    }
    for (let i = 0; i < fills.length; i++) {
      fills[i].setAttribute('fill', colour)
    }
  })

  return (
    <IconComponent
      ref={ref}
      className={'icon' + (props.className ? ` ${props.className}` : '')}
      style={props.style}
      onClick={props.onClick}
    />
  )
}
