import React, { PropsWithChildren, ReactElement } from 'react'

import Radio from '../radio'

type Props<PageType extends string> = {
  value: PageType
}

export default function Item<PageType extends string>(props: PropsWithChildren<Props<PageType>>): ReactElement | null {
  return <Radio.Button value={props.value}>{props.children}</Radio.Button>
}
