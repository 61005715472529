import React, { PropsWithChildren, ReactElement } from 'react'

import { useRadioGroupContext } from './group'
import Radio from './radio'

type Props<DataType> = {
  prefixCls?: string
  value: DataType
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
}

export default function RadioButton<DataType = string>(props: PropsWithChildren<Props<DataType>>): ReactElement | null {
  const { prefixCls = 'ant-radio-button', ...radioProps } = props

  const { groupState, onChange } = useRadioGroupContext()

  if (groupState) {
    radioProps.onChange = onChange
    radioProps.checked = props.value === groupState.value
    radioProps.disabled = props.disabled || groupState.disabled
  }

  return <Radio prefixCls={prefixCls} {...radioProps} />
}
