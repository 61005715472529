import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import AssetCategory, { AssetCategoryMutableFields } from '../../../model/assetCategory'
import Company from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import { AlertReducer } from '../../../reducers/alerts'
import { AssetCategoryReducer } from '../../../reducers/assetCategories'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import Alerts from '../../widgets/Alerts'
import DumbLink from '../../widgets/DumbLink'
import AssetCategoryEdit from './AssetCategoryEdit'

type Props = {
  alerts: AlertReducer
  assetCategories: AssetCategoryReducer
  companyUser?: CompanyUser
  company: Company

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  createAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  updateAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  deleteAssetCategory: (assetCategoryID: string) => void
  getAssetCategories: () => void
}

export default function AssetCategoriesTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [modalKey, setModalKey] = useState<number>(1)
  const [editing, setEditing] = useState<boolean | string>(false)
  const [deleting, setDeleting] = useState<string[]>([])

  const setEditVisibility = useCallback(
    (id: boolean | string) => {
      setModalKey((prev) => prev + 1)
      setEditing(id)
    },
    [setModalKey, setEditing]
  )

  const { assetCategories } = props
  const previousAssetCategories = usePrevious(props.assetCategories)

  useEffect(() => {
    if (previousAssetCategories && previousAssetCategories.saving && !assetCategories.saving) {
      if (!assetCategories.error) {
        setEditVisibility(false)
      }
    }
  }, [previousAssetCategories, assetCategories, setEditVisibility])

  const assetCategoryError = props.assetCategories.error

  useEffect(() => {
    regularComponentDidUpdate(assetCategoryError, error, setError)
  }, [error, assetCategoryError])

  const remove = (assetCategory: AssetCategory) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, assetCategory.id])
        props.deleteAssetCategory(assetCategory.id)
      }
    }
  }

  type AssetCategoryRow = {
    key: string
    id: string
    title: string
    original: AssetCategory
  }

  const columns = [
    { title: t('asset_category.table.title'), dataIndex: 'title', key: 'title' },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'company-table-actions',
      render: (assetCategory: AssetCategoryRow) => {
        if (deleting.indexOf(assetCategory.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('asset_category.table.action.edit')}>
              <span onClick={() => setEditVisibility(assetCategory.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            {
              <Tooltip title={t('asset_category.table.action.delete')}>
                <DumbLink onClick={remove(assetCategory.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="xSign" />
                </DumbLink>
              </Tooltip>
            }
          </div>
        )
      },
    },
  ]

  const getAssetCategoryRows = (): AssetCategoryRow[] => {
    return props.assetCategories.assetCategories.toArray().map((assetCategory: AssetCategory): AssetCategoryRow => {
      let title: string | undefined = assetCategory.title
      if (title.length > 22) {
        title = title.substring(0, 20) + '...'
      }
      return {
        key: assetCategory.id,
        id: assetCategory.id,
        title,
        original: assetCategory,
      }
    })
  }

  const companyID = props.company.id
  const { getAssetCategories } = props

  useEffect(() => {
    if (assetCategories.companyID !== companyID || (!assetCategories.loading && !assetCategories.loaded)) {
      getAssetCategories()
    }
  }, [companyID, assetCategories, getAssetCategories])

  return (
    <Card className="assetCategories">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <TitleMenu
        style={{
          minWidth: '500px',
          textAlign: 'right',
        }}
      >
        <Link to={'/' + paths.ASSETS + '/'}>
          <Button>{t('asset_category.header.assets')}</Button>
        </Link>
        <Button type="primary" onClick={() => setEditVisibility(true)} prefixIcon="plusCircle">
          {t('asset_category.header.add_asset_category')}
        </Button>
      </TitleMenu>
      <Title>{t('asset_category.header.title')}</Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getAssetCategoryRows()} pagination={false} />

      <Modal
        key={`new-${modalKey}`}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={460}
        footer={null}
      >
        <AssetCategoryEdit
          visible={editing !== false}
          company={props.company}
          createAssetCategory={props.createAssetCategory}
          updateAssetCategory={props.updateAssetCategory}
          assetCategories={props.assetCategories}
          assetCategoryID={typeof editing === 'boolean' ? undefined : editing}
        />
      </Modal>
    </Card>
  )
}
