import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Employee, { AffiliationType } from '../../model/employee'
import EmploymentPosition from '../../model/employmentPosition'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import SupplementType from '../../model/supplementType'
import { getDate, isSameOrAfter } from '../../utils/date-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import ContractPayment from '../form-elements/ContractPayment'
import ContractPosition from '../form-elements/ContractPosition'
import ContractWorkplace from '../form-elements/ContractWorkplace'
import { RemunerationFields, SalaryDefinitionFields, SupplementDefinitionFields } from './types'

type Fields = {
  employmentPositionID?: [string, string]
  position?: string
  payType: EmployeePayType
  salaryCycleID?: string
  remuneration?: {
    salary: SalaryDefinitionFields[]
  }
  productionUnitID?: string
}

export type EmploymentStepResult = {
  employmentPositionID?: [string, string]
  position?: string
  payType: EmployeePayType
  salaryCycleID?: string
  remuneration: {
    salary: SalaryDefinitionFields[]
  }
  productionUnitID: string
  step: 'EmploymentStep'
}

type Props = {
  employee: Employee
  company: Company
  employmentPositions: List<EmploymentPosition>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  supplementTypes: List<SupplementType>

  employmentPositionID?: [string, string]
  payType: EmployeePayType
  position?: string
  productionUnitID?: string
  salaryCycleID?: string
  remuneration: RemunerationFields
  affiliationType: AffiliationType
}

function EmploymentStep(props: Props & FormComponentProps<Fields, EmploymentStepResult>): ReactElement | null {
  return (
    <div>
      <Subtitle>{t('contracts_add.employment_step.title')}</Subtitle>
      <p>{t('contracts_add.employment_step.intro')}</p>
      {props.getFormError()}
      <ContractPosition<Fields>
        decorateField={props.decorateField}
        setFieldValue={props.setFieldValue}
        getFieldValue={props.getFieldValue}
        employmentPositions={props.employmentPositions}
      />
      <ContractPayment<Fields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setFieldValue={props.setFieldValue}
        company={props.company}
        employee={props.employee}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
      />
      <ContractWorkplace<Fields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        productionUnits={props.company.productionUnits}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" suffixIcon="arrowRight">
            {t('contracts_add.employment_step.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, EmploymentStepResult>({
  mapPropsToFields: (props) => ({
    employmentPositionID: props.employmentPositionID,
    payType: props.payType,
    position: props.position,
    productionUnitID: props.productionUnitID,
    salaryCycleID: props.salaryCycleID,
    remuneration: props.remuneration,
  }),
  onChange: (key, val) => {
    const values = {}
    switch (key) {
      case 'employmentPositionID':
        // only set when array
        if (Array.isArray(val)) {
          setByPath(values, key, val)
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values, props) => {
    let salary: SalaryDefinitionFields[] = props.remuneration.salary
    let supplements: SupplementDefinitionFields[] = props.remuneration.supplements
    if (values.payType !== 'Commissioned') {
      const salaryType = props.salaryTypes.find(
        (salaryType) => salaryType.name === (values.payType === 'Hourly Paid' ? 'Hourly' : 'Fixed')
      )
      if (salaryType) {
        if (salary.length === 0) {
          salary.push({
            salaryTypeID: salaryType.id,
            title: salaryType.title || undefined,
          })
        } else if (salary[0].salaryTypeID !== salaryType.id) {
          salary[0].salaryTypeID = salaryType.id
          salary[0].title = salaryType.title || salary[0].title
        }
      }
    } else {
      // filter lines not SupplementVaried
      salary = salary.filter((line) =>
        props.salaryTypes.some(
          (salaryType) => salaryType.id === line.salaryTypeID && salaryType.class === 'SupplementVaried'
        )
      )
    }
    // Make sure the great prayer day is in effect
    if (isSameOrAfter(getDate(), getDate('2024-01-01'))) {
      const gpdsupplement = props.supplementTypes.find(
        (supplement) => supplement.name == 'DenmarkGreatPrayerDaySupplement'
      )
      if (gpdsupplement) {
        supplements = supplements.filter((supplement) => supplement.typeID !== gpdsupplement.id)
        if (
          values.payType === 'Salaried' &&
          props.affiliationType !== 'Director' &&
          props.company.settingsEnabled.some((setting) => setting === 'GreatPrayerDayEnableDefault')
        ) {
          supplements.push({
            typeID: gpdsupplement.id,
            compensationRate: 0.0045,
          })
        }
      }
    }
    return {
      ...values,
      productionUnitID: values.productionUnitID!,
      remuneration: {
        ...values.remuneration,
        salary,
        supplements,
      },
      step: 'EmploymentStep',
    }
  },
})(EmploymentStep)
