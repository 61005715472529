import Contract, { ContractCreationFields, ContractMutableFields } from '../model/contract'
import ContractDelta from '../model/contractDelta'
import Remuneration, { RemunerationCreationFields, RemunerationMutableFields } from '../model/remuneration'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchContracts(companyID: string, limit: number, offset: number): Promise<RequestResponse<Contract[]>> {
  return secureRequest('GET', url('/v2/employeeContracts', { companyID, limit, offset }))
}

export function remunerationFix(
  remuneration?: Remuneration | RemunerationCreationFields | RemunerationMutableFields
): RemunerationCreationFields | undefined {
  if (!remuneration) {
    return undefined
  }
  return {
    benefits: remuneration.benefits.map((benefit) => {
      return {
        title: benefit.title,
        amount: benefit.amount,
        type: benefit.type,
        carValuation: benefit.carValuation,
        carAcquisitionDate: benefit.carAcquisitionDate,
        carFirstLicensePlateDate: benefit.carFirstLicensePlateDate,
        yearlyEnvironmentalTax: benefit.yearlyEnvironmentalTax,
        carMajorityShareholder: benefit.carMajorityShareholder,
        customerNumber: benefit.customerNumber,
      }
    }),
    leave: remuneration.leave.map((leave) => {
      return {
        typeID: leave.typeID,
        days: leave.days,
        rate: leave.rate,
      }
    }),
    pension: remuneration.pension.map((pension) => {
      return {
        title: pension.title,
        percentage: pension.percentage,
        fixedAmount: pension.fixedAmount,
        pensionCompanyID: pension.pensionCompanyID,
        pensionCustomerID: pension.pensionCustomerID,
        sortCode: pension.sortCode,
        destinationType: pension.destinationType,
        account: pension.account,
        scheme: pension.scheme,
        unionAgreementNumber: pension.unionAgreementNumber,
        pensionType: pension.pensionType,
      }
    }),
    salary: remuneration.salary.map((salary) => {
      return {
        title: salary.title ? salary.title : undefined,
        salaryTypeID: salary.salaryTypeID,
        rate: salary.rate,
        quantity: salary.quantity,
      }
    }),
    supplements: remuneration.supplements.map((supplement) => {
      return {
        typeID: supplement.typeID,
        compensationRate: supplement.compensationRate,
      }
    }),
  }
}

export function postContract(
  employmentID: string,
  contract: ContractCreationFields
): Promise<RequestResponse<Contract>> {
  return secureRequest('POST', url('/v2/employeeContracts'), {
    body: {
      employmentID,
      contractTemplateID: contract.contractTemplateID,
      productionUnitID: contract.productionUnitID,
      salaryCycleID: contract.salaryCycleID,
      remuneration: remunerationFix(contract.remuneration),
      validFrom: contract.validFrom,
      validTo: contract.validTo,
      employmentPositionID: contract.employmentPositionID,
      position: contract.position,
      timeRegistrationMethodType: contract.timeRegistrationMethodType,
      carAllowanceRegistrationMethodType: contract.carAllowanceRegistrationMethodType,
      salaryRegistrationMethodType: contract.salaryRegistrationMethodType,
      workCycle: contract.workCycle,
      workCycleHours: contract.workCycleHours,
      workCycleAnchorDate: contract.workCycleAnchorDate,
      periodWorkHours: contract.periodWorkHours,
      employmentType: contract.employmentType,
      workSchedule: contract.workSchedule,
      extraTaxPercentage: contract.extraTaxPercentage,
      carAllowanceRate: contract.carAllowanceRate,
      vacationFundCVR: contract.vacationFundCVR,
      familyLeaveFund: contract.familyLeaveFund,
      manualVacationFund: contract.manualVacationFund,
      dayLaborer: contract.dayLaborer,
    },
  })
}

export function putContract(
  contract: ContractMutableFields,
  removeOrphans: boolean
): Promise<RequestResponse<Contract>> {
  return secureRequest('PUT', url('/v2/employeeContracts/' + contract.id), {
    body: {
      employmentID: contract.employmentID,
      productionUnitID: contract.productionUnitID,
      salaryCycleID: contract.salaryCycleID,
      remuneration: remunerationFix(contract.remuneration),
      validFrom: contract.validFrom,
      validTo: contract.validTo,
      employmentPositionID: contract.employmentPositionID,
      position: contract.position,
      timeRegistrationMethodType: contract.timeRegistrationMethodType,
      carAllowanceRegistrationMethodType: contract.carAllowanceRegistrationMethodType,
      salaryRegistrationMethodType: contract.salaryRegistrationMethodType,
      workCycle: contract.workCycle,
      workCycleHours: contract.workCycleHours,
      workCycleAnchorDate: contract.workCycleAnchorDate,
      periodWorkHours: contract.periodWorkHours,
      employmentType: contract.employmentType,
      workSchedule: contract.workSchedule,
      extraTaxPercentage: contract.extraTaxPercentage,
      carAllowanceRate: contract.carAllowanceRate,
      vacationFundCVR: contract.vacationFundCVR,
      familyLeaveFund: contract.familyLeaveFund,
      manualVacationFund: contract.manualVacationFund,
      dayLaborer: contract.dayLaborer,
      removeOrphans: removeOrphans,
    },
  })
}

export function getContract(contractID: string): Promise<RequestResponse<Contract>> {
  return secureRequest('GET', url('/v2/employeeContracts/' + contractID))
}

type LeaveResponse = {
  contractBefore?: Contract
  contractAfter?: Contract
}

export function postCreateLeaveForEmployee(
  employeeID: string,
  leaveFrom: DateFormat,
  leaveTo: DateFormat
): Promise<RequestResponse<LeaveResponse>> {
  return secureRequest('POST', url('/v2/employees/' + employeeID + '/leave'), {
    body: {
      leaveFrom,
      leaveTo,
    },
  })
}

export function deleteRemoveLeaveForContract(contractID: string): Promise<RequestResponse<Contract>> {
  return secureRequest('DELETE', url('/v2/employeeContracts/' + contractID + '/leave'))
}

export function delContract(contractID: string, removeOrphans: boolean): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/employeeContracts/' + contractID, { removeOrphans }))
}

export function fetchEmployeeContractDeltas(employeeID: string): Promise<RequestResponse<ContractDelta[]>> {
  return secureRequest('GET', url('/v2/employees/' + employeeID + '/contractDeltas'))
}
