import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import SalaryRegistration from '../../../model/salaryRegistration'
import SalaryType from '../../../model/salaryType'
import { formatDate } from '../../../utils/date-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t, translateGroupTitle } from '../../../utils/translation-utils'
import Icon from '../../elements/icon'
import Table from '../../elements/table'
import Tooltip from '../../elements/tooltip'

type Props = {
  employee: Employee
  salaryRegistrations: List<SalaryRegistration>
  salaryTypes: List<SalaryType>
}

export default function DetailedSalaryRegistrationHistoryTab(props: Props): ReactElement | null {
  type SalaryRegistrationRow = {
    date: string
    description: string
    quantity: string
    note?: string
  }
  const columns = [
    { title: t('salary_registrations_tab.detailed.history.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('salary_registrations_tab.detailed.history.table.header.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('salary_registrations_tab.detailed.history.table.header.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('salary_registrations_tab.detailed.history.table.header.note'),
      key: 'x2',
      className: 'employee-table-actions',
      render: (row: SalaryRegistrationRow) => {
        if (!row.note) {
          return null
        }
        return (
          <div>
            <Tooltip title={row.note}>
              <span>
                <Icon type="document" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getSalaryRegistrations = (): SalaryRegistrationRow[] => {
    return props.salaryRegistrations
      .filter((salaryRegistration) => salaryRegistration.state === 'Settled')
      .map((salaryRegistration) => {
        let title: string | undefined = undefined
        const quantity = formatDisplayNumber(salaryRegistration.quantity)
        const salary =
          props.employee.activeContract && props.employee.activeContract.remuneration
            ? props.employee.activeContract.remuneration.salary.find(
                (salary) => salary.salaryTypeID === salaryRegistration.salaryTypeID
              )
            : undefined
        if (salary) {
          title = translateGroupTitle(salary)
        }
        if (!title) {
          title = getSalaryType(props.salaryTypes.toArray(), salaryRegistration.salaryTypeID)?.title || ''
        }
        return {
          key: salaryRegistration.id,
          id: salaryRegistration.id,
          date: formatDate(salaryRegistration.date),
          description: title || t('common.unknown'),
          quantity,
          note: salaryRegistration.note,
        }
      })
      .toArray()
  }

  return (
    <div>
      <Table columns={columns} dataSource={getSalaryRegistrations()} pagination={false} />
    </div>
  )
}
