import React, { ReactElement } from 'react'

import { DateFormat } from '../../model/types'
import { TransferReducer } from '../../reducers/transfers'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  transferID: string
  transfers: TransferReducer
}

type Fields = {
  date: Date
}

export type TransferDateFields = {
  date: DateFormat
}

function TransferDateModalForm(props: Props & FormComponentProps<Fields, TransferDateFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('date', {
            placeholder: t('transfers.date.form.date'),
            validate: (val) => {
              if (!val) {
                return t('transfers.date.form.date.required')
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" block>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.transfers.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, TransferDateFields>({
  mapPropsToFields: (props) => {
    const transfer = props.transfers.transfers.find((transfer) => transfer.id === props.transferID)
    return { date: transfer ? getDate(transfer.paymentDate) : getDate() }
  },
  onSubmit: (values) => ({ date: formatAPIDate(values.date) }),
})(TransferDateModalForm)
