import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { CompanyLogoCreate } from '../../api/company-logos'
import Company, { CompanyMutableFields } from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyLogoReducer } from '../../reducers/companyLogo'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatError } from '../../utils/error-utils'
import { url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import { FileChangeEvent, HandleFileEvents } from '../../utils/upload-utils'
import { Dragger as UploadDragger } from '../antd/upload'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PayslipQuestionsForm, { PayslipQuestionResult } from './PayslipQuestionsForm'

type Props = {
  visible: boolean
  company: Company
  companies: CompanyReducer
  companyLogo: CompanyLogoReducer

  closeModal: () => void
  addAlert: addAlertSignature
  updateCompanyLogo: (logo: CompanyLogoCreate) => void
  deleteCompanyLogo: () => void
  updateCompany: (company: CompanyMutableFields) => void
}

export default function PaySlipFieldsModal(props: Props): ReactElement | null {
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [reduceError, setReduceError] = useState<Error | null>(null)

  const { visible, companyLogo, companies } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, companyLogo.error || companies.error, reduceError, setReduceError)
  }, [visible, companyLogo, companies, reduceError])

  const { addAlert, company, closeModal } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    // Check for save callback
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      // Check for no error occurred
      if (!companies.error) {
        addAlert('success', t('pay_slip_fields.alert.success', { name: company.name }), { timeout: 5 })

        closeModal()
      }
    }
  })

  const handleLogoUpload = (e: FileChangeEvent) =>
    HandleFileEvents(e, {
      onUploading: () => setUploading(true),
      onDone: (data) => {
        if (data) {
          props.updateCompanyLogo({ fileID: data.id })
        }
      },
      onError: (e) => setError(e || null),
      onFinally: () => setUploading(false),
    })

  const handleLogoRemove = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteCompanyLogo()
    }
  }

  const handleSubmit = (values: PayslipQuestionResult) => {
    props.updateCompany({
      ...props.company,
      paySlipQuestionsVisible: values.paySlipQuestionsVisible,
      paySlipQuestionsLink: values.paySlipQuestionsLink,
      allowCustomPDFPasswords: values.allowCustomPDFPasswords,
    })
  }

  const logo = companyLogo.companyLogo

  return (
    <Card className="company-payslip-fields">
      <div className="company-logo">
        <Subtitle>{logo ? t('pay_slip_fields.logo.title.edit') : t('pay_slip_fields.logo.title.create')}</Subtitle>
        <p>{t('pay_slip_fields.logo.form.note')}</p>
        <p>&nbsp;</p>
        {(error || reduceError) && <Alert message={formatError(error || reduceError!)} type="error" showIcon />}
        {logo && (
          <Row>
            <Col span={24}>
              <div className="logo">
                <img src={'data:image/png;base64,' + logo} alt="" />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={logo ? 12 : 24} style={{ textAlign: logo ? 'right' : 'center' }}>
            <UploadDragger
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              accept={'.jpg,.jpeg,.png'}
              showUploadList={false}
              onChange={handleLogoUpload}
            >
              <Button>
                {logo ? t('pay_slip_fields.logo.form.button.edit') : t('pay_slip_fields.logo.form.button.create')}
              </Button>
            </UploadDragger>
          </Col>
          {logo && (
            <Col span={12}>
              <Button onClick={handleLogoRemove}>{t('pay_slip_fields.logo.form.button.delete')}</Button>
            </Col>
          )}
        </Row>
        {(uploading || props.companyLogo.saving) && <LoadingOverlay />}
      </div>
      <div className="company-logo">
        <Subtitle>{t('pay_slip_fields.link.title')}</Subtitle>
        <p>&nbsp;</p>
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <PayslipQuestionsForm company={props.company} companies={props.companies} onSubmit={handleSubmit} />
      </div>
    </Card>
  )
}
