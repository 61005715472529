import { startOfMonth, startOfYear, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import { DateFormat } from '../../model/types'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isSameOrBefore, isTimeAfter } from '../../utils/date-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { decorateFieldSignature, getFieldValueSignature, setFieldValueSignature } from '../../utils/form-utils'
import { getSalaryCyclePeriods } from '../../utils/salary-period-utils'
import { t } from '../../utils/translation-utils'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'

type ContractFirstSalaryPeriodFields = {
  salaryCycleID: string
  payType: EmployeePayType
  salaryPeriod?: SalaryPeriod
  validFrom: DateFormat
}

type Props<Fields extends ContractFirstSalaryPeriodFields> = {
  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  employment: Employment
  salaryCycles: List<SalaryCycle>
  salaryCycleID: string
}

export default function ContractFirstSalaryPeriod<Fields extends ContractFirstSalaryPeriodFields>(
  props: Props<Fields>
): ReactElement | null {
  type SalaryPeriodExtended = SalaryPeriod & {
    first: DateFormat
  }
  const getSalaryPeriods = (): SalaryPeriodExtended[] => {
    const { getFieldValue } = props
    let useFirst = false
    if (getFieldValue('payType') !== 'Salaried') {
      const salaryCycles = props.salaryCycles.filter(
        (item) => item.id === getFieldValue('salaryCycleID') && item.frequency === 'Monthly'
      )
      if (salaryCycles.size > 0) {
        useFirst = true
      }
    }
    const employment = props.employment
    return getSalaryCyclePeriods(props.salaryCycles, props.salaryCycleID)
      .map(
        (salaryPeriod): SalaryPeriodExtended => ({
          ...salaryPeriod,
          first: useFirst ? formatAPIDate(startOfMonth(getDate(salaryPeriod.end))) : salaryPeriod.start,
        })
      )
      .filter(
        (salaryPeriod) =>
          isSameOrBefore(getDate(employment.startDate), getDate(salaryPeriod.latestStartTime)) &&
          isSameOrAfter(getDate(salaryPeriod.latestStartTime), startOfYear(subYears(getDate(), 1)))
      )
      .sort((a, b) => b.start.localeCompare(a.start))
  }

  const updateSelectedSalaryPeriod = (start: DateFormat) => {
    const salaryPeriod = getSalaryCyclePeriods(props.salaryCycles, props.salaryCycleID).find(
      (period) => period.start === start
    )
    props.setFieldValue('salaryPeriod', salaryPeriod)
  }

  const { decorateField } = props

  const employment = props.employment
  return (
    <Row>
      <Col span={12}>
        {decorateField('validFrom', {
          title: t('contract.first_salary_period.valid_from'),
          placeholder: t('contract.first_salary_period.valid_from.placeholder'),
          validate: (val) => {
            if (!val) {
              return t('contract.first_salary_period.valid_from.required')
            }
            if (employment.endDate && isTimeAfter(getDate(val), getDate(employment.endDate))) {
              return t('contract.first_salary_period.valid_from.not_after_end_date')
            }
            return null
          },
        })(
          <Select dropdownMatchSelectWidth={false} onChange={(v) => updateSelectedSalaryPeriod(v as string)}>
            {getSalaryPeriods().map((salaryPeriod) => {
              return (
                <Select.Option key={salaryPeriod.id} value={salaryPeriod.start}>
                  {formatDate(salaryPeriod.start)} - {formatDate(salaryPeriod.end)}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Col>
      <Col span={12} />
    </Row>
  )
}
