import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Icon from '../elements/icon'

type Props = {
  expanded: boolean
  onChange: (newState: boolean) => void
}

export default function PayrollCardToggle(props: Props): ReactElement | null {
  return (
    <span
      onClick={() => props.onChange(!props.expanded)}
      className={'pay-roll-toggle' + (props.expanded ? ' pay-roll-toggle-visible' : '')}
    >
      {props.expanded ? t('pay_roll.single.toggle.hide') : t('pay_roll.single.toggle.show')}
      <Icon type={props.expanded ? 'chevronUp' : 'chevronDown'} style={{ top: '2px', position: 'relative' }} />
    </span>
  )
}
