import arrayTreeFilter from 'array-tree-filter'
import omit from 'omit.js'
import RcCascader from 'rc-cascader'
import KeyCode from 'rc-util/lib/KeyCode'
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { t } from '../../../utils/translation-utils'
import classNames from '../../antd/_util/classNames'
import Icon from '../icon'
import Input from '../input'

import './style/css'

export type CascaderLabel = {
  kind: 'label'
  label: ReactNode
  searchText: string
  value: string
}

export type CascaderGroup = {
  kind: 'group'
  label: ReactNode
  searchText: string
  value: string
  children: CascaderLabel[]
}

type CascaderNode = (CascaderLabel | CascaderGroup) & Record<string, unknown>

function highlightKeyword(str: string, keyword: string, prefixCls: string) {
  return str.split(keyword).map((node, index) =>
    index === 0
      ? node
      : [
          <span className={`${prefixCls}-menu-item-keyword`} key="seperator">
            {keyword}
          </span>,
          node,
        ]
  )
}

function defaultFilterOption(inputValue: string, path: CascaderNode[]) {
  return path.some((option) => !!option.label && option.label.toString().indexOf(inputValue) > -1)
}

function defaultRenderFilteredOption(inputValue: string, path: CascaderNode[], prefixCls: string) {
  return path.map(({ label }, index) => {
    if (!label) {
      return ''
    }
    const node =
      label.toString().indexOf(inputValue) > -1 ? highlightKeyword(label.toString(), inputValue, prefixCls) : label
    return index === 0 ? node : [' / ', node]
  })
}

function defaultSortFilteredOption(a: CascaderNode[], b: CascaderNode[], inputValue: string) {
  function callback(elem: CascaderNode) {
    if (!elem.label) {
      return -1
    }
    return elem.label.toString().indexOf(inputValue) > -1
  }

  return a.findIndex(callback) - b.findIndex(callback)
}

function flattenTree(options: CascaderNode[], changeOnSelect: boolean | undefined, ancestor: CascaderNode[] = []) {
  let flattenOptions: CascaderNode[][] = []
  options.forEach((option) => {
    const path = ancestor.concat(option)
    if (changeOnSelect || option.kind === 'label') {
      flattenOptions.push(path)
    }
    if (option.kind === 'group' && option.children) {
      flattenOptions = flattenOptions.concat(flattenTree(option.children, changeOnSelect, path))
    }
  })
  return flattenOptions
}

const defaultDisplayRender = (label: ReactNode[]) => label.join(' / ')

type Props = {
  prefixCls?: string
  inputPrefixCls?: string
  placeholder?: string
  options: CascaderGroup[]
  onChange?: (value: string[], selectedOptions: CascaderNode[]) => void
  changeOnSelect?: boolean
  disabled?: boolean
  showSearch:
    | {
        filter?: (inputValue: string, path: CascaderNode[]) => boolean
        render?: (inputValue: string, path: CascaderNode[], prefixCls: string) => ReactNode
        sort?: (a: CascaderNode[], b: CascaderNode[], inputValue: string) => number
        matchInputWidth?: boolean
      }
    | false
  displayRender?: (label: ReactNode[], selectedOptions?: CascaderNode[]) => ReactNode
  value?: string[]
  defaultValue?: string[]
  transitionName?: string
  popupPlacement?: 'bottomLeft'
  allowClear?: boolean
  notFoundContent?: string
  size?: 'medium' | 'large' | 'extra-large'
  className?: string
  style?: CSSProperties
}

export default function Cascader(props: Props): ReactElement | null {
  type State = {
    value: string[]
    inputValue: string
    inputFocused: boolean
    popupVisible: boolean
    flattenOptions: CascaderNode[][]
  }
  const [state, setState] = useState<State>({
    value: props.value || props.defaultValue || [],
    inputValue: '',
    inputFocused: false,
    popupVisible: false,
    flattenOptions: (props.showSearch && flattenTree(props.options, props.changeOnSelect)) || [],
  })
  const [cachedOptions, setCachedOptions] = useState<Partial<CascaderNode>[]>([])
  const input = React.createRef<HTMLInputElement>()

  const setValue = (value: string[], selectedOptions: CascaderNode[] = []) => {
    if (!('value' in props)) {
      setState((prev) => ({ ...prev, value }))
    }
    const onChange = props.onChange
    if (onChange) {
      onChange(value, selectedOptions)
    }
  }

  type filteredNode = {
    __IS_FILTERED_OPTION: true
    path: CascaderNode[]
    label: ReactNode
    value: string
    disabled: boolean
  }

  const handleChange = (value: (string | number | null)[], selectedOptions: CascaderNode[]) => {
    setState((prev) => ({ ...prev, inputValue: '' }))
    if (selectedOptions[0].__IS_FILTERED_OPTION) {
      const unwrappedSelectedOptions = selectedOptions[0].path as CascaderNode[]
      const unwrappedValue = unwrappedSelectedOptions.map((p) => p.value)
      setValue(unwrappedValue, unwrappedSelectedOptions)
      return
    }
    setValue(value as string[], selectedOptions)
  }

  const handlePopupVisibleChange = (popupVisible: boolean) => {
    if (!('popupVisible' in props)) {
      setState((prev) => ({
        ...prev,
        popupVisible,
        inputFocused: popupVisible,
        inputValue: popupVisible ? prev.inputValue : '',
      }))
    }
  }

  const handleInputBlur = () => {
    setState((prev) => ({ ...prev, inputFocused: false }))
  }

  const handleInputClick = (e: React.MouseEvent) => {
    const { inputFocused, popupVisible } = state
    // Prevent `Trigger` behaviour.
    if (inputFocused || popupVisible) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === KeyCode.BACKSPACE) {
      e.stopPropagation()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setState((prev) => ({ ...prev, inputValue }))
  }

  const getLabel = () => {
    const { options, displayRender = defaultDisplayRender } = props
    const value = state.value
    const unwrappedValue = Array.isArray(value[0]) ? value[0] : value
    const selectedOptions = arrayTreeFilter(options, (o, level) => o.value === unwrappedValue[level])
    const label = selectedOptions.map((o) => o.label)
    return displayRender(label, selectedOptions)
  }

  const clearSelection = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!state.inputValue) {
      setValue([])
      handlePopupVisibleChange(false)
    } else {
      setState((prev) => ({ ...prev, inputValue: '' }))
    }
  }

  const generateFilteredOptions = (prefixCls: string): Partial<CascaderNode>[] => {
    const { showSearch } = props
    const {
      filter = defaultFilterOption,
      render = defaultRenderFilteredOption,
      sort = defaultSortFilteredOption,
    } = showSearch || {}
    const { flattenOptions, inputValue } = state
    const filtered = flattenOptions.filter((path) => filter(inputValue, path)).sort((a, b) => sort(a, b, inputValue))

    if (filtered.length > 0) {
      return filtered.map(
        (path): filteredNode => ({
          __IS_FILTERED_OPTION: true,
          path,
          label: render(inputValue, path, prefixCls),
          value: path.map((v) => v.value).join(' '),
          disabled: path.some((o) => o.disabled),
        })
      )
    }
    return []
  }

  const propsValue = props.value
  useEffect(() => {
    if (propsValue && propsValue !== state.value) {
      setState((prev) => ({ ...prev, value: propsValue }))
    }
  }, [propsValue, state])

  const {
    prefixCls = 'ant-cascader',
    inputPrefixCls = 'ant-input',
    placeholder = 'Please select',
    transitionName = 'slide-up',
    popupPlacement = 'bottomLeft',
    allowClear = true,
    notFoundContent = t('search.not_found'),
    size,
    disabled = false,
    className,
    style,
    showSearch = false,
    ...otherProps
  } = props

  const sizeCls = classNames({
    [`${inputPrefixCls}-l`]: size === 'large',
    [`${inputPrefixCls}-xl`]: size === 'extra-large',
  })
  const clearIcon =
    (allowClear && !disabled && state.value.length > 0) || state.inputValue ? (
      <Icon type="xSignCircle" className={`${prefixCls}-picker-clear`} onClick={clearSelection} />
    ) : null
  const arrowCls = classNames({
    [`${prefixCls}-picker-arrow`]: true,
    [`${prefixCls}-picker-arrow-expand`]: state.popupVisible,
  })
  const pickerCls = classNames(className, {
    [`${prefixCls}-picker`]: true,
    [`${prefixCls}-picker-with-value`]: state.inputValue,
    [`${prefixCls}-picker-disabled`]: disabled,
  })

  // Fix bug of https://github.com/facebook/react/pull/5004
  // and https://fb.me/react-unknown-prop
  const inputProps = omit(otherProps, ['onChange', 'options', 'displayRender', 'changeOnSelect', 'defaultValue'])

  const { options = [] } = props
  let actualOptions = options as Partial<CascaderNode>[]
  if (state.inputValue) {
    actualOptions = generateFilteredOptions(prefixCls)
  }
  // Dropdown menu should keep previous status until it is fully closed.
  const previousState = usePrevious(state)
  useEffect(() => {
    if (previousState && !previousState.popupVisible && state.popupVisible) {
      setCachedOptions(actualOptions)
    }
  }, [previousState, state, actualOptions])
  if (!state.popupVisible) {
    actualOptions = cachedOptions
  }

  const dropdownMenuColumnStyle: { height?: string; width?: number } = {}
  const isNotFound = actualOptions.length === 0
  if (isNotFound) {
    dropdownMenuColumnStyle.height = 'auto' // Height of one row.
  }
  // The default value of `matchInputWidth` is `true`
  const resultListMatchInputWidth = (showSearch || {}).matchInputWidth !== false
  if (resultListMatchInputWidth && state.inputValue && input.current) {
    dropdownMenuColumnStyle.width = input.current.offsetWidth
  }

  const innerInput = (
    <span style={style} className={pickerCls}>
      <span className={`${prefixCls}-picker-label`}>{getLabel()}</span>
      <Input
        {...inputProps}
        forwardRef={input}
        placeholder={state.value && state.value.length > 0 ? undefined : placeholder}
        className={`${prefixCls}-input ${sizeCls}`}
        value={state.inputValue}
        disabled={disabled}
        readOnly={!showSearch}
        autoComplete="off"
        onClick={showSearch ? handleInputClick : undefined}
        onBlur={showSearch ? handleInputBlur : undefined}
        onKeyDown={handleKeyDown}
        onChange={showSearch ? handleInputChange : undefined}
        selectAllOnFocus={false}
      />
      {clearIcon}
      <Icon type={state.popupVisible ? 'chevronUp' : 'chevronDown'} className={arrowCls} />
    </span>
  )

  return (
    <RcCascader
      {...props}
      prefixCls={prefixCls}
      placeholder={placeholder}
      options={actualOptions as CascaderGroup[]}
      value={state.value}
      popupVisible={state.popupVisible}
      onPopupVisibleChange={handlePopupVisibleChange}
      onChange={handleChange}
      dropdownMenuColumnStyle={dropdownMenuColumnStyle}
      transitionName={transitionName}
      placement={popupPlacement}
      allowClear={allowClear}
      notFoundContent={notFoundContent}
    >
      {innerInput}
    </RcCascader>
  )
}
