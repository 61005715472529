import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import SlackIntegrationComponent from '../components/slack-integration/SlackIntegration'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

function SlackIntegration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <IntegrationsLayout location={props.location}>
      <SlackIntegrationComponent
        alerts={props.alerts}
        company={company}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(SlackIntegration)
