import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import PensionCompany, { PensionCustomerIDType } from '../../model/pensionCompany'
import PensionScheme from '../../types/pension-scheme'
import {
  decorateAnyFieldSignature,
  decorateFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
} from '../../utils/form-utils'
import { formatPensionCustomerIDType, formatPensionScheme } from '../../utils/format-utils'
import { parseInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Switch from '../elements/switch'
import Tooltip from '../elements/tooltip'

export type PensionRow = {
  pensionCompanyID: string
  customerIDType?: PensionCustomerIDType
  suffix: 'percentage' | 'fixedAmount'
  pensionCompanyName?: string
  pensionCustomerID?: string
  hasUnionAgreementNumber: boolean
  scheme?: PensionScheme
  unionAgreementNumber?: string
  value?: string
}

type PensionFields = {
  hasPension: boolean
  hasNetPension: boolean
  hasUnpaidVacation: boolean
  includeVacationInPensionBasis: boolean
  remuneration: {
    pension: PensionRow[]
  }
}

type Props<Fields extends PensionFields> = {
  decorateField: decorateFieldSignature<Fields>
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
  settingsEnabled: CompanySetting[]
  employee: Employee
  pensionCompanies: List<PensionCompany>
}

const NO_PENSION_COMPANY = 'NoPensionCompany'

export default function PensionForm<Fields extends PensionFields>(props: Props<Fields>): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    const row: PensionRow = {
      pensionCompanyID: NO_PENSION_COMPANY,
      customerIDType: undefined,
      suffix: 'percentage',
      hasUnionAgreementNumber: true,
    }
    if (remuneration.pension.length > 0) {
      const latestRow = remuneration.pension[remuneration.pension.length - 1]
      row.pensionCompanyID = latestRow.pensionCompanyID
      row.pensionCompanyName = latestRow.pensionCompanyName
      row.customerIDType = latestRow.customerIDType
      row.pensionCustomerID = latestRow.pensionCustomerID
      row.hasUnionAgreementNumber = latestRow.hasUnionAgreementNumber
      if (remuneration.pension.length === 1) {
        switch (latestRow.scheme) {
          case PensionScheme.EMPLOYEE_PAID:
            row.scheme = PensionScheme.EMPLOYER_PAID
            break
          case PensionScheme.EMPLOYER_PAID:
            row.scheme = PensionScheme.EMPLOYEE_PAID
            break
          default:
            break
        }
      }
    }
    setAnyFieldValue(`remuneration.pension.${remuneration.pension.length}`, row)
  }
  const remove = (i: number) => {
    const { getFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    remuneration.pension.splice(i, 1)
    setAnyFieldValue('remuneration.pension', remuneration.pension)
  }

  const getCustomerIDType = (value?: string) => {
    let customerIDType = undefined
    if (value !== NO_PENSION_COMPANY) {
      customerIDType = props.pensionCompanies.find((company) => company.id === value)?.customerIDType
    }
    return customerIDType
  }
  const hasUnionAgreementNumber = () => {
    const { getFieldValue } = props
    const pension = getFieldValue('remuneration').pension
    return pension.some((row) => row.hasUnionAgreementNumber)
  }
  const hasFilledUnionAgreementNumber = () => {
    const { getFieldValue } = props
    const pension = getFieldValue('remuneration').pension
    return pension.some((row) => {
      return row.hasUnionAgreementNumber && row.unionAgreementNumber && row.unionAgreementNumber !== ''
    })
  }

  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldValue, setAnyFieldValue, getAnyFieldError } = props

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('hasPension', {
            skipLabel: true,
          })(
            <Radio.Group>
              <Radio value={false}>{t('pension.card.edit.form.has_pension.false')}</Radio>
              <Radio value={true}>{t('pension.card.edit.form.has_pension.true')}</Radio>
            </Radio.Group>
          )}
        </Col>
      </Row>
      {getFieldValue('hasPension') && props.settingsEnabled.some((setting) => setting === 'AllowNetPension') && (
        <div className="ant-switch-wrapper">
          {decorateField('hasNetPension', {
            placeholder: t('pension.card.edit.form.has_net_pension'),
            valueOnChecked: true,
            noBlur: true,
            skipLabel: true,
            skipWrapper: true,
          })(<Switch />)}
          <span className="ant-switch-text">{t('pension.card.edit.form.has_net_pension')}</span>
        </div>
      )}
      {getFieldValue('hasPension') && (
        <div>
          <Subcard>
            <Row style={{ marginBottom: '-10px' }}>
              <Col span={hasUnionAgreementNumber() ? 5 : 6}>
                <label>{t('pension.card.edit.form.table.company')}</label>
              </Col>
              <Col span={hasUnionAgreementNumber() ? 6 : 7}>
                <label>{t('pension.card.edit.form.table.customer_id')}</label>
              </Col>
              <Col
                span={4}
                style={{
                  display: hasUnionAgreementNumber() ? 'block' : 'none',
                }}
              >
                <label>{t('pension.card.edit.form.table.union_agreement_number')}</label>
              </Col>
              <Col span={hasUnionAgreementNumber() ? 4 : 5}>
                <label>{t('pension.card.edit.form.table.scheme')}</label>
              </Col>
              <Col span={hasUnionAgreementNumber() ? 5 : 6}>
                <label>{t('pension.card.edit.form.table.rate')}</label>
              </Col>
            </Row>
            {(getAnyFieldValue('remuneration.pension') as PensionRow[]).map((pension, i) => {
              pension.customerIDType =
                pension.customerIDType || getCustomerIDType(pension.pensionCompanyID || NO_PENSION_COMPANY)
              const canSelectPercentage = !(
                getAnyFieldValue(`remuneration.pension.${i}.scheme`) === PensionScheme.EMPLOYEE_PAID &&
                getFieldValue('hasNetPension')
              )
              const hasError =
                getAnyFieldError(`remuneration.pension.${i}.pensionCompanyID`) ||
                getAnyFieldError(`remuneration.pension.${i}.pensionCustomerID`) ||
                getAnyFieldError(`remuneration.pension.${i}.unionAgreementNumber`) ||
                getAnyFieldError(`remuneration.pension.${i}.sortCode`) ||
                getAnyFieldError(`remuneration.pension.${i}.account`) ||
                getAnyFieldError(`remuneration.pension.${i}.scheme`) ||
                getAnyFieldError(`remuneration.pension.${i}.value`) ||
                getAnyFieldError(`remuneration.pension.${i}.suffix`)
              return (
                <Form.Item key={i} validateStatus={hasError ? 'error' : 'success'}>
                  {hasError && (
                    <Row>
                      <Col span={hasUnionAgreementNumber() ? 5 : 6} className="form-error">
                        {getAnyFieldError(`remuneration.pension.${i}.pensionCompanyID`)}
                      </Col>
                      <Col span={hasUnionAgreementNumber() ? 6 : 7} className="form-error">
                        {getAnyFieldError(`remuneration.pension.${i}.pensionCustomerID`) ||
                          getAnyFieldError(`remuneration.pension.${i}.sortCode`) ||
                          getAnyFieldError(`remuneration.pension.${i}.account`)}
                      </Col>
                      {hasUnionAgreementNumber() && (
                        <Col span={4} className="form-error">
                          {getAnyFieldError(`remuneration.pension.${i}.unionAgreementNumber`)}
                        </Col>
                      )}
                      <Col span={hasUnionAgreementNumber() ? 4 : 5} className="form-error">
                        {getAnyFieldError(`remuneration.pension.${i}.scheme`)}
                      </Col>
                      <Col span={hasUnionAgreementNumber() ? 5 : 6} className="form-error">
                        {getAnyFieldError(`remuneration.pension.${i}.value`) ||
                          getAnyFieldError(`remuneration.pension.${i}.suffix`)}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={hasUnionAgreementNumber() ? 5 : 6}>
                      {decorateAnyField(`remuneration.pension.${i}.pensionCompanyID`, {
                        placeholder: t('pension.card.edit.form.company'),
                        validate: (val) => (!val ? t('pension.card.edit.form.company.required') : null),
                        skipWrapper: true,
                        skipLabel: true,
                        trigger: 'onChange',
                        noBlur: true,
                      })(
                        <Select
                          dropdownMatchSelectWidth={false}
                          showSearch={true}
                          filterOption={(inputValue: string, option: ReactElement) => {
                            const children = option.props.children
                            const item = typeof children === 'string' ? children : children.join('')
                            return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                          }}
                          onChange={(value) => {
                            let customerIDType: PensionCustomerIDType | undefined = undefined
                            let hasUnionAgreementNumber = true
                            if (value !== NO_PENSION_COMPANY) {
                              props.pensionCompanies.forEach((company) => {
                                if (company.id === value) {
                                  setAnyFieldValue(`remuneration.pension.${i}.pensionCompanyName`, company.name)
                                  customerIDType = company.customerIDType
                                  hasUnionAgreementNumber =
                                    company.fields && company.fields.indexOf('UnionAgreementNumber') !== -1
                                }
                              })
                            }
                            setAnyFieldValue(`remuneration.pension.${i}.customerIDType`, customerIDType)
                            setAnyFieldValue(
                              `remuneration.pension.${i}.hasUnionAgreementNumber`,
                              hasUnionAgreementNumber
                            )
                            if (!getAnyFieldValue(`remuneration.pension.${i}.pensionCustomerID`)) {
                              setAnyFieldValue(
                                `remuneration.pension.${i}.pensionCustomerID`,
                                customerIDType === 'NationalID' ? props.employee.nationalID : ''
                              )
                            }
                          }}
                          size="medium"
                        >
                          <Select.Option key={NO_PENSION_COMPANY} value={NO_PENSION_COMPANY}>
                            {t('pension.card.edit.form.company.no_company')}
                          </Select.Option>
                          {props.pensionCompanies
                            .filter(
                              (company) =>
                                company.active ||
                                company.id === getAnyFieldValue(`remuneration.pension.${i}.pensionCompanyID`)
                            )
                            .map((company) => {
                              return (
                                <Select.Option key={company.id} value={company.id}>
                                  {company.name} ({company.paymentID})
                                </Select.Option>
                              )
                            })}
                        </Select>
                      )}
                    </Col>
                    <Col
                      span={hasUnionAgreementNumber() ? 6 : 7}
                      style={{
                        display: pension.customerIDType !== undefined ? 'block' : 'none',
                      }}
                    >
                      <div
                        className={
                          'ant-form-item-control' +
                          (getAnyFieldError(`remuneration.pension.${i}.pensionCustomerID`) ? ' has-error' : '')
                        }
                      >
                        {decorateAnyField(`remuneration.pension.${i}.pensionCustomerID`, {
                          placeholder: formatPensionCustomerIDType(pension.customerIDType),
                          validate: (val) => {
                            if (getAnyFieldValue(`remuneration.pension.${i}.pensionCompanyID`) === NO_PENSION_COMPANY) {
                              return null
                            }
                            if (!val) {
                              return t('pension.card.edit.form.customer_id.required', {
                                name: formatPensionCustomerIDType(pension.customerIDType),
                              })
                            }
                            return null
                          },
                          skipWrapper: true,
                          skipLabel: true,
                        })(<Input />)}
                      </div>
                    </Col>
                    <Col
                      span={
                        getAnyFieldValue(`remuneration.pension.${i}.hasUnionAgreementNumber`)
                          ? 6
                          : hasUnionAgreementNumber()
                          ? 10
                          : 7
                      }
                      style={{
                        display: pension.customerIDType === undefined ? 'block' : 'none',
                      }}
                    >
                      <Input.Group compact>
                        {decorateAnyField(`remuneration.pension.${i}.sortCode`, {
                          placeholder: t('pension.card.edit.form.sort_code'),
                          validate: (val) => {
                            if (getAnyFieldValue(`remuneration.pension.${i}.pensionCompanyID`) !== NO_PENSION_COMPANY) {
                              return null
                            }
                            if (!val) {
                              return t('pension.card.edit.form.sort_code.required')
                            }
                            if (!val.match(/^[0-9]{4}$/)) {
                              return t('pension.card.edit.form.sort_code.invalid')
                            }
                            return null
                          },
                          skipWrapper: true,
                          skipLabel: true,
                        })(<Input style={{ width: '35%' }} />)}
                        {decorateAnyField(`remuneration.pension.${i}.account`, {
                          placeholder: t('pension.card.edit.form.account'),
                          validate: (val) => {
                            if (getAnyFieldValue(`remuneration.pension.${i}.pensionCompanyID`) !== NO_PENSION_COMPANY) {
                              return null
                            }
                            if (!val) {
                              return t('pension.card.edit.form.account.required')
                            }
                            if (!val.match(/^[0-9]{6,10}$/)) {
                              return t('pension.card.edit.form.account.invalid')
                            }
                            return null
                          },
                          skipWrapper: true,
                          skipLabel: true,
                        })(<Input style={{ width: '65%' }} />)}
                      </Input.Group>
                    </Col>
                    {((hasUnionAgreementNumber() && pension.customerIDType !== undefined) ||
                      getAnyFieldValue(`remuneration.pension.${i}.hasUnionAgreementNumber`)) && (
                      <Col span={4}>
                        {!!getAnyFieldValue(`remuneration.pension.${i}.hasUnionAgreementNumber`) && (
                          <div
                            className={
                              'ant-form-item-control' +
                              (getAnyFieldError(`remuneration.pension.${i}.unionAgreementNumber`) ? ' has-error' : '')
                            }
                          >
                            <Tooltip
                              title={
                                <>
                                  {t('pension.card.edit.form.union_agreement_number.tooltip.line_1')}
                                  <br />
                                  {t('pension.card.edit.form.union_agreement_number.tooltip.line_2')}
                                </>
                              }
                            >
                              {decorateAnyField(`remuneration.pension.${i}.unionAgreementNumber`, {
                                placeholder: t('pension.card.edit.form.union_agreement_number'),
                                skipWrapper: true,
                                skipLabel: true,
                                validate: (val) => {
                                  if (!val) {
                                    return null
                                  }
                                  if (val.length !== 5) {
                                    return t('pension.card.edit.form.union_agreement_number.not_five_characters')
                                  }
                                  return null
                                },
                              })(<Input />)}
                            </Tooltip>
                          </div>
                        )}
                      </Col>
                    )}
                    <Col span={hasUnionAgreementNumber() ? 4 : 5}>
                      {decorateAnyField(`remuneration.pension.${i}.scheme`, {
                        placeholder: t('pension.card.edit.form.scheme'),
                        validate: (val) => (!val ? t('pension.card.edit.form.scheme.required') : null),
                        skipWrapper: true,
                        skipLabel: true,
                        trigger: 'onChange',
                        noBlur: true,
                      })(
                        <Select dropdownMatchSelectWidth={false} size="medium">
                          <Select.Option value={PensionScheme.EMPLOYEE_PAID}>
                            {formatPensionScheme(PensionScheme.EMPLOYEE_PAID)}
                          </Select.Option>
                          <Select.Option value={PensionScheme.EMPLOYER_PAID}>
                            {formatPensionScheme(PensionScheme.EMPLOYER_PAID)}
                          </Select.Option>
                        </Select>
                      )}
                    </Col>
                    <Col span={hasUnionAgreementNumber() ? (i > 0 ? 4 : 5) : i > 0 ? 5 : 6}>
                      <Input.Group compact>
                        {decorateAnyField(`remuneration.pension.${i}.value`, {
                          placeholder:
                            pension.suffix === 'fixedAmount'
                              ? t('pension.card.edit.form.value.fixed')
                              : t('pension.card.edit.form.value.percentage'),
                          validate: (val) => {
                            if (!val) {
                              return t('pension.card.edit.form.value.required', {
                                suffix:
                                  pension.suffix === 'fixedAmount'
                                    ? t('pension.card.edit.form.value.fixed')
                                    : t('pension.card.edit.form.value.percentage'),
                              })
                            }
                            val = parseInputNumber(val)
                            if (typeof val !== 'number') {
                              return t('pension.card.edit.form.value.invalid', {
                                suffix:
                                  pension.suffix === 'fixedAmount'
                                    ? t('pension.card.edit.form.value.fixed')
                                    : t('pension.card.edit.form.value.percentage'),
                              })
                            }
                            if (pension.suffix !== 'fixedAmount' && val > 50) {
                              return t('pension.card.edit.form.value.invalid', {
                                suffix: t('pension.card.edit.form.value.percentage'),
                              })
                            }
                            return null
                          },
                          skipWrapper: true,
                          skipLabel: true,
                        })(<Input style={{ width: '60%' }} />)}
                        {decorateAnyField(`remuneration.pension.${i}.suffix`, {
                          skipWrapper: true,
                          skipLabel: true,
                        })(
                          <Select dropdownMatchSelectWidth={false} style={{ width: '40%' }} size="medium" compact>
                            {canSelectPercentage && (
                              <Select.Option value="percentage">
                                {t('pension.card.edit.form.value.percentage.short')}
                              </Select.Option>
                            )}
                            <Select.Option value="fixedAmount">
                              {t('pension.card.edit.form.value.fixed.short')}
                            </Select.Option>
                          </Select>
                        )}
                      </Input.Group>
                    </Col>
                    {i > 0 && (
                      <Col span={1} className="contracts-remove-row">
                        <span onClick={() => remove(i)}>
                          <Icon type="xSign" />
                        </span>
                      </Col>
                    )}
                  </Row>
                </Form.Item>
              )
            })}
            <span className="contracts-add-row" onClick={add}>
              <Icon type="plusCircle" /> {t('pension.card.edit.form.add_row')}
            </span>
          </Subcard>
          {getFieldValue('hasUnpaidVacation') && hasFilledUnionAgreementNumber() && (
            <Row>
              <Col span={12}>
                <div className="ant-switch-wrapper">
                  {decorateField('includeVacationInPensionBasis', {
                    skipWrapper: true,
                    skipLabel: true,
                    valueOnChecked: true,
                    noBlur: true,
                  })(<Switch />)}
                  <span className="ant-switch-text">
                    {t('pension.card.edit.form.include_vacation_in_pension_basis')}
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  )
}
