import React, { ReactElement } from 'react'

import { DepartmentReducer } from '../../reducers/departments'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  departmentID?: string
  departments: DepartmentReducer
}

type Fields = {
  name?: string
}

export type DepartmentResult = {
  name: string
}

function DepartmentEditForm(props: Props & FormComponentProps<Fields, DepartmentResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('department.edit.form.name'),
            validate: (val) => (!val ? t('department.edit.form.validation.name.required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.departments.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, DepartmentResult>({
  mapPropsToFields: (props) => {
    const department = props.departments.departments.find((department) => department.id === props.departmentID)
    if (department) {
      return {
        name: department.name,
      }
    }
    return {}
  },
  onSubmit: (values) => ({
    name: values.name!,
  }),
})(DepartmentEditForm)
