import { RequestError } from './request-utils'

interface FileChangeEventFileResponseData {
  id: string
  mimeType?: string
}

interface FileChangeEventFileResponseError {
  details?: string[]
  message: string
  type: string
}

function handleFileEventError(response: FileChangeEventFileResponse): RequestError | undefined {
  const error = response?.error
  if (error) {
    return new RequestError(error.message, error.type, error.details || [])
  }
  return undefined
}

interface FileChangeEventFileResponse {
  data?: FileChangeEventFileResponseData
  error?: FileChangeEventFileResponseError
}

interface FileChangeEventFile {
  status: 'uploading' | 'done' | 'error'
  response: FileChangeEventFileResponse
}

export interface FileChangeEvent {
  file: FileChangeEventFile
}

export function HandleFileEvents(
  e: FileChangeEvent,
  events: {
    onUploading: () => void
    onDone: (d?: FileChangeEventFileResponseData) => void
    onError: (e: Error | undefined) => void
    onFinally?: () => void
  }
) {
  switch (e.file.status) {
    case 'uploading':
      if (events.onUploading) {
        events.onUploading()
      }
      break
    case 'done':
      if (events.onDone) {
        events.onDone(e.file.response.data)
      }
      if (events.onFinally) {
        events.onFinally()
      }
      break
    case 'error':
      events.onError(handleFileEventError(e.file.response))
      if (events.onFinally) {
        events.onFinally()
      }
      break
  }
}
