import React, { ReactElement } from 'react'

import Contract from '../../../model/contract'
import { ContractReducer } from '../../../reducers/contracts'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  contract: Contract
  contracts: ContractReducer
}

type Fields = {
  extraTaxPercentage?: string
}

export type ExtraTaxPercentageResult = {
  extraTaxPercentage: number
}

function ExtraTaxPercentageModalForm(
  props: Props & FormComponentProps<Fields, ExtraTaxPercentageResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('extraTaxPercentage', {
            placeholder: t('tax_card.tab.increase_percentage.form.extra_tax_percentage'),
            suffix: t('tax_card.tab.increase_percentage.form.extra_tax_percentage.suffix'),
            validate: (val) => {
              if (!val) {
                return t('tax_card.tab.increase_percentage.form.extra_tax_percentage.required')
              }
              if (parseInputNumber(val) < 0 || parseInputNumber(val) > 100 || !val.toString().match(/^[0-9]+$/)) {
                return t('tax_card.tab.increase_percentage.form.extra_tax_percentage.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ExtraTaxPercentageResult>({
  mapPropsToFields: (props) => ({
    extraTaxPercentage: formatInputNumber(props.contract.extraTaxPercentage),
  }),
  onChange: (key, val, allValues, options) => {
    const values = {}
    setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    return values
  },
  onSubmit: (values) => {
    return {
      extraTaxPercentage: forceParseInputNumber(values.extraTaxPercentage),
    }
  },
})(ExtraTaxPercentageModalForm)
