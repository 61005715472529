import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import Company from '../../model/company'
import PricingPackage from '../../model/pricingPackage'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { getPrickingPackagePrice } from '../../utils/misc-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import LoadingOverlay from './LoadingOverlay'

import './PackageUpgrade.css'

type Props = {
  company: Company
  pricingPackages: List<PricingPackage>
  companyPricingPackages: CompanyPricingPackageReducer

  addCompanyPricingPackage: (pricingPackageID: string) => void
}

export default function PremiumUpgrade(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companyPricingPackages } = props

  useEffect(() => {
    regularComponentDidUpdate(companyPricingPackages.error, error, setError)
  }, [companyPricingPackages, error])

  const upgrade = (e: React.MouseEvent) => {
    e.preventDefault()
    const pricingPackage = props.pricingPackages.find((pkg) => pkg.name === 'Premium' && pkg.selectable)
    if (pricingPackage) {
      props.addCompanyPricingPackage(pricingPackage.id)
    }
  }

  return (
    <Card className="package-upgrade premium-upgrade">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SalaryLogo />

      <Title>{t('modals.premium_upgrade.title')}</Title>
      <div className="package-upgrade-subtitle">{t('modals.premium_upgrade.subtitle')}</div>

      <div className="package-upgrade-price">{formatCurrency(getPrickingPackagePrice('Premium'))}</div>
      <div className="package-upgrade-price-details">{t('modals.premium_upgrade.price_per')}</div>

      <Button onClick={upgrade} size="extra-extra-large" type="secondary">
        {t('modals.premium_upgrade.button')}
      </Button>

      <Row>
        <Col span={6}>
          <strong>{t('pricing_package.package.premium.feature_1.title')}</strong>
          {t('pricing_package.package.premium.feature_1.line_1')}
        </Col>
        <Col span={6}>
          <strong>{t('pricing_package.package.premium.feature_2.title')}</strong>
          <p>{t('pricing_package.package.premium.feature_2.line_1')}</p>
        </Col>
        <Col span={6}>
          <strong>{t('pricing_package.package.premium.feature_3.title')}</strong>
          <p>{t('pricing_package.package.premium.feature_3.line_1')}</p>
        </Col>
        <Col span={6}>
          <strong>{t('pricing_package.package.premium.feature_4.title')}</strong>
          <p>{t('pricing_package.package.premium.feature_4.line_1')}</p>
        </Col>
      </Row>
      {props.companyPricingPackages.saving && <LoadingOverlay />}
    </Card>
  )
}
