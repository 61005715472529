import { List } from 'immutable'
import React, { ReactElement, ReactNode } from 'react'

import EmploymentPosition from '../../model/employmentPosition'
import { decorateFieldSignature, getFieldValueSignature, setFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Cascader, { CascaderGroup, CascaderLabel } from '../elements/cascader/Cascader'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'

type PositionFields = {
  employmentPositionID?: string[]
  position?: string
}

type Props<Fields extends PositionFields> = {
  decorateField: decorateFieldSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  employmentPositions: List<EmploymentPosition>
  disabled?: boolean
}

export default function ContractPosition<Fields extends PositionFields>(props: Props<Fields>): ReactElement | null {
  const getPositions = (): CascaderGroup[] => {
    const groups: Record<string, CascaderGroup> = {}
    props.employmentPositions.forEach((position) => {
      if (!position.active) {
        return
      }
      if (!groups[position.group]) {
        groups[position.group] = {
          kind: 'group',
          value: position.group,
          label: (
            <>
              {position.group} <Icon type="arrowRight" />
            </>
          ),
          searchText: position.group.toLowerCase(),
          children: [],
        }
      }
      if (groups[position.group].children) {
        groups[position.group].children.push({
          kind: 'label',
          value: position.id,
          label: position.title + (position.code ? ' (' + position.code + ')' : ''),
          searchText: (position.title + (position.code ? position.code : '')).toLowerCase(),
        })
      }
    })
    return Object.keys(groups).map((key) => groups[key])
  }

  const renderSelectedPosition = (
    labels: ReactNode[],
    selectedOptions?: (CascaderGroup | CascaderLabel)[]
  ): ReactNode => {
    if (!selectedOptions) {
      return null
    }
    return labels.map((label, i) => {
      return <span key={selectedOptions[i].value}>{label}</span>
    })
  }

  const { decorateField, setFieldValue, getFieldValue } = props

  return (
    <Row>
      <Col span={12}>
        {decorateField('employmentPositionID', {
          placeholder: t('contract.position.employment_position_id'),
          trigger: 'onChange',
          noBlur: true,
        })(
          <Cascader
            disabled={props.disabled}
            options={getPositions()}
            onChange={(value: string[]) => {
              if (value.length >= 2) {
                const position = props.employmentPositions.find((position) => position.id === value[1])
                if (position && !getFieldValue('position')) {
                  setFieldValue('position', position.title)
                }
              }
              if (value.length === 0) {
                setFieldValue('employmentPositionID', [])
              }
            }}
            allowClear={true}
            displayRender={renderSelectedPosition}
            showSearch={{
              matchInputWidth: false,
              filter: (inputValue: string, options: (CascaderGroup | CascaderLabel)[]) => {
                inputValue = inputValue.toLowerCase()
                const group = options[0] as CascaderGroup
                if (options.length === 2) {
                  const label = options[1] as CascaderLabel
                  return group.searchText.indexOf(inputValue) !== -1 || label.searchText.indexOf(inputValue) !== -1
                }
                return false
              },
              render: (inputValue: string, options: (CascaderGroup | CascaderLabel)[]) => {
                return options.map((option) => {
                  return <span key={option.value}>{option.label}</span>
                })
              },
              sort: (a: (CascaderGroup | CascaderLabel)[], b: (CascaderGroup | CascaderLabel)[]) => {
                for (let i = 0; i < a.length; i++) {
                  const aLabel = a[i].label?.toString() || a[i].value || ''
                  const bLabel = (b[i] && (b[i].label?.toString() || b[i].value)) || ''
                  const order = aLabel.localeCompare(bLabel, 'da-dk')
                  if (order !== 0) {
                    return order
                  }
                }
                return 0
              },
            }}
          />
        )}
      </Col>
      <Col span={12}>
        {decorateField('position', {
          placeholder: t('contract.position.position'),
          validate: (val) => (!val ? t('contract.position.position.required') : null),
        })(<Input disabled={props.disabled} />)}
      </Col>
    </Row>
  )
}
