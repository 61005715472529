import React, { ReactElement } from 'react'

import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Switch from '../elements/switch'

type BenefitOtherFields = {
  payReductions: {
    title: string
    isGross: 'true' | 'false'
    withVacation: boolean
    withPension: boolean
    reduction: string
  }[]
}

type Props<Fields extends BenefitOtherFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
}

export default function BenefitPayReductionForm<Fields extends BenefitOtherFields>(
  props: Props<Fields>
): ReactElement | null {
  const add = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    const { getFieldValue, setAnyFieldValue } = props
    const payReductions = getFieldValue('payReductions')
    setAnyFieldValue(`payReductions.${payReductions.length}`, {
      type: 'Pay Reduction',
      isGross: 'true',
      withVacation: true,
      withPension: true,
    })
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue } = props
    const payReductions = getFieldValue('payReductions')
    payReductions.splice(i, 1)
    setFieldValue('payReductions', payReductions)
  }

  const { decorateAnyField, getFieldValue, getAnyFieldValue, getAnyFieldError } = props

  return (
    <div>
      <Row style={{ marginTop: '15px', marginBottom: '20px' }}>
        <Col span={24}>
          <Headline>{t('benefit.form.pay_reduction.intro.title')}</Headline>
        </Col>
      </Row>
      <Subcard>
        <Row style={{ marginBottom: '-10px' }}>
          <Col span={16}>
            <label>{t('benefit.form.pay_reduction.header.title')}</label>
          </Col>
          <Col span={8}>
            <label>{t('benefit.form.pay_reduction.header.reduction')}</label>
          </Col>
        </Row>
        {getFieldValue('payReductions').map((benefit, i) => {
          const title =
            getAnyFieldValue(`payReductions.${i}.isGross`) === 'true'
              ? t('benefit.form.pay_reduction.reduction.gross')
              : t('benefit.form.pay_reduction.reduction.net')
          return (
            <Form.Item
              key={i}
              validateStatus={
                getAnyFieldError(`payReductions.${i}.title`) ||
                getAnyFieldError(`payReductions.${i}.isGross`) ||
                getAnyFieldError(`payReductions.${i}.reduction`)
                  ? 'error'
                  : 'success'
              }
            >
              <Row>
                <Col span={8}>
                  {decorateAnyField(`payReductions.${i}.title`, {
                    placeholder: t('benefit.form.pay_reduction.title'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.pay_reduction.title.required') : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={8}>
                  <Row>
                    <Col span={24}>
                      {decorateAnyField(`payReductions.${i}.isGross`, {
                        skipWrapper: true,
                        skipLabel: true,
                      })(
                        <Select dropdownMatchSelectWidth={false}>
                          <Select.Option value="true">{t('benefit.form.pay_reduction.is_gross.true')}</Select.Option>
                          <Select.Option value="false">{t('benefit.form.pay_reduction.is_gross.false')}</Select.Option>
                        </Select>
                      )}
                    </Col>
                  </Row>
                  {getAnyFieldValue(`payReductions.${i}.isGross`) === 'true' && (
                    <>
                      <Row>
                        <Col span={24}>
                          <p style={{ margin: '0' }}>{t('benefit.form.pay_reduction.is_gross.basis_note')}:</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="ant-switch-wrapper">
                            {decorateAnyField(`payReductions.${i}.withVacation`, {
                              skipWrapper: true,
                              skipLabel: true,
                              valueOnChecked: true,
                              noBlur: true,
                            })(<Switch />)}
                            <span className="ant-switch-text">{t('benefit.form.pay_reduction.with_vacation')}</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="ant-switch-wrapper">
                            {decorateAnyField(`payReductions.${i}.withPension`, {
                              skipWrapper: true,
                              skipLabel: true,
                              valueOnChecked: true,
                              noBlur: true,
                            })(<Switch />)}
                            <span className="ant-switch-text">{t('benefit.form.pay_reduction.with_pension')}</span>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col span={7}>
                  {decorateAnyField(`payReductions.${i}.reduction`, {
                    placeholder: title,
                    suffix: t('benefit.form.pay_reduction.reduction.suffix'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.pay_reduction.reduction.required', { title }) : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={1} className="contracts-remove-row">
                  <span onClick={() => remove(i)}>
                    <Icon type="xSign" />
                  </span>
                </Col>
              </Row>
            </Form.Item>
          )
        })}
        <span className="contracts-add-row" onClick={add}>
          <Icon type="plusCircle" />{' '}
          {getFieldValue('payReductions').length > 0
            ? t('benefit.form.pay_reduction.add_row.not_empty')
            : t('benefit.form.pay_reduction.add_row.empty')}
        </span>
      </Subcard>
    </div>
  )
}
