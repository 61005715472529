import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import { EmploymentReducer } from '../../../reducers/employments'
import { getDate, isTimeBefore } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  employments: EmploymentReducer
  startDate: Date
}

export type Fields = {
  startDate: Date
}

function RehireEmployeeForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField } = props

  const employment = props.employee.activeEmployment
  const hasStartDate =
    employment && (employment.settled || (employment.endDate && !isTimeBefore(getDate(), getDate(employment.endDate))))
  const isFreelancer = props.employee.affiliationType === 'Freelancer'
  return (
    <div>
      {props.getFormError()}
      {hasStartDate && (
        <Row>
          <Col span={24}>
            {decorateField('startDate', {
              placeholder: isFreelancer
                ? t('employee_single.rehire.form.start_date.freelancer')
                : t('employee_single.rehire.form.start_date.employee'),
              validate: (val) => {
                if (!val) {
                  return isFreelancer
                    ? t('employee_single.rehire.form.start_date.required.freelancer')
                    : t('employee_single.rehire.form.start_date.required.employee')
                }
                return null
              },
            })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" danger size="extra-extra-large" block>
            {isFreelancer
              ? t('employee_single.rehire.form.submit.freelancer')
              : t('employee_single.rehire.form.submit.employee')}
          </Button>
        </Col>
      </Row>
      {props.employments.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => ({
    startDate: props.startDate,
  }),
  onSubmit: (values) => values,
})(RehireEmployeeForm)
