import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import Company from '../../model/company'
import { formatError, isRequestError } from '../../utils/error-utils'
import { secureRequest, url } from '../../utils/request-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
}

type Status = {
  redirectUrl?: string
  leadStatus:
    | 'Company is not registered'
    | 'Company is registered'
    | 'Company is registered and connected with Salary'
    | 'Company has not signed the agreement'
    | 'Company is in an unknown state'
}

export default function Grandhood(props: Props): ReactElement | null {
  const [status, setStatus] = useState<Status>({ leadStatus: 'Company is in an unknown state' })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const { company } = props

  useEffect(() => {
    if (status.leadStatus === 'Company is in an unknown state') {
      secureRequest<Status>('GET', url('/v2/grandhoodLead', { companyID: company.id }))
        .then((res) => {
          setStatus(res.data)
          setLoading(false)
        })
        .catch(null) // do nothing
    }
  }, [status, setStatus, company])

  const startLead = () => {
    setLoading(true)
    secureRequest<Status>('POST', url('/v2/grandhoodLead', { companyID: company.id }))
      .then((res) => {
        const r = res.data
        if (r.redirectUrl) {
          setLoading(false)
          window.open(r.redirectUrl, '_blank')
        }
      })
      .catch((e) => {
        setLoading(false)
        if (isRequestError(e)) {
          setError(e)
        }
      })
      .catch(null) // do nothing
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Card className={'card-grandhood'}>
        {status.leadStatus === 'Company is not registered' && (
          <div className={'integration-not-started'}>
            <Row>
              <Col span={14}>
                <h1>{t('basic_integrations.grandhood.not_registered.title')}</h1>
                <p>
                  {tx('basic_integrations.grandhood.not_registered.intro', {
                    strong: (
                      <strong>
                        {tx('basic_integrations.grandhood.not_registered.intro.strong', {
                          link: (
                            <Link to={'https://grandhood.dk'} target={'_blank'} rel="noopener noreferrer">
                              Grandhood
                            </Link>
                          ),
                        })}
                      </strong>
                    ),
                  })}
                </p>
                <ul>
                  <li>{t('basic_integrations.grandhood.not_registered.points.0')}</li>
                  <li>{t('basic_integrations.grandhood.not_registered.points.1')}</li>
                  <li>{t('basic_integrations.grandhood.not_registered.points.2')}</li>
                  <li>{t('basic_integrations.grandhood.not_registered.points.3')}</li>
                  <li>{t('basic_integrations.grandhood.not_registered.points.4')}</li>
                </ul>
              </Col>
              <Col span={10}>
                <blockquote>
                  <p>{t('basic_integrations.grandhood.not_registered.quote')}</p>
                  <cite>
                    <strong>Frederik Smedegaard Dannevang</strong>
                    <br />
                    CEO & Founder
                    <br />
                    Pensure
                  </cite>
                </blockquote>
              </Col>
            </Row>
            <Row className={'button-row'}>
              <Col span={2}>
                <Link to={'https://grandhood.dk'} target={'_blank'} rel="noopener noreferrer">
                  <Button>{t('basic_integrations.grandhood.not_registered.buttons.read_more')}</Button>
                </Link>
              </Col>
              <Col span={6}>
                <DumbLink onClick={startLead}>
                  <Button type="primary">{t('basic_integrations.grandhood.not_registered.buttons.start')}</Button>
                </DumbLink>
              </Col>
            </Row>
          </div>
        )}
        {status.leadStatus === 'Company is registered and connected with Salary' && (
          <div className={'integration-in-use'}>
            <Row>
              <Col span={13}>
                <h1>{t('basic_integrations.grandhood.registered.title')}</h1>
                <p>{t('basic_integrations.grandhood.registered.intro')}</p>
                <ul>
                  <li>{t('basic_integrations.grandhood.registered.points.0')}</li>
                  <li>{t('basic_integrations.grandhood.registered.points.1')}</li>
                  <li>{t('basic_integrations.grandhood.registered.points.2')}</li>
                  <li>{t('basic_integrations.grandhood.registered.points.3')}</li>
                  <li>{t('basic_integrations.grandhood.registered.points.4')}</li>
                </ul>
              </Col>
            </Row>
            <Row className={'button-row'}>
              <Col span={4}>
                <Link to={'https://www.grandhood.dk/booking-meeting/book'} target={'_blank'} rel="noopener noreferrer">
                  <Button>{t('basic_integrations.grandhood.registered.buttons.book')}</Button>
                </Link>
              </Col>
              <Col span={6}>
                <Link to={'https://firma.grandhood.dk/'} target={'_blank'} rel="noopener noreferrer">
                  <Button type="primary">{t('basic_integrations.grandhood.registered.buttons.go_to')}</Button>
                </Link>
              </Col>
            </Row>
          </div>
        )}
        {status.leadStatus === 'Company has not signed the agreement' && (
          <div className={'integration-need-signature'}>
            <Row>
              <Col span={14}>
                <h1>{t('basic_integrations.grandhood.not_completed.title')}</h1>
                <p>{t('basic_integrations.grandhood.not_completed.intro')}</p>
                <ul>
                  <li>{t('basic_integrations.grandhood.not_completed.points.0')}</li>
                  <li>{t('basic_integrations.grandhood.not_completed.points.1')}</li>
                  <li>{t('basic_integrations.grandhood.not_completed.points.2')}</li>
                  <li>{t('basic_integrations.grandhood.not_completed.points.3')}</li>
                  <li>{t('basic_integrations.grandhood.not_completed.points.4')}</li>
                </ul>
              </Col>
              <Col span={10}>
                <blockquote>
                  <p>{t('basic_integrations.grandhood.not_completed.quote')}</p>
                  <cite>
                    <strong>Mads Koch Petersen</strong>
                    <br />
                    Co-founder & COO
                    <br />
                    Messy Weekend
                  </cite>
                </blockquote>
              </Col>
            </Row>
            <Row className={'button-row'}>
              <Col span={4}>
                <Link to={'https://www.grandhood.dk/booking-meeting/book'} target={'_blank'} rel="noopener noreferrer">
                  <Button>{t('basic_integrations.grandhood.not_completed.buttons.book')}</Button>
                </Link>
              </Col>
              <Col span={6}>
                <Link to={'https://firma.grandhood.dk/'} target={'_blank'} rel="noopener noreferrer">
                  <Button type="primary">{t('basic_integrations.grandhood.not_completed.buttons.sign')}</Button>
                </Link>
              </Col>
            </Row>
          </div>
        )}
        {status.leadStatus === 'Company is registered' && (
          <div className={'integration-in-use'}>
            <Row>
              <Col
                span={13}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight: '400px' }}
              >
                <h1>{t('basic_integrations.grandhood.not_connected.title')}</h1>
                <p>
                  {tx('basic_integrations.grandhood.not_connected.intro', {
                    strong: (
                      <strong>
                        {tx('basic_integrations.grandhood.not_connected.intro.strong', {
                          link: (
                            <Link to={'https://grandhood.dk'} target={'_blank'} rel="noopener noreferrer">
                              Grandhood
                            </Link>
                          ),
                        })}
                      </strong>
                    ),
                  })}
                </p>
              </Col>
            </Row>
            <Row className={'button-row'}>
              <Col span={6}>
                <Link to={'https://firma.grandhood.dk/'} target={'_blank'} rel="noopener noreferrer">
                  <Button type="primary">{t('basic_integrations.grandhood.not_connected.buttons.connect')}</Button>
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </Card>
      {loading && <LoadingOverlay />}
    </div>
  )
}
