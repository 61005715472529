import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'

export default function PersonaleZonen(): ReactElement | null {
  return (
    <div>
      <Card className="card-personalezonen">
        <img
          src="/images/personalezonen-logo-995x175.png"
          alt="PersonaleZonen"
          style={{ width: '40%', height: 'auto', display: 'block', marginBottom: '25px' }}
        />
        <p>{t('basic_integrations.personale_zonen.line_1')}</p>
        <p>{t('basic_integrations.personale_zonen.line_2')}</p>
        <Link to="https://personalezonen.dk/register?business_id=941689519" target="_blank" rel="noopener noreferrer">
          <Button size="extra-extra-large" type="primary">
            {t('basic_integrations.personale_zonen.sign_up')}
          </Button>
        </Link>
        <img
          src="/images/personalezonen-preview-1099x888.png"
          alt="PersonaleZonen"
          style={{ width: '75%', height: 'auto', display: 'block', marginTop: '25px', marginBottom: '25px' }}
        />
        <Link to="https://personalezonen.dk/register?business_id=941689519" target="_blank" rel="noopener noreferrer">
          <Button size="extra-extra-large" type="primary">
            {t('basic_integrations.personale_zonen.sign_up')}
          </Button>
        </Link>
      </Card>
    </div>
  )
}
