import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { EmployeeConversionType } from '../../../api/employees'
import paths from '../../../constants/paths'
import Contract from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Modal from '../../elements/modal'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'
import EmploymentDetailsEdit from './EmploymentDetailsEdit'

type Props = {
  mutableContract: Contract
  employee: Employee
  departments: DepartmentReducer
  employees: EmployeeReducer
  isDepartmentManager: boolean
  canEditObjects: boolean
  canHireFire: boolean
  isMutableContract: boolean

  updateEmployee: (employee: Employee) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  convertEmployee: (employeeID: string, toType: EmployeeConversionType) => Promise<Employee | void>
}

export default function EmploymentDetailsCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const setEditVisibility = (visible: boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowEdit(visible)
  }

  const convertToEmployee = () => {
    if (window.confirm(t('common.are_you_sure'))) {
      props.convertEmployee(props.employee.id, 'Standard').then((employee) => {
        if (!employee) {
          return
        }
        jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
      })
    }
  }

  const { employees } = props
  const previousEmployees = usePrevious(employees)
  useEffect(() => {
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      if (!employees.error) {
        setEditVisibility(false)
      }
    }
  })

  const getDepartment = () => {
    return (
      (props.employee.departmentID &&
        props.departments.departments.find((department) => props.employee.departmentID === department.id)?.name) ||
      t('freelancer.details.card.department.none')
    )
  }

  return (
    <Card>
      {props.canEditObjects && props.isMutableContract && (
        <TitleMenu>
          <Button.Icon type="paperWithPencil" onClick={() => setEditVisibility(true)} />
        </TitleMenu>
      )}
      <Title>{t('freelancer.details.card.title')}</Title>
      <Row>
        <Col span={7}>
          {t('freelancer.details.card.department')}:
          <br />
          <strong>{getDepartment()}</strong>
        </Col>
      </Row>
      {props.canHireFire && (
        <Row>
          <Col span={24} className="employment-buttons">
            <Button type="primary" onClick={() => convertToEmployee()}>
              {t('freelancer.details.card.convert_to_employee')}
            </Button>
          </Col>
        </Row>
      )}

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <EmploymentDetailsEdit
          visible={showEdit}
          departments={props.departments}
          employee={props.employee}
          employees={props.employees}
          mutableContract={props.mutableContract}
          isDepartmentManager={props.isDepartmentManager}
          updateEmployee={props.updateEmployee}
          addDepartment={props.addDepartment}
        />
      </Modal>
    </Card>
  )
}
