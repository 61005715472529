import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import CostCenter from '../../../model/costCenter'
import { decorateFieldSignature } from '../../../utils/form-utils'
import { hasCostCenters } from '../../../utils/one-time-pay-utils'
import { t } from '../../../utils/translation-utils'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Select from '../../elements/select'

type OneTimePayCostCenterFields = {
  costCenterID?: string
}

type Props<Fields extends OneTimePayCostCenterFields> = {
  editing: boolean
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>

  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayCostCenter<Fields extends OneTimePayCostCenterFields>(
  props: Props<Fields>
): ReactElement | null {
  if (!hasCostCenters(props.costCenterAccounting, props.costCenters)) {
    return null
  }

  return (
    <Row>
      <Col span={24}>
        {props.decorateField('costCenterID', {
          placeholder: t('otp_form.cost_center'),
        })(
          <Select
            dropdownMatchSelectWidth={false}
            disabled={!props.editing}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              const item = option.props.children
              return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
          >
            {props.costCenters.map((costCenter) => {
              return (
                <Select.Option key={costCenter.id} value={costCenter.id}>
                  {costCenter.name}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Col>
    </Row>
  )
}
