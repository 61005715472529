import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Company from '../../model/company'
import ContractBookIntegration from '../../model/contractBookIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

type Props = {
  contractBookIntegrations: List<ContractBookIntegration>
  company: Company
}

type ContractBookIntegrationField = ContractBookIntegration & {
  delete: boolean
}

export type ContractBookResult = {
  contractBookIntegrations: ContractBookIntegrationField[]
}

function ContractBookForm(
  props: Props & FormComponentProps<ContractBookResult, ContractBookResult>
): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const contractBookIntegrations = getFieldValue('contractBookIntegrations')
    const callbackToken = uuidv4()
    const callbackURL = `https://api.salary.dk/v2/companies/${props.company.id}/contractBookCallback?token=${callbackToken}`
    setAnyFieldValue(`contractBookIntegrations.${contractBookIntegrations.length}`, {
      contractBookAPIKey: '',
      contractBookCallbackToken: callbackToken,
      contractBookCallbackURL: callbackURL,
    })
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  return (
    <Subcard>
      <Row>
        <Col span={20}>{props.getFormError()}</Col>
      </Row>
      <Row>
        <Col />
      </Row>
      <Row style={{ marginBottom: '-10px' }}>
        <Col span={10}>
          <label>{t('contract_book.form.header.callback_url')}</label>
        </Col>
        <Col span={10}>
          <label>{t('contract_book.form.header.api_key')}</label>
        </Col>
        <Col span={2}>
          <label>{t('contract_book.form.header.delete')}</label>
        </Col>
      </Row>
      {getFieldValue('contractBookIntegrations').map((contractBookIntegration, i) => {
        return (
          <Form.Item
            key={i}
            validateStatus={getAnyFieldError(`contractBookIntegrations.${i}.contractBookAPIKey`) ? 'error' : 'success'}
          >
            <Row>
              <Col span={10}>
                {decorateAnyField(`contractBookIntegrations.${i}.contractBookCallbackURL`, {
                  placeholder: t('contract_book.form.callback_url'),
                  skipWrapper: true,
                  skipLabel: true,
                })(<Input disabled={true} />)}
              </Col>
              <Col span={10}>
                {decorateAnyField(`contractBookIntegrations.${i}.contractBookAPIKey`, {
                  placeholder: t('contract_book.form.api_key'),
                  skipWrapper: true,
                  skipLabel: true,
                })(<Input />)}
              </Col>
              <Col span={2}>
                {decorateAnyField(`contractBookIntegrations.${i}.delete`, {
                  placeholder: t('contract_book.form.delete'),
                  skipWrapper: true,
                  skipLabel: true,
                })(<Checkbox style={{ marginTop: 10 }} />)}
              </Col>
            </Row>
          </Form.Item>
        )
      })}
      <span className="contracts-add-row" onClick={add}>
        <Icon type="plusCircle" /> {t('contract_book.form.add_integration')}
      </span>
      <Row>
        <Col span={20}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </Subcard>
  )
}

export default withValidations<Props, ContractBookResult, ContractBookResult>({
  mapPropsToFields: (props) => ({
    contractBookIntegrations: props.contractBookIntegrations
      .map((integration) => ({ ...integration, delete: false }))
      .toArray(),
  }),
  onSubmit: (values) => values,
})(ContractBookForm)
