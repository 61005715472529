import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import paths from '../../../constants/paths'
import Company, { PayRollRunApprovalRule } from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import CompanyUser from '../../../model/companyUser'
import { CompanyReducer } from '../../../reducers/companies'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatPayRollRunApprovalType } from '../../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Headline from '../../elements/Headline'
import Input from '../../elements/input'
import Radio from '../../elements/radio'
import Switch from '../../elements/switch'
import FeatureLock from '../../widgets/FeatureLock'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import Lock from '../../widgets/Lock'

type Props = {
  isAdmin: boolean
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>
  companyUsers: List<CompanyUser>

  hasPaymentIntegration: () => boolean
}

type CommonFields = {
  rulePayRollRunApproval: PayRollRunApprovalRule
  numberOfPayRollApprovers: number
  allowEmptyTaxCardMail: boolean
  allowAutomaticZeroTaxReport: boolean
}

export type ResultFields = CommonFields & {
  numberOfPayRollReviewers: number
  departmentApproverNotificationDaysBefore1: number
  departmentApproverNotificationDaysBefore2: number
}

type Fields = CommonFields & {
  orgHadApproversReviewers: boolean
  numberOfPayRollReviewers: boolean
  departmentApproverNotificationDaysBefore1: string
  departmentApproverNotificationDaysBefore2: string
}

function PayrollTabForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const needCreditCard = () => {
    return (
      !props.hasPaymentIntegration() &&
      props.companyFeatures.some((feature) => feature.featureType === 'Automatic Salary')
    )
  }

  const { decorateField, getFieldValue } = props

  const company = props.company
  const canApproveCount = props.companyUsers.filter(
    (companyUser) =>
      companyUser.companyID === company.id &&
      companyUser.permissions.some(
        (permission) =>
          permission.permission === 'Admin' ||
          permission.permission === 'ApprovePayRoll' ||
          permission.permission === 'ApproveOnly'
      )
  ).size
  const canReviewCount = props.companyUsers.filter(
    (companyUser) =>
      companyUser.companyID === company.id &&
      companyUser.permissions.some(
        (permission) => permission.permission === 'Admin' || permission.permission === 'ReviewPayRoll'
      )
  ).size
  return (
    <div className="companies-single-form">
      <Row>
        <Col span={12}>
          <Lock
            isLocked={props.company.askForPayrollApprovalCode}
            description={t('company_pay_roll.form.rule_pay_roll_run_approval.payroll_approval_code_lock')}
          >
            <Form.Item>
              <label htmlFor="rulePayRollRunApproval" title={t('company_pay_roll.form.rule_pay_roll_run_approval')}>
                {t('company_pay_roll.form.rule_pay_roll_run_approval')}
              </label>
              <FeatureLock
                featureType={'Automatic Salary'}
                description={t('company_pay_roll.form.rule_pay_roll_run_approval.lock')}
              >
                {decorateField('rulePayRollRunApproval', {
                  validate: (val) => {
                    if (!val) {
                      return t('company_pay_roll.form.rule_pay_roll_run_approval.required')
                    }
                    return null
                  },
                })(
                  <Radio.Group>
                    {props.hasPaymentIntegration() && (
                      <Radio value={'Automatic'}>{formatPayRollRunApprovalType('Automatic')}</Radio>
                    )}
                    <Radio value={'Manual'}>{formatPayRollRunApprovalType('Manual')}</Radio>
                    {needCreditCard() && (
                      <p>
                        {tx('company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note', {
                          link: (
                            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/invoices'}>
                              {t('company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note.link')}
                            </Link>
                          ),
                        })}
                      </p>
                    )}
                  </Radio.Group>
                )}
              </FeatureLock>
            </Form.Item>
          </Lock>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Row>
        <Col span={24}>
          <Headline>{t('company_pay_roll.form.section.income_reports')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            <FeatureLock
              featureType={'DK Zero Tax Report'}
              description={t('company_pay_roll.form.allow_automatic_zero_tax_report.lock')}
            >
              <div className="ant-switch-wrapper">
                {decorateField('allowAutomaticZeroTaxReport', {
                  skipWrapper: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('company_pay_roll.form.allow_automatic_zero_tax_report')}</span>
              </div>
            </FeatureLock>
          </Form.Item>
        </Col>
        <Col span={24}>
          <span className="ant-form-extra">{t('company_pay_roll.form.allow_automatic_zero_tax_report.note')}</span>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Row>
        <Col span={24}>
          <Headline>{t('company_pay_roll.form.section.notifications')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            <div className="ant-switch-wrapper">
              {decorateField('allowEmptyTaxCardMail', {
                skipWrapper: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('company_pay_roll.form.allow_empty_tax_card_mail')}</span>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <div>
        <Row>&nbsp;</Row>
        <Row>
          <Col span={24}>
            <Headline>{t('company_pay_roll.form.section.approval_notifications')}</Headline>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p>{t('company_pay_roll.form.approval_notifications_note')}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {decorateField('departmentApproverNotificationDaysBefore1', {
              placeholder: t('company_pay_roll.form.department_approver_notification_days_before_1'),
              suffix: t('company_pay_roll.form.department_approver_notification_days_before_1.suffix'),
              validate: (val) => {
                if (forceParseInputNumber(val) < 0) {
                  return t('company_pay_roll.form.department_approver_notification_days_before_1.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
          <Col span={12}>
            {decorateField('departmentApproverNotificationDaysBefore2', {
              placeholder: t('company_pay_roll.form.department_approver_notification_days_before_2'),
              suffix: t('company_pay_roll.form.department_approver_notification_days_before_2.suffix'),
              validate: (val) => {
                if (forceParseInputNumber(val) < 0) {
                  return t('company_pay_roll.form.department_approver_notification_days_before_2.invalid')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      </div>
      {(canApproveCount > 1 || canReviewCount > 0) && (
        <div>
          <Row>&nbsp;</Row>
          <Row>
            <Col span={24}>
              <Headline>{t('company_pay_roll.form.section.approvers_reviewers')}</Headline>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>{t('company_pay_roll.form.approvers_reviewers.note.line_1')}</p>
              <p>{t('company_pay_roll.form.approvers_reviewers.note.line_2')}</p>
              <p>{t('company_pay_roll.form.approvers_reviewers.note.line_3')}</p>
              <p>{t('company_pay_roll.form.approvers_reviewers.note.line_4')}</p>
            </Col>
          </Row>
          {(getFieldValue('rulePayRollRunApproval') === 'Manual' ||
            (getFieldValue('numberOfPayRollApprovers') || 1) > 1 ||
            getFieldValue('numberOfPayRollReviewers') ||
            getFieldValue('orgHadApproversReviewers')) && (
            <Row>
              {canApproveCount > 1 && (
                <Col span={12}>
                  {decorateField('numberOfPayRollApprovers', {
                    title: t('company_pay_roll.form.number_of_pay_roll_approvers'),
                  })(
                    <Radio.Group disabled={!props.isAdmin}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      {canApproveCount > 2 && <Radio value={3}>3</Radio>}
                    </Radio.Group>
                  )}
                </Col>
              )}
              {canApproveCount < 2 && (
                <Col span={12}>
                  <p>{t('company_pay_roll.form.number_of_pay_roll_approvers.not_enough')}</p>
                </Col>
              )}
              {canReviewCount > 0 && (
                <Col span={12}>
                  <Form.Item>
                    <div className="ant-switch-wrapper">
                      {decorateField('numberOfPayRollReviewers', {
                        skipWrapper: true,
                        valueOnChecked: true,
                        noBlur: true,
                      })(<Switch disabled={!props.isAdmin} />)}
                      <span className="ant-switch-text">{t('company_pay_roll.form.number_of_pay_roll_reviewers')}</span>
                    </div>
                  </Form.Item>
                </Col>
              )}
              {canReviewCount < 1 && (
                <Col span={12}>
                  <p>{t('company_pay_roll.form.number_of_pay_roll_reviewers.not_enough')}</p>
                </Col>
              )}
            </Row>
          )}
          {!(
            getFieldValue('rulePayRollRunApproval') === 'Manual' ||
            (getFieldValue('numberOfPayRollApprovers') || 0) > 1 ||
            getFieldValue('numberOfPayRollReviewers') ||
            getFieldValue('orgHadApproversReviewers')
          ) && (
            <Row>
              <Col span={12}>
                <p>{t('company_pay_roll.form.approvers_reviewers.auto_approve_note')}</p>
              </Col>
            </Row>
          )}
        </div>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      rulePayRollRunApproval: props.company.rulePayRollRunApproval,
      numberOfPayRollApprovers: props.company.numberOfPayRollApprovers ? props.company.numberOfPayRollApprovers : 1,
      numberOfPayRollReviewers: props.company.numberOfPayRollReviewers > 0,
      allowEmptyTaxCardMail: props.company.allowEmptyTaxCardMail,
      orgHadApproversReviewers:
        props.company.numberOfPayRollApprovers > 1 && props.company.numberOfPayRollReviewers > 0,
      departmentApproverNotificationDaysBefore1: formatInputNumber(
        props.company.departmentApproverNotificationDaysBefore1
      ),
      departmentApproverNotificationDaysBefore2: formatInputNumber(
        props.company.departmentApproverNotificationDaysBefore2
      ),
      allowAutomaticZeroTaxReport: false,
    }
    if (props.company.dkSpecific && props.company.dkSpecific.allowAutomaticZeroTaxReport) {
      fields.allowAutomaticZeroTaxReport = props.company.dkSpecific.allowAutomaticZeroTaxReport
    }
    return fields
  },
  onSubmit: (values: Fields): ResultFields => {
    return {
      rulePayRollRunApproval: values.rulePayRollRunApproval,
      numberOfPayRollApprovers: values.numberOfPayRollApprovers ? values.numberOfPayRollApprovers : 1,
      numberOfPayRollReviewers: values.numberOfPayRollReviewers ? 1 : 0,
      allowEmptyTaxCardMail: values.allowEmptyTaxCardMail,
      allowAutomaticZeroTaxReport: values.allowAutomaticZeroTaxReport,
      departmentApproverNotificationDaysBefore1: forceParseInputNumber(
        values.departmentApproverNotificationDaysBefore1
      ),
      departmentApproverNotificationDaysBefore2: forceParseInputNumber(
        values.departmentApproverNotificationDaysBefore2
      ),
    }
  },
})(PayrollTabForm)
