import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { apiClientUserConnect } from '../actions/companies'
import { logout } from '../actions/user'
import Alert from '../components/elements/alert'
import Button from '../components/elements/button'
import Card from '../components/elements/card'
import Title from '../components/elements/Title'
import { UserReducer } from '../reducers/user'
import { regularComponentDidUpdate } from '../utils/component-utils'
import { formatError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  apiClientUserConnect: (apiClientID: string, ref: string) => void
  logout: () => void
}

function WebTokenUserConnect(props: Reducers & Actions & RouteProps): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const email = props.location.query.email

  const { user, location } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    if (previousUser && previousUser.saving && !user.saving) {
      if (!user.error) {
        if (location.query.redirect) {
          document.location = location.query.redirect
        }
      }
    }
  }, [user, previousUser, location])

  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const logout = (e: React.MouseEvent) => {
    e.preventDefault()
    props.logout()
  }

  const handleConfirm = (e: React.MouseEvent) => {
    e.preventDefault()
    props.apiClientUserConnect(props.location.query.apiClientID, props.location.query.ref)
  }

  if (!email) {
    return (
      <Card>
        <Alert message={'Manglende data i kald, kontakt Billy'} type="error" showIcon />
      </Card>
    )
  }

  if (email !== user.email) {
    return (
      <Card>
        <Alert
          message={`Du er logget ind som en anden bruger (${user.email}) end den der kan tilknyttes til Billy-brugeren (${email}).  Log ud og ind igen med den rigtige bruger.`}
          type="error"
          showIcon
        />
        <Button size="extra-extra-large" type="secondary" onClick={logout}>
          Log ud
        </Button>
      </Card>
    )
  }

  return (
    <Card>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Title>Forbind din Salary-bruger til din Billy-bruger ({email})</Title>
      <p>
        Du har allerede en ekisterende bruger i Salary, som du kan forbinde til din Billy-bruger før du kan forbinde din
        virksomhed i Billy til Salary.
      </p>
      <Button size="extra-extra-large" type="secondary" onClick={handleConfirm}>
        Forbind bruger
      </Button>
    </Card>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    apiClientUserConnect: apiClientUserConnect,
    logout: logout,
  }
)(WebTokenUserConnect)
