import { List } from 'immutable'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyGroup from '../../model/companyGroup'
import { ModalOption, ModalType } from '../../model/modal'
import Warning from '../../model/warning'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { UserReducer } from '../../reducers/user'
import { WarningReducer } from '../../reducers/warnings'
import Language from '../../types/language'
import { removeUUIDFromURL } from '../../utils/link-util'
import { getAllParentsForCurrentPath, getMainMenu, MenuContext, MenuItem } from '../../utils/menu-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Menu from '../elements/menu'
import Popover from '../elements/popover'
import UserImage from '../elements/UserImage'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import JumpLink from '../widgets/JumpLink'

import './Header.css'

type Props = {
  location: RoutePropsLocation
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyGroups: List<CompanyGroup>
  warnings: WarningReducer
  currentLanguage: Language

  setActiveCompany: (id: string) => void
  logout: () => Promise<boolean | void>
  setCurrentLanguage: (language: Language) => void
  addModal: (type: ModalType, options: ModalOption) => void
}

export default function Header(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const { menu } = useContext(MenuContext)

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const { companies } = props

  const company = companies.company
  const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)

  const numberOfCompanies = companies.companies.size
  const hasMultipleCompanies = numberOfCompanies > 1 && company

  const isMenuItemVisible = (id: string): boolean => {
    return document.getElementById(id)?.parentElement?.offsetTop === 0
  }

  const getDropDownMenu = useCallback((): MenuItem[] => {
    const orgItems = getMainMenu(menu)
    const items: MenuItem[] = []
    orgItems.forEach((item) => {
      if (!isMenuItemVisible(item.id)) {
        items.push(item)
      }
    })
    return items
  }, [menu])

  const [dropDownMenu, setDropDownMenu] = useState<MenuItem[]>([])

  useEffectOnce(() => {
    window.setTimeout(() => {
      window.addEventListener('resize', () => setDropDownMenu(getDropDownMenu()))
      setDropDownMenu(getDropDownMenu())
    }, 2000)
  })

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    props
      .logout()
      .then((res) => {
        if (res) {
          jsBrowserHistory.push('/' + paths.LOGIN)
        }
      })
      .catch(() => {
        // TODO: Handle
        return
      })
  }

  const getNotifications = (): Warning[] => {
    return props.warnings.warnings.filter((warning) => warning.companyLevel).toArray()
  }

  const getCompanies = (): Company[] => {
    const pattern = searchQuery ? new RegExp(escapeRegExp(searchQuery), 'i') : null
    return props.companies.companies
      .filter((company) => {
        if (pattern) {
          if (!pattern.test(company.name) && !pattern.test(company.nationalID)) {
            return false
          }
        }
        return true
      })
      .toArray()
  }

  const renderDropDownMenu = (): ReactElement => {
    return (
      <Menu className="header-dropdown-menu">
        {dropDownMenu.map((item) => {
          return (
            <Menu.Item key={'dropdown-' + item.id}>
              {item.type === 'item-label' && (
                <Link to={item.link}>
                  {item.icon && <Icon type={item.icon} />}
                  {t(item.labelID)}
                </Link>
              )}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const buildJumpPath = () => {
    const split = props.location.pathname.split('/')
    if (split.length < 2) {
      return '/' + paths.JUMP + props.location.search
    }
    let result = '/' + paths.JUMP + removeUUIDFromURL(props.location.pathname)
    if (result.split('/').length === 2) {
      // handle a few corner cases
      switch (split[1]) {
        case paths.COMPANIES:
          result = '/' + paths.JUMP + '/' + paths.COMPANIES + '/company'
          break
        case paths.EMPLOYEES:
          result = '/' + paths.JUMP + '/' + paths.EMPLOYEES + '/employment'
          break
        case paths.FREELANCERS:
          result = '/' + paths.JUMP + '/' + paths.FREELANCERS + '/overview'
          break
        case paths.PAY_ROLLS:
          result = '/' + paths.JUMP + '/' + paths.PAY_ROLLS + '/single'
          break
      }
    }
    return result + props.location.search
  }

  const renderUserMenu = (): ReactElement => {
    const company = props.companies.company
    const hasMultipleCompanies =
      props.companies.companies.size > 1 ||
      (!props.companies.company && props.companies.companies.size > 0) ||
      props.companyGroups.size > 0
    const hasSearch = props.companies.companies.size >= 10 || isUserSupport(props.user)
    const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)
    return (
      <Menu
        className={'header-user-menu' + (hasMultipleCompanies ? ' header-user-menu-companies' : '')}
        selectedKeys={[]}
      >
        {isUserSupport(props.user) && (
          <Menu.Item className="support-user-field">
            <JumpLink to={buildJumpPath()}>Hjælpehægte til denne side</JumpLink>
          </Menu.Item>
        )}
        {hasMultipleCompanies && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES}>
              <Icon type="suitcase" />
              {t('header.all_companies')}
            </Link>
          </Menu.Item>
        )}
        {hasSearch && (
          <Menu.Item>
            <div className="search-field">
              <Icon type="magnifyingGlass" /> <Input onChange={handleSearch} />
            </div>
          </Menu.Item>
        )}
        {hasMultipleCompanies &&
          getCompanies().map((company) => {
            return (
              <Menu.Item key={company.id}>
                <DumbLink
                  onClick={(e) => {
                    e.preventDefault()
                    props.setActiveCompany(company.id)
                    jsBrowserHistory.push('/')
                  }}
                >
                  <UserImage company={company.name} size="xsmall" />
                  {company.name} <span className="cvr">{company.nationalID}</span>
                </DumbLink>
              </Menu.Item>
            )
          })}
        {hasMultipleCompanies && <Menu.Divider />}
        {!onlyDepartments && !hasMultipleCompanies && !!company && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES + '/' + company.id}>
              <Icon type="suitcase" />
              {t('header.company')}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item>
          <Link to={'/' + paths.ACCOUNT}>
            <Icon type="user" />
            {t('header.account')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <DumbLink onClick={handleLogout}>
            <Icon type="key" />
            {t('header.log_out')}
          </DumbLink>
        </Menu.Item>
      </Menu>
    )
  }

  const notifications = getNotifications().length

  return (
    <header className="header">
      <div className="main-menu">
        {hasMultipleCompanies ? (
          <Link to="/" className="header-logo-multiple">
            {company.name}
          </Link>
        ) : (
          <Link to="/" className="header-logo">
            <SalaryLogo />
          </Link>
        )}
        <Menu
          mode="horizontal"
          selectedKeys={getAllParentsForCurrentPath(props.location.pathname).map((item) => item.id)}
        >
          {getMainMenu(menu).map((item) => {
            if (item.type === 'item-employee-selector') {
              return null
            }
            if (item.modal) {
              const modal = item.modal
              return (
                <Menu.Item key={item.id}>
                  <div
                    id={item.id}
                    onClick={(e) => {
                      e.preventDefault()
                      props.addModal(modal.name, { width: modal.width, closable: modal.closable })
                    }}
                  >
                    {item.icon && <Icon type={item.icon} />}
                    {t(item.labelID)}
                  </div>
                </Menu.Item>
              )
            }
            return (
              <Menu.Item key={item.id}>
                <Link to={item.link} id={item.id}>
                  {item.icon && <Icon type={item.icon} />}
                  {t(item.labelID)}
                </Link>
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
      <div className="right-menu">
        {dropDownMenu.length > 0 && (
          <Popover
            placement="bottomRight"
            overlayClassName="header-dropdown-menu-popover"
            content={renderDropDownMenu()}
            trigger="click"
          >
            <div className="drop-down-menu" title={t('header.more_menu_items')}>
              <Icon type="chevronDown" />
            </div>
          </Popover>
        )}
        {!onlyDepartments && (
          <Link to={'/' + paths.NOTIFICATIONS} className="notification-button">
            <Icon type="bell" />
            {notifications > 0 && <span className="notification-badge">{notifications}</span>}
          </Link>
        )}
        <Popover
          placement="bottomRight"
          overlayClassName="header-user-menu-popover"
          content={renderUserMenu()}
          trigger="click"
        >
          <div className="header-user-menu-button">
            <UserImage company={company ? company.name : ''} name={props.user.name} size="medium" />
          </div>
        </Popover>
      </div>
    </header>
  )
}
