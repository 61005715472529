import { startOfMonth, subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import { ContractCreationFields } from '../../model/contract'
import Department, { DepartmentCreationFields } from '../../model/department'
import Employee, { AffiliationType, EmployeeCreation, NationalIDType, OnboardingState } from '../../model/employee'
import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import { TransferDestinationType } from '../../model/transfer'
import { DateFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { BankReducer } from '../../reducers/banks'
import { ContractReducer } from '../../reducers/contracts'
import { DepartmentReducer } from '../../reducers/departments'
import { EmployeeReducer } from '../../reducers/employees'
import { UserReducer } from '../../reducers/user'
import IncomeType from '../../types/income-type'
import Language from '../../types/language'
import PreferredTaxCardTypes from '../../types/preferred-tax-card-type'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatAPIDate, getDate, isSameOrAfter, isTimeBefore } from '../../utils/date-utils'
import { findDepartmentID } from '../../utils/department-utils'
import { formatError } from '../../utils/error-utils'
import { hasPaySlipTransportDefault } from '../../utils/pay-slip-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import BaseDataForm, { BaseDataResults } from '../employees-add/BaseDataForm'
import CommunicationForm, { CommunicationFields } from '../employees-add/CommunicationForm'
import FinancialDataForm, { FinancialDataResult } from '../employees-add/FinancialDataForm'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import InviteForm, { InviteResult } from './InviteForm'

import '../employees-add/EmployeesAdd.css'

type Props = {
  isInvite: boolean

  alerts: AlertReducer
  company: Company
  user: UserReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  companyFeatures: List<CompanyFeature>
  banks: BankReducer
  contracts: ContractReducer
  salaryCycles: List<SalaryCycle>
  hasPremiumPackage: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  addContract: (contract: ContractCreationFields) => void
  addEmployee: (employee: EmployeeCreation) => Promise<Employee | void>
}

type State = {
  employeeNumber: string
  nationalIDType: NationalIDType
  nationalID?: string
  name?: string
  address?: string
  postalCode?: string
  city?: string
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode: string
  employmentStartDate: DateFormat
  birthDate?: DateFormat
  language: Language
  paySlipTransportEMail: boolean
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportSMS: boolean
  preferredTaxCardType: PreferredTaxCardType
  transferDestinationType: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  affiliationType: AffiliationType
  departmentID?: string
  incomeType: IncomeType
  salaryCycleID: string
  sendLogin: boolean
  onboardingState: OnboardingState
}

export default function FreelancersAdd(props: Props): ReactElement | null {
  const salaryCycles = props.salaryCycles.filter(
    (salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.prepaid && !salaryCycle.offset
  )
  let salaryCycleID = undefined
  const salaryCycle = salaryCycles.first()
  if (salaryCycle) {
    salaryCycleID = salaryCycle.id
  }

  const [state, setState] = useState<State>({
    employeeNumber: props.company.nextAvailableEmployeeNumber.toString(),
    nationalIDType: 'DK CPR',
    phoneNumberCountryCode: '45',
    employmentStartDate: formatAPIDate(startOfMonth(subMonths(getDate(), 1))),
    language: Language.DANISH,
    paySlipTransportEMail: hasPaySlipTransportDefault(
      'EMail',
      props.company.settingsEnabled,
      props.companyFeatures.toArray()
    ),
    paySlipTransportMitDK: hasPaySlipTransportDefault(
      'MitDK',
      props.company.settingsEnabled,
      props.companyFeatures.toArray()
    ),
    paySlipTransportEBoks: hasPaySlipTransportDefault(
      'EBoks',
      props.company.settingsEnabled,
      props.companyFeatures.toArray()
    ),
    paySlipTransportSMS: hasPaySlipTransportDefault(
      'SMS',
      props.company.settingsEnabled,
      props.companyFeatures.toArray()
    ),
    preferredTaxCardType: PreferredTaxCardTypes.SECONDARY_CARD,
    transferDestinationType: 'DK Account',
    affiliationType: 'Freelancer',
    incomeType: IncomeType.DK_B_INCOME,
    salaryCycleID: salaryCycleID || '',
    sendLogin: props.isInvite,
    onboardingState: props.isInvite ? 'Draft' : 'Final',
  })

  const [current, setCurrent] = useState(0)
  const [error, setError] = useState<Error | null>(null)

  const { contracts, employees, addAlert } = props
  const previousContracts = usePrevious(contracts)
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error && !employees.error) {
        // Find newly created employee
        const employee = employees.employees.find((employee) => employee.nationalID === state.nationalID)
        if (employee) {
          addAlert('success', t('freelancer.add.alert.success', { name: employee.name }), { timeout: 5 })
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id)
        } else {
          // Something weird happened, redirect to employees page
          jsBrowserHistory.push('/' + paths.FREELANCERS)
        }
      }
    } else if (previousEmployees && previousEmployees.saving && !employees.saving) {
      if (!employees.error && !!state.email) {
        // Find newly created freelancer
        const employee = employees.employees.find((employee) => employee.email === state.email)
        if (employee) {
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/profile')
        } else {
          // Something weird happened, redirect to freelancer page
          jsBrowserHistory.push('/' + paths.FREELANCERS)
        }
      }
    }
  }, [previousContracts, contracts, previousEmployees, employees, addAlert, state])

  const { departments } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error || departments.error || contracts.error, error, setError)
  }, [employees, departments, contracts, error])

  const canUseEasyAccount = () => {
    return props.company.enableNemKontoTransfers && state.nationalIDType !== 'DK No CPR'
  }

  const _handlePrevious = (values: CommunicationFields | FinancialDataResult, steps = 1) => {
    setState((prev) => ({ ...prev, ...values }))
    setCurrent((prev) => prev - steps)
  }
  const _handleNext = (values: BaseDataResults | CommunicationFields, steps = 1) => {
    setState((prev) => ({ ...prev, ...values }))
    setCurrent((prev) => prev + steps)
  }
  const _createFreelanceContract = (employment: Employment, employeeID: string) => {
    // Get first usable salary period
    const periods = props.salaryCycles
      .find((cycle) => cycle.id === state.salaryCycleID)
      ?.salaryPeriods.filter((salaryPeriod) =>
        isSameOrAfter(getDate(salaryPeriod.latestStartTime), subMonths(getDate(), 1))
      )
    const productionUnits = props.company.productionUnits

    const firstPeriod = periods && periods.length > 0 && periods[0]

    // but if the period starts before the employment, switch it to the employment start date
    let validFrom = getDate(employment.startDate)
    if (firstPeriod) {
      validFrom = getDate(firstPeriod.start)
      if (isTimeBefore(validFrom, getDate(employment.startDate))) {
        validFrom = getDate(employment.startDate)
      }
    }
    if (!state.salaryCycleID) {
      return
    }
    // Create freelance contract
    props.addContract({
      employmentID: employment.id,
      employeeID,
      employmentType: 'Freelance',
      validFrom: formatAPIDate(validFrom),
      productionUnitID: productionUnits && productionUnits.length > 0 ? productionUnits[0].id : undefined,
      salaryCycleID: state.salaryCycleID,
      timeRegistrationMethodType: 'Coarse',
      carAllowanceRegistrationMethodType: 'Coarse',
      salaryRegistrationMethodType: 'Coarse',
      weeklyWorkDays: 0,
      workCycleHours: [0],
      workCycle: [[]],
      remuneration: {
        salary: [],
        benefits: [],
        leave: [],
        supplements: [],
        pension: [],
      },
    })
  }

  const _handleSubmit = (values: InviteResult | FinancialDataResult) => {
    const result = { ...state, ...values }
    const employee: EmployeeCreation = {
      ...{ paySlipTransports: [] },
      ...result,
      employeeNumber: result.employeeNumber.toString(),
      nationalID: result.nationalID || '',
      name: result.name || '',
      email: result.email || '',
      address: result.address || '',
      city: result.city || '',
      postalCode: result.postalCode || '',
      departmentID: findDepartmentID(departments.departments.toArray(), result.departmentID || undefined),
    }
    const promise = new Promise<string | void>((resolve, reject) => {
      if (employee.departmentID && !props.departments.departments.some((item) => item.id === employee.departmentID)) {
        props
          .addDepartment({ name: employee.departmentID })
          .then((data) => {
            if (data) {
              resolve(data.id)
            } else {
              resolve()
            }
          })
          .catch(reject)
      } else {
        resolve()
      }
    })
    promise.then((departmentID) => {
      if (departmentID) {
        employee.departmentID = departmentID
      }
      props.addEmployee(employee).then((newEmployee) => {
        if (newEmployee && newEmployee.activeEmployment) {
          _createFreelanceContract(newEmployee.activeEmployment, newEmployee.id)
        }
      })
    })
  }

  const saving = props.employees.saving || props.departments.saving || props.contracts.saving
  return (
    <div className="employees-add">
      <Row>
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {saving && (
            <div style={{ position: 'relative', minHeight: '400px' }}>
              <LoadingOverlay />
            </div>
          )}
          {!saving && (
            <div className="employees-add-overview">
              {error && <Alert message={formatError(error)} type="error" showIcon />}
              {props.isInvite && (
                <div>
                  <Card>
                    <InviteForm departments={props.departments.departments} {...state} onSubmit={_handleSubmit} />
                  </Card>
                </div>
              )}
              {!props.isInvite && (
                <div>
                  {current === 0 && (
                    <Card>
                      <Blocktitle>{t('freelancer.add.step_0.title')}</Blocktitle>
                      <BaseDataForm
                        user={props.user}
                        company={props.company}
                        employees={props.employees}
                        salaryCycles={props.salaryCycles}
                        departments={props.departments.departments}
                        departmentIDs={[]}
                        {...state}
                        onSubmit={_handleNext}
                      />
                    </Card>
                  )}
                  {current === 1 && (
                    <Card>
                      <Blocktitle>{t('freelancer.add.step_1.title')}</Blocktitle>
                      <CommunicationForm
                        hasPremiumPackage={props.hasPremiumPackage}
                        {...state}
                        onBack={_handlePrevious}
                        onSubmit={_handleNext}
                      />
                    </Card>
                  )}
                  {current === 2 && (
                    <Card>
                      <Blocktitle>{t('freelancer.add.step_2.title')}</Blocktitle>
                      <FinancialDataForm
                        company={props.company}
                        banks={props.banks.banks}
                        canUseNemKonto={canUseEasyAccount()}
                        {...state}
                        onBack={_handlePrevious}
                        onSubmit={_handleSubmit}
                      />
                    </Card>
                  )}
                </div>
              )}
            </div>
          )}
        </Col>
        {!props.isInvite && (
          <Col span={8}>
            <Card>
              <Subtitle>{t('freelancer.add.steps.title')}</Subtitle>
              <p>{t('freelancer.add.steps.intro')}</p>

              <Steps direction="vertical" current={current}>
                <Steps.Step title={t('freelancer.add.steps.0')} />
                <Steps.Step title={t('freelancer.add.steps.1')} />
                <Steps.Step title={t('freelancer.add.steps.2')} />
              </Steps>
            </Card>
            <small style={{ textAlign: 'center' }}>
              {tx('freelancer.add.steps.help_note', {
                email: <a href="mailto:support@salary.dk">support@salary.dk</a>,
              })}
            </small>
          </Col>
        )}
      </Row>
    </div>
  )
}
