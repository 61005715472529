import React, { CSSProperties } from 'react'

import classNames from '../../antd/_util/classNames'

// NEED_NO_TRANSLATION

type Props = {
  className?: string
  prefixCls?: string
  style?: CSSProperties
  size?: 'large' | 'huge'
  compact?: boolean
  children?: React.ReactNode
}

export default function Group(props: Props) {
  const { prefixCls = 'ant-input-group', className = '' } = props
  const cls = classNames(
    prefixCls,
    {
      [`${prefixCls}-l`]: props.size === 'large',
      [`${prefixCls}-xl`]: props.size === 'huge',
      [`${prefixCls}-compact`]: props.compact,
    },
    className
  )
  return (
    <span className={cls} style={props.style}>
      {props.children}
    </span>
  )
}
