import React, { ReactElement } from 'react'

import PackageUpgrade from '../../controllers/modals/PackageUpgrade'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { EmployeeReducer } from '../../reducers/employees'
import { ModalReducer } from '../../reducers/modals'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import Modal from '../elements/modal'
import GlobalSearch from '../global-search/GlobalSearch'

// NEED_NO_TRANSLATION

type Reducers = {
  employees: EmployeeReducer
}

type Props = {
  companies: CompanyReducer
  companyPricingPackages: CompanyPricingPackageReducer
  modals: ModalReducer
  pricingPackages: PricingPackageReducer
  removeModal: (id: number) => void
  getCompanyPricingPackages: () => void
  addCompanyPricingPackage: (pricingPackageID: string) => void
  getPricingPackages: (includePricingPackageID: string[]) => void
}

export default function Modals(props: Props & Reducers): ReactElement | null {
  return (
    <div className="modals">
      {props.modals.modals.map((modal) => {
        const id = modal.id
        if (!id) {
          return null
        }
        let content
        switch (modal.type) {
          case 'global-search':
            content = (
              <GlobalSearch
                id={id}
                employees={props.employees}
                removeModal={props.removeModal}
                employeesLimit={5}
                articlesLimit={5}
              />
            )
            break
          case 'basic-upgrade':
            content = (
              <PackageUpgrade
                id={id}
                featurePackage={'Basic'}
                companies={props.companies}
                companyPricingPackages={props.companyPricingPackages}
                pricingPackages={props.pricingPackages}
                removeModal={props.removeModal}
                getCompanyPricingPackages={props.getCompanyPricingPackages}
                addCompanyPricingPackage={props.addCompanyPricingPackage}
                getPricingPackages={props.getPricingPackages}
              />
            )
            break
          case 'premium-upgrade':
            content = (
              <PackageUpgrade
                id={id}
                featurePackage={'Premium'}
                companies={props.companies}
                companyPricingPackages={props.companyPricingPackages}
                pricingPackages={props.pricingPackages}
                removeModal={props.removeModal}
                getCompanyPricingPackages={props.getCompanyPricingPackages}
                addCompanyPricingPackage={props.addCompanyPricingPackage}
                getPricingPackages={props.getPricingPackages}
              />
            )
            break
          default:
            return null
        }
        return (
          <Modal
            key={id}
            visible
            closable={modal.options.closable}
            width={modal.options.width}
            footer={null}
            onOk={() => {
              props.removeModal(id)
            }}
            onCancel={() => {
              props.removeModal(id)
            }}
          >
            {content}
          </Modal>
        )
      })}
    </div>
  )
}
