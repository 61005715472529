import React, { ReactElement, useState } from 'react'

import { UITheme } from '../../model/user'
import { UserReducer } from '../../reducers/user'
import Language from '../../types/language'
import { hasLeftMenuFeature } from '../../utils/feature-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import HelpModal from '../elements/HelpModal'
import Input from '../elements/input'
import Select from '../elements/select'
import PhoneNumberForm from '../form-elements/PhoneNumberForm'
import SwitchWrapper from '../form-elements/SwitchWrapper'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  user: UserReducer
}

type Fields = {
  name?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  language: Language
  showCompanyListOnLogIn: boolean
  sideMenu: boolean
  theme: UITheme
}

export type ProfileResult = {
  name: string
  phoneNumber: string
  phoneNumberCountryCode: string
  language: Language
  uiSettings: {
    showCompanyListOnLogIn: boolean
    sideMenu: boolean
    theme: UITheme
  }
}

function ProfileForm(props: Props & FormComponentProps<Fields, ProfileResult>): ReactElement | null {
  const { decorateField, getFieldValue, getFormError, getFieldError } = props
  const [themeEnters, setThemeEnters] = useState(0)

  return (
    <div>
      {getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('name', {
            placeholder: t('account.profile.form.name'),
            validate: (val) => (!val ? t('account.profile.form.name.required') : null),
          })(<Input tabIndex={1} />)}
        </Col>
        <Col span={12}>
          <PhoneNumberForm
            decorateField={decorateField}
            getFieldValue={getFieldValue}
            getFieldError={getFieldError}
            tabIndex={2}
            requirePhoneNumber
          />
        </Col>
        {(props.user.user?.userCompanies ?? 0) > 1 && (
          <Col span={12}>
            <SwitchWrapper id={'showCompanyListOnLogIn'} decorateField={decorateField}>
              {t('account.profile.form.show_company_list_on_log_in')}
              <HelpModal>
                <p>{t('account.profile.form.show_company_list_on_log_in.help.line_1')}</p>
                <p>{t('account.profile.form.show_company_list_on_log_in.help.line_2')}</p>
              </HelpModal>
            </SwitchWrapper>
          </Col>
        )}
        <Col span={12}>
          {decorateField('language', {
            placeholder: t('account.profile.form.language'),
            validate: (val) => (!val ? t('account.profile.form.language.required') : null),
          })(
            <Select>
              <Select.Option value={Language.DANISH}>{t('common.language.da')}</Select.Option>
              <Select.Option value={Language.ENGLISH}>{t('common.language.en')}</Select.Option>
            </Select>
          )}
        </Col>
        {hasLeftMenuFeature() && (
          <Col span={12}>
            <SwitchWrapper id={'sideMenu'} decorateField={decorateField}>
              {t('account.profile.form.side_menu')}
            </SwitchWrapper>
          </Col>
        )}
        <Col span={12} onMouseEnter={() => setThemeEnters((prev) => prev + 1)}>
          {decorateField('theme', {
            placeholder: t('account.profile.form.theme'),
          })(
            <Select>
              <Select.Option value={'Default'}>{t('account.profile.form.theme.default')}</Select.Option>
              <Select.Option value={'Light'}>{t('account.profile.form.theme.light')}</Select.Option>
              {(themeEnters > 10 || getFieldValue('theme') === 'Classic') && (
                /* a little Easter egg for those who desire the classic theme */ <Select.Option value={'Classic'}>
                  {t('account.profile.form.theme.classic')}
                </Select.Option>
              )}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.user.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ProfileResult>({
  mapPropsToFields: (props) => ({
    name: props.user.name,
    phoneNumber: props.user.phoneNumber,
    phoneNumberCountryCode: props.user.phoneNumberCountryCode,
    language: props.user.language || Language.DANISH,
    showCompanyListOnLogIn: props.user.user?.uiSettings.showCompanyListOnLogIn ?? false,
    sideMenu: props.user.user?.uiSettings.sideMenu ?? false,
    theme: props.user.user?.uiSettings.theme ?? 'Default',
  }),
  onSubmit: (values) => ({
    name: values.name!,
    phoneNumber: values.phoneNumber!,
    phoneNumberCountryCode: values.phoneNumberCountryCode!,
    language: values.language,
    uiSettings: {
      showCompanyListOnLogIn: values.showCompanyListOnLogIn,
      sideMenu: values.sideMenu,
      theme: values.theme,
    },
  }),
})(ProfileForm)
