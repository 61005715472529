import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AuthParameter, AuthParameters } from '../../api/company-data-integration-setup'
import AvailableDataIntegration from '../../model/availableDataIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  availableDataIntegrations: List<AvailableDataIntegration>

  integrationType: string
  authParameters?: AuthParameters
}

type ConnectionFields = Partial<Record<`parameter-${AuthParameter}`, string>>

export type ParameterConnectionResult = {
  readonly step: 'NeedKey'
  authParameters: AuthParameters
}

function ConnectionForm(
  props: Props & FormComponentProps<ConnectionFields, ParameterConnectionResult>
): ReactElement | null {
  const getIntegration = () => {
    const integrationType = props.integrationType
    return props.availableDataIntegrations.find((integration) => integration.type === integrationType)
  }
  const getIntegrationGuide = (): string => {
    return getIntegration()?.description || ''
  }

  const integration = getIntegration()

  if (!integration || integration.hasAuthURL) {
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <LoadingOverlay />
      </div>
    )
  }

  const { decorateField } = props

  return (
    <div>
      <Subtitle>{t('company_data_integration.connection_form.title')}</Subtitle>
      <p dangerouslySetInnerHTML={{ __html: getIntegrationGuide() }} />
      {props.getFormError()}
      <Subcard className="connection-search">
        {integration.parameters.map((parameter) =>
          decorateField(`parameter-${parameter.name}`, {
            title: parameter.displayName,
            placeholder: t('company_data_integration.connection_form.form.parameter.placeholder', {
              parameter: parameter.displayName,
              integration: integration.displayName,
            }),
            validate: (val) =>
              !val
                ? t('company_data_integration.connection_form.form.parameter.required', {
                    parameter: parameter.displayName,
                  })
                : null,
          })(<Input type={parameter.type === 'password' ? 'password' : 'text'} />)
        )}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="secondary"
            className="gtm-data-integration-continue-from-connection"
          >
            {t('company_data_integration.connection_form.form.submit')}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack} className="gtm-data-integration-back">
            {t('company_data_integration.connection_form.form.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, ConnectionFields, ParameterConnectionResult>({
  mapPropsToFields: (props) => {
    const fields: ConnectionFields = {}
    for (const key in props.authParameters) {
      fields[`parameter-${key as AuthParameter}`] = props.authParameters[key as AuthParameter]
    }
    return fields
  },
  onSubmit: (values) => {
    const authParameters: AuthParameters = {}
    for (const key in values) {
      if (!key.match(/^parameter-/)) {
        continue
      }
      authParameters[key.replace(/^parameter-/, '') as AuthParameter] =
        values[key as `parameter-${AuthParameter}`]?.trim()
    }
    return {
      step: 'NeedKey',
      authParameters,
    }
  },
})(ConnectionForm)
