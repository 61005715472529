import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { CoarseTimeRegistrationMutableFields } from '../../../model/coarseTimeRegistration'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { CoarseTimeRegistrationReducer } from '../../../reducers/coarseTimeRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { formatDate } from '../../../utils/date-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t } from '../../../utils/translation-utils'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Tooltip from '../../elements/tooltip'
import CoarseTimeRegistrationModal from './CoarseTimeRegistrationModal'

type Props = {
  employee: Employee
  contracts: ContractReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  addContract: (contract: Contract) => void
  updateContract: (contract: Contract) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
}

export default function CoarseTimeRegistrationHistoryTab(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showCoarse, setShowCoarse] = useState<boolean | string>(false)

  const { coarseTimeRegistrations } = props
  const previousCoarseTimeRegistrations = usePrevious(coarseTimeRegistrations)

  const setCoarseVisibility = (showCoarse: boolean | string) => {
    setModalKey((prev) => prev + 1)
    setShowCoarse(showCoarse)
  }

  useEffect(() => {
    if (previousCoarseTimeRegistrations && previousCoarseTimeRegistrations.saving && !coarseTimeRegistrations.saving) {
      if (!coarseTimeRegistrations.error) {
        setCoarseVisibility(false)
      }
    }
  })

  type TimeRegistrationRow = {
    key: string
    id: string
    salaryPeriodID: string
    date: string
    period: string
    description: string
    hours: string
    days: string
    immutable: boolean
  }

  const columns = [
    { title: t('time_registration_tab.coarse.table.header.period'), dataIndex: 'period', key: 'period' },
    { title: t('time_registration_tab.coarse.table.header.description'), dataIndex: 'description', key: 'description' },
    { title: t('time_registration_tab.coarse.table.header.hours'), dataIndex: 'hours', key: 'hours' },
    { title: t('time_registration_tab.coarse.table.header.days'), dataIndex: 'days', key: 'days' },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (timeRegistration.immutable) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('time_registration_tab.coarse.table.actions.edit')}>
              <span
                onClick={(e) => {
                  e.preventDefault()
                  setCoarseVisibility(timeRegistration.salaryPeriodID)
                }}
                style={{ cursor: 'pointer' }}
              >
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    return props.coarseTimeRegistrations.coarseTimeRegistrations
      .filter((registration) => registration.employeeID === props.employee.id)
      .map((registration) => {
        const period = props.salaryCycle.salaryPeriods.find((row) => row.id === registration.salaryPeriodID)
        let title = props.employee?.activeContract?.remuneration?.salary.find(
          (row) => row.salaryTypeID === registration.salaryTypeID
        )?.title
        if (!title) {
          title = getSalaryType(props.salaryTypes.toArray(), registration.salaryTypeID)?.title || undefined
        }
        return {
          key: registration.id,
          id: registration.id,
          salaryPeriodID: registration.salaryPeriodID,
          date: period ? period.start : '-',
          period: period ? formatDate(period.start) + ' - ' + formatDate(period.end) : '-',
          description: title || '',
          hours: formatDisplayNumber(registration.hours),
          days: formatDisplayNumber(registration.days),
          immutable: registration.immutable,
        }
      })
      .toArray()
      .sort((a, b) => {
        const d = !a.date || !b.date ? 0 : b.date.localeCompare(a.date, 'da-dk')
        if (d !== 0) {
          return d
        }
        return a.description.localeCompare(b.description, 'da-dk')
      })
  }

  return (
    <div>
      <Table columns={columns} dataSource={getTimeRegistrations()} pagination={false} />

      <Modal
        key={modalKey * -1}
        visible={!!showCoarse}
        onOk={() => setCoarseVisibility(false)}
        onCancel={() => setCoarseVisibility(false)}
        width={582}
        footer={null}
      >
        <CoarseTimeRegistrationModal
          visible={!!showCoarse}
          employee={props.employee}
          salaryPeriodID={typeof showCoarse === 'string' ? showCoarse : undefined}
          contracts={props.contracts}
          coarseTimeRegistrations={props.coarseTimeRegistrations}
          salaryCycles={props.salaryCycles}
          salaryCycle={props.salaryCycle}
          salaryTypes={props.salaryTypes}
          addAlert={props.addAlert}
          addContract={props.addContract}
          updateContract={props.updateContract}
          updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
        />
      </Modal>
    </div>
  )
}
