import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import { UserPermission } from '../../model/companyUser'
import Department from '../../model/department'
import { DepartmentUserMutableFields } from '../../model/departmentUser'
import { UserInviteReducer } from '../../reducers/userInvites'
import UserTypes from '../../types/user-company-type'
import UserCompanyType from '../../types/user-company-type'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Icon from '../elements/icon'
import Subtitle from '../elements/Subtitle'
import CompanyUsersAddForm, { ResultFields } from './CompanyUsersAddForm'

interface Props {
  company: Company
  departments: List<Department>
  userInvites: UserInviteReducer

  addUserInvite: (
    email: string,
    userType: UserTypes,
    permissions: UserPermission[],
    departments: DepartmentUserMutableFields[],
    name?: string,
    phoneNumberCountryCode?: string,
    phoneNumber?: string
  ) => void
}

export default function CompanyUsersAdd(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { userInvites } = props

  useEffect(() => {
    regularComponentDidUpdate(userInvites.error, error, setError)
  }, [userInvites, error])

  const handleSubmit = (values: ResultFields) => {
    if (values.userType === UserTypes.DEPARTMENT_MANAGER) {
      if (!values.departments || values.departments.length === 0) {
        setError(new Error(t('company_users.add.error.no_departments')))
        return
      }
    }
    const permissions: UserPermission[] = []
    if (values.userType !== UserTypes.DEPARTMENT_MANAGER) {
      if (values.userType !== UserCompanyType.APPROVE_ONLY && values.userType !== UserCompanyType.READ_ONLY) {
        if (values.permissionAdmin) {
          permissions.push('Admin')
        } else {
          permissions.push('Manager') // manager if not department manager or admin
        }
        if (values.permissionApprovePayRoll) {
          permissions.push('ApprovePayRoll')
        }
        if (values.permissionReviewPayRoll) {
          permissions.push('ReviewPayRoll')
        }
      } else if (values.userType === UserCompanyType.APPROVE_ONLY) {
        permissions.push('ApproveOnly')
      } else {
        permissions.push('ReadOnly')
      }
    }
    props.addUserInvite(
      values.email,
      values.userType,
      permissions,
      values.departments,
      values.name,
      values.phoneNumberCountryCode,
      values.phoneNumber
    )
  }

  return (
    <div className="company-users-add">
      <Subtitle>
        <Icon type="plusCircle" />
        {t('company_users.add.title')}
      </Subtitle>
      <p>{t('company_users.add.intro')}</p>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CompanyUsersAddForm
        company={props.company}
        departments={props.departments}
        userInvites={props.userInvites}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
