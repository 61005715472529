import { Divider, ItemGroup, SubMenu } from 'rc-menu'

import MenuComponent from './Menu'
import Item from './MenuItem'

import './style/css'

const Menu = Object.assign(MenuComponent, { Item, Divider, ItemGroup, SubMenu })

export default Menu
