import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import { TransferSetting } from '../../model/paymentConfiguration'
import PaymentIntegration from '../../model/paymentIntegration'
import { PaymentConfigurationReducer } from '../../reducers/paymentConfigurations'
import TransferSettingIntegration from '../../types/transfer-setting-integration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatTransferSettingIntegration, formatTransferType } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Subcard from '../elements/Subcard'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  transferSettings: TransferSetting[]
  companyPaymentIntegrations: List<PaymentIntegration>
  companyFeatures: List<CompanyFeature>
  paymentConfigurations: PaymentConfigurationReducer

  addAlert: addAlertSignature
}

export type Fields = {
  transferSettings: TransferSetting[]
}

function TransferSettingsForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { paymentConfigurations, company, addAlert } = props
  const previousPaymentConfigurations = usePrevious(paymentConfigurations)

  useEffect(() => {
    if (previousPaymentConfigurations && previousPaymentConfigurations.saving && !paymentConfigurations.saving) {
      if (!paymentConfigurations.error) {
        addAlert('success', t('transfer_settings.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  }, [previousPaymentConfigurations, paymentConfigurations, addAlert, company])

  const hasStripe = props.companyPaymentIntegrations.find(
    (integration) => integration.paymentIntegrationType === 'Stripe'
  )
  const hasNETS = props.companyPaymentIntegrations.find((integration) => integration.paymentIntegrationType === 'NETS')
  const hasZimpler = props.companyPaymentIntegrations.find(
    (integration) => integration.paymentIntegrationType === 'Zimpler'
  )

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  const transferSettings = getFieldValue('transferSettings') as TransferSetting[]

  return (
    <div>
      <Row>&nbsp;</Row>
      {props.getFormError()}
      <Subcard>
        {transferSettings.map((setting, i) => {
          return (
            <Form.Item
              key={i}
              validateStatus={getAnyFieldError(`transferSettings.${i}.integration`) ? 'error' : 'success'}
            >
              <Col span={24}>
                <Input.Group compact>
                  <div style={{ width: '33%' }}>{formatTransferType(setting.type)}</div>
                  {decorateAnyField(`transferSettings.${i}.integration`, {
                    skipWrapper: true,
                    skipLabel: true,
                  })(
                    <Radio.Group>
                      {hasNETS && (
                        <Radio value={TransferSettingIntegration.NETS}>
                          {t('transfer_settings.form.transfer_via', {
                            integration: formatTransferSettingIntegration(TransferSettingIntegration.NETS),
                          })}
                        </Radio>
                      )}
                      <Radio value={TransferSettingIntegration.NONE} disabled={setting.type === 'Invoice'}>
                        {t('transfer_settings.form.transfer_manual')}
                      </Radio>
                      {hasStripe && setting.type === 'Invoice' && (
                        <Radio value={TransferSettingIntegration.STRIPE}>
                          {t('transfer_settings.form.transfer_via', {
                            integration: formatTransferSettingIntegration(
                              TransferSettingIntegration.STRIPE
                            ).toLowerCase(),
                          })}
                        </Radio>
                      )}
                      {hasZimpler && setting.type === 'Immediate Pay' && (
                        <Radio value={TransferSettingIntegration.ZIMPLER}>
                          {t('transfer_settings.form.transfer_via', {
                            integration: formatTransferSettingIntegration(TransferSettingIntegration.ZIMPLER),
                          })}
                        </Radio>
                      )}
                    </Radio.Group>
                  )}
                </Input.Group>
              </Col>
            </Form.Item>
          )
        })}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" className="gtm-transfer-settings-save">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.paymentConfigurations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => ({
    transferSettings: props.transferSettings,
  }),
  onSubmit: (values) => values,
})(TransferSettingsForm)
