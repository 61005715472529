import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { TimeRegistrationBulk } from '../../api/time-registrations'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import LeaveBalance from '../../model/leaveBalance'
import LeaveType from '../../model/leaveType'
import SalaryType from '../../model/salaryType'
import { TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { AlertReducer } from '../../reducers/alerts'
import { EmployeeReducer } from '../../reducers/employees'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import LeaveRegistrationCalendar from './LeaveRegistrationCalendar'
import LeaveRegistrationTable from './LeaveRegistrationTable'

import './LeaveRegistration.css'

type Props = {
  alerts: AlertReducer
  showCalendar: boolean
  company: Company
  companyUser?: CompanyUser
  employees: EmployeeReducer
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  leaveBalances: List<LeaveBalance>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  vacationCalendars: VacationCalendarReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  approveTimeRegistrations: (ids: string[]) => void
  getVacationCalendarYear: (companyID: string, year: number) => void
}

export default function LeaveRegistration(props: Props): ReactElement | null {
  return (
    <div className="leave-registration">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <Link to={'/' + paths.LEAVE_REGISTRATION + (props.showCalendar ? '' : '/calendar')}>
          <Button size="extra-large" type="secondary" className="gtm-toggle-leave-view">
            {props.showCalendar
              ? t('leave_registrations.header.switch_to_table')
              : t('leave_registrations.header.switch_to_calendar')}
          </Button>
        </Link>
      </TitleMenu>
      <Title>{t('leave_registrations.title')}</Title>

      {props.showCalendar && (
        <LeaveRegistrationCalendar
          company={props.company}
          companyUser={props.companyUser}
          employees={props.employees}
          departments={props.departments}
          timeRegistrations={props.timeRegistrations}
          vacationCalendars={props.vacationCalendars}
          leaveTypes={props.leaveTypes}
          getVacationCalendarYear={props.getVacationCalendarYear}
          getRemuneration={props.getRemuneration}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          approveTimeRegistrations={props.approveTimeRegistrations}
          updateTimeRegistration={props.updateTimeRegistration}
          deleteTimeRegistration={props.deleteTimeRegistration}
        />
      )}
      {!props.showCalendar && (
        <LeaveRegistrationTable
          company={props.company}
          companyUser={props.companyUser}
          employees={props.employees}
          departments={props.departments}
          timeRegistrations={props.timeRegistrations}
          leaveBalances={props.leaveBalances}
          leaveTypes={props.leaveTypes}
          salaryTypes={props.salaryTypes}
          getRemuneration={props.getRemuneration}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          approveTimeRegistrations={props.approveTimeRegistrations}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      )}
    </div>
  )
}
