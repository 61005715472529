import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

interface Props {
  switchToNETS: (e: React.MouseEvent) => void
}

export default function SwitchToNETS(props: Props): ReactElement | null {
  return (
    <Card className="pay-roll-switch-to-nets">
      <Subtitle>{t('pay_roll.single.nets.title')}</Subtitle>
      <p>{t('pay_roll.single.nets.warning')}</p>
      <p>{t('pay_roll.single.nets.intro.line_1')}</p>
      <p>{t('pay_roll.single.nets.intro.line_2')}</p>
      <Row>
        <Col span={24}>
          <Button size="extra-extra-large" type="secondary" block onClick={props.switchToNETS}>
            {t('pay_roll.single.nets.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
