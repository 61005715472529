import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import classNames from '../../antd/_util/classNames'

// NEED_NO_TRANSLATION

type Props = {
  span?: number
  order?: number
  offset?: number
  push?: number
  pull?: number
  className?: string
  id?: string
  prefixCls?: string
  style?: CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
  children?: ReactNode
}

export default function Col(props: Props): ReactElement | null {
  const { span, order, offset, push, pull, className, children, prefixCls = 'ant-col', ...others } = props
  const classes = classNames(
    {
      [`${prefixCls}-${span}`]: span !== undefined,
      [`${prefixCls}-order-${order}`]: order,
      [`${prefixCls}-offset-${offset}`]: offset,
      [`${prefixCls}-push-${push}`]: push,
      [`${prefixCls}-pull-${pull}`]: pull,
    },
    prefixCls,
    className
  )

  return (
    <div {...others} className={classes}>
      {children}
    </div>
  )
}
