import React, { ReactElement } from 'react'

import { decorateFieldSignature, getFieldErrorSignature, getFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import { getPermittedSMSPhonePrefixes, getPhonePrefixes } from '../../utils/validation-utils'
import Form from '../antd/form'
import Input from '../elements/input'
import Select from '../elements/select'

type PhoneNumberFields = {
  phoneNumberCountryCode?: string
  phoneNumber?: string
}

type Props<Fields extends PhoneNumberFields> = {
  disabled?: boolean
  tabIndex?: number
  restrictPhonePrefixes?: boolean
  requirePhoneNumber?: boolean
  skipLabel?: boolean

  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getFieldError: getFieldErrorSignature<Fields>
}

export default function PhoneNumberForm<Fields extends PhoneNumberFields>(props: Props<Fields>): ReactElement | null {
  const { decorateField, getFieldValue, getFieldError } = props

  const prefixes = props.restrictPhonePrefixes ? getPermittedSMSPhonePrefixes() : getPhonePrefixes()

  return (
    <Form.Item
      validateStatus={getFieldError('phoneNumberCountryCode') || getFieldError('phoneNumber') ? 'error' : 'success'}
    >
      {!props.skipLabel && (
        <label htmlFor="phoneNumber" title={t('form.field.phone_number')}>
          {getFieldError('phoneNumberCountryCode') || getFieldError('phoneNumber') || t('form.field.phone_number')}
        </label>
      )}
      <Input.Group compact className="country-code-phone-group">
        {decorateField('phoneNumberCountryCode', {
          placeholder: t('form.field.phone_number_country_code'),
          validate: (val) => {
            if (!getFieldValue('phoneNumber')) {
              return null
            }
            if (!val) {
              return t('form.validation.phone_number_country_code_is_required')
            }
            if (prefixes.indexOf(val) === -1) {
              return t('form.validation.phone_number_country_code_is_invalid')
            }
            return null
          },
          skipWrapper: true,
          skipLabel: true,
        })(
          <Select disabled={props.disabled} dropdownMatchSelectWidth={false}>
            {prefixes.map((prefix) => {
              return (
                <Select.Option key={prefix} value={prefix}>
                  +{prefix}
                </Select.Option>
              )
            })}
          </Select>
        )}
        {decorateField('phoneNumber', {
          placeholder: t('form.field.phone_number'),
          validate: (val) => {
            if (!val) {
              if (props.requirePhoneNumber) {
                return t('form.validation.phone_number_is_required')
              } else {
                return null
              }
            }
            if (!val.match(/^[0-9]+$/)) {
              return t('form.validation.phone_number_is_invalid')
            }
            if (getFieldValue('phoneNumberCountryCode') === '45' && !val.match(/^[0-9]{8}$/)) {
              return t('form.validation.phone_number_is_invalid')
            }
            return null
          },
          skipWrapper: true,
          skipLabel: true,
        })(<Input tabIndex={props.tabIndex} disabled={props.disabled} />)}
      </Input.Group>
    </Form.Item>
  )
}
