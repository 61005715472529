import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company, { CompanyDKSpecific } from '../../model/company'
import { BasicSalaryCycle } from '../../model/salaryCycle'
import { SignupCompanyRole } from '../../model/user'
import CompanySize from '../../types/company-size'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatCompanySize } from '../../utils/format-utils'
import { forceParseInputNumber, formatOrdinalNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import { getCityFromPostalCode } from '../../utils/validation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'

type Props = {
  companies: List<Company>
  salaryCycles: BasicSalaryCycle[]

  name?: string
  address?: string
  postalCode?: string
  city?: string
  companySize?: string
  dkSpecific?: CompanyDKSpecific
}

export type AccountingSoftware =
  | 'Billy'
  | 'Dinero'
  | 'Dynaccount'
  | 'Dynamics 365 Business Central'
  | 'e-conomic'
  | 'Meneto'
  | 'Uniconta'
  | 'Xena'
  | 'Xero'
  | 'Manual'
  | 'Other'

type Fields = {
  name?: string
  address?: string
  postalCode?: string
  city?: string
  defaultHourlyMonthlyCycleID: string
  skatSize: CompanySize
  allowAutomaticZeroTaxReport: 'Yes' | 'No'
  companySize?: string
  accountingSoftware?: AccountingSoftware
  companyRole?: SignupCompanyRole
  referredBy?: string
}

export type DataResult = {
  name: string
  address: string
  postalCode: string
  city: string
  dkSpecific: CompanyDKSpecific
  companySize: string
  accountingSoftware: AccountingSoftware
  companyRole?: SignupCompanyRole
  referredBy?: string
}

function BaseDataForm(props: Props & FormComponentProps<Fields, DataResult>): ReactElement | null {
  const { decorateField } = props

  const firstCompany = props.companies.size < 1

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('name', {
            placeholder: t('companies_add.form.name'),
            validate: (val) => (!val ? t('companies_add.form.name.required') : null),
          })(<Input />)}
        </Col>
        <Col span={12}>
          {decorateField('companySize', {
            placeholder: t('companies_add.form.company_size'),
            validate: (val) => (!val ? t('companies_add.form.company_size.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} allowClear>
              <Select.Option value="1-2">{t('companies_add.form.company_size.option.1_2')}</Select.Option>
              <Select.Option value="3-5">{t('companies_add.form.company_size.option.3_5')}</Select.Option>
              <Select.Option value="6-10">{t('companies_add.form.company_size.option.6_10')}</Select.Option>
              <Select.Option value="11-20">{t('companies_add.form.company_size.option.11_20')}</Select.Option>
              <Select.Option value="21-50">{t('companies_add.form.company_size.option.21_50')}</Select.Option>
              <Select.Option value="51-100">{t('companies_add.form.company_size.option.51_100')}</Select.Option>
              <Select.Option value="100+">{t('companies_add.form.company_size.option.100_plus')}</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('address', {
            placeholder: t('companies_add.form.address'),
            validate: (val) => (!val ? t('companies_add.form.address.required') : null),
          })(<Input />)}
        </Col>
        <Col span={6}>
          {decorateField('postalCode', {
            placeholder: t('companies_add.form.postal_code'),
            validate: (val) => {
              if (!val) {
                return t('companies_add.form.postal_code.required')
              }
              const n = forceParseInputNumber(val)
              if (n >= 3900 && n < 4000) {
                return t('companies_add.form.postal_code.greenland_not_allowed')
              }
              if (!getCityFromPostalCode(val)) {
                return t('companies_add.form.postal_code.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
        <Col span={6}>
          {decorateField('city', {
            placeholder: t('companies_add.form.city'),
            validate: (val) => (!val ? t('companies_add.form.city.required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('skatSize', {
            placeholder: t('companies_add.form.skat_size'),
            validate: (val) => (!val ? t('companies_add.form.skat_size.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} placeholder={t('companies_add.form.skat_size.placeholder')}>
              <Select.Option value={CompanySize.SMALL}>{formatCompanySize(CompanySize.SMALL)}</Select.Option>
              <Select.Option value={CompanySize.LARGE}>{formatCompanySize(CompanySize.LARGE)}</Select.Option>
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('allowAutomaticZeroTaxReport', {
            placeholder: t('companies_add.form.allow_automatic_zero_tax_report'),
            validate: (val) =>
              val === undefined ? t('companies_add.form.allow_automatic_zero_tax_report.required') : null,
          })(
            <Select dropdownMatchSelectWidth={false}>
              <Select.Option value={'Yes'}>
                {t('companies_add.form.allow_automatic_zero_tax_report.true')}
              </Select.Option>
              <Select.Option value={'No'}>
                {t('companies_add.form.allow_automatic_zero_tax_report.false')}
              </Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('defaultHourlyMonthlyCycleID', {
            title: t('companies_add.form.default_hourly_monthly_cycle_id'),
            placeholder: t('companies_add.form.default_hourly_monthly_cycle_id.placeholder'),
            validate: (val) => (!val ? t('companies_add.form.default_hourly_monthly_cycle_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.salaryCycles
                .filter((salaryCycle) => !salaryCycle.prepaid && salaryCycle.frequency === 'Monthly')
                .map((salaryCycle) => {
                  let desc = t('companies_add.form.default_hourly_monthly_cycle_id.no_offset')
                  if (salaryCycle.offset) {
                    desc = t('companies_add.form.default_hourly_monthly_cycle_id.with_offset', {
                      from: formatOrdinalNumber(salaryCycle.offset + 1),
                      to: formatOrdinalNumber(salaryCycle.offset),
                    })
                  }
                  return (
                    <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                      {desc}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('accountingSoftware', {
            placeholder: t('companies_add.form.accounting_software'),
            validate: (val) => (!val ? t('companies_add.form.accounting_software.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} allowClear>
              <Select.Option value="Billy">Billy</Select.Option>
              <Select.Option value="Dinero">Dinero</Select.Option>
              <Select.Option value="Dynaccount">Dynaccount</Select.Option>
              <Select.Option value="Dynamics 365 Business Central">Dynamics 365 Business Central</Select.Option>
              <Select.Option value="e-conomic">e-conomic</Select.Option>
              <Select.Option value="Meneto">Meneto</Select.Option>
              <Select.Option value="Uniconta">Uniconta</Select.Option>
              <Select.Option value="Xena">Xena</Select.Option>
              <Select.Option value="Xero">Xero</Select.Option>
              <Select.Option value="Manual">{t('companies_add.form.accounting_software.manual')}</Select.Option>
              <Select.Option value="Other">{t('companies_add.form.accounting_software.other')}</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      {firstCompany && (
        <Row>
          <Col span={12}>
            {decorateField('companyRole', {
              placeholder: t('companies_add.form.company_role'),
              validate: (val) => (!val ? t('companies_add.form.company_role.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false} allowClear>
                <Select.Option value="Company Owner, Runs Pay Roll">
                  {t('companies_add.form.company_role.option.company_owner_runs_pay_roll')}
                </Select.Option>
                <Select.Option value="Company Owner, Accountant Runs Pay Roll">
                  {t('companies_add.form.company_role.option.company_owner_accountant_runs_pay_roll')}
                </Select.Option>
                <Select.Option value="Accountant">
                  {t('companies_add.form.company_role.option.accountant')}
                </Select.Option>
              </Select>
            )}
          </Col>
          <Col span={12}>
            {decorateField('referredBy', {
              placeholder: t('companies_add.form.referred_by'),
            })(<Input />)}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" id="btn-create-company">
            {t('companies_add.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, DataResult>({
  mapPropsToFields: (props) => {
    let defaultHourlyMonthlyCycleID = undefined
    const salaryCycle = props.salaryCycles.find(
      (salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.prepaid && !salaryCycle.offset
    )
    if (salaryCycle) {
      defaultHourlyMonthlyCycleID = salaryCycle.id
    }
    return {
      name: props.name,
      address: props.address,
      postalCode: props.postalCode,
      city: props.city,
      companySize: props.companySize,
      defaultHourlyMonthlyCycleID: defaultHourlyMonthlyCycleID!,
      skatSize: props.dkSpecific?.skatSize ?? CompanySize.SMALL,
      allowAutomaticZeroTaxReport: props.dkSpecific?.allowAutomaticZeroTaxReport ?? true ? 'Yes' : 'No',
    }
  },
  onChange: (key, val) => {
    const values = {}
    switch (key) {
      case 'postalCode': {
        setByPath(values, key, val)
        const city = getCityFromPostalCode(val as string)
        if (city) {
          setByPath(values, 'city', city)
        }
        break
      }
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    return {
      name: values.name!,
      address: values.address!,
      postalCode: values.postalCode!,
      city: values.city!,
      dkSpecific: {
        skatSize: values.skatSize,
        allowAutomaticZeroTaxReport: values.allowAutomaticZeroTaxReport === 'Yes',
      },
      companySize: values.companySize!,
      accountingSoftware: values.accountingSoftware!,
      companyRole: values.companyRole,
      referredBy: values.referredBy,
    }
  },
})(BaseDataForm)
