import React, { ReactElement, useEffect, useState } from 'react'

import { harvest } from '../../api/harvest'
import { ReactComponent as SalaryLogo } from '../../assets/logo.svg'
import { AccessToken } from '../../model/user'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { getParam } from '../../utils/route-utils'
import { trackGaEvent } from '../../utils/tracking-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import RegisterForm, { RegisterResult } from './RegisterForm'

import './Register.css'

type Props = {
  user: UserReducer
  register: (
    email: string,
    password: string,
    name: string,
    phoneNumber: string,
    phoneNumberCountryCode: string
  ) => Promise<AccessToken | void>
}

export default function Register(props: Props): ReactElement | null {
  type State = {
    name?: string
    phoneNumber?: string
    phoneNumberCountryCode?: string
    email?: string
    password?: string
    firstSession?: string
    returningSession?: string
    terms: boolean
  }
  const [state] = useState<State>(() => {
    const state: State = {
      phoneNumberCountryCode: '45',
      terms: false,
    }

    const params = getParam('d')
    if (params) {
      atob(params)
        .split('&')
        .forEach((param) => {
          const [key, val] = param.split('=')
          switch (key) {
            case 'name':
            case 'phoneNumber':
            case 'email':
              state[key] = val
              break
            default:
              break
          }
        })
    }

    const firstSession = getParam('firstSession')
    if (firstSession) {
      state.firstSession = atob(firstSession)
    }
    const returningSession = getParam('returningSession')
    if (returningSession) {
      state.returningSession = atob(returningSession)
    }
    return state
  })
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const handleSubmit = (values: RegisterResult) => {
    props
      .register(values.email, values.password, values.name, values.phoneNumber, values.phoneNumberCountryCode)
      .then((res) => {
        if (res && res.user && res.user.id) {
          // Save accepted terms (fire and forget)
          harvest(res.user.id, undefined, {
            AcceptedTerms: 'v1.1',
            FirstSession: state.firstSession,
            ReturningSession: state.returningSession,
          })
          // Track signup
          trackGaEvent('User', 'signup')
        }
      })
  }

  return (
    <div className="login-register responsive-wrapper">
      <div className="register-form">
        <SalaryLogo className="logo" />
        <Title>{t('register.title')}</Title>

        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <RegisterForm {...state} onSubmit={handleSubmit} />
        {props.user.loggingIn && <LoadingOverlay />}
      </div>
      <div className="register-presentation">
        <Title>{t('register.presentation.title')}</Title>
        <ol>
          <li>{t('register.presentation.points.0')}</li>
          <li>{t('register.presentation.points.1')}</li>
          <li>{t('register.presentation.points.2')}</li>
          <li>{t('register.presentation.points.3')}</li>
          <li>{t('register.presentation.points.4')}</li>
          <li>{t('register.presentation.points.5')}</li>
          <li>{t('register.presentation.points.6')}</li>
          <li>{t('register.presentation.points.7')}</li>
        </ol>
      </div>
    </div>
  )
}
