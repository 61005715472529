import { List } from 'immutable'
import React, { ReactElement, ReactNode, useState } from 'react'

import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import { WarningReducer } from '../../reducers/warnings'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

function getWindowWidth() {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}

const scrollLimit = 1600

type Props = {
  company: Company
  employees: List<Employee>
  warnings: WarningReducer

  deleteWarning: (id: string) => Promise<boolean | void>
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
}

type State = {
  canScrollLeft: boolean
  canScrollRight: boolean
}

export default function Onboarding(props: Props): ReactElement | null {
  type OnboardingItem = {
    key: string
    id?: string
    icon: ReactNode
    title: string
    description: string
    link?: string
    dismissable: boolean
    reducedElementSpacing?: boolean
    reducedTitlePadding?: boolean
    acknowledgeOnClick?: boolean
    imageWidth?: number
    buttonClick?: () => void
    buttonText?: string
    progress?: number
    order: number
  }
  const getItems = (): OnboardingItem[] => {
    const items: OnboardingItem[] = []
    const employees = props.employees
    if (!employees.some((employee) => !!employee.earliestMutableContract)) {
      items.push({
        key: 'create-employee-card',
        icon: <Icon type="user" />,
        title: t('dashboard.onboarding.create_employee.title'),
        description: t('dashboard.onboarding.create_employee.description'),
        link: '/' + paths.EMPLOYEES + '/' + paths.ADD,
        dismissable: false,
        progress: 0.2,
        order: 10,
      })
    }
    props.warnings.warnings.forEach((warning) => {
      switch (warning.warningType) {
        case 'NotRegisteredAsEmployerOnboarding':
        case 'CheckEmployerRegistration':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="handAndCoins" />,
            title: t('dashboard.onboarding.employer_registration.title'),
            description: t('dashboard.onboarding.employer_registration.description'),
            link: 'https://help.salary.dk/kom-godt-og-nemt-igang-med-salary/registrering-som-arbejdsgiver',
            dismissable: warning.canAcknowledge,
            reducedElementSpacing: true,
            order: 10,
          })
          break
        case 'PaymentIntegrationFlowNotStarted':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="handAndCoins" />,
            title: t('dashboard.onboarding.payment_flow_not_started.title'),
            description: t('dashboard.onboarding.payment_flow_not_started.description'),
            link: 'https://overfoerselsservice.mastercard.com/',
            dismissable: warning.canAcknowledge,
            reducedTitlePadding: true,
            order: 20,
          })
          break
        case 'MissingAccountingIntegration':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="paperclip" />,
            title: t('dashboard.onboarding.missing_accounting_integration.title'),
            description: t('dashboard.onboarding.missing_accounting_integration.description'),
            link: '/' + paths.COMPANIES + '/' + props.company.id + '/' + paths.ACCOUNTING + '/' + paths.ADD,
            dismissable: warning.canAcknowledge,
            order: 30,
          })
          break
        case 'ChoosePayRollAutomation':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="cog" />,
            title: t('dashboard.onboarding.choose_pay_roll_automation.title'),
            description: t('dashboard.onboarding.choose_pay_roll_automation.description'),
            link: '/' + paths.COMPANIES + '/' + props.company.id + '/pay',
            dismissable: warning.canAcknowledge,
            order: 40,
          })
          break
        case 'CampaignGrandhood':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="users" />,
            title: t('dashboard.onboarding.grandhood.title'),
            description: t('dashboard.onboarding.grandhood.description'),
            link: 'https://www.grandhood.dk/partner/salary/',
            dismissable: warning.canAcknowledge,
            acknowledgeOnClick: true,
            imageWidth: 45,
            order: 25,
          })
          break
        case 'ConfigureExpenseCategoryAccounts':
          items.push({
            id: warning.id,
            key: warning.id,
            icon: <Icon type="cog" />,
            title: t('dashboard.onboarding.configure_expense_category_accounts.title'),
            description: t('dashboard.onboarding.configure_expense_category_accounts.description'),
            link: '/' + paths.COMPANIES + '/' + props.company.id + '/expense-categories',
            dismissable: warning.canAcknowledge,
            reducedTitlePadding: true,
            order: 45,
          })
          break
        default:
          break
      }
    })
    items.sort((a, b) => {
      return a.order - b.order
    })
    return items
  }

  const [state, setState] = useState<State>({
    canScrollLeft: false,
    canScrollRight: getWindowWidth() < scrollLimit && getItems().length > 3,
  })

  const dismissWarning = (id?: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (id) {
        props.deleteWarning(id)
      }
    }
  }

  const cardsContainer = React.useRef<HTMLDivElement>(null)

  const canScrollLeft = (): boolean => {
    if (getWindowWidth() > scrollLimit) {
      return false
    }
    if (!cardsContainer.current) {
      return false
    }
    return cardsContainer.current.scrollLeft > 0
  }
  const canScrollRight = (): boolean => {
    if (getWindowWidth() > scrollLimit) {
      return false
    }
    if (!cardsContainer.current) {
      return false
    }
    const items = getItems()
    if (items.length < 4) {
      return false
    }
    const pos = cardsContainer.current.scrollLeft
    return pos < (items.length - 3) * 345
  }
  const scrollLeft = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!cardsContainer.current) {
      return
    }

    const pos = cardsContainer.current.scrollLeft
    cardsContainer.current.scrollLeft = Math.max(pos - 345, 0)
    setState({
      canScrollLeft: canScrollLeft(),
      canScrollRight: canScrollRight(),
    })
  }
  const scrollRight = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!cardsContainer.current) {
      return
    }

    const items = getItems()
    const pos = cardsContainer.current.scrollLeft
    cardsContainer.current.scrollLeft = Math.min(pos + 345, (items.length - 3) * 345)
    setState({
      canScrollLeft: canScrollLeft(),
      canScrollRight: canScrollRight(),
    })
  }

  const items = getItems()
  if (items.length === 0) {
    return null
  }

  return (
    <div className="onboarding">
      <div ref={cardsContainer} className="onboarding-cards">
        {items.map((item) => {
          return (
            <div
              key={item.key}
              className={
                'onboarding-card' +
                (item.reducedTitlePadding ? ' reduced-padding' : '') +
                (item.reducedElementSpacing ? ' reduced-spacing' : '') +
                (item.buttonClick ? ' with-button' : '')
              }
              onClick={() => {
                if (item.buttonClick) {
                  return // do not do anything if it has a button
                }
                if (item.acknowledgeOnClick) {
                  dismissWarning(item.id)
                }
                if (item.link && item.link.match(/^\//)) {
                  jsBrowserHistory.push(item.link)
                } else {
                  window.open(item.link, '_blank', 'noopener noreferrer')
                }
              }}
            >
              {item.dismissable && (
                <span className="onboarding-close" onClick={dismissWarning(item.id)}>
                  <Icon type="xSign" />
                </span>
              )}
              {item.icon}
              <Headline>{item.title}</Headline>
              <p>{item.description}</p>

              {item.buttonClick && (
                <Button onClick={item.buttonClick} type="secondary">
                  {item.buttonText ? item.buttonText : t('dashboard.onboarding.click_here')}
                </Button>
              )}

              {props.warnings.saving && <LoadingOverlay />}
            </div>
          )
        })}
      </div>

      {state.canScrollLeft && <div className="onboarding-arrow onboarding-arrow-left" onClick={scrollLeft} />}
      {state.canScrollRight && <div className="onboarding-arrow onboarding-arrow-right" onClick={scrollRight} />}
    </div>
  )
}
