import React from 'react'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  switchToManual: (e: React.MouseEvent) => void
}

export default function SwitchToManual(props: Props) {
  return (
    <Card className="pay-roll-switch-to-nets">
      <Subtitle>{t('pay_roll.single.manual.title')}</Subtitle>
      <p>{t('pay_roll.single.manual.warning')}</p>
      <p>{t('pay_roll.single.manual.intro.line_1')}</p>
      <p>{t('pay_roll.single.manual.intro.line_2')}</p>
      <Row>
        <Col span={24}>
          <Button size="extra-extra-large" type="secondary" block onClick={props.switchToManual}>
            {t('pay_roll.single.manual.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
