import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import SalaryType from '../../../model/salaryType'
import TimeRegistration, { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { DateFormat } from '../../../model/types'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { formatDate } from '../../../utils/date-utils'
import { formatLeaveTypeName, formatTimeRegistrationClass } from '../../../utils/format-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatCurrency, formatDisplayNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Tooltip from '../../elements/tooltip'
import { TimeRegistrationMode } from '../../types'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import DetailedTimeRegistrationEdit from './DetailedTimeRegistrationEdit'

type Props = {
  company: Company
  employee: Employee
  mode: TimeRegistrationMode
  canEditObjects: boolean
  canApproveObjects: boolean
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: List<SalaryType>
  leaveTypes: List<LeaveType>
  projects: List<Project>
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  departments: List<Department>

  addAlert: addAlertSignature
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
}

export default function DetailedTimeRegistrationTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState<number>(1)
  const [editing, setEditing] = useState<boolean>(false)
  const [timeRegID, setTimeRegID] = useState<string | undefined>(undefined)

  const setEditVisibility = useCallback(
    (id: string | boolean) => {
      // Increment modalKey to create a new component
      setModalKey(modalKey + 1)
      if (typeof id === 'boolean') {
        setEditing(id)
        setTimeRegID(undefined)
      } else {
        setEditing(true)
        setTimeRegID(id)
      }
    },
    [setModalKey, modalKey, setEditing, setTimeRegID]
  )

  const saving = props.timeRegistrations.saving
  const wasSaving = usePrevious(saving)
  const savingError = props.timeRegistrations.error

  useEffect(() => {
    if (wasSaving && !saving) {
      if (!savingError) {
        setEditVisibility(false)
      }
    }
  }, [wasSaving, saving, savingError, setEditVisibility])

  const approve = (timeRegistration: TimeRegistration) => {
    setApproving((prev) => [...prev, timeRegistration.id])
    props.approveTimeRegistrations([timeRegistration.id])
  }
  const unapprove = (timeRegistration: TimeRegistration) => {
    setApproving((prev) => prev.filter((id) => id !== timeRegistration.id))
    props.unapproveTimeRegistrations([timeRegistration.id])
  }

  type TimeRegistrationRow = {
    key: string
    id: string
    approved: boolean
    date: DateFormat
    description: string | React.ReactNode
    hours: string
    note: string
    settled: boolean
    original: TimeRegistration
  }

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    const isHourRegistration = props.mode === 'Hours'
    return props.timeRegistrations.timeRegistrations
      .filter(
        (timeRegistration) =>
          timeRegistration.employeeID === props.employee.id &&
          !timeRegistration.settled &&
          ((isHourRegistration && timeRegistration.class === 'Hours') ||
            (props.mode === 'FlexTime' && timeRegistration.class === 'Flex') ||
            (props.mode === 'Overtime' && timeRegistration.class === 'Overtime') ||
            (props.mode === 'Project' && timeRegistration.class === 'Project Hours'))
      )
      .toArray()
      .map((timeRegistration: TimeRegistration): TimeRegistrationRow => {
        let title
        let hours = formatDisplayNumber(timeRegistration.hours)
        if (timeRegistration.class === 'Hours') {
          let rate = null
          if (props.employee.activeContract && props.employee.activeContract.remuneration) {
            const salaryLine = props.employee.activeContract.remuneration.salary.find(
              (row) => row.salaryTypeID === timeRegistration.salaryTypeID
            )
            if (salaryLine) {
              title = salaryLine.title
              rate = salaryLine.rate
            }
          }
          if (!title) {
            title =
              getSalaryType(props.salaryTypes.toArray(), timeRegistration.salaryTypeID)?.title ||
              formatTimeRegistrationClass(timeRegistration.class)
          }
          if (rate) {
            title = t('time_registration_tab.detailed.table.title.rate_format', { title, rate: formatCurrency(rate) })
          }
          if (timeRegistration.projectID) {
            const project = props.projects.find((p) => p.id === timeRegistration.projectID)
            if (project) {
              title = (
                <>
                  {title}
                  <br />
                  <small>{project.name}</small>
                </>
              )
            }
          }
        } else if (timeRegistration.class === 'Flex' || timeRegistration.class === 'Overtime') {
          const hoursCalc = timeRegistration.minutes ? timeRegistration.minutes / 60 : 0
          if (props.mode === 'Overtime') {
            title = formatLeaveTypeName('DenmarkOvertime')
          } else {
            title = formatLeaveTypeName('DenmarkFlexTime')
          }
          if (timeRegistration.minutes && timeRegistration.minutes < 0) {
            hours = t('time_registration_tab.detailed.table.title.earned', {
              hours: formatDisplayNumber(hoursCalc * -1),
            })
          } else {
            hours = t('time_registration_tab.detailed.table.title.used', { hours: formatDisplayNumber(hoursCalc) })
          }
        } else if (timeRegistration.class === 'Project Hours') {
          title =
            props.projects.find((p) => p.id === timeRegistration.projectID)?.name ||
            formatTimeRegistrationClass(timeRegistration.class)
        } else {
          title = formatTimeRegistrationClass(timeRegistration.class)
        }
        return {
          key: timeRegistration.id,
          id: timeRegistration.id,
          approved: timeRegistration.approved,
          date: formatDate(timeRegistration.date),
          description: title,
          hours: hours,
          note: timeRegistration.note,
          settled: timeRegistration.settled,
          original: timeRegistration,
        }
      })
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const registrations = getTimeRegistrations()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(registrations.map((reg) => reg.id))
      props.approveTimeRegistrations(registrations.map((reg) => reg.id))
    }
  }

  const remove = (timeRegistration: TimeRegistration) => {
    if (window.confirm(t('common.are_you_sure'))) {
      setDeleting([...deleting, timeRegistration.id])
      props.deleteTimeRegistration(timeRegistration.id)
    }
  }

  const getUnsettledHours = (project: TimeRegistrationClass): string => {
    const hours = props.timeRegistrations.timeRegistrations
      .filter(
        (timeRegistration) =>
          timeRegistration.employeeID === props.employee.id &&
          timeRegistration.class === project &&
          !timeRegistration.settled
      )
      .reduce((hours, timeRegistration) => {
        if (project === 'Flex' || project === 'Overtime') {
          return hours + (timeRegistration.minutes ? timeRegistration.minutes / 60 : 0)
        } else {
          return hours + (timeRegistration.hours ? timeRegistration.hours : 0)
        }
      }, 0)
    const mul = project === 'Flex' || project === 'Overtime' ? -1 : 1
    return t('unit.hour_count', { count: hours * mul })
  }

  const getColumns = () => {
    return [
      {
        title: t('time_registration_tab.detailed.table.approved'),
        key: 'x1',
        className: 'time-registration-table-approved',
        render: (timeRegistration: TimeRegistrationRow) => {
          if (timeRegistration.approved) {
            return (
              <span className="table-approve-wrapper">
                {t('time_registration_tab.detailed.table.approved.true')}
                {props.canApproveObjects && (
                  <Tooltip title={t('time_registration_tab.detailed.table.approved.remove')}>
                    <span
                      onClick={(e) => {
                        e.preventDefault()
                        unapprove(timeRegistration.original)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon type="xSign" />
                    </span>
                  </Tooltip>
                )}
              </span>
            )
          }
          if (approving.indexOf(timeRegistration.id) !== -1) {
            return t('time_registration_tab.detailed.table.saving')
          }
          if (!props.canApproveObjects) {
            return t('time_registration_tab.detailed.table.approved.false')
          }
          return (
            <DumbLink
              onClick={(e) => {
                e.preventDefault()
                approve(timeRegistration.original)
              }}
              type="standard"
            >
              {t('time_registration_tab.detailed.table.approved.approve')}
            </DumbLink>
          )
        },
      },
      { title: t('time_registration_tab.detailed.table.date'), dataIndex: 'date', key: 'date' },
      { title: t('time_registration_tab.detailed.table.description'), dataIndex: 'description', key: 'description' },
      { title: t('time_registration_tab.detailed.table.hours'), dataIndex: 'hours', key: 'hours' },
      {
        title: t('time_registration_tab.detailed.table.note'),
        key: 'x2',
        className: 'employee-table-actions',
        render: (timeRegistration: TimeRegistrationRow) => {
          if (!timeRegistration.note) {
            return null
          }
          return (
            <div>
              <Tooltip title={timeRegistration.note}>
                <span>
                  <Icon type="document" />
                </span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        title: '',
        key: 'x3',
        className: 'employee-table-actions',
        render: (timeRegistration: TimeRegistrationRow) => {
          if (deleting.indexOf(timeRegistration.id) !== -1) {
            return null
          }
          if (timeRegistration.settled) {
            return null
          }
          if (!props.canEditObjects) {
            return null
          }
          return (
            <div>
              <Tooltip title={t('time_registration_tab.detailed.table.actions.edit')}>
                <span onClick={() => setEditVisibility(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="paperWithPencil" />
                </span>
              </Tooltip>
              <Tooltip title={t('time_registration_tab.detailed.table.actions.delete')}>
                <span
                  onClick={(e) => {
                    e.preventDefault()
                    remove(timeRegistration.original)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon type="xSign" />
                </span>
              </Tooltip>
            </div>
          )
        },
      },
    ]
  }

  const hasUnapprovedTimeRegistrations = (): boolean => {
    return getTimeRegistrations().some((reg) => !reg.approved)
  }

  const bulkDelete = () => {
    if (window.confirm(t('time_registration_tab.detailed.confirm.bulk_delete'))) {
      props.deleteTimeRegistrationBulk(undefined, props.employee.id, 'Hours')
    }
  }

  const timeRegistrations = getTimeRegistrations()
  let balanceNote = t('time_registration_tab.detailed.balance_note.hours')
  let currentType: TimeRegistrationClass = 'Hours'
  let addNewLine = t('time_registration_tab.detailed.add_new.hours')
  if (props.mode === 'FlexTime') {
    balanceNote = t('time_registration_tab.detailed.balance_note.flex_time')
    currentType = 'Flex'
    addNewLine = t('time_registration_tab.detailed.add_new.flex_time')
  }
  if (props.mode === 'Overtime') {
    balanceNote = t('time_registration_tab.detailed.balance_note.overtime')
    currentType = 'Overtime'
    addNewLine = t('time_registration_tab.detailed.add_new.overtime')
  }
  if (props.mode === 'Project') {
    balanceNote = t('time_registration_tab.detailed.balance_note.project')
    currentType = 'Project Hours'
    addNewLine = t('time_registration_tab.detailed.add_new.project')
  }
  return (
    <div>
      <p>{balanceNote}</p>
      <Row>
        <Col span={8}>
          <div className="time-registration-stat">
            <strong>{getUnsettledHours(currentType)}</strong>
            <span className="time-registration-hours">{formatTimeRegistrationClass(currentType)}</span>
          </div>
        </Col>
        <Col span={16} className="time-registration-buttons">
          {props.mode === 'Hours' &&
            props.canEditObjects &&
            timeRegistrations.filter((timeReg) => !timeReg.settled).length > 0 && (
              <Button
                onClick={() => bulkDelete()}
                type="primary"
                danger
                style={{ width: '125px', marginRight: '10px' }}
                prefixIcon="xSign"
              >
                {t('time_registration_tab.detailed.delete_bulk')}
              </Button>
            )}

          {props.canEditObjects && (
            <Button
              type="primary"
              onClick={() => setEditVisibility(true)}
              className="register-time-registration"
              prefixIcon="plusCircle"
            >
              {addNewLine}
            </Button>
          )}

          {hasUnapprovedTimeRegistrations() && props.canApproveObjects && (
            <Button type="secondary" onClick={approveAll} style={{ marginLeft: 20 }}>
              {t('time_registration_tab.detailed.approve_all')}
            </Button>
          )}
        </Col>
      </Row>

      <Table columns={getColumns()} dataSource={timeRegistrations} pagination={false} />
      {props.timeRegistrations.saving && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.timeRegistrations.saving, text: t('loading.reducer.time_registrations') },
          ])}
        />
      )}

      <Modal
        key={modalKey}
        visible={editing}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={376}
        footer={null}
      >
        <DetailedTimeRegistrationEdit
          visible={editing}
          company={props.company}
          employee={props.employee}
          mode={props.mode}
          timeRegistrationID={timeRegID}
          timeRegistrations={props.timeRegistrations}
          salaryTypes={props.salaryTypes}
          leaveTypes={props.leaveTypes}
          projects={props.projects}
          costCenters={props.costCenters}
          departments={props.departments}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </div>
  )
}
