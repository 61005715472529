import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingAccount } from '../../model/accountingIntegration'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import Voucher from '../../model/voucher'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Table from '../elements/table'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type VoucherLineRow = {
  key: string
  accountNumber: string
  description: string
  debit: string
  credit: string
  vat: string
}

type Props = {
  visible: boolean
  voucher?: Voucher
  reimbursementVoucher?: ReimbursementVoucher
  expenseCategories: List<ExpenseCategory>
  accounts: List<AccountingAccount>
}

export default function VoucherLinesModal(props: Props): ReactElement | null {
  const getVouchers = (): VoucherLineRow[] => {
    if (props.voucher) {
      return props.voucher.lines
        .filter((line) => line.amount)
        .map((line) => {
          return {
            key: line.type,
            accountNumber: line.accountNumber || t('vouchers.modal.unknown_account'),
            description: line.text,
            debit: line.amount > 0 ? formatCurrency(line.amount, 2) : '',
            credit: line.amount < 0 ? formatCurrency(-line.amount, 2) : '',
            vat: line.vatAmount ? formatCurrency(line.vatAmount, 2) : '',
          }
        })
    }
    if (props.reimbursementVoucher) {
      let debitAccount = t('vouchers.modal.unknown_account')
      let creditAccount = t('vouchers.modal.unknown_account')
      if (
        props.reimbursementVoucher.expenseCategory &&
        props.reimbursementVoucher.expenseCategory.accountingAccountID
      ) {
        const accountingID = props.reimbursementVoucher.expenseCategory.accountingAccountID
        const account = props.accounts.find((account) => account.id === accountingID)
        if (account) {
          debitAccount = account.accountNumber
        }
      }
      if (props.reimbursementVoucher.offsetAccountingAccountID) {
        const account = props.accounts.find(
          (account) => account.id === props.reimbursementVoucher?.offsetAccountingAccountID
        )
        if (account) {
          creditAccount = account.accountNumber
        }
      }
      return [
        {
          key: '1',
          accountNumber: debitAccount,
          description: props.reimbursementVoucher.cardNote || props.reimbursementVoucher.note,
          debit: formatCurrency(props.reimbursementVoucher.disburseAmount || 0, 2),
          credit: '',
          vat: '',
        },
        {
          key: '2',
          accountNumber: creditAccount,
          description: props.reimbursementVoucher.cardNote || props.reimbursementVoucher.note,
          credit: formatCurrency(props.reimbursementVoucher.disburseAmount || 0, 2),
          debit: '',
          vat: '',
        },
      ]
    }
    return []
  }

  const columns = [
    { title: t('vouchers.modal.header.account_number'), dataIndex: 'accountNumber', key: 'accountNumber' },
    { title: t('vouchers.modal.header.description'), dataIndex: 'description', key: 'description' },
    { title: t('vouchers.modal.header.debit'), dataIndex: 'debit', key: 'debit' },
    { title: t('vouchers.modal.header.credit'), dataIndex: 'credit', key: 'credit' },
    { title: t('vouchers.modal.header.vat'), dataIndex: 'vat', key: 'vat' },
  ]

  return (
    <Card className="pay-roll-tax-report">
      <Title>{t('vouchers.modal.title')}</Title>

      <Table
        columns={columns}
        dataSource={getVouchers()}
        pagination={false}
        className={'pay-roll-table pay-roll-table-small'}
      />

      {!props.voucher && !props.reimbursementVoucher && <LoadingOverlay />}
    </Card>
  )
}
