import React, { ReactElement, useEffect, useState } from 'react'

import { getPersonVerificationUrl } from '../../api/person-verification'
import paths from '../../constants/paths'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  signed?: boolean
}

export default function PersonVerificationPage(props: Props): ReactElement | null {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [signURL, setSignURL] = useState('')

  const signed = !!props.signed

  useEffect(() => {
    if (signed) {
      return
    }
    if (!errorMessage) {
      getPersonVerificationUrl()
        .then((res) => {
          setSignURL(res.data.url)
        })
        .catch(() => {
          setErrorMessage(t('person_verification.error.unable_to_find_url'))
        })
    }
  })

  const handleCriiptoSign = (e: React.MouseEvent) => {
    e.preventDefault()
    document.location = signURL + `&state=` + paths.PERSON_VERIFICATION
  }

  if (signed) {
    return (
      <Card>
        <div className="signing-fields">
          <Title>{t('person_verification.title')}</Title>
          <p>{t('person_verification.signed')}</p>
        </div>
      </Card>
    )
  }

  if (errorMessage) {
    return <Alert type={'error'} message={errorMessage} showIcon={true} />
  }

  if (!signURL) {
    return <LoadingOverlay />
  }

  return (
    <Card>
      <div className="signing-fields">
        <Title>{t('person_verification.title')}</Title>
        <Button onClick={handleCriiptoSign} size="extra-extra-large" type="secondary" className="mitid-button">
          {t('person_verification.sign_with_mitid')}
        </Button>
      </div>
    </Card>
  )
}
