import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Project from '../../model/project'
import TimeRegistration from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatCurrency, formatDisplayNumber } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Button from '../elements/button'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  timeRegistrations: TimeRegistrationReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>
  projects: List<Project>

  addAlert: addAlertSignature
  approveTimeRegistrations: (ids: string[]) => void
}

export default function TimeRegistrationsCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  type Sort = {
    sortOn?: 'employee' | 'date' | 'hours'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { timeRegistrations, addAlert } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (timeRegistrations.error) {
        addAlert('error', formatError(timeRegistrations.error))
      }
      setApproving([]) //empty it
    }
  }, [previousTimeRegistrations, timeRegistrations, addAlert, setApproving])

  const pendingTimeRegistrations: TimeRegistration[] = timeRegistrations.timeRegistrations
    .filter(
      (timeReg) =>
        !timeReg.approved &&
        timeReg.class === 'Hours' &&
        !approving.some((approve) => approve === timeReg.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, timeReg.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveTimeRegistrations([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingTimeRegistrations.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveTimeRegistrations(unapproved)
  }

  type TimeRegistrationRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    date: string
    hours: string
    rate: string
    projectName?: string
    original: TimeRegistration
  }

  const columns = [
    {
      title: t('approve_tab.time_registrations.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: TimeRegistrationRow) => {
        return (
          <ApproveCardEmployeeName
            {...row}
            linkTo={'/' + paths.EMPLOYEES + '/' + row.employeeID + '/time-registration'}
          />
        )
      },
    },
    { title: t('approve_tab.time_registrations.header.date'), dataIndex: 'date', key: 'date', sorter: 'date' },
    { title: t('approve_tab.time_registrations.header.hours'), dataIndex: 'hours', key: 'hours', sorter: 'hours' },
    {
      title: t('approve_tab.time_registrations.header.rate'),
      dataIndex: '',
      key: 'xRate',
      render: (row: TimeRegistrationRow) => {
        if (!row.projectName) {
          return row.rate
        }
        return (
          <>
            {row.rate}
            <br />
            <small>{row.projectName}</small>
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: TimeRegistrationRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.time_registrations.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingTimeRegistrationRows: TimeRegistrationRow[] = pendingTimeRegistrations
    .map((timeReg) => {
      const employee = props.employees.get(timeReg.employeeID)
      let salaryLine = ''
      if (employee && employee.earliestMutableContract && employee.earliestMutableContract.remuneration) {
        const salary = employee.earliestMutableContract.remuneration.salary.find(
          (salary) => salary.salaryTypeID === timeReg.salaryTypeID
        )
        if (salary) {
          salaryLine = t('approve_tab.time_registrations.table.rate_display', {
            name: translateGroupTitle(salary),
            rate: formatCurrency(salary.rate, 2),
          })
        }
      }
      let projectName
      if (timeReg.projectID) {
        projectName = props.projects.find((project) => project.id === timeReg.projectID)?.name
      }
      return {
        key: timeReg.id,
        id: timeReg.id,
        employeeID: timeReg.employeeID,
        ...getEmployeeRowEmployeeDetails(
          props.employees.get(timeReg.employeeID),
          props.departments,
          props.companyUsers
        ),
        date: formatDate(timeReg.date),
        hours: formatDisplayNumber(timeReg.hours || 0),
        hoursNo: timeReg.hours || 0,
        rate: salaryLine,
        projectName,
        original: timeReg,
      }
    })
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.original.date === j.original.date) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.original.date.localeCompare(j.original.date)
        case 'hours':
          if (i.hoursNo === j.hoursNo) {
            if (i.employeeName === j.employeeName) {
              return a.original.date.localeCompare(b.original.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.hoursNo - i.hoursNo
        default:
          if (a.employeeName === b.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = ({ sorter }: TableChange<TimeRegistrationRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'hours':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingTimeRegistrations.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="extra-extra-large">
          {t('approve_tab.time_registrations.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.time_registrations.title')}</Title>
      <Table columns={columns} dataSource={pendingTimeRegistrationRows} onChange={tableChange} pagination={false} />
    </div>
  )
}
