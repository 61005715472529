import { List } from 'immutable'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { TaxCardUpdate } from '../../actions/tax-cards'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../../api/reimbursement-vouchers'
import { TimeRegistrationBulk } from '../../api/time-registrations'
import paths from '../../constants/paths'
import Asset, { AssetMutableFields } from '../../model/asset'
import AssetCategory from '../../model/assetCategory'
import CarAllowance, { CarAllowanceMutableFields } from '../../model/carAllowance'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../model/coarseCarAllowance'
import CoarseSalaryRegistration, { CoarseSalaryRegistrationMutableFields } from '../../model/coarseSalaryRegistration'
import { CoarseTimeRegistrationMutableFields } from '../../model/coarseTimeRegistration'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../model/contract'
import Department, { DepartmentCreationFields } from '../../model/department'
import { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import Employee from '../../model/employee'
import EmployeeDimension, { EmployeeDimensionMutableFields } from '../../model/employeeDimension'
import { EmployeeEmergencyContactMutableFields } from '../../model/employeeEmergencyContact'
import Employment from '../../model/employment'
import EmploymentPosition from '../../model/employmentPosition'
import ExpenseCategory from '../../model/expenseCategory'
import { LeaveAdjustmentCreationFields, LeaveAdjustmentMutableFields } from '../../model/leaveAdjustment'
import LeaveType from '../../model/leaveType'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../model/oneTimePay'
import { OneTimePayPensionCreationFields, OneTimePayPensionMutableFields } from '../../model/oneTimePayPension'
import PensionCompany from '../../model/pensionCompany'
import PricingPackage from '../../model/pricingPackage'
import ProfileImage from '../../model/profileImage'
import Project from '../../model/project'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import SalaryCycle from '../../model/salaryCycle'
import { SalaryRegistrationMutableFields } from '../../model/salaryRegistration'
import SalaryType from '../../model/salaryType'
import { StartBalances } from '../../model/startBalances'
import { SupplementAdjustmentCreationFields, SupplementAdjustmentMutableFields } from '../../model/supplementAdjustment'
import SupplementType from '../../model/supplementType'
import TaxCardRequest from '../../model/taxCardRequest'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../model/timeRegistration'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../../model/timeRegistrationTemplate'
import { DateFormat } from '../../model/types'
import Warning from '../../model/warning'
import { AccountingDimensionReducer } from '../../reducers/accountingDimensions'
import { AlertReducer } from '../../reducers/alerts'
import { AssetCategoryReducer } from '../../reducers/assetCategories'
import { AssetReducer } from '../../reducers/assets'
import { BankReducer } from '../../reducers/banks'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { CoarseCarAllowanceReducer } from '../../reducers/coarseCarAllowances'
import { CoarseSalaryRegistrationReducer } from '../../reducers/coarseSalaryRegistrations'
import { CoarseTimeRegistrationReducer } from '../../reducers/coarseTimeRegistrations'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { ContractReducer } from '../../reducers/contracts'
import { CostCenterReducer } from '../../reducers/costCenters'
import { DepartmentReducer } from '../../reducers/departments'
import { DocumentCategoryReducer } from '../../reducers/documentCategories'
import { DocumentReducer } from '../../reducers/documents'
import { EmployeeContractDeltaReducer } from '../../reducers/employeeContractDeltas'
import { EmployeeEmergencyContactReducer } from '../../reducers/employeeEmergencyContacts'
import { EmployeeInviteReducer } from '../../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../../reducers/employeeProfileImages'
import { EmployeeReducer } from '../../reducers/employees'
import { EmploymentReducer } from '../../reducers/employments'
import { LeaveAdjustmentReducer } from '../../reducers/leaveAdjustments'
import { LeaveBalanceReducer } from '../../reducers/leaveBalances'
import { OneTimePayPensionReducer } from '../../reducers/oneTimePayPensions'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { PaySlipReducer } from '../../reducers/paySlips'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { StartBalanceReducer } from '../../reducers/startBalances'
import { SupplementAdjustmentReducer } from '../../reducers/supplementAdjustments'
import { SupplementBalanceReducer } from '../../reducers/supplementBalances'
import { TaxCardRequestReducer } from '../../reducers/taxCardRequests'
import { TaxCardReducer } from '../../reducers/taxCards'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../../reducers/timeRegistrationTemplates'
import { UserReducer } from '../../reducers/user'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { getDate, isSameOrAfter, isSameOrBefore, isTimeAfter } from '../../utils/date-utils'
import { getEmployeePayType } from '../../utils/employee-utils'
import {
  getAllParentsForCurrentPath,
  getMainMenuEmployeeMenu,
  MainMenuEmployeeID,
  MenuContext,
  setMainMenuEmployeeMenu,
} from '../../utils/menu-utils'
import { projectFilter } from '../../utils/projects-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { getCurrentPeriod, getCurrentPeriodFromDispositionDate } from '../../utils/salary-period-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Modal from '../elements/modal'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import AssetsTab from './assets/AssetsTab'
import BalanceAdjustmentsTab from './balance-adjustments/BalanceAdjustmentsTab'
import BonusTab from './bonus/BonusTab'
import CarAllowanceTab from './car-allowance/CarAllowanceTab'
import ContractHistoryTab from './contract-history/ContractHistoryTab'
import CreateFutureContract from './create-future-contract/CreateFutureContract'
import DeleteContract from './delete-contract/DeleteContract'
import DeleteEmployee from './delete-employee/DeleteEmployee'
import DocumentsTab from './documents/DocumentsTab'
import EmploymentTab from './employment/EmploymentTab'
import ExtraPensionTab from './extra-pension/ExtraPensionTab'
import FreeTextTab from './free-text/FreeTextTab'
import LeaveEmployee from './leave-employee/LeaveEmployee'
import LeaveTab from './leave/LeaveTab'
import PayCheckAdvanceTab from './pay-check-advance/PayCheckAdvanceTab'
import PaySlipsTab from './pay-slips/PaySlipsTab'
import ProfileTab from './profile/ProfileTab'
import RehireEmployee from './rehire-employee/RehireEmployee'
import ReimbursementTab from './reimbursement/ReimbursementTab'
import SalaryReductionTab from './salary-reduction/SalaryReductionTab'
import SalaryRegistrationTab from './salary-registrations/SalaryRegistrationTab'
import EmployeeSettings from './settings/EmployeeSettings'
import Sidebar from './sidebar/Sidebar'
import StartBalanceTab from './start-balance/StartBalanceTab'
import TaxCardsTab from './tax-cards/TaxCardsTab'
import TerminateEmployee from './terminate-employee/TerminateEmployee'
import TimeRegistrationTab from './time-registration/TimeRegistrationTab'
import TravelAllowanceTab from './travel-allowance/TravelAllowanceTab'
import WorkHoursRegistrationTab from './work-hours-registration/WorkHoursRegistrationTab'

import './EmployeesSingle.css'

type Props = {
  section?: string
  subsection?: string
  alerts: AlertReducer
  employee: Employee
  employees: EmployeeReducer
  employeeContractDeltas: EmployeeContractDeltaReducer
  hasFutureContractsFeature: boolean
  company: Company
  companyUsers: CompanyUserReducer
  user: UserReducer
  companyFeatures: List<CompanyFeature>
  settingsEnabled: CompanySetting[]
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  carAllowances: CarAllowanceReducer
  coarseCarAllowances: CoarseCarAllowanceReducer
  salaryRegistrations: SalaryRegistrationReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  leaveBalances: LeaveBalanceReducer
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: SupplementBalanceReducer
  paySlips: PaySlipReducer
  employmentPositions: List<EmploymentPosition>
  salaryCycles: List<SalaryCycle>
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  leaveAdjustments: LeaveAdjustmentReducer
  salaryTypes: List<SalaryType>
  startBalances: StartBalanceReducer
  banks: BankReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employments: EmploymentReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  taxCards: TaxCardReducer
  taxCardRequests: TaxCardRequestReducer
  oneTimePays: OneTimePayReducer
  oneTimePayPensions: OneTimePayPensionReducer
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  assets: AssetReducer
  assetCategories: AssetCategoryReducer
  accountingDimensions: AccountingDimensionReducer
  employeeDimensions: List<EmployeeDimension>
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  warnings: List<Warning>
  pricingPackages: List<PricingPackage>
  projects: List<Project>
  companyPricingPackages: CompanyPricingPackageReducer
  vacationCalendars: VacationCalendarReducer
  hasEmployeeEmergencyContactsFeature: boolean
  canSeeSalaryRates: boolean
  canEditObjects: boolean
  canApproveObjects: boolean
  canHireFire: boolean
  location: RoutePropsLocation

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  deleteEmployee: (employeeID: string, removeOrphans: boolean) => Promise<boolean | void>
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  setEmployeePDFPassword: (employeeID: string, password: string | null) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
  createCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistration[]
  ) => void
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    salaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  deleteCoarseSalaryRegistration: (registration: CoarseSalaryRegistration) => void
  createSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  approveSalaryRegistrations: (ids: string[]) => void
  unapproveSalaryRegistrations: (ids: string[]) => void
  updateSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  deleteSalaryRegistration: (id: string) => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => void
  getLeaveBalances: () => void
  getSupplementBalances: () => void
  getBanks: () => void
  updateEmployeeEmergencyContact: (
    employeeID: string,
    employeeEmergencyContact: EmployeeEmergencyContactMutableFields
  ) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  addEmployment: (employeeID: string, employment: Employment) => void
  updateEmployment: (id: string, employment: Employment) => void
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  rehireEmployee: (employeeID: string, startDate: DateFormat) => Promise<boolean | void>
  getStartBalances: (employeeID: string) => void
  updateStartBalances: (employmentID: string, startBalances: StartBalances<number>) => void
  addContract: (contract: ContractCreationFields) => Promise<Contract | void>
  updateContract: (
    contract: ContractMutableFields,
    setViewing?: boolean,
    removeOrphans?: boolean
  ) => Promise<Contract | void>
  deleteContract: (contractID: string, employeeID: string, removeOrphans: boolean) => void
  createLeave: (employeeID: string, leaveFrom: DateFormat, leaveTo: DateFormat) => Promise<Contract[] | void>
  removeLeave: (contractID: string) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  getDocuments: () => void
  addDocument: (companyID: string, document: DocumentCreationFields) => void
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
  deleteDocument: (id: string) => void
  getDocumentCategories: () => void
  updateTaxCard: (taxCard: TaxCardUpdate) => void
  getTaxCardRequests: (employeeID: string) => void
  addTaxCardRequest: (employeeID: string) => Promise<TaxCardRequest | null>
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, otp: OneTimePayMutableFields) => void
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  deleteOneTimePay: (id: string) => void
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  approveCarAllowances: (ids: string[]) => void
  unapproveCarAllowances: (ids: string[]) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (coarseCarAllowance: CoarseCarAllowance) => void
  sendInvite: (employeeID: string) => void
  deleteEmployeeUser: (employeeID: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  getContractDeltas: (employeeID: string) => void
  setEmployeeContract: (employeeID: string, contractID: string) => void
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  updateLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentMutableFields) => void
  deleteLeaveAdjustment: (leaveAdjustmentID: string) => void
  addSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentCreationFields) => void
  updateSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentMutableFields) => void
  deleteSupplementAdjustment: (supplementAdjustmentID: string) => void
  getOneTimePayPensions: (companyID?: string, employeeID?: string) => void
  addOneTimePayPension: (employeeID: string, otp: OneTimePayPensionCreationFields) => void
  updateOneTimePayPension: (employeeID: string, otp: OneTimePayPensionMutableFields) => void
  updateEmployeeDimensions: (employeeID: string, employeeDimensions: EmployeeDimensionMutableFields[]) => void
  addReimbursementVoucher: (voucher: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucher: (voucher: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (voucher: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  createAsset: (companyID: string, asset: AssetMutableFields) => void
  updateAsset: (companyID: string, asset: AssetMutableFields) => void
  deleteAsset: (assetID: string) => void
  returnAsset: (companyID: string, asset: Asset) => void
  receiveAsset: (companyID: string, asset: Asset) => void
  hideAsset: (companyID: string, asset: Asset) => void
  getAssetCategories: () => Promise<AssetCategory[] | void>
  getAssets: () => Promise<Asset[] | void>
  getCompanyAccountingIntegration: () => void
  getAccountingDimensions: () => void
  getCompanyPricingPackages: () => void
  deleteWarning: (id: string) => void
  markEmployeeReady: (employeeID: string) => Promise<Employee | void>
  unmarkEmployeeReady: (employeeID: string) => Promise<Employee | void>
  getTimeRegistrationTemplates: (employeeID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
  getVacationCalendarYear: (companyID: string, year: number) => void
}

export default function EmployeesSingle(props: Props): ReactElement | null {
  const [showLeave, setShowLeave] = useState(props.section === 'give-leave')
  const [showRehire, setShowRehire] = useState(props.section === 'rehire')
  const [showDelete, setShowDelete] = useState(props.section === 'delete')
  const [showTerminate, setShowTerminate] = useState(props.section === 'terminate')
  const [justDeleted, setJustDeleted] = useState(false)
  const [justTerminated, setJustTerminated] = useState(false)
  const [showCreateFutureContract, setShowCreateFutureContract] = useState(false)
  const [showDeleteContract, setShowDeleteContract] = useState(false)
  const [modalKey, setModalKey] = useState(1)
  const [hasFlex, setHasFlex] = useState(() => {
    const contract = props.employeeContractDeltas.viewingContract || props.employee.earliestMutableContract
    return !!(
      contract &&
      contract.remuneration &&
      contract.remuneration.leave &&
      contract.remuneration.leave.some((leave) => leave.type && leave.type.class === 'Flex')
    )
  })
  const [hasOvertime, setHasOvertime] = useState(() => {
    const contract = props.employeeContractDeltas.viewingContract || props.employee.earliestMutableContract
    return !!(
      contract &&
      contract.remuneration &&
      contract.remuneration.leave &&
      contract.remuneration.leave.some((leave) => leave.type && leave.type.class === 'Overtime')
    )
  })
  const [hasSupplementVaried, setHasSupplementVaried] = useState(false)
  const { menu, setMenu } = useContext(MenuContext)

  const contract =
    props.employeeContractDeltas.viewingContract ||
    props.employee.earliestMutableContract ||
    props.employee.activeContract

  const payType = getEmployeePayType(contract)

  const { employee, canSeeSalaryRates, employeeContractDeltas, getContractDeltas, salaryTypes, projects } = props

  let section = props.section

  useEffect(() => {
    switch (section) {
      case 'start-balances':
        if (employee.immutableEndDate) {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/employment')
        }
        break
      case 'time-registration':
        if (
          payType !== 'Hourly Paid' &&
          !contract?.remuneration?.salary.some((salary) =>
            salaryTypes.some((salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'Hourly')
          )
        ) {
          if (projects.some((p) => projectFilter(p, employee))) {
            jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/project-registration')
          } else {
            jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/leave')
          }
        }
        break
      case 'project-registration':
        if (payType === 'Hourly Paid') {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/time-registration')
        }
        if (!projects.some((p) => projectFilter(p, employee))) {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/leave')
        }
        break
      case 'work-hours-registration':
        if ((payType !== 'Salaried' && payType !== 'Commissioned') || employee.disableWorkHours) {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/employment')
        }
        break
      default:
        break
    }
  }, [employee, salaryTypes, projects, contract, payType, section])

  const viewingContract =
    props.employeeContractDeltas.viewingContract ||
    props.employee.earliestMutableContract ||
    props.employee.activeContract

  useEffect(() => {
    if (!employee) {
      jsBrowserHistory.push('/' + paths.EMPLOYEES)
      return
    }
    if (
      !employeeContractDeltas.loading &&
      (employeeContractDeltas.employeeID !== employee.id ||
        !employeeContractDeltas.loaded ||
        employeeContractDeltas.dirty)
    ) {
      getContractDeltas(employee.id)
    }
  }, [employee, employeeContractDeltas, canSeeSalaryRates, getContractDeltas])

  const { contracts, addAlert } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (!employee) {
      jsBrowserHistory.push('/' + paths.EMPLOYEES)
      return
    }
    // Check for save callback
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      // Check for no error occurred
      if (!contracts.error) {
        // Check for open terminate modal
        if (showLeave) {
          addAlert('success', t('employee_single.alert.leave.success', { name: employee.name }), {
            timeout: 5,
          })
          // Redirect to employment
          setShowLeave(false)
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/employment')
        }
      }
    }
  }, [employee, previousContracts, contracts, showLeave, addAlert])

  const { employments } = props
  const previousEmployments = usePrevious(employments)

  useEffect(() => {
    // Check for save callback
    if (previousEmployments && previousEmployments.saving && !employments.saving) {
      // Check for no error occurred
      if (!employments.error) {
        // Check for open rehire modal
        if (showRehire) {
          addAlert('success', t('employee_single.alert.rehire.success', { name: employee.name }), { timeout: 5 })
          // Redirect to employment
          setShowRehire(false)
          jsBrowserHistory.push(
            '/' +
              paths.EMPLOYEES +
              '/' +
              employee.id +
              '/' +
              (employee.earliestMutableContract ? 'employment' : 'contracts/add')
          )
        }
        if (justTerminated) {
          // Redirect to employees overview
          jsBrowserHistory.push('/' + paths.EMPLOYEES)
        }
      }
    }
  }, [previousEmployments, employments, employee, addAlert, justTerminated, showRehire])

  const { employees } = props
  const previousEmployees = usePrevious(employees)
  useEffect(() => {
    // Check for save callback
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      // Check for no error occurred
      if (!employees.error) {
        if (justDeleted) {
          // Redirect to employees overview
          jsBrowserHistory.push('/' + paths.EMPLOYEES)
        }
      }
    }
  }, [previousEmployees, employees, justDeleted])

  useEffect(() => {
    const nowHasFlex = !!contract?.remuneration?.leave?.some((leave) => leave.type && leave.type.class === 'Flex')
    const nowHasOvertime = !!contract?.remuneration?.leave?.some(
      (leave) => leave.type && leave.type.class === 'Overtime'
    )
    const nowHasSupplementVaried = !!contract?.remuneration?.salary?.some((salary) =>
      salaryTypes.some((type) => type.id === salary.salaryTypeID && type.class === 'SupplementVaried')
    )
    if (hasFlex !== nowHasFlex) {
      setHasFlex(nowHasFlex)
    }
    if (hasOvertime !== nowHasOvertime) {
      setHasOvertime(nowHasOvertime)
    }
    if (hasSupplementVaried !== nowHasSupplementVaried) {
      setHasSupplementVaried(nowHasSupplementVaried)
    }
  }, [contract, employeeContractDeltas, employee, hasFlex, hasOvertime, hasSupplementVaried, salaryTypes])

  const setDeleteVisibility = (visible: boolean, success?: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDelete(visible)
    if (success !== undefined) {
      setJustDeleted(success)
    }
  }

  const setTerminateVisibility = (visible: boolean, success?: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTerminate(visible)
    if (success !== undefined) {
      setJustTerminated(success)
    }
  }

  const setRehireVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowRehire(visible)
  }

  const setLeaveVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowLeave(visible)
  }

  const setCreateFutureContractVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowCreateFutureContract(visible)
  }

  const setDeleteContractVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDeleteContract(visible)
  }

  const salaryCycle = props.salaryCycles.find((cycle) => cycle.id === viewingContract?.salaryCycleID)

  const {
    coarseTimeRegistrations,
    timeRegistrations,
    coarseCarAllowances,
    carAllowances,
    coarseSalaryRegistrations,
    salaryRegistrations,
  } = props
  const hasTimeRegistrationsForPeriod = useCallback((): boolean => {
    if (!contract || !salaryCycle) {
      return false
    }
    if (contract.timeRegistrationMethodType === 'Coarse') {
      const period = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)
      return (
        !!period &&
        coarseTimeRegistrations.coarseTimeRegistrations.some(
          (registration) => registration.employeeID === employee.id && registration.salaryPeriodID === period.id
        )
      )
    }
    const period = getCurrentPeriod(salaryCycle)
    const periodStart = isTimeAfter(period.start, getDate(contract.validFrom))
      ? period.start
      : getDate(contract.validFrom)
    return timeRegistrations.timeRegistrations.some(
      (registration) =>
        registration.employeeID === employee.id &&
        registration.class === 'Hours' &&
        ((isSameOrAfter(getDate(registration.date), periodStart) &&
          isSameOrBefore(getDate(registration.date), getDate(period.end))) ||
          !registration.settled)
    )
  }, [contract, salaryCycle, coarseTimeRegistrations, timeRegistrations, employee])
  const hasCarAllowancesForPeriod = useCallback((): boolean => {
    if (!contract || !salaryCycle) {
      return false
    }
    const period = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)
    if (!period) {
      return false
    }
    const hasCoarse = coarseCarAllowances.coarseCarAllowances.some(
      (carAllowance) => carAllowance.employeeID === employee.id && carAllowance.salaryPeriodID === period.id
    )
    const hasDetailed = carAllowances.carAllowances.some(
      (carAllowance) =>
        carAllowance.employeeID === employee.id &&
        ((isSameOrAfter(getDate(carAllowance.date), period.start) &&
          isSameOrBefore(getDate(carAllowance.date), period.end)) ||
          !carAllowance.settled)
    )
    return hasCoarse || hasDetailed
  }, [contract, salaryCycle, coarseCarAllowances, carAllowances, employee])
  const hasSalaryRegistrationsForPeriod = useCallback((): boolean => {
    if (!contract || !salaryCycle) {
      return false
    }
    const period = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)
    if (!period) {
      return false
    }
    const hasCoarse = coarseSalaryRegistrations.coarseSalaryRegistrations.some(
      (registration) => registration.employeeID === employee.id && registration.salaryPeriodID === period.id
    )
    const hasDetailed = salaryRegistrations.salaryRegistrations.some(
      (registration) =>
        registration.employeeID === employee.id &&
        ((isSameOrAfter(getDate(registration.date), period.start) &&
          isSameOrBefore(getDate(registration.date), period.end)) ||
          registration.state === 'Pending')
    )
    return hasCoarse || hasDetailed
  }, [contract, salaryCycle, coarseSalaryRegistrations, salaryRegistrations, employee])

  const { canEditObjects, company } = props

  useEffect(() => {
    // set the employee menu whenever stuff happens
    const menuItems: MainMenuEmployeeID[] = []
    if (canSeeSalaryRates) {
      menuItems.push('employee-menu-employment')
      menuItems.push('employee-menu-profile')
      if (!employee.immutableEndDate) {
        menuItems.push('employee-menu-start-balances')
      }
    }
    menuItems.push('employee-menu-leave')
    const payType = getEmployeePayType(contract)
    if (
      contract &&
      (payType === 'Salaried' || payType === 'Commissioned') &&
      company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursFeature') &&
      !employee.disableWorkHours
    ) {
      menuItems.push('employee-menu-work-hours-registration')
    }
    if (
      payType === 'Hourly Paid' ||
      contract?.remuneration?.salary.some((salary) =>
        salaryTypes.some((salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'Hourly')
      )
    ) {
      menuItems.push('employee-menu-time-registration')
    }
    if (hasFlex || hasOvertime) {
      if (hasOvertime) {
        menuItems.push('employee-menu-time-box-registration-overtime')
      } else {
        menuItems.push('employee-menu-time-box-registration-flex')
      }
    }
    if (contract && payType !== 'Hourly Paid' && projects.some((p) => projectFilter(p, employee))) {
      menuItems.push('employee-menu-project-registration')
    }
    if (hasSupplementVaried) {
      menuItems.push('employee-menu-salary-registration')
    }
    menuItems.push('employee-menu-car-allowance')
    if (canSeeSalaryRates) {
      menuItems.push('employee-menu-contract-history')
      menuItems.push('employee-menu-pay-slips')
      menuItems.push('employee-menu-tax-cards')
    }
    if (canEditObjects) {
      menuItems.push('employee-menu-settings')
    }
    setMainMenuEmployeeMenu(setMenu, employee.id, menuItems)
  }, [
    setMenu,
    employee,
    canSeeSalaryRates,
    canEditObjects,
    company,
    contract,
    hasFlex,
    hasOvertime,
    hasSupplementVaried,
    projects,
    salaryTypes,
  ])

  const getWarnings = (): Warning[] => {
    return props.warnings
      .filter(
        (warning) =>
          warning.subjectID === employee.id &&
          (warning.warningType === 'StartBalancesNeedAdjustment' || warning.warningType === 'EmployeeIsForeignResident')
      )
      .toArray()
  }

  if (!employee) {
    return null
  }

  if (
    section === 'overview' ||
    section === 'give-leave' ||
    section === 'rehire' ||
    section === 'delete' ||
    section === 'terminate'
  ) {
    section = 'employment'
  }
  if (
    !props.canSeeSalaryRates &&
    (section === 'employment' ||
      section === 'profile' ||
      section === 'start-balances' ||
      section === 'contract-history' ||
      section === 'pay-slips' ||
      section === 'tax-cards' ||
      section === 'documents' ||
      section === 'balance-adjustments' ||
      section === 'extra-pension')
  ) {
    section = 'leave'
  }

  const costCenterAccounting = props.company.costCenterAccounting || 'Off'
  const activePath = getAllParentsForCurrentPath(props.location.pathname).map((i) => i.id)
  return (
    <div className={`employees-single with-submenu section-${section}`}>
      {!props.user.user?.uiSettings.sideMenu && (
        <div className="submenu">
          {getMainMenuEmployeeMenu(menu).map((item) => {
            if (item.type === 'item-employee-selector') {
              return null
            }
            return (
              <Link key={item.id} to={item.link} className={activePath.includes(item.id) ? 'active' : ''}>
                {t(item.labelID)}
              </Link>
            )
          })}
        </div>
      )}
      <div className="employees-single-main with-submenu-main">
        {getWarnings().map((warning) => {
          let onClose = undefined
          if (warning.canAcknowledge) {
            onClose = () => {
              props.deleteWarning(warning.id)
            }
          }
          switch (warning.warningType) {
            case 'StartBalancesNeedAdjustment':
              return (
                <Alert
                  key={warning.id}
                  message={t('employee_single.warning.start_balances_need_adjustment')}
                  type={'warning'}
                  showIcon
                  closable={!!onClose}
                  onClose={onClose}
                />
              )
            case 'EmployeeIsForeignResident':
              return (
                <Alert
                  key={warning.id}
                  message={t('employee_single.warning.employee_is_foreign_resident')}
                  type={'warning'}
                  showIcon
                  closable={!!onClose}
                  onClose={onClose}
                />
              )
            case 'InvalidBirthDate':
              return (
                <Alert
                  key={warning.id}
                  message={t('employee_single.warning.invalid_birth_date')}
                  type={'warning'}
                  showIcon
                  closable={!!onClose}
                  onClose={onClose}
                />
              )
            default:
              return null
          }
        })}
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        {employee.onboardingState === 'Draft' && (
          <Alert message={t('employee_single.warning.in_draft_state')} type={'warning'} showIcon />
        )}
        {section === 'employment' && (
          <EmploymentTab
            employee={employee}
            company={props.company}
            companyUsers={props.companyUsers.companyUsers}
            contracts={props.contracts}
            departments={props.departments}
            employees={props.employees}
            employments={props.employments}
            employmentPositions={props.employmentPositions}
            employeeContractDeltas={props.employeeContractDeltas}
            viewingContract={viewingContract}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            pensionCompanies={props.pensionCompanies}
            leaveTypes={props.leaveTypes}
            supplementTypes={props.supplementTypes}
            salaryTypes={props.salaryTypes}
            leaveBalances={props.leaveBalances}
            accountingDimensions={props.accountingDimensions}
            employeeDimensions={props.employeeDimensions}
            companyAccountingIntegration={props.companyAccountingIntegration}
            hasFutureContractsFeature={props.hasFutureContractsFeature}
            companyUser={props.companyUsers.companyUser}
            canEditObjects={props.canEditObjects}
            canHireFire={props.canHireFire}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            updateEmployee={props.updateEmployee}
            updateEmployment={props.updateEmployment}
            addContract={props.addContract}
            updateContract={props.updateContract}
            addDepartment={props.addDepartment}
            getLeaveBalances={props.getLeaveBalances}
            addLeaveAdjustment={props.addLeaveAdjustment}
            updateEmployeeDimensions={props.updateEmployeeDimensions}
            setEmployeeContract={props.setEmployeeContract}
            getCompanyAccountingIntegration={props.getCompanyAccountingIntegration}
            getAccountingDimensions={props.getAccountingDimensions}
            setRehireVisibility={setRehireVisibility}
            setDeleteVisibility={setDeleteVisibility}
            setTerminateVisibility={setTerminateVisibility}
            setCreateFutureContractVisibility={setCreateFutureContractVisibility}
            setDeleteContractVisibility={setDeleteContractVisibility}
            markEmployeeReady={props.markEmployeeReady}
            unmarkEmployeeReady={props.unmarkEmployeeReady}
          />
        )}
        {section === 'start-balances' && (
          <StartBalanceTab
            employee={props.employee}
            startBalances={props.startBalances}
            leaveTypes={props.leaveTypes}
            salaryCycle={salaryCycle}
            canEditObjects={props.canEditObjects}
            addAlert={props.addAlert}
            getStartBalances={props.getStartBalances}
            updateStartBalances={props.updateStartBalances}
          />
        )}
        {(section === 'time-registration' ||
          section === 'time-box-registration' ||
          section === 'project-registration') && (
          <TimeRegistrationTab
            key={section}
            forFreelancer={false}
            mode={
              section === 'project-registration'
                ? 'Project'
                : section === 'time-box-registration'
                ? hasFlex
                  ? 'FlexTime'
                  : 'Overtime'
                : 'Hours'
            }
            subsection={props.subsection}
            hasTimeRegistrationsForPeriod={hasTimeRegistrationsForPeriod()}
            company={props.company}
            employee={props.employee}
            contracts={props.contracts}
            timeRegistrations={props.timeRegistrations}
            coarseTimeRegistrations={props.coarseTimeRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            salaryTypes={props.salaryTypes}
            leaveTypes={props.leaveTypes}
            projects={props.projects}
            costCenterAccounting={costCenterAccounting}
            costCenters={props.costCenters.costCenters}
            departments={props.departments.departments}
            canEditObjects={props.canEditObjects}
            canHireFire={props.canHireFire}
            canApproveObjects={props.canApproveObjects}
            addAlert={props.addAlert}
            addContract={props.addContract}
            updateContract={props.updateContract}
            createTimeRegistration={props.createTimeRegistration}
            approveTimeRegistrations={props.approveTimeRegistrations}
            unapproveTimeRegistrations={props.unapproveTimeRegistrations}
            updateTimeRegistration={props.updateTimeRegistration}
            deleteTimeRegistration={props.deleteTimeRegistration}
            deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
            updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
          />
        )}
        {section === 'work-hours-registration' && (
          <WorkHoursRegistrationTab
            key={section}
            alerts={props.alerts}
            company={props.company}
            companyFeatures={props.companyFeatures}
            employee={props.employee}
            employeeContractDeltas={props.employeeContractDeltas}
            timeRegistrations={props.timeRegistrations}
            timeRegistrationTemplates={props.timeRegistrationTemplates}
            costCenters={props.costCenters.costCenters}
            departments={props.departments.departments}
            projects={props.projects}
            leaveTypes={props.leaveTypes}
            canEditObjects={props.canEditObjects}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            createTimeRegistration={props.createTimeRegistration}
            updateTimeRegistration={props.updateTimeRegistration}
            deleteTimeRegistration={props.deleteTimeRegistration}
            getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
            saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
            deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
          />
        )}
        {section === 'salary-registration' && (
          <SalaryRegistrationTab
            company={props.company}
            employee={props.employee}
            costCenters={props.costCenters.costCenters}
            departments={props.departments.departments}
            contracts={props.contracts}
            coarseSalaryRegistrations={props.coarseSalaryRegistrations}
            salaryRegistrations={props.salaryRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            salaryTypes={props.salaryTypes}
            subsection={props.subsection}
            hasSalaryRegistrationsForPeriod={hasSalaryRegistrationsForPeriod()}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            canHireFire={props.canHireFire}
            addAlert={props.addAlert}
            updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
            updateContract={props.updateContract}
            addContract={props.addContract}
            createSalaryRegistration={props.createSalaryRegistration}
            approveSalaryRegistrations={props.approveSalaryRegistrations}
            unapproveSalaryRegistrations={props.unapproveSalaryRegistrations}
            updateSalaryRegistration={props.updateSalaryRegistration}
            deleteSalaryRegistration={props.deleteSalaryRegistration}
            deleteSalaryRegistrationBulk={props.deleteSalaryRegistrationBulk}
          />
        )}
        {section === 'leave' && (
          <LeaveTab
            company={props.company}
            employee={props.employee}
            timeRegistrations={props.timeRegistrations}
            leaveBalances={props.leaveBalances}
            leaveTypes={props.leaveTypes}
            vacationCalendars={props.vacationCalendars}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            addAlert={props.addAlert}
            createTimeRegistrationBulk={props.createTimeRegistrationBulk}
            createTimeRegistration={props.createTimeRegistration}
            approveTimeRegistrations={props.approveTimeRegistrations}
            unapproveTimeRegistrations={props.unapproveTimeRegistrations}
            updateTimeRegistration={props.updateTimeRegistration}
            deleteTimeRegistration={props.deleteTimeRegistration}
            deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
            getLeaveBalances={props.getLeaveBalances}
            getVacationCalendarYear={props.getVacationCalendarYear}
          />
        )}
        {section === 'car-allowance' && (
          <CarAllowanceTab
            subsection={props.subsection}
            hasCarAllowancesForPeriod={hasCarAllowancesForPeriod()}
            company={props.company}
            employee={props.employee}
            costCenters={props.costCenters.costCenters}
            departments={props.departments.departments}
            contracts={props.contracts}
            carAllowances={props.carAllowances}
            coarseCarAllowances={props.coarseCarAllowances}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            canEditObjects={props.canEditObjects}
            canHireFire={props.canHireFire}
            canApproveObjects={props.canApproveObjects}
            addAlert={props.addAlert}
            addContract={props.addContract}
            updateContract={props.updateContract}
            createCarAllowances={props.createCarAllowances}
            approveCarAllowances={props.approveCarAllowances}
            unapproveCarAllowances={props.unapproveCarAllowances}
            updateCarAllowance={props.updateCarAllowance}
            deleteCarAllowance={props.deleteCarAllowance}
            createCoarseCarAllowance={props.createCoarseCarAllowance}
            updateCoarseCarAllowance={props.updateCoarseCarAllowance}
            deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
          />
        )}
        {section === 'assets' && (
          <AssetsTab
            employee={props.employee}
            company={props.company}
            canEditObjects={props.canEditObjects}
            createAsset={props.createAsset}
            updateAsset={props.updateAsset}
            deleteAsset={props.deleteAsset}
            receiveAsset={props.receiveAsset}
            hideAsset={props.hideAsset}
            getAssetCategories={props.getAssetCategories}
            getAssets={props.getAssets}
            assetCategories={props.assetCategories}
            assets={props.assets}
            canApproveObjects={props.canApproveObjects}
          />
        )}
        {section === 'bonus' && (
          <BonusTab
            subsection={props.subsection}
            employee={props.employee}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            company={props.company}
            oneTimePays={props.oneTimePays}
            leaveBalances={props.leaveBalances}
            leaveTypes={props.leaveTypes}
            salaryCycle={salaryCycle}
            supplementBalances={props.supplementBalances}
            supplementTypes={props.supplementTypes}
            costCenters={props.costCenters.costCenters}
            costCenterAccounting={costCenterAccounting}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
            getSupplementBalances={props.getSupplementBalances}
            getLeaveBalances={props.getLeaveBalances}
            user={props.user}
          />
        )}
        {section === 'reimbursement' && (
          <ReimbursementTab
            subsection={props.subsection}
            employee={props.employee}
            employees={props.employees.employees}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            isFreelancer={false}
            company={props.company}
            companyUser={props.companyUsers.companyUser}
            companyUsers={props.companyUsers.companyUsers}
            companyFeatures={props.companyFeatures}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            expenseCategories={props.expenseCategories}
            reimbursementVouchers={props.reimbursementVouchers}
            costCenters={props.costCenters.costCenters}
            costCenterAccounting={costCenterAccounting}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
            addReimbursementVoucher={props.addReimbursementVoucher}
            updateReimbursementVoucher={props.updateReimbursementVoucher}
            updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
            approveReimbursementVouchers={props.approveReimbursementVouchers}
            unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
            draftReimbursementVouchers={props.draftReimbursementVouchers}
          />
        )}
        {section === 'travel-allowance' && (
          <TravelAllowanceTab
            subsection={props.subsection}
            employee={props.employee}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters.costCenters}
            costCenterAccounting={costCenterAccounting}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'pay-check-advance' && (
          <PayCheckAdvanceTab
            subsection={props.subsection}
            employee={props.employee}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            isFreelancer={false}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters.costCenters}
            costCenterAccounting={costCenterAccounting}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'salary-reduction' && (
          <SalaryReductionTab
            subsection={props.subsection}
            employee={props.employee}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            isFreelancer={false}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters.costCenters}
            costCenterAccounting={costCenterAccounting}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'free-text' && (
          <FreeTextTab
            subsection={props.subsection}
            company={props.company}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            employee={props.employee}
            isFreelancer={false}
            eIncomeText={false}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'e-income-text' && (
          <FreeTextTab
            subsection={props.subsection}
            company={props.company}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            employee={props.employee}
            isFreelancer={false}
            eIncomeText={true}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'balance-adjustments' && (
          <BalanceAdjustmentsTab
            employee={props.employee}
            leaveAdjustments={props.leaveAdjustments}
            leaveBalances={props.leaveBalances}
            supplementAdjustments={props.supplementAdjustments}
            supplementBalances={props.supplementBalances}
            salaryCycle={salaryCycle}
            leaveTypes={props.leaveTypes}
            supplementTypes={props.supplementTypes}
            salaryTypes={props.salaryTypes}
            viewingContract={viewingContract}
            addAlert={props.addAlert}
            addLeaveAdjustment={props.addLeaveAdjustment}
            updateLeaveAdjustment={props.updateLeaveAdjustment}
            deleteLeaveAdjustment={props.deleteLeaveAdjustment}
            addSupplementAdjustment={props.addSupplementAdjustment}
            updateSupplementAdjustment={props.updateSupplementAdjustment}
            deleteSupplementAdjustment={props.deleteSupplementAdjustment}
            getLeaveBalances={props.getLeaveBalances}
            getSupplementBalances={props.getSupplementBalances}
          />
        )}
        {section === 'extra-pension' && (
          <ExtraPensionTab
            subsection={props.subsection}
            company={props.company}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            employee={props.employee}
            oneTimePayPensions={props.oneTimePayPensions}
            pensionCompanies={props.pensionCompanies}
            salaryCycle={salaryCycle}
            viewingContract={viewingContract}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            getOneTimePayPensions={props.getOneTimePayPensions}
            addOneTimePayPension={props.addOneTimePayPension}
            updateOneTimePayPension={props.updateOneTimePayPension}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'pay-slips' && (
          <PaySlipsTab
            company={props.company}
            employee={props.employee}
            employees={props.employees}
            paySlips={props.paySlips.paySlips}
            pricingPackages={props.pricingPackages}
            companyPricingPackages={props.companyPricingPackages}
            addAlert={props.addAlert}
            updateEmployee={props.updateEmployee}
            getCompanyPricingPackages={props.getCompanyPricingPackages}
            setEmployeePDFPassword={props.setEmployeePDFPassword}
          />
        )}
        {section === 'tax-cards' && (
          <TaxCardsTab
            employee={props.employee}
            contracts={props.contracts}
            employments={props.employments}
            taxCards={props.taxCards}
            taxCardRequests={props.taxCardRequests}
            canEditObjects={props.canEditObjects}
            canHireFire={props.canHireFire}
            addAlert={props.addAlert}
            updateEmployment={props.updateEmployment}
            updateTaxCard={props.updateTaxCard}
            getTaxCardRequests={props.getTaxCardRequests}
            addTaxCardRequest={props.addTaxCardRequest}
            addContract={props.addContract}
            updateContract={props.updateContract}
          />
        )}
        {section === 'profile' && (
          <ProfileTab
            user={props.user}
            employee={props.employee}
            company={props.company}
            companyFeatures={props.companyFeatures}
            employees={props.employees}
            employeeEmergencyContacts={props.employeeEmergencyContacts}
            employments={props.employments}
            banks={props.banks}
            canEditObjects={props.canEditObjects}
            addAlert={props.addAlert}
            updateEmployee={props.updateEmployee}
            updateEmployeeEmergencyContact={props.updateEmployeeEmergencyContact}
            deleteEmployeeEmergencyContact={props.deleteEmployeeEmergencyContact}
            updateEmployment={props.updateEmployment}
            getBanks={props.getBanks}
          />
        )}
        {section === 'documents' && (
          <DocumentsTab
            company={props.company}
            companyUsers={props.companyUsers.companyUsers}
            employee={props.employee}
            companyFeatures={props.companyFeatures}
            documents={props.documents}
            documentCategories={props.documentCategories}
            addAlert={props.addAlert}
            getDocuments={props.getDocuments}
            addDocument={props.addDocument}
            updateDocument={props.updateDocument}
            deleteDocument={props.deleteDocument}
            getDocumentCategories={props.getDocumentCategories}
          />
        )}
        {section === 'contract-history' && (
          <ContractHistoryTab
            employee={props.employee}
            employeeContractDeltas={props.employeeContractDeltas}
            salaryCycles={props.salaryCycles}
            salaryTypes={props.salaryTypes}
            pensionCompanies={props.pensionCompanies}
            companyUsers={props.companyUsers.companyUsers}
            hasFutureContractsFeature={props.hasFutureContractsFeature}
            canEditObjects={props.canEditObjects}
            addAlert={props.addAlert}
            setCreateFutureContractVisibility={setCreateFutureContractVisibility}
            setEmployeeContract={props.setEmployeeContract}
            setLeaveVisibility={setLeaveVisibility}
          />
        )}
        {section === 'settings' && (
          <EmployeeSettings
            employee={props.employee}
            company={props.company}
            updateEmployee={props.updateEmployee}
            employees={props.employees}
            addAlert={props.addAlert}
          />
        )}
      </div>
      <div className="employees-single-side">
        <Sidebar
          section={props.section}
          subsection={props.subsection}
          salaryCycle={salaryCycle}
          employee={props.employee}
          employeeEmergencyContact={props.employeeEmergencyContacts.employeeEmergencyContact}
          employeeContractDeltas={props.employeeContractDeltas}
          viewingContract={viewingContract}
          companyFeatures={props.companyFeatures}
          settingsEnabled={props.settingsEnabled}
          employees={props.employees.employees}
          paySlips={props.paySlips.paySlips}
          departments={props.departments.departments}
          employeeInvites={props.employeeInvites}
          employeeProfileImages={props.employeeProfileImages}
          canEditObjects={props.canEditObjects}
          canSeeSalaryRates={props.canSeeSalaryRates}
          hasFutureContractsFeature={props.hasFutureContractsFeature}
          hasEmployeeEmergencyContactsFeature={props.hasEmployeeEmergencyContactsFeature}
          addAlert={props.addAlert}
          sendInvite={props.sendInvite}
          deleteEmployeeUser={props.deleteEmployeeUser}
          updateProfileImage={props.updateProfileImage}
          setLeaveVisibility={setLeaveVisibility}
          setEmployeeContract={props.setEmployeeContract}
        />
      </div>

      {viewingContract && salaryCycle && (
        <Modal
          key={`leave-${modalKey}`}
          visible={showLeave}
          onOk={() => setLeaveVisibility(false)}
          onCancel={() => setLeaveVisibility(false)}
          width={376}
          footer={null}
        >
          <LeaveEmployee
            visible={showLeave}
            employee={props.employee}
            contracts={props.contracts}
            viewingContract={viewingContract}
            employeeContractDeltas={props.employeeContractDeltas}
            salaryCycle={salaryCycle}
            createLeave={props.createLeave}
            removeLeave={props.removeLeave}
            setEmployeeContract={props.setEmployeeContract}
          />
        </Modal>
      )}

      <Modal
        key={`rehire-${modalKey}`}
        visible={showRehire}
        onOk={() => setRehireVisibility(false)}
        onCancel={() => setRehireVisibility(false)}
        width={376}
        footer={null}
      >
        <RehireEmployee
          visible={showRehire}
          employee={props.employee}
          employments={props.employments}
          rehireEmployee={props.rehireEmployee}
          addAlert={props.addAlert}
          closeModal={() => setRehireVisibility(false)}
        />
      </Modal>

      <Modal
        key={`delete-${modalKey}`}
        visible={showDelete}
        onOk={() => setDeleteVisibility(false)}
        onCancel={() => setDeleteVisibility(false)}
        width={572}
        footer={null}
      >
        <DeleteEmployee
          visible={showDelete}
          employee={props.employee}
          employees={props.employees}
          deleteEmployee={props.deleteEmployee}
          addAlert={props.addAlert}
          closeModal={() => setDeleteVisibility(false, true)}
        />
      </Modal>

      <Modal
        key={`terminate-${modalKey}`}
        visible={showTerminate}
        onOk={() => setTerminateVisibility(false)}
        onCancel={() => setTerminateVisibility(false)}
        width={572}
        footer={null}
      >
        <TerminateEmployee
          visible={showTerminate}
          employee={props.employee}
          employees={props.employees}
          employments={props.employments}
          salaryCycles={props.salaryCycles}
          terminateEmployment={props.terminateEmployment}
          addAlert={props.addAlert}
          closeModal={() => setTerminateVisibility(false, true)}
        />
      </Modal>

      {viewingContract && salaryCycle && (
        <Modal
          key={`create-future-${modalKey}`}
          visible={showCreateFutureContract}
          onOk={() => setCreateFutureContractVisibility(false)}
          onCancel={() => setCreateFutureContractVisibility(false)}
          width={582}
          footer={null}
        >
          <CreateFutureContract
            visible={showCreateFutureContract}
            earliestMutableContract={props.employee.earliestMutableContract}
            viewingContract={viewingContract}
            employee={props.employee}
            employeeContractDeltas={props.employeeContractDeltas}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            contracts={props.contracts}
            leaveTypes={props.leaveTypes}
            supplementTypes={props.supplementTypes}
            addContract={props.addContract}
            closeModal={() => setCreateFutureContractVisibility(false)}
          />
        </Modal>
      )}

      {viewingContract && (
        <Modal
          key={`delete-future-${modalKey}`}
          visible={showDeleteContract}
          onOk={() => setDeleteContractVisibility(false)}
          onCancel={() => setDeleteContractVisibility(false)}
          width={582}
          footer={null}
        >
          <DeleteContract
            visible={showDeleteContract}
            viewingContract={viewingContract}
            employee={props.employee}
            employeeContractDeltas={props.employeeContractDeltas}
            contracts={props.contracts}
            deleteContract={props.deleteContract}
            setEmployeeContract={props.setEmployeeContract}
            closeModal={() => setDeleteContractVisibility(false)}
          />
        </Modal>
      )}
    </div>
  )
}
