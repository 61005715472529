import omit from 'omit.js'
import React from 'react'

import classNames from '../../antd/_util/classNames'

import '../../antd/style/index.css'

type Props = {
  type: string
  spin?: boolean
  className?: string
  title?: string
  onClick?: (e: React.MouseEvent) => void
}

// deprecated avoid usage
export default function BaseIcon(props: Props) {
  // NEED_NO_TRANSLATION
  const { type, className = '', spin } = props
  const classString = classNames(
    {
      anticon: true,
      'anticon-spin': !!spin || type === 'loading',
      [`anticon-${type}`]: true,
    },
    className
  )
  return <i {...omit(props, ['type', 'spin'])} className={classString} onClick={props.onClick} />
}
