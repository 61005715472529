import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import { decorateFieldSignature, getFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'

type RegistrationCostCenterFields = {
  costCenterID?: string
}

type Props<Fields extends RegistrationCostCenterFields> = {
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>

  getFieldValue: getFieldValueSignature<Fields>
  decorateField: decorateFieldSignature<Fields>
}

export default function RegistrationCostCenter<Fields extends RegistrationCostCenterFields>(
  props: Props<Fields>
): ReactElement | null {
  type anyCenter = {
    id: string
    name: string
  }

  const getCostCenters = (departmentAccounting: boolean): anyCenter[] => {
    if (!departmentAccounting) {
      return props.costCenters
        .filter((costCenter) => costCenter.active || costCenter.id === props.getFieldValue('costCenterID'))
        .toArray()
    }
    return props.departments
      .filter(
        (department) =>
          !!department.costCenterID &&
          (department.active || department.costCenterID === props.getFieldValue('costCenterID'))
      )
      .map((department) => ({
        id: department.costCenterID || '',
        name: department.name,
      }))
      .toArray()
  }

  const hasCostCenters =
    props.costCenters.size > 0 &&
    props.company.settingsEnabled.some((setting) => setting === 'DepartmentSelectTimeRegistration')
  const costCenterAccounting = hasCostCenters && props.company.costCenterAccounting === 'CostCenters'
  const departmentAccounting = hasCostCenters && props.company.costCenterAccounting === 'Departments'

  if (!costCenterAccounting && !departmentAccounting) {
    return null
  }

  return (
    <Row>
      <Col span={24}>
        {props.decorateField('costCenterID', {
          placeholder: departmentAccounting
            ? t('registration_form.cost_center.cost_center_id.department')
            : t('registration_form.cost_center.cost_center_id.cost_center'),
        })(
          <Select
            dropdownMatchSelectWidth={false}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              const item = option.props.children
              return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
          >
            {getCostCenters(departmentAccounting).map((costCenter) => {
              return (
                <Select.Option key={costCenter.id} value={costCenter.id}>
                  {costCenter.name}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Col>
    </Row>
  )
}
