import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { CarAllowanceRate, fetchCarAllowanceRates } from '../../../api/car-allowances'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import { DateFormat } from '../../../model/types'
import { CarAllowanceReducer } from '../../../reducers/carAllowances'
import { findRelevantCarAllowance } from '../../../utils/car-allowance-rates-utils'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatInputNumber,
  formatNumber,
  parseInputNumber,
} from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import AutoComplete from '../../elements/auto-complete'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/icon'
import Input from '../../elements/input'
import Radio from '../../elements/radio'
import Tooltip from '../../elements/tooltip'
import RegistrationCostCenter from '../../form-elements/RegistrationCostCenter'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CarAllowanceMap from './CarAllowanceMap'

type Props = {
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  newCarAllowance: boolean
  carAllowanceID?: string
  carAllowances: CarAllowanceReducer
}

type Fields = {
  date: Date
  createReturnTrip: boolean
  avoidFerries: boolean
  licensePlate?: string
  locationFrom?: string
  locationTo?: string
  viaPoints?: {
    location?: string
    kilometers?: number
  }[]
  returnViaPoints?: {
    kilometers?: number
  }[]
  kilometers?: string
  returnKilometers?: number
  reason?: string
  costCenterID?: string
  rate?: string
}

type CarAllowanceResultTrip = {
  locationFrom: string
  locationTo: string
  kilometers: number
}

export type CarAllowanceResult = {
  date: DateFormat
  avoidFerries: boolean
  licensePlate: string
  reason: string
  costCenterID?: string
  rate?: number
  trips: CarAllowanceResultTrip[]
}

function DetailedCarAllowanceEditForm(
  props: Props & FormComponentProps<Fields, CarAllowanceResult>
): ReactElement | null {
  type State = {
    from: string
    to: string
    waypoints: string[]
    noAutoUpdate: boolean
  }
  const [state, setState] = useState<State>(() => {
    const state: State = { from: '', to: '', waypoints: [], noAutoUpdate: false }
    if (props.carAllowanceID) {
      state.noAutoUpdate = true
      const carAllowance = props.carAllowances.carAllowances.find(
        (carAllowance) => carAllowance.id === props.carAllowanceID
      )
      if (carAllowance) {
        state.from = carAllowance.locationFrom
        state.to = carAllowance.locationTo
      }
    }
    return state
  })
  const [typingMode, setTypingMode] = useState(false)
  const [existingLocations] = useState(() => {
    type Location = {
      name: string
      count: number
    }
    const locations: Record<string, Location> = {}
    const setLocation = (newLocation: string) => {
      newLocation = newLocation.replace(/ +/g, ' ')
      if (!locations[newLocation.toLowerCase()]) {
        locations[newLocation.toLowerCase()] = { name: newLocation, count: 1 }
      } else {
        locations[newLocation.toLowerCase()].count = locations[newLocation.toLowerCase()].count + 1
      }
    }
    props.carAllowances.carAllowances
      .filter((carAllowance) => carAllowance.employeeID === props.employee.id)
      .forEach((carAllowance) => {
        setLocation(carAllowance.locationFrom)
        setLocation(carAllowance.locationTo)
      })

    return Object.values(locations)
      .sort((a, b) => {
        if (a.count === b.count) {
          return a.name.localeCompare(b.name)
        }
        return b.count - a.count
      })
      .filter((_, i) => i < 10) // limit to first 10
      .map((location) => location.name)
  })
  type LocationType = 'from' | 'to' | `via-${number}`
  type SearchState = Record<LocationType, string>
  const [searchState, setSearchState] = useState<SearchState>({ from: '', to: '' })
  const [locationResults, setLocationResults] = useState<Record<LocationType, string[]>>({
    from: [],
    to: [],
  })
  const [service, setService] = useState<google.maps.places.AutocompleteService>()

  const locationFrom = props.getFieldValue('locationFrom')
  const locationTo = props.getFieldValue('locationTo')
  const viaPoints = props.getFieldValue('viaPoints')

  useEffect(() => {
    const distanceTimeout = setTimeout(() => {
      if (!locationFrom || !locationTo) {
        return
      }
      setState((prev) => ({
        ...prev,
        from: locationFrom,
        to: locationTo,
        waypoints: viaPoints?.filter((v) => !!v.location).map((v) => v.location!) ?? [],
      }))
    }, 500)
    return () => clearTimeout(distanceTimeout)
  }, [locationFrom, locationTo, viaPoints])

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      if (!typingMode) {
        return
      }
      setSearchState(() => {
        const o =
          viaPoints?.reduce((o: Record<`via-${number}`, string>, p, i) => {
            o[`via-${i}`] = p.location ?? ''
            return o
          }, {}) ?? {}
        return { from: locationFrom ?? '', to: locationTo ?? '', ...o }
      })
    }, 500)
    return () => clearTimeout(searchTimeout)
  }, [typingMode, locationFrom, locationTo, viaPoints])

  const handleChange = useCallback(
    (lType: LocationType, search: string) => {
      // Ensure Maps have been loaded
      if (!window.google) {
        return
      }

      if (search.length <= 3) {
        setLocationResults((prev) => ({ ...prev, [lType]: [] }))
        return
      }

      let localService = service
      if (!localService) {
        localService = new window.google.maps.places.AutocompleteService()
        setService(localService)
      }

      const request = {
        input: search,
        fields: ['name'],
        language: 'da',
        locationRestriction: {
          south: 54.56,
          west: 8.07,
          north: 57.74,
          east: 15.16,
        },
        region: 'dk',
      }

      localService!.getPlacePredictions(
        request,
        (
          results: google.maps.places.AutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            const locations = results.reduce((list: string[], place) => {
              if (place.description) {
                list.push(place.description)
              }
              return list
            }, [])
            setLocationResults((prev) => ({ ...prev, [lType]: locations }))
          }
        }
      )
    },
    [service]
  )

  const addViaPoint = () => {
    props.setFieldValue('viaPoints', [...(viaPoints ?? []), {}])
    props.setFieldValue('returnViaPoints', [...(props.getFieldValue('returnViaPoints') ?? []), {}])
  }

  const removeViaPoint = (i: number) => {
    const viaPoints = props.getFieldValue('viaPoints') ?? []
    const returnViaPoints = props.getFieldValue('returnViaPoints') ?? []
    if (viaPoints.length > 0) {
      // splice() only does it in place, so we cannot use its return value
      viaPoints.splice(i, 1)
      props.setFieldValue('viaPoints', viaPoints)
    }
    if (returnViaPoints.length > 0) {
      returnViaPoints.splice(returnViaPoints.length - 1 - i, 1)
      props.setFieldValue('returnViaPoints', returnViaPoints)
    }
  }

  const previousSearchState = usePrevious(searchState)
  useEffect(() => {
    if (previousSearchState) {
      const list: LocationType[] = ['from', 'to', ...(viaPoints?.map((_, i): LocationType => `via-${i}`) ?? [])]
      list.forEach((k) => {
        if (previousSearchState[k] !== searchState[k]) {
          handleChange(k, searchState[k])
        }
      })
    }
  }, [previousSearchState, searchState, handleChange, viaPoints])

  const [rates, setRates] = useState<CarAllowanceRate[]>()
  const [loading, setLoading] = useState(true)

  useEffectOnce(() => {
    fetchCarAllowanceRates()
      .then((res) => {
        if (!res) {
          return
        }
        setRates(res.data)
      })
      .finally(() => setLoading(false))
  })

  const updateDistance = (leg: number, meters: number, avoidFerries: boolean) => {
    if (state.noAutoUpdate && avoidFerries === props.getFieldValue('avoidFerries')) {
      // if avoidFerries changes, it overrides noAutoUpdate
      return
    }
    const { setFieldValue } = props
    if (leg === 0) {
      setFieldValue('kilometers', formatInputNumber(meters / 1000, 2))
      setFieldValue('avoidFerries', avoidFerries)
    } else {
      props.setAnyFieldValue(`viaPoints.${leg - 1}.kilometers`, meters / 1000)
    }
  }
  const updateReturnDistance = (leg: number, meters: number) => {
    if (state.noAutoUpdate) {
      return
    }
    const { setFieldValue } = props
    if (leg === 0) {
      setFieldValue('returnKilometers', meters / 1000)
    } else {
      props.setAnyFieldValue(`returnViaPoints.${leg - 1}.kilometers`, meters / 1000)
    }
  }

  const getLocations = (lType: LocationType): string[] => {
    return [...(locationResults[lType] ?? []), ...existingLocations].filter(
      (value, index, array) => array.indexOf(value) === index
    )
  }

  if (loading) {
    return <LoadingOverlay />
  }

  const { decorateField, getFieldValue } = props
  const carAllowanceRateLimit = findRelevantCarAllowance(rates, getFieldValue('date'))
  const isNew = !props.carAllowanceID || props.newCarAllowance
  const usingViaPoints = !!viaPoints && viaPoints.length > 0

  const locationField = (lType: LocationType, id: keyof Fields | `viaPoints.${number}.location`, msgID: string) => {
    return props.decorateAnyField(id, {
      placeholder: t(msgID),
      validate: (val) => (!val ? t(msgID + '.required') : null),
    })(
      <AutoComplete
        value={state.from}
        dataSource={getLocations(lType)}
        filterOption={(inputValue: string, option: ReactElement) => {
          return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }}
        className="autocomplete-field"
        onChange={() => setState((prev) => ({ ...prev, noAutoUpdate: false }))}
        onFocus={() => setTypingMode(true)}
        onBlur={() => setTypingMode(false)}
        inputID="location-from-input"
      />
    )
  }

  return (
    <Row>
      <Col span={12}>
        {props.getFormError()}
        <Row>
          <Col span={24}>
            {decorateField('date', {
              placeholder: t('car_allowances_tab.detailed.edit.form.date'),
              validate: (val) => {
                if (!val) {
                  return t('car_allowances_tab.detailed.edit.form.date.required')
                }
                return null
              },
            })(<DatePicker allowClear={false} tabIndex={2} style={{ width: '100%' }} />)}
          </Col>
        </Row>
        {!usingViaPoints && (
          <>
            <Row>
              <Col span={12}>
                {locationField('from', 'locationFrom', 'car_allowances_tab.detailed.edit.form.location_from')}
              </Col>
              <Col span={12}>
                {locationField('to', 'locationTo', 'car_allowances_tab.detailed.edit.form.location_to')}
              </Col>
            </Row>
            {isNew && (
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button onClick={addViaPoint}>{t('car_allowances_tab.detailed.edit.form.add_via_point')}</Button>
                </Col>
              </Row>
            )}
          </>
        )}
        {usingViaPoints && (
          <>
            <Row>
              <Col span={12}>
                {locationField('from', 'locationFrom', 'car_allowances_tab.detailed.edit.form.location_from')}
              </Col>
              <Col span={12} className="via-point-cell">
                <p>
                  {t('car_allowances_tab.detailed.edit.form.via.distance_to_next')}
                  <br />
                  {t('car_allowances_tab.detailed.edit.form.kilometer_format', {
                    kilometers: props.getFieldValue('kilometers') ?? formatNumber(0, 2),
                  })}
                </p>
              </Col>
            </Row>
            {viaPoints &&
              viaPoints.map((p, i) => (
                <Row key={`via-${i}`}>
                  <Col span={12}>
                    {locationField(
                      `via-${i}`,
                      `viaPoints.${i}.location`,
                      'car_allowances_tab.detailed.edit.form.location_via'
                    )}
                  </Col>
                  <Col span={8} className="via-point-cell">
                    <p>
                      {t('car_allowances_tab.detailed.edit.form.via.distance_to_next')}
                      <br />
                      {t('car_allowances_tab.detailed.edit.form.kilometer_format', {
                        kilometers: formatNumber(p.kilometers, 2),
                      })}
                    </p>
                  </Col>
                  <Col span={4} className="via-point-cell">
                    <Tooltip title={t('car_allowances_tab.detailed.edit.form.remove_via_point')}>
                      <span
                        onClick={(e) => {
                          e.preventDefault()
                          removeViaPoint(i)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Icon type="xSign" />
                      </span>
                    </Tooltip>
                  </Col>
                </Row>
              ))}
            <Row>
              <Col span={12}>
                {locationField('to', 'locationTo', 'car_allowances_tab.detailed.edit.form.location_to')}
              </Col>
              <Col span={12} className="via-point-cell">
                <Button onClick={addViaPoint}>{t('car_allowances_tab.detailed.edit.form.add_via_point')}</Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={12}>
            {decorateField('licensePlate', {
              placeholder: t('car_allowances_tab.detailed.edit.form.license_plate'),
              validate: (val) => (!val ? t('car_allowances_tab.detailed.edit.form.license_plate.required') : null),
            })(<Input />)}
          </Col>
          {!usingViaPoints && (
            <Col span={12}>
              {decorateField('kilometers', {
                placeholder: t('car_allowances_tab.detailed.edit.form.kilometers'),
                suffix: t('car_allowances_tab.detailed.edit.form.kilometers.suffix'),
                validate: (val) => (!val ? t('car_allowances_tab.detailed.edit.form.kilometers.required') : null),
              })(<Input />)}
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {decorateField('reason', {
              placeholder: t('car_allowances_tab.detailed.edit.form.reason'),
              validate: (val) => (!val ? t('car_allowances_tab.detailed.edit.form.reason.required') : null),
            })(<Input />)}
          </Col>
        </Row>
        {!!rates && props.company.settingsEnabled.some((setting) => setting === 'AllowRatePerCarAllowance') && (
          <Row>
            <Col span={24}>
              {decorateField('rate', {
                placeholder: t('car_allowances_tab.detailed.edit.form.rate'),
                suffix: t('car_allowances_tab.detailed.edit.form.rate.suffix'),
                helpText: (
                  <>
                    <p>{t('car_allowances_tab.detailed.edit.form.rate.help')}</p>
                  </>
                ),
                validate: (val) => {
                  if (!val) {
                    return null
                  }
                  const n = forceParseInputNumber(val)
                  if (n < 0) {
                    return t('car_allowances_tab.detailed.edit.form.rate.invalid')
                  }
                  if (n > carAllowanceRateLimit) {
                    return t('car_allowances_tab.detailed.edit.form.rate.above_limit', {
                      limit: formatCurrency(carAllowanceRateLimit, 2),
                    })
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          </Row>
        )}
        {isNew && (
          <Row>
            <Col span={24}>
              {decorateField('createReturnTrip', {
                title: t('car_allowances_tab.detailed.edit.form.create_return_trip'),
              })(
                <Radio.Group>
                  <Radio value={true}>{t('car_allowances_tab.detailed.edit.form.create_return_trip.true')}</Radio>
                  <Radio value={false}>{t('car_allowances_tab.detailed.edit.form.create_return_trip.false')}</Radio>
                </Radio.Group>
              )}
            </Col>
          </Row>
        )}
        <RegistrationCostCenter
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          decorateField={decorateField}
          getFieldValue={getFieldValue}
        />
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="extra-extra-large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
        {props.carAllowances.saving && <LoadingOverlay />}
      </Col>
      <Col span={12}>
        <Form.Item>
          <label>
            {!state.from || !state.to
              ? t('car_allowances_tab.detailed.edit.form.suggested.route.need_input')
              : t('car_allowances_tab.detailed.edit.form.suggested.route')}
          </label>
          <CarAllowanceMap
            from={state.from}
            to={state.to}
            waypoints={state.waypoints}
            avoidFerries={getFieldValue('avoidFerries')}
            updateDistance={updateDistance}
            updateReturnDistance={isNew ? updateReturnDistance : undefined}
          />
        </Form.Item>
        {usingViaPoints && (
          <div>
            <p>{t('car_allowances_tab.detailed.edit.form.via.help.line_1')}</p>
            <p>{t('car_allowances_tab.detailed.edit.form.via.help.line_2')}</p>
            <p>{t('car_allowances_tab.detailed.edit.form.via.help.line_3')}</p>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default withValidations<Props, Fields, CarAllowanceResult>({
  mapPropsToFields: (props) => {
    const summary = props.carAllowances.carAllowances
      .filter((carAllowance) => carAllowance.employeeID === props.employee.id)
      .reduce(
        (summary, carAllowance) => {
          if (carAllowance.avoidFerries) {
            summary.avoidFerry += 1
          } else {
            summary.withFerry += 1
          }
          return summary
        },
        { withFerry: 0, avoidFerry: 0 }
      )
    const fields: Fields = {
      date: getDate(),
      createReturnTrip: false,
      // so if the most common option for user is to avoid ferries; avoid ferries per default
      avoidFerries: summary.avoidFerry > summary.withFerry,
    }
    const firstCarAllowance = props.carAllowances.carAllowances
      .filter((carAllowance) => carAllowance.employeeID === props.employee.id)
      .first()
    if (firstCarAllowance) {
      fields.licensePlate = firstCarAllowance.licensePlate
    }
    const carAllowance = props.carAllowances.carAllowances.find(
      (carAllowance) => carAllowance.id === props.carAllowanceID
    )
    if (carAllowance) {
      if (!props.newCarAllowance) {
        fields.date = getDate(carAllowance.date)
      }
      fields.licensePlate = carAllowance.licensePlate
      fields.locationFrom = carAllowance.locationFrom
      fields.locationTo = carAllowance.locationTo
      fields.kilometers = formatInputNumber(carAllowance.kilometers, 2)
      fields.reason = carAllowance.reason
      fields.avoidFerries = carAllowance.avoidFerries
      fields.costCenterID = carAllowance.costCenterID
      fields.rate = carAllowance.rate && carAllowance.rate > 0 ? formatInputNumber(carAllowance.rate, 2) : undefined
    } else {
      fields.viaPoints = []
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'kilometers':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const rate = values.rate ? forceParseInputNumber(values.rate) : undefined
    const trips: CarAllowanceResultTrip[] = []
    if (!values.viaPoints || values.viaPoints.length === 0) {
      trips.push({
        locationFrom: values.locationFrom!,
        locationTo: values.locationTo!,
        kilometers: forceParseInputNumber(values.kilometers),
      })
      if (values.createReturnTrip && values.returnKilometers) {
        trips.push({
          locationFrom: values.locationTo!,
          locationTo: values.locationFrom!,
          kilometers: values.returnKilometers,
        })
      }
    } else {
      const viaPoints = values.viaPoints
      trips.push({
        locationFrom: values.locationFrom!,
        locationTo: values.viaPoints[0].location!,
        kilometers: forceParseInputNumber(values.kilometers),
      })
      viaPoints.forEach((v, i) => {
        let locationTo
        if (i === viaPoints.length - 1) {
          locationTo = values.locationTo!
        } else {
          locationTo = viaPoints[i + 1].location!
        }
        trips.push({
          locationFrom: v.location!,
          locationTo: locationTo,
          kilometers: v.kilometers!,
        })
      })
      if (values.createReturnTrip && values.returnViaPoints) {
        const returnViaPoints = values.returnViaPoints
        trips.push({
          locationFrom: values.locationTo!,
          locationTo: viaPoints[viaPoints.length - 1].location!,
          kilometers: values.returnKilometers ?? 0,
        })
        returnViaPoints.forEach((v, i) => {
          const locationIdx = viaPoints.length - 1 - i
          const locationFrom = viaPoints[locationIdx].location!
          let locationTo
          if (i === returnViaPoints.length - 1) {
            locationTo = values.locationFrom!
          } else {
            locationTo = viaPoints[locationIdx - 1].location!
          }
          trips.push({
            locationFrom: locationFrom,
            locationTo: locationTo,
            kilometers: v.kilometers!,
          })
        })
      }
    }
    return {
      date: formatAPIDate(values.date),
      avoidFerries: values.avoidFerries,
      licensePlate: values.licensePlate!,
      reason: values.reason!,
      costCenterID: values.costCenterID,
      rate: rate && rate > 0 ? rate : undefined,
      trips,
    }
  },
})(DetailedCarAllowanceEditForm)
