import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import LeaveType, { LeaveTypeName } from '../../model/leaveType'
import PensionCompany from '../../model/pensionCompany'
import { PensionDefinitionCreationFields, SupplementDefinitionMutableFields } from '../../model/remuneration'
import SupplementType from '../../model/supplementType'
import PensionSchemes from '../../types/pension-scheme'
import PensionScheme from '../../types/pension-scheme'
import {
  isAmountChoiceSupplement,
  isChoiceSupplement,
  isDirectPayChoiceSupplement,
  isPercentageChoiceSupplement,
} from '../../utils/employee-contract-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Subtitle from '../elements/Subtitle'
import ChoiceForm, { SupplementRow } from './ChoiceForm'
import PensionForm, { PensionRow } from './PensionForm'
import { LeaveDefinitionFields, PensionDefinitionFields, RemunerationFields, SupplementDefinitionFields } from './types'

type Props = {
  settingsEnabled: CompanySetting[]
  hasFutureChanges: boolean
  employee: Employee
  pensionCompanies: List<PensionCompany>
  supplementTypes: List<SupplementType>
  leaveTypes: List<LeaveType>
  useATP: boolean

  remuneration: RemunerationFields
}

type Fields = {
  hasPension: boolean
  hasNetPension: boolean
  hasUnpaidVacation: boolean
  includeVacationInPensionBasis: boolean
  hasChoice: boolean
  remuneration: {
    pension: PensionRow[]
    supplements: SupplementRow[]
    otherSupplements: SupplementDefinitionFields[]
  }
}

export type PensionStepResult = {
  remuneration: {
    pension: PensionDefinitionFields[]
    supplements: SupplementDefinitionFields[]
    leave: LeaveDefinitionFields[]
  }
  step: 'PensionStep'
}

function PensionStep(props: Props & FormComponentProps<Fields, PensionStepResult>): ReactElement | null {
  return (
    <div>
      <Subtitle>{t('contracts_add.pension_step.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          <Headline>{t('contracts_add.pension_step.pension.title')}</Headline>
        </Col>
      </Row>
      <PensionForm<Fields>
        decorateField={props.decorateField}
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        settingsEnabled={props.settingsEnabled}
        employee={props.employee}
        pensionCompanies={props.pensionCompanies}
      />
      <Row>
        <Col span={24}>&nbsp;</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Headline>{t('contracts_add.pension_step.choice.title')}</Headline>
        </Col>
      </Row>
      <ChoiceForm<Fields>
        decorateField={props.decorateField}
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        employee={props.employee}
        settingsEnabled={props.settingsEnabled}
        supplementTypes={props.supplementTypes}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" suffixIcon="arrowRight">
            {props.hasFutureChanges
              ? t('contracts_add.pension_step.submit.to_future')
              : t('contracts_add.pension_step.submit.final')}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack}>
            {t('contracts_add.pension_step.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, PensionStepResult>({
  mapPropsToFields: (props) => {
    const getLeaveType = (id?: string) => props.leaveTypes.find((type) => type.id === id)
    const getSupplementType = (id?: string) => props.supplementTypes.find((type) => type.id === id)
    let hasNetPension = false
    const fields: Fields = {
      hasNetPension,
      hasPension: props.remuneration.pension.filter((row) => row.scheme !== 'ATP').length > 0,
      hasChoice:
        props.remuneration.supplements.filter((row) => isChoiceSupplement(getSupplementType(row.typeID)?.name)).length >
        0,
      hasUnpaidVacation: props.remuneration.leave.some((row) => {
        const type = getLeaveType(row.typeID)
        return type && (type.name === 'DenmarkVacationFund' || type.name === 'DenmarkVacationFundWithPension')
      }),
      includeVacationInPensionBasis: props.remuneration.leave.some((row) => {
        const type = getLeaveType(row.typeID)
        return type && type.name === 'DenmarkVacationFundWithPension'
      }),
      remuneration: {
        pension: props.remuneration.pension
          .filter((row) => row.scheme !== 'ATP')
          .map((row) => {
            const pensionCompanyName = undefined
            let hasUnionAgreementNumber = true
            if (row.pensionCompanyID) {
              hasUnionAgreementNumber = props.pensionCompanies.some(
                (company) =>
                  company.id === row.pensionCompanyID &&
                  company.fields &&
                  company.fields.indexOf('UnionAgreementNumber') !== -1
              )
            }
            let scheme = row.scheme
            switch (scheme) {
              case PensionSchemes.EMPLOYER_PAID_NET:
                scheme = PensionSchemes.EMPLOYER_PAID
                hasNetPension = true
                break
              case PensionSchemes.EMPLOYEE_PAID_NET:
                scheme = PensionSchemes.EMPLOYEE_PAID
                hasNetPension = true
                break
              default:
                scheme = row.scheme
                break
            }
            return {
              title: row.title,
              pensionCompanyID: row.pensionCompanyID || 'NoPensionCompany',
              pensionCompanyName,
              hasUnionAgreementNumber,
              customerIDType: row.customerIDType,
              pensionCustomerID: row.pensionCustomerID,
              sortCode: row.sortCode,
              account: row.account,
              unionAgreementNumber: row.unionAgreementNumber,
              scheme: scheme,
              value: formatInputNumber(row.fixedAmount || row.percentage),
              suffix: row.fixedAmount ? 'fixedAmount' : 'percentage',
            }
          }),
        supplements: props.remuneration.supplements
          .filter((row) => isChoiceSupplement(getSupplementType(row.typeID)?.name))
          .map((row) => {
            return {
              typeID: row.typeID,
              compensationRate: formatInputNumber(row.compensationRate * 100),
              suffix: isAmountChoiceSupplement(getSupplementType(row.typeID)?.name) ? 'fixedAmount' : 'percentage',
              directPay: isDirectPayChoiceSupplement(getSupplementType(row.typeID)?.name),
            }
          }),
        otherSupplements: props.remuneration.supplements.filter(
          (row) => !isChoiceSupplement(getSupplementType(row.typeID)?.name)
        ),
      },
    }
    fields.hasNetPension = hasNetPension
    if (fields.remuneration.pension.length === 0) {
      fields.remuneration.pension.push({
        pensionCompanyID: 'NoPensionCompany',
        suffix: 'percentage',
        hasUnionAgreementNumber: true,
      })
    }
    if (fields.remuneration.supplements.length === 0) {
      const supplementType = props.supplementTypes.find(
        (row) => isPercentageChoiceSupplement(row.name) && !isDirectPayChoiceSupplement(row.name)
      )
      if (supplementType) {
        fields.remuneration.supplements.push({
          typeID: supplementType.id,
          suffix: isAmountChoiceSupplement(supplementType.name) ? 'fixedAmount' : 'percentage',
          directPay: false,
        })
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (
      key.match(/^remuneration\.pension\.\d+\.(fixedAmount|percentage)$/) ||
      key.match(/^remuneration\.supplements\.\d+\.compensationRate$/)
    ) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else if (key.match(/^remuneration\.pension\.\d+\.scheme$/)) {
      // when employee paid and net, it can only be fixed amount
      const result = /^remuneration\.pension\.(\d+)\.scheme$/.exec(key)
      const row = result ? parseInt(result[1]) : -1
      if (
        allValues.remuneration.pension[row].suffix === 'percentage' &&
        val === PensionSchemes.EMPLOYEE_PAID &&
        allValues.hasNetPension
      ) {
        setByPath(values, `remuneration.pension.${row}.suffix`, 'fixedAmount')
      }
      setByPath(values, key, val)
    } else if (key === 'hasNetPension') {
      if (val) {
        allValues.remuneration.pension.forEach((pension, i) => {
          if (pension.suffix === 'percentage' && pension.scheme === PensionSchemes.EMPLOYEE_PAID) {
            setByPath(values, `remuneration.pension.${i}.suffix`, 'fixedAmount')
          }
        })
      }
      setByPath(values, key, val)
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values, props) => {
    const getLeaveType = (name: LeaveTypeName) => props.leaveTypes.find((type) => type.name === name)!
    const getLeaveTypeID = (id: string) => props.leaveTypes.find((type) => type.id === id)
    const pension = values.hasPension
      ? values.remuneration.pension.map((oldRow): PensionDefinitionCreationFields => {
          const row: PensionDefinitionCreationFields = {
            ...oldRow,
            destinationType: 'DK Account',
            scheme: oldRow.scheme || PensionScheme.EMPLOYEE_PAID,
          }
          if (values.hasNetPension) {
            if (row.scheme === PensionSchemes.EMPLOYEE_PAID) {
              row.scheme = PensionSchemes.EMPLOYEE_PAID_NET
            } else {
              row.scheme = PensionSchemes.EMPLOYER_PAID_NET
            }
          }
          if (!row.pensionCompanyID || row.pensionCompanyID === 'NoPensionCompany') {
            row.destinationType = 'DK Account'
            row.pensionCompanyID = undefined
            row.pensionCustomerID = undefined
          } else {
            row.destinationType = 'DK PBS'
            row.account = undefined
            row.sortCode = undefined
          }
          if (!row.unionAgreementNumber || row.unionAgreementNumber.trim() === '') {
            row.unionAgreementNumber = undefined
          }
          if (row.pensionCompanyID) {
            const pensionCompany = props.pensionCompanies.find((company) => company.id === row.pensionCompanyID)
            if (pensionCompany) {
              if (pensionCompany.fields && pensionCompany.fields.indexOf('PensionType') !== -1) {
                if (row.unionAgreementNumber && row.unionAgreementNumber.trim() !== '') {
                  row.pensionType = 1
                } else {
                  row.pensionType = 2
                }
              } else {
                row.pensionType = undefined
              }
            }
          }
          row[oldRow.suffix] = forceParseInputNumber(oldRow.value)
          return row
        })
      : []
    if (pension.filter((row) => row.scheme === 'ATP').length === 0 && props.useATP) {
      pension.push({ scheme: PensionScheme.ATP })
    }
    let supplements = values.remuneration.otherSupplements
    if (values.hasChoice) {
      supplements = [
        ...supplements,
        ...values.remuneration.supplements.map(
          (row): SupplementDefinitionMutableFields => ({
            ...row,
            compensationRate: forceParseInputNumber(row.compensationRate) / (row.suffix === 'fixedAmount' ? 1 : 100),
          })
        ),
      ]
    }
    let leave = props.remuneration.leave
    if (values.hasUnpaidVacation) {
      leave = leave.map((row) => {
        const type = getLeaveTypeID(row.typeID)
        if (type) {
          if (type.name === 'DenmarkVacationFund' && values.includeVacationInPensionBasis) {
            row.typeID = getLeaveType('DenmarkVacationFundWithPension').id
          } else if (type.name === 'DenmarkVacationFundWithPension' && !values.includeVacationInPensionBasis) {
            row.typeID = getLeaveType('DenmarkVacationFund').id
          }
        }
        return row
      })
    }
    return {
      remuneration: {
        pension,
        supplements,
        leave,
      },
      step: 'PensionStep',
    }
  },
})(PensionStep)
