import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import { Col, Row } from '../../elements/grid'
import Input from '../../elements/input'
import Subtitle from '../../elements/Subtitle'

type Props = {
  company: Company
}

type Fields = {
  approvalCode?: string
}

export type ApprovalCodeResult = {
  enable: boolean
  approvalCode: string
}

function PayrollApprovalCodeModal(props: Props & FormComponentProps<Fields, ApprovalCodeResult>): ReactElement | null {
  if (props.company.rulePayRollRunApproval === 'Automatic') {
    return (
      <Card>
        <p>{t('company_pay_roll.approval_code.blocked')}</p>
        <Row>
          <Col span={24}>
            <Button onClick={(e) => props.goBack(e)} style={{ width: '100%' }}>
              {t('company_pay_roll.approval_code.button.blocked')}
            </Button>
          </Col>
        </Row>
      </Card>
    )
  }

  return (
    <Card>
      <Subtitle>{t('company_pay_roll.approval_code.title')}</Subtitle>
      <p>{t('company_pay_roll.approval_code.intro.line_1')}</p>
      <p>{t('company_pay_roll.approval_code.intro.line_2')}</p>
      <Row>
        <Col span={24}>
          {props.decorateField('approvalCode', {
            placeholder: t('company_pay_roll.approval_code.approval_code'),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('company_pay_roll.approval_code.button.submit')}
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={(e) => props.goBack(e)} style={{ width: '100%' }}>
            {t('company_pay_roll.approval_code.button.cancel')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, ApprovalCodeResult>({
  onSubmit: (values) => {
    if (!values.approvalCode) {
      return { enable: false, approvalCode: '' }
    }
    return {
      enable: true,
      approvalCode: values.approvalCode,
    }
  },
})(PayrollApprovalCodeModal)
