import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { ReimbursementVoucherFieldsUpdate, ReimbursementVoucherUpdate } from '../../api/reimbursement-vouchers'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Modal from '../elements/modal'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import ReimbursementVoucherEdit from '../reimbursement-vouchers/ReimbursementVoucherEdit'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  company: Company
  companyUser?: CompanyUser
  reimbursementVouchers: ReimbursementVoucherReducer

  companyUsers: List<CompanyUser>
  costCenters: List<CostCenter>
  employees: List<Employee>
  employeeMaps: Map<string, Employee>
  expenseCategories: List<ExpenseCategory>
  departments: List<Department>

  addAlert: addAlertSignature
  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
}

export default function ReimbursementVouchersCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [modalKey, setModalKey] = useState<number>(1)
  const [editing, setEditing] = useState<boolean | string>(false)
  type Sort = {
    sortOn?: 'employee' | 'date' | 'expenseCategory' | 'amount'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { reimbursementVouchers, addAlert } = props
  const previousReimbursementVouchers = usePrevious(reimbursementVouchers)

  const setEditVisibility = useCallback(
    (id: boolean | string) => {
      setModalKey(modalKey + 1)
      setEditing(id)
    },
    [modalKey, setModalKey, setEditing]
  )

  useEffect(() => {
    if (previousReimbursementVouchers && previousReimbursementVouchers.saving && !reimbursementVouchers.saving) {
      if (reimbursementVouchers.error) {
        addAlert('error', formatError(reimbursementVouchers.error))
      }
      setApproving([]) //empty it
    }
  }, [previousReimbursementVouchers, reimbursementVouchers, addAlert, setApproving])

  const pendingReimbursementVouchers: ReimbursementVoucher[] = reimbursementVouchers.reimbursementVouchers
    .filter(
      (voucher) =>
        voucher.approvalState === 'Ready' &&
        !approving.some((approve) => approve === voucher.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employeeMaps, voucher.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveReimbursementVouchers([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingReimbursementVouchers.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveReimbursementVouchers(unapproved)
  }

  type ReimbursementVoucherRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    expenseCategory?: string
    amount: string
    disburseable: boolean
    immediatePay: boolean
    note: string
    date: string
    receiptDate?: string
    original: ReimbursementVoucher
  }

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'xThumbnail',
      className: 'ant-table-col-thumbnail',
      render: (row: ReimbursementVoucherRow) => {
        return (
          <div className="voucher-image" onClick={() => setEditVisibility(row.id)}>
            <img src={secureUrl(`v2/reimbursementVouchers/${row.id}/thumbnail`)} alt="" />
          </div>
        )
      },
    },
    {
      title: t('approve_tab.reimbursements_vouchers.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: ReimbursementVoucherRow) => {
        return <ApproveCardEmployeeName {...row} onClick={() => setEditVisibility(row.id)} />
      },
    },
    {
      title: t('approve_tab.reimbursements_vouchers.header.date'),
      dataIndex: '',
      key: 'xDate',
      sorter: 'date',
      render: (row: ReimbursementVoucherRow) => {
        return (
          <span onClick={() => setEditVisibility(row.id)}>
            {row.receiptDate}
            {row.receiptDate !== row.date && (
              <small>
                {t('approve_tab.reimbursements_vouchers.table.received')}
                <br />
                {row.date}
              </small>
            )}
          </span>
        )
      },
    },
    {
      title: t('approve_tab.reimbursements_vouchers.header.expense_category'),
      dataIndex: '',
      key: 'xExpenseCategory',
      width: '15%',
      sorter: 'expenseCategory',
      render: (row: ReimbursementVoucherRow) => {
        return (
          <span onClick={() => setEditVisibility(row.id)}>
            {row.expenseCategory ?? <em>{t('approve_tab.reimbursements_vouchers.table.missing_category')}</em>}
          </span>
        )
      },
    },
    {
      title: t('approve_tab.reimbursements_vouchers.header.amount'),
      dataIndex: '',
      key: 'xAmount',
      sorter: 'amount',
      render: (row: ReimbursementVoucherRow) => {
        return <span onClick={() => setEditVisibility(row.id)}>{row.amount}</span>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: ReimbursementVoucherRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.reimbursements_vouchers.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingReimbursementVoucherRows: ReimbursementVoucherRow[] = pendingReimbursementVouchers
    .map((voucher) => {
      return {
        key: voucher.id,
        id: voucher.id,
        employeeID: voucher.employeeID,
        ...getEmployeeRowEmployeeDetails(
          props.employeeMaps.get(voucher.employeeID),
          props.departments,
          props.companyUsers
        ),
        date: formatDate(voucher.createdAt),
        receiptDate: voucher.receiptDate ? formatDate(voucher.receiptDate) : undefined,
        expenseCategory: voucher.expenseCategory ? voucher.expenseCategory.name : undefined,
        amount: formatCurrency(voucher.disburseAmount, 2),
        amountNo: voucher.disburseAmount || 0,
        disburseable: voucher.disburseable,
        immediatePay: voucher.immediatePay,
        note: voucher.note,
        original: voucher,
      }
    })
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.date.localeCompare(b.date)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.date === j.date) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.date.localeCompare(j.date)
        case 'expenseCategory':
          if (i.expenseCategory === j.expenseCategory) {
            if (i.employeeName === j.employeeName) {
              return a.date.localeCompare(b.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          if (i.expenseCategory === undefined) {
            return 1000
          }
          if (j.expenseCategory === undefined) {
            return -1000
          }
          return i.expenseCategory.localeCompare(j.expenseCategory)
        case 'amount':
          if (i.amountNo === j.amountNo) {
            if (i.employeeName === j.employeeName) {
              return a.date.localeCompare(b.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.amountNo - i.amountNo
        default:
          if (a.employeeName === b.employeeName) {
            if (a.date === b.date) {
              return a.amountNo - b.amountNo
            }
            return a.date.localeCompare(b.date)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = ({ sorter }: TableChange<ReimbursementVoucherRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'expenseCategory':
      case 'amount':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingReimbursementVouchers.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="extra-extra-large">
          {t('approve_tab.reimbursements_vouchers.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.reimbursements_vouchers.title')}</Title>
      <Table columns={columns} dataSource={pendingReimbursementVoucherRows} onChange={tableChange} pagination={false} />

      <Modal
        key={`edit-${modalKey}`}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={980}
        footer={null}
      >
        <ReimbursementVoucherEdit
          visible={editing !== false}
          reimbursementVoucherID={typeof editing === 'boolean' ? undefined : editing}
          company={props.company}
          companyUsers={props.companyUsers}
          employees={props.employees}
          expenseCategories={props.expenseCategories}
          reimbursementVouchers={props.reimbursementVouchers}
          costCenterAccounting={props.company.costCenterAccounting || 'Off'}
          costCenters={props.costCenters}
          updateReimbursementVoucher={props.updateReimbursementVoucher}
          updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
          approveReimbursementVouchers={props.approveReimbursementVouchers}
          unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
          draftReimbursementVouchers={props.draftReimbursementVouchers}
          closeModal={() => setEditVisibility(false)}
        />
      </Modal>
    </div>
  )
}
