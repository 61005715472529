import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import { CostCenterAccounting } from '../../model/accountingIntegration'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../model/oneTimePay'
import SalaryCycle from '../../model/salaryCycle'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import StagedImportFileType from '../../types/staged-import-file-type'
import { formatCurrency } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import FeeEdit from '../freelancers-single/fee/FeeEdit'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'

import './FreelancersOverview.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyUser?: CompanyUser
  employees: List<Employee>
  salaryCycle?: SalaryCycle
  oneTimePays: OneTimePayReducer
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  companyAccountPlans: CompanyAccountPlanReducer

  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  removeAlert: removeAlertSignature
  getCompanyAccountPlans: () => void
}

export default function FreelancersOverview({
  alerts,
  company,
  companyUser,
  employees,
  salaryCycle,
  oneTimePays,
  costCenterAccounting,
  costCenters,
  companyAccountPlans,
  addOneTimePay,
  updateOneTimePay,
  removeAlert,
  getCompanyAccountPlans,
}: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState<number>(1)
  const [showFee, setShowFee] = useState<string | null>(null)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })

  useEffectOnce(() => {
    if (!companyAccountPlans.loading && !companyAccountPlans.loaded) {
      getCompanyAccountPlans()
    }
  })

  const saving = oneTimePays.saving
  const prevSaving = usePrevious(saving)

  const setFeeVisibility = useCallback(
    (showFee: string | null) => {
      // Increment modalKey to create a new component
      setModalKey(modalKey + 1)
      setShowFee(showFee)
    },
    [modalKey, setModalKey, setShowFee]
  )

  const otpError = oneTimePays.error

  useEffect(() => {
    if (prevSaving && !saving) {
      if (!otpError) {
        setFeeVisibility(null)
      }
    }
  }, [prevSaving, saving, otpError, setFeeVisibility])

  type EmployeeRow = {
    key: string
    id: string
    profileImageURL?: string
    name: string
    position?: string
    totalFee: number
  }

  const getColumns = () => {
    return [
      {
        title: t('freelancers_overview.table.header.freelancer'),
        dataIndex: '',
        key: 'xFreelancer',
        width: 330,
        render: (employee: EmployeeRow) => {
          return (
            <Headline>
              <UserImage src={employee.profileImageURL} name={employee.name} />
              <Link to={'/' + paths.FREELANCERS + '/' + employee.id + '/profile'}>{employee.name}</Link>
              <small>{employee.position ? employee.position : '-'}</small>
            </Headline>
          )
        },
      },
      {
        title: t('freelancers_overview.table.header.compensation'),
        dataIndex: '',
        key: 'xCompensation',
        render: (employee: EmployeeRow) => {
          const amount = employee.totalFee
          return (
            <Link to={'/' + paths.FREELANCERS + '/' + employee.id} className="black">
              {formatCurrency(amount)}
            </Link>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        key: 'xActions',
        className: 'ant-table-col-context',
        render: (employee: EmployeeRow) => {
          return (
            <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
              <DumbLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setFeeVisibility(employee.id)
                }}
              >
                <Icon type="user" /> {t('freelancers_overview.table.actions.add_fee')}
              </DumbLink>
              <Link to={'/' + paths.FREELANCERS + '/' + employee.id + '/overview'}>
                <Icon type="paperWithPencil" /> {t('freelancers_overview.table.actions.details')}
              </Link>
            </ContextMenu>
          )
        },
      },
    ]
  }

  const getEmployees = (): EmployeeRow[] => {
    return employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.affiliationType === 'Freelancer' &&
          employee.activeEmployment &&
          employee.earliestMutableContract
      )
      .toArray()
      .map((employee: Employee): EmployeeRow => {
        const result: EmployeeRow = {
          key: employee.id,
          id: employee.id,
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.earliestMutableContract ? employee.earliestMutableContract.position : undefined,
          totalFee: 0,
        }
        oneTimePays.oneTimePays
          .filter((reg) => reg.employeeID === employee.id)
          .forEach((oneTimePay) => {
            if (oneTimePay.settled) {
              return
            }
            const type = oneTimePay.type
            if (type === 'Compensation' || type === 'Honorary Gift' || type === 'Compensation No AM') {
              result.totalFee += oneTimePay.amount
            }
          })
        return result
      })
  }

  const feeEmployee = employees.find((employee) => employee.id === showFee)

  return (
    <div className="freelancers-overview">
      <Alerts alerts={alerts} removeAlert={removeAlert} />

      <TitleMenu>
        <EmployeeFilter companyUser={companyUser} onFilterChange={(filter) => setFilter(filter)} />
        {hasDepartmentPermission(companyUser, undefined, 'EditObjects') && (
          <Link to={'/' + paths.STAGED_IMPORT + '?type=' + StagedImportFileType.FREELANCERS_FEE}>
            <Button type="primary" size="extra-large" className="gtm-import-timereg" prefixIcon="clock">
              {t('freelancers_overview.header.import_from_file')}
            </Button>
          </Link>
        )}
      </TitleMenu>

      <Title>{t('freelancers_overview.title')}</Title>
      <Table style={{ clear: 'both' }} columns={getColumns()} dataSource={getEmployees()} pagination={false} />

      {salaryCycle && (
        <Modal
          key={modalKey}
          visible={!!showFee}
          onOk={() => setFeeVisibility(null)}
          onCancel={() => setFeeVisibility(null)}
          width={582}
          footer={null}
        >
          <FeeEdit
            visible={!!showFee}
            editing={true}
            company={company}
            employee={feeEmployee}
            canApproveObjects={hasDepartmentPermission(
              companyUser,
              feeEmployee ? feeEmployee.departmentID : undefined,
              'ApproveObjects'
            )}
            oneTimePays={oneTimePays}
            salaryCycle={salaryCycle}
            costCenterAccounting={costCenterAccounting}
            costCenters={costCenters}
            accounts={companyAccountPlans.accounts}
            addOneTimePay={addOneTimePay}
            updateOneTimePay={updateOneTimePay}
          />
        </Modal>
      )}
    </div>
  )
}
