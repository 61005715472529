import { List } from 'immutable'
import React, { ReactElement } from 'react'

import PaySlip from '../../model/paySlip'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  paySlips: List<PaySlip>
}

type Fields = {
  paySlips: { [key: string]: boolean }
}

export type NegateResult = {
  paySlipIDs: string[]
}

function NegatePayroll(props: Props & FormComponentProps<Fields, NegateResult>): ReactElement | null {
  const { decorateAnyField } = props
  const getName = (paySlip: PaySlip): string => {
    let el = paySlip.fields.find((field) => field.name === 'EmployeeName')
    if (el) {
      return el.serializedValue
    }
    el = paySlip.fields.find((field) => field.name === 'Email')
    if (el) {
      return el.serializedValue
    }
    return '-'
  }
  return (
    <Card className="pay-roll-negate">
      <Subtitle>{t('pay_roll.single.negate.title')}</Subtitle>
      <p>{t('pay_roll.single.negate.intro')}</p>
      <p>&nbsp;</p>
      <Row>
        <Col span={24} style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          {props.paySlips
            .sort((a, b) => getName(a).localeCompare(getName(b)))
            .map((paySlip) => {
              return decorateAnyField('paySlips.' + paySlip.id, {
                valueOnChecked: true,
                noBlur: true,
              })(
                <Checkbox id={'paySlips.' + paySlip.id} key={'paySlips.' + paySlip.id}>
                  {getName(paySlip)} ({formatCurrency(paySlip.payCheck, 2)})
                </Checkbox>
              )
            })}
        </Col>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" block>
            {t('pay_roll.single.negate.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, NegateResult>({
  mapPropsToFields: (props: Props) => {
    const fields: Fields = {
      paySlips: {},
    }
    props.paySlips.forEach((paySlip) => {
      fields.paySlips[paySlip.id] = false
    })
    return fields
  },
  onSubmit: (values: Fields) => {
    const paySlipIDs: string[] = Object.keys(values.paySlips).filter((key) => values.paySlips[key])
    return {
      paySlipIDs,
    }
  },
})(NegatePayroll)
