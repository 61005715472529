import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { requestCompanyDeletion } from '../../api/companies'
import Company from '../../model/company'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Input from '../elements/input'
import Title from '../elements/Title'

type Props = {
  company: Company

  addAlert: addAlertSignature
  closeModal: () => void
}

export default function RequestDeletionModal(props: Props): ReactElement | null {
  const [confirmed, setConfirmed] = useState(false)
  const [comment, setComment] = useState('')
  return (
    <Card>
      <Title>{t('request_deletion.title')}</Title>
      <p>{t('request_deletion.note.line_1')}</p>
      <p>{t('request_deletion.note.line_2')}</p>
      <Input.TextArea
        autosize={{ minRows: 2, maxRows: 6 }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          e.preventDefault()
          setComment(e.target.value)
        }}
        placeholder={t('request_deletion.form.comment')}
      />
      <Checkbox
        style={{ marginTop: '25px' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault()
          setConfirmed(e.target.checked)
        }}
      >
        {t('request_deletion.form.confirmed')}
      </Checkbox>
      <Button
        style={{ marginTop: '25px' }}
        disabled={!confirmed}
        onClick={() => {
          requestCompanyDeletion(props.company.id, 'userRequest', comment).then(() => {
            props.addAlert('success', t('request_deletion.alert.success'), { timeout: 10 })
            props.closeModal()
          })
        }}
        danger
        size="extra-extra-large"
      >
        {t('request_deletion.form.submit')}
      </Button>
    </Card>
  )
}
