import { addYears, min, setDayOfYear, subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import { CoarseSalaryRegistrationMutableFields } from '../../model/coarseSalaryRegistration'
import Company from '../../model/company'
import Contract, { ContractCreationFields, SalaryRegistrationMethodType } from '../../model/contract'
import Department from '../../model/department'
import SalaryCycle, { SalaryFrequency } from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { DateFormat, SettledState } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { CoarseSalaryRegistrationReducer } from '../../reducers/coarseSalaryRegistrations'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { ContractReducer } from '../../reducers/contracts'
import { EmployeeReducer } from '../../reducers/employees'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { formatSalaryFrequency } from '../../utils/format-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { getCurrentPeriodFromDispositionDate } from '../../utils/salary-period-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Select from '../elements/select'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CoarseSalaryRegistrationModal from './CoarseSalaryRegistrationModal'
import DeleteCompanySalaryRegistrationsModal from './DeleteCompanySalaryRegistrationsModal'

import './SalaryRegistration.css'

type Props = {
  alerts: AlertReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  contracts: ContractReducer
  employees: EmployeeReducer
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: Contract) => void
  getCoarseSalaryRegistrations: (companyID: string, salaryPeriodID: string) => void
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState
  ) => void
  getContracts: () => void
  approveSalaryRegistrations: (ids: string[]) => void
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => Promise<void>
}

export default function SalaryRegistration(props: Props): ReactElement | null {
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })

  const getSalaryFrequencies = () => {
    const frequencies = props.employees.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.affiliationType !== 'Freelancer' &&
          employee.activeContract
      )
      .reduce((frequencies: SalaryFrequency[], employee) => {
        const salaryCycle = props.salaryCycles.find(
          (salaryCycle) => salaryCycle.id === employee.activeContract?.salaryCycleID
        )
        if (salaryCycle) {
          if (frequencies.indexOf(salaryCycle.frequency) === -1) {
            frequencies.push(salaryCycle.frequency)
          }
        }
        return frequencies
      }, [])
    const order: Record<SalaryFrequency, number> = {
      Monthly: 0,
      BiWeekly: 1,
      Weekly: 2,
    }
    frequencies.sort((a, b) => {
      return order[a] - order[b]
    })
    return frequencies
  }

  const [frequency, setFrequency] = useState(() => {
    const frequencies = getSalaryFrequencies()
    return frequencies.length > 0 ? frequencies[0] : 'Monthly'
  })
  const [modalKey, setModalKey] = useState(1)
  const [showCoarse, setShowCoarse] = useState<string | boolean>(false)
  const [showDeleteCompanySalaryRegistrations, setShowDeleteCompanySalaryRegistrations] = useState(false)

  const getSalaryCycleID = (company: Company, salaryCycles: List<SalaryCycle>, thisFrequency = frequency) => {
    let salaryCycleID = undefined
    if (thisFrequency === 'Monthly') {
      salaryCycleID = company.defaultHourlyMonthlyCycleID
    }
    if (thisFrequency === 'BiWeekly') {
      salaryCycleID = company.defaultBiweeklyCycleID
    }
    if (!salaryCycleID) {
      return salaryCycles.find(
        (salaryCycle) => salaryCycle.frequency === thisFrequency && !salaryCycle.prepaid && !salaryCycle.offset
      )?.id
    }
    return salaryCycleID
  }

  const getSalaryCycle = (company: Company, salaryCycles: List<SalaryCycle>, thisFrequency = frequency) => {
    const salaryCycleID = getSalaryCycleID(company, salaryCycles, thisFrequency)
    if (salaryCycleID) {
      return salaryCycles.find((salaryCycle) => salaryCycle.id === salaryCycleID)
    }
    return undefined
  }

  const { companies, employees, contracts, getContracts } = props

  useEffect(() => {
    // load remunerations for all employees we display here
    if (
      !contracts.loading &&
      employees.employees.some(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          !!employee.activeContract &&
          !employee.activeContract.remuneration
      )
    ) {
      getContracts()
    }
  }, [contracts, employees, getContracts])

  const {
    salaryCycles,
    coarseSalaryRegistrations,
    getCoarseSalaryRegistrations,
    salaryRegistrations,
    getSalaryRegistrations,
  } = props

  useEffect(() => {
    if (!companies.company) {
      return
    }

    const companyID = companies.company.id
    const salaryCycle = getSalaryCycle(companies.company, salaryCycles)
    if (salaryCycle) {
      const salaryPeriod = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)
      if (salaryPeriod) {
        const salaryPeriodID = salaryPeriod.id
        if (
          coarseSalaryRegistrations.companyID !== companyID ||
          coarseSalaryRegistrations.salaryPeriodID !== salaryPeriodID ||
          (!coarseSalaryRegistrations.loading && !coarseSalaryRegistrations.loaded)
        ) {
          getCoarseSalaryRegistrations(companyID, salaryPeriodID)
        }
      }
    }
    if (
      salaryRegistrations.companyID !== companyID ||
      !!salaryRegistrations.employeeID ||
      salaryRegistrations.stateFilter !== 'Pending' ||
      (!salaryRegistrations.loading && !salaryRegistrations.loaded)
    ) {
      getSalaryRegistrations(
        companyID,
        undefined,
        undefined,
        formatAPIDate(min([setDayOfYear(getDate(), 1), subMonths(getDate(), 2)])),
        formatAPIDate(addYears(getDate(), 2)),
        'Pending'
      )
    }
  })

  const setCoarseVisibility = useCallback(
    (id: string | boolean) => {
      setModalKey((prev) => prev + 1)
      setShowCoarse(id)
    },
    [setModalKey, setShowCoarse]
  )

  const setDeleteCompanySalaryRegistrations = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDeleteCompanySalaryRegistrations(visible)
  }

  const previousCoarseSalaryRegistrations = usePrevious(coarseSalaryRegistrations)

  useEffect(() => {
    if (
      previousCoarseSalaryRegistrations &&
      previousCoarseSalaryRegistrations.saving &&
      !coarseSalaryRegistrations.saving
    ) {
      if (!coarseSalaryRegistrations.error) {
        setCoarseVisibility(false)
      }
    }
  }, [previousCoarseSalaryRegistrations, coarseSalaryRegistrations, setCoarseVisibility])

  const changeFrequency = (frequency: SalaryFrequency) => {
    const company = companies.company
    if (!company) {
      return
    }
    setFrequency(frequency)
  }

  const approveAll = (employeeID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (window.confirm(t('common.are_you_sure'))) {
        const ids = salaryRegistrations.salaryRegistrations
          .filter((reg) => reg.employeeID === employeeID && !reg.approved)
          .toArray()
          .map((reg) => reg.id)
        props.approveSalaryRegistrations(ids)
      }
    }
  }

  type GenericSalaryRegistration = {
    key: string
    salaryTypeID: string
    salaryName: string
    rate: number
    quantity: number
  }

  type EmployeeRow = {
    key: string
    id: string
    loading: boolean
    name: string
    profileImageURL?: string
    position?: string
    canSeeSalaryRates: boolean
    canApproveObjects: boolean
    canEditObjects: boolean
    hasUnapproved: boolean
    type: SalaryRegistrationMethodType
    salaryRegistrations: GenericSalaryRegistration[]
  }

  const columns = [
    {
      title: t('salary_registrations.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/salary-registration'}>{employee.name}</Link>
            <small>{employee.position ? employee.position : ''}</small>
          </Headline>
        )
      },
    },
    {
      title: t('salary_registrations.table.header.salary'),
      dataIndex: '',
      key: 'xSalary',
      width: 250,
      render: (employee: EmployeeRow) => {
        if (employee.loading) {
          return <span className="salary-registration-cell-loading">{t('salary_registrations.table.loading')}</span>
        }
        if (employee.salaryRegistrations.length === 0) {
          return null
        }
        return (
          <div className="salary-registration-salary-line">
            {employee.salaryRegistrations.map((salaryReg) => {
              return <div key={salaryReg.key}>{salaryReg.salaryName}</div>
            })}
          </div>
        )
      },
    },
    {
      title: t('salary_registrations.table.header.rate'),
      dataIndex: '',
      key: 'xRate',
      width: 150,
      render: (employee: EmployeeRow) => {
        if (!employee.canSeeSalaryRates) {
          return null
        }
        if (employee.loading) {
          return <span className="salary-registration-cell-loading">{t('salary_registrations.table.loading')}</span>
        }
        if (employee.salaryRegistrations.length === 0) {
          return null
        }
        return (
          <div className="salary-registration-salary-line">
            {employee.salaryRegistrations.map((salaryReg) => {
              return <div key={salaryReg.key}>{formatCurrency(salaryReg.rate, 2)}</div>
            })}
          </div>
        )
      },
    },
    {
      title: t('salary_registrations.table.header.quantity'),
      dataIndex: '',
      key: 'xQuantity',
      width: 150,
      render: (employee: EmployeeRow) => {
        if (employee.loading) {
          return <span className="salary-registration-cell-loading">{t('salary_registrations.table.loading')}</span>
        }
        if (employee.salaryRegistrations.length === 0) {
          return formatNumber(0, 2)
        }
        return (
          <div className="salary-registration-salary-line">
            {employee.salaryRegistrations.map((salaryReg) => {
              return <div key={salaryReg.key}>{formatNumber(salaryReg.quantity, 2)}</div>
            })}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xHasUnapproved',
      width: 200,
      render: (employee: EmployeeRow) => {
        if (employee.type === 'Coarse') {
          return ''
        }
        if (!employee.canApproveObjects) {
          return ''
        }
        if (salaryRegistrations.saving) {
          return <span className="salary-registration-cell-loading">{t('salary_registrations.table.saving')}</span>
        }
        if (employee.hasUnapproved) {
          return (
            <span className="approve-link" onClick={approveAll(employee.id)}>
              {t('salary_registrations.table.approve_hours')}
            </span>
          )
        }
        return t('salary_registrations.table.all_approved')
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (employee: EmployeeRow) => {
        if (!employee.canEditObjects) {
          return ''
        }
        if (employee.type === 'Coarse') {
          return (
            <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
              <DumbLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setCoarseVisibility(employee.id)
                }}
              >
                <Icon type="user" /> {t('salary_registrations.table.action.register')}
              </DumbLink>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/salary-registration'}>
                <Icon type="paperWithPencil" /> {t('salary_registrations.table.action.edit')}
              </Link>
            </ContextMenu>
          )
        }
        return (
          <Tooltip placement="top" title={t('salary_registrations.table.action.edit')}>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/salary-registration'}>
              <Icon type="paperWithPencil" />
            </Link>
          </Tooltip>
        )
      },
    },
  ]

  const getSimpleEmployees = (): EmployeeRow[] => {
    const company = companies.company
    if (!company) {
      return []
    }
    const salaryCycleID = getSalaryCycleID(company, salaryCycles)
    return employees.employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract &&
          employee.activeContract.salaryCycleID === salaryCycleID &&
          employee.activeContract.remuneration &&
          employee.activeContract.remuneration.salary.some((salary) =>
            props.salaryTypes.some((type) => type.id === salary.salaryTypeID && type.class === 'SupplementVaried')
          ) &&
          employee.activeContract.salaryRegistrationMethodType === 'Coarse'
      )
      .map((employee): EmployeeRow => {
        let salaryRegistrations: GenericSalaryRegistration[] = []
        const loading = !employee.activeContract || !employee.activeContract.remuneration
        if (!loading) {
          salaryRegistrations = coarseSalaryRegistrations.coarseSalaryRegistrations
            .filter((reg) => reg.employeeID === employee.id)
            .reduce((registrations, reg) => {
              if (!employee.activeContract || !employee.activeContract.remuneration) {
                return registrations
              }
              const salary = employee.activeContract.remuneration.salary.find(
                (salary) => salary.salaryTypeID === reg.salaryTypeID
              )
              if (!salary) {
                return registrations
              }
              const row: GenericSalaryRegistration = {
                key: reg.id,
                salaryTypeID: salary.salaryTypeID,
                quantity: reg.quantity,
                salaryName: translateGroupTitle(salary),
                rate: salary.rate,
              }
              return [...registrations, row]
            }, salaryRegistrations)
        }

        return {
          key: employee.id,
          id: employee.id,
          type: 'Coarse',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          loading,
          salaryRegistrations,
          hasUnapproved: false,
          canSeeSalaryRates: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'SeeSalaryRates'
          ),
          canApproveObjects: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'ApproveObjects'
          ),
          canEditObjects: hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const getDetailedEmployees = (): EmployeeRow[] => {
    const company = companies.company
    if (!company) {
      return []
    }
    return employees.employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract &&
          employee.activeContract.remuneration &&
          employee.activeContract.remuneration.salary.some((salary) =>
            props.salaryTypes.some((type) => type.id === salary.salaryTypeID && type.class === 'SupplementVaried')
          ) &&
          employee.activeContract.salaryRegistrationMethodType === 'Detailed'
      )
      .map((employee): EmployeeRow => {
        let hasUnapproved = false
        let salaryRegistrations: GenericSalaryRegistration[] = []
        const loading = !employee.activeContract || !employee.activeContract.remuneration
        if (!loading) {
          salaryRegistrations = props.salaryRegistrations.salaryRegistrations
            .filter((reg) => reg.employeeID === employee.id)
            .reduce((registrations, reg) => {
              if (!employee.activeContract || !employee.activeContract.remuneration) {
                return registrations
              }
              const salary = employee.activeContract.remuneration.salary.find(
                (salary) => salary.salaryTypeID === reg.salaryTypeID
              )
              if (!salary) {
                return registrations
              }
              if (!reg.approved) {
                hasUnapproved = true
              }
              const idx = registrations.findIndex((reg) => reg.salaryTypeID === salary.salaryTypeID)
              if (idx !== -1) {
                registrations[idx].quantity += reg.quantity
                return registrations
              }
              const row: GenericSalaryRegistration = {
                key: reg.id,
                salaryTypeID: salary.salaryTypeID,
                quantity: reg.quantity,
                salaryName: translateGroupTitle(salary),
                rate: salary.rate,
              }
              return [...registrations, row]
            }, salaryRegistrations)
        }

        return {
          key: employee.id,
          id: employee.id,
          type: 'Detailed',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          loading,
          salaryRegistrations,
          hasUnapproved,
          canSeeSalaryRates: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'SeeSalaryRates'
          ),
          canApproveObjects: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'ApproveObjects'
          ),
          canEditObjects: hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const loading =
    !props.coarseSalaryRegistrations.loaded || (props.salaryRegistrations.loading && !props.salaryRegistrations.loaded)
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            {
              loading: !props.coarseSalaryRegistrations.loaded,
              text: t('loading.reducer.coarse_salary_registrations'),
            },
            {
              loading: props.salaryRegistrations.loading && !props.salaryRegistrations.loaded,
              text: t('loading.reducer.salary_registrations'),
            },
          ])}
        />
      </div>
    )
  }

  const frequencies = getSalaryFrequencies()

  const company = companies.company
  if (!company) {
    return null
  }
  const salaryCycle = salaryCycles.find((cycle) => cycle.id === getSalaryCycleID(company, salaryCycles))

  return (
    <div className="salary-registration">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <EmployeeFilter
          departments={props.departments}
          companyUser={props.companyUsers.companyUser}
          onFilterChange={(filter) => setFilter(filter)}
        />
      </TitleMenu>
      <Title>
        {t('salary_registrations.table.coarse.title')}
        {frequencies.length > 1 && (
          <Select
            placeholder={t('salary_registrations.table.select_period')}
            value={frequency}
            onChange={(v) => changeFrequency(v as SalaryFrequency)}
          >
            {frequencies.map((frequency) => (
              <Select.Option key={frequency} value={frequency}>
                {formatSalaryFrequency(frequency)}
              </Select.Option>
            ))}
          </Select>
        )}
      </Title>
      <Table
        columns={columns}
        dataSource={getSimpleEmployees()}
        pagination={false}
        onRowClick={(employee: EmployeeRow) => {
          setCoarseVisibility(employee.id)
        }}
      />

      {hasDepartmentPermission(props.companyUsers.companyUser, undefined, 'EditObjects') &&
        props.salaryRegistrations.salaryRegistrations.some((timeReg) => timeReg.state === 'Pending') && (
          <TitleMenu>
            <Button
              danger
              className="gtm-delete-company-salaryregs"
              onClick={() => setDeleteCompanySalaryRegistrations(true)}
            >
              {t('salary_registrations.table.detailed.delete_all')}
            </Button>
          </TitleMenu>
        )}
      <Title>{t('salary_registrations.table.detailed.title')}</Title>
      <Table
        columns={columns}
        dataSource={getDetailedEmployees()}
        pagination={false}
        onRowClick={(employee: EmployeeRow) => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/salary-registration')
        }}
      />

      <Modal
        key={`modal-${modalKey}`}
        visible={!!showCoarse}
        onOk={() => setCoarseVisibility(false)}
        onCancel={() => setCoarseVisibility(false)}
        width={582}
        footer={null}
      >
        <CoarseSalaryRegistrationModal
          visible={!!showCoarse}
          employee={props.employees.employees.find((employee) => employee.id === showCoarse)}
          contracts={props.contracts}
          coarseSalaryRegistrations={props.coarseSalaryRegistrations}
          salaryCycles={props.salaryCycles}
          salaryCycle={salaryCycle}
          salaryTypes={props.salaryTypes}
          addAlert={props.addAlert}
          addContract={props.addContract}
          updateContract={props.updateContract}
          updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
        />
      </Modal>

      <Modal
        key={`delete-${modalKey}`}
        visible={showDeleteCompanySalaryRegistrations}
        onOk={() => setDeleteCompanySalaryRegistrations(false)}
        onCancel={() => setDeleteCompanySalaryRegistrations(false)}
        width={582}
        footer={null}
      >
        <DeleteCompanySalaryRegistrationsModal
          visible={showDeleteCompanySalaryRegistrations}
          company={company}
          salaryRegistrations={props.salaryRegistrations.salaryRegistrations}
          closeModal={() => setDeleteCompanySalaryRegistrations(false)}
          deleteSalaryRegistrationBulk={props.deleteSalaryRegistrationBulk}
        />
      </Modal>
    </div>
  )
}
