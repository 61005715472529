import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Bank from '../../model/bank'
import Company from '../../model/company'
import { AffiliationType } from '../../model/employee'
import { TransferDestinationType } from '../../model/transfer'
import IncomeType from '../../types/income-type'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatIncomeType, formatPreferredTaxCardType } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import TitleMenu from '../elements/TitleMenu'
import SettingsLock from '../widgets/SettingsLock'

type Props = {
  company: Company
  banks: List<Bank>
  canUseNemKonto: boolean
  affiliationType: AffiliationType

  preferredTaxCardType: PreferredTaxCardType
  incomeType: IncomeType
  transferDestinationType: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
}

type Fields = {
  preferredTaxCardType: PreferredTaxCardType
  incomeType: IncomeType
  transferDestinationType?: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  useManualTransfer: boolean
}

export type FinancialDataResult = {
  readonly step: 'FinancialData'
  preferredTaxCardType: PreferredTaxCardType
  incomeType: IncomeType
  transferDestinationType: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
}

function FinancialDataForm(props: Props & FormComponentProps<Fields, FinancialDataResult>): ReactElement | null {
  type State = {
    bankName?: string
    bankLogo?: string
  }

  const [state, setState] = useState<State>({})

  const getBankLogo = (sortCode: string): State => {
    const newState: State = {
      bankName: undefined,
      bankLogo: undefined,
    }
    const bank = props.banks.find((b) => b.sortCode === sortCode)
    if (bank) {
      newState.bankName = bank.bankName
      newState.bankLogo = bank.logoImageURL
    }
    return newState
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = e.target.value
    if (num.length === 4) {
      setState(getBankLogo(num))
    }
  }

  const { decorateField, getFieldValue } = props
  const isFreelancer = props.affiliationType === 'Freelancer'

  return (
    <div>
      {state.bankLogo && (
        <TitleMenu>
          <img src={state.bankLogo} className="employees-add-bank-logo" alt={state.bankName} />
        </TitleMenu>
      )}
      <Subtitle>{t('employee.add.financial_data.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('bankRegistrationNumber', {
            placeholder: t('employee.add.financial_data.bank_registration_number'),
            validate: (val) => {
              if (
                (props.canUseNemKonto && getFieldValue('transferDestinationType') !== 'DK Account') ||
                (!props.canUseNemKonto && !!getFieldValue('useManualTransfer'))
              ) {
                return null
              }
              if (!val) {
                return t('employee.add.financial_data.bank_registration_number.required')
              }
              if (!val.match(/^[0-9]{4}$/)) {
                return t('employee.add.financial_data.bank_registration_number.invalid')
              }
              return null
            },
          })(<Input onChange={handleChange} />)}
        </Col>
        <Col span={12}>
          {decorateField('bankAccountNumber', {
            placeholder: t('employee.add.financial_data.bank_account_number'),
            validate: (val) => {
              if (
                (props.canUseNemKonto && getFieldValue('transferDestinationType') !== 'DK Account') ||
                (!props.canUseNemKonto && !!getFieldValue('useManualTransfer'))
              ) {
                return null
              }
              if (!val) {
                return t('employee.add.financial_data.bank_account_number.required')
              }
              if (!val.match(/^[0-9]{6,10}$/)) {
                return t('employee.add.financial_data.bank_account_number.invalid')
              }
              return null
            },
          })(<Input />)}
          {!props.canUseNemKonto && (
            <Form.Item style={{ float: 'right', marginTop: -25 }}>
              <label htmlFor="useManualTransfer">&nbsp;</label>
              <div className="ant-switch-wrapper">
                {decorateField('useManualTransfer', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('employee.add.financial_data.use_manual_transfer')}</span>
              </div>
            </Form.Item>
          )}
        </Col>
      </Row>
      {props.canUseNemKonto && (
        <Row>
          <Col span={24}>
            <Form.Item>
              {decorateField('transferDestinationType', {
                title: t('employee.add.financial_data.transfer_destination_type'),
              })(
                <Radio.Group>
                  <Radio value="DK NemKonto">
                    {t('employee.add.financial_data.transfer_destination_type.easy_account')}
                  </Radio>
                  <Radio value="DK Account">
                    {t('employee.add.financial_data.transfer_destination_type.dk_account')}
                  </Radio>
                  <Radio value="None">{t('employee.add.financial_data.transfer_destination_type.none')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {!isFreelancer && (
        <Row>
          <Col span={12}>
            {decorateField('preferredTaxCardType', {
              title: t('employee.add.financial_data.preferred_tax_card_type'),
              placeholder: t('employee.add.financial_data.preferred_tax_card_type.placeholder'),
              validate: (val) => (!val ? t('employee.add.financial_data.preferred_tax_card_type.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                <Select.Option value={PreferredTaxCardType.MAIN_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.MAIN_CARD)}
                </Select.Option>
                <Select.Option value={PreferredTaxCardType.SECONDARY_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.SECONDARY_CARD)}
                </Select.Option>
              </Select>
            )}
          </Col>
          <Col span={12}>
            <SettingsLock setting={'MoreIncomeTypes'} description={t('employee.add.financial_data.income_type.lock')}>
              {decorateField('incomeType', {
                title: t('employee.add.financial_data.income_type'),
                placeholder: t('employee.add.financial_data.income_type.placeholder'),
                validate: (val) => (!val ? t('employee.add.financial_data.income_type.required') : null),
              })(
                <Select dropdownMatchSelectWidth={false}>
                  <Select.Option value={IncomeType.DK_SALARY_INCOME}>
                    {formatIncomeType(IncomeType.DK_SALARY_INCOME)}
                  </Select.Option>
                  <Select.Option value={IncomeType.DK_RESEARCH_INCOME}>
                    {formatIncomeType(IncomeType.DK_RESEARCH_INCOME)}
                  </Select.Option>
                  <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE}>
                    {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE)}
                  </Select.Option>
                  <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE_AM}>
                    {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE_AM)}
                  </Select.Option>
                  <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE_ABROAD}>
                    {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE_ABROAD)}
                  </Select.Option>
                </Select>
              )}
            </SettingsLock>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" className="gtm-add-employee-save">
            {t('employee.add.financial_data.submit')}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack}>
            {t('employee.add.financial_data.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, FinancialDataResult>({
  mapPropsToFields: (props) => {
    return {
      preferredTaxCardType: props.preferredTaxCardType,
      incomeType: props.incomeType,
      useManualTransfer: props.transferDestinationType === 'None',
      transferDestinationType: props.transferDestinationType
        ? props.transferDestinationType
        : props.canUseNemKonto
        ? 'DK NemKonto'
        : undefined,
      bankRegistrationNumber: props.bankRegistrationNumber,
      bankAccountNumber: props.bankAccountNumber,
    }
  },
  onSubmit: (values, props) => {
    return {
      step: 'FinancialData',
      preferredTaxCardType: values.preferredTaxCardType,
      incomeType: values.incomeType,
      transferDestinationType: props.canUseNemKonto
        ? values.transferDestinationType || 'DK NemKonto'
        : values.useManualTransfer
        ? 'None'
        : 'DK Account',
      bankRegistrationNumber: values.bankRegistrationNumber ? values.bankRegistrationNumber.trim() : undefined,
      bankAccountNumber: values.bankAccountNumber ? values.bankAccountNumber.trim() : undefined,
    }
  },
})(FinancialDataForm)
