import { addDays, getISOWeek } from 'date-fns'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { Day } from '../../model/types'
import { formatDate } from '../../utils/date-utils'
import { formatDay, getStartOfWeekForWorkCycleWeek, week } from '../../utils/day-utils'
import {
  decorateAnyFieldSignature,
  decorateFieldSignature,
  getFieldErrorSignature,
  getFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import {
  forceParseInputNumber,
  formatInputNumber,
  formatOrdinalNumber,
  parseInputNumber,
} from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import DatePicker from '../elements/date-picker/DatePicker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Tooltip from '../elements/tooltip'

type ContractWorktimeFields = {
  workCycleHours: string[]
  weeklyWorkDays: string
  periodWorkHours?: string
  keepWeekOpen: boolean
  workCycle: Record<`workDay${Day}`, boolean>[]
  workCycleAnchorDate?: Date
}

type Props<Fields extends ContractWorktimeFields> = {
  company: Company

  decorateField: decorateFieldSignature<Fields>
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  getFieldError: getFieldErrorSignature<Fields>
}

export function formWorkCycleToContractWorkCycle(cycle: Record<`workDay${Day}`, boolean>[]): Day[][] {
  return cycle.reduce((list: Day[][], w, i) => {
    const row = week.filter((day) => cycle[i][`workDay${day}`])
    if (row.length === 0) {
      return list
    }
    return [...list, row]
  }, [])
}

export default function ContractWorktime<Fields extends ContractWorktimeFields>(
  props: Props<Fields>
): ReactElement | null {
  const { decorateField, decorateAnyField, getFieldValue, getFieldError } = props

  const allowMultipleWeeks = props.company.settingsEnabled.includes('EnableMultipleWorkWeeks')

  const updateWeeklyWorkDays = (
    workCycle: Record<`workDay${Day}`, boolean>[],
    row?: number,
    rowDay?: Day,
    checked?: boolean
  ) => {
    const totalWorkDays = workCycle.reduce((c, w, i) => {
      return week.reduce((cc, day) => {
        if (i === row && day === rowDay) {
          return cc + (checked ? 1 : 0)
        }
        if (w[`workDay${day}`]) {
          return cc + 1
        }
        return cc
      }, c)
    }, 0)
    const weeklyWorkDays = totalWorkDays / workCycle.length
    props.setFieldValue('weeklyWorkDays', formatInputNumber(weeklyWorkDays, 2))
    props.setFieldValue('keepWeekOpen', true)
  }

  const handleWorkDaySelected = (row?: number, rowDay?: Day, checked?: boolean) => {
    const workCycle = props.getFieldValue('workCycle')
    updateWeeklyWorkDays(workCycle, row, rowDay, checked)
  }

  const addWeek = () => {
    const cycle = props.getFieldValue('workCycle')
    const newCycle = [...cycle, cycle[cycle.length - 1]]
    props.setFieldValue('workCycle', newCycle)
    updateWeeklyWorkDays(newCycle)
  }
  const removeWeek = (i: number) => {
    const newCycle = props.getFieldValue('workCycle').filter((_, j) => i !== j)
    props.setFieldValue('workCycle', newCycle)
    updateWeeklyWorkDays(newCycle)
  }

  const multipleWeeksOpen =
    allowMultipleWeeks || getFieldValue('keepWeekOpen') || parseInputNumber(getFieldValue('weeklyWorkDays')) !== 5

  return (
    <div>
      <Row>
        <Col span={8}>
          {(!allowMultipleWeeks || !multipleWeeksOpen) &&
            decorateAnyField('workCycleHours.0', {
              placeholder: t('employment_pay.edit.contract_worktime.weekly_hours'),
              suffix: t('employment_pay.edit.contract_worktime.weekly_hours.suffix'),
              validate: (val?: string) => {
                if (!val) {
                  return t('employment_pay.edit.contract_worktime.weekly_hours.required')
                }
                if (forceParseInputNumber(val) < 1 || forceParseInputNumber(val) > 168) {
                  return t('employment_pay.edit.contract_worktime.weekly_hours.invalid')
                }
                return null
              },
            })(<Input />)}
        </Col>
        <Col span={8}>
          {decorateField('weeklyWorkDays', {
            placeholder: t('employment_pay.edit.contract_worktime.weekly_work_days'),
            suffix: t('employment_pay.edit.contract_worktime.weekly_work_days.suffix'),
          })(<Input disabled={getFieldValue('keepWeekOpen') || allowMultipleWeeks} />)}
        </Col>
        <Col span={8}>
          {decorateField('periodWorkHours', {
            title: t('employment_pay.edit.contract_worktime.period_work_hours'),
            placeholder: t('employment_pay.edit.contract_worktime.period_work_hours.placeholder'),
            suffix: t('employment_pay.edit.contract_worktime.period_work_hours.suffix'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.toString().match(/^[0-9,.]+$/) || forceParseInputNumber(val) < 0) {
                return t('employment_pay.edit.contract_worktime.period_work_hours.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      {multipleWeeksOpen && (
        <Subcard style={{ padding: '10px 15px 5px 15px', marginBottom: '10px' }}>
          {!allowMultipleWeeks && (
            <>
              <Row>
                <Col span={24}>
                  <p style={{ marginBottom: '5px' }}>{t('employment_pay.edit.contract_worktime.work_days.note')}</p>
                </Col>
              </Row>
              <Row>
                {week.map((day) => {
                  return (
                    <Col span={3} key={day}>
                      {decorateAnyField(`workCycle.0.workDay${day}`, {
                        valueOnChecked: true,
                        noBlur: true,
                        onAfterChange: (e) => handleWorkDaySelected(0, day, e.target.checked),
                      })(<Checkbox>{formatDay(day)}</Checkbox>)}
                    </Col>
                  )
                })}
              </Row>
            </>
          )}
          {allowMultipleWeeks && (
            <>
              <Row>
                <Col span={24}>
                  <p style={{ marginBottom: '5px' }}>
                    {t('employment_pay.edit.contract_worktime.work_days.note.multiple_weeks')}
                  </p>
                </Col>
              </Row>
              {getFieldValue('workCycle').map((w, i) => {
                let anchorDateContainer = null
                if (i === 1) {
                  anchorDateContainer = (
                    <Row>
                      <Col span={16}>
                        {getFieldError('workCycleAnchorDate') && (
                          <Alert type={'error'} message={getFieldError('workCycleAnchorDate')} />
                        )}
                        <p>{t('employment_pay.edit.contract_worktime.work_cycle_anchor_date.description')}</p>
                      </Col>
                      <Col span={8}>
                        {decorateField('workCycleAnchorDate', {
                          skipWrapper: true,
                          skipLabel: true,
                          validate: (val) => {
                            if (!val) {
                              return t('employment_pay.edit.contract_worktime.work_cycle_anchor_date.required')
                            }
                            return null
                          },
                        })(<DatePicker allowClear={false} />)}
                      </Col>
                    </Row>
                  )
                }
                const weekStart = getStartOfWeekForWorkCycleWeek(
                  formWorkCycleToContractWorkCycle(getFieldValue('workCycle')),
                  getFieldValue('workCycleAnchorDate'),
                  i
                )
                let whichWeekNote = null
                if (weekStart) {
                  whichWeekNote = t('employment_pay.edit.contract_worktime.work_cycle.which_week', {
                    weekNo: getISOWeek(weekStart),
                    start: formatDate(weekStart),
                    end: formatDate(addDays(weekStart, 6)),
                  })
                }
                return (
                  <React.Fragment key={`week-${i}`}>
                    {anchorDateContainer}
                    {getFieldValue('workCycle').length > 1 && (
                      <Row>
                        <Col span={24}>
                          {t('employment_pay.edit.contract_worktime.work_cycle.week_no', {
                            number: formatOrdinalNumber(i + 1),
                          })}
                          {whichWeekNote && <>: {whichWeekNote}</>}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col span={24} className="contract-worktime-week-row">
                        {week.map((day) => {
                          return (
                            <React.Fragment key={day}>
                              {decorateAnyField(`workCycle.${i}.workDay${day}`, {
                                valueOnChecked: true,
                                noBlur: true,
                                onAfterChange: (e) => handleWorkDaySelected(i, day, e.target.checked),
                              })(<Checkbox>{formatDay(day)}</Checkbox>)}
                            </React.Fragment>
                          )
                        })}
                        {decorateAnyField(`workCycleHours.${i}`, {
                          placeholder: t('employment_pay.edit.contract_worktime.weekly_hours'),
                          suffix: t('employment_pay.edit.contract_worktime.weekly_hours.suffix'),
                          skipLabel: false,
                          skipWrapper: false,
                          validate: (val?: string) => {
                            if (!val) {
                              return t('employment_pay.edit.contract_worktime.weekly_hours.required')
                            }
                            if (forceParseInputNumber(val) < 1 || forceParseInputNumber(val) > 168) {
                              return t('employment_pay.edit.contract_worktime.weekly_hours.invalid')
                            }
                            return null
                          },
                        })(<Input />)}
                        <Tooltip title={t('employment_pay.edit.contract_worktime.remove_week')}>
                          <span onClick={() => removeWeek(i)} className="contract-worktime-week-row-remove">
                            <Icon type="xSign" />
                          </span>
                        </Tooltip>
                      </Col>
                    </Row>
                  </React.Fragment>
                )
              })}
              <Button onClick={() => addWeek()}>{t('employment_pay.edit.contract_worktime.add_week')}</Button>
            </>
          )}
        </Subcard>
      )}
    </div>
  )
}
