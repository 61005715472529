import { List } from 'immutable'
import React from 'react'

import Employee from '../../../model/employee'
import Remuneration from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementBalance from '../../../model/supplementBalance'
import SupplementType, { SupplementTypeName } from '../../../model/supplementType'
import { AdjustmentOperation, DateFormat } from '../../../model/types'
import { SupplementAdjustmentReducer } from '../../../reducers/supplementAdjustments'
import { formatDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatSupplementTypeName } from '../../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber } from '../../../utils/number-utils'
import { getDispositionDateList } from '../../../utils/one-time-pay-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Radio from '../../elements/radio'
import Select from '../../elements/select'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  supplementAdjustmentID?: string
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: List<SupplementBalance>
  supplementTypes: List<SupplementType>
  remuneration: Remuneration
  salaryCycle: SalaryCycle
}

type Fields = {
  dispositionDate?: DateFormat
  supplementTypeName?: SupplementTypeName
  operation: AdjustmentOperation
  earned?: string
  paidOut?: string
  writtenOff?: string
}

export type SupplementAdjustmentResult = {
  dispositionDate: DateFormat
  supplementTypeName: SupplementTypeName
  operation: AdjustmentOperation
  earned?: number
  paidOut?: number
  writtenOf?: number
}

function SupplementAdjustmentEditForm(props: Props & FormComponentProps<Fields, SupplementAdjustmentResult>) {
  const getSupplementTypes = () => {
    return props.supplementTypes.filter(
      (supplementType) =>
        props.remuneration.supplements.some((supplement) => supplement.typeID === supplementType.id) ||
        props.supplementBalances.some((balance) => balance.supplementTypeName === supplementType.name)
    )
  }

  const valueValidate = (val?: string) => {
    if (val) {
      return null
    }
    if (!props.getFieldValue('earned') && !props.getFieldValue('paidOut') && !props.getFieldValue('writtenOff')) {
      return t('balance_adjustment_tab.supplements.edit.form.value.need_one')
    }
    return null
  }

  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('dispositionDate', {
            placeholder: t('balance_adjustment_tab.supplements.edit.form.disposition_date'),
            validate: (val) =>
              !val ? t('balance_adjustment_tab.supplements.edit.form.disposition_date.required') : null,
          })(
            <Select dropdownMatchSelectWidth={false}>
              {getDispositionDateList(props.salaryCycle.salaryPeriods, []).map((date) => {
                return (
                  <Select.Option key={date} value={date}>
                    {formatDate(date)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('supplementTypeName', {
            placeholder: t('balance_adjustment_tab.supplements.edit.form.supplement_type_name'),
            validate: (val) =>
              !val ? t('balance_adjustment_tab.supplements.edit.form.supplement_type_name.required') : null,
          })(
            <Select dropdownMatchSelectWidth={false}>
              {getSupplementTypes().map((supplementType) => {
                return (
                  <Select.Option key={supplementType.id} value={supplementType.name}>
                    {formatSupplementTypeName(supplementType.name)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('operation', {
            placeholder: t('balance_adjustment_tab.supplements.edit.form.operation'),
            validate: (val) => (!val ? t('balance_adjustment_tab.supplements.edit.form.operation.required') : null),
          })(
            <Radio.Group>
              <Radio value="Increase">{t('balance_adjustment_tab.supplements.edit.form.operation.increase')}</Radio>
              <Radio value="Reduce">{t('balance_adjustment_tab.supplements.edit.form.operation.reduce')}</Radio>
              <Radio value="Override">{t('balance_adjustment_tab.supplements.edit.form.operation.override')}</Radio>
            </Radio.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {decorateField('earned', {
            placeholder: t('balance_adjustment_tab.supplements.edit.form.earned'),
            validate: valueValidate,
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.supplementAdjustments.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, SupplementAdjustmentResult>({
  mapPropsToFields: (props) => {
    const salaryPeriod = getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)
    const fields: Fields = {
      operation: 'Increase',
      dispositionDate: salaryPeriod?.dispositionDate,
    }
    if (props.supplementAdjustmentID) {
      const supplementAdjustment = props.supplementAdjustments.supplementAdjustments.find(
        (supplementAdjustment) => supplementAdjustment.id === props.supplementAdjustmentID
      )
      if (supplementAdjustment) {
        fields.supplementTypeName = supplementAdjustment.supplementTypeName
        fields.operation = supplementAdjustment.operation
        fields.dispositionDate = supplementAdjustment.dispositionDate
        fields.earned = formatInputNumber(supplementAdjustment.earned)
        fields.writtenOff = formatInputNumber(supplementAdjustment.writtenOff)
        fields.paidOut = formatInputNumber(supplementAdjustment.paidOut)
      }
    }
    return fields
  },
  onSubmit: (values) => ({
    ...values,
    dispositionDate: values.dispositionDate!,
    supplementTypeName: values.supplementTypeName!,
    earned: values.earned ? forceParseInputNumber(values.earned) : undefined,
    writtenOff: values.writtenOff ? forceParseInputNumber(values.writtenOff) : undefined,
    paidOut: values.paidOut ? forceParseInputNumber(values.paidOut) : undefined,
  }),
})(SupplementAdjustmentEditForm)
