import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getEmployees, updateEmployee } from '../actions/employees'
import { getSalaryTypes } from '../actions/salary-types'
import { approveSwipes, deleteSwipe, getSwipes } from '../actions/swipes'
import SwipeOverviewComponent from '../components/swipe/SwipeOverview'
import Employee from '../model/employee'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { EmployeeReducer } from '../reducers/employees'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { SwipeReducer } from '../reducers/swipes'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  employees: EmployeeReducer
  swipes: SwipeReducer
  salaryTypes: SalaryTypeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getSwipes: () => void
  approveSwipes: (swipeIDs: string[]) => void
  deleteSwipe: (swipeID: string) => void
  updateEmployee: (employee: Employee) => void
  getEmployees: () => void
  getSalaryTypes: () => void
}

function SwipeOverview(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { employees, getEmployees, salaryTypes, getSalaryTypes, swipes, getSwipes } = props
  useEffect(() => {
    if (!employees.loading && !employees.loaded) {
      getEmployees()
    }
    if (!salaryTypes.loading && !salaryTypes.loaded) {
      getSalaryTypes()
    }
    if (!swipes.loading && !swipes.loaded) {
      getSwipes()
    }
  })

  const company = props.companies.company
  if (!company) {
    return null
  }

  return (
    <RegistrationLayout location={props.location}>
      <SwipeOverviewComponent
        subsection={splatString(props.params.splat)}
        alerts={props.alerts}
        company={company}
        employees={props.employees}
        swipes={props.swipes}
        salaryTypes={props.salaryTypes.salaryTypes}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        approveSwipes={props.approveSwipes}
        deleteSwipe={props.deleteSwipe}
        updateEmployee={props.updateEmployee}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    employees: state.employees,
    swipes: state.swipes,
    salaryTypes: state.salaryTypes,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getSwipes: getSwipes,
    approveSwipes: approveSwipes,
    deleteSwipe: deleteSwipe,
    updateEmployee: updateEmployee,
    getEmployees: getEmployees,
    getSalaryTypes: getSalaryTypes,
  }
)(SwipeOverview)
