import { TableColumn } from './Table'

export function treeMap<DataType extends Record<string, unknown>>(
  tree: TableColumn<DataType>[],
  mapper: (column: TableColumn<DataType>, index: number) => TableColumn<DataType>
): TableColumn<DataType>[] {
  return tree.map((node, index) => {
    return {
      ...mapper(node, index),
    }
  })
}

export function flatFilter<DataType>(
  tree: TableColumn<DataType>[],
  callback: (column: TableColumn<DataType>) => boolean
) {
  return tree.reduce((acc: TableColumn<DataType>[], node) => {
    if (callback(node)) {
      acc.push(node)
    }
    return acc
  }, [])
}
