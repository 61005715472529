import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { ReimbursementVoucherCreate } from '../../api/reimbursement-vouchers'
import Company from '../../model/company'
import Employee from '../../model/employee'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ReimbursementVoucherNewForm from './ReimbursementVoucherNewForm'

type Props = {
  visible: boolean
  company: Company
  employees: List<Employee>
  reimbursementVouchers: ReimbursementVoucherReducer

  addReimbursementVoucher: (o: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  newReimbursementVoucher: (id: string) => void
}

export default function ReimbursementVoucherNew(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [formError, setFormError] = useState<Error | null>(null)
  const [voucherID, setVoucherID] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const visible = props.visible
  const reimbursementVoucherError = props.reimbursementVouchers.error

  useEffect(() => {
    visibleComponentDidUpdate(visible, reimbursementVoucherError, error, setError)
  }, [visible, reimbursementVoucherError, error])

  const reimbursementVoucherList = props.reimbursementVouchers.reimbursementVouchers
  const { newReimbursementVoucher } = props

  useEffect(() => {
    if (!voucherID) {
      return
    }
    const voucher = reimbursementVoucherList.find((voucher) => voucher.id === voucherID)
    if (!voucher) {
      return
    }
    // make sure we only send them further once the voucher is actually ready
    if (voucher.approvalState === 'Ready') {
      setLoading(false)
      newReimbursementVoucher(voucherID)
    }
  }, [voucherID, reimbursementVoucherList, setLoading, newReimbursementVoucher])

  const handleSubmit = (fileID: string, employeeID?: string) => {
    if (!fileID || !employeeID) {
      return
    }
    setLoading(true)
    props
      .addReimbursementVoucher({
        companyID: props.company.id,
        employeeID: employeeID,
        fileID: fileID,
      })
      .then((res) => {
        if (!res) {
          return
        }
        setVoucherID(res.id)
      })
  }

  return (
    <Card className="reimbursement-voucher-modal">
      <Subtitle>{t('reimbursement_vouchers.new.title')}</Subtitle>
      {loading && <LoadingOverlay />}
      <p>&nbsp;</p>
      <p>{t('reimbursement_vouchers.new.intro')}</p>
      {(formError || error) && <Alert message={formatError(formError || error!)} type="error" showIcon />}
      <ReimbursementVoucherNewForm
        employees={props.employees}
        onSubmit={handleSubmit}
        onError={(e) => setFormError(e)}
      />
    </Card>
  )
}
