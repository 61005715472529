import RcPagination from 'rc-pagination'
import React, { ReactElement } from 'react'

import { getTranslationAntdStrings } from '../../../utils/language-utils'
import classNames from '../../antd/_util/classNames'
import Icon from '../icon'
import Select from '../select'

type Props = {
  prefixCls?: string
  selectPrefixCls?: string
  className?: string
  onChange?: (current: number) => void
  total?: number
  current?: number
  onShowSizeChange?: (current: number, pageSize: number) => void
}

export default function Pagination(props: Props): ReactElement | null {
  const { prefixCls = 'ant-pagination', selectPrefixCls = 'ant-select', className, ...restProps } = props

  const locale = getTranslationAntdStrings()
  return (
    <RcPagination
      {...restProps}
      prevIcon={<Icon type="chevronLeft" />}
      nextIcon={<Icon type="chevronRight" />}
      jumpPrevIcon={<Icon type="threeDots" />}
      jumpNextIcon={<Icon type="threeDots" />}
      prefixCls={prefixCls}
      selectPrefixCls={selectPrefixCls}
      className={classNames(className)}
      selectComponentClass={Select}
      locale={locale}
    />
  )
}
