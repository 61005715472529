import { subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CostCenterAccounting } from '../../model/accountingIntegration'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import { DateFormat } from '../../model/types'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { formatAPIDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { FormComponentProps } from '../../utils/form-utils'
import { withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { hasCostCenters } from '../../utils/one-time-pay-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import { getCurrencies } from '../../utils/validation-utils'
import Form from '../antd/form'
import Alert from '../elements/alert'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

const NO_COST_CENTER = 'NoCostCenter'

type Props = {
  disabled: boolean
  reimbursementVoucherID?: string
  company: Company
  companyUsers: List<CompanyUser>
  employees: List<Employee>
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
}

type Fields = {
  receiptDate: Date
  amount?: string
  currency?: string
  disburseAmount: string
  disburseable: boolean
  immediatePay: boolean
  expenseCategoryID?: string
  costCenterID?: string
  note: string
  cardNote?: string

  ready: boolean
  approved: boolean

  isForeignCurrency: boolean
  isReadOnly: boolean
  canDraft: boolean
}

export type ResultFields = {
  receiptDate: DateFormat
  amount?: number
  currency?: string
  disburseAmount: number
  disburseable: boolean
  immediatePay: boolean
  expenseCategoryID?: string
  costCenterID?: string
  note: string
  cardNote?: string
  ready: boolean
  approved: boolean
}

function ReimbursementVoucherEditForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const { decorateField, getFieldValue, getFieldError } = props
  const isReadOnly = getFieldValue('isReadOnly')
  const canDraft = getFieldValue('canDraft')

  const costCenterAccounting = hasCostCenters(props.costCenterAccounting, props.costCenters)
  const departmentAccounting = hasCostCenters(props.costCenterAccounting, props.costCenters, 'Departments')
  const isReceiptDateOld = isTimeBefore(getFieldValue('receiptDate'), subMonths(getDate(), 12))

  return (
    <Row>
      <Col span={12}>
        {props.getFormError()}
        <Row>
          <Col span={24}>
            {decorateField('expenseCategoryID', {
              placeholder: t('reimbursement_vouchers.edit.form.expense_category_id'),
              validate: (val) => {
                if (!val) {
                  return t('reimbursement_vouchers.edit.form.expense_category_id.required')
                }
                return null
              },
            })(
              <Select tabIndex={1} disabled={isReadOnly}>
                {props.expenseCategories
                  .filter(
                    (expenseCategory) =>
                      expenseCategory.active || expenseCategory.id === getFieldValue('expenseCategoryID')
                  )
                  .map((expenseCategory) => {
                    return (
                      <Select.Option key={expenseCategory.id} value={expenseCategory.id}>
                        {expenseCategory.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {decorateField('note', {
              placeholder: t('reimbursement_vouchers.edit.form.note'),
            })(<Input tabIndex={2} disabled={isReadOnly} />)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {isReceiptDateOld && (
              <Alert
                type={'warning'}
                message={t('reimbursement_vouchers.edit.form.warning.older_than_12_months')}
                showIcon
              />
            )}
            {decorateField('receiptDate', {
              placeholder: t('reimbursement_vouchers.edit.form.date'),
              validate: (val) => {
                if (!val) {
                  return t('reimbursement_vouchers.edit.form.date.required')
                }
                return null
              },
            })(<DatePicker allowClear={false} tabIndex={3} disabled={isReadOnly} style={{ width: '100%' }} />)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {decorateField('isForeignCurrency', {
              title: t('reimbursement_vouchers.edit.form.is_foreign_currency'),
            })(
              <Radio.Group disabled={isReadOnly}>
                <Radio value={true}>{t('reimbursement_vouchers.edit.form.is_foreign_currency.true')}</Radio>
                <Radio value={false}>{t('reimbursement_vouchers.edit.form.is_foreign_currency.false')}</Radio>
              </Radio.Group>
            )}
          </Col>
        </Row>
        {getFieldValue('isForeignCurrency') && (
          <Row>
            <Col span={24}>
              <Form.Item validateStatus={getFieldError('currency') || getFieldError('amount') ? 'error' : 'success'}>
                <label htmlFor="amount" title={t('reimbursement_vouchers.edit.form.foreign_amount')}>
                  {getFieldError('currency') ||
                    getFieldError('amount') ||
                    t('reimbursement_vouchers.edit.form.foreign_amount')}
                </label>
                <Input.Group compact className="country-code-phone-group">
                  {decorateField('currency', {
                    placeholder: t('reimbursement_vouchers.edit.form.currency'),
                    validate: (val) => {
                      if (!val) {
                        return t('reimbursement_vouchers.edit.form.currency.required')
                      }
                      if (getCurrencies().indexOf(val) === -1) {
                        return t('reimbursement_vouchers.edit.form.currency.invalid')
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(
                    <Select dropdownMatchSelectWidth={false} disabled={isReadOnly}>
                      {getCurrencies().map((prefix) => {
                        return (
                          <Select.Option key={prefix} value={prefix}>
                            {prefix}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                  {decorateField('amount', {
                    placeholder: t('reimbursement_vouchers.edit.form.amount'),
                    validate: (val) => {
                      if (!val) {
                        return null
                      }
                      if (!String(val).match(/^[0-9.,]+$/)) {
                        return t('reimbursement_vouchers.edit.form.amount.invalid')
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input tabIndex={4} disabled={isReadOnly} />)}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            {decorateField('disburseAmount', {
              placeholder: t('reimbursement_vouchers.edit.form.disburse_amount'),
              suffix: t('reimbursement_vouchers.edit.form.disburse_amount.suffix'),
              validate: (val) => {
                if (!val) {
                  return t('reimbursement_vouchers.edit.form.disburse_amount.required')
                }
                return null
              },
            })(<Input tabIndex={5} disabled={isReadOnly} />)}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {decorateField('disburseable', {
              title: t('reimbursement_vouchers.edit.form.disburseable'),
            })(
              <Radio.Group disabled={isReadOnly}>
                <Radio value={true}>{t('reimbursement_vouchers.edit.form.disburseable.true')}</Radio>
                <Radio value={false}>{t('reimbursement_vouchers.edit.form.disburseable.false')}</Radio>
              </Radio.Group>
            )}
          </Col>
        </Row>
        {(props.company.enableImmediatePay || getFieldValue('immediatePay')) && getFieldValue('disburseable') && (
          <Row>
            <Col span={24}>
              {decorateField('immediatePay', {
                title: t('reimbursement_vouchers.edit.form.immediate_pay'),
                valueOnChecked: true,
                noBlur: true,
              })(<Switch disabled={isReadOnly} />)}
            </Col>
          </Row>
        )}
        {!getFieldValue('disburseable') && (
          <Row>
            <Col span={24}>
              {decorateField('cardNote', {
                title: t('reimbursement_vouchers.edit.form.card_note'),
                placeholder: t('reimbursement_vouchers.edit.form.card_note.placeholder'),
              })(<Input tabIndex={6} disabled={isReadOnly} />)}
            </Col>
          </Row>
        )}
        {(costCenterAccounting || departmentAccounting) && (
          <Row>
            <Col span={24}>
              {decorateField('costCenterID', {
                title: costCenterAccounting
                  ? t('reimbursement_vouchers.edit.form.cost_center_id.cost_center')
                  : t('reimbursement_vouchers.edit.form.cost_center_id.department'),
              })(
                <Select dropdownMatchSelectWidth={false} disabled={isReadOnly}>
                  <Select.Option key={NO_COST_CENTER} value={NO_COST_CENTER}>
                    <i>
                      {costCenterAccounting
                        ? t('reimbursement_vouchers.edit.form.cost_center_id.cost_center.none')
                        : t('reimbursement_vouchers.edit.form.cost_center_id.department.none')}
                    </i>
                  </Select.Option>
                  {props.costCenters
                    .filter((costCenter) => costCenter.active)
                    .map((costCenter) => {
                      return (
                        <Select.Option key={costCenter.id} value={costCenter.id}>
                          {costCenter.name}
                        </Select.Option>
                      )
                    })}
                </Select>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            {decorateField('approved', {
              title: t('reimbursement_vouchers.edit.form.approved'),
            })(
              <Radio.Group disabled={isReadOnly}>
                <Radio value={true}>{t('reimbursement_vouchers.edit.form.approved.true')}</Radio>
                <Radio value={false}>{t('reimbursement_vouchers.edit.form.approved.false')}</Radio>
              </Radio.Group>
            )}
          </Col>
        </Row>
        {canDraft && !getFieldValue('approved') && (
          <Row>
            <Col span={24}>
              {decorateField('ready', {
                title: t('reimbursement_vouchers.edit.form.ready'),
              })(
                <Radio.Group disabled={isReadOnly}>
                  <Radio value={true}>{t('reimbursement_vouchers.edit.form.ready.true')}</Radio>
                  <Radio value={false}>{t('reimbursement_vouchers.edit.form.ready.false')}</Radio>
                </Radio.Group>
              )}
            </Col>
          </Row>
        )}
        {!isReadOnly && (
          <Row>
            <Col span={24}>
              <Button htmlType="submit" size="extra-extra-large" type="secondary">
                {t('form.button.save_changes')}
              </Button>
            </Col>
          </Row>
        )}
        {props.reimbursementVouchers.saving && <LoadingOverlay />}
      </Col>
      <Col span={12}>
        <Form.Item>
          <label>{t('reimbursement_vouchers.edit.form.voucher.title')}</label>
          <div className="reimbursement-voucher-image">
            <img src={secureUrl(`v2/reimbursementVouchers/${props.reimbursementVoucherID}/image`)} alt="" />
          </div>
          <label style={{ width: 440, textAlign: 'right' }}>
            <a
              href={secureUrl(`v2/reimbursementVouchers/${props.reimbursementVoucherID}/image`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('reimbursement_vouchers.edit.form.voucher.download')}
            </a>
          </label>
        </Form.Item>
      </Col>
    </Row>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props: Props): Fields => {
    const fields: Fields = {
      disburseAmount: '0',
      disburseable: true,
      immediatePay: false,
      receiptDate: getDate(),
      note: '',
      isForeignCurrency: false,
      isReadOnly: props.disabled,
      costCenterID: NO_COST_CENTER,
      ready: false,
      approved: false,
      canDraft: true,
    }
    if (props.reimbursementVoucherID) {
      const reimbursementVoucher = props.reimbursementVouchers.reimbursementVouchers.find(
        (reimbursementVoucher) => reimbursementVoucher.id === props.reimbursementVoucherID
      )
      if (reimbursementVoucher) {
        fields.receiptDate = getDate(reimbursementVoucher.receiptDate)
        fields.disburseAmount = formatInputNumber(reimbursementVoucher.disburseAmount, 2)
        fields.immediatePay = reimbursementVoucher.immediatePay
        if ((reimbursementVoucher.amount || reimbursementVoucher.currency) && reimbursementVoucher.currency !== 'DKK') {
          fields.isForeignCurrency = true
          fields.amount = formatInputNumber(reimbursementVoucher.amount, 2)
          fields.currency = reimbursementVoucher.currency
        }
        if (reimbursementVoucher.expenseCategory) {
          fields.expenseCategoryID = reimbursementVoucher.expenseCategory.id
        }
        fields.note = reimbursementVoucher.note
        fields.disburseable = reimbursementVoucher.disburseable
        fields.cardNote = reimbursementVoucher.cardNote
        fields.costCenterID = reimbursementVoucher.costCenterID ? reimbursementVoucher.costCenterID : NO_COST_CENTER
        fields.approved = reimbursementVoucher.approvalState === 'Approved'
        fields.ready = reimbursementVoucher.approvalState !== 'Draft'
        if (!fields.isReadOnly) {
          fields.isReadOnly =
            reimbursementVoucher.bookingState === 'Booked' ||
            reimbursementVoucher.bookingState === 'Daybooked' ||
            reimbursementVoucher.bookingState === 'Failed'
        }
        if (reimbursementVoucher.userID) {
          // if any company user is found for this user ID, then we cannot draft this voucher
          fields.canDraft = !props.companyUsers.some((user) => user.userID === reimbursementVoucher.userID)
          if (!fields.canDraft) {
            fields.ready = true // always ready if we cannot draft
          }
        }
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'disburseAmount':
      case 'amount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }), 2)
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      receiptDate: formatAPIDate(values.receiptDate),
      amount:
        values.isForeignCurrency && values.amount !== undefined ? forceParseInputNumber(values.amount) : undefined,
      currency: values.currency,
      disburseAmount: forceParseInputNumber(values.disburseAmount),
      disburseable: values.disburseable,
      immediatePay: values.immediatePay,
      expenseCategoryID: values.expenseCategoryID,
      costCenterID: values.costCenterID,
      note: values.note,
      cardNote: values.cardNote,
      ready: values.ready,
      approved: values.approved,
    }
    if (!values.isForeignCurrency) {
      result.amount = undefined
      result.currency = undefined
    }
    if (result.disburseable) {
      result.cardNote = undefined
    } else {
      result.immediatePay = false
    }
    if (result.costCenterID === NO_COST_CENTER) {
      result.costCenterID = undefined
    }
    return result
  },
})(ReimbursementVoucherEditForm)
