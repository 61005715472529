import React, { ReactElement } from 'react'

import AsynchronousTask from '../../model/asynchronousTask'
import { formatDateTime } from '../../utils/date-utils'
import { formatAsynchronousTaskStatus } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'

interface Props {
  title: string
  description: string
  task?: AsynchronousTask
  refreshTask: (e: React.MouseEvent) => void
}

export default function AsynchronousTaskStatusDisplay(props: Props): ReactElement | null {
  const { task } = props
  if (!task) {
    return null
  }

  return (
    <Row>
      <Col span={12}>
        <dl>
          <dt>{props.title}</dt>
          <dd>
            <strong>{formatAsynchronousTaskStatus(task.status)}</strong>
          </dd>
          <dt>{t('data_integration.async_task_status.started')}:</dt>
          <dd>
            <strong>
              {task.startedAt ? formatDateTime(task.startedAt) : t('data_integration.async_task_status.started.false')}
            </strong>
          </dd>
        </dl>
        <p>{props.description}</p>
      </Col>
      <Col span={12}>
        <Button.Icon type="roundedArrows" onClick={props.refreshTask} className="gtm-data-integrations-refresh-task" />
      </Col>
    </Row>
  )
}
