import { endOfYear, startOfYear } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import ReportButton from '../widgets/ReportButton'

type Props = {
  visible: boolean
  company: Company
  asynchronousTasks: List<AsynchronousTask>

  addAlert: addAlertSignature
}

type Fields = {
  fromDate: Date
  toDate: Date
}

function TransferReportModal(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <Card className="company-report-dst">
      <Title>{t('transfer_report.title')}</Title>
      <Row>
        <Col span={12}>
          {decorateField('fromDate', {
            skipWrapper: true,
            placeholder: t('transfer_report.from_date'),
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
        <Col span={12}>
          {decorateField('toDate', {
            skipWrapper: true,
            placeholder: t('transfer_report.to_date'),
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ReportButton
            state={1}
            asynchronousTasks={props.asynchronousTasks}
            type={'Excel'}
            addAlert={props.addAlert}
            getFields={() => {
              const from = getFieldValue('fromDate')
              const to = getFieldValue('toDate')
              return {
                type: 'Regular',
                companyID: props.company.id,
                report: 'PayRollTransfers',
                from: formatAPIDate(from),
                to: formatAPIDate(to),
              }
            }}
            buttonType="primary"
          />
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: () => ({ fromDate: startOfYear(getDate()), toDate: endOfYear(getDate()) }),
  onSubmit: (values) => values,
})(TransferReportModal)
