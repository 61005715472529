import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import OneTimePay, { OneTimePayType } from '../../model/oneTimePay'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatOneTimePayType } from '../../utils/format-utils'
import { formatCurrency, formatDisplayNumber } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  oneTimePays: OneTimePayReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>

  addAlert: addAlertSignature
  approveOneTimePays: (ids: string[]) => void
}

export default function OneTimePaysCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  type Sort = {
    sortOn?: 'employee' | 'date' | 'type' | 'title' | 'amount'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { oneTimePays, addAlert } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      if (oneTimePays.error) {
        addAlert('error', formatError(oneTimePays.error))
      }
      setApproving([]) //empty it
    }
  }, [previousOneTimePays, oneTimePays, addAlert, setApproving])

  const notOneTimePays: OneTimePayType[] = ['Reimbursement Voucher', 'Pension', 'Swipe']

  const pendingOneTimePays: OneTimePay[] = oneTimePays.oneTimePays
    .filter(
      (otp) =>
        !notOneTimePays.some((type) => otp.type === type) &&
        !otp.approved &&
        !approving.some((approve) => approve === otp.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, otp.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveOneTimePays([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingOneTimePays.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveOneTimePays(unapproved)
  }

  type OneTimePayRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    date: string
    type: string
    noAmount: boolean
    title?: string
    amount: string
    rate?: string
    units?: string
    original: OneTimePay
  }

  const columns = [
    {
      title: t('approve_tab.one_time_pays.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: OneTimePayRow) => {
        return (
          <ApproveCardEmployeeName
            {...row}
            linkTo={'/' + (row.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) + '/' + row.employeeID}
          />
        )
      },
    },
    { title: t('approve_tab.one_time_pays.header.date'), dataIndex: 'date', key: 'date', sorter: 'date' },
    { title: t('approve_tab.one_time_pays.header.type'), dataIndex: 'type', key: 'type', sorter: 'type' },
    { title: t('approve_tab.one_time_pays.header.title'), dataIndex: 'title', key: 'title', sorter: 'title' },
    {
      title: t('approve_tab.one_time_pays.header.amount'),
      dataIndex: '',
      key: 'xAmount',
      sorter: 'amount',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.noAmount) {
          return null
        }
        if (!oneTimePay.rate) {
          return oneTimePay.amount
        }
        return (
          <>
            {oneTimePay.amount}
            <br />
            <small>
              (
              {t('approve_tab.one_time_pays.table.amount_unit_rate', {
                unit: oneTimePay.units || formatDisplayNumber(0),
                rate: oneTimePay.rate,
              })}
              )
            </small>
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: OneTimePayRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.one_time_pays.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingOneTimePayRows: OneTimePayRow[] = pendingOneTimePays
    .map((otp) => {
      let title: string | undefined = otp.title
      const noAmount = otp.type === 'Free Phone' || otp.type === 'ATP'
      if (noAmount) {
        title = undefined
      }

      return {
        key: otp.id,
        id: otp.id,
        employeeID: otp.employeeID,
        ...getEmployeeRowEmployeeDetails(props.employees.get(otp.employeeID), props.departments, props.companyUsers),
        date: formatDate(otp.dispositionDate),
        type: formatOneTimePayType(otp.type),
        noAmount,
        amount: otp.type === 'Free Text' ? '' : formatCurrency(otp.amount, 2),
        amountNo: otp.amount,
        title,
        rate: otp.rate ? formatCurrency(otp.rate, 2) : undefined,
        units: otp.units ? formatDisplayNumber(otp.units) : undefined,
        original: otp,
      }
    })
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.date.localeCompare(b.date)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.date === j.date) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.date.localeCompare(j.date)
        case 'title':
          if (i.title === j.title) {
            if (i.employeeName === j.employeeName) {
              return a.date.localeCompare(b.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          if (i.title === undefined) {
            return 1000
          }
          if (j.title === undefined) {
            return -1000
          }
          return i.title.localeCompare(j.title)
        case 'type':
          if (i.type === j.type) {
            if (i.employeeName === j.employeeName) {
              return a.date.localeCompare(b.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.type.localeCompare(j.type)
        case 'amount':
          if (i.amountNo === j.amountNo) {
            if (i.employeeName === j.employeeName) {
              return a.date.localeCompare(b.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.amountNo - i.amountNo
        default:
          if (a.employeeName === b.employeeName) {
            if (a.date === b.date) {
              return a.type.localeCompare(b.type)
            }
            return a.date.localeCompare(b.date)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = ({ sorter }: TableChange<OneTimePayRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'type':
      case 'title':
      case 'amount':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingOneTimePays.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="extra-extra-large">
          {t('approve_tab.one_time_pays.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.one_time_pays.title')}</Title>
      <Table columns={columns} dataSource={pendingOneTimePayRows} onChange={tableChange} pagination={false} />
    </div>
  )
}
