import React, { PropsWithChildren, ReactElement, useContext } from 'react'
import { Link } from 'react-router'

import { UserReducer } from '../../reducers/user'
import { getAllParentsForCurrentPath, getMainMenuAdministrationMenu, MenuContext } from '../../utils/menu-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  user: UserReducer
}

type Props = PropsWithChildren<{
  location: RoutePropsLocation
}>

function AdministrationLayout(props: Reducers & Props): ReactElement | null {
  const { menu } = useContext(MenuContext)

  if (props.user.user?.uiSettings.sideMenu) {
    return <>{props.children}</>
  }

  const activePath = getAllParentsForCurrentPath(props.location.pathname).map((i) => i.id)
  return (
    <div className="with-submenu">
      <div className="submenu">
        {getMainMenuAdministrationMenu(menu).map((item) => {
          if (item.type === 'item-employee-selector') {
            return null
          }
          return (
            <Link key={item.id} to={item.link} className={activePath.includes(item.id) ? 'active' : ''}>
              {t(item.labelID)}
            </Link>
          )
        })}
      </div>
      <div className="with-submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    user: state.user,
  }),
  {}
)(AdministrationLayout)
