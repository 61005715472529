import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Props = {
  company: Company
}

export type InvoiceEmailFields = {
  invoiceEmail?: string
}

function InvoiceEmailModalForm(
  props: Props & FormComponentProps<InvoiceEmailFields, InvoiceEmailFields>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <p>{t('invoice_email.edit.form.intro.line_1')}</p>
      <p>{t('invoice_email.edit.form.intro.line_2')}</p>
      <Row>
        <Col span={24}>
          {decorateField('invoiceEmail', {
            placeholder: t('invoice_email.edit.form.invoice_email'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!validateEmail(val)) {
                return t('invoice_email.edit.form.invoice_email.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" className="gtm-transfer-settings-save">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, InvoiceEmailFields, InvoiceEmailFields>({
  mapPropsToFields: (props) => ({
    invoiceEmail: props.company.invoiceEmail,
  }),
  onSubmit: (values) => values,
})(InvoiceEmailModalForm)
