import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import TimeRegistration, { TimeRegistrationClass } from '../../model/timeRegistration'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

type Props = {
  visible: boolean
  company: Company
  timeRegistrations: List<TimeRegistration>
  closeModal: () => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass
  ) => Promise<boolean | void>
}

export default function DeleteCompanyTimeRegistrationsModal(props: Props): ReactElement | null {
  const deleteBulk = () => {
    props.deleteTimeRegistrationBulk(props.company.id, undefined, 'Hours').then(() => props.closeModal())
  }

  const count = props.timeRegistrations.filter(
    (timeReg) => timeReg.class === 'Hours' && timeReg.state === 'Pending'
  ).size
  return (
    <Card className="delete-company-time-registrations">
      <Subtitle>{t('time_registrations.tab.delete_modal.title')}</Subtitle>
      <p>{t('time_registrations.tab.delete_modal.intro', { count })}</p>
      <Button danger onClick={deleteBulk}>
        {t('time_registrations.tab.delete_modal.submit')}
      </Button>
    </Card>
  )
}
