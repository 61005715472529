import { addWeeks, differenceInWeeks, startOfWeek } from 'date-fns'

import { DateFormat, Day } from '../model/types'
import { formatDate, getDate } from './date-utils'
import { logWarning } from './log-utils'
import { t } from './translation-utils'

export const week: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export function dayToNumber(day: Day): number {
  switch (day) {
    case 'Monday':
      return 1
    case 'Tuesday':
      return 2
    case 'Wednesday':
      return 3
    case 'Thursday':
      return 4
    case 'Friday':
      return 5
    case 'Saturday':
      return 6
    case 'Sunday':
      return 0
  }
}

export function numberToDay(day: number): Day {
  switch (day) {
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    case 0:
      return 'Sunday'
    default:
      // should not happen
      throw new Error('Only numbers 0-6 are supported')
  }
}

export function formatDay(day: Day | string): string {
  switch (day) {
    case 'Monday':
      return t('day.monday')
    case 'Tuesday':
      return t('day.tuesday')
    case 'Wednesday':
      return t('day.wednesday')
    case 'Thursday':
      return t('day.thursday')
    case 'Friday':
      return t('day.friday')
    case 'Saturday':
      return t('day.saturday')
    case 'Sunday':
      return t('day.sunday')
    case 'Holiday':
      return t('day.holiday')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing day formatting: `' + day + '`')
      }
      return t('common.unknown')
  }
}

export function formatWorkCycle(workCycle: Day[][], workCycleHours: number[], anchorDate?: DateFormat): string {
  if (!workCycle) {
    return ''
  }
  let result = ''
  workCycle.forEach((days, i) => {
    if (i > 0) {
      result += ', '
    }
    if (workCycle.length > 1) {
      result += '['
    }
    result += days.map((day) => formatDay(day)).join(', ')
    if (workCycleHours.length === workCycle.length) {
      result += ' (' + t('unit.hour_count', { count: workCycleHours[i] }) + ')'
    }
    if (workCycle.length > 1) {
      result += ']'
    }
  })
  if (anchorDate) {
    result += ' (' + formatDate(anchorDate) + ')'
  }
  return result
}

export function getCurrentWorkWeek(workCycle?: Day[][], workCycleAnchorDate?: DateFormat, today = getDate()): Day[] {
  if (!workCycle || workCycle.length === 0) {
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  }
  if (workCycle.length === 1 || !workCycleAnchorDate) {
    return workCycle[0]
  }
  const anchorStart = startOfWeek(getDate(workCycleAnchorDate), { weekStartsOn: 1 })
  const todayStart = startOfWeek(today, { weekStartsOn: 1 })
  const diff = Math.abs(differenceInWeeks(anchorStart, todayStart))
  return workCycle[diff % workCycle.length]
}

export function getStartOfWeekForWorkCycleWeek(
  workCycle: Day[][] | undefined,
  anchorDate: Date | undefined,
  week: number,
  today = getDate()
): Date | undefined {
  if (!workCycle || !anchorDate) {
    return undefined
  }
  // let's find the first week
  const anchorStart = startOfWeek(getDate(anchorDate), { weekStartsOn: 1 })
  const todayStart = startOfWeek(today, { weekStartsOn: 1 })
  const cycleLength = workCycle.length
  const diffWeeks = differenceInWeeks(anchorStart, todayStart)
  const firstWeekStart = addWeeks(anchorStart, -1 * (Math.floor(diffWeeks / cycleLength) * cycleLength))
  // then add the number of weeks, we are requesting
  return addWeeks(firstWeekStart, week)
}
