import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import { AsynchronousScheduleCreationFields } from '../../model/asynchronousSchedule'
import AsynchronousTask from '../../model/asynchronousTask'
import AvailableDataIntegration from '../../model/availableDataIntegration'
import Company from '../../model/company'
import { ExternalPayRollImportMode } from '../../model/dataIntegration'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import PayRoll from '../../model/payRoll'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { AsynchronousScheduleReducer } from '../../reducers/asynchronousSchedules'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { CompanyDataIntegrationReducer } from '../../reducers/companyDataIntegration'
import { ContractReducer } from '../../reducers/contracts'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import TabNavigator from '../elements/tab-navigator'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CarAllowancesImport from './CarAllowancesImport'
import CustomFieldMapping from './CustomFieldMapping'
import EmployeesImport from './EmployeesImport'
import LeaveRegistrationExport from './LeaveRegistrationsExport'
import LeaveRegistrationImport from './LeaveRegistrationsImport'
import PayRollImport from './PayRollImport'
import ReimbursementsImport from './ReimbursementsImport'

import './DataIntegration.css'

type Props = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  asynchronousSchedules: AsynchronousScheduleReducer
  company: Company
  contracts: ContractReducer
  availableDataIntegrations: List<AvailableDataIntegration>
  companyDataIntegration: CompanyDataIntegrationReducer
  employees: List<Employee>
  payRolls: List<PayRoll>
  salaryTypes: List<SalaryType>
  salaryCycles: List<SalaryCycle>
  leaveTypes: List<LeaveType>
  timeRegistrations: TimeRegistrationReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteCompanyDataIntegration: () => void
  storeStagedImportData: (asynchronousTaskID: string, employeesToImport?: string[]) => Promise<AsynchronousTask | void>
  startPayRollImport: (
    companyID: string,
    salaryPeriodID: string,
    mode: ExternalPayRollImportMode
  ) => Promise<AsynchronousTask | void>
  startLeaveRegistrationImport: (
    companyID: string,
    salaryPeriodID: string,
    ignoreUnknowns: boolean
  ) => Promise<AsynchronousTask | void>
  startLeaveRegistrationExport: (companyID: string) => Promise<AsynchronousTask | void>
  saveLeaveRegistrationExport: (
    companyID: string,
    asynchronousTaskID: string,
    timeRegistrationIDs: string[]
  ) => Promise<AsynchronousTask | void>
  startReimbursementImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  startCarAllowanceImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  startEmployeeImport: (companyID: string, preferredTaxCard: PreferredTaxCardType) => Promise<AsynchronousTask | void>
  getAsynchronousTask: (asynchronousTaskID: string) => void
  getCompanyDataIntegration: () => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  getContracts: () => void
  addAsynchronousSchedule: (schedule: AsynchronousScheduleCreationFields) => void
  updateAsynchronousSchedule: (companyID: string, scheduleID: string, cron: string) => void
  deleteAsynchronousSchedule: (companyID: string, scheduleID: string) => void
}

type DataSheet =
  | 'EmployeesImport'
  | 'PayRollImport'
  | 'LeaveRegistrationsImport'
  | 'LeaveRegistrationsExport'
  | 'ReimbursementsImport'
  | 'CarAllowancesImport'
  | 'FieldMapping'
  | undefined

export default function DataIntegration(props: Props): ReactElement | null {
  const getDisplayName = (integrationType?: string): string => {
    let name = t('common.unknown')
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    if (integration) {
      name = integration.displayName
    }
    return name
  }

  const hasIntegration = (): boolean => {
    return !!props.companyDataIntegration.dataIntegration
  }
  const hasEmployeeImport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.employeeImport : false
  }
  const hasPayRollImport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.payRollImport : false
  }
  const hasLeaveRegistrationImport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.leaveRegistrationImport : false
  }
  const hasLeaveRegistrationExport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.leaveRegistrationExport : false
  }
  const hasReimbursementsImport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.reimbursementImport : false
  }
  const hasCarAllowancesImport = (integrationType?: string): boolean => {
    const integration = props.availableDataIntegrations.find((integration) => integration.type === integrationType)
    return integration ? integration.carAllowanceImport : false
  }
  const [dataSheet, setDataSheet] = useState<DataSheet>(() => {
    if (!hasIntegration()) {
      return undefined
    }
    const integrationType = props.companyDataIntegration.dataIntegration?.integrationType
    if (hasPayRollImport(integrationType)) {
      return 'PayRollImport'
    }
    if (hasLeaveRegistrationImport(integrationType)) {
      return 'LeaveRegistrationsImport'
    }
    if (hasLeaveRegistrationExport(integrationType)) {
      return 'LeaveRegistrationsExport'
    }
    return 'EmployeesImport'
  })

  const deleteIntegration = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteCompanyDataIntegration()
    }
  }

  const company = props.company
  if (!hasIntegration()) {
    return (
      <div>
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

        <Card>
          <Title>{t('data_integration.no_integration.intro.title')}</Title>
          <Row>
            <Col span={16} style={{ fontSize: 14 }}>
              <p>{t('data_integration.no_integration.intro.line_1')}</p>
              <p>
                {tx('data_integration.no_integration.intro.line_2', {
                  link: <a href="mailto:support@salary.dk">support@salary.dk</a>,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.DATA_PROVIDER + '/' + paths.ADD}>
                <Button type="secondary" className="gtm-setup-data-integration">
                  {t('data_integration.no_integration.button.create')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }

  const dataIntegration = props.companyDataIntegration.dataIntegration
  const integrationType = dataIntegration?.integrationType
  const displayName = getDisplayName(integrationType)
  const hasEmployees = hasEmployeeImport(integrationType)
  const hasTimeRegistrations = hasPayRollImport(integrationType)
  const hasLeaveRegistrations = hasLeaveRegistrationImport(integrationType)
  const hasLeaveRegistrationsExport = hasLeaveRegistrationExport(integrationType)
  const hasReimbursements = hasReimbursementsImport(integrationType)
  const hasCarAllowances = hasCarAllowancesImport(integrationType)
  return (
    <div>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <Card>
        <TitleMenu>
          <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.DATA_PROVIDER + '/' + paths.ADD}>
            <Button type="secondary" className="gtm-change-data-integration" style={{ marginRight: '10px' }}>
              {t('data_integration.integration.header.change')}
            </Button>
          </Link>
          <DumbLink onClick={deleteIntegration}>
            <Button type="secondary" danger className="gtm-delete-data-integration">
              {t('data_integration.integration.header.delete')}
            </Button>
          </DumbLink>
        </TitleMenu>
        <Title>{t('data_integration.integration.title')}</Title>

        <Row>
          <Col span={8}>
            {t('data_integration.integration.summary.name')}:
            <br />
            <strong>{displayName}</strong>
          </Col>
        </Row>
      </Card>

      {dataIntegration && (
        <Card className="data-integration-container">
          <Row>
            <Col span={24} className="integration-import-button-row">
              <TabNavigator currentPage={dataSheet} onChange={(p) => setDataSheet(p)} size="large">
                {hasTimeRegistrations && (
                  <TabNavigator.Item value="PayRollImport">
                    {t('data_integration.integration.sheet.pay_roll_import')}
                  </TabNavigator.Item>
                )}
                {hasLeaveRegistrations && (
                  <TabNavigator.Item value="LeaveRegistrationsImport">
                    {t('data_integration.integration.sheet.leave_registrations_import')}
                  </TabNavigator.Item>
                )}
                {hasLeaveRegistrationsExport && (
                  <TabNavigator.Item value="LeaveRegistrationsExport">
                    {t('data_integration.integration.sheet.leave_registrations_export')}
                  </TabNavigator.Item>
                )}
                {hasReimbursements && (
                  <TabNavigator.Item value="ReimbursementsImport">
                    {t('data_integration.integration.sheet.reimbursements_import')}
                  </TabNavigator.Item>
                )}
                {hasCarAllowances && (
                  <TabNavigator.Item value="CarAllowancesImport">
                    {t('data_integration.integration.sheet.car_allowances_import')}
                  </TabNavigator.Item>
                )}
                {hasEmployees && (
                  <TabNavigator.Item value="EmployeesImport">
                    {t('data_integration.integration.sheet.employees_import')}
                  </TabNavigator.Item>
                )}
                {dataIntegration.hasFieldsMapping && (
                  <TabNavigator.Item value="FieldMapping">
                    {t('data_integration.integration.sheet.field_mapping')}
                  </TabNavigator.Item>
                )}
              </TabNavigator>
            </Col>
          </Row>

          {dataSheet === 'PayRollImport' && (
            <div>
              <PayRollImport
                asynchronousTasks={props.asynchronousTasks}
                company={props.company}
                contracts={props.contracts}
                payRolls={props.payRolls}
                salaryCycles={props.salaryCycles}
                employees={props.employees}
                salaryTypes={props.salaryTypes}
                displayName={displayName}
                addAlert={props.addAlert}
                startPayRollImport={props.startPayRollImport}
                storeStagedImportData={props.storeStagedImportData}
                getAsynchronousTask={props.getAsynchronousTask}
                getContracts={props.getContracts}
              />
            </div>
          )}
          {dataSheet === 'LeaveRegistrationsImport' && (
            <div>
              <LeaveRegistrationImport
                asynchronousTasks={props.asynchronousTasks}
                company={props.company}
                payRolls={props.payRolls}
                salaryCycles={props.salaryCycles}
                employees={props.employees}
                leaveTypes={props.leaveTypes}
                displayName={displayName}
                addAlert={props.addAlert}
                startLeaveRegistrationImport={props.startLeaveRegistrationImport}
                storeStagedImportData={props.storeStagedImportData}
                getAsynchronousTask={props.getAsynchronousTask}
              />
            </div>
          )}
          {dataSheet === 'LeaveRegistrationsExport' && (
            <div>
              <LeaveRegistrationExport
                asynchronousTasks={props.asynchronousTasks}
                asynchronousSchedules={props.asynchronousSchedules}
                company={props.company}
                employees={props.employees}
                leaveTypes={props.leaveTypes}
                timeRegistrations={props.timeRegistrations}
                displayName={displayName}
                addAlert={props.addAlert}
                startLeaveRegistrationExport={props.startLeaveRegistrationExport}
                saveLeaveRegistrationExport={props.saveLeaveRegistrationExport}
                getTimeRegistrations={props.getTimeRegistrations}
                getAsynchronousTask={props.getAsynchronousTask}
                addAsynchronousSchedule={props.addAsynchronousSchedule}
                updateAsynchronousSchedule={props.updateAsynchronousSchedule}
                deleteAsynchronousSchedule={props.deleteAsynchronousSchedule}
              />
            </div>
          )}
          {dataSheet === 'ReimbursementsImport' && (
            <div>
              <ReimbursementsImport
                asynchronousTasks={props.asynchronousTasks}
                company={props.company}
                employees={props.employees}
                payRolls={props.payRolls}
                salaryCycles={props.salaryCycles}
                displayName={displayName}
                addAlert={props.addAlert}
                startReimbursementImport={props.startReimbursementImport}
                storeStagedImportData={props.storeStagedImportData}
                getAsynchronousTask={props.getAsynchronousTask}
              />
            </div>
          )}
          {dataSheet === 'CarAllowancesImport' && (
            <div>
              <CarAllowancesImport
                asynchronousTasks={props.asynchronousTasks}
                company={props.company}
                employees={props.employees}
                payRolls={props.payRolls}
                salaryCycles={props.salaryCycles}
                displayName={displayName}
                addAlert={props.addAlert}
                startCarAllowanceImport={props.startCarAllowanceImport}
                storeStagedImportData={props.storeStagedImportData}
                getAsynchronousTask={props.getAsynchronousTask}
              />
            </div>
          )}
          {dataSheet === 'EmployeesImport' && (
            <div>
              <EmployeesImport
                displayName={displayName}
                integrationType={dataIntegration.integrationType}
                company={props.company}
                employees={props.employees}
                salaryTypes={props.salaryTypes}
                asynchronousTasks={props.asynchronousTasks}
                isFieldsMappingComplete={dataIntegration.isFieldsMappingComplete}
                addAlert={props.addAlert}
                startEmployeeImport={props.startEmployeeImport}
                storeStagedImportData={props.storeStagedImportData}
                getAsynchronousTask={props.getAsynchronousTask}
                toggleShowFieldsMapping={() => setDataSheet('FieldMapping')}
              />
            </div>
          )}
          {dataSheet === 'FieldMapping' && (
            <div>
              <CustomFieldMapping
                displayName={dataIntegration.displayName}
                company={props.company}
                addAlert={props.addAlert}
                getCompanyDataIntegration={props.getCompanyDataIntegration}
              />
            </div>
          )}
        </Card>
      )}

      {props.companyDataIntegration.saving && <LoadingOverlay text={t('data_integration.integration.saving')} />}
    </div>
  )
}
