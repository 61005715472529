import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'

type Props = {
  kilometers?: number
}

type Fields = {
  kilometers?: string
}

export type CoarseCarAllowanceResult = {
  kilometers: number
}

function CoarseCarAllowanceForm(props: FormComponentProps<Fields, CoarseCarAllowanceResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('kilometers', {
            placeholder: t('car_allowances_tab.coarse.form.kilometers'),
            suffix: t('car_allowances_tab.coarse.form.kilometers.suffix'),
            validate: (val) => {
              if (val === undefined) {
                return t('car_allowances_tab.coarse.form.kilometers.required')
              }
              if (forceParseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('car_allowances_tab.coarse.form.kilometers.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, CoarseCarAllowanceResult>({
  mapPropsToFields: (props) => ({
    kilometers: formatInputNumber(props.kilometers),
  }),
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'kilometers':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    kilometers: forceParseInputNumber(values.kilometers),
  }),
})(CoarseCarAllowanceForm)
