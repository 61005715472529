import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import SupplementType from '../../model/supplementType'
import {
  isAmountChoiceSupplement,
  isChoiceSupplement,
  isDirectPayChoiceSupplement,
  isPercentageChoiceSupplement,
} from '../../utils/employee-contract-utils'
import {
  decorateAnyFieldSignature,
  decorateFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
} from '../../utils/form-utils'
import { formatSupplementTypeFullName } from '../../utils/format-utils'
import { parseInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Switch from '../elements/switch'

export type SupplementRow = {
  typeID?: string
  suffix: 'percentage' | 'fixedAmount'
  compensationRate?: string
  directPay: boolean
}

export type ChoiceFormFields = {
  hasChoice: boolean
  remuneration: {
    supplements: SupplementRow[]
  }
}

type Props<Fields extends ChoiceFormFields> = {
  decorateField: decorateFieldSignature<Fields>
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
  settingsEnabled: CompanySetting[]
  employee: Employee
  supplementTypes: List<SupplementType>
}

export default function ChoiceForm<Fields extends ChoiceFormFields>(props: Props<Fields>): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    let typeID = ''
    let suffix: 'percentage' | 'fixedAmount' = 'percentage'
    const supplementType = props.supplementTypes.find(
      (row) => isChoiceSupplement(row.name) && !isDirectPayChoiceSupplement(row.name)
    )
    if (supplementType) {
      typeID = supplementType.id
      if (isAmountChoiceSupplement(supplementType.name)) {
        suffix = 'fixedAmount'
      }
    }
    const row: SupplementRow = {
      typeID,
      suffix,
      directPay: false,
    }
    setAnyFieldValue(`remuneration.supplements.${remuneration.supplements.length}`, row)
  }
  const remove = (i: number) => {
    const { getFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    remuneration.supplements.splice(i, 1)
    setAnyFieldValue('remuneration.supplements', remuneration.supplements)
  }

  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldValue, setAnyFieldValue, getAnyFieldError } = props

  const directPayPermitted = props.settingsEnabled.some((setting) => setting === 'AllowSHFritvalgDirectPay')

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('hasChoice', {
            skipLabel: true,
          })(
            <Radio.Group>
              <Radio value={false}>{t('choice.edit.form.has_choice.false')}</Radio>
              <Radio value={true}>{t('choice.edit.form.has_choice.true')}</Radio>
            </Radio.Group>
          )}
        </Col>
      </Row>
      {getFieldValue('hasChoice') && (
        <Subcard>
          <Row style={{ marginBottom: '-10px' }}>
            <Col span={13}>
              <label>{t('choice.edit.form.header.type')}</label>
            </Col>
            {directPayPermitted && (
              <Col span={3}>
                <label>{t('choice.edit.form.header.direct_pay')}</label>
              </Col>
            )}
            <Col span={8 + (directPayPermitted ? 0 : 3)}>
              <label>{t('choice.edit.form.header.rate')}</label>
            </Col>
          </Row>
          {(getAnyFieldValue('remuneration.supplements') as SupplementRow[]).map((supplement, i) => {
            const hasError =
              getAnyFieldError(`remuneration.supplements.${i}.typeID`) ||
              getAnyFieldError(`remuneration.supplements.${i}.directPay`) ||
              getAnyFieldError(`remuneration.supplements.${i}.compensationRate`) ||
              getAnyFieldError(`remuneration.supplements.${i}.suffix`)
            const suffix = getAnyFieldValue(`remuneration.supplements.${i}.suffix`)
            const directPay = getAnyFieldValue(`remuneration.supplements.${i}.directPay`)
            const choiceTypes = props.supplementTypes
              .filter(
                (row) =>
                  isChoiceSupplement(row.name) &&
                  (suffix === 'fixedAmount'
                    ? isAmountChoiceSupplement(row.name)
                    : isPercentageChoiceSupplement(row.name)) &&
                  (directPay ? isDirectPayChoiceSupplement(row.name) : !isDirectPayChoiceSupplement(row.name))
              )
              .toArray()
            choiceTypes.sort((a, b) =>
              formatSupplementTypeFullName(a.name, { skipDirectPay: true, skipAmount: true }).localeCompare(
                formatSupplementTypeFullName(b.name, { skipDirectPay: true, skipAmount: true })
              )
            )
            return (
              <Form.Item key={i} validateStatus={hasError ? 'error' : 'success'}>
                <Row>
                  <Col span={13}>
                    {decorateAnyField(`remuneration.supplements.${i}.typeID`, {
                      placeholder: t('choice.edit.form.type'),
                      validate: (val) => (!val ? t('choice.edit.form.type.required') : null),
                      skipWrapper: true,
                      skipLabel: true,
                      trigger: 'onChange',
                      noBlur: true,
                    })(
                      <Select dropdownMatchSelectWidth={false}>
                        {choiceTypes.map((row) => {
                          return (
                            <Select.Option key={row.id} value={row.id}>
                              {formatSupplementTypeFullName(row.name, { skipDirectPay: true, skipAmount: true })}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                  </Col>
                  {directPayPermitted && (
                    <Col span={3}>
                      {decorateAnyField(`remuneration.supplements.${i}.directPay`, {
                        placeholder: t('choice.edit.form.direct_pay'),
                        valueOnChecked: true,
                        skipWrapper: true,
                        skipLabel: true,
                        noBlur: true,
                      })(
                        <Switch
                          onChange={(value: boolean) => {
                            const supplementType = props.supplementTypes.find(
                              (row) => row.id === getAnyFieldValue(`remuneration.supplements.${i}.typeID`)
                            )
                            let name = supplementType ? supplementType.name : ''
                            if (value) {
                              name += 'DirectPay'
                            } else {
                              name = name.replace(/DirectPay/i, '')
                            }
                            const typeAgain = props.supplementTypes.find((row) => row.name === name)
                            if (typeAgain) {
                              setAnyFieldValue(`remuneration.supplements.${i}.typeID`, typeAgain.id)
                            }
                          }}
                        />
                      )}
                    </Col>
                  )}
                  <Col span={(i > 0 ? 7 : 8) + (directPayPermitted ? 0 : 3)}>
                    <Input.Group compact>
                      {decorateAnyField(`remuneration.supplements.${i}.compensationRate`, {
                        placeholder:
                          suffix === 'fixedAmount'
                            ? t('choice.edit.form.compensation_rate.fixed')
                            : t('choice.edit.form.compensation_rate.percentage'),
                        validate: (val) => {
                          if (!val) {
                            return t('choice.edit.form.compensation_rate.required', {
                              title:
                                suffix === 'fixedAmount'
                                  ? t('choice.edit.form.compensation_rate.fixed')
                                  : t('choice.edit.form.compensation_rate.percentage'),
                            })
                          }
                          if (suffix !== 'fixedAmount' && parseInputNumber(val) > 50) {
                            return t('choice.edit.form.compensation_rate.invalid_percentage')
                          }
                          return null
                        },
                        skipWrapper: true,
                        skipLabel: true,
                      })(<Input style={{ width: '60%' }} />)}
                      {decorateAnyField(`remuneration.supplements.${i}.suffix`, {
                        skipWrapper: true,
                        skipLabel: true,
                      })(
                        <Select
                          dropdownMatchSelectWidth={false}
                          onChange={(value) => {
                            const supplementType = props.supplementTypes.find(
                              (row) => row.id === getAnyFieldValue(`remuneration.supplements.${i}.typeID`)
                            )
                            let name = supplementType ? supplementType.name : ''
                            name = name.replace(/DirectPay$/i, '')
                            if (value === 'fixedAmount') {
                              name += 'Amount'
                            } else {
                              name = name.replace(/Amount/i, '')
                            }
                            if (getAnyFieldValue(`remuneration.supplements.${i}.directPay`)) {
                              name += 'DirectPay'
                            }
                            const typeAgain = props.supplementTypes.find((row) => row.name === name)
                            if (typeAgain) {
                              setAnyFieldValue(`remuneration.supplements.${i}.typeID`, typeAgain.id)
                            }
                          }}
                          style={{ width: '40%' }}
                        >
                          <Select.Option value="percentage">{t('choice.edit.form.suffix.percentage')}</Select.Option>
                          <Select.Option value="fixedAmount">{t('choice.edit.form.suffix.fixed_amount')}</Select.Option>
                        </Select>
                      )}
                    </Input.Group>
                  </Col>
                  {i > 0 && (
                    <Col span={1} className="contracts-remove-row">
                      <span onClick={() => remove(i)}>
                        <Icon type="xSign" />
                      </span>
                    </Col>
                  )}
                </Row>
              </Form.Item>
            )
          })}
          <span className="contracts-add-row" onClick={add}>
            <Icon type="plusCircle" /> {t('choice.edit.form.add_row')}
          </span>
        </Subcard>
      )}
    </div>
  )
}
