import { Item } from 'rc-menu'
import React, { PropsWithChildren, ReactElement } from 'react'

type Props = {
  className?: string
}

export default React.forwardRef<HTMLLIElement, PropsWithChildren<Props>>((props, ref): ReactElement | null => {
  return <Item {...props} ref={ref} />
})
