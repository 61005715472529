import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import {
  getSigningDocument,
  postSigningDocumentComplete,
  postSigningDocumentStart,
  SigningDocument,
} from '../../api/documents'
import { getCurrentLanguage, setCurrentLanguage } from '../../utils/language-utils'
import { url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import DocumentSigningCompleteForm, { CompleteFields } from './DocumentSigningCompleteForm'
import DocumentSigningStartForm, { StartFields } from './DocumentSigningStartForm'

import './DocumentSigning.css'

type Props = {
  signID: string
  signKey: string
  documentID: string
}

export default function DocumentSigningPage(props: Props): ReactElement | null {
  const [signingDocument, setSigningDocument] = useState<SigningDocument>()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [stage, setStage] = useState(0)

  const { documentID, signID, signKey } = props

  useEffect(() => {
    if (!signingDocument && !errorMessage) {
      getSigningDocument(documentID, signID, signKey)
        .then((res) => {
          setCurrentLanguage(res.data.signerLanguage)
          setSigningDocument(res.data)
          if (res.data.signState === 'Signed') {
            setStage(2) // jump straight to last scene
          }
        })
        .catch(() => {
          setErrorMessage(t('signing_document.error.unable_to_fetch_document'))
        })
    }
  })

  const handleStartSigning = (values: StartFields) => {
    setLoading(true)
    postSigningDocumentStart(documentID, signID, signKey, values.signerPhoneNumberCountryCode, values.signerPhoneNumber)
      .then(() => {
        setStage(1)
      })
      .catch(() => {
        setErrorMessage(t('signing_document.error.unable_to_start_signing'))
      })
      .finally(() => setLoading(false))
  }

  const handleCriiptoSign = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!signingDocument || !signingDocument.signUrl) {
      return
    }
    setLoading(true)
    document.location =
      signingDocument.signUrl + `&state=document-signing_${props.documentID}_${props.signID}_${props.signKey}`
  }

  const handleCompleteSigning = (values: CompleteFields) => {
    setLoading(true)
    postSigningDocumentComplete(documentID, signID, signKey, values.signerValidationCode)
      .then(() => setStage(2))
      .catch(() => {
        setErrorMessage(t('signing_document.error.unable_to_complete_signing'))
      })
      .finally(() => setLoading(false))
  }

  if (errorMessage) {
    return <Alert type={'error'} message={errorMessage} showIcon={true} />
  }

  if (!signingDocument || loading) {
    return <LoadingOverlay />
  }

  if (getCurrentLanguage() !== signingDocument.signerLanguage) {
    window.location.reload()
    return null
  }

  return (
    <Card>
      <Title>{stage === 2 ? t('signing_document.document_is_signed') : t('signing_document.title')}</Title>
      <p>
        {t('signing_document.description', {
          name: signingDocument.companyName,
          nationalID: signingDocument.companyNationalID,
        })}
      </p>
      <Link
        to={url('/v2/documents/' + documentID + '/signDownload', {
          signID: signID,
          key: signKey,
          disposition: 'inline',
        })}
        target="_blank"
      >
        <Button className="btn-download" prefixIcon="arrowDownIntoTray">
          {t('signing_document.get_document')}
        </Button>
      </Link>
      {signingDocument.signatureType === 'SMS' && (stage === 0 || stage === 1) && (
        <p>{t('signing_document.instructions')}</p>
      )}
      <div className="signing-fields">
        {stage === 0 && signingDocument.signatureType === 'SMS' && (
          <DocumentSigningStartForm onSubmit={handleStartSigning} />
        )}
        {stage === 0 && signingDocument.signatureType === 'Criipto' && (
          <Button onClick={handleCriiptoSign} size="extra-extra-large" type="secondary" className="mitid-button">
            {t('signing_document.sign_with_mitid')}
          </Button>
        )}
        {stage === 1 && <DocumentSigningCompleteForm onSubmit={handleCompleteSigning} />}
      </div>
    </Card>
  )
}
