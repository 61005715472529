import ContractTemplate, { ContractTemplateMutableFields } from '../model/contractTemplate'
import ContractTemplateContract, {
  ContractTemplateContractContainer,
  ContractTemplateContractMutableFields,
} from '../model/contractTemplateContract'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'
import { remunerationFix } from './contracts'

export function fetchContractTemplates(companyID: string): Promise<RequestResponse<ContractTemplate[]>> {
  return secureRequest('GET', url('/v2/contractTemplates', { companyID }))
}

export function postContractTemplate(
  contractTemplate: ContractTemplateMutableFields
): Promise<RequestResponse<ContractTemplate>> {
  return secureRequest('POST', url('/v2/contractTemplates'), {
    body: {
      companyID: contractTemplate.companyID,
      title: contractTemplate.title,
      description: contractTemplate.description,
      version: contractTemplate.version,
      employeeType: contractTemplate.employeeType,
      parentID: contractTemplate.parentID,
      affiliationType: contractTemplate.affiliationType,
      paySlipTransportSMS: contractTemplate.paySlipTransportSMS,
      paySlipTransportEMail: contractTemplate.paySlipTransportEMail,
      paySlipTransportMitDK: contractTemplate.paySlipTransportMitDK,
      paySlipTransportEBoks: contractTemplate.paySlipTransportEBoks,
      transferDestinationType: contractTemplate.transferDestinationType,
      language: contractTemplate.language,
      preferredTaxCardType: contractTemplate.preferredTaxCardType,
      incomeType: contractTemplate.incomeType,
      departmentID: contractTemplate.departmentID,
      public: contractTemplate.public,
      active: contractTemplate.active,
    },
  })
}

export function putContractTemplate(
  contractTemplateID: string,
  contractTemplate: ContractTemplate
): Promise<RequestResponse<ContractTemplate>> {
  return secureRequest('PUT', url('/v2/contractTemplates/' + contractTemplateID), {
    body: {
      companyID: contractTemplate.companyID,
      title: contractTemplate.title,
      description: contractTemplate.description,
      version: contractTemplate.version,
      employeeType: contractTemplate.employeeType,
      parentID: contractTemplate.parentID,
      affiliationType: contractTemplate.affiliationType,
      paySlipTransportSMS: contractTemplate.paySlipTransportSMS,
      paySlipTransportEMail: contractTemplate.paySlipTransportEMail,
      paySlipTransportMitDK: contractTemplate.paySlipTransportMitDK,
      paySlipTransportEBoks: contractTemplate.paySlipTransportEBoks,
      transferDestinationType: contractTemplate.transferDestinationType,
      language: contractTemplate.language,
      preferredTaxCardType: contractTemplate.preferredTaxCardType,
      incomeType: contractTemplate.incomeType,
      departmentID: contractTemplate.departmentID,
      public: contractTemplate.public,
      active: contractTemplate.active,
    },
  })
}

export function delContractTemplate(contractTemplateID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/contractTemplates/' + contractTemplateID))
}

export function fetchContractTemplateContracts(
  contractTemplateID: string
): Promise<RequestResponse<ContractTemplateContractContainer[]>> {
  return secureRequest('GET', url('/v2/contractTemplateContracts', { contractTemplateID }))
}

export function postContractTemplateContract(
  contractTemplateContract: ContractTemplateContractMutableFields
): Promise<RequestResponse<ContractTemplateContract>> {
  return secureRequest('POST', url('/v2/contractTemplateContracts'), {
    body: {
      contractTemplateID: contractTemplateContract.contractTemplateID,
      carAllowanceRegistrationMethodType: contractTemplateContract.carAllowanceRegistrationMethodType,
      timeRegistrationMethodType: contractTemplateContract.timeRegistrationMethodType,
      salaryRegistrationMethodType: contractTemplateContract.salaryRegistrationMethodType,
      employmentPositionID: contractTemplateContract.employmentPositionID,
      position: contractTemplateContract.position,
      salaryCycleID: contractTemplateContract.salaryCycleID,
      workCycle: contractTemplateContract.workCycle,
      workCycleHours: contractTemplateContract.workCycleHours,
      workCycleAnchorDate: contractTemplateContract.workCycleAnchorDate,
      dayLaborer: contractTemplateContract.dayLaborer,
      remuneration: remunerationFix(contractTemplateContract.remuneration),
      validFrom: contractTemplateContract.validFrom,
      order: contractTemplateContract.order,
    },
  })
}

export function putContractTemplateContract(
  contractTemplateContractID: string,
  contractTemplateContract: ContractTemplateContract
): Promise<RequestResponse<ContractTemplateContract>> {
  return secureRequest('PUT', url('/v2/contractTemplateContracts/' + contractTemplateContractID), {
    body: {
      contractTemplateID: contractTemplateContract.contractTemplateID,
      carAllowanceRegistrationMethodType: contractTemplateContract.carAllowanceRegistrationMethodType,
      timeRegistrationMethodType: contractTemplateContract.timeRegistrationMethodType,
      salaryRegistrationMethodType: contractTemplateContract.salaryRegistrationMethodType,
      employmentPositionID: contractTemplateContract.employmentPositionID,
      position: contractTemplateContract.position,
      salaryCycleID: contractTemplateContract.salaryCycleID,
      workCycle: contractTemplateContract.workCycle,
      workCycleHours: contractTemplateContract.workCycleHours,
      workCycleAnchorDate: contractTemplateContract.workCycleAnchorDate,
      dayLaborer: contractTemplateContract.dayLaborer,
      remuneration: remunerationFix(contractTemplateContract.remuneration),
      validFrom: contractTemplateContract.validFrom,
      order: contractTemplateContract.order,
    },
  })
}

export function delContractTemplateContract(contractTemplateContractID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/contractTemplateContracts/' + contractTemplateContractID))
}
