import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import SalaryRegistration from '../../model/salaryRegistration'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

interface Props {
  visible: boolean
  company: Company
  salaryRegistrations: List<SalaryRegistration>

  closeModal: () => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => Promise<void>
}

export default function DeleteCompanySalaryRegistrationsModal(props: Props): ReactElement | null {
  const deleteBulk = () => {
    props.deleteSalaryRegistrationBulk(props.company.id).then(() => props.closeModal())
  }

  const count = props.salaryRegistrations.filter((reg) => reg.state === 'Pending').size

  return (
    <Card className="delete-company-salary-registrations">
      <Subtitle>{t('salary_registrations.delete.title')}</Subtitle>
      <p>{t('salary_registrations.delete.intro', { count })}</p>
      <Button danger onClick={deleteBulk}>
        {t('salary_registrations.delete.submit')}
      </Button>
    </Card>
  )
}
