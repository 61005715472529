import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { getStagedImportData, getStagedImportResult } from '../../api/data'
import ApplyResult from '../../model/applyResult'
import AsynchronousTask from '../../model/asynchronousTask'
import Employee from '../../model/employee'
import SalaryType from '../../model/salaryType'
import { StagedImportData } from '../../model/stagedImport'
import { DateFormat } from '../../model/types'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { getAccessToken } from '../../utils/cookie-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatEmployeePayType, formatPreferredTaxCardType } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { RequestError, url } from '../../utils/request-utils'
import { formatEmployeeBatchMessage, formatEmployeeBatchStatus } from '../../utils/staged-import-utils'
import { t } from '../../utils/translation-utils'
import { FileChangeEvent, HandleFileEvents } from '../../utils/upload-utils'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  visible: boolean
  asynchronousTasks: AsynchronousTaskReducer
  salaryTypes: List<SalaryType>
  employees: List<Employee>

  startExcelEmployeesData: (id: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (id: string) => Promise<AsynchronousTask | void>
  closeModal: () => void
}

type State = {
  taskID?: string
  importData?: StagedImportData
  importResult?: ApplyResult[]
}

export default function ImportExcelEmployeesModal(props: Props): ReactElement | null {
  const [step, setStep] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [importing, setImporting] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [state, setState] = useState<State>({})
  const [collecting, setCollecting] = useState(false)

  const downloadURL = '/downloads/excel_medarbejder_import.xlsx'

  const asynchronousTasksList = props.asynchronousTasks.asynchronousTasks

  useEffectOnce(() => {
    // find a task
    const task = asynchronousTasksList.find(
      (task) => task.type === 'ExcelEmployeesImportGather' && task.status === 'Success'
    )
    if (task) {
      // check if its save call has finished
      if (
        !asynchronousTasksList.find(
          (newTask) =>
            newTask.type === 'StagedDataSave' &&
            newTask.status === 'Success' &&
            newTask.originalAsynchronousTaskID === task.id
        )
      ) {
        getStagedImportData(task.id)
          .then((res) => {
            setState({ taskID: task.id, importData: res.data })
            setStep(2)
          })
          .catch((e: RequestError) => setError(e))
      }
    }
  })

  const asyncError = props.asynchronousTasks.error

  useEffect(() => {
    if (asyncError && step === 2) {
      setStep(1)
    }
  }, [asyncError, step, setStep])

  const prevError = usePrevious(error)

  useEffect(() => {
    if (!state.taskID) {
      return
    }
    const task = asynchronousTasksList.find((task) => task.id === state.taskID)
    if (!task) {
      return
    }
    if (task.type !== 'ExcelEmployeesImportGather' && task.type !== 'StagedDataSave') {
      return
    }
    if (importing && (task.status === 'Failed' || task.status === 'Success')) {
      setImporting(false)
    }
    if (task.status !== 'Failed' && task.status !== 'Success') {
      return
    }
    const newError = convertStoredErrorToError(task.error)
    if (!compareError(prevError || null, newError)) {
      setError(newError)
    }
    switch (task.type) {
      case 'ExcelEmployeesImportGather':
        if (task.status === 'Success' && !state.importData && !collecting) {
          setCollecting(true)
          getStagedImportData(task.id).then((res) => {
            setState({ taskID: state.taskID, importData: res.data })
            setCollecting(false)
            setStep(2)
          })
        }
        break
      case 'StagedDataSave':
        if (task.status === 'Success' && !state.importResult && !collecting) {
          setCollecting(true)
          getStagedImportResult(task.id).then((res) => {
            setState({ taskID: state.taskID, importData: state.importData, importResult: res.data })
            setCollecting(false)
            setStep(3)
          })
        }
        break
    }
  }, [
    asynchronousTasksList,
    state,
    importing,
    prevError,
    setImporting,
    setError,
    setState,
    setStep,
    collecting,
    setCollecting,
  ])

  const getErrors = () => {
    if (!state.importData) {
      return []
    }
    const errors = state.importData.errors
    if (!errors) {
      return []
    }
    return errors.map((error) => error.message)
  }

  type EmployeeRow = {
    key: string
    employeeID?: string
    nationalID: string
    name: string
    address: string
    postalCode: string
    city: string
    email: string | undefined
    phoneNumber: string | undefined
    employmentStartDate: DateFormat
    bankRegistrationNumber: string | undefined
    bankAccountNumber: string | undefined
    preferredTaxCardType: PreferredTaxCardType
    update: boolean
    payType: EmployeePayType
    salary?: number
    position: string
  }

  const getEmployees = (): EmployeeRow[] => {
    if (!state.importData) {
      return []
    }
    const employees = state.importData.companies[0].employees
    return employees.map((employee): EmployeeRow => {
      let payType: EmployeePayType = 'Salaried'
      let salary = null
      if (employee.remuneration && employee.remuneration.salary.length > 0) {
        const salaryRow = employee.remuneration.salary[0]
        const salaryType = props.salaryTypes.find((salaryType) => salaryType.id === salaryRow.salaryTypeID)
        if (salaryType && salaryType.name === 'Hourly') {
          payType = 'Hourly Paid'
        }
        salary = salaryRow.rate
      } else {
        payType = 'Commissioned'
      }
      return {
        key: employee.nationalID,
        employeeID: employee.employeeID,
        nationalID: employee.nationalID,
        name: employee.name ?? '-',
        address: employee.address ?? '-',
        postalCode: employee.postalCode ?? '-',
        city: employee.city ?? '-',
        email: employee.email,
        phoneNumber: employee.phoneNumber,
        employmentStartDate: employee.employmentStartDate,
        bankRegistrationNumber: employee.bankRegistrationNumber,
        bankAccountNumber: employee.bankAccountNumber,
        preferredTaxCardType: employee.preferredTaxCardType,
        update: !employee.remuneration, // if the remuneration is not set, the employee is being updated
        payType,
        salary: salary || undefined,
        position: employee.position,
      }
    })
  }

  type ResultRow = {
    key: string
    nationalID: string
    name: string
    state: string
    stateMessage: string
  }

  const getResult = (): ResultRow[] => {
    const { importResult, importData } = state
    if (!importResult || !importData) {
      return []
    }
    if (importResult.length === 0 || importData.companies.length === 0) {
      return []
    }
    return importResult[0].messages.map((message, i): ResultRow => {
      const employee = props.employees.find((employee) => employee.id === message.employeeID)
      if (employee && employee.nationalID) {
        return {
          key: employee.nationalID,
          nationalID: employee.nationalID,
          name: employee.name || employee.email || '-',
          state: formatEmployeeBatchStatus(message.state),
          stateMessage: formatEmployeeBatchMessage(message.state, message.message, message.details),
        }
      }
      const employeeData = importData.companies[0].employees[i]
      return {
        key: employeeData.nationalID,
        nationalID: employeeData.nationalID,
        name: employeeData.name ?? employeeData.email ?? '-',
        state: formatEmployeeBatchStatus(message.state),
        stateMessage: formatEmployeeBatchMessage(message.state, message.message, message.details),
      }
    })
  }

  const handleFileUpload = (e: FileChangeEvent) =>
    HandleFileEvents(e, {
      onUploading: () => {
        setUploading(true)
        setState({})
      },
      onDone: (data) => {
        setUploading(false)
        setImporting(true)
        if (data) {
          props.startExcelEmployeesData(data.id).then((res) => {
            if (!res) {
              return
            }
            setState({ taskID: res.id })
          })
        }
      },
      onError: (e) => {
        setUploading(false)
        setError(e || null)
      },
    })

  const handleFileStore = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.taskID) {
      return
    }

    const task = asynchronousTasksList.find((task) => task.id === state.taskID)

    if (!task) {
      return
    }

    setState({ importData: state.importData })
    setImporting(true)
    props.storeStagedImportData(task.id).then((res) => {
      if (!res) {
        return
      }
      setState({ taskID: res.id, importData: state.importData })
    })
  }

  const errors = getErrors()
  return (
    <Card className="excel-employees-import">
      <Title>{t('employees_excel_import.title')}</Title>
      <Steps current={step}>
        <Steps.Step title={t('employees_excel_import.steps.1')} />
        <Steps.Step title={t('employees_excel_import.steps.2')} />
        <Steps.Step title={t('employees_excel_import.steps.3')} />
        <Steps.Step title={t('employees_excel_import.steps.4')} />
      </Steps>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <p>&nbsp;</p>
      {step === 0 && (
        <div>
          <p>{t('employees_excel_import.step_one.intro')}</p>
          <Link to={downloadURL} target="_blank" rel="noopener noreferrer" onClick={() => setStep(1)}>
            <Button type="primary" className="gtm-download-timereg-csv" prefixIcon="arrowDownIntoTray">
              {t('employees_excel_import.step_one.button.download')}
            </Button>
          </Link>
          <Button size="extra-extra-large" style={{ marginLeft: 20 }} onClick={() => setStep(1)}>
            {t('employees_excel_import.step_one.button.skip')}
          </Button>
        </div>
      )}
      {step === 1 && (
        <div>
          {importing && <div>{t('employees_excel_import.step_two.loading')}</div>}
          {!importing && (
            <div>
              <p>{t('employees_excel_import.step_two.intro')}</p>
              <div style={{ float: 'left' }}>
                <UploadDragger
                  name={'fileData'}
                  action={url('v2/stagedFiles')}
                  headers={{ authorization: getAccessToken() }}
                  accept={'.xlsx'}
                  showUploadList={false}
                  onChange={handleFileUpload}
                >
                  <Button
                    type="primary"
                    className="gtm-upload-timereg-csv"
                    disabled={uploading}
                    prefixIcon="arrowUpInsideCloud"
                  >
                    {t('employees_excel_import.step_two.button.upload')}
                  </Button>
                </UploadDragger>
              </div>
              <Button size="extra-extra-large" style={{ marginLeft: 20 }} onClick={() => setStep(0)}>
                {t('employees_excel_import.step_two.button.back')}
              </Button>
              {(uploading || props.asynchronousTasks.saving) && <LoadingOverlay />}
            </div>
          )}
        </div>
      )}
      {step === 2 && (
        <div>
          {importing && <div>{t('employees_excel_import.step_three.importing')}</div>}
          {!importing && (
            <div className="preview-table">
              {errors.length > 0 && (
                <div className="errors">
                  {errors.map((error: string, i: number) => {
                    return <Alert key={i} message={error} type="error" showIcon />
                  })}
                </div>
              )}
              {!!state.importData && state.importData.companies.length > 0 && (
                <div>
                  <div style={{ marginBottom: 20 }}>
                    {state.importData.companies[0].employees.length > 0 && (
                      <Button onClick={handleFileStore} type="primary" className="gtm-submit-employees-excel">
                        {t('employees_excel_import.step_three.button.save')}
                      </Button>
                    )}
                    <Button size="extra-extra-large" style={{ marginLeft: 20 }} onClick={() => setStep(1)}>
                      {t('employees_excel_import.step_three.button.back')}
                    </Button>
                  </div>
                  {state.importData.companies[0].employees.length > 0 && (
                    <div>
                      <Row>
                        <Col span={3}>
                          <strong>{t('employees_excel_import.step_three.table.header.national_id')}</strong>
                        </Col>
                        <Col span={5}>
                          <strong>{t('employees_excel_import.step_three.table.header.name')}</strong>
                        </Col>
                        <Col span={4}>
                          <strong>{t('employees_excel_import.step_three.table.header.contact')}</strong>
                        </Col>
                        <Col span={4}>
                          <strong>{t('employees_excel_import.step_three.table.header.account')}</strong>
                        </Col>
                        <Col span={8}>
                          <strong>{t('employees_excel_import.step_three.table.header.employment')}</strong>
                        </Col>
                      </Row>
                      {getEmployees().map((employee) => {
                        return (
                          <Row key={employee.key}>
                            <Col span={3}>
                              {employee.nationalID}
                              {employee.update && (
                                <>
                                  <br />
                                  <i>{t('employees_excel_import.step_three.table.will_be_updated')}</i>
                                </>
                              )}
                            </Col>
                            <Col span={5}>
                              {employee.name}
                              <br />
                              {employee.address}, {employee.postalCode} {employee.city}
                            </Col>
                            <Col span={4}>
                              {employee.email}
                              <br />
                              {employee.phoneNumber}
                            </Col>
                            <Col span={4}>
                              {employee.bankRegistrationNumber} {employee.bankAccountNumber}
                              <br />
                              {formatPreferredTaxCardType(employee.preferredTaxCardType)}
                            </Col>
                            {!employee.update && (
                              <Col span={8}>
                                {t(
                                  [
                                    'employees_excel_import',
                                    'step_three',
                                    'table',
                                    'employment_format',
                                    employee.payType === 'Hourly Paid' ? 'hourly' : 'non_hourly',
                                  ],
                                  {
                                    position: employee.position,
                                    pay_type: formatEmployeePayType(employee.payType).toLowerCase(),
                                    amount: formatCurrency(employee.salary),
                                  }
                                )}
                                <br />
                                {t('employees_excel_import.step_three.table.employment_format.date', {
                                  date: employee.employmentStartDate,
                                })}
                              </Col>
                            )}
                          </Row>
                        )
                      })}
                    </div>
                  )}
                  {state.importData.companies[0].employees.length === 0 && (
                    <p>{t('employees_excel_import.step_three.no_changes')}</p>
                  )}
                </div>
              )}
              {props.asynchronousTasks.saving && <LoadingOverlay />}
            </div>
          )}
        </div>
      )}
      {step === 3 && (
        <div className="preview-table">
          {(!state.importResult || state.importResult.length === 0) && (
            <div>{t('employees_excel_import.step_four.all_imported')}</div>
          )}
          {state.importResult && state.importResult.length > 0 && (
            <div>
              <Button
                style={{ marginBottom: 20 }}
                onClick={props.closeModal}
                type="primary"
                className="gtm-done-employees-excel"
              >
                {t('employees_excel_import.step_four.button.done')}
              </Button>
              <Row>
                <Col span={3}>
                  <strong>{t('employees_excel_import.step_four.table.header.national_id')}</strong>
                </Col>
                <Col span={5}>
                  <strong>{t('employees_excel_import.step_four.table.header.name')}</strong>
                </Col>
                <Col span={8}>
                  <strong>{t('employees_excel_import.step_four.table.header.state')}</strong>
                </Col>
                <Col span={8}>
                  <strong>{t('employees_excel_import.step_four.table.header.state_message')}</strong>
                </Col>
              </Row>
              {getResult().map((result) => {
                return (
                  <Row key={result.key}>
                    <Col span={3}>{result.nationalID}</Col>
                    <Col span={5}>{result.name}</Col>
                    <Col span={8}>{result.state}</Col>
                    <Col span={8}>{result.stateMessage}</Col>
                  </Row>
                )
              })}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}
