import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import { LeaveAdjustmentCreationFields } from '../../../model/leaveAdjustment'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { EmployeeReducer } from '../../../reducers/employees'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Modal from '../../elements/modal'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import EmploymentVacationEdit from './EmploymentVacationEdit'

type Props = {
  company: Company
  mutableContract: Contract
  employee: Employee
  contracts: ContractReducer
  employees: EmployeeReducer
  salaryCycle: SalaryCycle
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  leaveBalances: LeaveBalanceReducer
  isMutableContract: boolean

  updateEmployee: (employee: Employee) => Promise<Employee | void>
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  addContract: (contract: ContractCreationFields) => Promise<Contract | void>
  updateContract: (contract: ContractMutableFields) => Promise<Contract | void>
}

export default function EmploymentVacationCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const { employee, leaveBalances, getLeaveBalances } = props

  useEffect(() => {
    const employeeID = employee.id
    if (
      !leaveBalances.employeeID ||
      (leaveBalances.employeeID && leaveBalances.employeeID !== employeeID) ||
      (!leaveBalances.loading && !leaveBalances.loaded)
    ) {
      getLeaveBalances(undefined, employeeID)
    }
  }, [employee, leaveBalances, getLeaveBalances])

  const { isMutableContract } = props

  const setEditVisibility = useCallback(
    (visible: boolean) => {
      if (!isMutableContract) {
        return
      }
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setShowEdit(visible)
    },
    [isMutableContract]
  )

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    // Check for save callback
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      // Check for no error occurred
      if (!contracts.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  }, [previousContracts, contracts, setEditVisibility])

  const hasVacation = () => {
    return props.employee.affiliationType !== 'Director'
  }
  const hasPaidVacation = () => {
    const paids: LeaveTypeName[] = ['DenmarkVacationPaid', 'DenmarkVacationAccrual']
    return (
      props.mutableContract.remuneration?.leave.some((row) => row.type && paids.indexOf(row.type.name) !== -1) || false
    )
  }
  const daysFormat = (days: number) => {
    return `${formatNumber(days, 2)} ${t('unit.day', { count: days })}`
  }
  const getVacationDays = () => {
    const vacation: LeaveTypeName[] = [
      'DenmarkVacationPaid',
      'DenmarkVacationNoPay',
      'DenmarkVacationAccrual',
      'DenmarkVacationFund',
      'DenmarkVacationFundWithPension',
    ]
    const days =
      props.mutableContract.remuneration?.leave.find((row) => row.type && vacation.indexOf(row.type.name) !== -1)
        ?.days || 0
    return daysFormat(days)
  }
  const getVacationAmount = () => {
    let amount = 12.5
    const vacation: LeaveTypeName[] = [
      'DenmarkVacationPaid',
      'DenmarkVacationNoPay',
      'DenmarkVacationAccrual',
      'DenmarkVacationFund',
      'DenmarkVacationFundWithPension',
    ]
    if (hasPaidVacation()) {
      amount = 1
      const supplement = props.mutableContract.remuneration?.supplements.find(
        (row) =>
          row.type && ['DenmarkVacationPaidSupplement', 'DenmarkVacationSupplement'].indexOf(row.type.name) !== -1
      )
      if (supplement) {
        amount = supplement.compensationRate * 100
      }
    } else {
      const rate = props.mutableContract.remuneration?.leave.find(
        (row) => row.type && vacation.indexOf(row.type.name) !== -1
      )?.rate
      if (rate) {
        amount = rate * 100
      }
    }
    return t('employment_vacation.card.vacation_amount', { amount: formatNumber(amount, 2) })
  }
  const getExtraVacationDays = () => {
    let days = 0
    let accrual: boolean | undefined = undefined
    const leave = props.mutableContract.remuneration?.leave.find(
      (row) =>
        row.type && (row.type.name === 'DenmarkOptionalVacation' || row.type.name === 'DenmarkExtraVacationAccrual')
    )
    if (leave && leave.type) {
      accrual = leave.type.name === 'DenmarkExtraVacationAccrual'
      days = leave.days
    }
    return accrual !== undefined
      ? t('employment_vacation.card.extra_vacation_day.format.with_suffix', {
          days: daysFormat(days),
          suffix: accrual
            ? t('employment_vacation.card.extra_vacation_day.format.monthly')
            : t('employment_vacation.card.extra_vacation_day.format.annual'),
        })
      : t('employment_vacation.card.extra_vacation_day.format.no_suffix', {
          days: daysFormat(days),
        })
  }
  const getPersonalDays = () => {
    let days = 0
    let accrual: boolean | undefined = undefined
    const leave = props.mutableContract.remuneration?.leave.find(
      (row) => row.type && (row.type.name === 'DenmarkPersonalDay' || row.type.name === 'DenmarkPersonalTimeAccrual')
    )
    if (leave && leave.type) {
      accrual = leave.type.name === 'DenmarkPersonalTimeAccrual'
      days = leave.days
    }
    return accrual !== undefined
      ? t('employment_vacation.card.personal_days.format.with_suffix', {
          days: daysFormat(days),
          suffix: accrual
            ? t('employment_vacation.card.personal_days.format.monthly')
            : t('employment_vacation.card.personal_days.format.annual'),
        })
      : t('employment_vacation.card.personal_days.format.no_suffix', {
          days: daysFormat(days),
        })
  }

  if (props.leaveBalances.loading || !props.leaveBalances.loaded) {
    return (
      <Card
        style={{
          position: 'relative',
          minHeight: '150px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </Card>
    )
  }
  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button.Icon type="paperWithPencil" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>
        {hasVacation()
          ? hasPaidVacation()
            ? t('employment_vacation.card.title.with_pay')
            : t('employment_vacation.card.title.without_pay')
          : t('employment_vacation.card.title.none')}
      </Title>

      {!hasVacation() && (
        <Row>
          <Col span={24}>{t('employment_vacation.card.no_vacation_note')}</Col>
        </Row>
      )}
      {hasVacation() && (
        <Row>
          <Col span={6}>
            {t('employment_vacation.card.table.header.vacation_days')}:
            <br />
            <strong>{getVacationDays()}</strong>
          </Col>
          <Col span={6}>
            {hasPaidVacation()
              ? t('employment_vacation.card.table.header.vacation_amount.paid')
              : t('employment_vacation.card.table.header.vacation_amount.unpaid')}
            :<br />
            <strong>{getVacationAmount()}</strong>
          </Col>
          <Col span={6}>
            {t('employment_vacation.card.table.header.extra_vacation_days')}:
            <br />
            <strong>{getExtraVacationDays()}</strong>
          </Col>
          <Col span={6}>
            {t('employment_vacation.card.table.header.personal_days')}:
            <br />
            <strong>{getPersonalDays()}</strong>
          </Col>
        </Row>
      )}

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={720}
        footer={null}
      >
        <EmploymentVacationEdit
          visible={showEdit}
          company={props.company}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          employees={props.employees}
          salaryCycle={props.salaryCycle}
          leaveTypes={props.leaveTypes}
          supplementTypes={props.supplementTypes}
          leaveBalances={props.leaveBalances.leaveBalances}
          addLeaveAdjustment={props.addLeaveAdjustment}
          updateEmployee={props.updateEmployee}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
