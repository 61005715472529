import React from 'react'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  rejectPayRoll: (e: React.MouseEvent) => void
}

export default function RejectPayRoll(props: Props) {
  return (
    <Card className="pay-roll-approve">
      <Subtitle>{t('pay_roll.single.reject.title')}</Subtitle>
      <p>{t('pay_roll.single.reject.intro')}</p>
      <p>&nbsp;</p>
      <Row>
        <Col span={24}>
          <Button danger size="extra-extra-large" block onClick={props.rejectPayRoll}>
            {t('pay_roll.single.reject.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
