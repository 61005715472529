import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ProductionUnitsForm, { ProductionUnitField } from './ProductionUnitsForm'

type Props = {
  company: Company
  companies: CompanyReducer
}

export type ProductionUnitResult = {
  productionUnits: ProductionUnitField[]
}

function ProductionUnitsFormWrapper(
  props: Props & FormComponentProps<ProductionUnitResult, ProductionUnitResult>
): ReactElement | null {
  return (
    <div className="companies-single-form">
      <Row>&nbsp;</Row>
      {props.getFormError()}
      <ProductionUnitsForm
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" id="button-save-production-units">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, ProductionUnitResult, ProductionUnitResult>({
  mapPropsToFields: (props) => ({
    productionUnits: props.company.productionUnits,
  }),
  onSubmit: (values) => values,
})(ProductionUnitsFormWrapper)
