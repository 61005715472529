import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import { getPermittedSMSPhonePrefixes } from '../../utils/validation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'

type Fields = {
  signerPhoneNumberCountryCode?: string
  signerPhoneNumber?: string
}

export type StartFields = {
  signerPhoneNumberCountryCode: string
  signerPhoneNumber: string
}

function DocumentSigningStartForm(props: FormComponentProps<Fields, StartFields>): ReactElement | null {
  const { decorateField, getFieldError, getFieldValue } = props
  const permittedPhonePrefixes = getPermittedSMSPhonePrefixes()

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item
            validateStatus={
              getFieldError('signerPhoneNumberCountryCode') || getFieldError('signerPhoneNumber') ? 'error' : 'success'
            }
          >
            <label htmlFor="signerPhoneNumber" title={t('form.field.phone_number')}>
              {getFieldError('signerPhoneNumberCountryCode') ||
                getFieldError('signerPhoneNumber') ||
                t('form.field.phone_number')}
            </label>
            <Input.Group compact className="country-code-phone-group">
              {decorateField('signerPhoneNumberCountryCode', {
                placeholder: t('form.field.phone_number_country_code'),
                validate: (val) => {
                  if (!val) {
                    return t('form.validation.phone_number_country_code_is_required')
                  }
                  if (permittedPhonePrefixes.indexOf(val) === -1) {
                    return t('form.validation.phone_number_country_code_is_invalid')
                  }
                  return null
                },
                skipWrapper: true,
                skipLabel: true,
              })(
                <Select dropdownMatchSelectWidth={false}>
                  {permittedPhonePrefixes.map((prefix) => {
                    return (
                      <Select.Option key={prefix} value={prefix}>
                        +{prefix}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
              {decorateField('signerPhoneNumber', {
                placeholder: t('form.field.phone_number'),
                validate: (val) => {
                  if (!val) {
                    return t('form.validation.phone_number_is_required')
                  }
                  if (!val.match(/^[0-9]+$/)) {
                    return t('form.validation.phone_number_is_invalid')
                  }
                  if (getFieldValue('signerPhoneNumberCountryCode') === '45' && !val.match(/^[0-9]{8}$/)) {
                    return t('form.validation.phone_number_is_invalid')
                  }
                  return null
                },
                skipWrapper: true,
                skipLabel: true,
              })(<Input />)}
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('signing_document.request_validation_code')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<unknown, Fields, StartFields>({
  mapPropsToFields: () => ({
    signerPhoneNumberCountryCode: '45',
  }),
  onSubmit: (values) => ({
    signerPhoneNumber: values.signerPhoneNumber!,
    signerPhoneNumberCountryCode: values.signerPhoneNumberCountryCode!,
  }),
})(DocumentSigningStartForm)
