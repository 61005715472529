import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CarAllowance from '../../model/carAllowance'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatDisplayNumber } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  carAllowances: CarAllowanceReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>

  addAlert: addAlertSignature
  approveCarAllowances: (ids: string[]) => void
}

export default function CarAllowancesCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  type Sort = {
    sortOn?: 'employee' | 'date' | 'kilometers'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { carAllowances, addAlert } = props
  const previousCarAllowances = usePrevious(carAllowances)

  useEffect(() => {
    if (previousCarAllowances && previousCarAllowances.saving && !carAllowances.saving) {
      if (carAllowances.error) {
        addAlert('error', formatError(carAllowances.error))
      }
      setApproving([]) //empty it
    }
  }, [previousCarAllowances, carAllowances, addAlert, setApproving])

  const pendingCarAllowances: CarAllowance[] = carAllowances.carAllowances
    .filter(
      (allowance) =>
        !allowance.approved &&
        !approving.some((approve) => approve === allowance.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, allowance.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveCarAllowances([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingCarAllowances.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveCarAllowances(unapproved)
  }

  type CarAllowanceRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    date: string
    kilometers: string
    reason: string
    trip: string
    original: CarAllowance
  }

  const columns = [
    {
      title: t('approve_tab.car_allowances.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: CarAllowanceRow) => {
        return (
          <ApproveCardEmployeeName {...row} linkTo={'/' + paths.EMPLOYEES + '/' + row.employeeID + '/car-allowance'} />
        )
      },
    },
    { title: t('approve_tab.car_allowances.header.date'), dataIndex: 'date', key: 'date', sorter: 'date' },
    { title: t('approve_tab.car_allowances.header.trip'), dataIndex: 'trip', key: 'trip' },
    {
      title: t('approve_tab.car_allowances.header.kilometers'),
      dataIndex: 'kilometers',
      key: 'kilometers',
      sorter: 'kilometers',
    },
    { title: t('approve_tab.car_allowances.header.reason'), dataIndex: 'reason', key: 'reason' },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: CarAllowanceRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.car_allowances.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingCarAllowanceRows: CarAllowanceRow[] = pendingCarAllowances
    .map((carAllowance) => {
      let reason = carAllowance.reason
      if (reason.length > 32) {
        reason = reason.substring(0, 30) + '...'
      }

      return {
        key: carAllowance.id,
        id: carAllowance.id,
        employeeID: carAllowance.employeeID,
        ...getEmployeeRowEmployeeDetails(
          props.employees.get(carAllowance.employeeID),
          props.departments,
          props.companyUsers
        ),
        date: formatDate(carAllowance.date),
        kilometers: t('car_allowance.trip_length', { amount: formatDisplayNumber(carAllowance.kilometers) }),
        kilometersNo: carAllowance.kilometers,
        trip: t('car_allowance.trip', { from: carAllowance.locationFrom, to: carAllowance.locationTo }),
        reason,
        original: carAllowance,
      }
    })
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.original.date === j.original.date) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.original.date.localeCompare(j.original.date)
        case 'kilometers':
          if (i.kilometersNo === j.kilometersNo) {
            if (i.employeeName === j.employeeName) {
              return a.original.date.localeCompare(b.original.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.kilometersNo - i.kilometersNo
        default:
          if (a.employeeName === b.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = ({ sorter }: TableChange<CarAllowanceRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'kilometers':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingCarAllowances.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="extra-extra-large">
          {t('approve_tab.car_allowances.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.car_allowances.title')}</Title>
      <Table columns={columns} dataSource={pendingCarAllowanceRows} onChange={tableChange} pagination={false} />
    </div>
  )
}
