import React, { PropsWithChildren, ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'

type Props = {
  prefixCls?: string
  className?: string
  last?: boolean
  status: 'warning' | 'awaiting' | 'error' | 'success'
}

export default function TimelineItem(props: PropsWithChildren<Props>): ReactElement | null {
  const { prefixCls = 'ant-timeline', className, status, children, last, ...restProps } = props

  const itemClassName = classNames(
    {
      [`${prefixCls}-item`]: true,
      [`${prefixCls}-last`]: last,
    },
    className
  )

  const dotClassName = classNames({
    [`${prefixCls}-item-head`]: true,
    [`${prefixCls}-item-head-${status}`]: true,
  })

  return (
    <li {...restProps} className={itemClassName}>
      <div className={`${prefixCls}-item-tail`} />
      <div className={dotClassName}></div>
      <div className={`${prefixCls}-item-content`}>{children}</div>
    </li>
  )
}
