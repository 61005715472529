import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryRegistration from '../../model/salaryRegistration'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatCurrency, formatDisplayNumber } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Button from '../elements/button'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  salaryRegistrations: SalaryRegistrationReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>

  addAlert: addAlertSignature
  approveSalaryRegistrations: (ids: string[]) => void
}

export default function SalaryRegistrationsCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  type Sort = {
    sortOn?: 'employee' | 'date' | 'quantity'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { salaryRegistrations, addAlert } = props
  const previousSalaryRegistrations = usePrevious(salaryRegistrations)

  useEffect(() => {
    if (previousSalaryRegistrations && previousSalaryRegistrations.saving && !salaryRegistrations.saving) {
      if (salaryRegistrations.error) {
        addAlert('error', formatError(salaryRegistrations.error))
      }
      setApproving([]) //empty it
    }
  }, [previousSalaryRegistrations, salaryRegistrations, addAlert, setApproving])

  const pendingSalaryRegistrations: SalaryRegistration[] = salaryRegistrations.salaryRegistrations
    .filter(
      (registration) =>
        !registration.approved &&
        !approving.some((approve) => approve === registration.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, registration.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveSalaryRegistrations([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingSalaryRegistrations.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveSalaryRegistrations(unapproved)
  }

  type SalaryRegistrationRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    date: string
    quantity: string
    salary: string
    original: SalaryRegistration
  }

  const columns = [
    {
      title: t('approve_tab.salary_registrations.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: SalaryRegistrationRow) => {
        return (
          <ApproveCardEmployeeName
            {...row}
            linkTo={'/' + paths.EMPLOYEES + '/' + row.employeeID + '/salary-registration'}
          />
        )
      },
    },
    { title: t('approve_tab.salary_registrations.header.date'), dataIndex: 'date', key: 'date', sorter: 'date' },
    {
      title: t('approve_tab.salary_registrations.header.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: 'quantity',
    },
    { title: t('approve_tab.salary_registrations.header.rate'), dataIndex: 'salary', key: 'salary' },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: SalaryRegistrationRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            Godkend
          </span>
        )
      },
    },
  ]

  const pendingSalaryRegistrationRows: SalaryRegistrationRow[] = pendingSalaryRegistrations
    .map((registration) => {
      const employee = props.employees.get(registration.employeeID)

      let salaryLine = ''
      if (employee && employee.earliestMutableContract && employee.earliestMutableContract.remuneration) {
        const salary = employee.earliestMutableContract.remuneration.salary.find(
          (salary) => salary.salaryTypeID === registration.salaryTypeID
        )
        if (salary) {
          salaryLine = t('approve_tab.salary_registrations.table.rate_display', {
            name: translateGroupTitle(salary),
            rate: formatCurrency(salary.rate, 2),
          })
        }
      }

      return {
        key: registration.id,
        id: registration.id,
        employeeID: registration.employeeID,
        ...getEmployeeRowEmployeeDetails(
          props.employees.get(registration.employeeID),
          props.departments,
          props.companyUsers
        ),
        date: formatDate(registration.date),
        quantity: formatDisplayNumber(registration.quantity),
        quantityNo: registration.quantity,
        salary: salaryLine,
        original: registration,
      }
    })
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.original.date === j.original.date) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.original.date.localeCompare(j.original.date)
        case 'quantity':
          if (i.quantityNo === j.quantityNo) {
            if (i.employeeName === j.employeeName) {
              return a.original.date.localeCompare(b.original.date)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.quantityNo - i.quantityNo
        default:
          if (a.employeeName === b.employeeName) {
            return a.original.date.localeCompare(b.original.date)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = ({ sorter }: TableChange<SalaryRegistrationRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'quantity':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingSalaryRegistrations.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="extra-extra-large">
          {t('approve_tab.salary_registrations.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.salary_registrations.title')}</Title>
      <Table columns={columns} dataSource={pendingSalaryRegistrationRows} onChange={tableChange} pagination={false} />
    </div>
  )
}
