import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import { CoarseTimeRegistrationMutableFields } from '../../model/coarseTimeRegistration'
import Company from '../../model/company'
import { ContractCreationFields, ContractMutableFields, TimeRegistrationMethodType } from '../../model/contract'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryCycle, { SalaryFrequency } from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { TimeRegistrationClass } from '../../model/timeRegistration'
import { CoarseTimeRegistrationReducer } from '../../reducers/coarseTimeRegistrations'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { ContractReducer } from '../../reducers/contracts'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { UserReducer } from '../../reducers/user'
import RemunerationType from '../../types/remuneration-type'
import { formatSalaryFrequency } from '../../utils/format-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { getCurrentPeriodFromDispositionDate } from '../../utils/salary-period-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import { Col, Row } from '../elements/grid'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Select from '../elements/select'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import CoarseTimeRegistrationModal from '../employees-single/time-registration/CoarseTimeRegistrationModal'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import DeleteCompanyTimeRegistrationsModal from './DeleteCompanyTimeRegistrationsModal'

type Props = {
  user: UserReducer
  company: Company
  companyUsers: CompanyUserReducer
  employees: List<Employee>
  departments: List<Department>
  contracts: ContractReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  timeRegistrations: TimeRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  getCoarseTimeRegistrations: (companyID: string, salaryPeriodID: string) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
  approveTimeRegistrations: (ids: string[]) => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass
  ) => Promise<boolean | void>
}

export default function TimeRegistrationTable(props: Props): ReactElement | null {
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })

  const getSalaryFrequencies = () => {
    const frequencies = props.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.affiliationType !== 'Freelancer' &&
          employee.activeContract &&
          employee.activeContract.remunerationType === RemunerationType.HOURLY
      )
      .reduce((frequencies: SalaryFrequency[], employee) => {
        const salaryCycle = props.salaryCycles.find(
          (salaryCycle) => salaryCycle.id === employee.activeContract?.salaryCycleID
        )
        if (salaryCycle) {
          if (frequencies.indexOf(salaryCycle.frequency) === -1) {
            frequencies.push(salaryCycle.frequency)
          }
        }
        return frequencies
      }, [])
    const order: Record<SalaryFrequency, number> = {
      Monthly: 0,
      BiWeekly: 10,
      Weekly: 20,
    }
    frequencies.sort((a, b) => {
      return order[a] - order[b]
    })
    return frequencies
  }

  const [frequency, setFrequency] = useState(() => {
    const frequencies = getSalaryFrequencies()
    return frequencies.length > 0 ? frequencies[0] : 'Monthly'
  })
  const [modalKey, setModalKey] = useState(1)
  const [showCoarse, setShowCoarse] = useState<string | boolean>(false)
  const [showDeleteCompanyTimeRegistrations, setShowDeleteCompanyTimeRegistrations] = useState(false)

  const { company } = props
  const getSalaryCycleID = (salaryCycles: SalaryCycle[], thisFrequency = frequency) => {
    let salaryCycleID = undefined
    if (thisFrequency === 'Monthly') {
      salaryCycleID = company.defaultHourlyMonthlyCycleID
    }
    if (thisFrequency === 'BiWeekly') {
      salaryCycleID = company.defaultBiweeklyCycleID
    }
    if (!salaryCycleID) {
      if (salaryCycles) {
        return salaryCycles.find(
          (salaryCycle) => salaryCycle.frequency === thisFrequency && !salaryCycle.prepaid && !salaryCycle.offset
        )?.id
      }
    }
    return salaryCycleID
  }

  const getSalaryCycle = (salaryCycles: SalaryCycle[], thisFrequency = frequency) => {
    const salaryCycleID = getSalaryCycleID(salaryCycles, thisFrequency)
    if (salaryCycleID) {
      return salaryCycles.find((salaryCycle) => salaryCycle.id === salaryCycleID)
    }
    return undefined
  }

  const { salaryCycles, coarseTimeRegistrations, getCoarseTimeRegistrations } = props

  useEffect(() => {
    const companyID = company.id
    const salaryCycle = getSalaryCycle(salaryCycles.toArray())
    if (salaryCycle) {
      const salaryPeriodID = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)?.id
      if (
        salaryPeriodID &&
        (coarseTimeRegistrations.companyID !== companyID ||
          coarseTimeRegistrations.salaryPeriodID !== salaryPeriodID ||
          (!coarseTimeRegistrations.loading && !coarseTimeRegistrations.loaded))
      ) {
        getCoarseTimeRegistrations(companyID, salaryPeriodID)
      }
    }
  })

  const setCoarseVisibility = (showCoarse: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowCoarse(showCoarse)
  }

  const previousCoarseTimeRegistrations = usePrevious(coarseTimeRegistrations)

  useEffect(() => {
    if (previousCoarseTimeRegistrations && previousCoarseTimeRegistrations.saving && !coarseTimeRegistrations.saving) {
      if (!coarseTimeRegistrations.error) {
        setCoarseVisibility(false)
      }
    }
  })

  const changeFrequency = (thisFrequency: SalaryFrequency) => {
    setFrequency(thisFrequency)
  }

  const setDeleteCompanyTimeRegistrations = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDeleteCompanyTimeRegistrations(visible)
  }

  const approveAll = (employeeID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (window.confirm(t('common.are_you_sure'))) {
        const ids = props.timeRegistrations.timeRegistrations
          .filter((reg) => reg.employeeID === employeeID && reg.class === 'Hours' && !reg.approved)
          .toArray()
          .map((reg) => reg.id)
        props.approveTimeRegistrations(ids)
      }
    }
  }

  type TimeRegistrationRow = {
    sort: number
    key: string
    salaryName: string
    salaryTypeID: string
    hours: number
    rate: number
  }

  type EmployeeRow = {
    key: string
    id: string
    name: string
    position?: string
    profileImageURL?: string
    timeRegistrations: TimeRegistrationRow[]
    type: TimeRegistrationMethodType
    workHours?: number
    canSeeSalaryRates: boolean
    canApproveObjects: boolean
    canEditObjects: boolean
    hasUnapproved: boolean
    loading: boolean
  }

  const columns = [
    {
      title: t('time_registrations.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
            <small>{employee.position ? employee.position : '-'}</small>
          </Headline>
        )
      },
    },
    {
      title: t('time_registrations.table.header.salary'),
      dataIndex: '',
      key: 'xSalary',
      width: 250,
      render: (employee: EmployeeRow) => {
        if (employee.loading) {
          return <span className="time-registration-cell-loading">{t('time_registrations.table.loading')}</span>
        }
        if (employee.timeRegistrations.length === 0) {
          return null
        }
        return (
          <div className="time-registration-hour-line">
            {employee.timeRegistrations.map((timeReg) => {
              return <div key={timeReg.key}>{timeReg.salaryName}</div>
            })}
          </div>
        )
      },
    },
    {
      title: t('time_registrations.table.header.rate'),
      dataIndex: '',
      key: 'xRate',
      width: 150,
      render: (employee: EmployeeRow) => {
        if (!employee.canSeeSalaryRates) {
          return null
        }
        if (employee.loading) {
          return <span className="time-registration-cell-loading">{t('time_registrations.table.loading')}</span>
        }
        if (employee.timeRegistrations.length === 0) {
          return null
        }
        return (
          <div className="time-registration-hour-line">
            {employee.timeRegistrations.map((timeReg) => {
              return <div key={timeReg.key}>{formatCurrency(timeReg.rate, 2)}</div>
            })}
          </div>
        )
      },
    },
    {
      title: t('time_registrations.table.header.hours'),
      dataIndex: '',
      key: 'xHours',
      width: 150,
      render: (employee: EmployeeRow) => {
        if (employee.loading) {
          return <span className="time-registration-cell-loading">{t('time_registrations.table.loading')}</span>
        }
        if (employee.timeRegistrations.length === 0) {
          return formatNumber(0, 2)
        }
        return (
          <div className="time-registration-hour-line">
            {employee.timeRegistrations.map((timeReg) => {
              return <div key={timeReg.key}>{formatNumber(timeReg.hours, 2)}</div>
            })}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xHasUnapproved',
      width: 200,
      render: (employee: EmployeeRow) => {
        if (employee.type === 'Coarse' || employee.workHours === 0) {
          return ''
        }
        if (!employee.canApproveObjects) {
          return ''
        }
        if (props.timeRegistrations.saving) {
          return <span className="time-registration-hour-loading">{t('time_registrations.table.saving')}</span>
        }
        if (employee.hasUnapproved) {
          return <DumbLink onClick={approveAll(employee.id)}>{t('time_registrations.table.approve_hours')}</DumbLink>
        }
        return t('time_registrations.table.all_approved')
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (employee: EmployeeRow) => {
        if (!employee.canEditObjects) {
          return ''
        }
        if (employee.type === 'Coarse') {
          return (
            <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
              <DumbLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setCoarseVisibility(employee.id)
                }}
              >
                <Icon type="user" /> {t('time_registrations.table.actions.register')}
              </DumbLink>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/time-registration'}>
                <Icon type="paperWithPencil" /> {t('time_registrations.table.actions.edit')}
              </Link>
            </ContextMenu>
          )
        }
        return (
          <Tooltip placement="top" title={t('time_registrations.table.actions.edit')}>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/time-registration'}>
              <Icon type="paperWithPencil" />
            </Link>
          </Tooltip>
        )
      },
    },
  ]

  const getSimpleEmployees = (): EmployeeRow[] => {
    const salaryCycleID = getSalaryCycleID(props.salaryCycles.toArray())
    return props.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract &&
          employee.activeContract.remunerationType === RemunerationType.HOURLY &&
          employee.activeContract.salaryCycleID === salaryCycleID &&
          employee.activeContract.timeRegistrationMethodType === 'Coarse' &&
          filterEmployee(employee, filter)
      )
      .map((employee) => {
        let timeRegistrations: TimeRegistrationRow[] = []
        const loading = !employee.activeContract?.remuneration
        if (!loading) {
          timeRegistrations = props.coarseTimeRegistrations.coarseTimeRegistrations
            .filter((reg) => reg.employeeID === employee.id)
            .reduce((timeRegs: TimeRegistrationRow[], reg) => {
              const idx = employee.activeContract?.remuneration?.salary.findIndex(
                (salary) => salary.salaryTypeID === reg.salaryTypeID
              )
              if (idx === undefined || idx === -1) {
                return timeRegs
              }
              const salary = employee.activeContract?.remuneration?.salary[idx]
              if (!salary) {
                return timeRegs
              }
              timeRegs.push({
                sort: idx,
                key: reg.id,
                hours: reg.hours,
                salaryName: translateGroupTitle(salary),
                salaryTypeID: salary.salaryTypeID,
                rate: salary.rate,
              })
              return timeRegs
            }, [])
        }

        return {
          key: employee.id,
          id: employee.id,
          type: employee.activeContract?.timeRegistrationMethodType || 'Coarse',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          loading,
          timeRegistrations: timeRegistrations.sort((a, b) => a.sort - b.sort),
          hasUnapproved: false,
          canSeeSalaryRates: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'SeeSalaryRates'
          ),
          canApproveObjects: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'ApproveObjects'
          ),
          canEditObjects: hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const getDetailedEmployees = (): EmployeeRow[] => {
    return props.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract &&
          employee.activeContract.remunerationType === RemunerationType.HOURLY &&
          employee.activeContract.timeRegistrationMethodType === 'Detailed' &&
          filterEmployee(employee, filter)
      )
      .map((employee) => {
        let hasUnapproved = false
        let timeRegistrations: TimeRegistrationRow[] = []
        const loading = !employee.activeContract?.remuneration
        if (!loading) {
          timeRegistrations = props.timeRegistrations.timeRegistrations
            .filter((reg) => reg.employeeID === employee.id && reg.class === 'Hours' && reg.state === 'Pending')
            .reduce((timeRegs: TimeRegistrationRow[], reg) => {
              const salaryIdx = employee.activeContract?.remuneration?.salary.findIndex(
                (salary) => salary.salaryTypeID === reg.salaryTypeID
              )
              if (salaryIdx === undefined || salaryIdx === -1) {
                return timeRegs
              }
              const salary = employee.activeContract?.remuneration?.salary[salaryIdx]
              if (!salary) {
                return timeRegs
              }
              if (!reg.approved) {
                hasUnapproved = true
              }
              const idx = timeRegs.findIndex((reg) => reg.salaryTypeID === salary.salaryTypeID)
              if (idx !== -1) {
                timeRegs[idx].hours += reg.hours || 0
                return timeRegs
              }
              timeRegs.push({
                sort: idx,
                key: salary.salaryTypeID,
                salaryTypeID: salary.salaryTypeID,
                hours: reg.hours || 0,
                salaryName: translateGroupTitle(salary),
                rate: salary.rate,
              })
              return timeRegs
            }, [])
        }
        return {
          key: employee.id,
          id: employee.id,
          type: employee.activeContract?.timeRegistrationMethodType || 'Detailed',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          timeRegistrations: timeRegistrations.sort((a, b) => a.sort - b.sort),
          loading,
          hasUnapproved,
          canSeeSalaryRates: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'SeeSalaryRates'
          ),
          canApproveObjects: hasDepartmentPermission(
            props.companyUsers.companyUser,
            employee.departmentID,
            'ApproveObjects'
          ),
          canEditObjects: hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const loading =
    !props.coarseTimeRegistrations.loaded || (props.timeRegistrations.loading && !props.timeRegistrations.loaded)
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.coarseTimeRegistrations.loaded, text: t('loading.reducer.coarse_time_registrations') },
            {
              loading: props.timeRegistrations.loading && !props.timeRegistrations.loaded,
              text: t('loading.reducer.time_registrations'),
            },
          ])}
        />
      </div>
    )
  }

  const frequencies = getSalaryFrequencies()
  const salaryCycle = salaryCycles.find((cycle) => cycle.id === getSalaryCycleID(salaryCycles.toArray()))
  const simpleEmployees = getSimpleEmployees()
  const detailedEmployees = getDetailedEmployees()

  return (
    <>
      <Title>
        {t('time_registrations.table.simple.title')}
        {frequencies.length > 1 && (
          <Select
            placeholder={t('time_registrations.table.select_period')}
            value={frequency}
            onChange={(v) => changeFrequency(v as SalaryFrequency)}
          >
            {frequencies.map((frequency) => (
              <Select.Option key={frequency} value={frequency}>
                {formatSalaryFrequency(frequency)}
              </Select.Option>
            ))}
          </Select>
        )}
      </Title>
      <Row>
        <Col span={24}>
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUsers.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={simpleEmployees}
        pagination={simpleEmployees.length > 100 ? { defaultPageSize: 100 } : false}
        onRowClick={(employee: EmployeeRow) => {
          setCoarseVisibility(employee.id)
        }}
      />

      {hasDepartmentPermission(props.companyUsers.companyUser, undefined, 'EditObjects') &&
        props.timeRegistrations.timeRegistrations.some((timeReg) => timeReg.state === 'Pending') && (
          <TitleMenu>
            <Button
              danger
              className="gtm-delete-company-timeregs"
              onClick={() => setDeleteCompanyTimeRegistrations(true)}
            >
              {t('time_registrations.table.detailed.delete_all')}
            </Button>
          </TitleMenu>
        )}
      <Title>{t('time_registrations.table.detailed.title')}</Title>
      <Table
        columns={columns}
        dataSource={detailedEmployees}
        pagination={detailedEmployees.length > 100 ? { defaultPageSize: 100 } : false}
        onRowClick={(employee: EmployeeRow) => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/time-registration')
        }}
      />

      <Modal
        key={modalKey * -1}
        visible={!!showCoarse}
        onOk={() => setCoarseVisibility(false)}
        onCancel={() => setCoarseVisibility(false)}
        width={582}
        footer={null}
      >
        <CoarseTimeRegistrationModal
          visible={!!showCoarse}
          employee={props.employees.find((employee) => employee.id === showCoarse)}
          contracts={props.contracts}
          coarseTimeRegistrations={props.coarseTimeRegistrations}
          salaryCycles={props.salaryCycles}
          salaryCycle={salaryCycle}
          salaryTypes={props.salaryTypes}
          addAlert={props.addAlert}
          addContract={props.addContract}
          updateContract={props.updateContract}
          updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
        />
      </Modal>

      <Modal
        key={modalKey * -2}
        visible={showDeleteCompanyTimeRegistrations}
        onOk={() => setDeleteCompanyTimeRegistrations(false)}
        onCancel={() => setDeleteCompanyTimeRegistrations(false)}
        width={582}
        footer={null}
      >
        <DeleteCompanyTimeRegistrationsModal
          visible={showDeleteCompanyTimeRegistrations}
          company={props.company}
          timeRegistrations={props.timeRegistrations.timeRegistrations}
          closeModal={() => setDeleteCompanyTimeRegistrations(false)}
          deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
        />
      </Modal>
    </>
  )
}
