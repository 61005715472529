import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'

type Props = {
  company: Company
}

type Fields = {
  autoApproveReimbursementVouchers: boolean
  reimbursementVoucherAutoApprovalLimit?: string
}

export type ReimbursementSettingsResult = {
  autoApproveReimbursementVouchers: boolean
  reimbursementVoucherAutoApprovalLimit?: number
}

function ReimbursementSettingsForm(
  props: Props & FormComponentProps<Fields, ReimbursementSettingsResult>
): ReactElement | null {
  const { decorateField, getFieldValue } = props
  return (
    <div>
      <Row>
        <Col span={12}>
          {decorateField('autoApproveReimbursementVouchers', {
            placeholder: t('reimbursement_settings.company.form.auto_approve'),
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
        {getFieldValue('autoApproveReimbursementVouchers') && (
          <Col span={12}>
            {decorateField('reimbursementVoucherAutoApprovalLimit', {
              placeholder: t('reimbursement_settings.company.form.auto_approval_limit'),
              suffix: t('reimbursement_settings.company.form.auto_approval_limit.suffix'),
              validate: (val) => {
                if (!val) {
                  return null
                }
                if (!val.match(/^[0-9,.]+$/)) {
                  return t('reimbursement_settings.company.form.auto_approval_limit.invalid')
                }
                return null
              },
            })(<Input />)}
            <p>
              {props.company.enableImmediatePay
                ? (props.company.periodicLimitForImmediatePayOut ?? 0) > 0
                  ? t('reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_with_limit', {
                      amount: formatCurrency(props.company.periodicLimitForImmediatePayOut),
                    })
                  : t('reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_no_limit')
                : t('reimbursement_settings.company.form.auto_approval_limit.note.no_immediate_pay')}
            </p>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, ReimbursementSettingsResult>({
  mapPropsToFields: (props) => ({
    autoApproveReimbursementVouchers: props.company.settingsEnabled.some(
      (setting) => setting === 'AutoApproveReimbursementVouchers'
    ),
    reimbursementVoucherAutoApprovalLimit:
      props.company.reimbursementVoucherAutoApprovalLimit !== undefined
        ? formatInputNumber(props.company.reimbursementVoucherAutoApprovalLimit)
        : undefined,
  }),
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'reimbursementVoucherAutoApprovalLimit':
        values[key] = formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    reimbursementVoucherAutoApprovalLimit: forceParseInputNumber(values.reimbursementVoucherAutoApprovalLimit),
  }),
})(ReimbursementSettingsForm)
