import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import ContractTemplate from '../../model/contractTemplate'
import Department from '../../model/department'
import { AffiliationType, NationalIDType } from '../../model/employee'
import Remuneration from '../../model/remuneration'
import SalaryCycle from '../../model/salaryCycle'
import { DateFormat } from '../../model/types'
import { EmployeeReducer } from '../../reducers/employees'
import { UserReducer } from '../../reducers/user'
import CountryCode from '../../types/country-code'
import Genders from '../../types/gender'
import Gender from '../../types/gender'
import { sortCountryCode } from '../../utils/country-code-utils'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { validateEmail } from '../../utils/email-utils'
import {
  EmployeePayType,
  getNationalIDTypes,
  hasBirthDate,
  hasCountry,
  hasGender,
  hasNationalID,
} from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatCountryCode, formatGender, formatNationalIDLabel, formatNationalIDType } from '../../utils/format-utils'
import { canLookupNationalID, lookupNationalID } from '../../utils/national-id-utils'
import { setByPath } from '../../utils/object-utils'
import { t, tx } from '../../utils/translation-utils'
import {
  getCityFromPostalCode,
  normalizeNationalID,
  trimSpaces,
  validateNationalID,
} from '../../utils/validation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import HelpModal from '../elements/HelpModal'
import Input from '../elements/input'
import Select from '../elements/select'
import Subtitle from '../elements/Subtitle'
import ContractPayment from '../form-elements/ContractPayment'
import PhoneNumberForm from '../form-elements/PhoneNumberForm'

const NO_TEMPLATE = 'NoTemplate'

type Props = {
  user: UserReducer
  affiliationType: AffiliationType
  company: Company
  employees: EmployeeReducer
  salaryCycles?: List<SalaryCycle>
  contractTemplates?: List<ContractTemplate>
  departments: List<Department>
  departmentIDs: string[] // list of departments this user is restricted to (no restriction empty)

  employeeNumber?: string
  nationalIDType?: NationalIDType
  nationalID?: string
  name?: string
  address?: string
  postalCode?: string
  city?: string
  country?: CountryCode
  tin?: string
  gender?: Gender
  birthDate?: DateFormat
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  employmentStartDate: DateFormat
  departmentID?: string
  sendLogin: boolean
  salaryCycleID?: string
  contractTemplateID?: string
  payType?: EmployeePayType
  remuneration?: Remuneration
}

type Fields = {
  employeeNumber?: string
  nationalIDType: NationalIDType
  nationalID?: string
  name?: string
  address?: string
  postalCode?: string
  city?: string
  country?: CountryCode
  tin?: string
  gender?: Genders
  birthDate?: Date
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  employmentStartDate?: Date
  departmentID?: string
  payType: EmployeePayType
  remuneration?: Remuneration
  sendLogin: boolean
  salaryCycleID?: string
  contractTemplateID?: string
}

export type BaseDataResults = {
  readonly step: 'BaseData'
  employeeNumber: string
  nationalIDType: NationalIDType
  nationalID?: string
  name: string
  address: string
  postalCode: string
  city: string
  country?: CountryCode
  gender?: Genders
  birthDate?: DateFormat
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  employmentStartDate?: DateFormat
  departmentID?: string
  sendLogin: boolean
  salaryCycleID?: string
  contractTemplateID?: string
}

function BaseDataForm(props: Props & FormComponentProps<Fields, BaseDataResults>): ReactElement | null {
  const getDepartments = (): Department[] => {
    if (props.departmentIDs.length === 0) {
      return props.departments.toArray()
    }
    return props.departments
      .filter((department) => department.active && props.departmentIDs.findIndex((id) => id === department.id) !== -1)
      .toArray()
  }

  const { decorateField, getFieldValue, getFieldError } = props
  const isFreelancer = props.affiliationType === 'Freelancer'
  const nationalIDTypes = getNationalIDTypes(props.company.settingsEnabled, props.affiliationType)
  const canNationalIDLookUp = canLookupNationalID(props.user.user, getFieldValue('nationalIDType'))

  const handleLookupNationalID = () => {
    const { setFieldError, setFieldValue } = props
    lookupNationalID(getFieldValue, setFieldValue, setFieldError)
  }

  return (
    <div>
      <Subtitle>
        {isFreelancer ? t('employee.add.base_data.title.freelancer') : t('employee.add.base_data.title.standard')}
      </Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      {props.contractTemplates && props.contractTemplates.size > 0 && (
        <Row>
          <Col span={12}>
            {decorateField('contractTemplateID', {
              title: t('employee.add.base_data.contract_template_id'),
              placeholder: t('employee.add.base_data.contract_template_id.placeholder'),
            })(
              <Select tabIndex={1} dropdownMatchSelectWidth={false}>
                <Select.Option key={NO_TEMPLATE} value={NO_TEMPLATE}>
                  <em>{t('employee.add.base_data.contract_template_id.none')}</em>
                </Select.Option>
                {props.contractTemplates.map((template) => {
                  return (
                    <Select.Option key={template.id} value={template.id}>
                      {template.title}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      {nationalIDTypes.length > 1 && (
        <Row>
          <Col span={12}>
            {decorateField('nationalIDType', {
              title: t('employee.add.base_data.national_id_type'),
              placeholder: t('employee.add.base_data.national_id_type.placeholder'),
              validate: (val) => (!val ? t('employee.add.base_data.national_id_type.required') : null),
            })(
              <Select tabIndex={2} dropdownMatchSelectWidth={false}>
                {nationalIDTypes.map((nationalIDType) => {
                  return (
                    <Select.Option key={nationalIDType} value={nationalIDType}>
                      {formatNationalIDType(nationalIDType)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField('employeeNumber', {
            placeholder: t('employee.add.base_data.employee_number'),
            validate: (val) => {
              if (!val) {
                return t('employee.add.base_data.employee_number.required')
              }
              val = val.toString()
              if (!val.match(/^[a-zæøå0-9 -]{1,15}$/i)) {
                return t('employee.add.base_data.employee_number.invalid')
              }
              if (
                props.employees.employees.some(
                  (employee) => !!employee.activeEmployment && employee.activeEmployment.employeeNumber === val
                )
              ) {
                return t('employee.add.base_data.employee_number.in_use')
              }
              return null
            },
          })(<Input tabIndex={3} />)}
        </Col>
      </Row>
      <Row>
        {hasNationalID(getFieldValue('nationalIDType')) && (
          <Col span={canNationalIDLookUp ? 10 : 12}>
            {decorateField('nationalID', {
              placeholder: formatNationalIDLabel(getFieldValue('nationalIDType')),
              validate: (val) => {
                return validateNationalID(val, getFieldValue('nationalIDType'), props.employees.employees.toArray())
              },
            })(<Input tabIndex={4} />)}
          </Col>
        )}
        {canNationalIDLookUp && (
          <Col span={2}>
            <Button.Icon
              title={t('employee.add.base_data.national_id.look_up')}
              onClick={handleLookupNationalID}
              className="icon-form-button"
              type="magnifyingGlass"
            />
          </Col>
        )}
        <Col span={12}>
          {decorateField('name', {
            placeholder: t('employee.add.base_data.name'),
            validate: (val) => (!val ? t('employee.add.base_data.name.required') : null),
          })(<Input tabIndex={5} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('address', {
            placeholder: t('employee.add.base_data.address'),
            validate: (val) => (!val ? t('employee.add.base_data.address.required') : null),
          })(<Input tabIndex={7} />)}
        </Col>
        <Col span={6}>
          {decorateField('postalCode', {
            placeholder: t('employee.add.base_data.postal_code'),
            validate: (val) => {
              if (!val) {
                return t('employee.add.base_data.postal_code.required')
              }
              return null
            },
          })(<Input tabIndex={8} />)}
        </Col>
        <Col span={6}>
          {decorateField('city', {
            placeholder: t('employee.add.base_data.city'),
            validate: (val) => (!val ? t('employee.add.base_data.city.required') : null),
          })(<Input tabIndex={9} />)}
        </Col>
      </Row>
      {(hasCountry(getFieldValue('nationalIDType')) || hasBirthDate(getFieldValue('nationalIDType'))) && (
        <Row>
          {hasCountry(getFieldValue('nationalIDType')) && (
            <Col span={12}>
              {decorateField('country', {
                title: t('employee.add.base_data.country'),
                placeholder: t('employee.add.base_data.country.placeholder'),
                validate: (val) => {
                  if (!val) {
                    return t('employee.add.base_data.country.required')
                  }
                  if (val === CountryCode.GB) {
                    return t('employee.add.base_data.country.not_gb')
                  }
                  return null
                },
              })(
                <Select
                  tabIndex={10}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  filterOption={(input: string, option: ReactElement) => {
                    const q = input.toLowerCase()
                    return (
                      option.props.title.toLowerCase().indexOf(q) >= 0 ||
                      option.props.children.toLowerCase().indexOf(q) >= 0
                    )
                  }}
                >
                  {Object.values(CountryCode)
                    .filter((code) => code !== CountryCode.DK)
                    .sort((a, b) => sortCountryCode(a, b))
                    .map((code) => {
                      return (
                        <Select.Option key={code} value={code} title={code}>
                          {formatCountryCode(code)}
                        </Select.Option>
                      )
                    })}
                </Select>
              )}
            </Col>
          )}
          {hasBirthDate(getFieldValue('nationalIDType')) && (
            <Col span={12}>
              {decorateField('birthDate', {
                placeholder: t('employee.add.base_data.birth_date'),
                validate: (val) => {
                  if (!val) {
                    return t('employee.add.base_data.birth_date.required')
                  }
                  if (val.getFullYear() >= getDate().getFullYear()) {
                    return t('employee_single.profile.form.birth_date.invalid')
                  }
                  return null
                },
              })(<DatePicker tabIndex={11} allowClear={false} style={{ width: '100%' }} />)}
            </Col>
          )}
          {getFieldValue('country') !== CountryCode.DK && (
            <Col span={12}>
              <Form.Item validateStatus={props.getFieldError('tin') ? 'error' : 'success'}>
                <label title={t('employee.add.base_data.tin')}>
                  {props.getFieldError('tin') || t('employee.add.base_data.tin')}{' '}
                  <HelpModal>
                    <p>{t('employee.add.base_data.tin.intro')}</p>
                    <p>
                      {tx('employee.add.base_data.tin.read_more', {
                        link: (
                          <a
                            target="_blank"
                            href="https://taxation-customs.ec.europa.eu/online-services/online-services-and-databases-taxation/tin-taxpayer-identification-number_da"
                          >
                            {t('employee.add.base_data.tin.read_more.link')}
                          </a>
                        ),
                      })}
                    </p>
                  </HelpModal>
                </label>
                {decorateField('tin', {
                  placeholder: t('employee.add.base_data.tin'),
                  skipLabel: true,
                  skipWrapper: true,
                })(<Input />)}
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      {hasGender(getFieldValue('nationalIDType')) && (
        <Row>
          <Col span={12}>
            {decorateField('gender', {
              title: t('employee.add.base_data.gender'),
              placeholder: t('employee.add.base_data.gender.placeholder'),
              validate: (val) => (!val ? t('employee.add.base_data.gender.required') : null),
            })(
              <Select tabIndex={12} dropdownMatchSelectWidth={false}>
                <Select.Option value={Genders.MALE}>{formatGender(Genders.MALE)}</Select.Option>
                <Select.Option value={Genders.FEMALE}>{formatGender(Genders.FEMALE)}</Select.Option>
                <Select.Option value={Genders.UNKNOWN}>{formatGender(Genders.UNKNOWN)}</Select.Option>
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField('email', {
            placeholder: t('employee.add.base_data.email'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!validateEmail(val)) {
                return t('employee.add.base_data.email.invalid')
              }
              return null
            },
          })(<Input tabIndex={13} />)}
        </Col>
        <Col span={12}>
          <PhoneNumberForm
            decorateField={decorateField}
            getFieldValue={getFieldValue}
            getFieldError={getFieldError}
            tabIndex={14}
          />
        </Col>
      </Row>
      <Row>
        {!isFreelancer && (
          <Col span={12}>
            {decorateField('employmentStartDate', {
              placeholder: t('employee.add.base_data.employment_start_date'),
              validate: (val) => {
                if (!val) {
                  return t('employee.add.base_data.employment_start_date.required')
                }
                return null
              },
            })(<DatePicker tabIndex={16} allowClear={false} style={{ width: '100%' }} />)}
          </Col>
        )}
        <Col span={12}>
          {decorateField('departmentID', {
            title: t('employee.add.base_data.department_id'),
            placeholder: t('employee.add.base_data.department_id.placeholder'),
            validate: (val) => {
              if (props.departmentIDs.length === 0) {
                // if empty, there are no restrictions
                return null
              }
              if (!val) {
                return t('employee.add.base_data.department_id.required')
              }
              if (!props.departmentIDs.some((id) => id === val)) {
                return t('employee.add.base_data.department_id.only_accessible')
              }
              return null
            },
          })(
            <Select
              tabIndex={17}
              mode={props.departmentIDs.length === 0 ? 'combobox' : undefined}
              dropdownMatchSelectWidth={false}
              optionLabelProp="title"
              filterOption={(input: string, option: ReactElement) =>
                option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getDepartments().map((department) => {
                return (
                  <Select.Option key={department.id} value={department.id} title={department.name}>
                    {department.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      {isFreelancer && (
        <Row>
          <Col span={12}>
            <ContractPayment<Fields>
              decorateField={props.decorateField}
              getFieldValue={props.getFieldValue}
              getAnyFieldValue={props.getAnyFieldValue}
              setFieldValue={props.setFieldValue}
              company={props.company}
              employee={{ affiliationType: 'Freelancer' }}
              salaryCycles={props.salaryCycles}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="secondary"
            className="gtm-add-employee-continue-to-communication"
            tabIndex={18}
          >
            {t('employee.add.base_data.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, BaseDataResults>({
  mapPropsToFields: (props) => ({
    employeeNumber: props.employeeNumber,
    nationalIDType: props.nationalIDType || 'DK CPR',
    nationalID: props.nationalID ? normalizeNationalID(props.nationalID, props.nationalIDType) : undefined,
    name: props.name,
    address: props.address,
    postalCode: props.postalCode,
    city: props.city ? props.city : props.postalCode ? getCityFromPostalCode(props.postalCode) : undefined,
    country: props.country,
    gender: props.gender,
    birthDate: props.birthDate ? getDate(props.birthDate) : undefined,
    tin: props.tin,
    email: props.email,
    phoneNumber: props.phoneNumber,
    phoneNumberCountryCode: props.phoneNumberCountryCode,
    employmentStartDate: getDate(props.employmentStartDate),
    departmentID: props.departmentID,
    sendLogin: props.sendLogin,
    salaryCycleID: props.salaryCycleID,
    contractTemplateID: props.contractTemplateID ? props.contractTemplateID : NO_TEMPLATE,
    payType: props.payType || 'Salaried',
    remuneration: props.remuneration,
  }),
  onChange: (key, val, allValues) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'nationalID':
        values.nationalID = normalizeNationalID(val as string, allValues.nationalIDType)
        break
      case 'postalCode': {
        values.postalCode = val as string
        const city = getCityFromPostalCode(val as string)
        if (city) {
          values.city = city
        }
        break
      }
      case 'phoneNumber':
        values.phoneNumber = trimSpaces(val as string)
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    if (values.contractTemplateID === NO_TEMPLATE) {
      values.contractTemplateID = undefined
    }
    if (values.nationalID) {
      values.nationalID = values.nationalID.replace(/-/g, '')
    }
    return {
      ...values,
      employeeNumber: values.employeeNumber!,
      name: values.name!,
      address: values.address!,
      city: values.city!,
      postalCode: values.postalCode!,
      birthDate: values.birthDate ? formatAPIDate(values.birthDate) : undefined,
      employmentStartDate: values.employmentStartDate ? formatAPIDate(values.employmentStartDate) : undefined,
      step: 'BaseData',
    }
  },
})(BaseDataForm)
