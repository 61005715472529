import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import ContractTemplate from '../../model/contractTemplate'
import Department from '../../model/department'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { AlertReducer } from '../../reducers/alerts'
import { ContractTemplateContractReducer } from '../../reducers/contractTemplateContracts'
import { ContractTemplateReducer } from '../../reducers/contractTemplates'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ContractTemplateForm, { ContractTemplateResult } from './ContractTemplateForm'

type Props = {
  alerts: AlertReducer
  contractTemplates: ContractTemplateReducer
  contractTemplateID: string
  contractTemplateContracts: ContractTemplateContractReducer
  employmentPositions: List<EmploymentPosition>
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  pensionCompanies: List<PensionCompany>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractTemplateContracts: (contractTemplateID: string) => void
  updateContractTemplate: (template: ContractTemplate) => Promise<ContractTemplate | void>
  deleteContractTemplate: (contractTemplateID: string) => void
}

export default function ContractTemplateEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { contractTemplateContracts, contractTemplateID, getContractTemplateContracts } = props

  useEffect(() => {
    if (
      contractTemplateContracts.contractTemplateID !== contractTemplateID ||
      (!contractTemplateContracts.loading && !contractTemplateContracts.loaded)
    ) {
      getContractTemplateContracts(contractTemplateID)
    }
  })

  const { contractTemplates, addAlert } = props
  const previousContractTemplates = usePrevious(contractTemplates)

  useEffect(() => {
    // Check for save callback
    if (previousContractTemplates && previousContractTemplates.saving && !contractTemplates.saving) {
      // Check for no error occurred
      if (!contractTemplates.error) {
        addAlert('success', t('contract_template.alert.success'), { timeout: 5 })

        jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(contractTemplates.error, error, setError)
  }, [contractTemplates, error])

  const deleteContractTemplate = () => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        props.deleteContractTemplate(props.contractTemplateID)
        jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
      }
    }
  }

  const _handleSubmit = (values: ContractTemplateResult) => {
    const template = props.contractTemplates.contractTemplates.find(
      (template) => template.id === props.contractTemplateID
    )
    if (!template) {
      return
    }
    template.title = values.title
    template.description = values.description
    props.updateContractTemplate(template).then((res) => {
      if (res && res.id) {
        props.addAlert('success', t('contract_template.alert.success'), { timeout: 5 })

        jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
      }
    })
  }

  if (!props.contractTemplateContracts.loaded) {
    return <LoadingOverlay />
  }
  const template = props.contractTemplates.contractTemplates.find(
    (template) => template.id === props.contractTemplateID
  )
  if (!template) {
    jsBrowserHistory.push('/' + paths.EMPLOYEE_TEMPLATES)
    return null
  }
  return (
    <Card>
      <TitleMenu>
        <Button style={{ marginRight: '15px' }} danger onClick={deleteContractTemplate()}>
          {t('contract_template.edit.delete')}
        </Button>
        <Link to={'/' + paths.EMPLOYEE_TEMPLATES}>
          <Button>{t('contract_template.edit.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('contract_template.edit.title')}</Title>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <p>{t('contract_template.edit.intro')}</p>
      <ContractTemplateForm
        creating={false}
        contractTemplate={template}
        contractTemplateContracts={props.contractTemplateContracts.contractTemplateContracts}
        employmentPositions={props.employmentPositions}
        departments={props.departments}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
        pensionCompanies={props.pensionCompanies}
        onSubmit={_handleSubmit}
      />
    </Card>
  )
}
