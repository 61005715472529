import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Employee from '../../model/employee'
import { getAccessToken } from '../../utils/cookie-utils'
import { compareEmployees } from '../../utils/employee-utils'
import { combineSearchOption } from '../../utils/form-utils'
import { url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import { FileChangeEvent, HandleFileEvents } from '../../utils/upload-utils'
import UploadDragger from '../antd/upload/Dragger'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Select from '../elements/select'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  employees: List<Employee>

  onSubmit: (fileID: string, employeeID?: string) => void
  onError: (e: Error) => void
}

export default function ReimbursementVoucherNewForm(props: Props): ReactElement | null {
  const [employeeID, setEmployeeID] = useState<string | undefined>(undefined)
  const [uploading, setUploading] = useState(false)

  const handleFileUpload = (e: FileChangeEvent) =>
    HandleFileEvents(e, {
      onUploading: () => setUploading(true),
      onDone: (data) => {
        if (data) {
          props.onSubmit(data.id, employeeID)
        }
      },
      onError: (e) => {
        if (e) {
          props.onError(e)
        }
      },
      onFinally: () => setUploading(false),
    })

  return (
    <div>
      {uploading && <LoadingOverlay />}
      <Row>
        <Col span={24}>
          <Select
            tabIndex={1}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) =>
              combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            placeholder={t('reimbursement_vouchers.new.form.employee.placeholder')}
            onChange={(value) => setEmployeeID(value as string)}
          >
            {props.employees
              .sort((a, b) => {
                const { orderA, orderB } = {
                  orderA: a.employmentStatus === 'Terminated' ? 1 : 0,
                  orderB: b.employmentStatus === 'Terminated' ? 1 : 0,
                }
                if (orderA === orderB) {
                  return compareEmployees(a, b)
                }
                return orderA - orderB
              })
              .map((employee) => {
                const name = employee.name || employee.email
                return (
                  <Select.Option key={employee.id} value={employee.id} title={name}>
                    {employee.employmentStatus === 'Terminated'
                      ? t('reimbursement_vouchers.new.form.employee.terminated', { name })
                      : employee.disableReimbursementVouchers
                      ? t('reimbursement_vouchers.new.form.employee.disabled', { name })
                      : name}
                  </Select.Option>
                )
              })}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <UploadDragger
            tabIndex={2}
            name={'fileData'}
            action={url('v2/stagedFiles')}
            headers={{ Authorization: getAccessToken() }}
            accept={'*'}
            showUploadList={false}
            onChange={handleFileUpload}
            disabled={!employeeID}
          >
            <Button
              type="secondary"
              className="gtm-reimbursement-upload"
              disabled={!employeeID}
              prefixIcon="arrowUpInsideCloud"
            >
              {t('reimbursement_vouchers.new.form.upload')}
            </Button>
          </UploadDragger>
        </Col>
      </Row>
    </div>
  )
}
