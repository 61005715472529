import React, { ReactElement } from 'react'

import { ContractBookDraftReducer } from '../../reducers/contractBookDrafts'
import { ContractBookTemplateReducer } from '../../reducers/contractBookTemplates'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  contractBookTemplates: ContractBookTemplateReducer
  contractBookDrafts: ContractBookDraftReducer
}

export type ContractBookResult = {
  templateID?: string
}

function ContractBookForm(
  props: Props & FormComponentProps<ContractBookResult, ContractBookResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('templateID', {
            title: t('employees.contract_book.form.template_id'),
            placeholder: t('employees.contract_book.form.template_id.placeholder'),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              optionLabelProp="title"
              showSearch={true}
              filterOption={(input: string, option: ReactElement) =>
                option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.contractBookTemplates.templates.map((template) => {
                return (
                  <Select.Option key={template.id} value={template.id} title={template.title}>
                    {template.title}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('employees.contract_book.form.template_id.submit')}
          </Button>
        </Col>
      </Row>
      {props.contractBookDrafts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, ContractBookResult, ContractBookResult>({
  onSubmit: (values) => values,
})(ContractBookForm)
