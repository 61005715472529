import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatInputNumber, softForceParseInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Title from '../elements/Title'
import SwitchWrapper from '../form-elements/SwitchWrapper'

type Props = {
  visible: boolean
  company: Company
}

type Fields = {
  DAUnionCode?: string
  enableYearlySalaryStatistics: boolean
  enableQuarterlySalaryStatistics: boolean
  enableAbsenceStatistics: boolean
}

export type AutomaticFields = {
  DAUnionCode?: number
  enableYearlySalaryStatistics: boolean
  enableQuarterlySalaryStatistics: boolean
  enableAbsenceStatistics: boolean
}

function ReportDSTAutomaticModal(props: Props & FormComponentProps<Fields, AutomaticFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <Card className="company-report-dst">
      <Title>{t('report_dst.automatic.title')}</Title>
      <p>{t('report_dst.automatic.note.line_1')}</p>
      <p>{t('report_dst.automatic.note.line_2')}</p>
      <Row>
        <Col span={24}>
          {decorateField('DAUnionCode', {
            placeholder: t('report_dst.automatic.form.da_union_code'),
            validate: (val) => {
              if (!val || val === '') {
                return null
              }
              if (!val.match(/^[0-9]+$/) || val.length !== 4) {
                return t('report_dst.automatic.form.da_union_code.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'enableYearlySalaryStatistics'} decorateField={decorateField}>
            {t('report_dst.automatic.form.enable_yearly_salary_statistics')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'enableQuarterlySalaryStatistics'} decorateField={decorateField}>
            {t('report_dst.automatic.form.enable_quarterly_salary_statistics')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<Fields> id={'enableAbsenceStatistics'} decorateField={decorateField}>
            {t('report_dst.automatic.form.enable_absence_statistics')}
          </SwitchWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, AutomaticFields>({
  mapPropsToFields: (props) => ({
    DAUnionCode: props.company.DAUnionCode ? formatInputNumber(props.company.DAUnionCode) : undefined,
    enableYearlySalaryStatistics: props.company.enableYearlySalaryStatistics,
    enableQuarterlySalaryStatistics: props.company.enableQuarterlySalaryStatistics,
    enableAbsenceStatistics: props.company.enableAbsenceStatistics,
  }),
  onSubmit: (values) => ({
    DAUnionCode: softForceParseInputNumber(values.DAUnionCode),
    enableYearlySalaryStatistics: values.enableYearlySalaryStatistics,
    enableQuarterlySalaryStatistics: values.enableQuarterlySalaryStatistics,
    enableAbsenceStatistics: values.enableAbsenceStatistics,
  }),
})(ReportDSTAutomaticModal)
