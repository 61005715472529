import { getAntdStrings, getStrings } from '../locale'
import Language from '../types/language'
import { getLanguage, setLanguage } from './cookie-utils'

let overrideLanguage: Language
let currentLanguage: Language | undefined
let currentStrings: Record<string, string> | undefined

// for testing purposes
export function setOverrideLanguage(language: Language) {
  currentStrings = undefined
  overrideLanguage = language
}

function internalGetCurrentLanguage(): Language | undefined {
  if (overrideLanguage) {
    return overrideLanguage
  }
  if (currentLanguage) {
    return currentLanguage
  }
  currentLanguage = getLanguage()
  return currentLanguage
}

function getDefaultLanguage(): Language {
  let lang
  if (typeof navigator !== 'undefined') {
    lang = navigator.languages !== undefined ? navigator.languages[0] : navigator.language
  }
  if (lang && !lang.match(/^da.*/)) {
    // use English if not Danish
    return Language.ENGLISH
  }
  return Language.DANISH
}

export function getCurrentLanguage(): Language {
  return internalGetCurrentLanguage() ?? getDefaultLanguage()
}

export function getCurrentLocale(): 'da-DK' | 'en-GB' {
  const currentLang = getCurrentLanguage()
  switch (currentLang) {
    case Language.DANISH:
      return 'da-DK'
    case Language.ENGLISH:
      return 'en-GB'
    default:
      return 'da-DK'
  }
}

export function getTranslationStrings(): Record<string, string> {
  if (currentStrings) {
    return currentStrings
  }
  currentStrings = getStrings(getCurrentLanguage())
  return currentStrings
}

export function getTranslationAntdStrings(): Record<string, any> {
  return getAntdStrings(getCurrentLanguage())
}

export function setCurrentLanguage(language: Language) {
  currentLanguage = language
  currentStrings = undefined
  setLanguage(language)
}
