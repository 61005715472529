import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { getStagedImportData } from '../../api/data'
import paths from '../../constants/paths'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import SalaryCycle from '../../model/salaryCycle'
import { StagedImportDataEmployee } from '../../model/stagedImport'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatDateTime } from '../../utils/date-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { formatDisplayNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AsynchronousTaskSelector from './AsynchronousTaskSelector'
import AsynchronousTaskStatusDisplay from './AsynchronousTaskStatusDisplay'
import PeriodSelector from './PeriodSelector'

type Props = {
  asynchronousTasks: AsynchronousTaskReducer
  company: Company
  payRolls: List<PayRoll>
  employees: List<Employee>
  salaryCycles: List<SalaryCycle>
  displayName: string

  addAlert: addAlertSignature
  startCarAllowanceImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string, employeesToImport?: string[]) => Promise<AsynchronousTask | void>
  getAsynchronousTask: (id: string) => void
}

type State = {
  employees?: StagedImportDataEmployee[]
  step: number
  asynchronousTask?: AsynchronousTask
  importing: boolean
  saving: boolean
}

export default function CarAllowancesImport(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({ step: 0, importing: false, saving: false })
  const [salaryPeriodID, setSalaryPeriodID] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [taskError, setTaskError] = useState<Error | null>(null)

  const loadEmployees = (task: AsynchronousTask) => {
    if (task.status !== 'Success') {
      return
    }
    setLoading(true)
    getStagedImportData(task.id).then((res) => {
      setState((prev) => ({
        ...prev,
        employees: res.data.companies.reduce((l: StagedImportDataEmployee[], c) => [...l, ...c.employees], []),
        importing: false,
      }))
      setLoading(false)
    })
  }

  const { asynchronousTasks } = props
  useEffectOnce(() => {
    const task = asynchronousTasks.asynchronousTasks
      .filter((task) => task.type === 'ExternalCarAllowanceImportGather' && task.status === 'Success')
      .sort((a, b) => (a.startedAt || '').localeCompare(b.startedAt || ''))
      .last()
    if (task) {
      loadEmployees(task)
    }
  })

  const { addAlert } = props

  useEffect(() => {
    const existingTask = state.asynchronousTask
    if (!existingTask) {
      return
    }
    const updatedTask = asynchronousTasks.asynchronousTasks.find((task) => task.id === existingTask.id)
    if (!updatedTask) {
      return
    }
    switch (updatedTask.type) {
      case 'ExternalCarAllowanceImportGather': {
        let nextImporting = state.importing
        if (nextImporting && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextImporting = false
        }
        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }
        if (state.importing !== nextImporting || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.importing !== nextImporting) {
              prev = { ...prev, importing: nextImporting }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success' && !state.employees && !loading) {
          loadEmployees(updatedTask)
        }
        break
      }
      case 'StagedDataSave': {
        let nextSaving = state.saving
        if (nextSaving && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextSaving = false
        }

        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }

        if (state.saving !== nextSaving || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.saving !== nextSaving) {
              prev = { ...prev, saving: nextSaving }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success') {
          addAlert('success', t('data_integration.car_allowance_import.alert.success'), { timeout: 5 })
          jsBrowserHistory.push('/' + paths.CAR_ALLOWANCE)
        }
        break
      }
      default:
        break
    }
  }, [asynchronousTasks, state, loading, taskError, addAlert])

  useEffect(() => {
    regularComponentDidUpdate(asynchronousTasks.error, error, setError)
  }, [asynchronousTasks, error])

  const startImport = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!salaryPeriodID) {
      setState((prev) => ({ ...prev, importing: false }))
      setTaskError(new Error(t('data_integration.car_allowance_import.error.no_period')))
      return
    }
    props.startCarAllowanceImport(props.company.id, salaryPeriodID).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: res,
        importing: true,
        employees: undefined,
      }))
      loadEmployees(res)
    })
  }

  const refreshTask = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.getAsynchronousTask(state.asynchronousTask.id)
  }

  const saveImport = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.storeStagedImportData(state.asynchronousTask.id).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 2,
        asynchronousTask: res,
        importing: false,
        saving: true,
      }))
    })
  }

  const selectAsynchronousTask = (taskID: string) => {
    const task = props.asynchronousTasks.asynchronousTasks.find((task) => task.id === taskID)
    if (task) {
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: task,
        importing: true,
        saving: false,
        employees: undefined,
      }))
      if (task.gatherInformation?.salaryPeriodID) {
        setSalaryPeriodID(task.gatherInformation.salaryPeriodID)
      }
      loadEmployees(task)
      setTaskError(convertStoredErrorToError(task.error))
    }
  }

  type EmployeeRow = {
    key: string
    name: string
    externalID: string
    employeeID?: string
    nameSalary?: string
    errors: string[]
    canImport: boolean
    amount: string
    quantity: string
  }

  const columns = [
    {
      key: 'xName',
      title: t('data_integration.car_allowance_import.table.header.name'),
      render: (employee: EmployeeRow) => {
        return (
          <div>
            {employee.name || (
              <i>
                {t('data_integration.car_allowance_import.table.external_id')}: {employee.externalID || '-'}
              </i>
            )}
            {employee.name && employee.employeeID && employee.nameSalary && employee.name !== employee.nameSalary ? (
              <>
                <br />({employee.nameSalary})
              </>
            ) : (
              ''
            )}
            {employee.errors.map((err, i) => (
              <React.Fragment key={i}>
                <br />
                <strong className="leave-registration-integration-error">{err}</strong>
              </React.Fragment>
            ))}
          </div>
        )
      },
    },
    {
      key: 'quantity',
      title: t('data_integration.car_allowance_import.table.header.quantity'),
      dataIndex: 'quantity',
    },
    {
      key: 'amount',
      title: t('data_integration.car_allowance_import.table.header.amount'),
      dataIndex: 'amount',
    },
  ]

  const getEmployeeRows = (): EmployeeRow[] => {
    if (!state.employees) {
      return []
    }
    return state.employees
      .sort((a, b) => {
        const canImportA = a.errors.length === 0
        const canImportB = b.errors.length === 0
        if (canImportA === canImportB) {
          return (a.name ?? a.additionalData?.currentSalaryName ?? '-').localeCompare(
            b.name ?? b.additionalData?.currentSalaryName ?? '-'
          )
        }
        if (canImportA) {
          return 1
        }
        return -1
      })
      .map((employee) => ({
        key: employee.employeeID ?? employee.importID,
        name: employee.name ?? employee.additionalData?.currentSalaryName ?? employee.email ?? t('common.unknown'),
        externalID: employee.additionalData?.externalID ?? t('common.unknown'),
        employeeID: employee.employeeID,
        nameSalary: employee.additionalData?.currentSalaryName,
        errors: employee.errors.map((err) => err.message),
        canImport: employee.errors.length === 0,
        quantity: formatDisplayNumber(employee.carAllowances.length),
        amount: formatDisplayNumber(employee.carAllowances.reduce((amount, ca) => amount + ca.kilometers, 0)),
      }))
  }

  if (!props.asynchronousTasks.loaded) {
    return (
      <LoadingOverlay
        text={formatLoadingText([
          { loading: !props.asynchronousTasks.loaded, text: t('loading.reducer.asynchronous_tasks') },
        ])}
      />
    )
  }

  let errors = 0
  if (state.step === 1 && !state.importing && state.employees) {
    errors = state.employees.reduce((e, emp) => e + (emp.errors.length > 0 ? 1 : 0), 0)
  }

  const isUsed = state.asynchronousTask?.gatherInformation?.used ?? false

  return (
    <div>
      {taskError && (
        <Alert
          message={
            <>
              {formatError(taskError)}
              <Button
                onClick={startImport}
                regular
                className="gtm-reimbursements-import"
                style={{ float: 'right' }}
                size="large"
                type="secondary"
              >
                {t('data_integration.car_allowance_import.actions.start_import_again')}
              </Button>
            </>
          }
          type="error"
          showIcon
        />
      )}
      {!taskError && error && <Alert message={formatError(error)} type="error" showIcon />}
      {isUsed && (
        <Alert
          type={'warning'}
          message={
            <>
              {t('data_integration.car_allowance_import.warning.already_used')}
              <Button
                onClick={startImport}
                regular
                className="gtm-reimbursements-import"
                style={{ float: 'right' }}
                size="large"
                type="secondary"
              >
                {t('data_integration.car_allowance_import.actions.start_import_again')}
              </Button>
            </>
          }
          showIcon
        />
      )}
      <TitleMenu style={{ width: '400px' }}>
        <AsynchronousTaskSelector
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          asynchronousTaskType={'ExternalCarAllowanceImportGather'}
          asynchronousTask={state.asynchronousTask}
          onChange={selectAsynchronousTask}
        />
      </TitleMenu>
      <Title>{t('data_integration.car_allowance_import.title', { displayName: props.displayName })}</Title>
      {state.step === 0 && (
        <Row>
          <Col span={24} className="button-row-wrapper">
            <PeriodSelector
              payRolls={props.payRolls}
              salaryCycles={props.salaryCycles}
              periodID={salaryPeriodID}
              onSelected={(salaryPeriodID) => setSalaryPeriodID(salaryPeriodID)}
              preferOffset={false}
            />
            <Button onClick={startImport} type="primary" className="gtm-leave-registrations-import" size="large">
              {t('data_integration.car_allowance_import.actions.start_import')}
            </Button>
          </Col>
        </Row>
      )}
      {state.step === 1 && state.importing && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.car_allowance_import.status.importing.title')}
          description={t('data_integration.car_allowance_import.status.importing.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {state.step === 2 && state.saving && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.car_allowance_import.status.saving.title')}
          description={t('data_integration.car_allowance_import.status.saving.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {state.step === 1 && !state.importing && state.employees && (
        <div className="leave-registration-integration-import-list" style={{ textAlign: 'left', fontSize: '14px' }}>
          {errors > 0 && (
            <Alert
              key={'employee-map-error'}
              message={t('data_integration.car_allowance_import.error.employees_cannot_be_imported', { count: errors })}
              type="error"
              showIcon
            />
          )}
          <Row>
            <Col span={12}>
              {!isUsed && (
                <Button
                  onClick={saveImport}
                  type="primary"
                  className="gtm-leave-registrations-import-save"
                  size="large"
                >
                  {t('data_integration.car_allowance_import.actions.save_import')}
                </Button>
              )}
              {state.asynchronousTask?.finishedAt && (
                <span style={{ paddingLeft: '15px' }}>
                  {t('data_integration.car_allowance_import.data_imported', {
                    date: formatDateTime(state.asynchronousTask?.finishedAt),
                  })}
                </span>
              )}
            </Col>
            <Col span={12} className="button-row-wrapper">
              <PeriodSelector
                payRolls={props.payRolls}
                salaryCycles={props.salaryCycles}
                periodID={salaryPeriodID}
                onSelected={(salaryPeriodID) => setSalaryPeriodID(salaryPeriodID)}
                preferOffset={false}
              />
              <Button
                size="large"
                onClick={startImport}
                className="gtm-leave-registrations-new-import"
                style={{ float: 'right' }}
                type="secondary"
              >
                {t('data_integration.car_allowance_import.actions.start_import_again')}
              </Button>
            </Col>
          </Row>
          <Table columns={columns} dataSource={getEmployeeRows()} pagination={false} />
          <Row style={{ marginTop: '35px' }}>
            <Col span={24}>
              {!isUsed && (
                <Button
                  onClick={saveImport}
                  type="primary"
                  className="gtm-leave-registrations-import-save"
                  size="large"
                >
                  {t('data_integration.car_allowance_import.actions.save_import')}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}

      {!taskError && !error && state.step === 1 && !state.importing && !state.employees && (
        <LoadingOverlay text={t('data_integration.car_allowance_import.importing')} />
      )}
    </div>
  )
}
