import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import PaymentIntegration from '../../model/paymentIntegration'
import { CompanyReducer } from '../../reducers/companies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companies: CompanyReducer
  company: Company
  integration: PaymentIntegration

  addAlert: addAlertSignature
}

export type TransferNemKontoFields = {
  enableNemKontoTransfers: boolean
}

function TransferNemKontoForm(
  props: Props & FormComponentProps<TransferNemKontoFields, TransferNemKontoFields>
): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companies, addAlert, company } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      // Check for no error occurred
      if (!companies.error) {
        addAlert('success', t('transfer_easy_account.alert.success', { name: company.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(companies.error, error, setError)
  }, [previousCompanies, companies, company, addAlert, error])

  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Form.Item style={{ marginTop: -5 }}>
        <div className="ant-switch-wrapper">
          {decorateField('enableNemKontoTransfers', {
            skipWrapper: true,
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
          <span className="ant-switch-text">{t('transfer_easy_account.form.enable_easy_account_transfers')}</span>
        </div>
      </Form.Item>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" className="gtm-transfer-settings-save">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, TransferNemKontoFields, TransferNemKontoFields>({
  mapPropsToFields: (props) => ({
    enableNemKontoTransfers: props.company.enableNemKontoTransfers,
  }),
  onSubmit: (values) => values,
})(TransferNemKontoForm)
