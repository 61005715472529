import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'

export default function Rmindr(): ReactElement | null {
  return (
    <div>
      <Card className="card-rmindr">
        <img
          src="/images/rmindr-logo.png"
          alt="Rmindr"
          style={{ width: '40%', height: 'auto', display: 'block', marginBottom: '25px', float: 'right' }}
        />
        <Title>{t('basic_integrations.rmindr.title')}</Title>
        <p>{t('basic_integrations.rmindr.line_1')}</p>
        <p>{t('basic_integrations.rmindr.line_2')}</p>
        <img
          src="/images/rmindr-preview.jpg"
          alt="Rmindr"
          style={{ width: '40%', height: 'auto', display: 'block', marginBottom: '25px', float: 'right' }}
        />
        <p>{t('basic_integrations.rmindr.line_3')}</p>
        <ol>
          <li>{t('basic_integrations.rmindr.line_3.points.0')}</li>
          <li>{t('basic_integrations.rmindr.line_3.points.1')}</li>
          <li>{t('basic_integrations.rmindr.line_3.points.2')}</li>
          <li>{t('basic_integrations.rmindr.line_3.points.3')}</li>
          <li>{t('basic_integrations.rmindr.line_3.points.4')}</li>
          <li>{t('basic_integrations.rmindr.line_3.points.5')}</li>
        </ol>
        <p>{t('basic_integrations.rmindr.line_4')}</p>
        <p>{tx('basic_integrations.rmindr.line_5', { code: <code>Salary20</code> })}</p>
        <Link to={'https://www.rmindr.io/'} target={'_blank'}>
          <Button size="extra-extra-large" type="secondary">
            {t('basic_integrations.rmindr.sign_up')}
          </Button>
        </Link>
      </Card>
    </div>
  )
}
