import RcCheckbox from 'rc-checkbox'
import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import classNames from '../../antd/_util/classNames'
import { useRadioGroupContext } from './group'

export type RadioProps<DataType> = {
  prefixCls?: string
  className?: string
  style?: CSSProperties
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  name?: string
  value: DataType
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
  children?: ReactNode
}

export default function Radio<DataType = string>(props: RadioProps<DataType>): ReactElement | null {
  const { prefixCls = 'ant-radio', className, children, style, ...restProps } = props
  const { groupState, onChange } = useRadioGroupContext()
  const radioProps = { ...restProps }
  if (groupState) {
    radioProps.name = groupState.name
    radioProps.onChange = onChange
    radioProps.checked = props.value === groupState.value
    radioProps.disabled = props.disabled || groupState.disabled
  }
  const wrapperClassString = classNames(className, {
    [`${prefixCls}-wrapper`]: true,
    [`${prefixCls}-wrapper-checked`]: radioProps.checked,
    [`${prefixCls}-wrapper-disabled`]: radioProps.disabled,
  })

  return (
    <label
      className={wrapperClassString}
      style={style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <RcCheckbox {...radioProps} prefixCls={prefixCls} type="radio" />
      {children !== undefined && <span>{children}</span>}
    </label>
  )
}
