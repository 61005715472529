import React, { ReactElement } from 'react'

import Voucher from '../../model/voucher'
import { getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker/DatePicker'
import { Col, Row } from '../elements/grid'

type Props = {
  voucher: Voucher
}

export type VoucherDateResult = {
  date: Date
}

function VoucherDateModalForm(
  props: Props & FormComponentProps<VoucherDateResult, VoucherDateResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <>
      <Row>
        <Col span={24}>
          {decorateField('date', {
            title: t('voucher_date_modal.form.date'),
          })(<DatePicker allowClear={false} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withValidations<Props, VoucherDateResult, VoucherDateResult>({
  mapPropsToFields: (props) => ({ date: getDate(props.voucher.date) }),
  onSubmit: (values) => values,
})(VoucherDateModalForm)
