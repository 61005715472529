import { addMonths, max, setDate, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import { CoarseTimeRegistrationMutableFields } from '../../../model/coarseTimeRegistration'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryPeriod from '../../../model/salaryPeriod'
import SalaryType from '../../../model/salaryType'
import { DateFormat } from '../../../model/types'
import { CoarseTimeRegistrationReducer } from '../../../reducers/coarseTimeRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isSameOrBefore } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t, tx } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Alert from '../../elements/alert'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Select from '../../elements/select'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CoarseTimeRegistrationForm, { CoarseTimeRegistrationResult } from './CoarseTimeRegistrationForm'

type Props = {
  preventPeriodChange: boolean
  employee: Employee
  salaryPeriodID?: string
  contracts: ContractReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  addContract: (contract: Contract) => void
  updateContract: (contract: Contract) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
}

export default function CoarseTimeRegistrationTab(props: Props): ReactElement | null {
  const [salaryPeriodID, setSalaryPeriodID] = useState(() => {
    let periodID = props.salaryPeriodID
    if (!periodID) {
      periodID = getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)?.id
    }
    return periodID
  })
  const [error, setError] = useState<Error | null>(null)

  const { coarseTimeRegistrations, addAlert } = props
  const previousCoarseTimeRegistrations = usePrevious(coarseTimeRegistrations)

  useEffect(() => {
    if (previousCoarseTimeRegistrations && previousCoarseTimeRegistrations.saving && !coarseTimeRegistrations.saving) {
      if (!coarseTimeRegistrations.error) {
        addAlert('success', t('time_registration_tab.coarse.alert.success'), { timeout: 5 })
      }
    }
  })

  const { contracts } = props

  useEffect(() => {
    regularComponentDidUpdate(contracts.error || coarseTimeRegistrations.error, error, setError)
  }, [contracts, coarseTimeRegistrations, error])

  type SalaryPeriodInfo = SalaryPeriod & {
    first: DateFormat
  }

  const getSalaryPeriods = (): SalaryPeriodInfo[] => {
    const contract = props.employee.activeContract
    if (!contract) {
      return []
    }
    let useFirst = false
    const salaryCycles = props.salaryCycles.filter(
      (item) => item.id === contract.salaryCycleID && item.frequency === 'Monthly'
    )
    if (salaryCycles.size > 0) {
      useFirst = true
    }
    return props.salaryCycle.salaryPeriods
      .map(
        (period): SalaryPeriodInfo => ({
          ...period,
          first: useFirst ? formatAPIDate(setDate(getDate(period.end), 1)) : period.start,
        })
      )
      .filter((salaryPeriod) => {
        const date = max([getDate(salaryPeriod.dispositionDate), getDate(salaryPeriod.end)])
        return isSameOrAfter(date, subYears(getDate(), 1)) && isSameOrBefore(date, addMonths(getDate(), 1))
      })
      .sort((a, b) => b.start.localeCompare(a.start))
  }

  const getPeriod = () => {
    return props.salaryCycle.salaryPeriods.find((salaryPeriod) => salaryPeriod.id === salaryPeriodID)
  }

  const handleSubmit = (values: CoarseTimeRegistrationResult) => {
    const period = getPeriod()
    if (!period) {
      return
    }
    const employee = props.employee
    if (employee.earliestMutableContract) {
      const contract = { ...employee.earliestMutableContract }
      if (contract.workSchedule !== values.workSchedule) {
        contract.workSchedule = values.workSchedule
        const { validFrom, canUpdate } = getValidFrom(employee, contract)
        if (canUpdate) {
          props.updateContract(contract)
        } else {
          contract.validFrom = formatAPIDate(validFrom)
          props.addContract(contract)
        }
      }
    }
    let workDays = values.workDays
    const registrations = Object.keys(values.workHours).reduce(
      (registrations: CoarseTimeRegistrationMutableFields[], salaryTypeID) => {
        const salaryClass = props.salaryTypes.find((type) => type.id === salaryTypeID)?.class
        const days = salaryClass && salaryClass === 'Hourly' ? workDays : 0
        if (days > 0) {
          workDays = 0 // so this is the only time it gets registered
        }
        registrations.push({
          employeeID: employee.id,
          salaryPeriodID: period.id,
          salaryTypeID: salaryTypeID,
          days: Math.ceil(days), // make sure it is always an integer
          hours: values.workHours[salaryTypeID],
        })
        return registrations
      },
      []
    )
    props.updateCoarseTimeRegistrationBulk(employee.id, registrations)
  }

  const getCoarseTimeRegistrations = () => {
    const period = getPeriod()
    if (!period) {
      return []
    }
    return props.coarseTimeRegistrations.coarseTimeRegistrations.filter(
      (registration) => registration.employeeID === props.employee.id && registration.salaryPeriodID === period.id
    )
  }
  const getWorkDays = () => {
    const registrations = getCoarseTimeRegistrations()
    let workDays = 0
    let hasWorkDays = false
    registrations.forEach((registration) => {
      workDays += registration.days
      hasWorkDays = true
    })
    return hasWorkDays ? workDays : undefined
  }
  const getWorkHours = () => {
    const workHours: Record<string, number> = {}
    const registrations = getCoarseTimeRegistrations()
    registrations.forEach((registration) => {
      workHours[registration.salaryTypeID] = registration.hours
    })
    return workHours
  }

  const period = getPeriod()
  const contract = props.employee.activeContract
  if (!period || !contract) {
    return null // probably shouldn't happen
  }
  return (
    <>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {props.preventPeriodChange ? (
        <p>
          {t('time_registration_tab.coarse.prevent_period_change.line_1', {
            start: formatDate(period.start),
            end: formatDate(period.end),
          })}
          <br />
          {tx('time_registration_tab.coarse.prevent_period_change.line_2', {
            link: (
              <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/time-registration'}>
                {t('time_registration_tab.coarse.prevent_period_change.line_2.link')}
              </Link>
            ),
          })}
        </p>
      ) : (
        <Row>
          <Col span={12}>
            <Form.Item style={{ marginBottom: 10 }}>
              <label style={{ marginBottom: 0 }}>{t('time_registration_tab.coarse.select_period')}</label>
              <Select
                value={salaryPeriodID}
                dropdownMatchSelectWidth={false}
                onChange={(e) => setSalaryPeriodID(e as string)}
              >
                {getSalaryPeriods().map((salaryPeriod) => {
                  return (
                    <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                      {formatDate(salaryPeriod.start)} - {formatDate(salaryPeriod.end)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <CoarseTimeRegistrationForm
        key={salaryPeriodID}
        employee={props.employee}
        salaryTypes={props.salaryTypes}
        workHours={getWorkHours()}
        workDays={getWorkDays()}
        period={period}
        workSchedule={contract.workSchedule}
        addAlert={props.addAlert}
        onSubmit={handleSubmit}
      />
      {(props.contracts.saving || props.coarseTimeRegistrations.saving) && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.contracts.saving, text: t('loading.reducer.contracts') },
            { loading: props.coarseTimeRegistrations.saving, text: t('loading.reducer.coarse_time_registrations') },
          ])}
        />
      )}
    </>
  )
}
