import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanySetting from '../../../model/companySetting'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import PensionCompany from '../../../model/pensionCompany'
import { LeaveDefinitionMutableFields, PensionDefinitionMutableFields } from '../../../model/remuneration'
import { ContractReducer } from '../../../reducers/contracts'
import PensionSchemes from '../../../types/pension-scheme'
import PensionScheme from '../../../types/pension-scheme'
import { formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import PensionForm, { PensionRow } from '../../contracts-add/PensionForm'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  mutableContract: Contract
  validFrom: Date
  settingsEnabled: CompanySetting[]
  employee: Employee
  contracts: ContractReducer
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>
}

type Fields = {
  hasPension: boolean
  hasNetPension: boolean
  hasUnpaidVacation: boolean
  includeVacationInPensionBasis: boolean
  useATP: boolean
  remuneration: {
    pension: PensionRow[]
  }
}

export type PensionResult = {
  remuneration: {
    pension: PensionDefinitionMutableFields[]
    leave: LeaveDefinitionMutableFields[]
  }
}

function EmploymentPensionEditForm(props: Props & FormComponentProps<Fields, PensionResult>): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <PensionForm<Fields>
        decorateField={props.decorateField}
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        settingsEnabled={props.settingsEnabled}
        employee={props.employee}
        pensionCompanies={props.pensionCompanies}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(props.validFrom, getDate())
              ? t('pension.card.edit.form.valid_from.at_date', { date: formatDate(props.validFrom) })
              : t('pension.card.edit.form.valid_from.now')}
          </div>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, PensionResult>({
  mapPropsToFields: (props) => {
    const getLeaveType = (id: string) => props.leaveTypes.find((type) => type.id === id)
    let hasNetPension = false
    const fields: Fields = {
      hasNetPension: false,
      hasPension: props.mutableContract.remuneration?.pension.some((row) => row.scheme !== 'ATP') || false,
      hasUnpaidVacation:
        props.mutableContract.remuneration?.leave.some((row) => {
          const type = row.type || getLeaveType(row.typeID)
          return type && (type.name === 'DenmarkVacationFund' || type.name === 'DenmarkVacationFundWithPension')
        }) || false,
      includeVacationInPensionBasis:
        props.mutableContract.remuneration?.leave.some((row) => {
          const type = row.type || getLeaveType(row.typeID)
          return type && type.name === 'DenmarkVacationFundWithPension'
        }) || false,
      useATP: props.mutableContract.remuneration?.pension.some((row) => row.scheme === 'ATP') || false,
      remuneration: {
        pension:
          props.mutableContract.remuneration?.pension
            .filter((row) => row.scheme !== 'ATP')
            .map((row) => {
              let pensionCompanyName = undefined
              let hasUnionAgreementNumber = true
              let customerIDType
              if (row.pensionCompanyID) {
                const pensionCompany = props.pensionCompanies.find((company) => company.id === row.pensionCompanyID)
                if (pensionCompany) {
                  pensionCompanyName = pensionCompany.name
                  customerIDType = pensionCompany.customerIDType
                  hasUnionAgreementNumber =
                    pensionCompany.fields && pensionCompany.fields.indexOf('UnionAgreementNumber') !== -1
                }
              }
              let scheme = row.scheme
              switch (scheme) {
                case PensionSchemes.EMPLOYER_PAID_NET:
                  scheme = PensionSchemes.EMPLOYER_PAID
                  hasNetPension = true
                  break
                case PensionSchemes.EMPLOYEE_PAID_NET:
                  scheme = PensionSchemes.EMPLOYEE_PAID
                  hasNetPension = true
                  break
                default:
                  scheme = row.scheme
                  break
              }
              return {
                pensionCompanyID: row.pensionCompanyID || 'NoPensionCompany',
                pensionCompanyName,
                hasUnionAgreementNumber,
                customerIDType: customerIDType,
                pensionCustomerID: row.pensionCustomerID,
                sortCode: row.sortCode,
                account: row.account,
                unionAgreementNumber: row.unionAgreementNumber,
                scheme: scheme,
                value: formatInputNumber(row.fixedAmount || row.percentage),
                suffix: row.fixedAmount ? 'fixedAmount' : 'percentage',
              }
            }) || [],
      },
    }
    fields.hasNetPension = hasNetPension
    if (fields.remuneration.pension.length === 0) {
      fields.remuneration.pension.push({
        pensionCompanyID: 'NoPensionCompany',
        suffix: 'percentage',
        hasUnionAgreementNumber: true,
      })
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/^remuneration\.pension\.\d+\.value$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else if (key.match(/^remuneration\.pension\.\d+\.scheme$/)) {
      // when employee paid and net, it can only be fixed amount
      const result = /^remuneration\.pension\.(\d+)\.scheme$/.exec(key)
      const row = result ? parseInt(result[1]) : -1
      if (
        allValues.remuneration.pension[row].suffix === 'percentage' &&
        val === PensionSchemes.EMPLOYEE_PAID &&
        allValues.hasNetPension
      ) {
        setByPath(values, `remuneration.pension.${row}.suffix`, 'fixedAmount')
      }
      setByPath(values, key, val)
    } else if (key === 'hasNetPension') {
      if (val) {
        allValues.remuneration.pension.forEach((pension, i) => {
          if (pension.suffix === 'percentage' && pension.scheme === PensionSchemes.EMPLOYEE_PAID) {
            setByPath(values, `remuneration.pension.${i}.suffix`, 'fixedAmount')
          }
        })
      }
      setByPath(values, key, val)
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values, props) => {
    const getLeaveType = (name: LeaveTypeName) => props.leaveTypes.find((type) => type.name === name)!
    const pension = values.hasPension
      ? values.remuneration.pension.map((oldRow): PensionDefinitionMutableFields => {
          const row: PensionDefinitionMutableFields = {
            ...oldRow,
            destinationType: 'DK Account',
            scheme: oldRow.scheme || PensionScheme.EMPLOYEE_PAID,
          }
          if (values.hasNetPension) {
            if (row.scheme === PensionSchemes.EMPLOYEE_PAID) {
              row.scheme = PensionSchemes.EMPLOYEE_PAID_NET
            } else {
              row.scheme = PensionSchemes.EMPLOYER_PAID_NET
            }
          }
          if (!row.pensionCompanyID || row.pensionCompanyID === 'NoPensionCompany') {
            row.destinationType = 'DK Account'
            row.pensionCompanyID = undefined
            row.pensionCustomerID = undefined
          } else {
            row.destinationType = 'DK PBS'
            row.account = undefined
            row.sortCode = undefined
          }
          if (!row.unionAgreementNumber || row.unionAgreementNumber.trim() === '') {
            row.unionAgreementNumber = undefined
          }
          if (row.pensionCompanyID) {
            const pensionCompany = props.pensionCompanies.find((company) => company.id === row.pensionCompanyID)
            if (pensionCompany) {
              if (pensionCompany.fields && pensionCompany.fields.indexOf('PensionType') !== -1) {
                if (row.unionAgreementNumber && row.unionAgreementNumber.trim() !== '') {
                  row.pensionType = 1
                } else {
                  row.pensionType = 2
                }
              } else {
                row.pensionType = undefined
              }
            }
          }
          row[oldRow.suffix] = forceParseInputNumber(oldRow.value)
          return row
        })
      : []
    if (!pension.some((row) => row.scheme === 'ATP') && values.useATP) {
      pension.push({ scheme: PensionScheme.ATP })
    }
    let leave = props.mutableContract.remuneration?.leave || []
    if (values.hasUnpaidVacation) {
      leave = leave.map((row) => {
        if (row.type?.name === 'DenmarkVacationFund' && values.includeVacationInPensionBasis) {
          row.typeID = getLeaveType('DenmarkVacationFundWithPension').id
        } else if (row.type?.name === 'DenmarkVacationFundWithPension' && !values.includeVacationInPensionBasis) {
          row.typeID = getLeaveType('DenmarkVacationFund').id
        }
        return row
      })
    }
    return {
      remuneration: {
        pension,
        leave,
      },
    }
  },
})(EmploymentPensionEditForm)
