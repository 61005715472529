import { List } from 'immutable'
import React, { ReactElement } from 'react'

import DocumentCategory from '../../model/documentCategory'
import { DocumentReducer } from '../../reducers/documents'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  documentID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
}

type Fields = {
  documentCategoryID?: string
  name?: string
}

export type CompanyDocumentResult = {
  documentCategoryID: string
  name: string
}

function CompanyDocumentEditForm(
  props: Props & FormComponentProps<Fields, CompanyDocumentResult>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('documentCategoryID', {
            placeholder: t('documents.company_document_edit.form.document_category_id'),
            validate: (val) => (!val ? t('documents.company_document_edit.form.document_category_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.documentCategories
                .filter(
                  (documentCategory) =>
                    documentCategory.active || documentCategory.id === getFieldValue('documentCategoryID')
                )
                .map((documentCategory) => {
                  return (
                    <Select.Option key={documentCategory.id} value={documentCategory.id}>
                      {documentCategory.name}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('documents.company_document_edit.form.name'),
            validate: (val) => (!val ? t('documents.company_document_edit.form.name.required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {props.documentID ? t('form.button.save_changes') : t('documents.company_document_edit.form.submit.create')}
          </Button>
        </Col>
      </Row>
      {props.documents.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, CompanyDocumentResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {}
    const document = props.documents.documents.find((document) => document.id === props.documentID)
    if (document) {
      fields.documentCategoryID = document.documentCategoryID
      fields.name = document.name
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      documentCategoryID: values.documentCategoryID!,
      name: values.name!,
    }
  },
})(CompanyDocumentEditForm)
