import { addDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import AccountingDimension, { AccountingDimensionValue } from '../../../model/accountingDimension'
import Company, { ProductionUnit } from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import EmployeeDimension from '../../../model/employeeDimension'
import Employment from '../../../model/employment'
import EmploymentPosition from '../../../model/employmentPosition'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { ContractReducer } from '../../../reducers/contracts'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import FamilyLeaveFund from '../../../types/family-leave-fund'
import IncomeType from '../../../types/income-type'
import { formatAPIDate, formatDate, getDate, isSameOrBefore, isTimeAfter } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatFamilyLeaveFund, formatIncomeType } from '../../../utils/format-utils'
import { setByPath } from '../../../utils/object-utils'
import { t, tx } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Select from '../../elements/select'
import ContractPosition from '../../form-elements/ContractPosition'
import ContractValidFrom from '../../form-elements/ContractValidFrom'
import ContractWorkplace from '../../form-elements/ContractWorkplace'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import SettingsLock from '../../widgets/SettingsLock'

type Props = {
  mutableContract: Contract
  validFrom: DateFormat
  company: Company
  employee: Employee
  employeeDimensions: List<EmployeeDimension>
  activeEmployment: Employment
  accountingDimensions: List<AccountingDimension>
  salaryCycle: SalaryCycle
  productionUnits: ProductionUnit[]
  employees: EmployeeReducer
  contracts: ContractReducer
  departmentIDs: string[]
  departments: DepartmentReducer
  employments: EmploymentReducer
  employmentPositions: List<EmploymentPosition>
  isFutureContract: boolean
  isDepartmentManager: boolean
  isMutableContract: boolean
  hasDimensionValues: boolean
}

type DimensionLine = {
  dimensionCode: string
  dimensionID: string
  dimensionValueID: string
  valuesMutable: boolean
  values: AccountingDimensionValue[]
}

type Fields = {
  departmentID?: string
  employmentStartDate: Date
  incomeType: IncomeType
  employmentPositionID?: string[]
  position?: string
  productionUnitID: string
  familyLeaveFund: FamilyLeaveFund
  immutableEndDate?: DateFormat
  validFrom?: DateFormat
  validFromPicker?: Date
  dimensionID?: string // for those without values
  dimensions: DimensionLine[]
}

type DimensionLineResult = {
  dimensionID: string
  dimensionValueID: string
}

export type EmploymentDetailResult = {
  departmentID?: string
  employmentStartDate: DateFormat
  incomeType: IncomeType
  employmentPositionID?: string[]
  position: string
  productionUnitID: string
  familyLeaveFund: FamilyLeaveFund
  immutableEndDate?: DateFormat
  validFrom: DateFormat
  dimensionID?: string
  dimensions: DimensionLineResult[]
}

const NO_DIMENSION_VALUE = 'NoDimensionValue'

function EmploymentDetailsEditForm(
  props: Props & FormComponentProps<Fields, EmploymentDetailResult>
): ReactElement | null {
  const showCombobox = () => {
    if (props.isDepartmentManager) {
      return false
    }
    if (!props.employee.departmentID) {
      return true
    }
    return (
      props.employee.departmentID !== props.getFieldValue('departmentID') &&
      !props.departments.departments.some((department) => department.id === props.getFieldValue('departmentID'))
    )
  }

  const { decorateField, getFieldValue, decorateAnyField } = props

  const contract = props.mutableContract
  const showDimensions = props.accountingDimensions.some((dimension) => dimension.usage === 'Employees')
  return (
    <div>
      {props.getFormError()}
      <ContractPosition<Fields>
        decorateField={props.decorateField}
        setFieldValue={props.setFieldValue}
        getFieldValue={props.getFieldValue}
        employmentPositions={props.employmentPositions}
        disabled={!props.isMutableContract}
      />
      <ContractWorkplace<Fields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        productionUnits={props.productionUnits}
        disabled={!props.isMutableContract}
      />
      <Row>
        <Col span={12}>
          {decorateField('employmentStartDate', {
            placeholder: t('employment_details.edit.form.employment_start_date'),
            validate: (val) => {
              if (!val) {
                return t('employment_details.edit.form.employment_start_date.required')
              }
              let validFrom = getFieldValue('validFromPicker')
              if (props.employee.immutableEndDate) {
                validFrom = getDate(getFieldValue('validFrom'))
              }
              if (validFrom && isTimeAfter(val, validFrom)) {
                return t('employment_details.edit.form.employment_start_date.not_before_contract')
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} disabled={!props.isMutableContract} />)}
        </Col>
        {(!props.isDepartmentManager || props.departmentIDs.length > 1) && (
          <Col span={12}>
            {decorateField('departmentID', {
              title: t('employment_details.edit.form.department_id'),
              placeholder: t('employment_details.edit.form.department_id.placeholder'),
            })(
              <Select
                mode={showCombobox() ? 'combobox' : undefined}
                dropdownMatchSelectWidth={false}
                optionLabelProp="title"
                filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {!showCombobox() && !props.isDepartmentManager && (
                  <Select.Option key={''} value={''} title={''}>
                    <i>{t('employment_details.edit.form.department_id.none')}</i>
                  </Select.Option>
                )}
                {props.departments.departments
                  .filter((department) => department.active && props.departmentIDs.some((id) => id === department.id))
                  .map((department) => {
                    return (
                      <Select.Option key={department.id} value={department.id} title={department.name}>
                        {department.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        )}
      </Row>
      <Row>
        {!props.isFutureContract && (
          <ContractValidFrom<Fields>
            decorateField={props.decorateField}
            getFieldValue={props.getFieldValue}
            useSalaryPeriods={!!props.employee.immutableEndDate}
            salaryCycle={props.salaryCycle}
            contract={contract}
            employment={props.activeEmployment}
            employee={props.employee}
            disabled={!props.isMutableContract}
          />
        )}
        {props.isFutureContract && (
          <Col span={12}>
            <p>
              {t('employment_details.edit.form.future.valid_from')}:{' '}
              <strong>{formatDate(props.getFieldValue('validFrom') || getDate())}</strong>
            </p>
            <p>
              {tx('employment_details.edit.form.future.valid_from.note', {
                em: <em>{t('employment_details.edit.form.future.valid_from.note.em')}</em>,
              })}
            </p>
          </Col>
        )}
        <Col span={12}>
          <SettingsLock setting={'MoreIncomeTypes'} description={t('employment_details.edit.form.income_type.lock')}>
            {decorateField('incomeType', {
              title: t('employment_details.edit.form.income_type'),
              placeholder: t('employment_details.edit.form.income_type.placeholder'),
              validate: (val) => (!val ? t('employment_details.edit.form.income_type.required') : null),
            })(
              <Select
                dropdownMatchSelectWidth={false}
                disabled={
                  !props.isMutableContract ||
                  !props.company.settingsEnabled.some((setting) => setting === 'MoreIncomeTypes')
                }
              >
                <Select.Option value={IncomeType.DK_SALARY_INCOME}>
                  {formatIncomeType(IncomeType.DK_SALARY_INCOME)}
                </Select.Option>
                <Select.Option value={IncomeType.DK_RESEARCH_INCOME}>
                  {formatIncomeType(IncomeType.DK_RESEARCH_INCOME)}
                </Select.Option>
                <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE}>
                  {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE)}
                </Select.Option>
                <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE_AM}>
                  {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE_AM)}
                </Select.Option>
                <Select.Option value={IncomeType.DK_CONTRIBUTION_FREE_ABROAD}>
                  {formatIncomeType(IncomeType.DK_CONTRIBUTION_FREE_ABROAD)}
                </Select.Option>
              </Select>
            )}
          </SettingsLock>
        </Col>
      </Row>
      {(props.company.familyLeaveFund !== FamilyLeaveFund.BARSEL_DK ||
        props.company.familyLeaveFund !== getFieldValue('familyLeaveFund')) && (
        <Row>
          <Col span={12}>
            {decorateField('familyLeaveFund', {
              title: t('employment_details.edit.form.family_leave_fund'),
              placeholder: t('employment_details.edit.form.family_leave_fund.placeholder'),
            })(
              <Select dropdownMatchSelectWidth={false} disabled={!props.isMutableContract}>
                {props.company.familyLeaveFund !== FamilyLeaveFund.BARSEL_DK && (
                  <Select.Option value={FamilyLeaveFund.BARSEL_DK}>
                    {formatFamilyLeaveFund(FamilyLeaveFund.BARSEL_DK)}
                  </Select.Option>
                )}
                <Select.Option value={FamilyLeaveFund.BARSEL_DK_PARTIAL}>
                  {formatFamilyLeaveFund(FamilyLeaveFund.BARSEL_DK_PARTIAL)}
                </Select.Option>
                {(props.company.familyLeaveFund === FamilyLeaveFund.DA_BARSEL ||
                  getFieldValue('familyLeaveFund') == FamilyLeaveFund.DA_BARSEL) && (
                  <Select.Option value={FamilyLeaveFund.DA_BARSEL}>
                    {formatFamilyLeaveFund(FamilyLeaveFund.DA_BARSEL)}
                  </Select.Option>
                )}
                <Select.Option value={FamilyLeaveFund.OTHER}>
                  {formatFamilyLeaveFund(FamilyLeaveFund.OTHER)}
                </Select.Option>
              </Select>
            )}
          </Col>
        </Row>
      )}
      {showDimensions && (
        <Row>
          {!props.hasDimensionValues && (
            <Col span={12}>
              {decorateField('dimensionID', {
                title: t('employment_details.edit.form.dimension_id'),
                placeholder: t('employment_details.edit.form.dimension_id.placeholder'),
              })(
                <Select
                  dropdownMatchSelectWidth={false}
                  optionLabelProp="title"
                  filterOption={(input: string, option: ReactElement) =>
                    option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!props.isMutableContract}
                >
                  <Select.Option
                    key={NO_DIMENSION_VALUE}
                    value={NO_DIMENSION_VALUE}
                    title={t('employment_details.edit.form.dimension_id.none')}
                  >
                    <i>{t('employment_details.edit.form.dimension_id.none')}</i>
                  </Select.Option>
                  {props.accountingDimensions.map((dimension) => {
                    let dimensionTitle = dimension.dimensionCode
                    if (dimension.dimensionName) {
                      dimensionTitle = `${dimension.dimensionCode} (${dimension.dimensionName})`
                    }
                    return (
                      <Select.Option key={dimension.id} value={dimension.id} title={dimensionTitle}>
                        {dimensionTitle}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Col>
          )}
          {props.hasDimensionValues &&
            getFieldValue('dimensions').map((dimension, i) => {
              return (
                <Col span={12} key={`dimension-${i}`}>
                  {decorateAnyField(`dimensions.${i}.dimensionValueID`, {
                    title: dimension.dimensionCode,
                    placeholder: t('employment_details.edit.form.dimension_value_id.placeholder', {
                      code: dimension.dimensionCode,
                    }),
                  })(
                    <Select
                      mode={dimension.valuesMutable ? 'combobox' : undefined}
                      dropdownMatchSelectWidth={false}
                      optionLabelProp="title"
                      filterOption={(input: string, option: ReactElement) =>
                        option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={!props.isMutableContract}
                    >
                      <Select.Option
                        key={dimension.valuesMutable ? '' : NO_DIMENSION_VALUE}
                        value={dimension.valuesMutable ? '' : NO_DIMENSION_VALUE}
                        title={
                          dimension.valuesMutable
                            ? t('employment_details.edit.form.dimension_value_id.none_or_new')
                            : t('employment_details.edit.form.dimension_value_id.none')
                        }
                      >
                        <i>
                          {dimension.valuesMutable
                            ? t('employment_details.edit.form.dimension_value_id.none_or_new')
                            : t('employment_details.edit.form.dimension_value_id.none')}
                        </i>
                      </Select.Option>
                      {dimension.values.map((value) => {
                        return (
                          <Select.Option key={value.id} value={value.id} title={value.dimensionValueCode}>
                            {value.dimensionValueCode}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                </Col>
              )
            })}
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(getDate(props.validFrom), getDate())
              ? t('employment_details.edit.warning.at_date', { date: formatDate(props.validFrom) })
              : t('employment_details.edit.warning.immediate')}
          </div>
        </Col>
      </Row>
      {(props.departments.saving || props.employees.saving || props.employments.saving || props.contracts.saving) && (
        <LoadingOverlay />
      )}
    </div>
  )
}

export default withValidations<Props, Fields, EmploymentDetailResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      departmentID: props.employee.departmentID,
      employmentStartDate: getDate(props.activeEmployment.startDate),
      incomeType: props.activeEmployment.incomeType,
      position: props.mutableContract.position,
      productionUnitID: props.mutableContract.productionUnitID,
      familyLeaveFund: props.mutableContract.familyLeaveFund,
      immutableEndDate: props.employee.immutableEndDate ? props.employee.immutableEndDate : undefined,
      validFrom: props.employee.immutableEndDate ? props.mutableContract.validFrom : undefined,
      validFromPicker: !props.employee.immutableEndDate ? getDate(props.mutableContract.validFrom) : undefined,
      dimensions: props.hasDimensionValues
        ? props.accountingDimensions.reduce((list: DimensionLine[], dimension) => {
            if (dimension.usage !== 'Employees') {
              return list
            }
            const value = props.employeeDimensions.find(
              (employeeDimension) =>
                employeeDimension.dimensionID === dimension.id && employeeDimension.employeeID === props.employee.id
            )
            list.push({
              dimensionCode: dimension.dimensionCode,
              dimensionID: dimension.id,
              dimensionValueID: value?.dimensionValueID || (dimension.valuesMutable ? '' : NO_DIMENSION_VALUE),
              valuesMutable: dimension.valuesMutable,
              values: dimension.values,
            })
            return list
          }, [])
        : [],
      dimensionID: props.employeeDimensions.find(
        (employeeDimension) => employeeDimension.employeeID === props.employee.id
      )?.dimensionID,
    }
    props.employmentPositions.forEach((position) => {
      if (props.mutableContract.employmentPositionID === position.id) {
        fields.employmentPositionID = [position.group, position.id]
      }
    })
    return fields
  },
  onChange: (key, val, allValues) => {
    const values = {}
    switch (key) {
      case 'productionUnitID':
        setByPath(values, key, val)
        if (
          allValues.immutableEndDate &&
          isSameOrBefore(getDate(allValues.validFrom), getDate(allValues.immutableEndDate))
        ) {
          const endDate = getDate(allValues.immutableEndDate)
          setByPath(values, 'validFrom', formatAPIDate(addDays(endDate, 1)))
        }
        break
      case 'employmentPositionID':
        // only set when array
        if (Array.isArray(val)) {
          setByPath(values, key, val)
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    return {
      ...values,
      departmentID: values.departmentID || undefined,
      validFrom: values.validFromPicker ? formatAPIDate(values.validFromPicker) : values.validFrom!,
      employmentStartDate: formatAPIDate(values.employmentStartDate),
      position: values.position!,
      dimensionID: values.dimensionID !== NO_DIMENSION_VALUE ? values.dimensionID : undefined,
      dimensions: values.dimensions.map((dimension) => ({
        dimensionID: dimension.dimensionID,
        dimensionValueID: dimension.dimensionValueID !== NO_DIMENSION_VALUE ? dimension.dimensionValueID : '',
      })),
    }
  },
})(EmploymentDetailsEditForm)
