import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Contract from '../../model/contract'
import { AffiliationType } from '../../model/employee'
import LeaveType, { LeaveTypeName } from '../../model/leaveType'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import SupplementType, { SupplementTypeName } from '../../model/supplementType'
import DayLaborer from '../../types/day-laborer'
import { getDate, isSameOrAfter } from '../../utils/date-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import { LeaveDefinitionFields, RemunerationFields, SupplementDefinitionFields } from './types'
import VacationForm from './VacationForm'

type Fields = {
  vacationType: 'paid' | 'unpaid' | 'none'
  vacationDays: string
  vacationSupplement: string
  vacationMoney: string
  extraVacationDays: string
  personalDays: string
  hasVacationFund: boolean
  vacationFundCVR?: string
  manualVacationFund: boolean
  horestaVacationSupplement: boolean
  horestaVacationSupplementAmount?: string
  hasExtraVacation: boolean
  hasPersonalDays: boolean
  extraVacationAccrual: 'Yearly' | 'Monthly'
  originalExtraVacationAccrual: 'Monthly' | 'Yearly'
  personalDaysAccrual: 'Yearly' | 'Monthly'
  originalPersonalDaysAccrual: 'Monthly' | 'Yearly'
  timeBank: 'None' | 'Flex' | 'Overtime'
  overtimeRate?: string
  useATP: boolean
  useATPInitial: boolean
  useDayLaborer: boolean
  dayLaborer: DayLaborer
  otherLeave: LeaveDefinitionFields[]
  otherSupplements: SupplementDefinitionFields[]
  minDays: number
  maxDays: number
  greatPrayerDaySupplement: boolean
  useGPDInitial: boolean
  includeVacationSupplementsInPensionBasis: boolean
}

export type VacationStepResult = {
  affiliationType: AffiliationType
  vacationFundCVR?: string
  manualVacationFund: boolean
  overtimeRate?: number
  useATP: boolean
  dayLaborer: DayLaborer
  remuneration: {
    leave: LeaveDefinitionFields[]
    supplements: SupplementDefinitionFields[]
  }
  step: 'VacationStep'
}

type Props = {
  company: Company
  salaryCycles: List<SalaryCycle>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>

  salaryPeriod: SalaryPeriod
  contract?: Contract
  payType: EmployeePayType
  affiliationType: AffiliationType
  dayLaborer?: DayLaborer
  remuneration: RemunerationFields
}

function VacationStep(props: Props & FormComponentProps<Fields, VacationStepResult>): ReactElement | null {
  return (
    <div>
      <Subtitle>{t('contracts_add.vacation_step.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <VacationForm<Fields>
        {...props}
        payType={props.payType}
        company={props.company}
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" suffixIcon="arrowRight">
            {t('contracts_add.vacation_step.submit')}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack}>
            {t('contracts_add.vacation_step.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, VacationStepResult>({
  mapPropsToFields: (props: Props): Fields => {
    const fields: Fields = {
      vacationType: props.affiliationType === 'Director' ? 'none' : props.payType === 'Salaried' ? 'paid' : 'unpaid',
      vacationDays: formatInputNumber(25),
      vacationSupplement: formatInputNumber(1.0),
      vacationMoney: formatInputNumber(12.5),
      extraVacationDays: formatInputNumber(0),
      personalDays: formatInputNumber(0),
      hasVacationFund: props.contract && props.contract.vacationFundCVR ? true : false,
      vacationFundCVR: props.contract && props.contract.vacationFundCVR ? props.contract.vacationFundCVR : undefined,
      manualVacationFund: false,
      horestaVacationSupplement: false,
      hasExtraVacation: false,
      extraVacationAccrual: 'Yearly',
      originalExtraVacationAccrual: 'Yearly',
      hasPersonalDays: false,
      personalDaysAccrual: 'Yearly',
      originalPersonalDaysAccrual: 'Yearly',
      timeBank: 'None',
      useATP: true,
      useATPInitial: true,
      useDayLaborer: !!props.dayLaborer && props.dayLaborer !== DayLaborer.NONE,
      dayLaborer: props.dayLaborer || DayLaborer.NONE,
      otherLeave: [],
      otherSupplements: [],
      minDays: 25,
      maxDays: 25,
      greatPrayerDaySupplement: false,
      useGPDInitial: false,
      includeVacationSupplementsInPensionBasis: false,
    }
    if (props.remuneration.leave.length > 0 || props.remuneration.supplements.length > 0) {
      props.remuneration.leave.forEach((row) => {
        const leaveType = props.leaveTypes.find((type) => type.id === row.typeID)
        if (!leaveType) {
          return
        }
        switch (leaveType.name) {
          case 'DenmarkVacationPaid':
          case 'DenmarkVacationAccrual':
            fields.vacationType = 'paid'
            fields.vacationDays = formatInputNumber(row.days)
            fields.minDays = leaveType.minDays
            fields.maxDays = leaveType.maxDays
            break
          case 'DenmarkVacationNoPay':
          case 'DenmarkVacationFund':
          case 'DenmarkVacationFundWithPension':
            fields.vacationType = 'unpaid'
            fields.vacationDays = formatInputNumber(row.days)
            fields.minDays = leaveType.minDays
            fields.maxDays = leaveType.maxDays
            break
          case 'DenmarkOptionalVacation':
            fields.hasExtraVacation = true
            fields.extraVacationDays = formatInputNumber(row.days)
            fields.extraVacationAccrual = 'Yearly'
            fields.originalExtraVacationAccrual = 'Yearly'
            break
          case 'DenmarkPersonalDay':
            fields.hasPersonalDays = true
            fields.personalDays = formatInputNumber(row.days)
            fields.personalDaysAccrual = 'Yearly'
            fields.originalPersonalDaysAccrual = 'Yearly'
            break
          case 'DenmarkExtraVacationAccrual':
            fields.hasExtraVacation = true
            fields.extraVacationDays = formatInputNumber(row.days)
            fields.extraVacationAccrual = 'Monthly'
            fields.originalExtraVacationAccrual = 'Monthly'
            break
          case 'DenmarkPersonalTimeAccrual':
            fields.hasPersonalDays = true
            fields.personalDays = formatInputNumber(row.days)
            fields.personalDaysAccrual = 'Monthly'
            fields.originalPersonalDaysAccrual = 'Monthly'
            break
          case 'DenmarkFlexTime':
            fields.timeBank = 'Flex'
            break
          case 'DenmarkOvertime':
            fields.timeBank = 'Overtime'
            fields.overtimeRate = formatInputNumber(row.rate || 0)
            break
          default:
            fields.otherLeave.push(row)
            break
        }
      })
      props.remuneration.supplements.forEach((row) => {
        const supplementType = props.supplementTypes.find((type) => type.id === row.typeID)
        if (!supplementType) {
          return
        }
        switch (supplementType.name) {
          case 'DenmarkVacationPaidSupplement':
          case 'DenmarkVacationSupplementPension':
          case 'DenmarkVacationSupplement':
            fields.vacationSupplement = formatInputNumber(row.compensationRate * 100)
            if (supplementType.name === 'DenmarkVacationSupplementPension') {
              fields.includeVacationSupplementsInPensionBasis = true
            }
            break
          case 'DenmarkVacationSupplementSpecial':
            fields.horestaVacationSupplement = true
            fields.horestaVacationSupplementAmount = formatInputNumber(row.compensationRate * 100)
            break
          case 'DenmarkGreatPrayerDaySupplement':
            fields.greatPrayerDaySupplement = true
            break
          default:
            fields.otherSupplements.push(row)
            break
        }
      })
    }
    if (fields.vacationType === 'none') {
      fields.useATP = props.remuneration.pension.some((pension) => pension.scheme === 'ATP')
    }
    fields.useGPDInitial = fields.greatPrayerDaySupplement
    return fields
  },
  onChange: (key: string, val: any, allValues: Fields, options: Record<string, unknown>) => {
    const values = {}
    switch (key) {
      case 'vacationDays':
      case 'vacationSupplement':
      case 'vacationMoney':
      case 'extraVacationDays':
      case 'personalDays':
        setByPath(values, key, formatInputNumber(parseInputNumber(val, { trim: options.trigger === 'onBlur' })))
        break
      case 'vacationType':
        setByPath(values, key, val)
        if (val === 'none') {
          setByPath(values, 'greatPrayerDaySupplement', false)
        } else {
          setByPath(values, 'greatPrayerDaySupplement', allValues.useGPDInitial)
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values: Fields, props: Props) => {
    const getLeaveType = (name: LeaveTypeName) => props.leaveTypes.find((type) => type.name === name)!
    const getSupplementType = (name: SupplementTypeName) => props.supplementTypes.find((type) => type.name === name)!

    let affiliationType: AffiliationType = 'Standard'
    const leave = values.otherLeave
    const supplements = values.otherSupplements
    if (values.vacationType !== 'none') {
      if (props.salaryPeriod && isSameOrAfter(getDate(props.salaryPeriod.dispositionDate), getDate('2020-09-01'))) {
        const rate =
          forceParseInputNumber(values.vacationMoney) !== 12.5
            ? forceParseInputNumber(values.vacationMoney) / 100
            : undefined
        if (values.vacationType === 'paid') {
          leave.push({
            typeID: getLeaveType('DenmarkVacationAccrual').id,
            days: forceParseInputNumber(values.vacationDays),
            rate,
          })
          if (values.includeVacationSupplementsInPensionBasis) {
            supplements.push({
              typeID: getSupplementType('DenmarkVacationSupplementPension').id,
              compensationRate: forceParseInputNumber(values.vacationSupplement) / 100,
            })
          } else {
            supplements.push({
              typeID: getSupplementType('DenmarkVacationSupplement').id,
              compensationRate: forceParseInputNumber(values.vacationSupplement) / 100,
            })
          }
        } else {
          leave.push({
            typeID: getLeaveType('DenmarkVacationFund').id,
            days: forceParseInputNumber(values.vacationDays),
            rate,
          })
        }
      } else {
        if (values.vacationType === 'paid') {
          leave.push({
            typeID: getLeaveType('DenmarkVacationPaid').id,
            days: forceParseInputNumber(values.vacationDays),
          })
          supplements.push({
            typeID: getSupplementType('DenmarkVacationPaidSupplement').id,
            compensationRate: forceParseInputNumber(values.vacationSupplement) / 100,
          })
        } else {
          leave.push({
            typeID: getLeaveType('DenmarkVacationNoPay').id,
            days: forceParseInputNumber(values.vacationDays),
          })
        }
      }
      if (parseInputNumber(values.extraVacationDays) > 0) {
        if (values.extraVacationAccrual === 'Yearly') {
          leave.push({
            typeID: getLeaveType('DenmarkOptionalVacation').id,
            days: forceParseInputNumber(values.extraVacationDays),
          })
        } else {
          leave.push({
            typeID: getLeaveType('DenmarkExtraVacationAccrual').id,
            days: forceParseInputNumber(values.extraVacationDays),
          })
        }
      }
      if (parseInputNumber(values.personalDays) > 0) {
        if (values.personalDaysAccrual === 'Yearly') {
          leave.push({
            typeID: getLeaveType('DenmarkPersonalDay').id,
            days: forceParseInputNumber(values.personalDays),
          })
        } else {
          leave.push({
            typeID: getLeaveType('DenmarkPersonalTimeAccrual').id,
            days: forceParseInputNumber(values.personalDays),
          })
        }
      }
    } else {
      affiliationType = 'Director'
    }
    if (values.horestaVacationSupplement) {
      supplements.push({
        typeID: getSupplementType('DenmarkVacationSupplementSpecial').id,
        compensationRate: forceParseInputNumber(values.horestaVacationSupplementAmount) / 100,
      })
    }
    if (values.timeBank === 'Flex') {
      leave.push({
        typeID: getLeaveType('DenmarkFlexTime').id,
        days: 0,
      })
    }
    if (values.timeBank === 'Overtime') {
      leave.push({
        typeID: getLeaveType('DenmarkOvertime').id,
        days: 0,
        rate: forceParseInputNumber(values.overtimeRate),
      })
    }
    if (values.greatPrayerDaySupplement) {
      supplements.push({
        typeID: getSupplementType('DenmarkGreatPrayerDaySupplement').id,
        compensationRate: 0.0045,
      })
    }
    return {
      affiliationType,
      useATP: values.vacationType === 'none' ? values.useATP : true,
      vacationFundCVR: values.hasVacationFund ? values.vacationFundCVR : undefined,
      manualVacationFund: values.manualVacationFund,
      remuneration: {
        leave,
        supplements,
      },
      dayLaborer: values.vacationType === 'unpaid' ? values.dayLaborer : DayLaborer.NONE,
      step: 'VacationStep',
    }
  },
})(VacationStep)
