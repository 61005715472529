import { List } from 'immutable'
import React, { ReactElement } from 'react'

import ExternalSalaryFileSetting, {
  ExternalSalaryFileSettingMutableFields,
  ExternalSalaryFileType,
} from '../../model/externalSalaryFileSetting'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Props = {
  externalSalaryFileSettings: List<ExternalSalaryFileSetting>
  externalSalaryFileType: ExternalSalaryFileType
}

export type ExternalSalarySettingResult = {
  externalSalaryFileType: ExternalSalaryFileType
  settings: ExternalSalaryFileSettingMutableFields[]
}

function ExternalSalaryFileSettingsForm(
  props: Props & FormComponentProps<ExternalSalarySettingResult, ExternalSalarySettingResult>
): ReactElement | null {
  const { decorateAnyField } = props
  return (
    <div>
      <Row>
        <Col span={4}>{t('external_salary_file_setting.edit.form.header.pay_part')}</Col>
        <Col span={10}>{t('external_salary_file_setting.edit.form.header.standard_title')}</Col>
        <Col span={10}>{t('external_salary_file_setting.edit.form.header.custom_title')}</Col>
      </Row>
      {props.externalSalaryFileSettings
        .filter((setting) => setting.fileType === props.externalSalaryFileType)
        .map((setting, i) => {
          return (
            <Row key={`setting-${i}`}>
              <Col span={4}>
                {setting.externalSalaryType}
                {setting.externalSalaryType.slice(-1) === '%' && (
                  <>
                    <br />
                    <small>{t('external_salary_file_setting.edit.form.variable_type_note')}</small>
                  </>
                )}
              </Col>
              <Col span={10}>{setting.standardTitle}</Col>
              <Col span={10}>
                {decorateAnyField(`settings.${i}.title`, {
                  skipLabel: true,
                  placeholder: setting.standardTitle,
                })(<Input />)}
              </Col>
            </Row>
          )
        })}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, ExternalSalarySettingResult, ExternalSalarySettingResult>({
  mapPropsToFields: (props) => ({
    externalSalaryFileType: props.externalSalaryFileType,
    settings: props.externalSalaryFileSettings
      .filter((setting) => setting.fileType === props.externalSalaryFileType)
      .map((setting) => ({
        fileType: setting.fileType,
        externalSalaryType: setting.externalSalaryType,
        title: setting.title,
      }))
      .toArray(),
  }),
  onSubmit: (values) => values,
})(ExternalSalaryFileSettingsForm)
