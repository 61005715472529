import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PensionCompany from '../model/pensionCompany'
import { ReducerAction } from '../utils/reducer-utils'

export interface PensionCompanyReducer {
  loading: boolean
  loaded: boolean
  pensionCompanies: List<PensionCompany>
  error: Error | null
}

const Params = Record<PensionCompanyReducer>({
  loading: false,
  loaded: false,
  pensionCompanies: List<PensionCompany>(),
  error: null,
})

export interface PensionCompanyAction extends ReducerAction {
  pensionCompanies?: PensionCompany[]
}

export default (
  state: Record<PensionCompanyReducer> = Params(),
  action: PensionCompanyAction = { type: '' }
): Record<PensionCompanyReducer> => {
  switch (action.type) {
    case ActionTypes.PENSION_COMPANY_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.PENSION_COMPANY_LOADED:
      return Params({
        loaded: true,
        pensionCompanies: List<PensionCompany>(action.pensionCompanies).sort((a, b) => a.name.localeCompare(b.name)),
      })
    case ActionTypes.PENSION_COMPANY_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
