import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import CarAllowance, { CarAllowanceMutableFields } from '../../../model/carAllowance'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../../model/coarseCarAllowance'
import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { CarAllowanceReducer } from '../../../reducers/carAllowances'
import { CoarseCarAllowanceReducer } from '../../../reducers/coarseCarAllowances'
import { ContractReducer } from '../../../reducers/contracts'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Modal from '../../elements/modal'
import { RegistrationMethodAlert } from '../../elements/RegistrationMethodAlert'
import { RegistrationMethodAppAlert } from '../../elements/RegistrationMethodAppAlert'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import CoarseCarAllowanceTab from './CoarseCarAllowanceTab'
import DetailedCarAllowanceTab from './DetailedCarAllowanceTab'
import RateEdit from './RateEdit'

import './CarAllowanceTab.css'

type Props = {
  subsection?: string
  hasCarAllowancesForPeriod: boolean
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  contracts: ContractReducer
  carAllowances: CarAllowanceReducer
  coarseCarAllowances: CoarseCarAllowanceReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  canEditObjects: boolean
  canHireFire: boolean
  canApproveObjects: boolean

  addAlert: addAlertSignature
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  approveCarAllowances: (ids: string[]) => void
  unapproveCarAllowances: (ids: string[]) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (carAllowance: CoarseCarAllowance) => void
}

export default function CarAllowanceTab(props: Props): ReactElement | null {
  const [hasDetailedCarAllowance, setHasDetailedCarAllowance] = useState(
    !!props.employee.activeContract && props.employee.activeContract.carAllowanceRegistrationMethodType === 'Detailed'
  )
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState(false)

  const setEditVisibility = (editing: boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(editing)
  }

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    // Check for save callback
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      // Check for no error occurred
      if (!contracts.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const { employee } = props

  // check for changes to the registration method
  useEffect(() => {
    if (hasDetailedCarAllowance && employee.earliestMutableContract?.carAllowanceRegistrationMethodType === 'Coarse') {
      setHasDetailedCarAllowance(false)
    } else if (
      !hasDetailedCarAllowance &&
      employee.earliestMutableContract?.carAllowanceRegistrationMethodType === 'Detailed'
    ) {
      setHasDetailedCarAllowance(true)
    }
  }, [hasDetailedCarAllowance, employee])

  const showHistory = () => {
    return hasDetailedCarAllowance && props.subsection === 'history'
  }

  const handleChange = (val: boolean, force = false) => {
    if (!props.employee.earliestMutableContract) {
      return
    }
    setHasDetailedCarAllowance(val)
    const contract: Contract = {
      ...props.employee.earliestMutableContract,
      carAllowanceRegistrationMethodType: val ? 'Detailed' : 'Coarse',
    }
    if (force) {
      props.updateContract(contract)
      return
    }
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  const deleteDetailed = () => {
    props.carAllowances.carAllowances
      .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
      .forEach((carAllowance) => props.deleteCarAllowance(carAllowance))
  }
  const deleteCoarse = () => {
    props.coarseCarAllowances.coarseCarAllowances
      .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
      .forEach((carAllowance) => props.deleteCoarseCarAllowance(carAllowance))
  }

  const renderCarAllowanceTab = () => {
    if (!hasDetailedCarAllowance) {
      if (!props.salaryCycle) {
        return null
      }
      return (
        <CoarseCarAllowanceTab
          preventPeriodChange={false}
          employee={props.employee}
          coarseCarAllowances={props.coarseCarAllowances}
          salaryCycles={props.salaryCycles}
          salaryCycle={props.salaryCycle}
          addAlert={props.addAlert}
          createCoarseCarAllowance={props.createCoarseCarAllowance}
          updateCoarseCarAllowance={props.updateCoarseCarAllowance}
          deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
        />
      )
    }
    return (
      <DetailedCarAllowanceTab
        showHistory={showHistory()}
        company={props.company}
        employee={props.employee}
        costCenters={props.costCenters}
        departments={props.departments}
        carAllowances={props.carAllowances}
        canEditObjects={props.canEditObjects}
        canApproveObjects={props.canApproveObjects}
        addAlert={props.addAlert}
        createCarAllowances={props.createCarAllowances}
        approveCarAllowances={props.approveCarAllowances}
        unapproveCarAllowances={props.unapproveCarAllowances}
        updateCarAllowance={props.updateCarAllowance}
        deleteCarAllowance={props.deleteCarAllowance}
      />
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }
  let title = t('car_allowances_tab.title.standard')
  if (showHistory()) {
    title = t('car_allowances_tab.title.history')
  }

  const pendingDetailedCarAllowanceSummary = props.carAllowances.carAllowances
    .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
    .reduce(
      (summary, registration) => {
        return {
          count: summary.count + 1,
          value: summary.value + registration.kilometers,
        }
      },
      { count: 0, value: 0 }
    )
  const pendingCoarseCarAllowanceSummary = props.coarseCarAllowances.coarseCarAllowances
    .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
    .reduce(
      (summary, registration) => {
        return {
          count: summary.count + 1,
          value: summary.value + registration.kilometers,
        }
      },
      { count: 0, value: 0 }
    )

  return (
    <>
      <RegistrationMethodAppAlert
        company={props.company}
        disablingSettings={['DisableCarAllowanceRegistrationMethodDetailedOnInvite']}
        employee={props.employee}
        isDetailed={hasDetailedCarAllowance ?? false}
        itemMessageID="car_allowance"
        switchToDetailed={() => handleChange(true, true)}
      />
      <Card className="employees-single-form">
        <RegistrationMethodAlert
          isDetailed={hasDetailedCarAllowance ?? false}
          detailedSummary={pendingDetailedCarAllowanceSummary}
          coarseSummary={pendingCoarseCarAllowanceSummary}
          itemMessageID={'car_allowance'}
          deleteDetailed={deleteDetailed}
          deleteCoarse={deleteCoarse}
          switchToDetailed={() => handleChange(true)}
          switchToCoarse={() => handleChange(false)}
          formatValue={(value) => `${formatDisplayNumber(value, 3)} km`}
        />
        <TitleMenu>
          {props.canHireFire &&
            !showHistory() &&
            !props.hasCarAllowancesForPeriod &&
            props.employee.earliestMutableContract && (
              <div className="ant-switch-wrapper" style={{ display: 'inline-block', marginRight: 20 }}>
                <Switch checked={hasDetailedCarAllowance} onChange={handleChange} />
                <span className="ant-switch-text">{t('car_allowances_tab.header.switch_detailed')}</span>
              </div>
            )}
          {props.canHireFire && !showHistory() && props.employee.earliestMutableContract && (
            <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20, paddingRight: 14 }}>
              {t('car_allowances_tab.header.edit_rate')}
            </Button>
          )}
          {hasDetailedCarAllowance &&
            (showHistory() ? (
              <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/car-allowance'}>
                <Button className="gtm-hide-car-allowance-history" style={{ paddingRight: 14 }}>
                  {t('car_allowances_tab.header.hide_history')}
                </Button>
              </Link>
            ) : (
              <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/car-allowance/history'}>
                <Button className="gtm-show-car-allowance-history" style={{ paddingRight: 14 }}>
                  {t('car_allowances_tab.header.show_history')}
                </Button>
              </Link>
            ))}
        </TitleMenu>
        <Title>{title}</Title>
        {renderCarAllowanceTab()}
        {props.contracts.saving && (
          <LoadingOverlay
            text={formatSavingText([{ loading: props.contracts.saving, text: t('loading.reducer.contracts') }])}
          />
        )}

        <Modal
          key={modalKey}
          visible={editing}
          onOk={() => setEditVisibility(false)}
          onCancel={() => setEditVisibility(false)}
          width={582}
          footer={null}
        >
          <RateEdit
            visible={editing}
            employee={props.employee}
            contracts={props.contracts}
            addContract={props.addContract}
            updateContract={props.updateContract}
          />
        </Modal>
      </Card>
    </>
  )
}
