import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'

import PayRoll from '../../model/payRoll'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import { formatDate, getDate, isTimeAfter } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Select from '../elements/select'

type Props = {
  payRolls: List<PayRoll>
  salaryCycles: List<SalaryCycle>
  preferOffset: boolean
  periodID: string | undefined

  onSelected: (salaryPeriodID: string) => void
}

export default function PeriodSelector(props: Props): ReactElement | null {
  const activePayrolls = props.payRolls
    .filter((v) => {
      if (v.payRollType !== 'Ordinary') {
        return false
      }
      switch (v.status) {
        case 'Scheduled':
        case 'Upcoming':
        case 'InProgress':
          return true
        default:
          return false
      }
    })
    .sort((a, b) => a.dispositionDate.localeCompare(b.dispositionDate)) // always newest first

  const { periodID, preferOffset, onSelected, payRolls } = props
  useEffect(() => {
    if (periodID) {
      return // already have a value, no need to find one
    }
    let payroll: PayRoll | undefined = undefined
    if (activePayrolls.size === 1) {
      payroll = activePayrolls.first()
    } else if (activePayrolls.size > 1) {
      // pick newest
      payroll = activePayrolls.find(
        (v) => (preferOffset && !!v.salaryCycle.offset) || (preferOffset && !v.salaryCycle.offset)
      )
      if (!payroll) {
        // pick newest
        payroll = activePayrolls.first()
      }
    }
    if (!payroll) {
      payroll = payRolls.reduce((payRoll: PayRoll | undefined, v) => {
        if (!payRoll || isTimeAfter(getDate(v.salaryPeriod.end), getDate(payRoll.salaryPeriod.end))) {
          return v
        }
        return payRoll
      }, payroll)
    }
    if (payroll) {
      onSelected(payroll.salaryPeriod.id)
    }
  }, [periodID, activePayrolls, preferOffset, onSelected, payRolls])

  const salaryPeriods = activePayrolls.map((p) => p.salaryPeriod).toArray()

  if (periodID && !salaryPeriods.some((p) => p.id === periodID)) {
    // selected periodID, but period is missing, let's add it
    const period = props.salaryCycles.reduce((period: SalaryPeriod | undefined, cycle) => {
      if (period) {
        return period
      }
      const p = cycle.salaryPeriods.find((p) => p.id === periodID)
      if (p) {
        return p
      }
      return undefined
    }, undefined)
    if (period) {
      salaryPeriods.push(period)
    }
  }

  if (salaryPeriods.length === 0) {
    return <div>{t('data_integration.period_selector.no_payrolls')}</div>
  }

  return (
    <Select
      dropdownMatchSelectWidth={false}
      style={{ maxWidth: '300px', marginRight: '10px' }}
      onChange={(value) => {
        props.onSelected(value as string)
      }}
      value={periodID}
    >
      {salaryPeriods.map((period) => {
        return (
          <Select.Option key={period.id} value={period.id}>
            {t('date.date_interval', {
              start: formatDate(period.start),
              end: formatDate(period.end),
            })}
          </Select.Option>
        )
      })}
    </Select>
  )
}
