import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import AccountingDimension, {
  AccountingDimensionMutable,
  AccountingDimensionValueMutable,
} from '../../../model/accountingDimension'
import AccountingIntegration, { CostCenterAccounting } from '../../../model/accountingIntegration'
import CostCenter from '../../../model/costCenter'
import { AccountingDimensionReducer } from '../../../reducers/accountingDimensions'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Select from '../../elements/select'
import DimensionsFormRow, { DimensionLine } from './DimensionsFormRow'

type Props = {
  accountingIntegration: AccountingIntegration
  accountingDimensions: AccountingDimensionReducer
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
}

type Fields = {
  dimensionID?: string
  lines: DimensionLine[]
}

export type AccountingDimensionResult = {
  dimensions: AccountingDimensionMutable[]
}

function prepareLines(
  accountingDimensions: List<AccountingDimension>,
  hasDimensionValues: boolean,
  dimensionID?: string
): DimensionLine[] {
  return accountingDimensions
    .filter(
      (dimension) =>
        dimension.usage !== 'Employees' &&
        (!hasDimensionValues ||
          dimension.id === dimensionID ||
          dimension.values.some((value) => value.costCenterIDs && value.costCenterIDs.length > 0))
    )
    .reduce((list: DimensionLine[], dimension) => {
      if (hasDimensionValues) {
        return [
          ...list,
          ...dimension.values.map((value, i) => ({
            no: list.length + i,
            internalID: value.id,
            externalID: value.dimensionValueID,
            code: value.dimensionValueCode,
            dimensionInternalID: dimension.id,
            dimensionCode: dimension.dimensionCode,
            dimensionValueName: value.dimensionValueName,
            active: (value.costCenterIDs?.length || 0) > 0,
            costCenterID: value.costCenterIDs && value.costCenterIDs.length > 0 ? value.costCenterIDs[0] : undefined,
            hadCostCenter: !!(value.costCenterIDs && value.costCenterIDs.length > 0),
          })),
        ]
      }
      return [
        ...list,
        {
          no: list.length,
          internalID: dimension.id,
          externalID: dimension.dimensionID,
          code: dimension.dimensionCode,
          dimensionName: dimension.dimensionName,
          active: (dimension.costCenterIDs?.length || 0) > 0,
          costCenterID:
            dimension.costCenterIDs && dimension.costCenterIDs.length > 0 ? dimension.costCenterIDs[0] : undefined,
          hadCostCenter: !!(dimension.costCenterIDs && dimension.costCenterIDs.length > 0),
        },
      ]
    }, [])
}

function DimensionsForm(props: Props & FormComponentProps<Fields, AccountingDimensionResult>): ReactElement | null {
  const [showWithCostCenters, setShowWithCostCenters] = useState(
    props.getFieldValue('lines').filter((line) => line.hadCostCenter).length <= 150
  )
  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldValue, getAnyFieldError, setAnyFieldValue } = props

  const hasDimensionValues = props.accountingIntegration.hasDimensionValues
  const useDepartments = props.costCenterAccounting === 'Departments'

  const dimensions = props.accountingIntegration.hasDimensionValues
    ? props.accountingDimensions.accountingDimensions
        .filter((dimension) => dimension.usage !== 'Employees')
        .map((dimension) => ({
          id: dimension.id,
          name: dimension.dimensionName,
        }))
    : []

  const linesWithoutCostCenters = getFieldValue('lines').filter((line) => !line.hadCostCenter)
  const linesWithCostCenters = getFieldValue('lines').filter((line) => line.hadCostCenter)

  const giveAllCostCenter = () => {
    getFieldValue('lines').forEach((line) => {
      if (line.costCenterID || line.active) {
        return
      }
      setAnyFieldValue(`lines.${line.no}.active`, true)
      setAnyFieldValue(`lines.${line.no}.costCenterID`, 'New')
    })
  }

  return (
    <div>
      <Row>
        <Col span={18}>
          <p>
            {useDepartments
              ? t('dimensions_tab.card.form.intro.line_1.departments')
              : t('dimensions_tab.card.form.intro.line_1.cost_centers')}
          </p>
          {props.accountingIntegration.hasDimensionValues && (
            <p>
              {useDepartments
                ? t('dimensions_tab.card.form.intro.line_2.departments')
                : t('dimensions_tab.card.form.intro.line_2.cost_centers')}
            </p>
          )}
        </Col>
        {props.accountingIntegration.hasDimensionValues && (
          <Col span={6}>
            {decorateField('dimensionID', {
              placeholder: t('dimensions_tab.card.form.dimension_id'),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {dimensions.map((dimension) => {
                  return (
                    <Select.Option key={dimension.id} value={dimension.id}>
                      {dimension.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        {hasDimensionValues && <Col span={6}>{t('dimensions_tab.card.form.table.header.dimension')}</Col>}
        <Col span={hasDimensionValues ? 8 : 10}>
          {hasDimensionValues
            ? t('dimensions_tab.card.form.table.header.dimension_value')
            : t('dimensions_tab.card.form.table.header.dimension')}
        </Col>
        <Col span={hasDimensionValues ? 8 : 12}>
          {useDepartments
            ? t('dimensions_tab.card.form.table.header.department')
            : t('dimensions_tab.card.form.table.header.cost_center')}
        </Col>
        <Col span={2}>{t('dimensions_tab.card.form.table.header.active')}</Col>
      </Row>
      {linesWithoutCostCenters.length > 0 && (
        <Row>
          <Col span={12}>
            {useDepartments
              ? t('dimensions_tab.card.form.table.without_cost_center.intro.departments')
              : t('dimensions_tab.card.form.table.without_cost_center.intro.cost_centers')}
          </Col>
          <Col span={12}>
            <Button style={{ float: 'right' }} onClick={giveAllCostCenter}>
              {useDepartments
                ? t('dimensions_tab.card.form.table.without_cost_center.button.departments')
                : t('dimensions_tab.card.form.table.without_cost_center.button.cost_centers')}
            </Button>
          </Col>
        </Row>
      )}
      {linesWithoutCostCenters.map((line, i) => {
        return (
          <DimensionsFormRow
            key={i}
            decorateAnyField={decorateAnyField}
            getAnyFieldValue={getAnyFieldValue}
            getAnyFieldError={getAnyFieldError}
            line={line}
            hasDimensionValues={hasDimensionValues}
            useDepartments={useDepartments}
            costCenters={props.costCenters}
          />
        )
      })}
      {linesWithoutCostCenters.length > 0 && (
        <Row>
          <Col span={24}>
            {useDepartments
              ? t('dimensions_tab.card.form.table.with_cost_center.intro.departments')
              : t('dimensions_tab.card.form.table.with_cost_center.intro.cost_centers')}
          </Col>
        </Row>
      )}
      {showWithCostCenters &&
        linesWithCostCenters.map((line, i) => {
          return (
            <DimensionsFormRow
              key={i}
              decorateAnyField={decorateAnyField}
              getAnyFieldValue={getAnyFieldValue}
              getAnyFieldError={getAnyFieldError}
              line={line}
              hasDimensionValues={hasDimensionValues}
              useDepartments={useDepartments}
              costCenters={props.costCenters}
            />
          )
        })}
      {!showWithCostCenters && (
        <Row>
          <Col span={24}>
            <Button onClick={() => setShowWithCostCenters(true)}>
              {useDepartments
                ? t('dimensions_tab.card.form.table.show_with_cost_center.departments', {
                    count: linesWithCostCenters.length,
                  })
                : t('dimensions_tab.card.form.table.show_with_cost_center.cost_centers', {
                    count: linesWithCostCenters.length,
                  })}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, AccountingDimensionResult>({
  mapPropsToFields: (props) => {
    const dimensionID = props.accountingIntegration.hasDimensionValues
      ? props.accountingDimensions.accountingDimensions.filter((dimension) => dimension.usage === 'CostCenters').first()
          ?.id
      : undefined
    return {
      dimensionID,
      lines: prepareLines(
        props.accountingDimensions.accountingDimensions,
        props.accountingIntegration.hasDimensionValues,
        dimensionID
      ),
    }
  },
  onChange: (key, val, allValues, options, props) => {
    if (key === 'dimensionID') {
      return {
        dimensionID: val,
        lines: prepareLines(
          props.accountingDimensions.accountingDimensions,
          props.accountingIntegration.hasDimensionValues,
          val as string
        ),
      }
    }
    const values = {}
    setByPath(values, key, val)
    return values
  },
  onSubmit: (values, props) => {
    return {
      dimensions: [
        ...props.accountingDimensions.accountingDimensions
          .filter((dimension) => dimension.usage === 'Employees')
          .map((dimension): AccountingDimensionMutable => ({ ...dimension })),
        ...values.lines
          .filter((line) => line.active)
          .reduce((list: AccountingDimensionMutable[], line) => {
            if (props.accountingIntegration.hasDimensionValues) {
              const dimension = props.accountingDimensions.accountingDimensions.find(
                (dimension) => dimension.id === line.dimensionInternalID
              )
              if (!dimension) {
                return list
              }
              const dimensionValue = dimension.values.find((value) => value.id === line.internalID)
              if (!dimensionValue) {
                return list
              }
              const newValue: AccountingDimensionValueMutable = {
                ...dimensionValue,
                createCostCenter: line.costCenterID === 'New',
                costCenterIDs:
                  line.costCenterID !== 'New' && line.costCenterID !== undefined
                    ? [...(dimensionValue.costCenterIDs || []), line.costCenterID]
                    : dimensionValue.costCenterIDs || undefined,
              }
              const idx = list.findIndex((item) => item.id === line.dimensionInternalID)
              if (idx !== -1) {
                if (line.active) {
                  // if any is active, we set it to CostCenters
                  list[idx].usage = 'CostCenters'
                }
                list[idx].values = [...list[idx].values, newValue]
              } else {
                list.push({
                  ...dimension,
                  usage: line.active ? 'CostCenters' : 'None',
                  createCostCenter: false,
                  values: [newValue],
                })
              }
              return list
            }
            const dimension = props.accountingDimensions.accountingDimensions.find(
              (dimension) => dimension.id === line.internalID
            )
            if (!dimension) {
              return list
            }
            list.push({
              ...dimension,
              usage: line.active ? 'CostCenters' : 'None',
              createCostCenter: line.costCenterID === 'New',
              costCenterIDs:
                line.costCenterID !== 'New' && line.costCenterID !== undefined
                  ? [...(dimension.costCenterIDs || []), line.costCenterID]
                  : dimension.costCenterIDs || undefined,
            })
            return list
          }, []),
      ],
    }
  },
})(DimensionsForm)
