import { addDays, endOfMonth, getISOWeek, startOfMonth, startOfWeek, subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { TimeRegistrationClass } from '../../model/timeRegistration'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../../model/timeRegistrationTemplate'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../../reducers/timeRegistrationTemplates'
import { UserReducer } from '../../reducers/user'
import { formatDate, getDate, isTimeBetween } from '../../utils/date-utils'
import { getEmployeePayType } from '../../utils/employee-utils'
import { formatDisplayNumber, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import { validateWorkHours } from '../../utils/work-hours-util'
import Button from '../elements/button'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import { Col, Row } from '../elements/grid'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import WorkHoursForm from '../employees-single/work-hours-registration/WorkHoursForm'
import FeatureLock from '../widgets/FeatureLock'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import WorkHoursDeleteAll from './WorkHoursDeleteAll'

import './WorkHours.css'

type Props = {
  alerts: AlertReducer
  company: Company
  employees: List<Employee>
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  departments: List<Department>
  user: UserReducer
  companyUsers: CompanyUserReducer
  companyFeatures: List<CompanyFeature>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
  getTimeRegistrationTemplates: (companyID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass,
    deleteAll: boolean
  ) => Promise<boolean | void>
}

export default function WorkHours(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(getDate(), { weekStartsOn: 1 }))
  const [showDeleteAll, setShowDeleteAll] = useState(false)

  const setTemplateModal = (t: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTemplateModal(t)
  }

  const setShowDeleteAllVisibility = (b: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDeleteAll(b)
  }

  const { timeRegistrationTemplates } = props
  const previousTimeRegistrationTemplates = usePrevious(timeRegistrationTemplates)
  useEffect(() => {
    if (previousTimeRegistrationTemplates?.saving && !timeRegistrationTemplates.saving) {
      if (!timeRegistrationTemplates.error) {
        setTemplateModal(false)
      }
    }
  }, [previousTimeRegistrationTemplates, timeRegistrationTemplates])

  const workHoursEnabled = props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursFeature')
  const toggleWorkHoursFeature = (enable: boolean) => {
    if (enable) {
      props.enableCompanySettings(props.company.id, ['EnableWorkHoursFeature'])
    } else {
      props.disableCompanySettings(props.company.id, ['EnableWorkHoursFeature'])
    }
  }
  const workHoursStartEndEnabled = props.company.settingsEnabled.some(
    (setting) => setting === 'RegisterWorkHoursStartEnd'
  )
  const toggleWorkHoursStartEnd = (enable: boolean) => {
    if (enable) {
      props.enableCompanySettings(props.company.id, ['RegisterWorkHoursStartEnd'])
    } else {
      props.disableCompanySettings(props.company.id, ['RegisterWorkHoursStartEnd'])
    }
  }
  const workHoursAutoEnabled = props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursAutomatic')
  const toggleWorkHoursAuto = (enable: boolean) => {
    if (enable) {
      props.enableCompanySettings(props.company.id, ['EnableWorkHoursAutomatic'])
    } else {
      props.disableCompanySettings(props.company.id, ['EnableWorkHoursAutomatic'])
    }
  }
  const workHoursWarningsEnabled = props.company.settingsEnabled.some(
    (setting) => setting === 'EnableWorkHoursWarnings'
  )
  const toggleWorkHoursWarnings = (enable: boolean) => {
    if (enable) {
      props.enableCompanySettings(props.company.id, ['EnableWorkHoursWarnings'])
    } else {
      props.disableCompanySettings(props.company.id, ['EnableWorkHoursWarnings'])
    }
  }

  type EmployeeRow = {
    key: string
    id: string
    name: string
    position?: string
    profileImageURL?: string
    registrationsCount: string
    totalWeekHours: string
    totalMonthHours: string
    hasAverageRuleViolation: boolean
    hasRestHoursViolations: boolean
    hasDayOffViolations: boolean
  }

  const columns = [
    {
      title: t('work_hours.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
            <small>{employee.position ? employee.position : '-'}</small>
          </Headline>
        )
      },
    },
    {
      title: t('work_hours.table.header.registrations_count'),
      dataIndex: 'registrationsCount',
      key: 'registrationsCount',
    },
    {
      title: t('work_hours.table.header.total_week_hours'),
      dataIndex: 'totalWeekHours',
      key: 'totalWeekHours',
    },
    {
      title: t('work_hours.table.header.total_month_hours'),
      dataIndex: 'totalMonthHours',
      key: 'totalMonthHours',
    },
    {
      title: '',
      dataIndex: '',
      key: 'xViolations',
      render: (employee: EmployeeRow) => {
        if (!employee.hasAverageRuleViolation && !employee.hasRestHoursViolations && !employee.hasDayOffViolations) {
          return null
        }
        const warnings = []
        if (employee.hasAverageRuleViolation) {
          warnings.push(t('work_hours.table.violations.average_rule_violation'))
        }
        if (employee.hasRestHoursViolations) {
          warnings.push(t('work_hours.table.violations.rest_hours_violation'))
        }
        if (employee.hasDayOffViolations) {
          warnings.push(t('work_hours.table.violations.day_off_violation'))
        }
        return (
          <Tooltip
            title={warnings.map((w, i) => (
              <p key={i}>{w}</p>
            ))}
          >
            <Icon className="violations-icon" type="exclamationMarkCircle" />
          </Tooltip>
        )
      },
    },
  ]

  const getEmployees = (): EmployeeRow[] => {
    const weekEnd = addDays(currentWeekStart, 6)
    const weekRegistrations = props.timeRegistrations.timeRegistrations.filter(
      (timeReg) => timeReg.class === 'Work Hours' && isTimeBetween(timeReg.date, currentWeekStart, weekEnd)
    )
    const monthStart = startOfMonth(currentWeekStart)
    const monthEnd = endOfMonth(monthStart)
    const monthRegistrations = props.timeRegistrations.timeRegistrations.filter(
      (timeReg) => timeReg.class === 'Work Hours' && isTimeBetween(timeReg.date, monthStart, monthEnd)
    )
    const validationErrors =
      props.companyFeatures.some((feature) => feature.featureType === 'Work Hours Warnings') &&
      props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursWarnings')
        ? validateWorkHours(props.timeRegistrations.timeRegistrations.toArray())
        : []
    return props.employees
      .filter(
        (e) =>
          e.affiliationType !== 'Freelancer' &&
          !e.disableWorkHours &&
          !!e.activeContract &&
          ['Salaried', 'Commissioned'].includes(getEmployeePayType(e.activeContract)) &&
          (e.employmentStatus === 'New' || e.employmentStatus === 'Employed') &&
          filterEmployee(e, filter)
      )
      .map((employee) => {
        const weekRegs = weekRegistrations.filter((timeReg) => timeReg.employeeID === employee.id)
        const monthRegs = monthRegistrations.filter((timeReg) => timeReg.employeeID === employee.id)
        const totalWeekHours = weekRegs.reduce((totalHours, timeReg) => totalHours + (timeReg.hours ?? 0), 0)
        const totalMonthHours = monthRegs.reduce((totalHours, timeReg) => totalHours + (timeReg.hours ?? 0), 0)
        const validationError = validationErrors.find((e) => e.employeeID === employee.id)
        return {
          id: employee.id,
          key: employee.id,
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          registrationsCount: formatDisplayNumber(weekRegs.size),
          totalWeekHours: formatNumber(totalWeekHours, 2),
          totalMonthHours: formatNumber(totalMonthHours, 2),
          hasAverageRuleViolation: (validationError?.averageRuleViolations.length ?? 0) > 0,
          hasRestHoursViolations: (validationError?.restHoursViolations.length ?? 0) > 0,
          hasDayOffViolations: (validationError?.dayOffViolations.length ?? 0) > 0,
        }
      })
      .toArray()
  }

  const employees = getEmployees()
  const canSeeMenu =
    props.companyUsers.companyUser?.permissions.some((p) => p.permission === 'Admin') || isUserSupport(props.user)
  const hasWorkHoursAutomatic = props.companyFeatures.some((feature) => feature.featureType === 'Work Hours Automatic')

  return (
    <div className="work-hours">
      {canSeeMenu && (
        <TitleMenu>
          <Row>
            <Col span={24}>
              {hasWorkHoursAutomatic &&
                workHoursEnabled &&
                props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursAutomatic') && (
                  <Button onClick={() => setTemplateModal(true)}>{t('work_hours.header.templates')}</Button>
                )}
              {workHoursEnabled && (
                <FeatureLock
                  featurePackage="Premium"
                  featureType="Work Hours Automatic"
                  description={t('work_hours.header.enable_work_hours_auto.lock')}
                >
                  <Switch checked={workHoursAutoEnabled} id="feature-toggle" onChange={toggleWorkHoursAuto} />
                  <label htmlFor="auto-toggle">{t('work_hours.header.enable_work_hours_auto')}</label>
                </FeatureLock>
              )}
              {workHoursEnabled && (
                <FeatureLock
                  featurePackage="Premium"
                  featureType="Work Hours Warnings"
                  description={t('work_hours.header.enable_work_hours_warnings.lock')}
                >
                  <Switch checked={workHoursWarningsEnabled} id="feature-toggle" onChange={toggleWorkHoursWarnings} />
                  <label htmlFor="auto-toggle">{t('work_hours.header.enable_work_hours_warnings')}</label>
                </FeatureLock>
              )}
              {workHoursEnabled && (
                <span>
                  <Switch checked={workHoursStartEndEnabled} id="start-end-toggle" onChange={toggleWorkHoursStartEnd} />
                  <label htmlFor="start-end-toggle">{t('work_hours.header.enable_work_hours_start_end')}</label>
                </span>
              )}
              <span>
                <Switch checked={workHoursEnabled} id="feature-toggle" onChange={toggleWorkHoursFeature} />
                <label htmlFor="feature-toggle">{t('work_hours.header.enable_work_hours')}</label>
              </span>
              <Button danger onClick={() => setShowDeleteAllVisibility(true)}>
                {t('work_hours.header.delete_all')}
              </Button>
            </Col>
          </Row>
          {workHoursEnabled && (
            <Row>
              <Col span={24}>
                <EmployeeFilter
                  departments={props.departments}
                  companyUser={props.companyUsers.companyUser}
                  onFilterChange={(filter) => setFilter(filter)}
                />
              </Col>
            </Row>
          )}
        </TitleMenu>
      )}
      <Title>{t('work_hours.table.title')}</Title>
      {!workHoursEnabled && <p>{t('work_hours.table.work_hours_not_enabled')}</p>}
      {workHoursEnabled && (
        <>
          <Subtitle>
            {t('work_hours.table.week.title', {
              week_number: formatNumber(getISOWeek(currentWeekStart)),
              week_from: formatDate(currentWeekStart),
              week_to: formatDate(addDays(currentWeekStart, 6)),
            })}
          </Subtitle>
          <Row style={{ marginBottom: '15px' }}>
            <Col span={8}>
              <Button onClick={() => setCurrentWeekStart((prev) => subDays(prev, 7))} prefixIcon="arrowLeft">
                {t('work_hours.table.week.previous')}
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Button onClick={() => setCurrentWeekStart(startOfWeek(getDate(), { weekStartsOn: 1 }))}>
                {t('work_hours.table.week.this_week')}
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => setCurrentWeekStart((prev) => addDays(prev, 7))} suffixIcon="arrowRight">
                {t('work_hours.table.week.next')}
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={employees}
            pagination={employees.length > 100 ? { defaultPageSize: 100 } : false}
            onRowClick={(employee: EmployeeRow) => {
              jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/work-hours-registration')
            }}
          />
        </>
      )}

      <Modal
        key={`template-${modalKey}`}
        visible={showTemplateModal}
        onOk={() => setTemplateModal(false)}
        onCancel={() => setTemplateModal(false)}
        width={750}
        footer={null}
      >
        <WorkHoursForm
          templateMode={true}
          alerts={props.alerts}
          company={props.company}
          timeRegistrationTemplates={props.timeRegistrationTemplates}
          addAlert={props.addAlert}
          removeAlert={props.removeAlert}
          getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
          saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
          deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
        />
      </Modal>

      <Modal
        key={`delete-${modalKey}`}
        visible={showDeleteAll}
        onOk={() => setShowDeleteAllVisibility(false)}
        onCancel={() => setShowDeleteAllVisibility(false)}
        width={500}
        footer={null}
      >
        <WorkHoursDeleteAll
          company={props.company}
          deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
          closeModal={() => setShowDeleteAllVisibility(false)}
        />
      </Modal>
    </div>
  )
}
