import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import classNames from '../../antd/_util/classNames'
import { RadioProps } from './radio'

function getCheckedValue<DataType>(children: ReactNode) {
  let value: DataType | undefined = undefined
  let matched = false
  React.Children.forEach(
    children as React.ReactElement<RadioProps<DataType>>[],
    (radio: React.ReactElement<RadioProps<DataType>>) => {
      if (radio && radio.props && radio.props.checked) {
        value = radio.props.value
        matched = true
      }
    }
  )
  return matched ? { value } : undefined
}

type RadioGroupState<DataType> = {
  value?: DataType
  disabled?: boolean
  name?: string
}

type RadioGroup<DataType> = {
  groupState: RadioGroupState<DataType>
  setGroupState: React.Dispatch<React.SetStateAction<RadioGroupState<DataType>>>
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

// base on https://stackoverflow.com/questions/58083588/typescript-generic-once-function
// this function allows wrapping a function to ensure it is only called once
const once = <A extends unknown[], R, T>(fn: (this: T, ...arg: A) => R): ((this: T, ...arg: A) => R) => {
  let done = false
  let result: R
  return function (this: T, ...args: A): R {
    if (done) {
      return result
    }
    done = true
    result = fn.apply(this, args)
    return result
  }
}

const createRadioGroupContext = once(<DataType,>() => React.createContext({} as RadioGroup<DataType>))
export const useRadioGroupContext = <DataType,>() => useContext(createRadioGroupContext<DataType>())

type Props<DataType> = {
  id?: string
  value?: DataType
  defaultValue?: DataType
  disabled?: boolean
  name?: string
  prefixCls?: string
  className?: string
  style?: CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
}

export default function Group<DataType = string>(props: PropsWithChildren<Props<DataType>>): ReactElement | null {
  const [state, setState] = useState<RadioGroupState<DataType>>(() => {
    let value: DataType | undefined
    if (props.value) {
      value = props.value
    } else if (props.defaultValue) {
      value = props.defaultValue
    } else {
      const checkedValue = getCheckedValue(props.children)
      value = checkedValue?.value
    }
    return {
      value,
      disabled: props.disabled,
      name: props.name,
    }
  })

  const { value } = props

  useEffect(() => {
    if (value !== undefined) {
      setState((prev) => ({ ...prev, value }))
    }
  }, [value])

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const lastValue = state.value
    const value = ev.target.value as unknown as DataType
    if (props.value === undefined) {
      setState((prev) => ({ ...prev, value }))
    }

    const onChange = props.onChange
    if (onChange && value !== lastValue) {
      onChange(ev)
    }
  }

  const { prefixCls = 'ant-radio-group', className = '' } = props
  const classString = classNames(prefixCls, className)

  const children = props.children

  const RadioGroupContext = createRadioGroupContext<DataType>()

  return (
    <RadioGroupContext.Provider
      value={{
        groupState: state,
        setGroupState: setState,
        onChange,
      }}
    >
      <div
        className={classString}
        style={props.style}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        id={props.id}
      >
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}
