import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Asset from '../../../model/asset'
import AssetCategory from '../../../model/assetCategory'
import { formatAPIDate, getDate, isTimeAfter, isTimeBefore } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Select from '../../elements/select'

type Fields = {
  title?: string
  categoryID?: string
  description?: string
  note?: string
  receivedDate?: Date
  returnDate?: Date
  hidden: boolean
}

export type AssetResult = {
  title: string
  categoryID: string
  description?: string
  note?: string
  receivedDate?: string
  returnDate?: string
  hidden: boolean
}

type Props = {
  returning: boolean
  editing: boolean
  full: boolean
  assetID?: string
  assets: List<Asset>
  assetCategories: List<AssetCategory>
}

function AssetEditForm(props: Props & FormComponentProps<Fields, AssetResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props
  const { editing, full } = props
  return (
    <div>
      {props.returning && (
        <div>
          <Row>
            <Col span={24}>
              {decorateField('returnDate', {
                placeholder: t('asset.edit.form.return_date'),
                validate: (val) => {
                  if (val && getFieldValue('receivedDate') && isTimeBefore(val, getFieldValue('receivedDate')!)) {
                    return t('asset.edit.form.return_date.before_received')
                  }
                  return null
                },
              })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button htmlType="submit" size="extra-extra-large" type="secondary">
                {t('asset.tab.confirm.return')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {!props.returning && (
        <div>
          <Row>
            <Col span={24}>
              {decorateField('title', {
                placeholder: t('asset.edit.form.title'),
                validate: (val) => {
                  if (!val) {
                    return t('asset.edit.form.title.required')
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {decorateField('description', {
                placeholder: t('asset.edit.form.description'),
              })(<Input />)}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {decorateField('note', {
                placeholder: t('asset.edit.form.note'),
              })(<Input />)}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {decorateField('categoryID', {
                placeholder: t('asset.edit.form.category_id'),
                validate: (val) => (!val ? t('asset.edit.form.category_id.required') : null),
              })(
                <Select dropdownMatchSelectWidth={false}>
                  {props.assetCategories.map((category) => {
                    return (
                      <Select.Option key={category.id} value={category.id}>
                        {category.title}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Col>
          </Row>
          {editing && full && (
            <Row>
              <Col span={24}>
                {decorateField('receivedDate', {
                  placeholder: t('asset.edit.form.received_date'),
                  validate: (val) => {
                    if (val && isTimeAfter(val, getDate())) {
                      return t('asset.edit.form.received_date.not_future')
                    }
                    return null
                  },
                })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
              </Col>
            </Row>
          )}
          {editing && full && (
            <Row>
              <Col span={24}>
                {decorateField('returnDate', {
                  placeholder: t('asset.edit.form.return_date'),
                  validate: (val) => {
                    if (val && getFieldValue('receivedDate') && isTimeBefore(val, getFieldValue('receivedDate')!)) {
                      return t('asset.edit.form.return_date.before_received')
                    }
                    return null
                  },
                })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Button htmlType="submit" size="extra-extra-large" type="secondary">
                {props.assetID ? t('asset.edit.form.submit.edit') : t('asset.edit.form.submit.create')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default withValidations<Props, Fields, AssetResult>({
  mapPropsToFields: (props: Props) => {
    const fields: Fields = { hidden: false }

    const asset = props.assets.find((asset: Asset) => asset.id === props.assetID)
    if (asset) {
      fields.title = asset.title
      fields.description = asset.description
      fields.note = asset.note
      fields.categoryID = asset.categoryID
      if (asset.receivedDate) {
        fields.receivedDate = getDate(asset.receivedDate)
      }
      if (asset.returnDate) {
        fields.returnDate = getDate(asset.returnDate)
      } else {
        if (props.returning) {
          fields.returnDate = getDate()
        }
      }
      fields.hidden = asset.hidden
    }

    return fields
  },
  onSubmit: (values) => {
    return {
      ...values,
      title: values.title!,
      categoryID: values.categoryID!,
      description: values.description,
      note: values.note,
      hidden: values.hidden,
      receivedDate: values.receivedDate ? formatAPIDate(values.receivedDate) : undefined,
      returnDate: values.returnDate ? formatAPIDate(values.returnDate) : undefined,
    }
  },
})(AssetEditForm)
