import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Company from '../../model/company'
import Employee from '../../model/employee'
import { EmployeeReducer } from '../../reducers/employees'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Icon from '../elements/icon'
import Input from '../elements/input'

type Props = {
  employee: Employee
  employees: EmployeeReducer
  company: Company

  onDelete: (employeeID: string) => void
}

type Fields = {
  employeeID: string
  reimbursementVoucherAutoApprovalLimit?: string
}

export type ReimbursementSettingsEmployeeResult = {
  employeeID: string
  reimbursementVoucherAutoApprovalLimit: number
}

function ReimbursementSettingsEmployeeForm(
  props: Props & FormComponentProps<Fields, ReimbursementSettingsEmployeeResult>
): ReactElement | null {
  const [editMode, setEditMode] = useState(false)

  const { employees } = props
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      if (!employees.error) {
        setEditMode(false)
      }
    }
  }, [previousEmployees, employees])

  const { decorateField, getFieldValue } = props

  if (!editMode) {
    return (
      <div onClick={() => setEditMode(true)} className="reimbursement-limit-display">
        {props.employee.reimbursementVoucherAutoApprovalLimit !== undefined ? (
          <div>
            <strong>{formatCurrency(props.employee.reimbursementVoucherAutoApprovalLimit)}</strong>
          </div>
        ) : (
          <div>
            <i>
              {props.company.reimbursementVoucherAutoApprovalLimit
                ? t('reimbursement_settings.employee.form.auto_approval_limit.company_limit', {
                    amount: formatCurrency(props.company.reimbursementVoucherAutoApprovalLimit),
                  })
                : t('reimbursement_settings.employee.form.auto_approval_limit.no_limit')}
            </i>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="reimbursement-limit-form">
      {decorateField('reimbursementVoucherAutoApprovalLimit', {
        placeholder: t('reimbursement_settings.employee.form.auto_approval_limit'),
        suffix: t('reimbursement_settings.employee.form.auto_approval_limit.suffix'),
        validate: (val) => (!val ? t('reimbursement_settings.employee.form.auto_approval_limit.required') : null),
      })(<Input />)}
      <div className="reimbursement-limit-buttons">
        <Button htmlType="submit" size="extra-extra-large" type="primary">
          {t('reimbursement_settings.employee.form.auto_approval_limit.save')}
        </Button>
        <div className="reimbursement-limit-remove">
          {props.employee.reimbursementVoucherAutoApprovalLimit !== undefined && (
            <Button
              size="extra-extra-large"
              className="ant-btn-danger"
              onClick={() => props.onDelete(getFieldValue('employeeID'))}
            >
              {t('reimbursement_settings.employee.form.auto_approval_limit.remove')}
            </Button>
          )}
        </div>
        <div onClick={() => setEditMode(false)} className="reimbursement-limit-toggle">
          <Icon type="xSign" />
        </div>
      </div>
    </div>
  )
}

export default withValidations<Props, Fields, ReimbursementSettingsEmployeeResult>({
  mapPropsToFields: (props) => ({
    employeeID: props.employee.id,
    reimbursementVoucherAutoApprovalLimit:
      props.employee.reimbursementVoucherAutoApprovalLimit !== undefined
        ? formatInputNumber(props.employee.reimbursementVoucherAutoApprovalLimit)
        : undefined,
  }),
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'reimbursementVoucherAutoApprovalLimit':
        values.reimbursementVoucherAutoApprovalLimit = formatInputNumber(
          parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    reimbursementVoucherAutoApprovalLimit: forceParseInputNumber(values.reimbursementVoucherAutoApprovalLimit),
  }),
})(ReimbursementSettingsEmployeeForm)
