import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Bank from '../../../model/bank'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import Employee, { NationalIDType } from '../../../model/employee'
import { TransferDestinationType } from '../../../model/transfer'
import { DateFormat } from '../../../model/types'
import { EmployeeEmergencyContactReducer } from '../../../reducers/employeeEmergencyContacts'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import { UserReducer } from '../../../reducers/user'
import CountryCode from '../../../types/country-code'
import Genders from '../../../types/gender'
import Gender from '../../../types/gender'
import Language from '../../../types/language'
import { sortCountryCode } from '../../../utils/country-code-utils'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { validateEmail } from '../../../utils/email-utils'
import { getNationalIDTypes, hasBirthDate, hasCountry, hasGender, hasNationalID } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import {
  formatCountryCode,
  formatGender,
  formatLanguage,
  formatNationalIDLabel,
  formatNationalIDType,
} from '../../../utils/format-utils'
import { canLookupNationalID, lookupNationalID } from '../../../utils/national-id-utils'
import { setByPath } from '../../../utils/object-utils'
import { t, tx } from '../../../utils/translation-utils'
import {
  getCityFromPostalCode,
  getPhonePrefixes,
  normalizeNationalID,
  trimSpaces,
  validateNationalID,
} from '../../../utils/validation-utils'
import Form from '../../antd/form'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import HelpModal from '../../elements/HelpModal'
import Input from '../../elements/input'
import Radio from '../../elements/radio'
import Select from '../../elements/select'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import PhoneNumberForm from '../../form-elements/PhoneNumberForm'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  company: Company
  user: UserReducer
  employee: Employee
  companyFeatures: List<CompanyFeature>
  employees: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employments: EmploymentReducer
  banks: List<Bank>

  disabled: boolean
}

type Fields = {
  employeeNumber?: string
  nationalIDType: NationalIDType
  nationalID?: string
  nationalIDUnlocked: boolean
  name: string
  address: string
  postalCode: string
  city: string
  country?: CountryCode
  tin?: string
  gender?: Gender
  birthDate?: Date
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  language: Language
  useManualTransfer: boolean
  transferDestinationType?: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  emergencyContactName?: string
  emergencyContactEmail?: string
  emergencyContactPhoneNumber?: string
  emergencyContactPhoneNumberCountryCode?: string
  emergencyContactRelation?: string
}

export type ProfileResult = {
  employeeNumber: string
  nationalIDType: NationalIDType
  nationalID?: string
  name: string
  address: string
  postalCode: string
  city: string
  country?: CountryCode
  gender?: Gender
  birthDate?: DateFormat
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  language: Language
  useManualTransfer: boolean
  transferDestinationType: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  emergencyContactName?: string
  emergencyContactEmail?: string
  emergencyContactPhoneNumber?: string
  emergencyContactPhoneNumberCountryCode?: string
  emergencyContactRelation?: string
}

function ProfileForm(props: Props & FormComponentProps<Fields, ProfileResult>): ReactElement | null {
  type BankState = {
    bankName?: string
    bankLogo?: string
  }
  const getBankLogo = (sortCode?: string): BankState => {
    const bank = props.banks.find((b) => b.sortCode === sortCode)
    return {
      bankName: bank?.bankName,
      bankLogo: bank?.logoImageURL,
    }
  }
  const [bank, setBank] = useState<BankState>(getBankLogo(props.employee.bankRegistrationNumber))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = e.target.value
    if (num.length === 4) {
      setBank(getBankLogo(num))
    }
  }

  const hasEmployeeEmergencyContactsFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Employee Emergency Contacts')
  }

  const { decorateField, setFieldValue, getFieldValue, getFieldError } = props

  const employee = props.employee

  const canUseEasyAccount = props.company.enableNemKontoTransfers && getFieldValue('nationalIDType') !== 'DK No CPR'

  const nationalIDTypes = getNationalIDTypes(props.company.settingsEnabled, employee.affiliationType)
  const canNationalIDLookUp = canLookupNationalID(props.user.user, getFieldValue('nationalIDType'))

  const handleLookupNationalID = () => {
    const { setFieldError, setFieldValue } = props
    lookupNationalID(getFieldValue, setFieldValue, setFieldError)
  }

  return (
    <div className="employees-single-form">
      {props.getFormError()}
      {nationalIDTypes.length > 1 && (
        <Row>
          <Col span={12}>
            {decorateField('nationalIDType', {
              title: t('employee_single.profile.form.national_id_type'),
              placeholder: t('employee_single.profile.form.national_id_type.placeholder'),
              validate: (val) => (!val ? t('employee_single.profile.form.national_id_type.required') : null),
            })(
              <Select disabled={props.disabled} tabIndex={1} dropdownMatchSelectWidth={false}>
                {nationalIDTypes.map((nationalIDType) => {
                  return (
                    <Select.Option key={nationalIDType} value={nationalIDType}>
                      {formatNationalIDType(nationalIDType)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      {employee.activeEmployment && (
        <Row>
          <Col span={12}>
            {decorateField('employeeNumber', {
              placeholder: t('employee_single.profile.form.employee_number'),
              validate: (val) => {
                if (!val) {
                  return t('employee_single.profile.form.employee_number.required')
                }
                if (!val.match(/^[a-zæøå0-9 -]{1,15}$/i)) {
                  return t('employee_single.profile.form.employee_number.invalid')
                }
                if (
                  props.employees.employees.some(
                    (otherEmployee) =>
                      !!otherEmployee.activeEmployment &&
                      otherEmployee.activeEmployment.employeeNumber === val &&
                      otherEmployee.id !== employee.id
                  )
                ) {
                  return t('employee_single.profile.form.employee_number.in_use')
                }
                return null
              },
            })(<Input disabled={props.disabled} tabIndex={1} />)}
          </Col>
        </Row>
      )}
      <Row>
        {hasNationalID(getFieldValue('nationalIDType')) && (
          <Col span={canNationalIDLookUp ? 8 : 10}>
            {decorateField('nationalID', {
              placeholder: formatNationalIDLabel(getFieldValue('nationalIDType')),
              validate: (val) => {
                return validateNationalID(
                  val,
                  getFieldValue('nationalIDType'),
                  props.employees.employees.toArray(),
                  props.employee
                )
              },
            })(<Input disabled={props.disabled || !getFieldValue('nationalIDUnlocked')} />)}
          </Col>
        )}
        {canNationalIDLookUp && (
          <Col span={2}>
            <Button.Icon
              title={t('employee_single.profile.form.national_id.look_up')}
              onClick={handleLookupNationalID}
              className="icon-form-button"
              disabled={!getFieldValue('nationalIDUnlocked')}
              size="large"
              type="magnifyingGlass"
            />
          </Col>
        )}
        {hasNationalID(getFieldValue('nationalIDType')) && (
          <Col span={2}>
            <Button.Icon
              title={t('employee_single.profile.form.national_id_unlock')}
              className="icon-form-button"
              onClick={() => setFieldValue('nationalIDUnlocked', !getFieldValue('nationalIDUnlocked'))}
              size="large"
              type={getFieldValue('nationalIDUnlocked') ? 'eyeCrossed' : 'eye'}
            />
          </Col>
        )}
        <Col span={12}>
          {decorateField('name', {
            placeholder: t('employee_single.profile.form.name'),
            validate: (val) => (!val ? t('employee_single.profile.form.name.required') : null),
          })(<Input disabled={props.disabled} tabIndex={2} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('address', {
            placeholder: t('employee_single.profile.form.address'),
            validate: (val) => (!val ? t('employee_single.profile.form.address.required') : null),
          })(<Input disabled={props.disabled} tabIndex={3} />)}
        </Col>
        <Col span={6}>
          {decorateField('postalCode', {
            placeholder: t('employee_single.profile.form.postal_code'),
            validate: (val) => {
              if (!val) {
                return t('employee_single.profile.form.postal_code.required')
              }
              return null
            },
          })(<Input disabled={props.disabled} tabIndex={4} />)}
        </Col>
        <Col span={6}>
          {decorateField('city', {
            placeholder: t('employee_single.profile.form.city'),
            validate: (val) => (!val ? t('employee_single.profile.form.city.required') : null),
          })(<Input disabled={props.disabled} tabIndex={5} />)}
        </Col>
      </Row>
      {(hasCountry(getFieldValue('nationalIDType')) || hasBirthDate(getFieldValue('nationalIDType'))) && (
        <Row>
          {hasCountry(getFieldValue('nationalIDType')) && (
            <Col span={12}>
              {decorateField('country', {
                title: t('employee_single.profile.form.country'),
                placeholder: t('employee_single.profile.form.country.placeholder'),
                validate: (val) => {
                  if (!val) {
                    return t('employee_single.profile.form.country.required')
                  }
                  if (val === CountryCode.GB) {
                    return t('employee_single.profile.form.country.not_gb')
                  }
                  return null
                },
              })(
                <Select
                  tabIndex={9}
                  disabled={props.disabled}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  filterOption={(input: string, option: ReactElement) => {
                    const q = input.toLowerCase()
                    return (
                      option.props.title.toLowerCase().indexOf(q) >= 0 ||
                      option.props.children.toLowerCase().indexOf(q) >= 0
                    )
                  }}
                >
                  {Object.values(CountryCode)
                    .filter((code) => code !== CountryCode.DK)
                    .sort((a, b) => sortCountryCode(a, b))
                    .map((code) => {
                      return (
                        <Select.Option key={code} value={code} title={code}>
                          {formatCountryCode(code)}
                        </Select.Option>
                      )
                    })}
                </Select>
              )}
            </Col>
          )}
          {hasBirthDate(getFieldValue('nationalIDType')) && (
            <Col span={12}>
              {decorateField('birthDate', {
                placeholder: t('employee_single.profile.form.birth_date'),
                validate: (val) => {
                  if (!val) {
                    return t('employee_single.profile.form.birth_date.required')
                  }
                  if (val.getFullYear() >= getDate().getFullYear()) {
                    return t('employee_single.profile.form.birth_date.invalid')
                  }
                  return null
                },
              })(<DatePicker disabled={props.disabled} tabIndex={10} allowClear={false} style={{ width: '100%' }} />)}
            </Col>
          )}
          {getFieldValue('country') !== CountryCode.DK && (
            <Col span={12}>
              <Form.Item validateStatus={props.getFieldError('tin') ? 'error' : 'success'}>
                <label title={t('employee_single.profile.form.tin')}>
                  {props.getFieldError('tin') || t('employee_single.profile.form.tin')}{' '}
                  <HelpModal>
                    <p>{t('employee_single.profile.form.tin.intro')}</p>
                    <p>
                      {tx('employee_single.profile.form.tin.read_more', {
                        link: (
                          <a
                            target="_blank"
                            href="https://taxation-customs.ec.europa.eu/online-services/online-services-and-databases-taxation/tin-taxpayer-identification-number_da"
                          >
                            {t('employee_single.profile.form.tin.read_more.link')}
                          </a>
                        ),
                      })}
                    </p>
                  </HelpModal>
                </label>
                {decorateField('tin', {
                  placeholder: t('employee_single.profile.form.tin'),
                  skipLabel: true,
                  skipWrapper: true,
                })(<Input />)}
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      {hasGender(getFieldValue('nationalIDType')) && (
        <Row>
          <Col span={12}>
            {decorateField('gender', {
              title: t('employee_single.profile.form.gender'),
              placeholder: t('employee_single.profile.form.gender.placeholder'),
              validate: (val) => (!val ? t('employee_single.profile.form.gender.required') : null),
            })(
              <Select disabled={props.disabled} tabIndex={11} dropdownMatchSelectWidth={false}>
                <Select.Option value={Genders.MALE}>{formatGender(Genders.MALE)}</Select.Option>
                <Select.Option value={Genders.FEMALE}>{formatGender(Genders.FEMALE)}</Select.Option>
                <Select.Option value={Genders.UNKNOWN}>{formatGender(Genders.UNKNOWN)}</Select.Option>
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField('email', {
            placeholder: t('employee_single.profile.form.email'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!validateEmail(val)) {
                return t('employee_single.profile.form.email.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} tabIndex={12} />)}
        </Col>
        <Col span={12}>
          <PhoneNumberForm
            decorateField={decorateField}
            getFieldValue={getFieldValue}
            getFieldError={getFieldError}
            tabIndex={13}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('language', {
            placeholder: t('employee_single.profile.form.language'),
            validate: (val) => (!val ? t('employee_single.profile.form.language.required') : null),
          })(
            <Select
              disabled={props.disabled}
              tabIndex={15}
              dropdownMatchSelectWidth={false}
              placeholder={t('employee_single.profile.form.language.placeholder')}
            >
              <Select.Option value={Language.DANISH}>{formatLanguage(Language.DANISH)}</Select.Option>
              <Select.Option value={Language.ENGLISH}>{formatLanguage(Language.ENGLISH)}</Select.Option>
            </Select>
          )}
        </Col>
        <Col span={12} />
      </Row>
      <Row>&nbsp;</Row>
      {bank.bankLogo && (
        <TitleMenu>
          <img src={bank.bankLogo} className="employees-single-bank-logo" alt={bank.bankName} />
        </TitleMenu>
      )}
      <Title>{t('employee_single.profile.form.bank_info_title')}</Title>
      <Row>
        <Col span={12}>
          {decorateField('bankRegistrationNumber', {
            placeholder: t('employee_single.profile.form.bank_registration_number'),
            validate: (val) => {
              if (getFieldValue('useManualTransfer') || getFieldValue('transferDestinationType') !== 'DK Account') {
                return null
              }
              if (!val) {
                return t('employee_single.profile.form.bank_registration_number.required')
              }
              val = val.toString()
              if (!val.match(/^[0-9]{4}$/)) {
                return t('employee_single.profile.form.bank_registration_number.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} tabIndex={16} onChange={handleChange} />)}
        </Col>
        <Col span={12}>
          {decorateField('bankAccountNumber', {
            placeholder: t('employee_single.profile.form.bank_account_number'),
            validate: (val) => {
              if (getFieldValue('useManualTransfer') || getFieldValue('transferDestinationType') !== 'DK Account') {
                return null
              }
              if (!val) {
                return t('employee_single.profile.form.bank_account_number.required')
              }
              val = val.toString()
              if (!val.match(/^[0-9]{4,10}$/)) {
                return t('employee_single.profile.form.bank_account_number.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
          {!canUseEasyAccount && (
            <Form.Item style={{ float: 'right', marginTop: -25 }}>
              <label htmlFor="useManualTransfer">&nbsp;</label>
              <div className="ant-switch-wrapper">
                {decorateField('useManualTransfer', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch disabled={props.disabled} />)}
                <span className="ant-switch-text">{t('employee_single.profile.form.use_manual_transfer')}</span>
              </div>
            </Form.Item>
          )}
        </Col>
      </Row>
      {canUseEasyAccount && (
        <Row>
          <Col span={24}>
            <Form.Item>
              {decorateField('transferDestinationType', {
                title: t('employee_single.profile.form.transfer_destination_type'),
              })(
                <Radio.Group disabled={props.disabled}>
                  <Radio value="DK NemKonto">
                    {t('employee_single.profile.form.transfer_destination_type.easy_account')}
                  </Radio>
                  <Radio value="DK Account">
                    {t('employee_single.profile.form.transfer_destination_type.dk_account')}
                  </Radio>
                  <Radio value="None">{t('employee_single.profile.form.transfer_destination_type.none')}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasEmployeeEmergencyContactsFeature() && (
        <div>
          <Row>&nbsp;</Row>
          <Title>{t('employee_single.profile.form.emergency_contact_title')}</Title>
          <Row>
            <Col span={12}>
              {decorateField('emergencyContactName', {
                placeholder: t('employee_single.profile.form.emergency_contact.name'),
              })(<Input disabled={props.disabled} tabIndex={20} />)}
            </Col>
            <Col span={12}>
              {decorateField('emergencyContactRelation', {
                placeholder: t('employee_single.profile.form.emergency_contact.relation'),
              })(<Input disabled={props.disabled} tabIndex={21} />)}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {decorateField('emergencyContactEmail', {
                placeholder: t('employee_single.profile.form.emergency_contact.email'),
                validate: (val) => {
                  if (!val) {
                    return null
                  }
                  if (!validateEmail(val)) {
                    return t('employee_single.profile.form.emergency_contact.email.invalid')
                  }
                  return null
                },
              })(<Input disabled={props.disabled} tabIndex={22} />)}
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={
                  getFieldError('emergencyContactPhoneNumberCountryCode') ||
                  getFieldError('emergencyContactPhoneNumber')
                    ? 'error'
                    : 'success'
                }
              >
                <label
                  htmlFor="emergencyContactPhoneNumber"
                  title={t('employee_single.profile.form.emergency_contact.phone_number')}
                >
                  {getFieldError('emergencyContactPhoneNumberCountryCode') ||
                    getFieldError('emergencyContactPhoneNumber') ||
                    t('employee_single.profile.form.emergency_contact.phone_number')}
                </label>
                <Input.Group compact className="country-code-phone-group">
                  {decorateField('emergencyContactPhoneNumberCountryCode', {
                    placeholder: t('employee_single.profile.form.emergency_contact.country_code'),
                    validate: (val) => {
                      if (!val) {
                        return t('employee_single.profile.form.emergency_contact.country_code.required')
                      }
                      if (getPhonePrefixes().indexOf(val) === -1) {
                        return t('employee_single.profile.form.emergency_contact.country_code.invalid')
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(
                    <Select disabled={props.disabled} tabIndex={23} dropdownMatchSelectWidth={false}>
                      {getPhonePrefixes().map((prefix) => {
                        return (
                          <Select.Option key={prefix} value={prefix}>
                            +{prefix}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                  {decorateField('emergencyContactPhoneNumber', {
                    placeholder: t('employee_single.profile.form.emergency_contact.phone_number'),
                    validate: (val) => {
                      if (!val) {
                        return null
                      }
                      if (!val.match(/^[0-9]+$/)) {
                        return t('employee_single.profile.form.emergency_contact.phone_number.invalid')
                      }
                      if (
                        getFieldValue('emergencyContactPhoneNumberCountryCode') === '45' &&
                        !val.match(/^[0-9]{8}$/)
                      ) {
                        return t('employee_single.profile.form.emergency_contact.phone_number.invalid')
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input disabled={props.disabled} tabIndex={24} />)}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      {!props.disabled && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="extra-extra-large" type="secondary" tabIndex={30}>
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {(props.employees.saving || props.employments.saving || props.employeeEmergencyContacts.saving) && (
        <LoadingOverlay />
      )}
    </div>
  )
}

export default withValidations<Props, Fields, ProfileResult>({
  mapPropsToFields: (props) => {
    const canUseNemKonto = props.company.enableNemKontoTransfers && props.employee.nationalIDType !== 'DK No CPR'

    const fields: Fields = {
      employeeNumber: props.employee.activeEmployment && props.employee.activeEmployment.employeeNumber,
      nationalIDType: props.employee.nationalIDType,
      nationalID: normalizeNationalID(props.employee.nationalID || '', props.employee.nationalIDType),
      nationalIDUnlocked: false,
      name: props.employee.name ?? '',
      address: props.employee.address ?? '',
      postalCode: props.employee.postalCode ?? '',
      city: props.employee.city ?? '',
      country: props.employee.country,
      gender: props.employee.gender,
      birthDate: props.employee.birthDate ? getDate(props.employee.birthDate) : undefined,
      tin: props.employee.tin,
      email: props.employee.email,
      phoneNumber: props.employee.phoneNumber,
      phoneNumberCountryCode: props.employee.phoneNumberCountryCode,
      language: props.employee.language || Language.DANISH,
      useManualTransfer: props.employee.transferDestinationType === 'None',
      transferDestinationType: canUseNemKonto ? props.employee.transferDestinationType : undefined,
      bankRegistrationNumber: props.employee.bankRegistrationNumber,
      bankAccountNumber: props.employee.bankAccountNumber,
      emergencyContactPhoneNumberCountryCode: '45',
    }
    if (props.employeeEmergencyContacts.employeeEmergencyContact) {
      const emergencyContact = props.employeeEmergencyContacts.employeeEmergencyContact
      fields.emergencyContactName = emergencyContact.name
      fields.emergencyContactEmail = emergencyContact.email
      fields.emergencyContactPhoneNumberCountryCode = emergencyContact.phoneNumberCountryCode
      fields.emergencyContactPhoneNumber = emergencyContact.phoneNumber
      fields.emergencyContactRelation = emergencyContact.relation
    }
    return fields
  },
  onChange: (key, val, allValues) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'nationalID':
        values.nationalID = normalizeNationalID(val as string, allValues.nationalIDType)
        break
      case 'nationalIDType': {
        const type = val as NationalIDType
        values.nationalIDType = type
        if (type === 'DK No CPR' || type === 'DK Foreign') {
          values['country'] = undefined
        }
        break
      }
      case 'postalCode': {
        values.postalCode = val as string
        if (allValues.nationalIDType === 'DK CPR' || allValues.nationalIDType === 'DK CVR') {
          const city = getCityFromPostalCode(val as string)
          if (city) {
            values.city = city
          }
        }
        break
      }
      case 'phoneNumber':
      case 'emergencyContactPhoneNumber':
        values[key] = trimSpaces(val as string)
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    employeeNumber: values.employeeNumber!,
    nationalID:
      values.nationalIDType === 'DK No CPR' || !values.nationalID ? undefined : values.nationalID.replace(/-/g, ''),
    transferDestinationType: values.transferDestinationType
      ? values.transferDestinationType
      : values.useManualTransfer
      ? 'None'
      : 'DK Account',
    bankRegistrationNumber: values.bankRegistrationNumber ? values.bankRegistrationNumber.trim() : undefined,
    bankAccountNumber: values.bankAccountNumber ? values.bankAccountNumber.trim() : undefined,
    birthDate: values.birthDate ? formatAPIDate(values.birthDate) : undefined,
  }),
})(ProfileForm)
