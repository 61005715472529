import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import OneTimePayPension, {
  OneTimePayPensionCreationFields,
  OneTimePayPensionMutableFields,
} from '../../../model/oneTimePayPension'
import PensionCompany from '../../../model/pensionCompany'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayPensionReducer } from '../../../reducers/oneTimePayPensions'
import PensionScheme from '../../../types/pension-scheme'
import { formatDate } from '../../../utils/date-utils'
import { formatPensionScheme } from '../../../utils/format-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import ExtraPensionEdit from './ExtraPensionEdit'

type Props = {
  subsection?: string
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean
  employee: Employee
  oneTimePayPensions: OneTimePayPensionReducer
  pensionCompanies: List<PensionCompany>
  salaryCycle?: SalaryCycle
  viewingContract?: Contract

  addAlert: addAlertSignature
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  getOneTimePayPensions: (companyID?: string, employeeID?: string) => void
  addOneTimePayPension: (employeeID: string, otp: OneTimePayPensionCreationFields) => void
  updateOneTimePayPension: (employeeID: string, otp: OneTimePayPensionMutableFields) => void
  deleteOneTimePay: (id: string) => void
}

export default function ExtraPensionTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)

  useEffectOnce(() => {
    const employeeID = props.employee.id
    if (
      (!props.oneTimePayPensions.loading && !props.oneTimePayPensions.loaded) ||
      props.oneTimePayPensions.employeeID !== employeeID
    ) {
      props.getOneTimePayPensions(undefined, employeeID)
    }
  })

  const setEditVisibility = (id: string | boolean, mutable = true) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setMutable(mutable)
  }

  const { oneTimePayPensions } = props
  const previousOneTimePayPensions = usePrevious(oneTimePayPensions)

  useEffect(() => {
    // Check for save callback
    if (previousOneTimePayPensions && previousOneTimePayPensions.saving && !props.oneTimePayPensions.saving) {
      // Check for no error occurred
      if (!props.oneTimePayPensions.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const showHistory = () => props.subsection === 'history'

  const approve = (oneTimePay: OneTimePayPension) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePay.oneTimePay.id])
      props.approveOneTimePays([oneTimePay.oneTimePay.id])
    }
  }
  const unapprove = (oneTimePay: OneTimePayPension) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePay.oneTimePay.id))
      props.unapproveOneTimePays([oneTimePay.oneTimePay.id])
    }
  }
  const remove = (oneTimePay: OneTimePayPension) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePay.oneTimePay.id])
        props.deleteOneTimePay(oneTimePay.oneTimePay.id)
      }
    }
  }

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    title: string
    amount: string
    hasCompany: boolean
    companyName?: string
    customerID?: string
    scheme: string
    sortCode?: string
    account?: string
    immutable: boolean
    approved: boolean
    original: OneTimePayPension
  }

  const columns = [
    {
      title: t('extra_pension.tab.table.header.approved'),
      key: 'x1',
      className: 'one-time-pay-table-approved',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.approved) {
          return (
            <span className="table-approve-wrapper">
              {t('extra_pension.tab.table.approved.true')}
              {!oneTimePay.immutable && props.canApproveObjects && (
                <Tooltip title={t('extra_pension.tab.table.approved.remove')}>
                  <span onClick={unapprove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="xSign" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(oneTimePay.id) !== -1) {
          return t('extra_pension.tab.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('extra_pension.tab.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(oneTimePay.original)} type="standard">
            {t('extra_pension.tab.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('extra_pension.tab.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('extra_pension.tab.table.header.pension_info'),
      key: 'pensionInfo',
      render: (otp: OneTimePayRow) => {
        if (otp.hasCompany) {
          return (
            <div>
              {t('extra_pension.tab.table.pension_info.company_name')}: {otp.companyName}
              <br />
              {t('extra_pension.tab.table.pension_info.customer_id')}: {otp.customerID}
              <br />
              {t('extra_pension.tab.table.pension_info.scheme')}: {otp.scheme}
            </div>
          )
        }
        return (
          <div>
            {t('extra_pension.tab.table.pension_info.sort_code')}: {otp.sortCode}
            <br />
            {t('extra_pension.tab.table.pension_info.account')}: {otp.account}
            <br />
            {t('extra_pension.tab.table.pension_info.scheme')}: {otp.scheme}
          </div>
        )
      },
    },
    { title: t('extra_pension.tab.table.header.amount'), dataIndex: 'amount', key: 'amount' },
    { title: t('extra_pension.tab.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (oneTimePay: OneTimePayRow) => {
        if (deleting.indexOf(oneTimePay.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip
              title={
                oneTimePay.immutable
                  ? t('extra_pension.tab.table.actions.view')
                  : t('extra_pension.tab.table.actions.edit')
              }
            >
              <span
                onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                style={{ cursor: 'pointer' }}
              >
                <Icon type={oneTimePay.immutable ? 'magnifyingGlass' : 'paperWithPencil'} />
              </span>
            </Tooltip>
            {!oneTimePay.immutable && (
              <Tooltip title={t('extra_pension.tab.table.actions.delete')}>
                <span onClick={remove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="xSign" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getExtraPensions = (): OneTimePayRow[] => {
    return props.oneTimePayPensions.oneTimePayPensions
      .filter((oneTimePay) => (showHistory() ? oneTimePay.oneTimePay.settled : !oneTimePay.oneTimePay.settled))
      .map((oneTimePay) => {
        let title = oneTimePay.oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        let hasCompany = false
        let companyName = t('extra_pension.tab.table.no_company')
        const pensionCompany = props.pensionCompanies.find((company) => company.id === oneTimePay.pensionCompanyID)
        if (pensionCompany) {
          hasCompany = true
          companyName = pensionCompany.name
        }
        return {
          key: oneTimePay.oneTimePay.id,
          id: oneTimePay.oneTimePay.id,
          date: formatDate(oneTimePay.oneTimePay.dispositionDate),
          title,
          amount: formatCurrency(oneTimePay.oneTimePay.amount, 2),
          hasCompany,
          companyName,
          customerID: oneTimePay.pensionCustomerID,
          scheme: formatPensionScheme(oneTimePay.scheme as PensionScheme),
          sortCode: oneTimePay.sortCode,
          account: oneTimePay.account,
          immutable: oneTimePay.oneTimePay.immutable,
          approved: oneTimePay.oneTimePay.approved,
          original: oneTimePay,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getExtraPensions()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original.oneTimePay)
      setApproving(oneTimePays.map((otp) => otp.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const hasUnapprovedExtraPensions = () => {
    return getExtraPensions().some((otp) => !otp.approved)
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (!props.viewingContract || props.employee.activeEmployment.id !== props.viewingContract.employmentID) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.oneTimePayPensions.loaded) {
    return <LoadingOverlay />
  }
  if (!props.salaryCycle) {
    return null
  }

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {!showHistory() && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }} prefixIcon="user">
            {t('extra_pension.tab.header.add_new')}
          </Button>
        )}
        {showHistory() ? (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/extra-pension'}>
            <Button className="gtm-hide-extra-pension-history" style={{ paddingRight: 14 }}>
              {t('extra_pension.tab.header.hide_history')}
            </Button>
          </Link>
        ) : (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/extra-pension/history'}>
            <Button className="gtm-show-extra-pension-history" style={{ paddingRight: 14 }}>
              {t('extra_pension.tab.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedExtraPensions() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('extra_pension.tab.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>{showHistory() ? t('extra_pension.tab.title.history') : t('extra_pension.tab.title.standard')}</Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getExtraPensions()} pagination={false} />
      {props.oneTimePayPensions.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <ExtraPensionEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          company={props.company}
          employee={props.employee}
          oneTimePayID={typeof editing === 'string' ? editing : undefined}
          oneTimePayPensions={props.oneTimePayPensions}
          pensionCompanies={props.pensionCompanies}
          salaryCycle={props.salaryCycle}
          viewingContract={props.viewingContract}
          addOneTimePayPension={props.addOneTimePayPension}
          updateOneTimePayPension={props.updateOneTimePayPension}
        />
      </Modal>
    </Card>
  )
}
