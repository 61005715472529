import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import PaySlip from '../../model/paySlip'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Table from '../elements/table'

type Props = {
  paySlips: List<PaySlip>
  employees: List<Employee>
}

export default function PaySlipTable(props: Props): ReactElement | null {
  const columns = [
    { title: t('payroll_approve.pay_slips.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('payroll_approve.pay_slips.table.header.gross_pay'), dataIndex: 'grossPay', key: 'grossPay', width: 90 },
    { title: t('payroll_approve.pay_slips.table.header.net_pay'), dataIndex: 'netPay', key: 'netPay', width: 90 },
  ]

  type PaySlipRow = {
    key: string
    id: string
    grossPay: string
    netPay: string
    name: string
    employeeID?: string
    profileImageURL?: string
    isFreelancer: boolean
  }

  const getPaySlips = (): PaySlipRow[] => {
    return props.paySlips
      .map((paySlip) => {
        const combinedPaySlip: PaySlipRow = {
          key: paySlip.id,
          id: paySlip.id,
          grossPay: '-',
          netPay: formatCurrency(paySlip.payCheck, 2),
          name: '-',
          isFreelancer: false,
        }
        const employeeNumber = paySlip.fields.find((field) => field.name === 'EmployeeNumber')
        if (employeeNumber) {
          const employee = props.employees.find(
            (employee) =>
              !!employee.activeEmployment && employee.activeEmployment.employeeNumber === employeeNumber.serializedValue
          )
          if (employee) {
            combinedPaySlip.employeeID = employee.id
            combinedPaySlip.name = employee.name || employee.email || '-'
            combinedPaySlip.profileImageURL = employee.profileImageURL
            combinedPaySlip.isFreelancer = employee.affiliationType === 'Freelancer'
          }
        }
        const grossPay = paySlip.calculations.find((calc) => calc.type === 'Salary')
        if (grossPay) {
          combinedPaySlip.grossPay = formatCurrency(grossPay.result, 2)
        }
        return combinedPaySlip
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .toArray()
  }

  return <Table columns={columns} dataSource={getPaySlips()} pagination={false} className={'pay-slip-list'} />
}
