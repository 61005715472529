import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { formatDate } from '../../../utils/date-utils'
import { formatCurrency, formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import TravelAllowanceEdit from './TravelAllowanceEdit'

type Props = {
  subsection?: string
  employee: Employee
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  costCenters: List<CostCenter>
  costCenterAccounting: CostCenterAccounting

  addAlert: addAlertSignature
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, otp: OneTimePayMutableFields) => void
  deleteOneTimePay: (id: string) => void
}

export default function TravelAllowanceTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)

  const setEditVisibility = (id: string | boolean, mutable = true) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setMutable(mutable)
  }

  const { oneTimePays } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    // Check for save callback
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      // Check for no error occurred
      if (!oneTimePays.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const showHistory = () => {
    return props.subsection === 'history'
  }

  const approve = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePay.id])
      props.approveOneTimePays([oneTimePay.id])
    }
  }
  const unapprove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePay.id))
      props.unapproveOneTimePays([oneTimePay.id])
    }
  }
  const remove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePay.id])
        props.deleteOneTimePay(oneTimePay.id)
      }
    }
  }

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    amount: string
    rate?: string
    units?: string
    title: string
    immutable: boolean
    approved: boolean
    original: OneTimePay
  }

  const getColumns = () => {
    const columns = [
      {
        title: t('travel_allowance.table.header.approved'),
        key: 'x1',
        className: 'one-time-pay-table-approved',
        render: (oneTimePay: OneTimePayRow): string | ReactElement | null => {
          if (oneTimePay.approved) {
            return (
              <span className="table-approve-wrapper">
                {t('travel_allowance.table.approved.true')}
                {!oneTimePay.immutable && props.canApproveObjects && (
                  <Tooltip title={t('travel_allowance.table.approved.remove')}>
                    <span onClick={unapprove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                      <Icon type="xSign" />
                    </span>
                  </Tooltip>
                )}
              </span>
            )
          }
          if (approving.indexOf(oneTimePay.id) !== -1) {
            return t('travel_allowance.table.saving')
          }
          if (!props.canApproveObjects) {
            return t('travel_allowance.table.approved.false')
          }
          return (
            <DumbLink onClick={approve(oneTimePay.original)} type="standard">
              {t('travel_allowance.table.approved.approve')}
            </DumbLink>
          )
        },
      },
      { title: t('travel_allowance.table.header.date'), dataIndex: 'date', key: 'date' },
      { title: t('travel_allowance.table.header.title'), dataIndex: 'title', key: 'title' },
      {
        title: t('travel_allowance.table.header.amount'),
        key: 'xAmount',
        render: (oneTimePay: OneTimePayRow) => {
          if (!oneTimePay.rate) {
            return oneTimePay.amount
          }
          return (
            <>
              {oneTimePay.amount}
              <br />
              <small>
                ({t('travel_allowance.table.amount.format', { units: oneTimePay.units, rate: oneTimePay.rate })})
              </small>
            </>
          )
        },
      },
    ]
    if (!showHistory()) {
      columns.push({
        title: '',
        key: 'x2',
        className: 'employee-table-actions',
        render: (oneTimePay: OneTimePayRow) => {
          if (deleting.indexOf(oneTimePay.id) !== -1) {
            return null
          }
          if (!props.canEditObjects) {
            return null
          }
          return (
            <div>
              <Tooltip
                title={
                  oneTimePay.immutable
                    ? t('travel_allowance.table.actions.view')
                    : t('travel_allowance.table.actions.edit')
                }
              >
                <span
                  onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon type={oneTimePay.immutable ? 'magnifyingGlass' : 'paperWithPencil'} />
                </span>
              </Tooltip>
              {!oneTimePay.immutable && (
                <Tooltip title={t('travel_allowance.table.actions.delete')}>
                  <span onClick={remove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="xSign" />
                  </span>
                </Tooltip>
              )}
            </div>
          )
        },
      })
    }
    return columns
  }
  const getTravelAllowances = (): OneTimePayRow[] => {
    return props.oneTimePays.oneTimePays
      .filter((oneTimePay) => oneTimePay.type === 'Travel Allowance')
      .filter((oneTimePay) => (showHistory() ? oneTimePay.settled : !oneTimePay.settled))
      .map((oneTimePay) => {
        let title = oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        return {
          key: oneTimePay.id,
          id: oneTimePay.id,
          date: formatDate(oneTimePay.dispositionDate),
          amount: formatCurrency(oneTimePay.amount, 2),
          rate: oneTimePay.rate ? formatCurrency(oneTimePay.rate, 2) : undefined,
          units: oneTimePay.units ? formatDisplayNumber(oneTimePay.units) : undefined,
          title,
          immutable: oneTimePay.immutable,
          approved: oneTimePay.approved,
          original: oneTimePay,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getTravelAllowances()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(oneTimePays.map((otp) => otp.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const hasUnapprovedTravelAllowances = () => {
    return getTravelAllowances().some((otp) => !otp.approved)
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {!showHistory() && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }} prefixIcon="user">
            {t('travel_allowance.header.add_travel_allowance')}
          </Button>
        )}
        {showHistory() ? (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/travel-allowance'}>
            <Button className="gtm-hide-travel-allowance-history" style={{ paddingRight: 14 }}>
              {t('travel_allowance.header.hide_history')}
            </Button>
          </Link>
        ) : (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/travel-allowance/history'}>
            <Button className="gtm-show-travel-allowance-history" style={{ paddingRight: 14 }}>
              {t('travel_allowance.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedTravelAllowances() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('travel_allowance.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>
        {showHistory() ? t('travel_allowance.header.title.history') : t('travel_allowance.header.title.standard')}
      </Title>
      <p>&nbsp;</p>

      <Table columns={getColumns()} dataSource={getTravelAllowances()} pagination={false} />
      {props.oneTimePays.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <TravelAllowanceEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          employee={props.employee}
          company={props.company}
          oneTimePayID={typeof editing === 'string' ? editing : undefined}
          oneTimePays={props.oneTimePays}
          salaryCycle={props.salaryCycle}
          costCenters={props.costCenters}
          costCenterAccounting={props.costCenterAccounting}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
    </Card>
  )
}
