import React, { ReactElement } from 'react'

import { BenefitType } from '../../model/remuneration'
import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

type BenefitFixedDisbursementFields = {
  increases: {
    title: string
    type: BenefitType
    increase: string
  }[]
}

type Props<Fields extends BenefitFixedDisbursementFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
}

export default function BenefitFixedDisbursementForm<Fields extends BenefitFixedDisbursementFields>(
  props: Props<Fields>
): ReactElement | null {
  const add = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    const { getFieldValue, setAnyFieldValue } = props
    const increases = getFieldValue('increases')
    setAnyFieldValue(`increases.${increases.length}`, {
      type: 'Fixed Disbursement',
    })
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue } = props
    const increases = getFieldValue('increases')
    increases.splice(i, 1)
    setFieldValue('increases', increases)
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  return (
    <div>
      <Row style={{ marginTop: '15px', marginBottom: '20px' }}>
        <Col span={24}>
          <Headline>{t('benefit.form.fixed_disbursement.intro.title')}</Headline>
        </Col>
      </Row>
      <Subcard>
        <Row style={{ marginBottom: '-10px' }}>
          <Col span={16}>
            <label>{t('benefit.form.fixed_disbursement.header.title')}</label>
          </Col>
          <Col span={8}>
            <label>{t('benefit.form.fixed_disbursement.header.increase')}</label>
          </Col>
        </Row>
        {getFieldValue('increases').map((benefit, i) => {
          return (
            <Form.Item
              key={i}
              validateStatus={
                getAnyFieldError(`increases.${i}.title`) || getAnyFieldError(`increases.${i}.increase`)
                  ? 'error'
                  : 'success'
              }
            >
              <Row>
                <Col span={16}>
                  {decorateAnyField(`increases.${i}.title`, {
                    placeholder: t('benefit.form.fixed_disbursement.title'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.fixed_disbursement.title.required') : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={7}>
                  {decorateAnyField(`increases.${i}.increase`, {
                    placeholder: t('benefit.form.fixed_disbursement.increase'),
                    suffix: t('benefit.form.fixed_disbursement.increase.suffix'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.fixed_disbursement.increase.required') : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={1} className="contracts-remove-row">
                  <span onClick={() => remove(i)}>
                    <Icon type="xSign" />
                  </span>
                </Col>
              </Row>
            </Form.Item>
          )
        })}
        <span className="contracts-add-row" onClick={add}>
          <Icon type="plusCircle" />{' '}
          {getFieldValue('increases').length > 0
            ? t('benefit.form.fixed_disbursement.add_row.not_empty')
            : t('benefit.form.fixed_disbursement.add_row.empty')}
        </span>
      </Subcard>
    </div>
  )
}
