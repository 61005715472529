import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveAdjustment, {
  LeaveAdjustmentCreationFields,
  LeaveAdjustmentMutableFields,
} from '../../../model/leaveAdjustment'
import LeaveType, { LeaveUnit } from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import SupplementAdjustment, {
  SupplementAdjustmentCreationFields,
  SupplementAdjustmentMutableFields,
} from '../../../model/supplementAdjustment'
import SupplementType from '../../../model/supplementType'
import { AdjustmentOperation } from '../../../model/types'
import { LeaveAdjustmentReducer } from '../../../reducers/leaveAdjustments'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { SupplementAdjustmentReducer } from '../../../reducers/supplementAdjustments'
import { SupplementBalanceReducer } from '../../../reducers/supplementBalances'
import { formatDate } from '../../../utils/date-utils'
import { isFreeChoiceSupplement, isSHSupplement } from '../../../utils/employee-contract-utils'
import { formatLeaveTypeName, formatLeaveUnit, formatSupplementTypeName } from '../../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import LeaveAdjustmentEdit from './LeaveAdjustmentEdit'
import SupplementAdjustmentEdit from './SupplementAdjustmentEdit'

import './BalanceAdjustments.css'

type Props = {
  employee: Employee
  leaveAdjustments: LeaveAdjustmentReducer
  leaveBalances: LeaveBalanceReducer
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: SupplementBalanceReducer
  salaryCycle?: SalaryCycle
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  salaryTypes: List<SalaryType>
  viewingContract?: Contract

  addAlert: addAlertSignature
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
  getSupplementBalances: (companyID?: string, employeeID?: string) => void
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  updateLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentMutableFields) => void
  deleteLeaveAdjustment: (id: string) => void
  addSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentCreationFields) => void
  updateSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentMutableFields) => void
  deleteSupplementAdjustment: (id: string) => void
}

export default function BalanceAdjustmentsTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editingLeave, setEditingLeave] = useState<string | boolean>(false)
  const [editingSupplement, setEditingSupplement] = useState<string | boolean>(false)

  useEffect(() => {
    const employeeID = props.employee.id
    if (
      props.leaveBalances.employeeID !== employeeID ||
      (!props.leaveBalances.loading && !props.leaveBalances.loaded)
    ) {
      props.getLeaveBalances(undefined, employeeID)
    }
    if (
      props.supplementBalances.employeeID !== employeeID ||
      (!props.supplementBalances.loading && !props.supplementBalances.loaded)
    ) {
      props.getSupplementBalances(undefined, employeeID)
    }
  })

  const setEditLeaveVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditingLeave(id)
  }
  const setEditSupplementVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditingSupplement(id)
  }

  const { leaveAdjustments, supplementAdjustments } = props
  const previousLeaveAdjustments = usePrevious(leaveAdjustments)
  const previousSupplementAdjustments = usePrevious(supplementAdjustments)

  useEffect(() => {
    // Check for save callback
    if (previousLeaveAdjustments && previousLeaveAdjustments.saving && !leaveAdjustments.saving) {
      // Check for no error occurred
      if (!leaveAdjustments.error) {
        // Close edit modal
        setEditLeaveVisibility(false)
      }
    }
    // Check for save callback
    if (previousSupplementAdjustments && previousSupplementAdjustments.saving && !supplementAdjustments.saving) {
      // Check for no error occurred
      if (!supplementAdjustments.error) {
        // Close edit modal
        setEditSupplementVisibility(false)
      }
    }
  })

  const removeLeaveAdjustment = (leaveAdjustment: LeaveAdjustment) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, leaveAdjustment.id])
        props.deleteLeaveAdjustment(leaveAdjustment.id)
      }
    }
  }

  const removeSupplementAdjustment = (supplementAdjustment: SupplementAdjustment) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, supplementAdjustment.id])
        props.deleteSupplementAdjustment(supplementAdjustment.id)
      }
    }
  }

  const getLeaveBalances = () => {
    return props.leaveBalances.leaveBalances.filter(
      (balance) =>
        balance.registered !== 0 ||
        balance.used !== 0 ||
        balance.left !== 0 ||
        props.viewingContract?.remuneration?.leave.some((def) => def.typeID === balance.leaveTypeID)
    )
  }

  const getSupplementBalances = () => {
    return props.supplementBalances.supplementBalances.filter(
      (balance) =>
        balance.earned !== 0 ||
        props.viewingContract?.remuneration?.supplements.some(
          (def) =>
            def.type &&
            (def.type.name === balance.supplementTypeName ||
              (isSHSupplement(balance.supplementTypeName) && isSHSupplement(def.type.name)) ||
              (isFreeChoiceSupplement(balance.supplementTypeName) && isFreeChoiceSupplement(def.type.name)))
        )
    )
  }

  const hasSupplements = () => {
    return props.supplementTypes.some(
      (supplementType) =>
        props.viewingContract?.remuneration?.supplements.some(
          (supplement) => supplement.typeID === supplementType.id
        ) ||
        getSupplementBalances().some(
          (balance) =>
            balance.supplementTypeName === supplementType.name ||
            (isSHSupplement(balance.supplementTypeName) && isSHSupplement(supplementType.name)) ||
            (isFreeChoiceSupplement(balance.supplementTypeName) && isFreeChoiceSupplement(supplementType.name))
        )
    )
  }

  const formatLeaveSupplementBalance = (leaveUnit: LeaveUnit | 'DKK' | undefined, amount: number) => {
    if (leaveUnit === undefined) {
      return formatNumber(amount, 2)
    }
    if (leaveUnit === 'DKK') {
      return formatCurrency(amount)
    }
    return formatNumber(amount, 2) + ' ' + formatLeaveUnit(leaveUnit, amount !== 1.0)
  }

  type BalanceAdjustmentRow = {
    key: string
    id: string
    operation: AdjustmentOperation
    date: string
    leaveTypeName?: string
    typeName: string
    immutable: boolean
    earned?: number
    complimentary?: number
    used?: number
    writtenOff?: number
    valuation?: number
    paidOut?: number
    liability?: number
    leaveAdjustment?: LeaveAdjustment
    supplementAdjustment?: SupplementAdjustment
  }

  const columns = [
    { title: t('balance_adjustment_tab.table.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('balance_adjustment_tab.table.header.type_name'), dataIndex: 'typeName', key: 'typeName' },
    {
      title: t('balance_adjustment_tab.table.header.adjustment'),
      key: 'x2',
      render: (balanceAdjustment: BalanceAdjustmentRow) => {
        let modify = '+'
        let text = t('balance_adjustment_tab.table.operation.increase')
        switch (balanceAdjustment.operation) {
          case 'Reduce':
            modify = '-'
            text = t('balance_adjustment_tab.table.operation.reduce')
            break
          case 'Override':
            modify = ''
            text = t('balance_adjustment_tab.table.operation.override')
            break
          default:
            break // do nothing
        }
        let div = 1
        let leaveUnit: LeaveUnit | 'DKK' | undefined
        if (balanceAdjustment.leaveAdjustment) {
          leaveUnit = 'Hours'
          const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === balanceAdjustment.leaveTypeName)
          leaveUnit = leaveType?.unit || 'Hours'
          if (leaveUnit === 'Minutes') {
            // minutes are rendered as hours
            leaveUnit = 'Hours'
            div = 60
          }
        } else if (balanceAdjustment.supplementAdjustment) {
          leaveUnit = 'DKK'
        }
        return (
          <div className="balance-adjustment-values">
            {text}:<br />
            {balanceAdjustment.earned !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.earned')}: {modify}
                {formatLeaveSupplementBalance(leaveUnit, balanceAdjustment.earned / div)}
              </span>
            )}
            {balanceAdjustment.complimentary !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.complementary')}: {modify}
                {formatLeaveSupplementBalance(leaveUnit, balanceAdjustment.complimentary / div)}
              </span>
            )}
            {balanceAdjustment.used !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.used')}: {modify}
                {formatLeaveSupplementBalance(leaveUnit, balanceAdjustment.used / div)}
              </span>
            )}
            {balanceAdjustment.writtenOff !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.written_off')}: {modify}
                {formatNumber(balanceAdjustment.writtenOff / div, 2)}{' '}
                {formatLeaveSupplementBalance(leaveUnit, balanceAdjustment.writtenOff / div)}
              </span>
            )}
            {balanceAdjustment.valuation !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.valuation')}: {modify}
                {formatCurrency(balanceAdjustment.valuation, 2)}
              </span>
            )}
            {balanceAdjustment.paidOut !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.paid_out')}: {modify}
                {formatCurrency(balanceAdjustment.paidOut, 2)}
              </span>
            )}
            {balanceAdjustment.liability !== undefined && (
              <span>
                {t('balance_adjustment_tab.table.type.liability')}: {modify}
                {formatCurrency(balanceAdjustment.liability, 2)}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (balanceAdjustment: BalanceAdjustmentRow) => {
        if (deleting.indexOf(balanceAdjustment.id) !== -1) {
          return null
        }
        if (balanceAdjustment.immutable) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('balance_adjustment_tab.table.actions.edit')}>
              <span onClick={() => setEditLeaveVisibility(balanceAdjustment.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            {balanceAdjustment.leaveAdjustment && (
              <Tooltip title={t('balance_adjustment_tab.table.actions.delete')}>
                <span onClick={removeLeaveAdjustment(balanceAdjustment.leaveAdjustment)} style={{ cursor: 'pointer' }}>
                  <Icon type="xSign" />
                </span>
              </Tooltip>
            )}
            {balanceAdjustment.supplementAdjustment && (
              <Tooltip title={t('balance_adjustment_tab.table.actions.delete')}>
                <span
                  onClick={removeSupplementAdjustment(balanceAdjustment.supplementAdjustment)}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon type="xSign" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getLeaveAdjustments = (): BalanceAdjustmentRow[] => {
    return props.leaveAdjustments.leaveAdjustments
      .sort((a, b) => b.dispositionDate.localeCompare(a.dispositionDate))
      .map((leaveAdjustment) => {
        return {
          key: leaveAdjustment.id,
          id: leaveAdjustment.id,
          date: formatDate(leaveAdjustment.dispositionDate),
          leaveTypeName: leaveAdjustment.leaveTypeName,
          typeName: formatLeaveTypeName(leaveAdjustment.leaveTypeName, false, props.viewingContract?.remunerationType),
          operation: leaveAdjustment.operation,
          immutable: leaveAdjustment.immutable,
          earned: leaveAdjustment.earned,
          complimentary: leaveAdjustment.complimentary,
          used: leaveAdjustment.used,
          writtenOff: leaveAdjustment.writtenOff,
          valuation: leaveAdjustment.valuation,
          paidOut: leaveAdjustment.paidOut,
          liability: leaveAdjustment.liability,
          leaveAdjustment,
        }
      })
      .toArray()
  }

  const getSupplementAdjustments = (): BalanceAdjustmentRow[] => {
    return props.supplementAdjustments.supplementAdjustments
      .sort((a, b) => b.dispositionDate.localeCompare(a.dispositionDate))
      .map((supplementAdjustment) => {
        return {
          key: supplementAdjustment.id,
          id: supplementAdjustment.id,
          date: formatDate(supplementAdjustment.dispositionDate),
          typeName: formatSupplementTypeName(supplementAdjustment.supplementTypeName),
          operation: supplementAdjustment.operation,
          immutable: supplementAdjustment.immutable,
          earned: supplementAdjustment.earned,
          writtenOff: supplementAdjustment.writtenOff,
          paidOut: supplementAdjustment.paidOut,
          supplementAdjustment,
        }
      })
      .toArray()
  }

  if (!props.leaveBalances.loaded || !props.supplementBalances.loaded) {
    return <LoadingOverlay />
  }

  if (!props.viewingContract || !props.viewingContract.remuneration || !props.salaryCycle) {
    return null
  }

  return (
    <div className="employees-single-form">
      <Card>
        <TitleMenu>
          <Button onClick={() => setEditLeaveVisibility(true)} style={{ marginRight: 20 }} prefixIcon="user">
            {t('balance_adjustment_tab.leave.header.new_adjustment')}
          </Button>
        </TitleMenu>
        <Title>{t('balance_adjustment_tab.leave.header.title')}</Title>

        {getLeaveBalances().size > 0 && (
          <Row>
            <Col span={24}>{t('balance_adjustment_tab.leave.header.balances')}:</Col>
            {getLeaveBalances()
              .map((balance) => {
                const leaveType = props.leaveTypes.find((type) => type.id === balance.leaveTypeID)
                if (!leaveType) {
                  return null
                }
                let bal = balance.left
                if (bal === 0) {
                  bal = 0 - balance.registered - balance.used
                }
                let div = 1
                if (leaveType.unit === 'Minutes') {
                  div = 60
                }
                return (
                  <Col span={6} key={leaveType.id}>
                    {formatLeaveTypeName(leaveType.name)}: {formatNumber(bal / div, 2)}
                  </Col>
                )
              })
              .filter((item) => !!item)}
          </Row>
        )}
        <p>&nbsp;</p>

        <Table columns={columns} dataSource={getLeaveAdjustments()} pagination={false} />
      </Card>
      {props.leaveAdjustments.saving && <LoadingOverlay />}

      <Card>
        <TitleMenu>
          {hasSupplements() && (
            <Button onClick={() => setEditSupplementVisibility(true)} style={{ marginRight: 20 }} prefixIcon="user">
              {t('balance_adjustment_tab.supplements.header.add_adjustment')}
            </Button>
          )}
        </TitleMenu>
        <Title>{t('balance_adjustment_tab.supplements.header.title')}</Title>

        {getSupplementBalances().size > 0 && (
          <Row>
            <Col span={24}>{t('balance_adjustment_tab.supplements.header.balances')}:</Col>
            {getSupplementBalances()
              .map((balance) => {
                const supplementType = props.supplementTypes.find((type) => type.name === balance.supplementTypeName)
                if (!supplementType) {
                  return null
                }
                return (
                  <Col span={6} key={supplementType.id}>
                    {formatSupplementTypeName(supplementType.name)}: {formatNumber(balance.left, 2)}
                  </Col>
                )
              })
              .filter((item) => !!item)}
          </Row>
        )}
        <p>&nbsp;</p>

        <Table columns={columns} dataSource={getSupplementAdjustments()} pagination={false} />
      </Card>
      {props.supplementAdjustments.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editingLeave !== false}
        onOk={() => setEditLeaveVisibility(false)}
        onCancel={() => setEditLeaveVisibility(false)}
        width={582}
        footer={null}
      >
        <LeaveAdjustmentEdit
          visible={editingLeave !== false}
          employee={props.employee}
          leaveAdjustmentID={typeof editingLeave === 'string' ? editingLeave : undefined}
          leaveAdjustments={props.leaveAdjustments}
          leaveBalances={props.leaveBalances.leaveBalances}
          salaryCycle={props.salaryCycle}
          leaveTypes={props.leaveTypes}
          salaryTypes={props.salaryTypes}
          remuneration={props.viewingContract.remuneration}
          remunerationType={props.viewingContract.remunerationType}
          addLeaveAdjustment={props.addLeaveAdjustment}
          updateLeaveAdjustment={props.updateLeaveAdjustment}
        />
      </Modal>

      <Modal
        key={modalKey + 1}
        visible={editingSupplement !== false}
        onOk={() => setEditSupplementVisibility(false)}
        onCancel={() => setEditSupplementVisibility(false)}
        width={582}
        footer={null}
      >
        <SupplementAdjustmentEdit
          visible={editingSupplement !== false}
          employee={props.employee}
          supplementAdjustmentID={typeof editingSupplement === 'string' ? editingSupplement : undefined}
          supplementAdjustments={props.supplementAdjustments}
          salaryCycle={props.salaryCycle}
          supplementBalances={getSupplementBalances()}
          supplementTypes={props.supplementTypes}
          remuneration={props.viewingContract.remuneration}
          addSupplementAdjustment={props.addSupplementAdjustment}
          updateSupplementAdjustment={props.updateSupplementAdjustment}
        />
      </Modal>
    </div>
  )
}
