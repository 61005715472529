import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import PaySlip from '../../model/paySlip'
import TimeRegistration from '../../model/timeRegistration'
import { EmployeePayType, getEmployeePayType } from '../../utils/employee-utils'
import { formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Subtitle from '../elements/Subtitle'
import Table from '../elements/table'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import PayrollCardToggle from './PayrollCardToggle'

type Props = {
  payRoll: PayRoll
  employeesMap: Record<string, Employee>
  timeRegistrations: List<TimeRegistration>
  paySlips: List<PaySlip>
}

export default function TimeRegistrationsCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)

  type TimeRegistrationRow = {
    key: string
    id: string
    profileImageURL?: string
    name: string
    employeeID: string
    workHours: string
    workDays: string
    payType: EmployeePayType
  }

  const columns = [
    {
      title: t('pay_roll.single.time_registrations.header.name'),
      dataIndex: '',
      key: 'xName',
      render: (timeRegistration: TimeRegistrationRow) => (
        <span>
          <UserImage src={timeRegistration.profileImageURL} name={timeRegistration.name} />
          {timeRegistration.name}
        </span>
      ),
    },
    { title: t('pay_roll.single.time_registrations.header.work_hours'), dataIndex: 'workHours', key: 'workHours' },
    { title: t('pay_roll.single.time_registrations.header.work_days'), dataIndex: 'workDays', key: 'workDays' },
    {
      title: '',
      dataIndex: '',
      key: 'x4',
      className: 'pay-roll-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => (
        <Link to={'/' + paths.EMPLOYEES + '/' + timeRegistration.employeeID + '/time-registration'}>
          <Tooltip placement="top" title={t('pay_roll.single.time_registrations.actions.edit')}>
            <span>
              <Icon type="paperWithPencil" />
            </span>
          </Tooltip>
        </Link>
      ),
    },
  ]

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    return props.paySlips
      .reduce((list: TimeRegistrationRow[], paySlip) => {
        const employee = props.employeesMap[paySlip.employeeID]
        if (!employee) {
          return list
        }
        const workDays = paySlip.fields.find((field) => field.name === 'Days')?.serializedValue
        const workHours = paySlip.fields.find((field) => field.name === 'Hours')?.serializedValue
        list.push({
          key: paySlip.id,
          id: paySlip.id,
          employeeID: employee.id,
          name: employee.name || employee.email || '-',
          profileImageURL: employee.profileImageURL,
          payType: getEmployeePayType(employee.earliestMutableContract),
          workDays: workDays ? formatNumber(parseFloat(workDays)) : '-',
          workHours: workHours ? formatNumber(parseFloat(workHours), 2) : '-',
        })
        return list
      }, [])
      .filter((timeRegistration) => timeRegistration.payType === 'Hourly Paid')
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  if (props.payRoll.totalHours === 0) {
    return null
  }

  const timeRegistrations = getTimeRegistrations()
  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.time_registrations.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {props.payRoll.totalHours > 0 && (
            <span>
              {tx('pay_roll.single.time_registrations.total', {
                count: props.payRoll.totalHours,
                total: <strong>{formatNumber(props.payRoll.totalHours, 2)}</strong>,
              })}
            </span>
          )}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <PayrollCardToggle expanded={expanded} onChange={(newState) => setExpanded(newState)} />
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        <Table
          columns={columns}
          dataSource={timeRegistrations}
          pagination={timeRegistrations.length > 100 ? { defaultPageSize: 100 } : false}
          className={'pay-roll-table'}
        />
      </div>
    </Card>
  )
}
