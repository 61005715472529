import RcTable from 'rc-table'
import React, { CSSProperties, ReactElement, ReactNode, useState } from 'react'

import { getTranslationAntdStrings } from '../../../utils/language-utils'
import classNames from '../../antd/_util/classNames'
import Icon from '../icon'
import Pagination from '../pagination'
import Spin from '../spin'
import { flatFilter, treeMap } from './util'

const defaultLocale = {
  filterTitle: 'Filter menu',
  filterConfirm: 'OK',
  filterReset: 'Reset',
  emptyText: 'No data',
  selectAll: 'Select current page',
  selectInvert: 'Invert current page',
}

type SortOrder = 'ascend' | 'descend'

export type TableColumn<DataType> = {
  key: string
  dataIndex?: string
  sortOrder?: SortOrder
  sorter?: string
  className?: string
  title?: ReactNode
  render?: (input: DataType) => ReactNode
}

type TablePagination = {
  defaultCurrent?: number
  current?: number
  defaultPageSize?: number
  pageSize?: number
  size?: 'small'
  total?: number
  className?: string
  onChange?: (current: number) => void
  onShowSizeChange?: (current: number, pageSize: number) => void
}

type State<DataType> = {
  sortColumn?: TableColumn<DataType>
  sortOrder?: SortOrder
  pagination: TablePagination
}

type TableSorter<DataType> = {
  column?: TableColumn<DataType>
  order?: SortOrder
  field?: string
  columnKey?: string
}

export type TableChange<DataType> = {
  pagination: TablePagination
  sorter: TableSorter<DataType>
}

type Props<DataType> = {
  columns: TableColumn<DataType>[]
  dataSource: DataType[]
  prefixCls?: string
  dropdownPrefixCls?: string
  useFixedHeader?: boolean
  className?: string
  rowClassName?: string | ((row: DataType) => string)
  loading?: boolean
  onChange?: (changeValue: TableChange<DataType>) => void
  onRowClick?: (row: DataType) => void
  pagination?: TablePagination | false
  showHeader?: boolean
  style?: CSSProperties
  indentSize?: number
  rowKey?: string
  footer?: () => ReactNode
  childrenColumnName?: string
  children?: ReactNode
}

export default function Table<DataType extends Record<string, unknown>>(props: Props<DataType>): ReactElement | null {
  const getSortOrderColumns = () => flatFilter(props.columns, (column) => 'sortOrder' in column)
  const getSortStateFromColumns = (): {
    sortColumn?: TableColumn<DataType>
    sortOrder?: SortOrder
  } => {
    // return first column which sortOrder is not falsy
    const sortedColumn = getSortOrderColumns().filter((col) => col.sortOrder)[0]
    if (sortedColumn) {
      return {
        sortColumn: sortedColumn,
        sortOrder: sortedColumn.sortOrder,
      }
    }
    return {
      sortColumn: undefined,
      sortOrder: undefined,
    }
  }

  const hasPagination = () => {
    return props.pagination !== false
  }
  const getDefaultPagination = () => {
    const pagination = props.pagination || {}
    return hasPagination()
      ? {
          ...{
            onChange: undefined,
            onShowSizeChange: undefined,
          },
          ...pagination,
          current: pagination.defaultCurrent ?? pagination.current ?? 1,
          pageSize: pagination.defaultPageSize ?? pagination.pageSize ?? 10,
        }
      : {}
  }
  const [state, setState] = useState<State<DataType>>(() => {
    return {
      ...getSortStateFromColumns(),
      pagination: getDefaultPagination(),
      prevProps: props,
    }
  })

  const getLocale = () => {
    const locale = getTranslationAntdStrings().Table
    return {
      ...defaultLocale,
      ...locale,
    }
  }

  const getColumnKey = (column: TableColumn<DataType>) => {
    return column.key
  }

  const isSortColumn = (column: TableColumn<DataType>) => {
    const { sortColumn } = state
    if (!column || !sortColumn) {
      return false
    }
    return getColumnKey(sortColumn) === getColumnKey(column)
  }

  // Get pagination, filters, sorter
  const prepareParamsArguments = (state: State<DataType>): TableChange<DataType> => {
    const pagination = { ...state.pagination }
    // remove useless handle function in Table.onChange
    delete pagination.onChange
    delete pagination.onShowSizeChange
    const sorter: TableSorter<DataType> = {}
    if (state.sortColumn && state.sortOrder) {
      sorter.column = state.sortColumn
      sorter.order = state.sortOrder
      sorter.field = state.sortColumn.dataIndex
      sorter.columnKey = getColumnKey(state.sortColumn)
    }
    return {
      pagination,
      sorter,
    }
  }

  const toggleSortOrder = (order: SortOrder, column: TableColumn<DataType>) => {
    let { sortColumn, sortOrder } = state
    const isSort = isSortColumn(column)
    if (!isSort) {
      sortOrder = order
      sortColumn = column
    } else {
      if (sortOrder === order) {
        sortOrder = undefined
        sortColumn = undefined
      } else {
        sortOrder = order
      }
    }
    const newState = {
      sortOrder,
      sortColumn,
    }

    // Controlled
    if (getSortOrderColumns().length === 0) {
      setState((prev) => ({ ...prev, ...newState }))
    }

    const onChange = props.onChange
    if (onChange) {
      onChange(
        prepareParamsArguments({
          ...state,
          ...newState,
        })
      )
    }
  }

  const handlePageChange = (current: number) => {
    const pagination = { ...state.pagination }
    if (current) {
      pagination.current = current
    } else {
      pagination.current = pagination.current ?? 1
    }
    if (pagination.onChange) {
      pagination.onChange(pagination.current)
    }

    const newState = {
      pagination,
    }
    // Controlled current prop will not respond user interaction
    if (props.pagination !== false && props.pagination?.current) {
      newState.pagination = {
        ...pagination,
        current: state.pagination?.current,
      }
    }
    setState(newState)

    const onChange = props.onChange
    if (onChange) {
      onChange(
        prepareParamsArguments({
          ...state,
          pagination,
        })
      )
    }
  }

  const getMaxCurrent = (total: number) => {
    const { current = 0, pageSize = 0 } = state.pagination
    if ((current - 1) * pageSize >= total) {
      return Math.floor((total - 1) / pageSize) + 1
    }
    return current
  }

  const { prefixCls = 'ant-table' } = props

  const renderColumnsDropdown = (columns: TableColumn<DataType>[]): TableColumn<DataType>[] => {
    const { sortOrder } = state
    return treeMap(columns, (originColumn: TableColumn<DataType>) => {
      const column = { ...originColumn }
      let sortButton
      let title = column.title
      if (column.sorter) {
        const isSort = isSortColumn(column)
        if (isSort) {
          column.className = column.className || ''
          if (sortOrder) {
            column.className += ` ${prefixCls}-column-sort`
          }
        }
        const isAscend = isSort && sortOrder === 'ascend'
        const isDescend = isSort && sortOrder === 'descend'
        title = (
          <span
            className={`${prefixCls}-column-sorter-toggle`}
            onClick={() => toggleSortOrder(isAscend ? 'descend' : 'ascend', column)}
          >
            {title}
          </span>
        )
        sortButton = (
          <div className={`${prefixCls}-column-sorter`}>
            {isAscend && (
              <span
                className={`${prefixCls}-column-sorter-up on`}
                title="↑"
                onClick={() => toggleSortOrder('descend', column)}
              >
                <Icon type="chevronUpSmall" />
              </span>
            )}
            {isDescend && (
              <span
                className={`${prefixCls}-column-sorter-down on`}
                title="↓"
                onClick={() => toggleSortOrder('ascend', column)}
              >
                <Icon type="chevronDownSmall" />
              </span>
            )}
          </div>
        )
      }
      column.title = (
        <span>
          {title}
          {sortButton}
        </span>
      )
      return column
    })
  }

  const handleShowSizeChange = (current: number, pageSize: number) => {
    const pagination = state.pagination
    if (pagination.onShowSizeChange) {
      pagination.onShowSizeChange(current, pageSize)
    }
    const nextPagination = {
      ...pagination,
      pageSize,
      current,
    }
    setState({ pagination: nextPagination })

    const onChange = props.onChange
    if (onChange) {
      onChange(
        prepareParamsArguments({
          ...state,
          pagination: nextPagination,
        })
      )
    }
  }

  const getLocalData = () => {
    const { dataSource } = props
    let data = dataSource || []
    data = data.slice(0)
    return data
  }

  const renderPagination = () => {
    if (!hasPagination()) {
      return null
    }
    const { pagination } = state
    const total = pagination.total || getLocalData().length
    return total > 0 ? (
      <Pagination
        key="pagination"
        {...pagination}
        className={classNames(pagination.className, `${prefixCls}-pagination`)}
        onChange={handlePageChange}
        total={total}
        current={getMaxCurrent(total)}
        onShowSizeChange={handleShowSizeChange}
      />
    ) : null
  }

  const getCurrentPageData = () => {
    let data = getLocalData()
    let current = 1
    let pageSize = Number.MAX_VALUE
    if (hasPagination()) {
      pageSize = state.pagination.pageSize ?? pageSize
      current = getMaxCurrent(state.pagination.total || data.length)
    }

    if (data.length > pageSize || pageSize === Number.MAX_VALUE) {
      data = data.filter((_, i) => {
        return i >= (current - 1) * pageSize && i < current * pageSize
      })
    }
    return data
  }

  const {
    style,
    className,
    loading = false,
    showHeader = true,
    useFixedHeader = false,
    indentSize = 20,
    rowKey = 'key',
    ...restProps
  } = props
  const data = getCurrentPageData()
  const locale = getLocale()

  const classString = classNames({
    [`${prefixCls}-empty`]: !data.length,
    [`${prefixCls}-without-column-header`]: !showHeader,
    [`${prefixCls}-clickable-rows`]: !!props.onRowClick,
  })

  const columns = renderColumnsDropdown(props.columns.concat()).map((column) => {
    const newColumn = { ...column }
    newColumn.key = getColumnKey(newColumn)
    return newColumn
  })

  const table = (
    <RcTable
      key="table"
      {...restProps}
      prefixCls={prefixCls}
      data={data}
      columns={columns}
      showHeader={showHeader}
      className={classString}
      expandIconColumnIndex={0}
      expandIconAsCell={false}
      useFixedHeader={useFixedHeader}
      indentSize={indentSize}
      rowKey={rowKey}
      emptyText={() => locale.emptyText}
    />
  )

  // if there is no pagination or no data,
  // the height of spin should decrease by half of pagination
  const paginationPatchClass =
    hasPagination() && data && data.length !== 0 ? `${prefixCls}-with-pagination` : `${prefixCls}-without-pagination`

  const spin = {
    spinning: loading,
  }

  return (
    <div className={classNames(`${prefixCls}-wrapper`, className)} style={style}>
      <Spin {...spin} className={loading ? `${paginationPatchClass} ${prefixCls}-spin-holder` : ''}>
        {table}
        {renderPagination()}
      </Spin>
    </div>
  )
}
