import React, { ReactElement, useEffect, useState } from 'react'

import PayRoll from '../../model/payRoll'
import { PayRollReducer } from '../../reducers/payRolls'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Props = {
  user: UserReducer
  payRoll: PayRoll
  payRolls: PayRollReducer

  reopenPayRoll: (payRollID: string, version: string, note?: string, notify?: boolean) => Promise<boolean | void>
  closeModal: () => void
}

export default function PayrollApproveReopenModal(props: Props): ReactElement | null {
  const [note, setNote] = useState('')
  const [error, setError] = useState<Error | null>(null)

  const reopen = () => {
    props.reopenPayRoll(props.payRoll.id, props.payRoll.version, note, true).then((success) => {
      if (success) {
        props.closeModal()
      }
    })
  }

  const { payRolls } = props

  useEffect(() => {
    regularComponentDidUpdate(payRolls.error, error, setError)
  }, [payRolls, error])

  return (
    <Card className="modal-form">
      <Row>
        <Col span={24}>
          <p>{t('payroll_approve.reopen.intro')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input.TextArea
            placeholder={t('payroll_approve.reopen.message')}
            rows={3}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Button danger onClick={reopen}>
            {t('payroll_approve.reopen.reopen')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
