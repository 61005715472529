import React, { PropsWithChildren, ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'
import Radio from '../radio'

type Props<PageType extends string> = {
  currentPage: PageType | undefined
  onChange?: (value: PageType) => void
  size?: 'medium' | 'large'
  className?: string
}

export default function TabNavigator<PageType extends string>(
  props: PropsWithChildren<Props<PageType>>
): ReactElement | null {
  const prefixCls = 'ant-radio-navigator'
  const className = classNames(
    {
      [prefixCls]: true,
      [`${prefixCls}-l`]: props.size === 'large',
    },
    props.className
  )
  return (
    <Radio.Group
      value={props.currentPage}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e.target.value as PageType)
        }
      }}
      className={className}
    >
      {props.children}
    </Radio.Group>
  )
}
