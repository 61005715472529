import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import { UserReducer } from '../../reducers/user'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  approvers: List<CompanyUser>
  company: Company
  user: UserReducer

  closeModal: () => void
  addAlert: addAlertSignature
}

type Fields = {
  channel: 'Email' | 'SMS'
  users: { [key: string]: boolean }
}

export type ReviewResult = {
  channel: 'Email' | 'SMS'
  userIDs: string[]
}

const approverFilter = (user: CompanyUser, isSMSChannel: boolean) =>
  (!isSMSChannel &&
    user.permissions.some(
      (permission) => permission.permission === 'Admin' || permission.permission === 'ApprovePayRoll'
    )) ||
  (isSMSChannel && user.permissions.some((permission) => permission.permission === 'ApproveOnly'))

function ReviewPayRoll(props: Props & FormComponentProps<Fields, ReviewResult>): ReactElement | null {
  const { decorateAnyField, getFieldValue, getAnyFieldValue, approvers } = props

  const hasFormError = () => {
    if (getFieldValue('channel') !== 'SMS') {
      return false
    }
    if (
      !approvers.filter((user) => approverFilter(user, true)).some((user) => !!getAnyFieldValue('users.' + user.userID))
    ) {
      return true
    }
    return false
  }

  return (
    <Card className="pay-roll-review">
      <Subtitle>{t('pay_roll.single.review.title')}</Subtitle>
      <p>{t('pay_roll.single.review.intro')}</p>
      {approvers.size > 0 && (
        <Row>
          <Col span={24}>
            <p>{t('pay_roll.single.review.approvers.intro')}</p>
            {getFieldValue('channel') === 'SMS' && <p>{t('pay_roll.single.review.approvers.sms_note')}</p>}
            {hasFormError() && (
              <label className="form-error">{t('pay_roll.single.review.approvers.error.at_least_one')}</label>
            )}
            {approvers
              .filter((user) => approverFilter(user, getFieldValue('channel') === 'SMS'))
              .map((approver: CompanyUser) => {
                return decorateAnyField('users.' + approver.userID, { valueOnChecked: true })(
                  <Checkbox id={'users.' + approver.userID} key={'users.' + approver.userID}>
                    {approver.name}
                  </Checkbox>
                )
              })}
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary" block disabled={hasFormError()}>
            {t('pay_roll.single.review.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, ReviewResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      users: {},
      channel: 'Email',
    }
    if (
      props.approvers.some((user) => user.permissions.some((permission) => permission.permission === 'ApproveOnly'))
    ) {
      fields.channel = 'SMS'
    }
    // only pre-select approvers if there is only one or everyone if SMS
    const approvers = props.approvers.filter((user) => approverFilter(user, fields.channel === 'SMS'))
    const selected = approvers.size < 2 || fields.channel === 'SMS'
    approvers.forEach((approver) => {
      fields.users[approver.userID] = selected
    })
    return fields
  },
  onSubmit: (values) => {
    const userIDs: string[] = Object.keys(values.users).filter((key) => values.users[key])
    return {
      channel: values.channel,
      userIDs,
    }
  },
})(ReviewPayRoll)
