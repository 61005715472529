import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type SlackEventGroup = {
  channelID: string
  departments: string[]
  eventTypes: ('birthdays' | 'leave' | 'anniversaries')[]
}

export type SlackConfiguration = {
  slackToken: string
  languageCode: 'da' | 'en'
  eventGroups: SlackEventGroup[]
}

export function getSlackIntegration(companyID: string): Promise<RequestResponse<SlackConfiguration>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/notifiers/slack'))
}

export function putSlackIntegration(
  companyID: string,
  configuration: SlackConfiguration | null
): Promise<RequestResponse<SlackConfiguration>> {
  return secureRequest('PUT', url('/v2/companies/' + companyID + '/notifiers/slack'), {
    body: configuration,
  })
}
