import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { updateCompany } from '../actions/companies'
import { getCostCenters } from '../actions/cost-centers'
import { getDepartments } from '../actions/departments'
import { getExpenseCategories } from '../actions/expense-categories'
import {
  addReimbursementVoucher,
  approveReimbursementVouchers,
  deleteReimbursementVoucher,
  draftReimbursementVouchers,
  getReimbursementVouchers,
  rebookReimbursementVouchers,
  unapproveReimbursementVouchers,
  updateReimbursementVoucher,
  updateReimbursementVoucherFields,
} from '../actions/reimbursement-vouchers'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../api/reimbursement-vouchers'
import ReimbursementVouchersComponent from '../components/reimbursement-vouchers/ReimbursementVouchers'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Company from '../model/company'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companyUsers: CompanyUserReducer
  user: UserReducer
  companies: CompanyReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  expenseCategories: ExpenseCategoryReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenters: CostCenterReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (company: Company) => void
  getExpenseCategories: () => void
  getCostCenters: () => void
  getDepartments: () => void
  getReimbursementVouchers: () => void
  addReimbursementVoucher: (o: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  rebookReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  deleteReimbursementVoucher: (id: string) => void
}

function ReimbursementVouchers(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { companies, reimbursementVouchers, getReimbursementVouchers } = props
  const company = companies.company

  useEffectOnce(() => {
    if (!company) {
      return
    }
    if (
      reimbursementVouchers.companyID !== company.id ||
      (!reimbursementVouchers.loading && !reimbursementVouchers.loaded)
    ) {
      getReimbursementVouchers()
    }
  })

  const { expenseCategories, getExpenseCategories, costCenters, getCostCenters, departments, getDepartments } = props

  useEffectOnce(() => {
    if (!company) {
      return
    }
    if (expenseCategories.companyID !== company.id || (!expenseCategories.loading && !expenseCategories.loaded)) {
      getExpenseCategories()
    }
    if (costCenters.companyID !== company.id || (!costCenters.loading && !costCenters.loaded)) {
      getCostCenters()
    }
    if (departments.companyID !== company.id || (!departments.loading && !departments.loaded)) {
      getDepartments()
    }
  })

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = !reimbursementVouchers.loaded || !expenseCategories.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <RegistrationLayout location={props.location}>
      <ReimbursementVouchersComponent
        subsection={splatString(props.params.splat)}
        alerts={props.alerts}
        companyUser={props.companyUsers.companyUser}
        companyUsers={props.companyUsers.companyUsers}
        departments={props.departments.departments}
        user={props.user}
        company={company}
        employees={props.employees.employees}
        expenseCategories={props.expenseCategories}
        reimbursementVouchers={props.reimbursementVouchers}
        costCenters={props.costCenters.costCenters}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        updateCompany={props.updateCompany}
        addReimbursementVoucher={props.addReimbursementVoucher}
        updateReimbursementVoucher={props.updateReimbursementVoucher}
        updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
        approveReimbursementVouchers={props.approveReimbursementVouchers}
        unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
        rebookReimbursementVouchers={props.rebookReimbursementVouchers}
        draftReimbursementVouchers={props.draftReimbursementVouchers}
        deleteReimbursementVoucher={props.deleteReimbursementVoucher}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companyUsers: state.companyUsers,
    user: state.user,
    companies: state.companies,
    employees: state.employees,
    expenseCategories: state.expenseCategories,
    reimbursementVouchers: state.reimbursementVouchers,
    departments: state.departments,
    costCenters: state.costCenters,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateCompany: updateCompany,
    getExpenseCategories: getExpenseCategories,
    getReimbursementVouchers: getReimbursementVouchers,
    addReimbursementVoucher: addReimbursementVoucher,
    updateReimbursementVoucher: updateReimbursementVoucher,
    updateReimbursementVoucherFields: updateReimbursementVoucherFields,
    approveReimbursementVouchers: approveReimbursementVouchers,
    unapproveReimbursementVouchers: unapproveReimbursementVouchers,
    rebookReimbursementVouchers: rebookReimbursementVouchers,
    draftReimbursementVouchers: draftReimbursementVouchers,
    deleteReimbursementVoucher: deleteReimbursementVoucher,
    getCostCenters: getCostCenters,
    getDepartments: getDepartments,
  }
)(ReimbursementVouchers)
