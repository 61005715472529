import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import Department from '../../model/department'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import Transfer from '../../model/transfer'
import { formatDate } from '../../utils/date-utils'
import { formatPaymentMethod, formatTransferType } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Modal from '../elements/modal'
import Subtitle from '../elements/Subtitle'
import Table from '../elements/table'
import ReportButton from '../widgets/ReportButton'
import PayrollCardToggle from './PayrollCardToggle'
import TransfersModal from './TransfersModal'

type Props = {
  payRoll: PayRoll
  employeesMap: Record<string, Employee>
  departments: List<Department>
  transfers: List<Transfer>

  addAlert: addAlertSignature
}

export default function TransfersCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)
  const [showTransfers, setShowTransfers] = useState(false)

  const setDetailsVisibility = (showTransfers: boolean) => {
    setShowTransfers(showTransfers)
  }

  type TransferRow = {
    key: string
    description: string
    recipient: string
    amount: string
    paymentDate: string
    method: string
  }

  const columns = [
    { title: t('pay_roll.single.transfers.header.description'), dataIndex: 'description', key: 'description' },
    {
      title: t('pay_roll.single.transfers.header.recipient'),
      dataIndex: '',
      key: 'xRecipient',
      width: '25%',
      render: (transfer: TransferRow) => <span className="pay-roll-limited-width">{transfer.recipient}</span>,
    },
    { title: t('pay_roll.single.transfers.header.amount'), dataIndex: 'amount', key: 'amount' },
    { title: t('pay_roll.single.transfers.header.payment_date'), dataIndex: 'paymentDate', key: 'paymentDate' },
    { title: t('pay_roll.single.transfers.header.method'), dataIndex: 'method', key: 'method' },
  ]

  const getTransfers = (): TransferRow[] => {
    return props.transfers
      .filter((transfer) => transfer.payRollID === props.payRoll.id && transfer.type !== 'DK FLF Company')
      .map((transfer) => {
        const combinedTransfer = {
          key: transfer.id,
          description: formatTransferType(transfer.type, transfer.transferDestinationType),
          recipient: transfer.recipient,
          amount: formatCurrency(transfer.amount, 2),
          paymentDate: formatDate(transfer.paymentDate),
          method: formatPaymentMethod(transfer.paymentMethod),
        }
        switch (transfer.type) {
          case 'FreelanceFee':
            if (transfer.employeeID) {
              const employee = props.employeesMap[transfer.employeeID]
              if (employee) {
                combinedTransfer.recipient = employee.name || employee.email || '-'
              } else {
                combinedTransfer.recipient =
                  transfer.recipient.substring(0, 1).toUpperCase() + transfer.recipient.substring(1)
              }
            }
            break
          case 'Salary':
          case 'AM Pension':
          case 'DK Pension':
            if (transfer.employeeID) {
              const employee = props.employeesMap[transfer.employeeID]
              if (employee) {
                combinedTransfer.recipient = employee.name || employee.email || '-'
                if (employee.departmentID) {
                  props.departments.forEach((department) => {
                    if (employee.departmentID === department.id) {
                      combinedTransfer.recipient += ` (${department.name})`
                    }
                  })
                }
              } else {
                combinedTransfer.recipient =
                  transfer.recipient.substring(0, 1).toUpperCase() + transfer.recipient.substring(1)
              }
            } else {
              combinedTransfer.recipient =
                transfer.recipient.substring(0, 1).toUpperCase() + transfer.recipient.substring(1)
            }
            break
          default:
            break
        }
        return combinedTransfer
      })
      .toArray()
  }

  const hasManualTransfers = props.transfers.some(
    (transfer) => ['FreelanceFee', 'Salary'].indexOf(transfer.type) !== -1
  )
  const hasNETSTransfer = props.transfers.some((transfer) => transfer.paymentMethod === 'NETS')
  const hasNonNETSTransfer = props.transfers.some((transfer) => transfer.paymentMethod !== 'NETS')

  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.transfers.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {tx('pay_roll.single.transfers.total', {
            total: <strong>{formatCurrency(props.payRoll.totalTransfer, 2)}</strong>,
          })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <PayrollCardToggle expanded={expanded} onChange={(newState) => setExpanded(newState)} />
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        <Row className="pay-roll-card-buttons">
          {hasNETSTransfer && (
            <Col span={16}>
              <p>
                {hasNonNETSTransfer
                  ? t('pay_roll.single.transfers.nets_note.has_non_nets')
                  : t('pay_roll.single.transfers.nets_note')}
              </p>
            </Col>
          )}
          <Col span={8} style={{ textAlign: 'right' }}>
            {hasManualTransfers && (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setDetailsVisibility(true)
                }}
              >
                {t('pay_roll.single.transfers.see_details')}
              </Button>
            )}
            <ReportButton
              state={1}
              type={'Excel'}
              addAlert={props.addAlert}
              icon="document"
              getFields={() => {
                return {
                  type: 'Regular',
                  companyID: props.payRoll.companyID,
                  payRollID: props.payRoll.id,
                  report: 'PayRollTransfers',
                  from: '0001-01-01',
                  to: '0001-01-01',
                }
              }}
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={getTransfers()}
          pagination={props.transfers.size > 100 ? { defaultPageSize: 100 } : false}
          className={'pay-roll-table pay-roll-table-small'}
        />
      </div>

      <Modal
        visible={showTransfers}
        onOk={() => setDetailsVisibility(false)}
        onCancel={() => setDetailsVisibility(false)}
        width={980}
        footer={null}
      >
        <TransfersModal
          visible={showTransfers}
          payRoll={props.payRoll}
          employeesMap={props.employeesMap}
          transfers={props.transfers}
        />
      </Modal>
    </Card>
  )
}
