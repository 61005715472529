import { addMonths, min, startOfYear, subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { approveCarAllowances, getCarAllowances } from '../actions/car-allowances'
import {
  createCoarseCarAllowance,
  deleteCoarseCarAllowance,
  getCoarseCarAllowances,
  updateCoarseCarAllowance,
} from '../actions/coarse-car-allowances'
import { getSalaryCycles } from '../actions/salary-cycles'
import CarAllowanceComponent from '../components/car-allowance/CarAllowance'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../model/coarseCarAllowance'
import SalaryCycle from '../model/salaryCycle'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CarAllowanceReducer } from '../reducers/carAllowances'
import { CoarseCarAllowanceReducer } from '../reducers/coarseCarAllowances'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { getCurrentPeriodFromDispositionDate } from '../utils/salary-period-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

function getSalaryPeriodIDs(salaryCycles: List<SalaryCycle>, offsetID?: string): string[] {
  return salaryCycles.reduce((list: string[], salaryCycle) => {
    if (salaryCycle.prepaid) {
      return list
    }
    if (offsetID && salaryCycle.offset && salaryCycle.id !== offsetID) {
      return list
    }
    if (!salaryCycle.salaryPeriods) {
      return list
    }
    const salaryPeriod = getCurrentPeriodFromDispositionDate(salaryCycle.salaryPeriods)
    if (salaryPeriod) {
      list.push(salaryPeriod.id)
    }
    return list
  }, [])
}

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  salaryCycles: SalaryCycleReducer
  coarseCarAllowances: CoarseCarAllowanceReducer
  carAllowances: CarAllowanceReducer
  salaryTypes: SalaryTypeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getSalaryCycles: () => void
  getCoarseCarAllowances: (companyID?: string, salaryPeriodID?: string[], employeeID?: string) => void
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (coarseCarAllowance: CoarseCarAllowance) => void
  getCarAllowances: (
    companyID: string | undefined,
    employeeID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  approveCarAllowances: (carAllowanceIDs: string[]) => void
}

function CarAllowance(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    companies,
    salaryCycles,
    getSalaryCycles,
    coarseCarAllowances,
    getCoarseCarAllowances,
    carAllowances,
    getCarAllowances,
  } = props
  const company = companies.company
  useEffect(() => {
    if (!company) {
      return
    }
    const companyID = company.id
    if (salaryCycles.companyID !== companyID || (!salaryCycles.loaded && !salaryCycles.loading)) {
      getSalaryCycles()
    }
    if (salaryCycles.loaded) {
      const salaryPeriodIDs = getSalaryPeriodIDs(salaryCycles.salaryCycles, company.defaultHourlyMonthlyCycleID)
      if (salaryPeriodIDs.length > 0) {
        if (
          coarseCarAllowances.companyID !== companyID ||
          !coarseCarAllowances.salaryPeriodIDs.some((salaryPeriodID) =>
            salaryPeriodIDs.some((id) => id === salaryPeriodID)
          ) ||
          (!coarseCarAllowances.loading && !coarseCarAllowances.loaded)
        ) {
          getCoarseCarAllowances(companyID, salaryPeriodIDs)
        }
      }
    }
    if (carAllowances.companyID !== companyID || (!carAllowances.loading && !carAllowances.loaded)) {
      getCarAllowances(
        companyID,
        undefined,
        formatAPIDate(min([startOfYear(getDate()), subMonths(getDate(), 2)])),
        formatAPIDate(addMonths(getDate(), 5))
      )
    }
  })

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = !props.salaryCycles.loaded || !props.coarseCarAllowances.loaded || !props.carAllowances.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <RegistrationLayout location={props.location}>
      <CarAllowanceComponent
        alerts={props.alerts}
        companyUser={props.companyUsers.companyUser}
        employees={props.employees.employees}
        departments={props.departments.departments}
        coarseCarAllowances={props.coarseCarAllowances}
        carAllowances={props.carAllowances}
        salaryCycles={props.salaryCycles.salaryCycles}
        createCoarseCarAllowance={props.createCoarseCarAllowance}
        updateCoarseCarAllowance={props.updateCoarseCarAllowance}
        deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
        approveCarAllowances={props.approveCarAllowances}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyUsers: state.companyUsers,
    employees: state.employees,
    departments: state.departments,
    salaryCycles: state.salaryCycles,
    coarseCarAllowances: state.coarseCarAllowances,
    carAllowances: state.carAllowances,
    salaryTypes: state.salaryTypes,
  }),
  {
    getSalaryCycles: getSalaryCycles,
    getCoarseCarAllowances: getCoarseCarAllowances,
    getCarAllowances: getCarAllowances,
    createCoarseCarAllowance: createCoarseCarAllowance,
    updateCoarseCarAllowance: updateCoarseCarAllowance,
    deleteCoarseCarAllowance: deleteCoarseCarAllowance,
    approveCarAllowances: approveCarAllowances,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(CarAllowance)
