import React, { Children, PropsWithChildren, ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'

type Props = {
  prefixCls?: string
  className?: string
}

export default function Timeline(props: PropsWithChildren<Props>): ReactElement | null {
  const { prefixCls = 'ant-timeline', children, className, ...restProps } = props
  const classString = classNames(prefixCls, className)
  // Remove falsy items
  const falsylessItems = Children.toArray(children).filter((item) => !!item)
  const items = Children.map(falsylessItems, (ele, idx) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.cloneElement(ele, {
      last: idx === Children.count(falsylessItems) - 1,
    })
  )
  return (
    <ul {...restProps} className={classString}>
      {items}
    </ul>
  )
}
