import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import SalaryType from '../../../model/salaryType'
import { DateFormat } from '../../../model/types'
import { SalaryRegistrationReducer } from '../../../reducers/salaryRegistrations'
import { formatAPIDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t, translateGroupTitle } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Select from '../../elements/select'
import RegistrationCostCenter from '../../form-elements/RegistrationCostCenter'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  company: Company
  costCenters: List<CostCenter>
  departments: List<Department>
  employee: Employee
  salaryRegistrationID?: string
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>
}

type Fields = {
  date: Date
  quantity?: string
  salaryTypeID?: string
  costCenterID?: string
  note?: string
}

export type ResultFields = {
  date: DateFormat
  quantity: number
  salaryTypeID: string
  costCenterID?: string
  note?: string
}

function DetailedSalaryRegistrationEditForm(
  props: Props & FormComponentProps<Fields, ResultFields>
): ReactElement | null {
  const { decorateField } = props

  const employment = props.employee.activeEmployment
  const contract = props.employee.activeContract
  const knownTypes: string[] = []
  const types =
    contract && contract.remuneration
      ? contract.remuneration.salary
          .filter((salary) => {
            const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
            if (!salaryType) {
              return false
            }
            if (knownTypes.indexOf(salary.salaryTypeID) !== -1) {
              return false
            }
            knownTypes.push(salary.salaryTypeID)
            return salaryType.class === 'SupplementVaried'
          })
          .sort((a, b) => {
            const titleA = translateGroupTitle(a)
            const titleB = translateGroupTitle(b)
            if (titleA === titleB) {
              return a.rate - b.rate
            }
            return titleA.localeCompare(titleB)
          })
      : []

  if (!employment) {
    return null
  }

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('date', {
            placeholder: t('salary_registrations_tab.detailed.edit.form.date'),
            validate: (val) => {
              if (!val) {
                return t('salary_registrations_tab.detailed.edit.form.date.required')
              }
              if (isTimeBefore(val, getDate(employment.startDate))) {
                return t('salary_registrations_tab.detailed.edit.form.date.not_before_employment')
              }
              return null
            },
          })(<DatePicker allowClear={false} tabIndex={2} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('quantity', {
            placeholder: t('salary_registrations_tab.detailed.edit.form.quantity'),
            suffix: t('salary_registrations_tab.detailed.edit.form.quantity.suffix'),
            validate: (val) => {
              if (!val) {
                return t('salary_registrations_tab.detailed.edit.form.quantity.required')
              }
              if (forceParseInputNumber(val) <= 0) {
                return t('salary_registrations_tab.detailed.edit.form.quantity.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('salaryTypeID', {
            placeholder: t('salary_registrations_tab.detailed.edit.form.salary_type_id'),
            validate: (val) => (!val ? t('salary_registrations_tab.detailed.edit.form.salary_type_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} tabIndex={1}>
              {types.map((row) => {
                return (
                  <Select.Option key={row.salaryTypeID} value={row.salaryTypeID}>
                    {translateGroupTitle(row)} ({formatCurrency(row.rate, 2)})
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <RegistrationCostCenter
        company={props.company}
        costCenters={props.costCenters}
        departments={props.departments}
        decorateField={decorateField}
        getFieldValue={props.getFieldValue}
      />
      <Row>
        <Col span={24}>
          {decorateField('note', {
            placeholder: t('salary_registrations_tab.detailed.edit.form.note'),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.salaryRegistrations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    // filter to supplement varied salary types
    const hourlySalaries =
      props.employee.activeContract && props.employee.activeContract.remuneration
        ? props.employee.activeContract.remuneration.salary.filter((salary) =>
            props.salaryTypes.some(
              (salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'SupplementVaried'
            )
          )
        : []
    let firstHourlySalary = null
    if (hourlySalaries.length > 0) {
      firstHourlySalary = hourlySalaries[0]
    }
    const fields: Fields = {
      date: getDate(),
      salaryTypeID: firstHourlySalary ? firstHourlySalary.salaryTypeID : undefined,
    }
    if (props.salaryRegistrationID) {
      const salaryRegistration = props.salaryRegistrations.salaryRegistrations.find(
        (registration) => registration.id === props.salaryRegistrationID
      )
      if (salaryRegistration) {
        fields.date = getDate(salaryRegistration.date)
        fields.quantity = formatInputNumber(salaryRegistration.quantity)
        fields.salaryTypeID = salaryRegistration.salaryTypeID
        fields.costCenterID = salaryRegistration.costCenterID
        fields.note = salaryRegistration.note
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'quantity':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: ResultFields = {
      date: formatAPIDate(values.date),
      salaryTypeID: values.salaryTypeID!,
      quantity: forceParseInputNumber(values.quantity),
      costCenterID: values.costCenterID,
      note: values.note,
    }
    return result
  },
})(DetailedSalaryRegistrationEditForm)
