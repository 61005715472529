import React, { ReactElement } from 'react'

import { AccountingDimensionCreation, AccountingDimensionMutable } from '../../../model/accountingDimension'
import AccountingIntegration from '../../../model/accountingIntegration'
import { AccountingDimensionReducer } from '../../../reducers/accountingDimensions'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Button from '../../elements/button'
import Checkbox from '../../elements/checkbox'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/icon'
import Input from '../../elements/input'

type Props = {
  accountingIntegration?: AccountingIntegration
  accountingDimensions: AccountingDimensionReducer
}

type DimensionLineValue = {
  internalID?: string
  value: string
}

type DimensionLine = {
  internalID?: string
  externalID?: string
  dimensionCode?: string
  dimensionName?: string
  valuesMutable: boolean
  values: DimensionLineValue[]
  active: boolean
}

type Fields = {
  lines: DimensionLine[]
}

export type EmployeeDimensionResult = {
  dimensions: (AccountingDimensionMutable | AccountingDimensionCreation)[]
}

function EmployeeDimensionsForm(
  props: Props & FormComponentProps<Fields, EmployeeDimensionResult>
): ReactElement | null {
  const add = () => {
    const i = props.getFieldValue('lines').length
    props.setAnyFieldValue(`lines.${i}`, { values: [{ value: '' }], valuesMutable: true, active: true })
  }

  const addValue = (i: number) => {
    const j = (props.getAnyFieldValue(`lines.${i}.values`) as DimensionLineValue[]).length
    props.setAnyFieldValue(`lines.${i}.values.${j}`, { value: '' })
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError, getAnyFieldValue } = props
  const hasAccountingIntegration = props.accountingIntegration?.integrationType !== 'None'
  const hasDimensionValues = (hasAccountingIntegration && props.accountingIntegration?.hasDimensionValues) ?? false

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={hasDimensionValues ? 6 : hasAccountingIntegration ? 14 : 22}>
          {t('dimensions_tab.employee.form.table.header.dimension')}
        </Col>
        {hasDimensionValues && (
          <Col span={hasAccountingIntegration ? 8 : 16}>
            {t('dimensions_tab.employee.form.table.header.dimension_value')}
          </Col>
        )}
        {hasAccountingIntegration && (
          <Col span={8}>{t('dimensions_tab.employee.form.table.header.accounting_integration')}</Col>
        )}
        <Col span={2}>{t('dimensions_tab.employee.form.table.header.active')}</Col>
      </Row>
      {getFieldValue('lines').map((dimension, i) => {
        const values = (getAnyFieldValue(`lines.${i}.values`) as DimensionLineValue[]) || []
        const externalID = getAnyFieldValue(`lines.${i}.externalID`) as string | undefined
        return (
          <Form.Item key={i} validateStatus={getAnyFieldError(`lines.${i}.dimensionCode`) ? 'error' : 'success'}>
            <Row>
              <Col span={hasDimensionValues ? 6 : hasAccountingIntegration ? 14 : 22}>
                {!externalID &&
                  decorateAnyField(`lines.${i}.dimensionCode`, {
                    placeholder: t('dimensions_tab.employee.form.dimension_code'),
                    validate: (val) => {
                      if (!val) {
                        return t('dimensions_tab.employee.form.dimension_code.required')
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                {externalID && (
                  <>
                    {dimension.dimensionCode} ({dimension.dimensionName})
                  </>
                )}
              </Col>
              {hasDimensionValues && (
                <Col span={hasAccountingIntegration ? 8 : 16}>
                  {!dimension.valuesMutable && (
                    <div className="employee-dimensions-values">
                      {values.map((value, j) => {
                        if (j === 0) {
                          return value.value
                        }
                        return ', ' + value.value
                      })}
                    </div>
                  )}
                  {dimension.valuesMutable && (
                    <div className="employee-dimensions-values">
                      {values.map((value, j) => {
                        return decorateAnyField(`lines.${i}.values.${j}.value`, {
                          placeholder:
                            j === 0
                              ? t('dimensions_tab.employee.form.value.first')
                              : t('dimensions_tab.employee.form.value.later'),
                          skipWrapper: true,
                          skipLabel: true,
                        })(<Input />)
                      })}
                      <span className="employee-dimensions-add-row" onClick={() => addValue(i)}>
                        <Icon type="plusCircle" /> {t('dimensions_tab.employee.form.value.add')}
                      </span>
                    </div>
                  )}
                </Col>
              )}
              {hasAccountingIntegration && (
                <Col span={8}>
                  {externalID
                    ? t('dimensions_tab.employee.form.external_id.true')
                    : t('dimensions_tab.employee.form.external_id.false')}
                </Col>
              )}
              <Col span={2}>
                {decorateAnyField(`lines.${i}.active`, {
                  placeholder: t('dimensions_tab.employee.form.active'),
                  valueOnChecked: true,
                  skipWrapper: true,
                  skipLabel: true,
                  noBlur: true,
                })(<Checkbox />)}
              </Col>
            </Row>
          </Form.Item>
        )
      })}
      <span className="employee-dimensions-add-row" onClick={() => add()}>
        <Icon type="plusCircle" /> {t('dimensions_tab.employee.form.add_dimension')}
      </span>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, EmployeeDimensionResult>({
  mapPropsToFields: (props) => ({
    lines: props.accountingDimensions.accountingDimensions
      .filter((dimension) => dimension.usage !== 'CostCenters')
      .map(
        (dimension): DimensionLine => ({
          internalID: dimension.id,
          externalID: dimension.dimensionID,
          dimensionCode: dimension.dimensionCode,
          dimensionName: dimension.dimensionName,
          valuesMutable: dimension.valuesMutable,
          active: dimension.usage === 'Employees',
          values: dimension.values.map((value) => ({ internalID: value.id, value: value.dimensionValueCode })),
        })
      )
      .toArray(),
  }),
  onSubmit: (values, props) => ({
    dimensions: [
      ...props.accountingDimensions.accountingDimensions.filter((dimension) => dimension.usage === 'CostCenters'),
      ...values.lines.map((line): AccountingDimensionCreation => {
        const dimension = props.accountingDimensions.accountingDimensions.find(
          (dimension) => dimension.id === line.internalID
        )
        if (dimension) {
          return {
            ...dimension,
            usage: line.active ? 'Employees' : 'None',
            values: dimension.valuesMutable
              ? line.values.map((value) => ({
                  id: value.internalID,
                  dimensionValueCode: value.value,
                }))
              : dimension.values,
          }
        }
        return {
          dimensionCode: line.dimensionCode || '',
          usage: line.active ? 'Employees' : 'None',
          values: line.values.map((value) => ({
            dimensionValueCode: value.value,
          })),
        }
      }),
    ],
  }),
})(EmployeeDimensionsForm)
