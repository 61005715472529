import { addYears, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { approveCarAllowances, getCarAllowances } from '../actions/car-allowances'
import { getContracts } from '../actions/contracts'
import { getExpenseCategories } from '../actions/expense-categories'
import { approveOneTimePays, getOneTimePays } from '../actions/one-time-pays'
import { getProjects } from '../actions/projects'
import {
  approveReimbursementVouchers,
  draftReimbursementVouchers,
  getReimbursementVouchers,
  unapproveReimbursementVouchers,
  updateReimbursementVoucher,
  updateReimbursementVoucherFields,
} from '../actions/reimbursement-vouchers'
import { approveSalaryRegistrations, getSalaryRegistrations } from '../actions/salary-registrations'
import { approveSwipes, getSwipes } from '../actions/swipes'
import { approveTimeRegistrations, getTimeRegistrations } from '../actions/time-registrations'
import { updateUserCompany } from '../actions/user-companies'
import { ReimbursementVoucherFieldsUpdate, ReimbursementVoucherUpdate } from '../api/reimbursement-vouchers'
import ApproveTabComponent from '../components/approve-tab/ApproveTab'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import CompanyUser from '../model/companyUser'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import { DateFormat, SettledState } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CarAllowanceReducer } from '../reducers/carAllowances'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { ProjectReducer } from '../reducers/projects'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from '../reducers/salaryRegistrations'
import { SwipeReducer } from '../reducers/swipes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { UserCompanyReducer } from '../reducers/userCompanies'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  contracts: ContractReducer
  carAllowances: CarAllowanceReducer
  timeRegistrations: TimeRegistrationReducer
  oneTimePays: OneTimePayReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryRegistrations: SalaryRegistrationReducer
  swipes: SwipeReducer
  userCompanies: UserCompanyReducer

  costCenters: CostCenterReducer
  employees: EmployeeReducer
  expenseCategories: ExpenseCategoryReducer
  departments: DepartmentReducer
  leaveTypes: LeaveTypeReducer
  projects: ProjectReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCarAllowances: (
    companyID: string | undefined,
    employeeID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  approveCarAllowances: (ids: string[]) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  approveTimeRegistrations: (ids: string[]) => void
  getOneTimePays: (companyID?: string) => void
  approveOneTimePays: (ids: string[]) => void
  getReimbursementVouchers: () => void
  getExpenseCategories: () => void
  getSwipes: () => void
  approveSwipes: (ids: string[]) => void
  getContracts: () => void
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState
  ) => void
  approveSalaryRegistrations: (ids: string[]) => void
  updateUserCompany: (companyUser: CompanyUser) => Promise<CompanyUser | void>
  getProjects: () => void

  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
}

function ApproveTab(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { company } = props.companies
  const {
    timeRegistrations,
    getTimeRegistrations,
    carAllowances,
    getCarAllowances,
    oneTimePays,
    getOneTimePays,
    reimbursementVouchers,
    getReimbursementVouchers,
    swipes,
    getSwipes,
    expenseCategories,
    getExpenseCategories,
    salaryRegistrations,
    getSalaryRegistrations,
    projects,
    getProjects,
  } = props
  useEffect(() => {
    if (!company) {
      return
    }
    if (
      !carAllowances.companyID ||
      carAllowances.companyID !== company.id ||
      (!carAllowances.loaded && !carAllowances.loading)
    ) {
      getCarAllowances(
        company.id,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !timeRegistrations.companyID ||
      timeRegistrations.companyID !== company.id ||
      (!timeRegistrations.loaded && !timeRegistrations.loading)
    ) {
      getTimeRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !oneTimePays.companyID ||
      oneTimePays.companyID !== company.id ||
      (!oneTimePays.loaded && !oneTimePays.loading)
    ) {
      getOneTimePays(company.id)
    }
    if (
      !reimbursementVouchers.companyID ||
      reimbursementVouchers.companyID !== company.id ||
      (!reimbursementVouchers.loaded && !reimbursementVouchers.loading)
    ) {
      getReimbursementVouchers()
    }
    if (!swipes.companyID || swipes.companyID !== company.id || (!swipes.loaded && !swipes.loading)) {
      getSwipes()
    }
    if (
      !expenseCategories.companyID ||
      expenseCategories.companyID !== company.id ||
      (!expenseCategories.loaded && !expenseCategories.loading)
    ) {
      getExpenseCategories()
    }
    if (
      !salaryRegistrations.companyID ||
      salaryRegistrations.companyID !== company.id ||
      (!salaryRegistrations.loaded && !salaryRegistrations.loading)
    ) {
      getSalaryRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10)),
        'Pending'
      )
    }
    if (!projects.companyID || projects.companyID !== company.id || (!projects.loaded && !projects.loading)) {
      getProjects()
    }
  }, [
    company,
    carAllowances,
    getCarAllowances,
    timeRegistrations,
    getTimeRegistrations,
    oneTimePays,
    getOneTimePays,
    reimbursementVouchers,
    getReimbursementVouchers,
    swipes,
    getSwipes,
    expenseCategories,
    getExpenseCategories,
    salaryRegistrations,
    getSalaryRegistrations,
    projects,
    getProjects,
  ])

  const { contracts, employees, getContracts } = props

  useEffect(() => {
    if (
      !contracts.loading &&
      employees.employees.some(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          !!employee.earliestMutableContract &&
          !employee.earliestMutableContract.remuneration
      )
    ) {
      getContracts()
    }
  }, [contracts, employees, getContracts])

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading =
    !timeRegistrations.loaded ||
    !carAllowances.loaded ||
    !oneTimePays.loaded ||
    !reimbursementVouchers.loaded ||
    !swipes.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !timeRegistrations.loaded, text: t('loading.reducer.time_registrations') },
            { loading: !carAllowances.loaded, text: t('loading.reducer.car_allowances') },
            { loading: !oneTimePays.loaded, text: t('loading.reducer.one_time_pays') },
            { loading: !reimbursementVouchers.loaded, text: t('loading.reducer.reimbursement_vouchers') },
            { loading: !swipes.loaded, text: t('loading.reducer.swipes') },
          ])}
        />
      </div>
    )
  }

  return (
    <RegistrationLayout location={props.location}>
      <ApproveTabComponent
        alerts={props.alerts}
        company={company}
        companyUser={props.companyUsers.companyUser}
        companyUsers={props.companyUsers.companyUsers}
        carAllowances={carAllowances}
        timeRegistrations={timeRegistrations}
        oneTimePays={oneTimePays}
        reimbursementVouchers={reimbursementVouchers}
        salaryRegistrations={salaryRegistrations}
        swipes={swipes}
        userCompanies={props.userCompanies}
        costCenters={props.costCenters.costCenters}
        employees={props.employees.employees}
        expenseCategories={props.expenseCategories.expenseCategories}
        departments={props.departments.departments}
        leaveTypes={props.leaveTypes.leaveTypes}
        projects={props.projects.projects}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        approveTimeRegistrations={props.approveTimeRegistrations}
        approveCarAllowances={props.approveCarAllowances}
        approveOneTimePays={props.approveOneTimePays}
        approveSwipes={props.approveSwipes}
        updateUserCompany={props.updateUserCompany}
        approveSalaryRegistrations={props.approveSalaryRegistrations}
        updateReimbursementVoucher={props.updateReimbursementVoucher}
        updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
        approveReimbursementVouchers={props.approveReimbursementVouchers}
        unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
        draftReimbursementVouchers={props.draftReimbursementVouchers}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    carAllowances: state.carAllowances,
    companies: state.companies,
    companyUsers: state.companyUsers,
    contracts: state.contracts,
    costCenters: state.costCenters,
    employees: state.employees,
    expenseCategories: state.expenseCategories,
    departments: state.departments,
    leaveTypes: state.leaveTypes,
    oneTimePays: state.oneTimePays,
    reimbursementVouchers: state.reimbursementVouchers,
    timeRegistrations: state.timeRegistrations,
    salaryRegistrations: state.salaryRegistrations,
    swipes: state.swipes,
    userCompanies: state.userCompanies,
    projects: state.projects,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getCarAllowances: getCarAllowances,
    approveCarAllowances: approveCarAllowances,
    getTimeRegistrations: getTimeRegistrations,
    approveTimeRegistrations: approveTimeRegistrations,
    getOneTimePays: getOneTimePays,
    approveOneTimePays: approveOneTimePays,
    getReimbursementVouchers: getReimbursementVouchers,
    updateReimbursementVoucher: updateReimbursementVoucher,
    updateReimbursementVoucherFields: updateReimbursementVoucherFields,
    approveReimbursementVouchers: approveReimbursementVouchers,
    unapproveReimbursementVouchers: unapproveReimbursementVouchers,
    draftReimbursementVouchers: draftReimbursementVouchers,
    getSwipes: getSwipes,
    approveSwipes: approveSwipes,
    getContracts: getContracts,
    updateUserCompany: updateUserCompany,
    getExpenseCategories: getExpenseCategories,
    approveSalaryRegistrations: approveSalaryRegistrations,
    getSalaryRegistrations: getSalaryRegistrations,
    getProjects: getProjects,
  }
)(ApproveTab)
