import React, { ReactElement } from 'react'

import { ProductionUnit } from '../../model/company'
import { decorateFieldSignature, getFieldValueSignature } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Select from '../elements/select'

type WorkplaceFields = {
  productionUnitID?: string
}

type Props<Fields extends WorkplaceFields> = {
  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  productionUnits: ProductionUnit[]
  disabled?: boolean
}

export default function ContractWorkplace<Fields extends WorkplaceFields>(props: Props<Fields>): ReactElement | null {
  const getProductionUnits = () => {
    const { getFieldValue } = props
    const productionUnitID = getFieldValue('productionUnitID')
    return props.productionUnits.filter((productionUnit) => {
      if (productionUnit.active) {
        return true
      }
      return productionUnitID && productionUnit.id === productionUnitID
    })
  }

  const { decorateField } = props

  return (
    <Row>
      <Col span={24}>
        {decorateField('productionUnitID', {
          title: t('contract.workplace.production_unit_id'),
          placeholder: t('contract.workplace.production_unit_id.placeholder'),
          validate: (val) => (!val ? t('contract.workplace.production_unit_id.required') : null),
        })(
          <Select dropdownMatchSelectWidth={false} disabled={props.disabled}>
            {getProductionUnits().map((productionUnit) => {
              return (
                <Select.Option key={productionUnit.id} value={productionUnit.id}>
                  {productionUnit.name} ({productionUnit.productionUnitNumber})
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Col>
    </Row>
  )
}
