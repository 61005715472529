import React, { ReactElement } from 'react'

import { UserReducer } from '../../reducers/user'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  user: UserReducer
}

type Fields = {
  email?: string
}

export type EmailResult = {
  email: string
}

function EmailForm(props: Props & FormComponentProps<Fields, EmailResult>): ReactElement | null {
  const { decorateField, getFormError } = props

  return (
    <div>
      {getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('email', {
            title: t('form.field.email'),
            validate: (val) => {
              if (!val) {
                return t('form.validation.email_is_required')
              }
              if (!validateEmail(val)) {
                return t('form.validation.email_is_invalid')
              }
              if (val === props.user.email) {
                return t('form.validation.email_is_the_same_as_existing')
              }
              return null
            },
          })(<Input />)}
        </Col>
        <Col span={12} style={{ clear: 'left' }}>
          <span className="ant-form-extra">{t('account.message.email_change_help_text')}</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.user.requestingEmailChange && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, EmailResult>({
  mapPropsToFields: (props) => ({
    email: props.user.email,
  }),
  onSubmit: (values) => ({
    email: values.email!,
  }),
})(EmailForm)
