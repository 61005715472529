import Dialog from 'rc-dialog'
import React, { PropsWithChildren, ReactElement, ReactNode, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { t } from '../../../utils/translation-utils'
import Button from '../button'

type Props = {
  prefixCls?: string
  onOk?: (e: React.MouseEvent) => void
  onCancel?: (e: React.MouseEvent) => void
  okText?: ReactNode
  cancelText?: ReactNode
  width?: string | number
  confirmLoading?: boolean
  visible?: boolean
  footer?: ReactNode
  title?: ReactNode
  closable?: boolean
  transitionName?: 'zoom'
  maskTransitionName?: 'fade'
  okType?: 'primary'
  className?: string
}

export default function Modal(props: PropsWithChildren<Props>): ReactElement | null {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null)
  const handleCancel = (e: React.MouseEvent) => {
    const onCancel = props.onCancel
    if (onCancel) {
      onCancel(e)
    }
  }

  const handleOk = (e: React.MouseEvent) => {
    const onOk = props.onOk
    if (onOk) {
      onOk(e)
    }
  }

  const handleClick = (e: MouseEvent) => {
    setMousePosition({
      x: e.pageX,
      y: e.pageY,
    })
    setTimeout(() => setMousePosition(null), 100)
  }

  useEffectOnce(() => {
    document.documentElement.addEventListener('click', handleClick)
    return () => document.documentElement.removeEventListener('click', handleClick)
  })

  const {
    prefixCls = 'ant-modal',
    width = 520,
    transitionName = 'zoom',
    maskTransitionName = 'fade',
    confirmLoading = false,
    visible = false,
    okType = 'primary',
    okText,
    cancelText,
    footer,
    ...restProps
  } = props

  const defaultFooter = [
    <Button key="cancel" size="large" onClick={handleCancel}>
      {cancelText || t('modal.confirm.cancel')}
    </Button>,
    <Button key="confirm" type={okType} size="large" loading={confirmLoading} onClick={handleOk}>
      {okText || t('modal.confirm.ok')}
    </Button>,
  ]

  return (
    <Dialog
      {...restProps}
      prefixCls={prefixCls}
      width={width}
      transitionName={transitionName}
      maskTransitionName={maskTransitionName}
      confirmLoading={confirmLoading}
      footer={footer === undefined ? defaultFooter : footer}
      visible={visible}
      mousePosition={mousePosition}
      onClose={handleCancel}
    />
  )
}
