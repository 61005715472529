import { startOfMonth } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import Contract, {
  CarAllowanceRegistrationMethodType,
  ContractCreationFields,
  ContractEmploymentType,
  SalaryRegistrationMethodType,
  TimeRegistrationMethodType,
} from '../../model/contract'
import ContractTemplate from '../../model/contractTemplate'
import { ContractTemplateContractContainer } from '../../model/contractTemplateContract'
import Employee, { AffiliationType } from '../../model/employee'
import EmploymentPosition from '../../model/employmentPosition'
import LeaveType from '../../model/leaveType'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import SalaryType from '../../model/salaryType'
import SupplementType from '../../model/supplementType'
import { DateFormat, Day } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { ContractBookContractReducer } from '../../reducers/contractBookContracts'
import { ContractReducer } from '../../reducers/contracts'
import { EmployeeReducer } from '../../reducers/employees'
import DayLaborer from '../../types/day-laborer'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatAPIDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { EmployeePayType, translateEmploymentTypeToPayType } from '../../utils/employee-utils'
import { formatError } from '../../utils/error-utils'
import { calculateFutureValidFrom } from '../../utils/future-contracts-utils'
import { logWarning } from '../../utils/log-utils'
import { getAvailableCycles } from '../../utils/salary-cycle-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import BenefitsStep, { BenefitStepResult } from './BenefitsStep'
import EmploymentStep, { EmploymentStepResult } from './EmploymentStep'
import FutureContractStep, { FutureContractStepResult } from './FutureContractStep'
import PayStep, { PayStepResult } from './PayStep'
import PensionStep, { PensionStepResult } from './PensionStep'
import { FutureContract, RemunerationFields } from './types'
import VacationStep, { VacationStepResult } from './VacationStep'

import './ContractsAdd.css'

type Props = {
  contractBookContractID?: string
  contractTemplateID?: string
  alerts: AlertReducer
  contract?: Contract
  employee: Employee
  company: Company
  contracts: ContractReducer
  contractTemplates: List<ContractTemplate>
  contractTemplateContracts: List<ContractTemplateContractContainer>
  employees: EmployeeReducer
  employmentPositions: List<EmploymentPosition>
  salaryCycles: List<SalaryCycle>
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  salaryTypes: List<SalaryType>
  contractBookContracts: ContractBookContractReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addContract: (contract: ContractCreationFields, viewable?: boolean) => Promise<Contract | void>
}

type State = {
  id?: string
  affiliationType: AffiliationType
  employmentID: string
  employmentType: ContractEmploymentType
  validFrom: DateFormat
  validTo?: DateFormat
  salaryPeriod?: SalaryPeriod
  productionUnitID?: string
  employmentPositionID?: [string, string]
  position?: string
  salaryCycleID?: string
  prepaid: boolean
  payType: EmployeePayType
  timeRegistrationMethodType: TimeRegistrationMethodType
  carAllowanceRegistrationMethodType: CarAllowanceRegistrationMethodType
  salaryRegistrationMethodType: SalaryRegistrationMethodType
  workCycle: Day[][]
  workCycleAnchorDate?: DateFormat
  weeklyWorkDays: number
  workCycleHours: number[]
  dayLaborer: DayLaborer
  periodWorkHours?: number
  useATP: boolean
  remuneration: RemunerationFields
  futureContracts: FutureContract[]
  futureLockedSalary: boolean
}

export default function ContractsAdd(props: Props): ReactElement | null {
  const [state, setState] = useState<State>(() => {
    let validFrom = startOfMonth(getDate())
    const startDate = getDate(props.employee.activeEmployment?.startDate)
    if (isTimeBefore(validFrom, startDate)) {
      validFrom = startDate
    }
    // only allow active production units in the list
    const productionUnits = props.company.productionUnits.filter((productionUnit) => productionUnit.active)
    const salaryCycles = getAvailableCycles(props.company, props.salaryCycles.toArray(), false).filter(
      (salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.offset
    )
    const salaryType = props.salaryTypes.find((salaryType) => salaryType.name === 'Fixed')
    const state: State = {
      id: undefined,
      affiliationType: props.employee.affiliationType,
      employmentID: props.employee.activeEmployment?.id || '',
      employmentType: 'Ordinary',
      validFrom: formatAPIDate(validFrom),
      validTo: undefined,
      salaryPeriod: undefined,
      productionUnitID: productionUnits && productionUnits.length ? productionUnits[0].id : undefined,
      employmentPositionID: undefined,
      position: undefined,
      salaryCycleID: salaryCycles && salaryCycles.length > 0 ? salaryCycles[0].id : undefined,
      prepaid: false,
      payType: 'Salaried',
      timeRegistrationMethodType: 'Coarse',
      carAllowanceRegistrationMethodType: 'Coarse',
      salaryRegistrationMethodType: 'Coarse',
      workCycle: [['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']],
      weeklyWorkDays: 5,
      workCycleHours: [37],
      dayLaborer: DayLaborer.NONE,
      useATP: true,
      remuneration: {
        salary: salaryType ? [{ salaryTypeID: salaryType.id, title: salaryType.title || undefined }] : [],
        benefits: [],
        leave: [],
        supplements: [],
        pension: [],
      },
      futureContracts: [],
      futureLockedSalary: false,
    }
    if (props.contractBookContractID) {
      const contract = props.contractBookContracts.contracts.find((v) => v.contractID === props.contractBookContractID)
      if (contract) {
        if (contract.contractValidFrom) {
          state.validFrom = contract.contractValidFrom
        }
        state.position = contract.position || undefined
        if (contract.remuneration) {
          if (contract.remuneration.salary) {
            if (contract.remuneration.salary.length > 0) {
              if (
                contract.remuneration.salary.some((salary) =>
                  props.salaryTypes.some((type) => type.id === salary.salaryTypeID && type.name === 'Hourly')
                )
              ) {
                state.payType = 'Hourly Paid'
              }
              state.remuneration.salary = contract.remuneration.salary
            } else {
              // assume hourly paid, if no salary lines
              const salaryType = props.salaryTypes.find((type) => type.name === 'Hourly')
              if (salaryType) {
                state.remuneration.salary = [{ salaryTypeID: salaryType.id, title: salaryType.title || undefined }]
                state.payType = 'Hourly Paid'
              } else {
                state.remuneration.salary = []
                state.payType = 'Commissioned'
              }
            }
          }
          if (contract.remuneration.benefits && contract.remuneration.benefits.length > 0) {
            state.remuneration.benefits = contract.remuneration.benefits
          }
          if (contract.remuneration.leave && contract.remuneration.leave.length > 0) {
            state.remuneration.leave = contract.remuneration.leave
          }
          if (contract.remuneration.supplements && contract.remuneration.supplements.length > 0) {
            state.remuneration.supplements = contract.remuneration.supplements
          }
          if (contract.remuneration.pension && contract.remuneration.pension.length > 0) {
            state.remuneration.pension = contract.remuneration.pension
          }
        }
      }
    }
    if (props.contractTemplateID) {
      const template = props.contractTemplates.find((template) => template.id === props.contractTemplateID)
      const baseTemplateContract = props.contractTemplateContracts.first()?.templateContract
      if (template && baseTemplateContract) {
        state.payType = translateEmploymentTypeToPayType(template.employeeType)
        state.position = baseTemplateContract.position
        if (baseTemplateContract.employmentPositionID) {
          const employmentPosition = props.employmentPositions.find(
            (position) => position.id === baseTemplateContract.employmentPositionID
          )
          if (employmentPosition) {
            state.employmentPositionID = [employmentPosition.group, employmentPosition.id]
            state.position = employmentPosition.title
          }
        }
        state.remuneration.benefits = baseTemplateContract.remuneration.benefits
        state.remuneration.leave = baseTemplateContract.remuneration.leave
        state.remuneration.pension = baseTemplateContract.remuneration.pension.map((pension) => ({
          ...pension,
          pensionCustomerID: undefined,
          destinationSortCode: undefined,
          destinationAccount: undefined,
        }))
        state.remuneration.supplements = baseTemplateContract.remuneration.supplements
        state.carAllowanceRegistrationMethodType = baseTemplateContract.carAllowanceRegistrationMethodType
        state.timeRegistrationMethodType = baseTemplateContract.timeRegistrationMethodType
        state.salaryCycleID = baseTemplateContract.salaryCycleID
        state.workCycleHours = baseTemplateContract.workCycleHours
        state.dayLaborer = baseTemplateContract.dayLaborer
        state.workCycle = baseTemplateContract.workCycle
        state.workCycleAnchorDate = baseTemplateContract.workCycleAnchorDate
        if (baseTemplateContract.remuneration.salary.length > 0 || state.payType === 'Commissioned') {
          // salary selected or commissioned, so we dare set their salary
          state.remuneration.salary = baseTemplateContract.remuneration.salary
          state.futureLockedSalary = true
        } else if (state.payType === 'Hourly Paid') {
          // if hourly paid, switch to that
          const salaryType = props.salaryTypes.find((type) => type.name === 'Hourly')
          if (salaryType) {
            state.remuneration.salary[0] = { salaryTypeID: salaryType.id, title: salaryType.title || undefined }
          }
        }
      }
      if (baseTemplateContract && props.contractTemplateContracts.size > 1) {
        // future contracts!
        props.contractTemplateContracts.forEach((delta, i) => {
          if (i === 0) {
            return
          }
          const templateContract = delta.templateContract
          const futureContract: FutureContract = {
            // these fields we just copy
            position: baseTemplateContract.position,
            employmentPositionID: baseTemplateContract.employmentPositionID,
            carAllowanceRegistrationMethodType: baseTemplateContract.carAllowanceRegistrationMethodType,
            timeRegistrationMethodType: baseTemplateContract.timeRegistrationMethodType,
            salaryRegistrationMethodType: baseTemplateContract.salaryRegistrationMethodType,
            salaryCycleID: baseTemplateContract.salaryCycleID,
            workCycleHours: baseTemplateContract.workCycleHours,
            dayLaborer: baseTemplateContract.dayLaborer,
            workCycle: baseTemplateContract.workCycle,
            workCycleAnchorDate: baseTemplateContract.workCycleAnchorDate,
            remuneration: {
              benefits: templateContract.remuneration.benefits,
              leave: templateContract.remuneration.leave,
              pension: templateContract.remuneration.pension.map((pension) => ({
                ...pension,
                pensionCustomerID: undefined,
                destinationSortCode: undefined,
                destinationAccount: undefined,
              })),
              supplements: templateContract.remuneration.supplements,
              salary: state.remuneration.salary, // use the base salary definition
            },
            validFrom: templateContract.validFrom || 0,
            delta,
          }
          if (state.futureLockedSalary) {
            futureContract.remuneration.salary = templateContract.remuneration.salary
          }

          state.futureContracts.push(futureContract)
        })
      }
    }
    if (state.payType === 'Hourly Paid' && props.company.defaultHourlyMonthlyCycleID) {
      state.salaryCycleID = props.company.defaultHourlyMonthlyCycleID
    }
    return state
  })
  const [current, setCurrent] = useState(0)
  const [error, setError] = useState<Error | null>(null)

  const { contracts, addAlert, employee } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (previousContracts && previousContracts.contracts.size < contracts.contracts.size) {
      // Check for no error occurred
      if (!contracts.error) {
        addAlert('success', t('contracts_add.alert.success', { name: employee.name }), { timeout: 5 })
        // Redirect to employee page
        jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/employment')
      }
    }

    regularComponentDidUpdate(contracts.error, error, setError)
  }, [previousContracts, contracts, employee, addAlert, error])

  const _handlePrevious = (
    values: PayStepResult | BenefitStepResult | VacationStepResult | PensionStepResult | FutureContractStepResult,
    steps = 1
  ) => {
    setState((state) => ({
      ...state,
      ...values,
      remuneration: { ...state.remuneration, ...(values.step !== 'FutureContractStep' ? values.remuneration : {}) },
    }))
    setCurrent((prev) => prev - steps)
  }
  const _handleNext = (
    values: EmploymentStepResult | PayStepResult | BenefitStepResult | VacationStepResult | PensionStepResult,
    steps = 1
  ) => {
    setState((state) => ({
      ...state,
      ...values,
      remuneration: { ...state.remuneration, ...values.remuneration },
      futureContracts:
        values.step === 'PayStep' && !state.futureLockedSalary
          ? state.futureContracts.map((futureContract) => ({
              ...futureContract,
              remuneration: { ...futureContract.remuneration, salary: values.remuneration.salary },
            }))
          : state.futureContracts,
    }))
    setCurrent((prev) => prev + steps)
  }
  const _handleSubmit = (values: PensionStepResult | FutureContractStepResult) => {
    setState((state) => ({
      ...state,
      ...values,
      remuneration: { ...state.remuneration, ...(values.step === 'PensionStep' ? values.remuneration : {}) },
    }))

    const employee = { ...props.employee, affiliationType: state.affiliationType }
    if (!employee.activeEmployment) {
      return
    }
    props.updateEmployee(employee).then(() => {
      if (!employee.activeEmployment) {
        return
      }
      const combinedState = {
        ...state,
        ...values,
        remuneration: { ...state.remuneration, ...(values.step === 'PensionStep' ? values.remuneration : {}) },
      }
      const contract: ContractCreationFields = {
        ...combinedState,
        employeeID: employee.id,
        employmentPositionID:
          combinedState.employmentPositionID && combinedState.employmentPositionID.length >= 2
            ? combinedState.employmentPositionID[1]
            : undefined,
        validFrom: isTimeBefore(getDate(combinedState.validFrom), getDate(employee.activeEmployment.startDate))
          ? employee.activeEmployment.startDate
          : combinedState.validFrom,
        periodWorkHours:
          combinedState.payType === 'Hourly Paid' ? undefined : combinedState.periodWorkHours || undefined,
        contractTemplateID: props.contractTemplateID,
        salaryCycleID: combinedState.salaryCycleID!,
        remuneration: {
          ...combinedState.remuneration,
          salary: combinedState.remuneration.salary.map((salary) => ({
            ...salary,
            rate: salary.rate || 0,
            title: salary.title || '',
          })),
        },
      }
      let previousValidFrom = contract.validFrom
      props.addContract(contract).then(() => {
        if (values.step !== 'FutureContractStep') {
          return
        }
        state.futureContracts.forEach((futureContract, i) => {
          if (!values.includedFutureContracts.some((idx) => idx === i)) {
            return
          }
          const combinedContract = { ...contract, ...futureContract, validTo: undefined }

          const validFrom = calculateFutureValidFrom(
            props.salaryCycles.toArray(),
            combinedContract.salaryCycleID,
            previousValidFrom,
            combinedContract.validFrom
          )

          if (!validFrom) {
            return // not valid
          }
          const contractFuture: ContractCreationFields = {
            ...combinedContract,
            validFrom,
            remuneration: {
              ...combinedContract.remuneration,
              salary: combinedContract.remuneration.salary.map((salary) => ({
                ...salary,
                rate: salary.rate || 0,
                title: salary.title || '',
              })),
            },
          }
          previousValidFrom = validFrom

          props.addContract(contractFuture, false)
        })
      })
    })
  }

  if (!props.employee.activeEmployment) {
    // nothing to show, and we will be redirecting them already
    jsBrowserHistory.push('/' + paths.EMPLOYEES)
    return null
  }
  const saving = props.contracts.saving || props.employees.saving
  let contractBookContract = null
  if (props.contractBookContractID) {
    contractBookContract = props.contractBookContracts.contracts.find(
      (v) => v.contractID === props.contractBookContractID
    )
    if (contractBookContract) {
      contractBookContract.fields.sort((a, b) => a.key.localeCompare(b.key, 'da-dk'))
    }
  }

  const hasFutureChanges = state.futureContracts.length > 0

  // check for broken states
  if ((current === 1 && !state.salaryCycleID) || (current === 3 && !state.salaryPeriod)) {
    logWarning(`broken contracts add state: ${current}`)
    return null
  }

  return (
    <div className="contracts-add">
      <Row>
        <Col span={17}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {saving && (
            <div style={{ position: 'relative', minHeight: '400px' }}>
              <LoadingOverlay />
            </div>
          )}
          {!saving && (
            <div className="contracts-add-overview">
              {error && <Alert message={formatError(error)} type="error" showIcon />}
              {current === 0 && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_0.title')}</Blocktitle>
                  <EmploymentStep
                    employee={props.employee}
                    company={props.company}
                    supplementTypes={props.supplementTypes}
                    employmentPositions={props.employmentPositions}
                    salaryCycles={props.salaryCycles}
                    salaryTypes={props.salaryTypes}
                    {...state}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {current === 1 && !!state.salaryCycleID && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_1.title')}</Blocktitle>
                  <PayStep
                    company={props.company}
                    employee={props.employee}
                    employment={props.employee.activeEmployment}
                    salaryCycles={props.salaryCycles}
                    salaryTypes={props.salaryTypes}
                    {...state}
                    salaryCycleID={state.salaryCycleID}
                    onBack={_handlePrevious}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {current === 2 && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_2.title')}</Blocktitle>
                  <BenefitsStep {...state} onBack={_handlePrevious} onSubmit={_handleNext} />
                </Card>
              )}
              {current === 3 && !!state.salaryPeriod && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_3.title')}</Blocktitle>
                  <VacationStep
                    company={props.company}
                    salaryCycles={props.salaryCycles}
                    leaveTypes={props.leaveTypes}
                    supplementTypes={props.supplementTypes}
                    {...state}
                    salaryPeriod={state.salaryPeriod}
                    onBack={_handlePrevious}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {current === 4 && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_4.title')}</Blocktitle>
                  <PensionStep
                    settingsEnabled={props.company.settingsEnabled}
                    employee={props.employee}
                    pensionCompanies={props.pensionCompanies}
                    supplementTypes={props.supplementTypes}
                    leaveTypes={props.leaveTypes}
                    hasFutureChanges={hasFutureChanges}
                    {...state}
                    onBack={_handlePrevious}
                    onSubmit={hasFutureChanges ? _handleNext : _handleSubmit}
                  />
                </Card>
              )}
              {current === 5 && (
                <Card>
                  <Blocktitle>{t('contracts_add.step_5.title')}</Blocktitle>
                  <FutureContractStep
                    employee={props.employee}
                    salaryCycles={props.salaryCycles}
                    salaryTypes={props.salaryTypes}
                    pensionCompanies={props.pensionCompanies}
                    {...state}
                    onBack={_handlePrevious}
                    onSubmit={_handleSubmit}
                  />
                </Card>
              )}
            </div>
          )}
        </Col>
        <Col span={7}>
          <Card>
            <Subtitle>{t('contracts_add.steps.title')}</Subtitle>
            <p>{t('contracts_add.steps.intro', { steps: hasFutureChanges ? '6' : '5', name: props.employee.name })}</p>

            <Steps direction="vertical" current={current}>
              <Steps.Step title={t('contracts_add.steps.0')} />
              <Steps.Step title={t('contracts_add.steps.1')} />
              <Steps.Step title={t('contracts_add.steps.2')} />
              <Steps.Step title={t('contracts_add.steps.3')} />
              <Steps.Step title={t('contracts_add.steps.4')} />
              {hasFutureChanges && <Steps.Step title={t('contracts_add.steps.5')} />}
            </Steps>
          </Card>
          {contractBookContract && (
            <Card>
              <Subtitle>{t('contracts_add.steps.contract_book.title')}</Subtitle>
              <p>{t('contracts_add.steps.contract_book.intro')}</p>
              <ul>
                {contractBookContract.fields.map((field) => (
                  <li key={field.key}>
                    {field.key}
                    <p>{field.value || t('contracts_add.steps.contract_book.missing')}</p>
                  </li>
                ))}
              </ul>
            </Card>
          )}
          <small style={{ textAlign: 'center' }}>
            {tx('contracts_add.steps.help_note', { email: <a href="mailto:support@salary.dk">support@salary.dk</a> })}
          </small>
        </Col>
      </Row>
    </div>
  )
}
