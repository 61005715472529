import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import { AlertReducer } from '../../reducers/alerts'
import { DocumentReducer } from '../../reducers/documents'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatError } from '../../utils/error-utils'
import { secureUrl, url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import { FileChangeEvent, HandleFileEvents } from '../../utils/upload-utils'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Table from '../elements/table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CompanyDocumentEdit from './CompanyDocumentEdit'

import './Documents.css'

type Props = {
  subsection?: string
  alerts: AlertReducer
  company: Company
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>

  addAlert: addAlertSignature
  addDocument: (companyID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => Promise<Document | void>
  deleteDocument: (documentID: string) => void
}

export default function CompanyDocuments(props: Props): ReactElement | null {
  type CreationDocumentState = {
    modalKey: number
    editing: string | boolean
    fileID?: string
  }
  const [deleting, setDeleting] = useState<string[]>([])
  const [companyDocumentState, setCompanyDocumentState] = useState<CreationDocumentState>({
    modalKey: 1,
    editing: false,
  })
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<Error>()

  const setEditCompanyDocumentVisibility = (id: string | boolean, fileID?: string) => {
    setCompanyDocumentState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
      fileID: fileID,
    }))
  }

  const { documents } = props
  const previousDocuments = usePrevious(documents)
  useEffect(() => {
    if (previousDocuments && previousDocuments.saving && !documents.saving) {
      if (!documents.error) {
        setEditCompanyDocumentVisibility(false)
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('documents.company.confirm.delete_document'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteDocument(id)
      }
    }
  }

  const handleFileUpload = (e: FileChangeEvent) =>
    HandleFileEvents(e, {
      onUploading: () => {
        setError(undefined)
        setUploading(true)
      },
      onDone: (data) => {
        setUploading(false)
        setEditCompanyDocumentVisibility(true, data?.id)
      },
      onError: (e) => {
        setUploading(false)
        setError(e)
      },
    })

  type CompanyDocumentRow = {
    key: string
    id: string
    category: string
    filename: string
    name: string
  }
  const companyDocumentColumns = [
    {
      title: t('documents.company.table.header.filename'),
      key: 'x1',
      render: (document: CompanyDocumentRow) => {
        return (
          <Tooltip title={document.filename}>
            <span className="table-overflow-ellipsis">{document.filename}</span>
          </Tooltip>
        )
      },
    },
    { title: t('documents.company.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('documents.company.table.header.category'), dataIndex: 'category', key: 'category' },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (document: CompanyDocumentRow) => {
        if (deleting.indexOf(document.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('documents.company.table.actions.download')}>
              <a
                href={secureUrl('v2/documentDownload/' + document.id + '?disposition=attachment')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="arrowDownIntoTray" />
              </a>
            </Tooltip>
            <Tooltip title={t('documents.company.table.actions.edit')}>
              <span onClick={() => setEditCompanyDocumentVisibility(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="paperWithPencil" />
              </span>
            </Tooltip>
            <Tooltip title={t('documents.company.table.actions.delete')}>
              <span onClick={remove(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="xSign" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getCompanyDocuments = (): CompanyDocumentRow[] => {
    return props.documents.documents
      .filter((document) => !document.employeeID && document.signers.length === 0)
      .map((document) => {
        let category = t('common.unknown')
        const documentCategory = props.documentCategories.find(
          (documentCategory) => documentCategory.id === document.documentCategoryID
        )
        if (documentCategory) {
          category = documentCategory.name
        }
        return {
          id: document.id,
          key: document.id,
          category,
          filename: document.filename,
          name: document.name,
        }
      })
      .toArray()
  }

  return (
    <div className="documents">
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <TitleMenu>
        <Link to={'/' + paths.DOCUMENTS}>
          <Button>{t('documents.company.header.back')}</Button>
        </Link>
        <div style={{ float: 'right', marginLeft: '20px' }}>
          <FeatureLock
            featurePackage="Premium"
            featureType="Document Store"
            description={t('documents.company.header.new_document.lock')}
          >
            <UploadDragger
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              showUploadList={false}
              onChange={handleFileUpload}
            >
              <Button type="primary" className="gtm-upload-document" prefixIcon="document">
                {t('documents.company.header.new_document')}
              </Button>
            </UploadDragger>
          </FeatureLock>
        </div>
      </TitleMenu>

      <Title>{t('documents.company.header.title')}</Title>

      <Table columns={companyDocumentColumns} dataSource={getCompanyDocuments()} pagination={false} />

      <Modal
        key={`document-company-${companyDocumentState.modalKey}`}
        visible={companyDocumentState.editing !== false}
        onOk={() => setEditCompanyDocumentVisibility(false)}
        onCancel={() => setEditCompanyDocumentVisibility(false)}
        width={582}
        footer={null}
      >
        <CompanyDocumentEdit
          visible={companyDocumentState.editing !== false}
          company={props.company}
          documentID={typeof companyDocumentState.editing === 'string' ? companyDocumentState.editing : undefined}
          fileID={companyDocumentState.fileID}
          documents={props.documents}
          documentCategories={props.documentCategories}
          addDocument={props.addDocument}
          updateDocument={props.updateDocument}
        />
      </Modal>

      {uploading && <LoadingOverlay />}
    </div>
  )
}
