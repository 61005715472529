import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react'

import Popover from './popover'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function ContextMenu(
  props: PropsWithChildren<{ style?: CSSProperties; placeholder: React.ReactNode }>
): ReactElement {
  return (
    <Popover
      placement="bottomRight"
      content={props.children}
      trigger="click"
      overlayClassName="context-menu-popover"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div>{props.placeholder}</div>
    </Popover>
  )
}
